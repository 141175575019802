import React, { useState } from "react"
import { FieldError, UseFormRegister, FieldErrors } from "react-hook-form"
import { InputProps } from "./Input"
import Icon from "./Icon"
import FormInput from "./FormInput"

interface Props extends InputProps {
	register: UseFormRegister<any>
	errors?: { [key: string]: FieldError | undefined } | FieldErrors
	containerClass?: string
	errorClass?: string
	loading?: boolean
	className?: string
	hidden?: boolean
}

const FormInputPassword: React.FC<Props> = ({
	register,
	errors = {},
	containerClass,
	name = "",
	loading,
	label,
	className,
	hidden,
}) => {
	const [showPassword, setShowPassword] = useState<boolean>(false)
	return (
		<div className={`flex flex-col ${containerClass}   relative  ${hidden ? "hidden" : ""}`}>
			<FormInput
				register={register}
				label={label}
				type={showPassword ? "text" : "password"}
				disabled={loading}
				loading={loading}
				containerClass={containerClass}
				className={className}
				errors={errors}
				name={name}
			/>
			<div className="absolute right-8 top-10 " onClick={() => setShowPassword(!showPassword)}>
				<Icon name={showPassword ? "visibilityOn" : "visibilityOff"} color="dark-alt" />
			</div>
		</div>
	)
}

export default FormInputPassword
