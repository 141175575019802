import React, { useEffect, useState } from "react"
import { Modal } from "antd"
import { Upload, Image } from "antd-v5"
import type { RcFile, UploadProps } from "antd-v5/es/upload"
import type { UploadFile } from "antd-v5/es/upload/interface"
import AntdButton from "components/AntdButton"
import { mediaService } from "services/index"
import Icon from "components/Icon"
import { SignedUrlForImage } from "components/SignedUrl"

const getBase64 = (file: RcFile): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result as string)
		reader.onerror = error => reject(error)
	})

interface Props {
	image?: string
	name?: string
	onImageChange: (filePath: string) => void
}
const UploadImageThumbnail: React.FC<Props> = React.memo(function ({ image = "", name, onImageChange }) {
	const [uploading, setUploading] = useState(false)
	const [previewOpen, setPreviewOpen] = useState(false)
	const [previewImage, setPreviewImage] = useState("")
	const [previewTitle, setPreviewTitle] = useState("")
	const [fileList, setFileList] = useState<UploadFile[]>([])
	const [uploadedImage, setUploadedImage] = useState("")

	const handleCancel = () => setPreviewOpen(false)

	const handleUpload = async () => {
		setUploading(true)

		const filePath = (await mediaService.upload(fileList[0].originFileObj as File)).data.url[0]
		setUploadedImage(filePath)
		onImageChange(filePath)
		setUploading(false)
		setPreviewOpen(false)
	}

	const handleChange: UploadProps["onChange"] = async ({ fileList: newFileList }) => {
		const file = newFileList[newFileList.length - 1]
		setFileList([file])
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile)
		}
		setPreviewImage(file.url || (file.preview as string) || "")
		if (file?.url) setPreviewTitle(file.name || file.url?.substring(file.url?.lastIndexOf("/") + 1))
		setPreviewOpen(true)
	}

	useEffect(() => {
		if (image !== uploadedImage) setUploadedImage(image)
	}, [image])

	return (
		<>
			<div className="flex h-full w-full items-center justify-center">
				{uploadedImage ? (
					<div className="relative flex h-full w-full flex-col items-center justify-center overflow-hidden rounded-2">
						<SignedUrlForImage path={uploadedImage}>
							{(imageUrl: string) => (
								<Image wrapperStyle={{ width: "130%", height: "130%" }} loading="lazy" src={imageUrl} />
							)}
						</SignedUrlForImage>

						<div className="absolute bottom-0 z-50 h-[25%] w-full place-content-center bg-[#00000080] ">
							<div className="flex w-full cursor-pointer items-center justify-center">
								<Upload onChange={handleChange} showUploadList={false}>
									<Icon color="white" name="camera" size={40} />
								</Upload>
							</div>
						</div>
					</div>
				) : (
					<div>
						<Upload
							listType="picture-card"
							onChange={handleChange}
							showUploadList={false}
							className="avatar-uploader"
						>
							<div className="flex flex-col">
								<div className="flex h-full w-full content-center items-center justify-center text-10.25 capitalize text-slate-700">
									{name?.charAt(0)}
								</div>
								<span>Upload</span>
							</div>
						</Upload>
					</div>
				)}
			</div>
			<Modal
				open={previewOpen}
				title={previewTitle}
				onCancel={handleCancel}
				footer={[
					<AntdButton key="cancel" onClick={handleCancel}>
						Cancel
					</AntdButton>,
					<AntdButton key="save" onClick={handleUpload} loading={uploading}>
						Save
					</AntdButton>,
				]}
			>
				<img alt="example" style={{ width: "100%" }} src={previewImage} />
			</Modal>
		</>
	)
})

export default UploadImageThumbnail
