import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EmployeeSicknessInterface } from "@type/employees.types"
import { sicknessService } from "services"
import { AppThunk, RootState } from "store"

export interface SicknessStateInterface {
	data: null | EmployeeSicknessInterface
	loading: boolean
	saving: boolean
}

const initialState: SicknessStateInterface = {
	data: null,
	loading: false,
	saving: false,
}

const sicknessSlice = createSlice({
	name: "sickness",
	initialState,
	reducers: {
		requestSicknessData: sickness => {
			sickness.loading = true
		},
		receiveSicknessData: (sickness, action: PayloadAction<EmployeeSicknessInterface>) => {
			sickness.loading = false
			if (typeof action.payload == "string") {
				sickness.data = null
				return
			}
			sickness.data = action.payload
		},
		receiveSicknessDataFailed: sickness => {
			sickness.loading = false
		},
		requestSavingSicknessData: sicknessData => {
			sicknessData.saving = true
		},
		SavingEmployeeSicknessSuccess: (sickness, action: PayloadAction<EmployeeSicknessInterface>) => {
			sickness.saving = false
			sickness.data = action.payload
		},
		SavingEmployeeSicknessSuccessFail: sickness => {
			sickness.saving = false
		},
	},
})

//REDUCER
export default sicknessSlice.reducer

//ACTIONS
const {
	requestSicknessData,
	receiveSicknessData,
	receiveSicknessDataFailed,
	requestSavingSicknessData,
	SavingEmployeeSicknessSuccess,
	SavingEmployeeSicknessSuccessFail,
} = sicknessSlice.actions

const getEmployeesSicknessByServiceId =
	(serviceId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestSicknessData())
			const { data: sicknessResponse } = await sicknessService.getEmployeeSicknessByEmployeeServiceId(serviceId)
			dispatch(receiveSicknessData(sicknessResponse))
		} catch (error) {
			dispatch(receiveSicknessDataFailed())
		}
	}

const saveEmployeeSicknessData =
	(sicknessData: EmployeeSicknessInterface): AppThunk =>
	async dispatch => {
		try {
			let data = null
			dispatch(requestSavingSicknessData())
			if (sicknessData._id) {
				data = await sicknessService.updateEmployeeSickness(sicknessData._id, sicknessData)
			} else {
				data = await sicknessService.createEmployeeSickness(sicknessData)
			}
			const { data: sicknessResponse } = data
			dispatch(SavingEmployeeSicknessSuccess(sicknessResponse))
		} catch (error) {
			dispatch(SavingEmployeeSicknessSuccessFail())
		}
	}

export { saveEmployeeSicknessData, getEmployeesSicknessByServiceId }

//SELECTORS
const selectEmployeeSicknessState = (state: RootState) => state.employee.sickness
const isEmployeeSicknessLoading = () => (state: RootState) => selectEmployeeSicknessState(state).loading
const selectEmployeeSicknessData = () => (state: RootState) => selectEmployeeSicknessState(state).data
const selectEmployeeSicknessSaving = () => (state: RootState) => selectEmployeeSicknessState(state).saving

export { selectEmployeeSicknessData, selectEmployeeSicknessSaving, isEmployeeSicknessLoading }
