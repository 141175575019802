import { sortByProperty, sortDeepCopyByProperty, updateState } from "config/utils"
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetWorkMonitorViewsInterface, WorkMonitorViewsInterface } from "@type/organization.types"
import { workMonitorViewsService } from "services"
import { AppThunk, RootState } from "store"

export interface WorkMonitorViewStateInterface {
	list: null | GetWorkMonitorViewsInterface[]
	loading: boolean
	saving: boolean
}

const initialState: WorkMonitorViewStateInterface = {
	list: null,
	loading: false,
	saving: false,
}

const workMonitorViewsSlice = createSlice({
	name: "workMonitorViews",
	initialState,
	reducers: {
		fetchingWorkMonitorViews: workMonitorViews => {
			workMonitorViews.loading = true
		},
		workMonitorViewsFetched: (workMonitorViews, action: PayloadAction<GetWorkMonitorViewsInterface[]>) => {
			workMonitorViews.loading = false
			workMonitorViews.list = action.payload
		},
		workMonitorViewsFetchingFailed: workMonitorViews => {
			workMonitorViews.loading = false
		},
		workMonitorViewsPresent: workMonitorViews => {
			workMonitorViews.loading = false
		},
		savingWorkMonitorViews: workMonitorViewsData => {
			workMonitorViewsData.saving = true
		},
		workMonitorViewsSaved: (workMonitorViews, action: PayloadAction<GetWorkMonitorViewsInterface>) => {
			workMonitorViews.saving = false
			const [UpdatedValue] = updateState(workMonitorViews.list, action.payload, "_id")
			workMonitorViews.list = UpdatedValue
		},
		workMonitorViewsSavingFailed: workMonitorViews => {
			workMonitorViews.saving = false
		},
	},
})

//REDUCER
export default workMonitorViewsSlice.reducer

//ACTIONS
const {
	fetchingWorkMonitorViews,
	workMonitorViewsFetched,
	workMonitorViewsFetchingFailed,
	savingWorkMonitorViews,
	workMonitorViewsSaved,
	workMonitorViewsSavingFailed,
} = workMonitorViewsSlice.actions

const getWorkMonitorViews =
	(cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingWorkMonitorViews())
			const { data: workMonitorViewsResponse } = await workMonitorViewsService.getAllWorkMonitorViews()
			workMonitorViewsResponse?.sort(sortByProperty("name"))
			cb && cb(workMonitorViewsResponse[0]._id)
			dispatch(workMonitorViewsFetched(workMonitorViewsResponse))
		} catch (error) {
			dispatch(workMonitorViewsFetchingFailed())
		}
	}

const saveWorkMonitorViews =
	(workMonitorViewsData: Partial<GetWorkMonitorViewsInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingWorkMonitorViews())
			if (workMonitorViewsData._id)
				data = await workMonitorViewsService.updateWorkMonitorViews(
					workMonitorViewsData._id,
					workMonitorViewsData,
				)
			else
				data = await workMonitorViewsService.createWorkMonitorViews(
					workMonitorViewsData as WorkMonitorViewsInterface,
				)
			const { data: workMonitorViewsResponse } = data
			cb(workMonitorViewsResponse._id)
			dispatch(workMonitorViewsSaved(workMonitorViewsResponse))
		} catch (error) {
			dispatch(workMonitorViewsSavingFailed())
		}
	}

export { getWorkMonitorViews, saveWorkMonitorViews }

//SELECTORS
const selectWorkMonitorViewsState = (state: RootState) => state.organization.workMonitorViews
const isWorkMonitorViewsLoading = (state: RootState) => selectWorkMonitorViewsState(state).loading
const isWorkMonitorViewsSaving = (state: RootState) => selectWorkMonitorViewsState(state).saving

const selectWorkMonitorViewsList = () =>
	createSelector(
		(state: RootState) => state.organization.workMonitorViews.list,
		list => {
			return sortDeepCopyByProperty(list, "name") as GetWorkMonitorViewsInterface[]
		},
	)

const selectWorkMonitorViewById = (workMonitorViewId: string | null) => (state: RootState) =>
	state.organization.workMonitorViews?.list?.find(
		(workMonitorView: GetWorkMonitorViewsInterface) => workMonitorView._id === workMonitorViewId,
	)

export {
	selectWorkMonitorViewsList,
	selectWorkMonitorViewsState,
	isWorkMonitorViewsSaving,
	isWorkMonitorViewsLoading,
	selectWorkMonitorViewById,
}
