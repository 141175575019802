import React from "react"
import { FormInputPassword } from "."
import Button from "./Button"
import Modal from "./Modal"
import { SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { userChangePasswordInitialValues, userChangePasswordSchema } from "Schema/Security/security"
import useAppDispatch from "hooks/useAppDispatch"
import { updatePassword } from "store/Security/users.slice"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	userId: string | null
	confirmationText?: string
	onConfirmation?: (notes: string) => void
	confirmationAction?: boolean
}
interface ChangePasswordForm {
	newPassword: string
	confirmPassword: string
}
const ConfirmationModal: React.FC<Props> = ({
	open,
	modalHandler,
	userId,
	confirmationText = "Submit",
	confirmationAction = false,
}) => {
	const dispatch = useAppDispatch()
	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm<ChangePasswordForm>({
		resolver: yupResolver(userChangePasswordSchema),
		mode: "onChange",
	})

	const onSubmit: SubmitHandler<ChangePasswordForm> = data => {
		if (userId) dispatch(updatePassword(userId, data))
		reset(userChangePasswordInitialValues)
		modalHandler(false)
	}

	return (
		<Modal open={open} modalHandler={modalHandler} containerClass={" w-96 "}>
			<div className=" justify-center sm:flex sm:items-start">
				<div className="w-full ">
					<form className="pb-2.75" onSubmit={handleSubmit(onSubmit)}>
						<div className="m-2 mt-2 flex max-w-full flex-col items-center justify-center space-y-4 pb-12">
							<p className="text-6">Change Password</p>
							<FormInputPassword
								containerClass="ml-2 max-w-72 w-full"
								className="mr-5.5 mt-1.25 w-auto "
								register={register}
								errors={errors}
								type="password"
								label="New Password"
								name="newPassword"
							/>
							<FormInputPassword
								containerClass="ml-2 max-w-72 w-full"
								className="mr-5.5 mt-1.25 w-auto "
								register={register}
								errors={errors}
								type="password"
								label="Confirm New Password"
								name="confirmPassword"
							/>
						</div>
						<div className="flex justify-center ">
							<Button
								disabled={confirmationAction}
								onClick={e => {
									e.preventDefault
									e.stopPropagation
								}}
								type="submit"
								className="mr-2 bg-red-200 capitalize text-black"
							>
								{confirmationText}
							</Button>
							<Button onClick={() => modalHandler(false)}>No</Button>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	)
}

export default ConfirmationModal
