import React from "react"
import Icon from "./Icon"
import { twMerge } from "tailwind-merge"

export interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
	label?: string | null
	loading?: boolean
	placeholder?: string
}

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
	({ loading = false, label = "", className = "", placeholder, children, ...rest }, ref) => {
		const selectClassName = twMerge(
			"disabled:cursor-not-allowed disabled:bg-secondary-light disabled:hover:border-secondary-light px-3 py-3.5 w-84 outline-none rounded-md border border-secondary-dark hover:border-black text-black placeholder:text-secondary-dark text-md transition ease-in-out",
			className,
		)
		return (
			<>
				{label && <label className="text-dark-alt">{label}</label>}
				{loading ? (
					<div className={`${selectClassName} flex w-full items-center justify-center bg-white`}>
						<Icon name="spinner" size={15} color="black" className="ml-auto animate-spin" />
					</div>
				) : (
					<>
						<select className={selectClassName} placeholder={placeholder} ref={ref} {...rest}>
							{children}
						</select>
					</>
				)}
			</>
		)
	},
)

export default Select
