import { removeWhiteSpaces } from "config/utils"
import { VALIDATION_REGEX } from "config/constants"
import * as Yup from "yup"

export const locationContactDetailsInitialValues = { name: "", designation: "", phone: "" }
const locationDetailsInitialValues = {
	sin: "",
	branch: "",
	customer: "",
	name: "",
	shortName: "",
	telephone: "",
	postalCode: "",
	addressLine1: "",
	addressLine2: "",
	city: "",
	county: "",
	thumbnail: "",
	contactDetails: [],
	notes: "",
	clientSin: "",
}
const locationDetailsValidationSchema = Yup.object().shape(
	{
		branch: Yup.string().required().label("Branch"),
		customer: Yup.string().required().label("Customer"),
		name: Yup.string().required().label("Full Name"),
		shortName: Yup.string().required().label("Shortname"),
		telephone: Yup.string()
			.label("Telephone")
			.nullable()
			.transform(value => (value === "" ? undefined : value))
			.matches(VALIDATION_REGEX.phone, "Invalid Telephone Number"),
		postalCode: Yup.string()
			.label("Postal code")
			.nullable()
			.notRequired()
			.transform(value => removeWhiteSpaces(value))
			.when("postalCode", {
				is: (value: any) => value?.length,
				then: rule => rule.matches(VALIDATION_REGEX.postalCode, "Invalid Postal Code"),
			}),

		addressLine1: Yup.string().optional().label("Address Line 1"),
		addressLine2: Yup.string().label("Address Line 2"),
		city: Yup.string()
			.label("City")
			.nullable()
			.transform(value => (value === "" ? undefined : value))
			.min(3),
		county: Yup.string()
			.label("County")
			.nullable()
			.transform(value => (value === "" ? undefined : value))
			.min(3),
		thumbnail: Yup.string(),
		contactDetails: Yup.array().of(
			Yup.object().shape({
				name: Yup.string().label("Contact Name"),
				designation: Yup.string().label("Designation"),
				phone: Yup.string().label("Phone Number").matches(VALIDATION_REGEX.phone, "Invalid Phone Number"),
			}),
		),
		notes: Yup.string().label("Notes"),
		clientSin: Yup.string().optional().label("Client Sin"),
	},
	[["postalCode", "postalCode"]],
)

export { locationDetailsInitialValues, locationDetailsValidationSchema }
