import React, { useMemo } from "react"
// import { Avatar, Button, Section } from "components"
import {
	//  useAppDispatch,
	useAppSelector,
} from "hooks"
import {
	//  locationAddingEventClicked,
	selectLocationById,
} from "store/Location/locationDetails.slice"
// import { addPathToDocument } from "../../config/utils"
import {
	selectSelectedLocation,
	// setSelectedLocation
} from "store/globalState.slice"
import { ConfigProvider, Statistic } from "antd-v5"

const LocationTopBar: React.FC = () => {
	const selected = useAppSelector(selectSelectedLocation)

	const location = useAppSelector(selectLocationById(selected))

	const data: { title: string; value: string | undefined; className?: string }[] = useMemo(() => {
		return [
			{ title: "SIN", value: location?.sin },
			{ title: "Client SIN", value: location?.clientSin },
			{ title: "Shortname", value: location?.shortName },
			{ title: "Branch", value: location?.branch?.name },
			{ title: "Customer", value: location?.customer?.name, className: "col-span-2" },
			// { title: "Name", value: location?.name },
			{ title: "Postal Code", value: location?.postalCode },
			{ title: "Address Line 1", value: location?.addressLine1 },
			{ title: "City", value: location?.city },
			// { title: "Telephone", value: location?.telephone },
			// { title: "Address Line 2", value: location?.addressLine2 },
			// { title: "County", value: location?.county?.name },
		]
	}, [location])

	return (
		<ConfigProvider
			prefixCls="ant5"
			theme={{
				components: {
					Statistic: {
						titleFontSize: 12,
						contentFontSize: 12,
						marginXXS: 0,
					},
				},
			}}
		>
			<div className="grid grid-cols-9">
				{data.map(({ title, value, className = "col-span-1" }) => (
					<Statistic
						key={title}
						title={title}
						value={value || "N/A"}
						className={className}
						formatter={val => val}
					/>
				))}
			</div>
		</ConfigProvider>
	)
}

export default LocationTopBar
