import { checkIfTextExists, sortByProperty, updateState } from "config/utils"
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetEmployeeInterface, CreateEmployeeInterface, ActiveEmployeeDetailsInterface } from "@type/employees.types"
import { employeesService } from "services"
import { AppThunk, RootState } from "store"
import { lowerCase } from "lodash"

export interface EmployeeStateInterface {
	list: GetEmployeeInterface[]
	loading: boolean
	saving: boolean
	newDetail: null | GetEmployeeInterface
}

const initialState: EmployeeStateInterface = {
	list: [],
	loading: false,
	saving: false,
	newDetail: null,
}

const detailSlice = createSlice({
	name: "detail",
	initialState,
	reducers: {
		requestEmployees: employee => {
			employee.loading = true
		},
		receiveEmployees: (employee, action: PayloadAction<GetEmployeeInterface[]>) => {
			employee.loading = false
			employee.list = action.payload.sort((a, b) =>
				String(`${a.firstName} ${a.lastName}`).localeCompare(`${b.firstName} ${b.lastName}`),
			)
		},
		receiveEmployeesFailed: employee => {
			employee.loading = false
		},
		requestSavingEmployee: employeeData => {
			employeeData.saving = true
		},
		savingEmployeeSuccess: (employee, action: PayloadAction<GetEmployeeInterface>) => {
			employee.saving = false
			const Updated = employee.list?.find(e => e._id === action.payload._id) as GetEmployeeInterface
			const [UpdatedValue] = updateState(employee.list, { ...Updated, ...action.payload }, "_id")
			if (!action.payload.shouldSelect) employee.newDetail = action.payload

			employee.list = UpdatedValue.sort((a, b) =>
				String(`${a.firstName} ${a.lastName}`).localeCompare(`${b.firstName} ${b.lastName}`),
			)
		},
		updateEmployeeByEmployeeIdAndIsActive: (
			employee,
			action: PayloadAction<{ employeeId: string; isActive: boolean }>,
		) => {
			employee.saving = false
			const Updated = employee.list?.find(e => e._id === action.payload.employeeId) as GetEmployeeInterface
			const [UpdatedValue] = updateState(employee.list, { ...Updated, isActive: action.payload.isActive }, "_id")

			employee.list = UpdatedValue.sort((a, b) =>
				String(`${a.firstName} ${a.lastName}`).localeCompare(`${b.firstName} ${b.lastName}`),
			)
		},
		savingEmployeeSuccessFail: employee => {
			employee.saving = false
		},
		ClearNewDetail: employee => {
			employee.newDetail = null
		},
		ClearEmployeeList: employee => {
			employee.list = []
		},
	},
})

//REDUCER
export default detailSlice.reducer

//ACTIONS
export const {
	requestEmployees,
	receiveEmployees,
	receiveEmployeesFailed,
	requestSavingEmployee,
	savingEmployeeSuccess,
	savingEmployeeSuccessFail,
	ClearNewDetail,
	ClearEmployeeList,
	updateEmployeeByEmployeeIdAndIsActive,
} = detailSlice.actions

const getEmployees = (): AppThunk => async dispatch => {
	try {
		dispatch(requestEmployees())
		const { data: employeeResponse } = await employeesService.getAllEmployees()
		dispatch(receiveEmployees(employeeResponse))
	} catch (error) {
		dispatch(receiveEmployeesFailed())
	}
}

const getEmployeesByBranchId =
	(ids: ActiveEmployeeDetailsInterface): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestEmployees())
			const { data: employeeResponse } = await employeesService.getActiveEmployeeByBranchId(ids)
			dispatch(receiveEmployees(employeeResponse))
		} catch (error) {
			dispatch(receiveEmployeesFailed())
		}
	}
const saveEmployee =
	(
		employeeData: Partial<GetEmployeeInterface>,
		cb: ({ branch, supplier }: { branch?: string; supplier?: string }) => void,
	): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(requestSavingEmployee())
			if (employeeData._id) {
				data = await employeesService.updateEmployee(employeeData._id, employeeData)
			} else {
				data = await employeesService.createEmployee(employeeData as CreateEmployeeInterface)
			}
			const { data: employeeResponse } = data
			dispatch(savingEmployeeSuccess(employeeResponse))
			cb &&
				cb(
					employeeResponse?.branch
						? { branch: employeeResponse?.branch }
						: { supplier: employeeResponse?.supplier },
				)
		} catch (error) {
			dispatch(savingEmployeeSuccessFail())
		}
	}

export { getEmployees, saveEmployee, getEmployeesByBranchId }

//SELECTORS
const selectEmployeeState = (state: RootState) => state.employee.details
const isEmployeeLoading = () => (state: RootState) => selectEmployeeState(state).loading
const isEmployeeSaving = () => (state: RootState) => selectEmployeeState(state).saving
const selectEmployeeList = () => (state: RootState) => selectEmployeeState(state).list
const selectLoadingEmployee = () => (state: RootState) => selectEmployeeState(state).loading
const selectNewEmployeeDetailAdded = () => (state: RootState) => selectEmployeeState(state).newDetail
const selectEmployeeById = (employeeId: string | null) => (state: RootState) =>
	selectEmployeeList()(state)?.find(employee => employee._id === employeeId)

const selectEmployeeListByBranch = (branchId: string | null) => {
	return createSelector(
		(state: RootState) => state.employee.details.list,
		employeeList => (branchId ? employeeList?.filter(i => i.branch === branchId) : employeeList),
	)
}

const selectEmployeeByEmployeeId = (employeeId: string) =>
	createSelector(
		(state: RootState) => state.employee.details.list,
		employeeList => {
			return employeeList?.find(i => i._id === employeeId)
		},
	)

const selectEmployeeListByField = (fieldName: string, fieldValue: string) => {
	return createSelector(
		(state: RootState) => state.employee.details.list,
		employeeList =>
			lowerCase(fieldName) && fieldValue
				? employeeList?.filter(
						item => item?.[lowerCase(fieldName) as keyof GetEmployeeInterface] === fieldValue,
				  )
				: [],
	)
}

const selectSelectedEmployeeData = createSelector(
	(state: RootState) => ({
		selectedEmployee: state.globalState.selectedEmployee,
		employeeList: state.employee.details.list,
	}),
	({ selectedEmployee = "", employeeList }) => employeeList.find(({ _id }) => _id === selectedEmployee),
)

const selectEmployeeName = (employeeId: string) =>
	createSelector(
		(state: RootState) => state.employee.details.list,
		employeeList => {
			const res = employeeList?.find(i => i._id === employeeId)
			return `${res?.firstName} ${res?.lastName} `
		},
	)

const selectEmployeeListSorted = () =>
	createSelector(
		(state: RootState) => state.employee.details.list,
		employeeList => {
			return employeeList
				? [...employeeList]?.sort((a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()))
				: []
		},
	)

const filterEmployeeByBranchOrSupplierName = (filterId: string, filterFrom: string, shouldSelectActiveOnly = true) =>
	createSelector(
		(state: RootState) => state.employee.details.list,
		employeeList => {
			if (!employeeList) return []

			let filteredData = employeeList

			if (filterId && !["Select Branch", "Select Supplier"].includes(filterId)) {
				filteredData = employeeList.filter(
					employee => employee[filterFrom as keyof CreateEmployeeInterface] === filterId,
				)
			}
			if (shouldSelectActiveOnly) {
				filteredData = filteredData.filter(employee => !!employee.isActive)
			}

			return filteredData
				.map(filteredEmployee => ({
					label: `${filteredEmployee.shortName}`,
					value: filteredEmployee._id,
				}))
				.sort(sortByProperty("label"))
		},
	)

const employeeListByBranchOrSupplierName = (filterId: string, filterFrom: string, shouldSelectActiveOnly = true) =>
	createSelector(
		(state: RootState) => state.employee.details.list,
		employeeList => {
			if (!employeeList) return []

			let filteredData = employeeList

			if (filterId && !["Select Branch", "Select Supplier"].includes(filterId)) {
				filteredData = employeeList.filter(
					employee => employee[filterFrom as keyof CreateEmployeeInterface] === filterId,
				)
			}
			if (shouldSelectActiveOnly) {
				filteredData = filteredData.filter(employee => !!employee.isActive)
			}

			return filteredData.sort(sortByProperty("firstName"))
		},
	)

const employeeListByMultipleBranchOrSupplierName = (
	filterId: string[],
	filterFrom: "branch" | "supplier" | "",
	shouldSelectActiveOnly = true,
) =>
	createSelector(
		(state: RootState) => state.employee.details.list,
		employeeList => {
			if (!employeeList) return []

			let filteredData = employeeList

			if (filterId.length && filterFrom) {
				filteredData = employeeList.filter(employee => filterId.includes(employee[filterFrom]))
			} else {
				filteredData = employeeList
			}
			if (shouldSelectActiveOnly) {
				filteredData = filteredData.filter(employee => !!employee.isActive)
			}

			return filteredData.sort(sortByProperty("firstName"))
		},
	)

const selectEmployeeDropDown = () =>
	createSelector(
		(state: RootState) => state.employee.details.list,
		employeeList =>
			employeeList ? employeeList?.map(i => ({ _id: i._id, name: `${i.firstName} ${i.lastName}` })) : [],
	)

const filteredListBySearch = (
	searchOn: string,
	searchOnFields: string[],
	isActive: boolean,
	branchId?: string,
	supplierId?: string,
) => {
	return createSelector(
		(state: RootState) => state.employee.details.list,
		(employeeList: any) => {
			return checkIfTextExists(
				employeeList
					?.filter((i: any) => {
						if (branchId && !!branchId) {
							if (isActive) return branchId === i.branch && isActive === i.isActive
							else return branchId === i.branch
						} else if (supplierId && !!supplierId) {
							if (isActive) return supplierId === i.supplier && isActive === i.isActive
							else return supplierId === i.supplier
						}
					})
					.sort((a: any, b: any) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase())),
				searchOn,
				searchOnFields,
			)
		},
	)
}

export {
	selectEmployeeByEmployeeId,
	filteredListBySearch,
	selectEmployeeListSorted,
	selectEmployeeName,
	selectEmployeeListByBranch,
	selectEmployeeList,
	selectEmployeeState,
	selectLoadingEmployee,
	selectEmployeeById,
	isEmployeeLoading,
	isEmployeeSaving,
	selectNewEmployeeDetailAdded,
	selectEmployeeDropDown,
	selectEmployeeListByField,
	filterEmployeeByBranchOrSupplierName,
	employeeListByBranchOrSupplierName,
	employeeListByMultipleBranchOrSupplierName,
	selectSelectedEmployeeData,
}
