import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { AppThunk, RootState } from ".."
import JAFService from "services/jaf.service"

interface JafStateInterface {
	list: { _id: string; formInfo: any }[]
	loading: boolean
	saving: boolean
	handling: boolean
}

const initialState: JafStateInterface = {
	list: [],
	loading: false,
	saving: false,
	handling: false,
}

const jafSlice = createSlice({
	name: "jaf",
	initialState,
	reducers: {
		fetchingJafDetails: state => {
			state.loading = true
		},
		fetchedJafDetails: (state, action: PayloadAction<[]>) => {
			state.loading = false
			state.list = action.payload
		},
		fetchingJafDetailsFailed: state => {
			state.loading = false
		},
		savingJafDetails: state => {
			state.saving = true
		},
		savingJafDetailsFailed: state => {
			state.saving = false
		},
		savedJafDetails: state => {
			state.saving = false
		},
		handlingJafDetails: state => {
			state.handling = true
		},
		handledJafDetails: (state, action: PayloadAction<{ _id: string; formInfo: any }>) => {
			state.handling = false
			const updatedArray = [...state.list]
			updatedArray.splice(
				state.list.findIndex(entity => entity._id === action.payload._id),
				1,
			)
			state.list = updatedArray
		},
		handlingJafDetailsFailed: state => {
			state.handling = false
		},
	},
})
export default jafSlice.reducer

export const {
	fetchingJafDetails,
	fetchedJafDetails,
	fetchingJafDetailsFailed,
	savingJafDetails,
	savingJafDetailsFailed,
	savedJafDetails,
	handlingJafDetails,
	handledJafDetails,
	handlingJafDetailsFailed,
} = jafSlice.actions

const createJaf =
	(data: any): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingJafDetails())
			await JAFService.createJAF(data)
			dispatch(savedJafDetails())
		} catch (error) {
			dispatch(savingJafDetailsFailed())
		}
	}

const getJafDetails =
	(approved: boolean): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingJafDetails())
			const { data: response } = await JAFService.getByStatus(approved)
			dispatch(fetchedJafDetails(response))
		} catch (error) {
			dispatch(fetchingJafDetailsFailed())
		}
	}
const approveJaf =
	(id: string, payload: any): AppThunk =>
	async dispatch => {
		try {
			dispatch(handlingJafDetails())
			const { data: response } = await JAFService.handleApprove(id, payload)
			dispatch(handledJafDetails(response))
		} catch (error) {
			dispatch(handlingJafDetailsFailed())
		}
	}

export { getJafDetails, approveJaf, createJaf }

const selectJafState = (state: RootState) => state.jaf
const selectJafList = () => (state: RootState) => selectJafState(state).list
const isJafLoading = () => (state: RootState) => selectJafState(state).loading
const isJafHandling = () => (state: RootState) => selectJafState(state).handling
const isJafSaving = () => (state: RootState) => selectJafState(state).saving

export { selectJafList, isJafLoading, isJafHandling, isJafSaving }
