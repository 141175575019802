import axios from "axios"
import { CreateTimeCaptureInterface, GetTimeCaptureInterface } from "@type/locations.types"

const ROOT_PATH = "locations/time-capture"
export default class LocationTimeCaptureService {
	static createLocationTimeCapture = (timeCapture: CreateTimeCaptureInterface) =>
		axios.post<CreateTimeCaptureInterface>(ROOT_PATH, timeCapture)

	static createLocationCalls = (callDetails: any) => axios.post<any>(`${ROOT_PATH}-calls`, callDetails)

	static getLocationTimeCapture = (postId: string) => axios.get<GetTimeCaptureInterface>(`${ROOT_PATH}/${postId}`)

	static updateLocationTimeCapture = (timeCaptureId: string, timeCapture: Partial<GetTimeCaptureInterface>) =>
		axios.put<GetTimeCaptureInterface>(`${ROOT_PATH}/${timeCaptureId}`, timeCapture)
}
