import React from "react"
import useFeatureFlags from "hooks/useFeatureFlags"

interface FeatureFlagWrapperProps {
	name: string
	children: React.ReactElement
}

const FeatureFlagWrapper: React.FC<FeatureFlagWrapperProps> = ({ name, children }) => {
	const { featureFlagEnabled } = useFeatureFlags()
	if (!featureFlagEnabled(name)) return null
	return children
}

export default FeatureFlagWrapper
