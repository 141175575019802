import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetNotifieeInterface, NotifieeInterface, UpdateNotifieeInterface } from "@type/organization.types"
import { notifieesService } from "services"
import { AppThunk, RootState } from "store"
import { sortByProperty, sortDeepCopyByProperty, updateState } from "config/utils"
export interface NotifieesStateInterface {
	list: null | Array<GetNotifieeInterface>
	loading: boolean
	saving: boolean
}

const initialState: NotifieesStateInterface = {
	list: null,
	loading: false,
	saving: false,
}

const notifieesSlice = createSlice({
	name: "notifiees",
	initialState,
	reducers: {
		fetchingNotifiees: notifiee => {
			notifiee.loading = true
		},
		notifieesFetched: (notifiee, action: PayloadAction<GetNotifieeInterface[]>) => {
			notifiee.loading = false
			notifiee.list = action.payload
		},
		notifieesFetchingFailed: notifiee => {
			notifiee.loading = false
		},
		savingNotifiee: notifieeData => {
			notifieeData.saving = true
		},
		notifieeSaved: (notifiee, action: PayloadAction<GetNotifieeInterface>) => {
			notifiee.saving = false
			const [UpdatedValue] = updateState(notifiee.list, action.payload, "_id")
			notifiee.list = UpdatedValue
		},
		notifieeSavingFailed: notifiee => {
			notifiee.saving = false
		},
	},
})

//REDUCER
export default notifieesSlice.reducer

//ACTIONS
const {
	fetchingNotifiees,
	notifieesFetched,
	notifieesFetchingFailed,
	savingNotifiee,
	notifieeSaved,
	notifieeSavingFailed,
} = notifieesSlice.actions

const getNotifiees =
	(cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingNotifiees())
			const { data: notifieeResponse } = await notifieesService.getAllNotifiees()
			notifieeResponse.sort(sortByProperty("recipient"))
			cb && cb(notifieeResponse[0]?._id)
			dispatch(notifieesFetched(notifieeResponse))
		} catch (error) {
			dispatch(notifieesFetchingFailed())
		}
	}

const saveNotifiee =
	(notifieeData: Partial<UpdateNotifieeInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingNotifiee())
			if (notifieeData?._id) data = await notifieesService.updateNotifiee(notifieeData._id, notifieeData)
			else data = await notifieesService.createNotifiee(notifieeData as NotifieeInterface)
			const { data: notifieeResponse } = data
			cb(notifieeResponse._id)
			dispatch(notifieeSaved(notifieeResponse))
		} catch (error) {
			dispatch(notifieeSavingFailed())
		}
	}

export { getNotifiees, saveNotifiee }

//SELECTORS
const selectNotifieeState = (state: RootState) => state.organization.notifiees
const isNotifieeLoading = () => (state: RootState) => selectNotifieeState(state).loading
const isNotifieeSaving = () => (state: RootState) => selectNotifieeState(state).saving
const selectNotifieeList = createSelector(
	(state: RootState) => state.organization.notifiees.list,
	list => {
		return sortDeepCopyByProperty(list, "recipient") as GetNotifieeInterface[]
	},
)

const selectNotifieeById = (notifieeId: string | null) => (state: RootState) =>
	state.organization.notifiees?.list?.find(notifiee => notifiee._id === notifieeId)

export { selectNotifieeList, selectNotifieeState, selectNotifieeById, isNotifieeLoading, isNotifieeSaving }
