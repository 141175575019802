import axios from "axios"
import { EmployeeSicknessInterface } from "@type/employees.types"

const ROOT_PATH = "employees/sickness"
export default class EmployeeSicknessService {
	static createEmployeeSickness = (Data: EmployeeSicknessInterface) => axios.post(`${ROOT_PATH}`, Data)

	static updateEmployeeSickness = (id: string, sicknessData: EmployeeSicknessInterface) =>
		axios.put(`${ROOT_PATH}/${id}`, sicknessData)

	static getEmployeeSickness = (employeeId: string) => axios.get(`${ROOT_PATH}/${employeeId}`)

	static getEmployeeSicknessByEmployeeServiceId = (serviceId: string) =>
		axios.get<EmployeeSicknessInterface>(`${ROOT_PATH}/${serviceId}`)
}
