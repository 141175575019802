import React, { useContext, useEffect } from "react"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AntdButton, FormInputAntd } from "components"
import { useAppDispatch } from "hooks"
import { getBankDetailByEmployeeId, saveBankDetailData } from "store/Employee/BankDetail/bankDetail.slice"
import EmployeeBankContext, { BankDetailContext } from "./AdditionalDetailContext"
import { CreateEmployeeBankDetailInterface } from "@type/employees.types"
import { bankDetailsValidationSchema, bankDetailsInitialValues } from "Schema"
import { AllowedTo } from "react-abac"
import { Permissions } from "config/constants"
import { SelectedPropInterface } from "services/generic/type"
import { Card } from "antd"

const BankDetail: React.FC<SelectedPropInterface> = ({ selected }) => {
	const dispatch = useAppDispatch()
	const { bankDetail, handleBankDetailHandler } = useContext(EmployeeBankContext) as BankDetailContext

	const methods = useForm<CreateEmployeeBankDetailInterface>({
		resolver: yupResolver(bankDetailsValidationSchema),
	})
	const {
		handleSubmit,
		formState: { isDirty, isSubmitting },
		reset,
	} = methods
	useEffect(() => {
		if (bankDetail) reset(bankDetail)
	}, [bankDetail])

	useEffect(() => {
		if (selected) {
			clearState()
			dispatch(getBankDetailByEmployeeId(selected as string))
		}
	}, [selected])

	const onSubmit: SubmitHandler<CreateEmployeeBankDetailInterface> = data =>
		dispatch(saveBankDetailData({ ...data, employee: selected }))

	const clearState = () => {
		reset(bankDetailsInitialValues)
		handleBankDetailHandler(null)
	}

	return (
		<div>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Card size="small" title="Bank Details">
						<div className="space-y-2">
							<div className="grid grid-cols-2 gap-x-5">
								<FormInputAntd type="text" name="accountNumber" label="Account Number" />
								<FormInputAntd type="text" name="bankName" label="Bank Name" />
								<FormInputAntd type="text" name="accountTitle" label="Account Title" />
								<FormInputAntd type="text" name="sortCode" label="Sort Code" />
							</div>
							<AllowedTo perform={Permissions.UPDATE_EMPLOYEE_ADDITIONAL_DETAILS}>
								<div className="flex space-x-2">
									<AntdButton
										onClick={e => {
											e.preventDefault()
											clearState()
										}}
									>
										Clear
									</AntdButton>
									<AntdButton disabled={!isDirty || isSubmitting} htmlType="submit">
										{bankDetail ? "Update" : "Save"}
									</AntdButton>
								</div>
							</AllowedTo>
						</div>
					</Card>
				</form>
			</FormProvider>
		</div>
	)
}

export default BankDetail
