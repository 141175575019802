import React, { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "hooks"

import { AntdButton, GlobalSidebar } from "components"
import BranchForm from "./BranchForm"

import {
	ClearBranch,
	filteredListBySearch,
	getBranches,
	selectBranchById,
	selectBranchIsLoading,
	selectNewBranchAdded,
} from "store/Branches/branch.slice"
import { getPayGroup } from "store/PayGroups/payGroup.slice"
import BranchSideBar from "./BranchSideBar"
import { getPBCodes } from "store/PayBill/PBCodes.slice"
import { PBCodeOptions } from "config/constants"
import { getPublicHolidays } from "store/PayBill/publicHolidays.slice"
import { getSDCounties } from "store/StaticData/SDCounties.slice"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"
import { getSDQualifications } from "store/StaticData/SDQualifications.slice"

let searchString1 = ""
let timeoutId: NodeJS.Timeout

const Index: React.FC = () => {
	const dispatch = useAppDispatch()

	const [selected, setSelected] = useState<string | null>("")
	const [branchSelection, setBranchSelection] = useState<string>("")
	const [searchString, setSearchString] = useState("")
	const [searchOnFields] = useState(["name"])
	const [addBranch, setAddBranch] = useState(false)

	const branchesLoading = useAppSelector(selectBranchIsLoading())
	const branch = useAppSelector(selectBranchById(selected))
	const filteredList = useAppSelector(filteredListBySearch(searchString, searchOnFields))
	const newBranch = useAppSelector(selectNewBranchAdded())

	useEffect(() => {
		dispatch(getBranches())
		dispatch(getSDQualifications())
	}, [])

	useEffect(() => {
		if (filteredList?.length) {
			if (selected == null && !addBranch) {
				handleSelected(filteredList[0]._id || selected)
				setAddBranch(true)
			}
		} else if (selected !== null) {
			handleSelected(null)
			setAddBranch(false)
		}
	}, [filteredList])

	useEffect(() => {
		if (newBranch?._id) {
			handleSelected(newBranch._id)
			newBranch && dispatch(ClearBranch())
		}
	}, [newBranch?._id])

	useEffect(() => {
		dispatch(getPayGroup())
		dispatch(getPBCodes(PBCodeOptions.BRANCH))
		dispatch(getPublicHolidays())
		dispatch(getSDCounties())
	}, [])

	const handleSelected = (branchId: string | null): void => setSelected(branchId as string)

	const handleAddBranch = (e: React.MouseEvent) => {
		e.preventDefault()
		setSelected("")
	}
	const createTimeout = (time: number) => {
		return setTimeout(() => {
			setBranchSelection("")
			searchString1 = ""
		}, time)
	}

	const onKeyUpHandler = (e: any) => {
		if (timeoutId) clearTimeout(timeoutId)
		searchString1 += e.key
		setBranchSelection(prev => {
			let prev1 = prev
			prev1 += e.key

			return prev1
		})
		const filteredBranches = filteredList?.filter(({ name }: any) =>
			`${name}`.toLowerCase().includes(searchString1.toLowerCase()),
		)
		if (filteredBranches?.length) {
			setSelected(filteredBranches[0]._id)
		}
		timeoutId = createTimeout(500)
	}

	return (
		<div>
			<GlobalSidebar>
				<BranchSideBar
					setSearchString={setSearchString}
					searchText={branchSelection}
					branches={filteredList}
					selected={selected}
					loading={branchesLoading}
					handleSelected={handleSelected}
					onKeyUpCapture={onKeyUpHandler}
				/>
			</GlobalSidebar>

			<div className="flex flex-col">
				<div className="flex justify-between">
					<AuditTrail id={branch?._id} name={Entities.BRANCHES} />
					<AntdButton className="ml-auto" onClick={handleAddBranch}>
						Add branch
					</AntdButton>
				</div>
				<BranchForm branch={branch} />
			</div>
		</div>
	)
}

export default Index
