import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit"
import { CreateLocationQRCallInterface, GetLocationCallTimeInterface } from "@type/locations.types"
import { locationQRCall } from "services"
import { AppThunk, RootState } from "store"

export interface LocationQRCallStateInterface {
	list: null | Array<GetLocationCallTimeInterface>
	loading: boolean
	saving: boolean
	deleting: boolean
}

const initialState: LocationQRCallStateInterface = {
	list: null,
	loading: false,
	saving: false,
	deleting: false,
}

const locationQRCallSlice = createSlice({
	name: "locationQRCall",
	initialState,
	reducers: {
		clearQRCallList: locationQRCall => {
			locationQRCall.list = null
		},
		fetchingLocationQRCall: locationQRCall => {
			locationQRCall.loading = true
		},
		deletingLocationQRCall: locationQRCall => {
			locationQRCall.deleting = true
		},
		locationQRCallFetched: (locationQRCall, action: PayloadAction<GetLocationCallTimeInterface[]>) => {
			locationQRCall.loading = false
			locationQRCall.list = action.payload
		},
		locationQRCallFetchingFailed: locationQRCall => {
			locationQRCall.loading = false
		},
		savingLocationQRCall: locationQRCallData => {
			locationQRCallData.saving = true
		},
		locationQRCallDeleted: (locationQRCall, action: PayloadAction<string[]>) => {
			locationQRCall.deleting = false
			locationQRCall.list = locationQRCall.list?.filter(item => !action.payload.includes(item._id)) || []
		},
		locationQRCallSaved: (locationQRCall, action: PayloadAction<GetLocationCallTimeInterface[]>) => {
			locationQRCall.saving = false
			locationQRCall.list?.push(...action.payload)
		},
		locationQRCallSavingFailed: locationQRCall => {
			locationQRCall.saving = false
		},
		locationQRCallDeletingFailed: locationQRCall => {
			locationQRCall.deleting = false
		},
	},
})

//REDUCER
export default locationQRCallSlice.reducer

//ACTIONS
export const {
	clearQRCallList,
	fetchingLocationQRCall,
	locationQRCallFetched,
	locationQRCallFetchingFailed,
	savingLocationQRCall,
	locationQRCallSaved,
	locationQRCallSavingFailed,
	locationQRCallDeleted,
	deletingLocationQRCall,
	locationQRCallDeletingFailed,
} = locationQRCallSlice.actions

const getLocationQRCall =
	(postId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingLocationQRCall())
			const { data: locationQRCallResponse } = await locationQRCall.getPostQRCalls(postId)
			dispatch(locationQRCallFetched(locationQRCallResponse))
		} catch (error) {
			dispatch(locationQRCallFetchingFailed())
		}
	}

const removeQRCalls =
	(qrCallIds: string[], cb: () => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(deletingLocationQRCall())
			await locationQRCall.deleteQRCalls(qrCallIds)
			dispatch(locationQRCallDeleted(qrCallIds))
			cb()
		} catch (error) {
			dispatch(locationQRCallDeletingFailed())
		}
	}

const saveLocationQRCalls =
	(locationQRCallData: CreateLocationQRCallInterface, cb: () => void = () => null): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingLocationQRCall())
			const { data: locationQRCallResponse } = await locationQRCall.createQRCall(locationQRCallData)
			dispatch(locationQRCallSaved(locationQRCallResponse))
			cb()
		} catch (error) {
			dispatch(locationQRCallSavingFailed())
		}
	}

export { getLocationQRCall, saveLocationQRCalls, removeQRCalls }

//SELECTORS
const selectLocationQRCallState = (state: RootState) => state.location.qrCall
const selectLocationQRCallList = (state: RootState) => selectLocationQRCallState(state).list
const isLoadingLocationQRCall = (state: RootState) => selectLocationQRCallState(state).loading
const isSavingLocationQRCall = (state: RootState) => selectLocationQRCallState(state).saving
const isDeletingLocationQRCall = (state: RootState) => selectLocationQRCallState(state).deleting
const selectLocationQRCallListWithLabel = (state: RootState) =>
	selectLocationQRCallState(state).list?.map(item => ({ ...item, label: "QR" }))

const selectLocationQRCallColumnLabels = createSelector(
	(state: RootState) => state.location.qrCall.list,
	list =>
		[...new Set(list?.map(item => item.time))].sort(
			(a, b) => new Date("1970/01/01 " + a).valueOf() - new Date("1970/01/01 " + b).valueOf(),
		),
)

export {
	selectLocationQRCallList,
	selectLocationQRCallListWithLabel,
	selectLocationQRCallState,
	isSavingLocationQRCall,
	isLoadingLocationQRCall,
	isDeletingLocationQRCall,
	selectLocationQRCallColumnLabels,
}
