import axios from "axios"
import { EmployeeHolidayInterface } from "@type/employees.types"

const ROOT_PATH = "employees/holiday"

export default class EmployeesHolidaysService {
	static createEmployeeHoliday = (employeeHoliday: EmployeeHolidayInterface) =>
		axios.post<EmployeeHolidayInterface>(ROOT_PATH, employeeHoliday)

	static getEmployeesHoliday = (employeeId: string) =>
		axios.get<EmployeeHolidayInterface>(`${ROOT_PATH}/${employeeId}`)

	static updateEmployeeHoliday = (id: string, holidayData: EmployeeHolidayInterface) =>
		axios.put<EmployeeHolidayInterface>(`${ROOT_PATH}/${id}`, holidayData)

	static getHolidayByService = (employeeHolidayByService: string) =>
		axios.get<EmployeeHolidayInterface>(`${ROOT_PATH}/${employeeHolidayByService}`)
}
