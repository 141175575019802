import { AntdButton, AntdSelect1, Icon } from "components/index"
import useAppSelector from "hooks/useAppSelector"
import { getBranches, selectBranchesMappedAsOptions } from "store/Branches/branch.slice"
import { suppliersDropdownList } from "store/StaticData/SDSuppliers.slice"
import { getCustomers, selectCustomerOptions } from "store/Customers/customers.slice"
import { Drawer, Switch } from "antd"
import { theme } from "config/index"
import { ShiftFiltersInterface } from "@type/workMonitor.types"
import useAppDispatch from "hooks/useAppDispatch"
import { clearShiftFilters, saveShiftFilters, selectShiftFilter } from "store/workMonitor/workMonitor.slice"
import { useEffect, useState } from "react"
import { Divider } from "antd-v5"

interface Props {
	openShiftFilterDrawer: boolean
	setOpenShiftFilterDrawer: (val: boolean) => void
}

const removeFalsyValuesFromObject = (obj: ShiftFiltersInterface | null) => {
	if (obj) {
		return Object.keys(obj).reduce((acc: ShiftFiltersInterface | null, curr) => {
			if (obj[curr as keyof ShiftFiltersInterface]) {
				if (!acc) acc = { [curr]: obj[curr as keyof ShiftFiltersInterface] }
				else (acc as any)[curr] = obj[curr as keyof ShiftFiltersInterface]
			}
			return acc
		}, null)
	}
	return null
}

const FilterShiftDrawer: React.FC<Props> = ({ openShiftFilterDrawer, setOpenShiftFilterDrawer }) => {
	const dispatch = useAppDispatch()
	const branchesOptions = useAppSelector(selectBranchesMappedAsOptions())
	const supplierOptions = useAppSelector(suppliersDropdownList)
	const customersOptions = useAppSelector(selectCustomerOptions)
	const globalShiftFilter = useAppSelector(selectShiftFilter())
	const [shiftFilter, setShiftFilter] = useState<ShiftFiltersInterface | null>(null)

	const handleSubmit = (payload: ShiftFiltersInterface | null) => {
		if (payload) {
			const updatedPayload = removeFalsyValuesFromObject(payload)
			if (updatedPayload) dispatch(saveShiftFilters(payload))
			else dispatch(clearShiftFilters())
		} else dispatch(clearShiftFilters())

		setOpenShiftFilterDrawer(false)
	}

	useEffect(() => {
		dispatch(getBranches(false))
		dispatch(getCustomers())
	}, [])

	useEffect(() => {
		setShiftFilter(globalShiftFilter)
	}, [globalShiftFilter])

	return (
		<Drawer
			title="Filter Shifts"
			width={"30%"}
			placement="right"
			onClose={() => setOpenShiftFilterDrawer(false)}
			open={openShiftFilterDrawer}
			rootStyle={{
				color: "rgb(229 229 229);",
			}}
			headerStyle={{
				background: "#1f2937",
			}}
			bodyStyle={{ background: theme.colors.background }}
			closeIcon={<Icon name="cross" color="white" size={18} />}
		>
			<div className="grid grid-cols-2 gap-y-3 gap-x-5 ">
				<AntdSelect1
					value={shiftFilter?.employeeBranch || []}
					allowClear={true}
					label="Employee's Branch"
					placeholder="Filter By Employee's Branch"
					onChange={e => {
						if (Array.isArray(e)) {
							if (e.length) {
								setShiftFilter(prev => ({
									...prev,
									employeeBranch: e,
								}))
							} else {
								setShiftFilter(prev => {
									const updatedState = { ...prev }
									if (updatedState?.employeeBranch) delete updatedState?.employeeBranch
									return updatedState
								})
							}
						}
					}}
					mode="multiple"
					options={branchesOptions}
				/>

				<AntdSelect1
					value={shiftFilter?.supplier || []}
					allowClear={true}
					label="Employee's Supplier"
					placeholder="Filter By Employee's Supplier"
					onChange={e => {
						if (Array.isArray(e)) {
							if (e.length) {
								setShiftFilter(prev => ({
									...prev,
									supplier: e,
								}))
							} else {
								setShiftFilter(prev => {
									const updatedState = { ...prev }
									if (updatedState?.supplier) delete updatedState?.supplier
									return updatedState
								})
							}
						}
					}}
					mode={"multiple"}
					options={supplierOptions}
				/>

				<AntdSelect1
					value={shiftFilter?.locationBranch || []}
					label="Location's Branch"
					allowClear={true}
					placeholder="Filter By Location's Branch"
					onChange={e => {
						if (Array.isArray(e)) {
							if (e.length) {
								setShiftFilter(prev => ({
									...prev,
									locationBranch: e,
								}))
							} else {
								setShiftFilter(prev => {
									const updatedState = { ...prev }
									if (updatedState?.locationBranch) delete updatedState?.locationBranch
									return updatedState
								})
							}
						}
					}}
					mode="multiple"
					options={branchesOptions}
				/>
				<AntdSelect1
					value={shiftFilter?.customer || []}
					allowClear={true}
					label="Location's Customer"
					placeholder="Filter By Location's Customer"
					onChange={e => {
						if (Array.isArray(e)) {
							if (e.length) {
								setShiftFilter(prev => ({
									...prev,
									customer: e,
								}))
							} else {
								setShiftFilter(prev => {
									const updatedState = { ...prev }
									if (updatedState.customer) delete updatedState?.customer
									return updatedState
								})
							}
						}
					}}
					mode="multiple"
					options={customersOptions}
				/>
				<div className="flex">
					<Switch
						checked={shiftFilter?.upcomingShift ?? false}
						onChange={() => {
							setShiftFilter(prev => ({
								...prev,
								upcomingShift: !prev?.upcomingShift,
							}))
						}}
					/>
					<label className="ml-4 min-w-10">To be Chased</label>
				</div>
				<div className="flex min-w-10">
					<Switch
						checked={shiftFilter?.startingSoon ?? false}
						onChange={() => {
							setShiftFilter(prev => ({
								...prev,
								startingSoon: !prev?.startingSoon,
							}))
						}}
					/>
					<label className="ml-4">To be Booked On</label>
				</div>
				<div className="flex min-w-10">
					<Switch
						checked={shiftFilter?.endingSoon ?? false}
						onChange={() => {
							setShiftFilter(prev => ({
								...prev,
								endingSoon: !prev?.endingSoon,
							}))
						}}
					/>
					<label className="ml-4 ">To be Booked Off</label>
				</div>
			</div>
			<Divider className="bg-slate-300" style={{ margin: "15px 0 10px 0" }} />
			<div className="flex justify-end space-x-2">
				<AntdButton className="bg-white" onClick={() => setShiftFilter(null)}>
					Clear Filters
				</AntdButton>
				<AntdButton className="bg-white" onClick={() => handleSubmit(shiftFilter)}>
					Apply Filters
				</AntdButton>
			</div>
		</Drawer>
	)
}

export default FilterShiftDrawer
