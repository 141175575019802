import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetSDCompaniesInterface, SDCompaniesInterface, UpdateSDCompaniesInterface } from "@type/staticData.types"
import { SDCompaniesService } from "services"
import { AppThunk, RootState } from "store"
import { sortByProperty, sortDeepCopyByProperty, updateState } from "config/utils"
export interface SDCompaniesStateInterface {
	list: null | Array<GetSDCompaniesInterface>
	loading: boolean
	saving: boolean
}

const initialState: SDCompaniesStateInterface = {
	list: null,
	loading: false,
	saving: false,
}

const SDCompaniesSlice = createSlice({
	name: "SDCompanies",
	initialState,
	reducers: {
		fetchingSDCompanies: SDCompanies => {
			SDCompanies.loading = true
		},
		SDCompaniesFetched: (SDCompanies, action: PayloadAction<GetSDCompaniesInterface[]>) => {
			SDCompanies.loading = false
			SDCompanies.list = action.payload
		},
		SDCompaniesFetchingFailed: SDCompanies => {
			SDCompanies.loading = false
		},
		savingSDCompanies: SDCompaniesData => {
			SDCompaniesData.saving = true
		},
		SDCompaniesSaved: (SDCompanies, action: PayloadAction<GetSDCompaniesInterface>) => {
			SDCompanies.saving = false
			const [UpdatedValue] = updateState(SDCompanies.list, action.payload, "_id")
			SDCompanies.list = UpdatedValue
		},
		SDCompaniesSavingFailed: SDCompanies => {
			SDCompanies.saving = false
		},
	},
})

//REDUCER
export default SDCompaniesSlice.reducer

//ACTIONS
const {
	fetchingSDCompanies,
	SDCompaniesFetched,
	SDCompaniesFetchingFailed,
	savingSDCompanies,
	SDCompaniesSaved,
	SDCompaniesSavingFailed,
} = SDCompaniesSlice.actions

const getSDCompanies =
	(fetchAll?: boolean, cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingSDCompanies())
			const { data: SDCompaniesResponse } = await SDCompaniesService.getAllSDCompanies()
			SDCompaniesResponse.sort(sortByProperty("name"))
			cb && cb(SDCompaniesResponse[0]?._id)
			dispatch(SDCompaniesFetched(SDCompaniesResponse))
		} catch (error) {
			dispatch(SDCompaniesFetchingFailed())
		}
	}

const saveSDCompanies =
	(SDCompaniesData: Partial<UpdateSDCompaniesInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingSDCompanies())
			if (SDCompaniesData?._id)
				data = await SDCompaniesService.updateSDCompanies(SDCompaniesData._id, SDCompaniesData)
			else data = await SDCompaniesService.createSDCompanies(SDCompaniesData as SDCompaniesInterface)
			const { data: SDCompaniesResponse } = data
			cb && cb(SDCompaniesResponse._id)
			dispatch(SDCompaniesSaved(SDCompaniesResponse))
		} catch (error) {
			dispatch(SDCompaniesSavingFailed())
		}
	}

export { getSDCompanies, saveSDCompanies }

//SELECTORS
const selectSDCompaniesState = (state: RootState) => state.staticData.SDCompanies
const isSDCompaniesLoading = () => (state: RootState) => selectSDCompaniesState(state).loading
const isSDCompaniesSaving = () => (state: RootState) => selectSDCompaniesState(state).saving
const selectSDCompaniesList = createSelector(
	(state: RootState) => state.staticData.SDCompanies.list,
	list => {
		return sortDeepCopyByProperty(list, "name") as GetSDCompaniesInterface[]
	},
)

const selectSDCompaniesDropdownList = createSelector(
	(state: RootState) => state.staticData.SDCompanies.list,
	list => {
		return [
			{ label: "Select Company", value: "" },
			...sortDeepCopyByProperty(list?.map(item => ({ label: item.name, value: item._id })) || [], "label"),
		]
	},
)

const selectSDCompaniesById = (SDCompaniesId: string | null) => (state: RootState) =>
	state.staticData.SDCompanies?.list?.find(SDCompanies => SDCompanies._id === SDCompaniesId)

export {
	selectSDCompaniesList,
	selectSDCompaniesDropdownList,
	selectSDCompaniesState,
	selectSDCompaniesById,
	isSDCompaniesLoading,
	isSDCompaniesSaving,
}
