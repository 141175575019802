import { FC } from "react"
import { Timeline } from "antd-v5"
import { DATE_FORMATS } from "../../config/constants"
import { ShiftInterface } from "@type/workMonitor.types"
import { getMomentZInstanceOfDate } from "../../config/utils"

export interface props {
	shift: ShiftInterface | null
}

export const GeoFenceTimeline: FC<props> = ({ shift }) => {
	const { geoFenceData } = shift || {}

	const items =
		geoFenceData &&
		geoFenceData.length &&
		geoFenceData
			.slice(0)
			.reverse()
			.map(data => {
				const { isInsideTheRadius, eventTime, latitude, longitude } = data
				const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`

				return {
					children: (
						<div>
							<div>
								<strong>{getMomentZInstanceOfDate(eventTime).format(DATE_FORMATS.HH_mm)}</strong>
								{isInsideTheRadius ? ": Officer entered the premises" : ": Officer left the premises"}
							</div>

							<div>
								<a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
									View on map
								</a>
							</div>
						</div>
					),
					color: isInsideTheRadius ? "gray" : "red",
				}
			})

	return (
		<div className="mt-4">
			<Timeline mode="left" items={items || []} />
		</div>
	)
}
