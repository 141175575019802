import { useState } from "react"
import { Icon, SignedUrlForImage } from "components"

interface Props {
	index: number
	imageSource: string
	handleDeleteImage?: (imageUrl: string) => void
	showDeleteIcon?: boolean
}

const Image: React.FC<Props> = ({ index, imageSource, handleDeleteImage = () => undefined, showDeleteIcon = true }) => {
	const [imageLoading, setImageLoading] = useState<boolean>(true)

	return (
		<div className="group relative" key={`privateImage${index}`}>
			{imageLoading && (
				<div className="absolute h-28 w-28 bg-white" key={`loading_${index}`}>
					<Icon
						name="spinner"
						color="white"
						size={40}
						className="mx-[27%] my-[33%] animate-spin !text-purple"
					/>
				</div>
			)}
			<SignedUrlForImage path={imageSource}>
				{(image: string) => (
					<img
						onLoad={() => setImageLoading(false)}
						width={28}
						height={28}
						decoding="async"
						loading="lazy"
						className={`object-fit h-28 w-28  object-cover py-1 text-xs ${
							showDeleteIcon && "group-hover:opacity-20"
						}`}
						src={image}
					/>
				)}
			</SignedUrlForImage>
			{!imageLoading && showDeleteIcon && (
				<div
					className={`group invisible absolute top-[42%] right-[40%] cursor-pointer group-hover:visible`}
					onClick={() => handleDeleteImage(imageSource)}
				>
					<Icon name="delete" color="danger" size={25} />
				</div>
			)}
		</div>
	)
}

export default Image
