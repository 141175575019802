import axios from "axios"
import { GetPBTermsInterface, PBTermsInterface } from "@type/payBill.types"

const ROOT_PATH = "pay-bill/terms"
export default class PBTermsService {
	static createPBTerm = (notifiee: PBTermsInterface) => axios.post<GetPBTermsInterface>(ROOT_PATH, notifiee)

	static getAllPBTerms = () => axios.get<Array<GetPBTermsInterface>>(`${ROOT_PATH}`)

	static getPBTermById = (id: string) => axios.get<GetPBTermsInterface>(`${ROOT_PATH}/${id}`)

	static updatePBTerm = (id: string, notifiee: Partial<GetPBTermsInterface>) =>
		axios.put<GetPBTermsInterface>(`${ROOT_PATH}/${id}`, notifiee)
}
