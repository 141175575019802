import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { updateState } from "config/utils"
import { GetLocationEquipmentInterface } from "@type/locations.types"
import { LocationEquipment } from "services"
import { AppThunk, RootState } from "store"

export interface LocationEquipmentInterface {
	data: null | GetLocationEquipmentInterface[]
	loading: boolean
	saving: boolean
}

const initialState: LocationEquipmentInterface = {
	data: null,
	loading: false,
	saving: false,
}

const locationEquipmentSlice = createSlice({
	name: "locationEquipment",
	initialState,
	reducers: {
		requestLocationEquipment: locationEquipment => {
			locationEquipment.loading = true
		},
		receiveLocationEquipmentData: (locationEquipment, action: PayloadAction<GetLocationEquipmentInterface[]>) => {
			locationEquipment.loading = false
			locationEquipment.data = action.payload
		},
		receiveLocationEquipmentDataFailed: locationEquipment => {
			locationEquipment.loading = false
		},
		requestSavingLocationEquipmentData: locationEquipmentData => {
			locationEquipmentData.saving = true
		},
		SavingLocationEquipmentData: (locationEquipment, action: PayloadAction<GetLocationEquipmentInterface>) => {
			locationEquipment.saving = false
			const [updatedValue] = updateState(locationEquipment.data, action.payload, "_id")
			locationEquipment.data = updatedValue
		},
		SavingLocationEquipmentDataFailed: locationEquipment => {
			locationEquipment.saving = false
		},
	},
})

//REDUCER
export default locationEquipmentSlice.reducer

//ACTIONS
const {
	requestLocationEquipment,
	receiveLocationEquipmentData,
	receiveLocationEquipmentDataFailed,
	requestSavingLocationEquipmentData,
	SavingLocationEquipmentData,
	SavingLocationEquipmentDataFailed,
} = locationEquipmentSlice.actions

const getLocationEquipment =
	(locationId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestLocationEquipment())
			const { data: locationEquipmentData } = await LocationEquipment.getLocationEquipment(locationId)
			dispatch(receiveLocationEquipmentData(locationEquipmentData))
		} catch (error) {
			dispatch(receiveLocationEquipmentDataFailed())
		}
	}

const saveLocationEquipmentData =
	(locationEquipmentData: GetLocationEquipmentInterface, cb?: () => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(requestSavingLocationEquipmentData())
			if (locationEquipmentData._id) {
				data = await LocationEquipment.updateLocationEquipmentById(
					locationEquipmentData._id,
					locationEquipmentData,
				)
			} else {
				data = await LocationEquipment.createLocationEquipment(locationEquipmentData)
			}
			const { data: locationEquipment } = data
			dispatch(SavingLocationEquipmentData(locationEquipment))
			cb?.()
		} catch (error) {
			dispatch(SavingLocationEquipmentDataFailed())
		}
	}

export { getLocationEquipment, saveLocationEquipmentData }

//SELECTORS
const selectLocationEquipment = (state: RootState) => state.location.locationEquipment
const isLocationEquipmentLoading = () => (state: RootState) => selectLocationEquipment(state).loading
const selectLocationEquipmentData = () => (state: RootState) => selectLocationEquipment(state).data
const isLocationEquipmentSaving = () => (state: RootState) => selectLocationEquipment(state).saving

export { isLocationEquipmentLoading, selectLocationEquipmentData, isLocationEquipmentSaving }
