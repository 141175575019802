import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "hooks"

import Form from "./Form"
import TermsTable from "./TermsTable"

import { getEmployeesTermByEmployeeId } from "store/Employee/Terms/terms.slice"
import TermContext from "./termsContext"

import { EmployeeTermInterface } from "@type/employees.types"
import { selectSelectedEmployee } from "store/globalState.slice"
import { Typography } from "antd"

const Terms = () => {
	const dispatch = useAppDispatch()

	const selected = useAppSelector(selectSelectedEmployee)

	const [term, setTerm] = useState<EmployeeTermInterface | null>(null)

	useEffect(() => {
		selected && dispatch(getEmployeesTermByEmployeeId(selected))
	}, [selected])

	const handleRow = (e: any) => setTerm(e)

	return (
		<TermContext.Provider value={{ handleRow, term }}>
			<div className="grid grid-cols-12 gap-x-10">
				<div className="col-span-3">
					<Form />
				</div>
				<div className="col-span-9">
					<Typography.Title level={5}>All Terms</Typography.Title>
					<TermsTable />
				</div>
			</div>
		</TermContext.Provider>
	)
}

export default Terms
