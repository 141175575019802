import axios from "axios"
import { GetUserInterface, UpdateUserPassword, UserInterface } from "@type/security.types"

const ROOT_PATH = "users"
export default class UserService {
	static createUser = (user: UserInterface) => axios.post<GetUserInterface>(ROOT_PATH, user)

	static getAllUsers = () => axios.get<Array<GetUserInterface>>(`${ROOT_PATH}`)

	static getAllUsersByOrganization = (platform: string) =>
		axios.get<Array<GetUserInterface>>(`${ROOT_PATH}/by-organization`, { params: { platform } })

	static getUserById = (id: string) => axios.get<GetUserInterface>(`${ROOT_PATH}/${id}`)

	static updateUser = (id: string, user: Partial<GetUserInterface>) =>
		axios.put<GetUserInterface>(`${ROOT_PATH}/${id}`, user)

	static updatePassword = (id: string, user: UpdateUserPassword) =>
		axios.put<GetUserInterface>(`${ROOT_PATH}/password/${id}`, user)
}
