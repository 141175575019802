import { FormInterface } from "@pages/accounts/MasterSheet"

interface TransformedFormInterface {
	[locationId: string]: string
	payrollId: string
}

const useMasterSheet = () => {
	const transformObjectForPayload = (inputObject: FormInterface): { [key: string]: TransformedFormInterface[] } => {
		const transformedObject: any = {}

		// Iterate over the keys of the input object
		for (const key in inputObject) {
			// Split the key into employeeId and locationId
			const [employeeId, locationId] = key.split("-")

			// Extract the values from the current object
			const { payrollId } = inputObject[key]
			const newLocationRate = inputObject[key][locationId]
			// Create a new object with locationId and payrollId
			const newObject = { [locationId]: newLocationRate, payrollId: payrollId || "" }

			// If employeeId already exists in the transformed object, push the new object
			// Otherwise, create a new array with the new object
			if (transformedObject[employeeId]) {
				transformedObject[employeeId].push(newObject)
			} else {
				transformedObject[employeeId] = [newObject]
			}
		}

		return transformedObject
	}

	const generateFormKey = (employeeId: string, locationId: string) => `${employeeId || ""}-${locationId}`

	return {
		transformObjectForPayload,
		generateFormKey,
	}
}

export default useMasterSheet
