import { EmployeeQualificationInterface } from "@type/employees.types"
import Qualification from "../HR/Qualification"
import QualificationTable from "../HR/QualificationTable"
import { useState } from "react"
import QualificationContext from "../HR/qualificationContext"

const QualificationComponent = () => {
	const [qualification, setQualification] = useState<EmployeeQualificationInterface | undefined>()
	const handleRow = (e: any) => {
		setQualification(e)
	}
	return (
		<QualificationContext.Provider value={{ handleRow, qualification }}>
			<div className="grid grid-cols-12 gap-x-10">
				<div className="col-span-3">
					<Qualification />
				</div>
				<div className="col-span-9">
					<QualificationTable />
				</div>
			</div>
		</QualificationContext.Provider>
	)
}

export default QualificationComponent
