import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit"
import { CreateLocationCallTimeInterface, GetLocationCallTimeInterface } from "@type/locations.types"
import { locationCallTime } from "services"
import { AppThunk, RootState } from "store"

export interface LocationCallTimeStateInterface {
	list: null | Array<GetLocationCallTimeInterface>
	loading: boolean
	saving: boolean
	deleting: boolean
}

const initialState: LocationCallTimeStateInterface = {
	list: null,
	loading: false,
	saving: false,
	deleting: false,
}

const locationCallTimeSlice = createSlice({
	name: "locationCallTime",
	initialState,
	reducers: {
		clearCallTimeList: locationCallTime => {
			locationCallTime.list = null
		},
		fetchingLocationCallTime: locationCallTime => {
			locationCallTime.loading = true
		},
		deletingLocationCallTime: locationCallTime => {
			locationCallTime.deleting = true
		},
		locationCallTimeFetched: (locationCallTime, action: PayloadAction<GetLocationCallTimeInterface[]>) => {
			locationCallTime.loading = false
			locationCallTime.list = action.payload
		},
		locationCallTimeFetchingFailed: locationCallTime => {
			locationCallTime.loading = false
		},
		savingLocationCallTime: locationCallTimeData => {
			locationCallTimeData.saving = true
		},
		locationCallTimeDeleted: (locationCallTime, action: PayloadAction<string[]>) => {
			locationCallTime.deleting = false
			locationCallTime.list = locationCallTime.list?.filter(item => !action.payload.includes(item._id)) || []
		},
		locationCallTimeSaved: (locationCallTime, action: PayloadAction<GetLocationCallTimeInterface[]>) => {
			locationCallTime.saving = false
			locationCallTime.list?.push(...action.payload)
		},
		locationCallTimeSavingFailed: locationCallTime => {
			locationCallTime.saving = false
		},
		locationCallTimeDeletingFailed: locationCallTime => {
			locationCallTime.deleting = false
		},
	},
})

//REDUCER
export default locationCallTimeSlice.reducer

//ACTIONS
export const {
	clearCallTimeList,
	fetchingLocationCallTime,
	locationCallTimeFetched,
	locationCallTimeFetchingFailed,
	savingLocationCallTime,
	locationCallTimeSaved,
	locationCallTimeSavingFailed,
	locationCallTimeDeleted,
	deletingLocationCallTime,
	locationCallTimeDeletingFailed,
} = locationCallTimeSlice.actions

const getLocationCallTime =
	(postId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingLocationCallTime())
			const { data: locationCallTimeResponse } = await locationCallTime.getPostCallTimes(postId)
			dispatch(locationCallTimeFetched(locationCallTimeResponse))
		} catch (error) {
			dispatch(locationCallTimeFetchingFailed())
		}
	}

const removeCallTimes =
	(callTimeIds: string[], cb: () => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(deletingLocationCallTime())
			await locationCallTime.deleteCallTimes(callTimeIds)
			dispatch(locationCallTimeDeleted(callTimeIds))
			cb()
		} catch (error) {
			dispatch(locationCallTimeDeletingFailed())
		}
	}

const saveLocationCallTimes =
	(locationCallTimeData: CreateLocationCallTimeInterface, cb: () => void = () => null): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingLocationCallTime())
			const { data: locationCallTimeResponse } = await locationCallTime.createCallTime(locationCallTimeData)
			dispatch(locationCallTimeSaved(locationCallTimeResponse))
			cb()
		} catch (error) {
			dispatch(locationCallTimeSavingFailed())
		}
	}

export { getLocationCallTime, saveLocationCallTimes, removeCallTimes }

//SELECTORS
const selectLocationCallTimeState = (state: RootState) => state.location.callTime
const selectLocationCallTimeList = (state: RootState) => selectLocationCallTimeState(state).list
const isLoadingLocationCallTime = (state: RootState) => selectLocationCallTimeState(state).loading
const isSavingLocationCallTime = (state: RootState) => selectLocationCallTimeState(state).saving
const isDeletingLocationCallTime = (state: RootState) => selectLocationCallTimeState(state).deleting
const selectLocationCallTimeListWithLabel = (state: RootState) =>
	selectLocationCallTimeState(state).list?.map(item => ({ ...item, label: "chk" }))

const selectLocationCallTimeColumnLabels = createSelector(
	(state: RootState) => state.location.callTime.list,
	list =>
		[...new Set(list?.map(item => item.time))].sort(
			(a, b) => new Date("1970/01/01 " + a).valueOf() - new Date("1970/01/01 " + b).valueOf(),
		),
)

export {
	selectLocationCallTimeList,
	selectLocationCallTimeListWithLabel,
	selectLocationCallTimeState,
	isSavingLocationCallTime,
	isLoadingLocationCallTime,
	isDeletingLocationCallTime,
	selectLocationCallTimeColumnLabels,
}
