interface EnvVariables {
	apiRoot: string
	socketRoot: string
	fileRoot: string
	defaultView: string
	googleMapsApiKey: string
	inactivityTimeoutMinutes: number
}

const env: EnvVariables = {
	apiRoot: `${process.env.REACT_APP_BACKEND_ROOT || ""}/api/`,
	socketRoot: process.env.REACT_APP_BACKEND_ROOT || "",
	fileRoot: process.env.REACT_APP_UPLOADED_FILES_PATH || "",
	googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
	defaultView: process.env.REACT_APP_DEFAULT_VIEW || "schedules",
	inactivityTimeoutMinutes: process.env?.REACT_APP_INACTIVITY_TIMEOUT_MINUTES
		? parseFloat(process.env.REACT_APP_INACTIVITY_TIMEOUT_MINUTES)
		: 5,
}

export default env
