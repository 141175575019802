import { meterToMiles } from "config/utils"
import { Modal, Table2, Icon, AntdButton } from "components"
import { selectBranchById } from "store/Branches/branch.slice"
import useAppSelector from "hooks/useAppSelector"
import { isNearbyEmployeesLoading, selectNearbyLocationEmployees } from "store/Geolocation/geolocation.slice"
import { useContext, useMemo, useState } from "react"
import EmployeeRetainContext, { EmployeeRetainContextProps } from "pages/schedules/Location/CreateScheduleRetainContext"
import { EmployeeTypesEnum } from "config/constants"

interface Props {
	open: boolean
	canSelect?: boolean
	reset?: () => void
	modalHandler: (open: boolean) => void
	location?: any
	filterEmployeeType?: string
}

const NearbyEmployeesModal: React.FC<Props> = ({
	open,
	modalHandler,
	location,
	reset,
	filterEmployeeType,
	canSelect = false,
}) => {
	const nearbyEmployees = useAppSelector(selectNearbyLocationEmployees(location?._id))
	const nearbyEmployeesLoading = useAppSelector(isNearbyEmployeesLoading())
	const [selectedRow, setSelectedRow] = useState("")
	const { handleCreateScheduleModalData, createScheduleModalData } = useContext(
		EmployeeRetainContext,
	) as EmployeeRetainContextProps

	const filteredEmployeeByPostType = useMemo(() => {
		return !filterEmployeeType
			? nearbyEmployees
			: nearbyEmployees.filter(employee => {
					if (filterEmployeeType === EmployeeTypesEnum.SECURITY || filterEmployeeType === "") {
						return !employee?.type || employee?.type === EmployeeTypesEnum.SECURITY
					} else return employee?.type === EmployeeTypesEnum.CLEANING
			  })
	}, [filterEmployeeType, nearbyEmployees])

	const selectHandler = () => {
		if (reset) {
			reset()
			handleCreateScheduleModalData({
				...createScheduleModalData,
				branch: "",
				supplier: "",
				...(!createScheduleModalData?.checkMultiple ? { employee: selectedRow } : {}),
			})
			modalHandler(false)
		}
	}
	return (
		<Modal
			open={open}
			title={
				<div>
					<div className="font-semi text-center text-md font-semibold">
						Nearby Employees (within 50 miles)
					</div>
					<div className="font-semi pt-1 pb-2 pl-2 text-md font-semibold">{`Location: ${location?.name}`}</div>
				</div>
			}
			modalHandler={modalHandler}
			containerClass="h-auto min-w-[600px]"
		>
			{nearbyEmployeesLoading ? (
				<div className={`flex min-h-52 w-full max-w-3xl items-center justify-center  overflow-hidden`}>
					<Icon name="spinner" color="black" size={85} className=" animate-spin" />
				</div>
			) : (
				<div className="h-full flex-col space-y-8">
					<div className="h-[50vh] overflow-y-auto">
						<Table2
							headerRowClassName="sticky top-0"
							selectedRows={[selectedRow]}
							rowClassName={"cursor-pointer"}
							onRowClick={setSelectedRow}
							selectRow={true}
							selectedRowColor="#c4e5f8"
							rows={filteredEmployeeByPostType}
							columns={[
								{
									label: "Employee",
									key: "firstName",
									render: ({ row, key }: any) => <div> {`${row[key]} ${row["lastName"]}`}</div>,
								},

								{
									label: "Branch",
									key: "branch",
									render: ({ row, key }: any) => (
										<div>{useAppSelector(selectBranchById(row[key]))?.name}</div>
									),
								},
								{
									label: "Distance",
									key: "nearbyDistance",
									render: ({ row, key }: any) => <div>{`${meterToMiles(row[key]["value"])} mi`}</div>,
								},
								{
									label: "Duration",
									key: "nearbyDistanceDuration",
									render: ({ row, key }) => <div> {row[key]["text"]}</div>,
								},
							]}
						/>
					</div>
					{canSelect && (
						<AntdButton htmlType="button" onClick={selectHandler}>
							Select
						</AntdButton>
					)}
				</div>
			)}
		</Modal>
	)
}

export default NearbyEmployeesModal
