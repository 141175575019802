import React from "react"

const ScheduleTableSkeletonLocation = () => {
	return (
		<div>
			<div className="flex w-full animate-pulse flex-col gap-1 px-2.5 pb-2.5">
				<div className="flex justify-around gap-1.25  rounded-md border-1 bg-secondary-dark px-0.25 py-1.25">
					<div className="ml-1.25 h-7.5 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-7.5 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-7.5 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-7.5 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-7.5 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-7.5 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="mr-1.25 h-7.5 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
				</div>
				<div className="flex justify-around gap-1 ">
					<div className="h-12 w-2/5 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>

					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
				</div>
				<div className="flex justify-around gap-1 ">
					<div className="h-12 w-2/5 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>

					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
				</div>
				<div className="flex justify-around gap-1 ">
					<div className="h-12 w-2/5 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-12 w-1/2 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
				</div>
			</div>
		</div>
	)
}

export default ScheduleTableSkeletonLocation
