import axios from "axios"
import { CreateLocationContractsInterface, GetLocationContractsInterface } from "@type/locations.types"
const ROOT_PATH = "locations/contracts"
export default class LocationContracts {
	static createLocationContracts = (locationContracts: CreateLocationContractsInterface) =>
		axios.post<GetLocationContractsInterface>(ROOT_PATH, locationContracts)
	static getAllLocationsContracts = () => axios.get<GetLocationContractsInterface>(`${ROOT_PATH}`)
	static getContractsByLocationId = (Location: string) =>
		axios.get<GetLocationContractsInterface[]>(`${ROOT_PATH}/?location=${Location}`)

	static updateLocationContracts = (id: string, locationContracts: Partial<GetLocationContractsInterface>) =>
		axios.put<GetLocationContractsInterface>(`${ROOT_PATH}/${id}`, locationContracts)
}
