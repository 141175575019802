import React, { Dispatch, SetStateAction, useEffect } from "react"
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai"
import AntdButton from "./AntdButton"
import Button from "./Button"
interface Row {
	label?: string
	key?: string
	value?: string
	className?: string
}

interface Props {
	sourceListTitle?: string
	targetListTitle?: string
	dataSource?: Row[]
	isLoading?: boolean
	isLoadingTarget?: boolean
	previousKeys: string[]
	customClasses?: string
	handleSelectChange?: () => void
	onChange: (list: string[]) => void
	disabled?: boolean
	columnClass?: string
	resetEntity?: string | null
}

interface SelectHandlerProps {
	checked: boolean
	value: string
}

export const Transfer: React.FC<Props> = ({
	sourceListTitle = "Source",
	targetListTitle = "Target",
	dataSource = [],
	isLoading,
	isLoadingTarget,
	previousKeys,
	onChange,
	disabled,
	customClasses = "",
	columnClass = "",
	resetEntity,
}) => {
	const [selectedKeys, setSelectedKeys] = React.useState<string[]>([])
	const [targetSelectedKeys, setTargetSelectedKKeys] = React.useState<string[]>([])
	const [targetKeys, setTargetKeys] = React.useState<string[]>([])

	useEffect(() => {
		if (previousKeys?.length) setTargetKeys([...previousKeys])
		else setTargetKeys([])
	}, [previousKeys])

	useEffect(() => {
		setSelectedKeys([])
		setTargetSelectedKKeys([])
	}, [resetEntity])

	const handleSelectChange = ({ checked, value }: SelectHandlerProps) => {
		if (checked) setSelectedKeys([...selectedKeys, value])
		else setSelectedKeys(selectedKeys.filter((key: string) => key !== value))
	}
	const handleTargetSelectChange = ({ checked, value }: SelectHandlerProps) => {
		if (checked) setTargetSelectedKKeys([...targetSelectedKeys, value])
		else setTargetSelectedKKeys(targetSelectedKeys.filter((key: string) => key !== value))
	}

	const handleAddToTargetList = () => {
		const newList = [...targetKeys, ...selectedKeys]
		onChange(newList)
		setTargetKeys(newList)
		setSelectedKeys([])
	}
	const handleRemoveFromTargetList = () => {
		const newList = [...targetKeys.filter((key: string) => !targetSelectedKeys.includes(key))]
		onChange(newList)
		setTargetKeys(newList)
		setTargetSelectedKKeys([])
	}
	return (
		<div className={`m-2 flex flex-row text-dark-alt ${customClasses}`}>
			<CustomCheckbox
				columnClass={columnClass}
				disabled={disabled}
				isLoading={isLoading}
				key={sourceListTitle}
				title={sourceListTitle}
				dataSource={dataSource}
				selectedList={selectedKeys}
				setSelectedList={setSelectedKeys}
				onChange={handleSelectChange}
				filter={(item: Row) => !targetKeys.includes(item.value as never)}
			/>
			<div className="mx-5 flex flex-col items-center justify-center">
				<Button
					disabled={disabled}
					className="mb-2 bg-green-700 px-3"
					onClick={e => {
						e.preventDefault()
						handleAddToTargetList()
					}}
				>
					<AiOutlineArrowRight size={20} />
				</Button>
				<Button
					disabled={disabled}
					className="bg-red-700 px-3"
					onClick={e => {
						e.preventDefault()
						handleRemoveFromTargetList()
					}}
				>
					<AiOutlineArrowLeft size={20} />
				</Button>
			</div>
			<CustomCheckbox
				columnClass={columnClass}
				disabled={disabled}
				isLoading={isLoadingTarget}
				key={targetListTitle}
				title={targetListTitle}
				dataSource={dataSource}
				selectedList={targetSelectedKeys}
				setSelectedList={setTargetSelectedKKeys}
				onChange={handleTargetSelectChange}
				filter={(item: Row) => targetKeys.includes(item.value as never)}
			/>
		</div>
	)
}

interface CustomCheckboxProp {
	title?: string
	selectedList?: string[]
	setSelectedList?: Dispatch<SetStateAction<string[]>>
	filterList?: string[]
	dataSource?: Row[]
	isLoading?: boolean
	onChange: (obj: SelectHandlerProps) => void
	filter: (item: Row) => boolean
	disabled?: boolean
	columnClass: string
}

const CustomCheckbox: React.FC<CustomCheckboxProp> = ({
	dataSource = [],
	title = "",
	selectedList = [],
	onChange,
	filter,
	isLoading,
	disabled,
	columnClass,
	setSelectedList = () => undefined,
}) => {
	return (
		<div
			className={`flex flex-col rounded-2 border-0.5 border-neutral-300 ${
				columnClass ? columnClass : "h-50 min-w-72"
			}`}
		>
			<div className="flex w-full items-center border-b-0.5 border-neutral-300 px-1">
				<div className="mt-2 mb-1 ml-2 w-[65%]">{title}</div>
				<div className="my-1  flex h-fit w-[35%] justify-end">
					<AntdButton
						className="px-3 text-sm"
						disabled={!dataSource.length}
						onClick={e => {
							e.preventDefault()
							if (selectedList.length !== dataSource.length)
								setSelectedList(dataSource.map(({ value }) => value as string))
							else setSelectedList([])
						}}
					>
						{dataSource.length && dataSource.length === selectedList.length ? "Deselect All" : "Select All"}
					</AntdButton>
				</div>
			</div>
			{isLoading ? (
				<TransferSkeleton />
			) : (
				<div className="overflow-scroll pt-2 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-400">
					{dataSource.filter(filter).map(item => (
						<div
							className={`pl-2 hover:bg-sky-100 ${
								selectedList.includes(item.value as never) ? "bg-sky-200" : ""
							}`}
							key={item.value}
							onMouseEnter={({ buttons }) => {
								if (!disabled && buttons === 1) {
									onChange({
										checked: !selectedList.includes(item.value as never),
										value: item.value as string,
									})
								}
							}}
							onMouseDown={() =>
								disabled ||
								onChange({
									checked: !selectedList.includes(item.value as never),
									value: item.value as string,
								})
							}
						>
							<input
								type="checkbox"
								className="h-3"
								checked={selectedList.includes(item.value as never)}
								value={item.value as string}
								readOnly
							/>
							<span className="ml-2 cursor-pointer select-none">{item.label}</span>
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export default Transfer

const TransferSkeleton = () => {
	return (
		<div>
			<div className="flex animate-pulse flex-col gap-5 px-2.5 py-2.5">
				<div className="flex justify-around gap-5">
					<div className="h-7.5 w-full rounded-md bg-secondary-light"></div>
				</div>
				<div className="flex justify-around gap-5">
					<div className="h-7.5 w-full rounded-md bg-secondary-light"></div>
				</div>
				<div className="flex justify-around gap-5">
					<div className="h-7.5 w-full rounded-md bg-secondary-light"></div>
				</div>
			</div>
		</div>
	)
}
