import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetSDCountiesInterface, SDCountiesInterface, UpdateSDCountiesInterface } from "@type/staticData.types"
import { SDCountiesService } from "services"
import { AppThunk, RootState } from "store"
import { sortByProperty, sortDeepCopyByProperty, updateState } from "config/utils"
export interface SDCountiesStateInterface {
	list: null | Array<GetSDCountiesInterface>
	loading: boolean
	saving: boolean
}

const initialState: SDCountiesStateInterface = {
	list: null,
	loading: false,
	saving: false,
}

const SDCountiesSlice = createSlice({
	name: "SDCounties",
	initialState,
	reducers: {
		fetchingSDCounties: SDCounties => {
			SDCounties.loading = true
		},
		SDCountiesFetched: (SDCounties, action: PayloadAction<GetSDCountiesInterface[]>) => {
			SDCounties.loading = false
			SDCounties.list = action.payload
		},
		SDCountiesFetchingFailed: SDCounties => {
			SDCounties.loading = false
		},
		savingSDCounties: SDCountiesData => {
			SDCountiesData.saving = true
		},
		SDCountiesSaved: (SDCounties, action: PayloadAction<GetSDCountiesInterface>) => {
			SDCounties.saving = false
			const [UpdatedValue] = updateState(SDCounties.list, action.payload, "_id")
			SDCounties.list = UpdatedValue
		},
		SDCountiesSavingFailed: SDCounties => {
			SDCounties.saving = false
		},
	},
})

//REDUCER
export default SDCountiesSlice.reducer

//ACTIONS
const {
	fetchingSDCounties,
	SDCountiesFetched,
	SDCountiesFetchingFailed,
	savingSDCounties,
	SDCountiesSaved,
	SDCountiesSavingFailed,
} = SDCountiesSlice.actions

const getSDCounties =
	(cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingSDCounties())
			const { data: SDCountiesResponse } = await SDCountiesService.getAllSDCounties()
			SDCountiesResponse.sort(sortByProperty("name"))
			cb && cb(SDCountiesResponse[0]?._id)
			dispatch(SDCountiesFetched(SDCountiesResponse))
		} catch (error) {
			dispatch(SDCountiesFetchingFailed())
		}
	}

const saveSDCounties =
	(SDCountiesData: Partial<UpdateSDCountiesInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingSDCounties())
			if (SDCountiesData?._id) data = await SDCountiesService.updateSDCounties(SDCountiesData._id, SDCountiesData)
			else data = await SDCountiesService.createSDCounties(SDCountiesData as SDCountiesInterface)
			const { data: SDCountiesResponse } = data
			cb && cb(SDCountiesResponse._id)
			dispatch(SDCountiesSaved(SDCountiesResponse))
		} catch (error) {
			dispatch(SDCountiesSavingFailed())
		}
	}

export { getSDCounties, saveSDCounties }

//SELECTORS
const selectSDCountiesState = (state: RootState) => state.staticData.SDCounties
const isSDCountiesLoading = () => (state: RootState) => selectSDCountiesState(state).loading
const isSDCountiesSaving = () => (state: RootState) => selectSDCountiesState(state).saving
const selectSDCountiesList = createSelector(
	(state: RootState) => state.staticData.SDCounties.list,
	list => {
		return sortDeepCopyByProperty(list, "description") as GetSDCountiesInterface[]
	},
)

const selectSDCountiesDropdownList = createSelector(
	(state: RootState) => state.staticData.SDCounties.list,
	list => {
		return sortDeepCopyByProperty(list?.map(item => ({ label: item.name, value: item._id })) || [], "label")
	},
)

const selectSDCountiesById = (SDCountiesId: string | null) => (state: RootState) =>
	state.staticData.SDCounties?.list?.find(SDCounties => SDCounties._id === SDCountiesId)

export {
	selectSDCountiesList,
	selectSDCountiesDropdownList,
	selectSDCountiesState,
	selectSDCountiesById,
	isSDCountiesLoading,
	isSDCountiesSaving,
}
