import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetSDSuppliersInterface, SDSuppliersInterface, UpdateSDSuppliersInterface } from "@type/staticData.types"
import { SDSuppliersService } from "services"
import { AppThunk, RootState } from "store"
import { sortByProperty, sortDeepCopyByProperty, updateState } from "config/utils"
export interface SDSuppliersStateInterface {
	list: null | Array<GetSDSuppliersInterface>
	loading: boolean
	saving: boolean
}

const initialState: SDSuppliersStateInterface = {
	list: null,
	loading: false,
	saving: false,
}

const SDSuppliersSlice = createSlice({
	name: "SDSuppliers",
	initialState,
	reducers: {
		fetchingSDSuppliers: SDSuppliers => {
			SDSuppliers.loading = true
		},
		SDSuppliersFetched: (SDSuppliers, action: PayloadAction<GetSDSuppliersInterface[]>) => {
			SDSuppliers.loading = false
			SDSuppliers.list = action.payload
		},
		SDSuppliersFetchingFailed: SDSuppliers => {
			SDSuppliers.loading = false
		},
		savingSDSuppliers: SDSuppliersData => {
			SDSuppliersData.saving = true
		},
		SDSuppliersSaved: (SDSuppliers, action: PayloadAction<GetSDSuppliersInterface>) => {
			SDSuppliers.saving = false
			const [UpdatedValue] = updateState(SDSuppliers.list, action.payload, "_id")
			SDSuppliers.list = UpdatedValue
		},
		SDSuppliersSavingFailed: SDSuppliers => {
			SDSuppliers.saving = false
		},
		ClearSDSuppliersState: SDSuppliers => {
			SDSuppliers.list = []
			SDSuppliers.loading = false
			SDSuppliers.saving = false
		},
	},
})

//REDUCER
export default SDSuppliersSlice.reducer

//ACTIONS
export const {
	ClearSDSuppliersState,
	fetchingSDSuppliers,
	SDSuppliersFetched,
	SDSuppliersFetchingFailed,
	savingSDSuppliers,
	SDSuppliersSaved,
	SDSuppliersSavingFailed,
} = SDSuppliersSlice.actions

const getSDSuppliers =
	(fetchAll?: boolean, cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingSDSuppliers())
			const { data: SDSuppliersResponse } = await SDSuppliersService.getAllSDSuppliers(fetchAll ?? true)
			SDSuppliersResponse.sort(sortByProperty("name"))
			cb && cb(SDSuppliersResponse[0]?._id)
			dispatch(SDSuppliersFetched(SDSuppliersResponse))
		} catch (error) {
			dispatch(SDSuppliersFetchingFailed())
		}
	}

const saveSDSuppliers =
	(SDSuppliersData: Partial<UpdateSDSuppliersInterface>, organization: string, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingSDSuppliers())
			if (SDSuppliersData?._id) {
				data = (await SDSuppliersService.updateSDSuppliers(SDSuppliersData._id, SDSuppliersData)) || {}
				data["data"]["organization"] = organization
			} else
				data = await SDSuppliersService.createSDSuppliers(SDSuppliersData as SDSuppliersInterface, organization)
			const { data: SDSuppliersResponse } = data
			cb && cb(SDSuppliersResponse._id)
			dispatch(SDSuppliersSaved(SDSuppliersResponse))
		} catch (error) {
			dispatch(SDSuppliersSavingFailed())
		}
	}

export { getSDSuppliers, saveSDSuppliers }

//SELECTORS
const selectSDSuppliersState = (state: RootState) => state.staticData.SDSuppliers
const isSDSuppliersLoading = () => (state: RootState) => selectSDSuppliersState(state).loading
const isSDSuppliersSaving = () => (state: RootState) => selectSDSuppliersState(state).saving
const selectSDSuppliersList = createSelector(
	(state: RootState) => state.staticData.SDSuppliers.list,
	list => {
		return sortDeepCopyByProperty(list, "description") as GetSDSuppliersInterface[]
	},
)

const selectSDSuppliersDropdownList = createSelector(
	(state: RootState) => state.staticData.SDSuppliers.list,
	list => {
		return [
			{ label: "Select Supplier", value: "" },
			...sortDeepCopyByProperty(list?.map(item => ({ label: item.name, value: item._id })) || [], "label"),
		]
	},
)

const suppliersDropdownList = createSelector(
	(state: RootState) => state.staticData.SDSuppliers.list,
	list => {
		return [...sortDeepCopyByProperty(list?.map(item => ({ label: item.name, value: item._id })) || [], "label")]
	},
)

const selectSDSuppliersById = (SDSuppliersId: string | null) => (state: RootState) =>
	state.staticData.SDSuppliers?.list?.find(SDSuppliers => SDSuppliers._id === SDSuppliersId)

export {
	selectSDSuppliersList,
	selectSDSuppliersDropdownList,
	suppliersDropdownList,
	selectSDSuppliersState,
	selectSDSuppliersById,
	isSDSuppliersLoading,
	isSDSuppliersSaving,
}
