import axios from "axios"
import Qs from "qs"
import {
	AllocateEmployeeToScheduleInterface,
	CreateSchedulesInterface,
	DeleteEmployeeToScheduleInterface,
	GetSchedulesInterface,
	GetSchedulesObjectInterface,
	ScheduleFilterInterface,
	UpdateSchedulesInterface,
} from "@type/schedules.types"
import { GetPostsInterface, InActiveSchedule } from "@type/locations.types"

export interface getSchedulesParameters {
	startTime: string
	finishTime: string
	posts?: string[]
	location?: string
	employee?: string
}

export interface getScheduleByEmployee {
	startTime: string
	finishTime: string
	employee: string
}

const ROOT_PATH = "/schedules"

export default class LocationSchedules {
	static createSchedulesByLocation = (scheduleData: CreateSchedulesInterface) =>
		axios.post<GetSchedulesObjectInterface>(`${ROOT_PATH}/create-by-location`, scheduleData)

	static createInactiveSchedulesByLocation = (scheduleData: CreateSchedulesInterface[]) =>
		axios.post<GetSchedulesObjectInterface[]>(`${ROOT_PATH}/create-by-location-inactive`, scheduleData)

	static createSchedulesByEmployee = (scheduleData: CreateSchedulesInterface) =>
		axios.post<GetSchedulesObjectInterface>(`${ROOT_PATH}/create-by-employee`, scheduleData)

	static updateSchedulesByLocation = (scheduleData: UpdateSchedulesInterface) =>
		axios.put<GetSchedulesObjectInterface>(`${ROOT_PATH}`, scheduleData)

	static updateScheduleStatus = (schedules: string[]) =>
		axios.put<GetSchedulesInterface[]>(`${ROOT_PATH}/update-schedule-status`, { schedules })

	static getScheduleByPostAndDateRange = ({ startTime, finishTime, location }: getSchedulesParameters) => {
		return axios.get<GetSchedulesInterface[]>(`${ROOT_PATH}/by-date-range`, {
			params: { startTime, finishTime, location }, // posts: [...posts],
			paramsSerializer: function (params) {
				return Qs.stringify(params, { arrayFormat: "brackets" })
			},
		})
	}

	static getScheduleByEmployeeAndDateRange = ({ startTime, finishTime, employee }: getScheduleByEmployee) => {
		return axios.get<GetSchedulesInterface[]>(`${ROOT_PATH}/by-date-range`, {
			params: { startTime, finishTime, employee },
		})
	}
	static getScheduleHoursByEmployeeHours = (month: string) => {
		return axios.get(`${ROOT_PATH}/by-month-hours`, { params: { month } })
	}

	static deAllocateEmployees = (scheduleId: { ids: string[] }) =>
		axios.put(`${ROOT_PATH}/deallocate-employee`, scheduleId)

	static AllocateEmployeeToScheduleInterface = (data: AllocateEmployeeToScheduleInterface) =>
		axios.put(`${ROOT_PATH}/allocate-employee`, data)

	static deleteSchedule = (scheduleIds: DeleteEmployeeToScheduleInterface) =>
		axios.put(`${ROOT_PATH}/delete`, scheduleIds)

	static scheduleByFilter = (filter: ScheduleFilterInterface) =>
		axios.get(`${ROOT_PATH}/by-custom-filters`, {
			params: { ...filter },
		})

	static getInactiveSchedules = (payload: { employee: string; startDate: string }) =>
		axios.get<GetPostsInterface[]>(`${ROOT_PATH}/inactive-schedule`, { params: payload })

	static createInactiveSchedule = (payload: { postId: string; inActiveSchedule: InActiveSchedule[] }) =>
		axios.post(`${ROOT_PATH}/inactive-schedule`, payload)

	static deleteInactiveSchedule = (payload: { [key: string]: string[] }) =>
		axios.put<{ data: GetPostsInterface[] }>(`${ROOT_PATH}/inactive-schedule`, { payload })
}
