import axios from "axios"

import { EmployeeEquipmentInterface, EmployeeEquipmentIssuedInterface } from "@type/employees.types"

const ROOT_PATH = "employees/equipments-sizes"
const ROOT_PATH_ISSUED = "employees/equipments-issued"

export default class EmployeesEquipmentService {
	static createEmployeeEquipment = (employeeEquipment: EmployeeEquipmentInterface) =>
		axios.post<EmployeeEquipmentInterface[]>(ROOT_PATH, employeeEquipment)

	static getEmployeesEquipment = (employeeId: string) =>
		axios.get<EmployeeEquipmentInterface[]>(`${ROOT_PATH}/${employeeId}`)

	static updateEmployeeEquipment = (id: string, equipmentData: EmployeeEquipmentInterface) =>
		axios.put(`${ROOT_PATH}/${id}`, equipmentData)

	static createEmployeeEquipmentIssued = (employeeEquipment: EmployeeEquipmentIssuedInterface) =>
		axios.post<EmployeeEquipmentIssuedInterface>(ROOT_PATH_ISSUED, employeeEquipment)

	static getEmployeesEquipmentIssued = (employeeId: string) =>
		axios.get<EmployeeEquipmentIssuedInterface>(`${ROOT_PATH_ISSUED}/${employeeId}`)

	static updateEmployeeEquipmentIssued = (id: string, equipmentData: EmployeeEquipmentInterface) =>
		axios.put<EmployeeEquipmentIssuedInterface>(`${ROOT_PATH_ISSUED}/${id}`, equipmentData)
}
