import { createContext } from "react"
import { EmployeeQualificationInterface } from "@type/employees.types"

export interface QualificationContextProps {
	handleRow: (e: EmployeeQualificationInterface | null) => void
	qualification: EmployeeQualificationInterface | undefined
}

const QualificationContext = createContext<QualificationContextProps | null>(null)

export default QualificationContext
