import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
	GetSDEquipmentTypeInterface,
	SDEquipmentTypeInterface,
	UpdateSDEquipmentTypeInterface,
} from "@type/staticData.types"
import { SDEquipmentTypesService } from "services"
import { AppThunk, RootState } from "store"
import { sortByProperty, sortDeepCopyByProperty, updateState } from "config/utils"
export interface SDEquipmentTypeStateInterface {
	list: null | Array<GetSDEquipmentTypeInterface>
	loading: boolean
	saving: boolean
}

const initialState: SDEquipmentTypeStateInterface = {
	list: null,
	loading: false,
	saving: false,
}

const SDEquipmentTypeSlice = createSlice({
	name: "SDEquipmentType",
	initialState,
	reducers: {
		fetchingSDEquipmentType: SDEquipmentType => {
			SDEquipmentType.loading = true
		},
		SDEquipmentTypeFetched: (SDEquipmentType, action: PayloadAction<GetSDEquipmentTypeInterface[]>) => {
			SDEquipmentType.loading = false
			SDEquipmentType.list = action.payload
		},
		SDEquipmentTypeFetchingFailed: SDEquipmentType => {
			SDEquipmentType.loading = false
		},
		savingSDEquipmentType: SDEquipmentTypeData => {
			SDEquipmentTypeData.saving = true
		},
		SDEquipmentTypeSaved: (SDEquipmentType, action: PayloadAction<GetSDEquipmentTypeInterface>) => {
			SDEquipmentType.saving = false
			const [UpdatedValue] = updateState(SDEquipmentType.list, action.payload, "_id")
			SDEquipmentType.list = UpdatedValue
		},
		SDEquipmentTypeSavingFailed: SDEquipmentType => {
			SDEquipmentType.saving = false
		},
	},
})

//REDUCER
export default SDEquipmentTypeSlice.reducer

//ACTIONS
const {
	fetchingSDEquipmentType,
	SDEquipmentTypeFetched,
	SDEquipmentTypeFetchingFailed,
	savingSDEquipmentType,
	SDEquipmentTypeSaved,
	SDEquipmentTypeSavingFailed,
} = SDEquipmentTypeSlice.actions

const getSDEquipmentType =
	(cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingSDEquipmentType())
			const { data: SDEquipmentTypeResponse } = await SDEquipmentTypesService.getAllSDEquipmentType()
			SDEquipmentTypeResponse.sort(sortByProperty("name"))
			cb && cb(SDEquipmentTypeResponse[0]?._id)
			dispatch(SDEquipmentTypeFetched(SDEquipmentTypeResponse))
		} catch (error) {
			dispatch(SDEquipmentTypeFetchingFailed())
		}
	}

const saveSDEquipmentType =
	(SDEquipmentTypeData: Partial<UpdateSDEquipmentTypeInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingSDEquipmentType())
			if (SDEquipmentTypeData?._id)
				data = await SDEquipmentTypesService.updateSDEquipmentType(SDEquipmentTypeData._id, SDEquipmentTypeData)
			else
				data = await SDEquipmentTypesService.createSDEquipmentType(
					SDEquipmentTypeData as SDEquipmentTypeInterface,
				)
			const { data: SDEquipmentTypeResponse } = data
			cb && cb(SDEquipmentTypeResponse._id)
			dispatch(SDEquipmentTypeSaved(SDEquipmentTypeResponse))
		} catch (error) {
			dispatch(SDEquipmentTypeSavingFailed())
		}
	}

export { getSDEquipmentType, saveSDEquipmentType }

//SELECTORS
const selectSDEquipmentTypeState = (state: RootState) => state.staticData.SDEquipmentTypes
const isSDEquipmentTypeLoading = () => (state: RootState) => selectSDEquipmentTypeState(state).loading
const isSDEquipmentTypeSaving = () => (state: RootState) => selectSDEquipmentTypeState(state).saving
const selectSDEquipmentTypeList = createSelector(
	(state: RootState) => state.staticData.SDEquipmentTypes.list,
	list => {
		return sortDeepCopyByProperty(list, "description") as GetSDEquipmentTypeInterface[]
	},
)

const selectSDEquipmentTypeById = (SDEquipmentTypeId: string | null) => (state: RootState) =>
	state.staticData.SDEquipmentTypes?.list?.find(SDEquipmentType => SDEquipmentType._id === SDEquipmentTypeId)

export {
	selectSDEquipmentTypeList,
	selectSDEquipmentTypeState,
	selectSDEquipmentTypeById,
	isSDEquipmentTypeLoading,
	isSDEquipmentTypeSaving,
}
