import { useEffect, useMemo, useState } from "react"
import { AntdButton, Table2, TableSkeleton } from "components"
import { getAdditionalDataByLocationId } from "store/Location/AdditionalDetail/additionalDetail.slice"
import { useAppDispatch, useAppSelector } from "hooks"
import {
	selectAdditionalDetailData,
	selectAdditionalDetailLoading,
} from "store/Location/AdditionalDetail/additionalDetail.slice"
import AdditionalDetailModal from "./AdditionalDetailModal"
import { GetLocationAdditionalDetailInterface } from "@type/locations.types"
import LocationEquipments from "./LocationEquipments"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"
import { selectSelectedLocation } from "store/globalState.slice"
import { Divider, Typography } from "antd"
import { PlusOutlined } from "@ant-design/icons"

const AdditionalDetail = () => {
	const dispatch = useAppDispatch()
	const bankData = useAppSelector(selectAdditionalDetailData())
	const isLoading = useAppSelector(selectAdditionalDetailLoading())

	const selected = useAppSelector(selectSelectedLocation)

	const [modalOpen, setModalOpen] = useState(false)
	const [row, setRow] = useState<GetLocationAdditionalDetailInterface>()

	const locationDetail = useMemo(() => {
		if (bankData) {
			return bankData?.map((d, i) => {
				return {
					...d,
					serialNumber: i + 1,
				}
			})
		}
		return []
	}, [bankData])

	useEffect(() => {
		selected && dispatch(getAdditionalDataByLocationId(selected as string))
	}, [selected])

	return (
		<div className="">
			<Divider orientation="left" plain>
				<Typography.Title level={5}>Additional Details</Typography.Title>
			</Divider>
			<div className="flex justify-end pb-4">
				<AntdButton
					icon={<PlusOutlined />}
					onClick={() => {
						setRow(undefined)
						setModalOpen(true)
					}}
				>
					Add Additional Detail
				</AntdButton>
			</div>

			<div>
				{isLoading ? (
					<TableSkeleton />
				) : (
					<Table2
						columns={[
							{ label: "Sr. No", key: "serialNumber" },
							{ label: "Key", key: "key" },
							{ label: "Pouch", key: "pouch" },
							{ label: "Description", key: "description" },
							{ label: "Position", key: "position" },
							{
								label: "History",
								key: "_id",
								render: ({ row }: any) => (
									<AuditTrail
										id={row["_id"]}
										name={Entities.LOCATION_ADDITIONAL_DETAILS}
										showLabel={false}
									/>
								),
							},
							{
								label: "Action",
								key: "action",
								render: ({ row }) => {
									return (
										<AntdButton
											className="my-1.25"
											onClick={e => {
												e.preventDefault()
												setRow(row)
												setModalOpen(true)
											}}
										>
											Update
										</AntdButton>
									)
								},
							},
						]}
						rows={locationDetail}
					/>
				)}
			</div>

			{modalOpen && (
				<AdditionalDetailModal
					row={row}
					open={modalOpen}
					modalHandler={(close: boolean) => {
						setModalOpen(close)
					}}
				/>
			)}
			<LocationEquipments />
		</div>
	)
}

export default AdditionalDetail
