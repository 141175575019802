import { UseFormRegister, FieldError, FieldErrors } from "react-hook-form"
import cx from "classnames"

interface PropsCheckBoxGroup {
	label: string
	register: UseFormRegister<any>
	errors: { [key: string]: FieldError } | FieldErrors
	name: string
	checkBoxes: { [key: string]: string }[]
	labelClass?: string
	containerClass?: string
}

const CheckBoxGroup: React.FC<PropsCheckBoxGroup> = ({
	labelClass = "",
	label = "",
	register,
	errors,
	name,
	checkBoxes,
	containerClass = "",
	...rest
}) => {
	return (
		<>
			<div className={`flex flex-col ${containerClass}`}>
				{label && <label className={cx("text-dark-alt", labelClass)}>{label}</label>}
				<div className="inline-flex gap-x-5.5">
					{checkBoxes.map(({ label, value }) => (
						<div className="flex flex-col " key={`${label}${value}`}>
							<input className="w-full" type="checkbox" value={value} {...rest} {...register(name)} />
							<label className="text-dark-alt">{label}</label>
						</div>
					))}
				</div>
				{errors[name] && <p className="mt-1 ml-1 text-sm text-danger">{errors[name].message}</p>}
			</div>
		</>
	)
}

export default CheckBoxGroup
