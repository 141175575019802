import { CircleF, GoogleMap, MarkerF } from "@react-google-maps/api"
import { GeocodeLatLngInterface } from "@type/geolocation.types"
import { Slider, SliderSingleProps } from "antd-v5"
import { defaultGeocodeData, defaultPosition } from "config/constants"

interface Props {
	containerClass?: string
	handlePosition?: (value: GeocodeLatLngInterface) => void
	handleRadius?: (radius: number) => void
	position: GeocodeLatLngInterface
	radius: number
}

const marks: SliderSingleProps["marks"] = {
	50: "50m",
	200: "200m",
	400: "400m",
	600: "600m",
	800: "800m",
	980: "980m",
}

const IntractableMap: React.FC<Props> = ({ containerClass, handlePosition, position, radius, handleRadius }) => {
	return (
		<div className="h-full">
			<Slider
				defaultValue={50}
				min={50}
				max={1000}
				marks={marks}
				value={radius || 0}
				onChange={value => {
					handleRadius?.(value)
				}}
			/>

			<GoogleMap
				zoom={18}
				center={position?.lat ? position : defaultGeocodeData.results[0].geometry.location}
				mapContainerClassName={`h-full w-full ${containerClass}`}
				options={{ mapTypeControl: false, disableDefaultUI: true, fullscreenControl: true }}
				onClick={({ latLng }) => {
					handlePosition?.({
						lat: latLng?.lat() || defaultPosition.lat,
						lng: latLng?.lng() || defaultPosition.lng,
					})
				}}
			>
				<MarkerF position={position.lat ? position : defaultGeocodeData.results[0].geometry.location} />
				``
				<CircleF
					center={position.lat ? position : defaultGeocodeData.results[0].geometry.location}
					radius={radius}
					options={{ strokeColor: "#02990E", fillColor: "transparent", strokeWeight: 2 }}
				/>
			</GoogleMap>
		</div>
	)
}

export default IntractableMap
