import axios from "axios"
import { GetPBCodeInterface, PBCodeInterface } from "@type/payBill.types"

const ROOT_PATH = "pay-bill/codes"
export default class PBCodesService {
	static createPBCode = (notifiee: PBCodeInterface) => axios.post<GetPBCodeInterface>(ROOT_PATH, notifiee)

	static getAllPBCodes = (usedFor: string) =>
		axios.get<Array<GetPBCodeInterface>>(`${ROOT_PATH}`, { params: { ...(usedFor ? { usedFor } : {}) } })

	static getPBCodeById = (id: string) => axios.get<GetPBCodeInterface>(`${ROOT_PATH}/${id}`)

	static updatePBCode = (id: string, notifiee: Partial<GetPBCodeInterface>) =>
		axios.put<GetPBCodeInterface>(`${ROOT_PATH}/${id}`, notifiee)
}
