import axios from "axios"
import { GetLocationEquipmentInterface } from "@type/locations.types"

const ROOT_PATH = "locations/equipment"
export default class LocationEquipment {
	static createLocationEquipment = (Data: GetLocationEquipmentInterface) =>
		axios.post<GetLocationEquipmentInterface>(`${ROOT_PATH}`, Data)

	static updateLocationEquipmentById = (id: string, equipmentData: GetLocationEquipmentInterface) =>
		axios.put<GetLocationEquipmentInterface>(`${ROOT_PATH}/${id}`, equipmentData)

	static getLocationEquipment = (locationId: string) => axios.get(`${ROOT_PATH}?location=${locationId}`)
}
