import { AntdButton, DateRangePicker, DocumentField, FormInputAntd } from "components"
import { yupResolver } from "@hookform/resolvers/yup"
import { LdnInterface } from "@type/employees.types"
import { LdnInitialValues, LdnValidationSchema } from "Schema"
import React, { useEffect } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "hooks"
import { momentZ } from "config/moment"
import { DATE_FORMATS } from "config/constants"
import moment from "moment"
import {
	SelectEmployeeQualificationLoading,
	saveLdnForEmployeeQualification,
} from "store/Employee/Qualification/qualification.slice"
import { mediaService } from "services"
import { LdnFormProps } from "../HR/QualificationTable"

const checkDateDifference = (date1: moment.Moment, date2: moment.Moment) => {
	const differenceInWeeks = Math.abs(date1.diff(date2, "weeks"))
	return differenceInWeeks <= 10
}

interface Props {
	ldnFormProps: LdnFormProps
	handleDrawerClose: () => void
}

const LdnForm: React.FC<Props> = ({ ldnFormProps, handleDrawerClose }) => {
	const dispatch = useAppDispatch()
	const loadingEmployeeQualifications = useAppSelector(SelectEmployeeQualificationLoading())
	const methods = useForm<LdnInterface>({
		resolver: yupResolver(LdnValidationSchema),
		mode: "onChange",
		defaultValues: LdnInitialValues,
	})

	const {
		handleSubmit,
		reset,
		setError,
		clearErrors,
		setValue,
		watch,
		formState: { errors },
	} = methods

	useEffect(() => {
		if (ldnFormProps.ldn) reset(ldnFormProps.ldn)
		else reset(LdnInitialValues)

		return () => {
			clearErrors(["startDate", "endDate", "reference", "reference"])
			reset(LdnInitialValues)
		}
	}, [])

	const onSubmit: SubmitHandler<LdnInterface> = data => {
		dispatch(
			saveLdnForEmployeeQualification(ldnFormProps.qualificationId, data, ldnFormProps.ldn ? false : true, () =>
				handleDrawerClose(),
			),
		)
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="space-y-5">
					<FormInputAntd type="text" label="LDN Reference" name="reference" />
					<div className="flex flex-col">
						<div>LDN Period</div>
						<DateRangePicker
							allowClear={false}
							format={DATE_FORMATS.DD_MM_YYYY}
							onChange={value => {
								if (value) {
									clearErrors("startDate")
									const isDatesDifferenceValid = checkDateDifference(
										moment(value[0]),
										moment(value[1]),
									)
									if (isDatesDifferenceValid) {
										setValue("startDate", moment(value[0]).utc().startOf("day").toISOString())
										setValue("endDate", moment(value[1]).utc().endOf("day").toISOString())
									} else setError("startDate", { message: "Difference should be at max 10 Weeks" })
								}
							}}
							value={[
								watch("startDate") ? moment(watch("startDate")) : momentZ(),
								watch("endDate") ? moment(watch("endDate")) : momentZ(),
							]}
						/>
						{!!errors["startDate"]?.message && (
							<p className="mt-1 ml-1 text-sm text-danger">{errors["startDate"].message}</p>
						)}
					</div>
					<div className="flex flex-col">
						<div>LDN Document</div>
						<DocumentField
							documentName={watch("documentPath")}
							loading={false}
							onDocumentChange={async file => {
								const { data: mediaResponse } = await mediaService.upload(file)
								setValue("documentPath", mediaResponse.url[0])
							}}
							name="documentPath"
							errors={errors}
						/>
					</div>
					<AntdButton
						loading={loadingEmployeeQualifications}
						disabled={!watch("documentPath")}
						htmlType="submit"
					>
						{ldnFormProps.ldn ? "Update" : "Save"}
					</AntdButton>
				</div>
			</form>
		</FormProvider>
	)
}

export default LdnForm
