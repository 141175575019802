import axios from "axios"
import { PayRollTermsInterface } from "@type/payBill.types"

const ROOT_PATH = "pay-bill/payroll-terms"
export default class PayRollService {
	static createPayRoll = (type: string) => axios.post<PayRollTermsInterface>(ROOT_PATH, type)

	static getAllPayroll = () => axios.get<Array<PayRollTermsInterface>>(`${ROOT_PATH}`)

	static getPayRollById = (id: string) => axios.get<PayRollTermsInterface>(`${ROOT_PATH}/${id}`)

	static updatePayRoll = (id: string, type: string) => axios.put<PayRollTermsInterface>(`${ROOT_PATH}/${id}`, type)
}
