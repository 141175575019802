import { VALIDATION_REGEX } from "config/constants"
import * as Yup from "yup"
const jafValidationSchema = Yup.object().shape({
	maidenOrFormerNames: Yup.string().required().label("Maiden/Former Names"),
	forenames: Yup.string().required().label("ForeName"),
	address: Yup.string().required().label("Address"),
	mobileTelephoneNumber: Yup.string()
		.label("Mobile")
		.required()
		.matches(VALIDATION_REGEX.mobile, "Invalid Mobile Number"),
	dateOfBirth: Yup.string().required().label("Date of Birth"),
	city: Yup.string().required().label("Nationality"),
	county: Yup.string().required().label("County of Birth"),
	nationInsuranceNumber: Yup.string()
		.required()
		.label("NI Number")
		.matches(VALIDATION_REGEX.nin, "Invalid NI Number"),
	emergencyContactName: Yup.string().required().label("Name"),
	emergencyContactAddress: Yup.string().required().label("Address"),
	emergencyContactTelephoneNumberAtHome: Yup.string()
		.label("Telephone no")
		.required()
		.matches(VALIDATION_REGEX.mobile, "Invalid Mobile Number"),
	//
})

export { jafValidationSchema }
