import * as Yup from "yup"

export const CronNotificationsInitialValues = {
	reportName: "",
	reportTitle: "",
	users: [],
}

export const CronNotificationsSchema = Yup.object().shape({
	reportName: Yup.string().required().label("Name"),
	reportTitle: Yup.string().required().label("Title"),
	users: Yup.array().of(Yup.string()).required().nullable().label("Assigned Users"),
})
