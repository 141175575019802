import * as Yup from "yup"
import { constants } from "config"
import { ConsistsOf, NotificationBy, PermitterForEnum } from "config/constants"

const { VALIDATION_REGEX } = constants

const userInitialValues = {
	userName: "",
	firstName: "",
	lastName: "",
	email: "",
	emailWhenMessage: false,
	mobile: "",
	telephone1: "",
	telephone2: "",
	enabled: true,
	password: "",
	confirmPassword: "",
	shouldChangePassword: false,
	expiryDate: "",
	notes: "",
	inactivityTimer: 0,
}

const usersSchema = Yup.object().shape(
	{
		userName: Yup.string()
			.required("Username is a required field")
			.min(6, "Username must be between 6-20 characters long")
			.matches(
				VALIDATION_REGEX.usernameCheckI,
				"Username must contains only letters, numbers, dots, and underscores",
			)
			.matches(VALIDATION_REGEX.usernameCheckII, "Username can not contain consecutive dots or underscores")
			.matches(VALIDATION_REGEX.usernameCheckIII, "Username can not start or end with dot or underscores")
			.max(20, "Username must be between 6-20 characters long"),
		firstName: Yup.string().required().label("First name"),
		lastName: Yup.string().required().label("Last name"),
		email: Yup.string().email().label("Email"),
		mobile: Yup.string()
			.label("Mobile")
			.when("mobile", {
				is: (value: any) => value,
				then: rule => rule.matches(VALIDATION_REGEX.mobile, "Invalid Mobile Number"),
			}),

		telephone1: Yup.string()
			.label("Telephone")
			.when("telephone1", {
				is: (value: any) => value,
				then: rule => rule.matches(VALIDATION_REGEX.phone, "Invalid Telephone Number"),
			}),
		telephone2: Yup.string()
			.label("Telephone")
			.when("telephone2", {
				is: (value: any) => value,
				then: rule => rule.matches(VALIDATION_REGEX.phone, "Invalid Telephone Number"),
			}),
		password: Yup.lazy(value => {
			if (value && value === "#") return Yup.string().notRequired()
			return Yup.string()
				.required()
				.min(6, "Password is too short - should be 6 chars minimum.")
				.label("Password")
		}),
		confirmPassword: Yup.lazy(value => {
			if (value && value === "#") return Yup.string().notRequired()
			return Yup.string()
				.required()
				.oneOf([Yup.ref("password"), null], "Passwords must match")
				.label("Confirm Password")
		}),

		enabled: Yup.boolean().label("Enabled"),
		receiveNotifications: Yup.boolean().label("Enabled"),
		shouldChangePassword: Yup.boolean().notRequired().label("Change password at next logon"),
		expiryDate: Yup.mixed()
			.notRequired()
			.nullable()
			.label("Expiry Date")
			.typeError("Expiry Date is Invalid")
			.test("is-date", "Invalid date format", value => !value || !isNaN(Date.parse(value))),
		// expiryDate: Yup.date().notRequired().label("Expiry Date").typeError("Expiry Date is Invalid"),
		inactivityTimer: Yup.number().min(0).label("Inactivity Timer"),
		notes: Yup.string().label("Notes"),
	},
	[
		["mobile", "mobile"],
		["telephone1", "telephone1"],
		["telephone2", "telephone2"],
	],
)
const userChangePasswordSchema = Yup.object().shape({
	newPassword: Yup.string().required().min(6, "Password is too short - should be 6 chars minimum.").label("Password"),
	confirmPassword: Yup.lazy(() => {
		return Yup.string()
			.required()
			.oneOf([Yup.ref("newPassword"), null], "Passwords must match")
			.label("Confirm Password")
	}),
})
const userChangePasswordInitialValues = {
	newPassword: "",
	confirmPassword: "",
}

const rolesInitialValues = {
	name: "",
	permittedFor: PermitterForEnum.COMPANY,
	onlyViewableBySuperAdmin: false,
}

const rolesSchema = Yup.object().shape({
	name: Yup.string().required().label("Role Name"),
	permittedFor: Yup.string().required().label("Permitted For"),
})

const notifieesInitialValues = {
	enabled: false,
	recipient: "",
	address: "",
	notificationBy: NotificationBy.EMAIL,
}

const notifieesSchema = Yup.object().shape({
	recipient: Yup.string().required().label("Recipient"),
	address: Yup.string().required().label("Address"),
	enabled: Yup.boolean().required().default(false).label("Enabled"),
	notificationBy: Yup.string().required().label("Notification By"),
})

const workMonitorViewsInitialValues = {
	name: "",
	type: ConsistsOf.BRANCH,
}

const workMonitorViewsSchema = Yup.object().shape({
	name: Yup.string().required().label("Name"),
	type: Yup.string().required().label("Consists of"),
})

export {
	userInitialValues,
	usersSchema,
	rolesSchema,
	notifieesInitialValues,
	notifieesSchema,
	workMonitorViewsSchema,
	workMonitorViewsInitialValues,
	rolesInitialValues,
	userChangePasswordSchema,
	userChangePasswordInitialValues,
}
