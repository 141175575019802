import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAppDispatch, useAppSelector } from "hooks"
import { Modal, DateField, FormSelectAntd, FormInputAntd, AntdButton } from "components"
import { GetLocationEquipmentInterface } from "@type/locations.types"
import { convertIOSDateToHTMLFormat } from "config/utils"
import { addLocationEquipmentSchema, LocationEquipmentInitialValues } from "Schema/Locations/locationSchema"
import { saveLocationEquipmentData, isLocationEquipmentSaving } from "store/Location/locationEquipment.slice"
import { GLOBAL_DATE_FORMAT } from "config/constants"
import { selectSelectedLocation } from "store/globalState.slice"

interface Props {
	open: boolean
	row: GetLocationEquipmentInterface | undefined
	modalHandler: (open: boolean) => void
}

enum LocationEquipments {
	FIRST_AID_KIT = "1st Aid Kit",
	EAR_DEFENDERS = "Ear Defenders",
	FIREPROOF_GLOVES = "FireProof Gloves",
	FLAME_RETARDANT_JACKET = "Flame Retardant Jacket",
	HARD_HAT = "Hard Hat",
	HI_VIS_COAT = "Hi-Vis Coat",
	HI_VIZ_VEST = "Hi-Viz Vest",
	ICE_SPIKES = "Ice Spikes",
	MOBILE_PHONE = "Mobile Phone",
	TORCH = "Torch",
	VEHICLE = "Vehicle",
}

const LocationEquipment = [
	{ _id: LocationEquipments.FIRST_AID_KIT, name: "1st Aid Kit" },
	{ _id: LocationEquipments.EAR_DEFENDERS, name: "Ear Defenders" },
	{ _id: LocationEquipments.FIREPROOF_GLOVES, name: "FireProof Gloves" },
	{ _id: LocationEquipments.FLAME_RETARDANT_JACKET, name: "Flame Retardant Jacket" },
	{ _id: LocationEquipments.HARD_HAT, name: "Hard Hat" },
	{ _id: LocationEquipments.HI_VIS_COAT, name: "Hi-Vis Coat" },
	{ _id: LocationEquipments.HI_VIZ_VEST, name: "Hi-Viz Vest" },
	{ _id: LocationEquipments.ICE_SPIKES, name: "Ice Spikes" },
	{ _id: LocationEquipments.MOBILE_PHONE, name: "Mobile Phone" },
	{ _id: LocationEquipments.TORCH, name: "Torch" },
	{ _id: LocationEquipments.VEHICLE, name: "Vehicle" },
]

const LocationEquipmentModal: React.FC<Props> = ({ open, modalHandler, row }) => {
	const dispatch = useAppDispatch()

	const isSaving = useAppSelector(isLocationEquipmentSaving())
	const selected = useAppSelector(selectSelectedLocation)

	const methods = useForm<GetLocationEquipmentInterface>({
		resolver: yupResolver(addLocationEquipmentSchema),
		shouldUnregister: true,
	})

	const {
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { isDirty, isSubmitting },
	} = methods

	const onSubmit = (data: GetLocationEquipmentInterface) => {
		selected &&
			dispatch(
				saveLocationEquipmentData({ ...data, location: selected as string, _id: row?._id as string }, () =>
					modalHandler(false),
				),
			)
	}

	useEffect(() => {
		if (row && row !== undefined) {
			reset({
				...row,
				issueDate: convertIOSDateToHTMLFormat(row.issueDate, GLOBAL_DATE_FORMAT),
				returnDate: convertIOSDateToHTMLFormat(row.returnDate, GLOBAL_DATE_FORMAT),
				checked: convertIOSDateToHTMLFormat(row.checked, GLOBAL_DATE_FORMAT),
				nextCheck: convertIOSDateToHTMLFormat(row.nextCheck, GLOBAL_DATE_FORMAT),
			})
		} else {
			reset(LocationEquipmentInitialValues)
		}
	}, [row])

	return (
		<Modal
			open={open}
			title={<div className="py-4 text-center text-md font-semibold">Equipment</div>}
			modalHandler={modalHandler}
		>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="grid grid-cols-3 gap-y-3 gap-x-6">
						<FormSelectAntd
							disabled={!LocationEquipment.length}
							label="Equipment"
							name="equipment"
							options={LocationEquipment.map(({ _id, name }) => ({ label: name, value: _id }))}
						/>
						<FormInputAntd type="text" label="Quantity" name="quantity" />
						<FormInputAntd type="text" name="serialNo" label="SerialNo" />
						<DateField label={"IssueDate"} setValue={setValue} watch={watch} name="issueDate" />
						<DateField label={"Return Date"} setValue={setValue} watch={watch} name="returnDate" />
						<DateField label={"Checked"} setValue={setValue} watch={watch} name="checked" />
						<DateField label={"Next Check"} setValue={setValue} watch={watch} name="nextCheck" />
						<div className="col-span-2">
							<FormInputAntd name="notes" label="Notes" type="textarea" />
						</div>
					</div>
					<AntdButton
						loading={isSaving}
						disabled={!isDirty || isSubmitting}
						className="mt-5"
						htmlType="submit"
					>
						{row ? "Update" : "Save"}
					</AntdButton>
				</form>
			</FormProvider>
		</Modal>
	)
}

export default LocationEquipmentModal
