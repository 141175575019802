import { createSlice } from "@reduxjs/toolkit"
import { AppThunk, RootState } from "store"
import { loginTrailService } from "services"
import { GetLoginTailInterface } from "@type/loginTrail.types"

interface LoginTrailStateInterface {
	list: GetLoginTailInterface[]
	loading: boolean
	saving: boolean
}

const initialState: LoginTrailStateInterface = {
	list: [],
	loading: false,
	saving: false,
}

const loginTrailSlice = createSlice({
	name: "loginTrail",
	initialState,
	reducers: {
		fetchingLoginTrailData: state => {
			state.loading = true
		},
		loginTrailDataFetched: (state, action) => {
			state.list = action.payload
			state.loading = false
		},

		loginTrailDataFailed: state => {
			state.loading = false
		},
	},
})

//ACTIONS
export const { fetchingLoginTrailData, loginTrailDataFetched, loginTrailDataFailed } = loginTrailSlice.actions

const getLoginTrail =
	(user: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingLoginTrailData())
			const { data: loginTrailResponse } = await loginTrailService.getLoginTrail(user)
			dispatch(loginTrailDataFetched(loginTrailResponse))
		} catch (error) {
			dispatch(loginTrailDataFailed)
		}
	}

export { getLoginTrail }
//REDUCER
export default loginTrailSlice.reducer

//SELECTORS

const selectLoginTrailState = (state: RootState) => state.loginTrail
const selectLoginTrailList = () => (state: RootState) => selectLoginTrailState(state).list
const selectLoginTrailIsLoading = () => (state: RootState) => selectLoginTrailState(state).loading

export { selectLoginTrailList, selectLoginTrailIsLoading }
