import React, { useEffect, useMemo, useRef, useState } from "react"
import { GlobalSidebar, SidebarList, FormInputAntd, FormSelectAntd, AntdButton, FormCheckbox } from "components"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { SDQualificationInitialValues, SDQualificationSchema } from "Schema"
import { SDQualificationInterface } from "@type/staticData.types"
import { PBCodeOptions } from "config/constants"
import useAppSelector from "hooks/useAppSelector"
import useAppDispatch from "hooks/useAppDispatch"
import {
	getSDQualifications,
	isSDQualificationsLoading,
	isSDQualificationsSaving,
	saveSDQualification,
	selectSDQualificationsById,
	selectSDQualificationsList,
} from "store/StaticData/SDQualifications.slice"
import { getPBCodes, selectPBCodeDropdownList } from "store/PayBill/PBCodes.slice"

interface SDQualificationFormHandler extends SDQualificationInterface {}

const SDQualifications = () => {
	const [selectedSDQualification, setSelectedSDQualification] = useState<string | null>("")
	const dispatch = useAppDispatch()
	const loading = useAppSelector(isSDQualificationsLoading())
	const saving = useAppSelector(isSDQualificationsSaving())
	const SDQualificationsList = useAppSelector(selectSDQualificationsList)
	const SDQualification = useAppSelector(selectSDQualificationsById(selectedSDQualification))
	const PBCodes = useAppSelector(selectPBCodeDropdownList)
	const isNewBeingCreated = useRef(false)

	const methods = useForm<SDQualificationFormHandler>({ resolver: yupResolver(SDQualificationSchema) })
	const {
		handleSubmit,
		reset,
		formState: { isDirty, isSubmitting, isSubmitted },
	} = methods

	const sidebarSDQualifications = useMemo(() => {
		return SDQualificationsList?.map(SDQualification => ({ _id: SDQualification._id, name: SDQualification.name }))
	}, [saving, SDQualificationsList?.length, isSubmitted])

	const onSubmit: SubmitHandler<SDQualificationFormHandler> = data => {
		isNewBeingCreated.current = true
		dispatch(
			saveSDQualification(data, id => {
				setSelectedSDQualification(id)
				isNewBeingCreated.current = false
			}),
		)
	}

	useEffect(() => {
		dispatch(getSDQualifications(setSelectedSDQualification))
		dispatch(getPBCodes(PBCodeOptions.QUALIFICATION))
	}, [])

	useEffect(() => {
		if (selectedSDQualification !== (null && undefined)) {
			reset({ ...SDQualificationInitialValues, ...SDQualification })
		} else if (selectedSDQualification === null && !isNewBeingCreated.current) {
			reset({ ...SDQualificationInitialValues })
		}
	}, [selectedSDQualification])

	const handleSelected = (SDQualificationId: string | null): void => setSelectedSDQualification(SDQualificationId)

	return (
		<>
			<GlobalSidebar>
				<SidebarList
					title={"SDQualifications"}
					loading={loading}
					selected={selectedSDQualification}
					setSelected={handleSelected}
					list={sidebarSDQualifications}
				/>
			</GlobalSidebar>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="flex w-1/4 flex-col space-y-3">
						<div className="flex flex-col space-y-3">
							<FormInputAntd type="text" label="Qualification Name" name="name" />
							<FormSelectAntd
								disabled={!PBCodes.length}
								label="Qualification PB Code"
								name="PBCode"
								options={PBCodes}
							/>
							<FormSelectAntd
								disabled={!PBCodes.length}
								label="Matched PB Code"
								name="matchedPBCode"
								options={PBCodes}
							/>
							<FormCheckbox label="Can be Overridden" name="canBeOverridden" />
						</div>
						<div className="flex gap-2">
							<AntdButton onClick={() => setSelectedSDQualification(null)}>New</AntdButton>
							<AntdButton disabled={!isDirty || isSubmitting} loading={saving} htmlType="submit">
								{selectedSDQualification ? "Update" : "Save"}
							</AntdButton>
						</div>
					</div>
				</form>
			</FormProvider>
		</>
	)
}

export default SDQualifications
