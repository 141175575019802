import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EmployeeDocumentInterface } from "@type/employees.types"
import { documentsService } from "services"
import { AppThunk, RootState } from "store"

export interface DocumentStateInterface {
	data: null | EmployeeDocumentInterface[]
	loading: boolean
	saving: boolean
}

const initialState: DocumentStateInterface = {
	data: null,
	loading: false,
	saving: false,
}

const documnetsSlice = createSlice({
	name: "documents",
	initialState,
	reducers: {
		requestDocumentData: document => {
			document.loading = true
		},
		receiveDocumentData: (document, action: PayloadAction<EmployeeDocumentInterface[]>) => {
			document.loading = false
			document.data = action.payload
		},
		receiveDocumentDataFailed: document => {
			document.loading = false
		},
		requestSavingDocumentData: documentData => {
			documentData.saving = true
		},
		SavingEmployeeDocumentSuccess: (document, action: PayloadAction<EmployeeDocumentInterface>) => {
			document.loading = false
			const index = document.data?.findIndex(d => d._id === action.payload._id)
			if (index !== -1) {
				document.data?.splice(index as number, 1, action.payload)
			} else {
				document.data = [...(document.data as EmployeeDocumentInterface[]), action.payload]
			}
		},
		SavingEmployeeDocumentSuccessFailes: document => {
			document.loading = false
		},
	},
})

//REDUCER
export default documnetsSlice.reducer

//ACTIONS
const {
	requestDocumentData,
	receiveDocumentData,
	receiveDocumentDataFailed,
	requestSavingDocumentData,
	SavingEmployeeDocumentSuccess,
	SavingEmployeeDocumentSuccessFailes,
} = documnetsSlice.actions

const getEmployeesDocumentByEmployeeId =
	(employeeId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestDocumentData())
			const { data: documentResponse } = await documentsService.getEmployeesDocument(employeeId)
			dispatch(receiveDocumentData(documentResponse))
		} catch (error) {
			dispatch(receiveDocumentDataFailed())
		}
	}

const saveEmployeeDocumentData =
	(documentData: EmployeeDocumentInterface, cb?: () => void): AppThunk =>
	async dispatch => {
		try {
			let data = null
			dispatch(requestSavingDocumentData())
			if (documentData._id) {
				data = await documentsService.updateEmployeeDocument(documentData._id, documentData)
			} else {
				data = await documentsService.createEmployeeDocument(documentData)
			}
			const { data: documentResponse } = data
			cb?.()
			dispatch(SavingEmployeeDocumentSuccess(documentResponse))
		} catch (error) {
			dispatch(SavingEmployeeDocumentSuccessFailes())
		}
	}

export { saveEmployeeDocumentData, getEmployeesDocumentByEmployeeId }

//SELECTORS
const selectEmployeeDocumentState = (state: RootState) => state.employee.documents
const isEmployeeDocumentLoading = () => (state: RootState) => selectEmployeeDocumentState(state).loading
const selectEmployeeDocumentData = () => (state: RootState) => selectEmployeeDocumentState(state).data
const selectLoadingEmployeeDocument = () => (state: RootState) => selectEmployeeDocumentState(state).loading

export { isEmployeeDocumentLoading, selectEmployeeDocumentData, selectLoadingEmployeeDocument }
