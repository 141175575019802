import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { apiConfig, socketConfig } from "config"
import { authService } from "services"
import { GetProfileResponseInterface, LoginInterface, LoginResponseInterface } from "@type/auth.types"
import { AppThunk, RootState } from "store"
import { caseConverters } from "config/utils"
import { IS_SUPER_ADMIN } from "config/constants"
import { socket } from "config/socket"
import { ClearBranchState } from "./Branches/branch.slice"
import { ClearEmployeeList } from "./Employee/detail.slice"
import { ClearLocationDetailsState } from "./Location/locationDetails.slice"
import { ClearSDSuppliersState } from "./StaticData/SDSuppliers.slice"
import { clearCustomerState } from "./Customers/customers.slice"
import { clearSchedule } from "./Schedules/schedules.slice"
import { clearWorkMonitor } from "./workMonitor/workMonitor.slice"
import { clearUsersState } from "./Security/users.slice"

export interface AuthStateInterface {
	initialAuthDone: boolean
	accessToken: string | null
	user: null | GetProfileResponseInterface
	loading: boolean
	permissions: string[]
}

const initialState: AuthStateInterface = {
	initialAuthDone: false,
	accessToken: "",
	user: null,
	permissions: [],
	loading: !!authService.getAuthToken(),
}

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		loggingIn: state => {
			state.loading = true
		},
		loggedIn: (state, action: PayloadAction<LoginResponseInterface & GetProfileResponseInterface>) => {
			const { token, ...rest } = action.payload
			state.accessToken = token
			state.user = rest
			state.permissions = [
				...new Set(action.payload.roles.flatMap(item => item.functionsPermitted)),
				...(rest.isSuperAdmin ? [IS_SUPER_ADMIN] : []),
			]
			state.loading = false
			state.initialAuthDone = true
		},
		loggingInFailed: state => {
			state.loading = false
		},
		clearAuthState: state => {
			state.initialAuthDone = false
			state.accessToken = null
			state.user = null
			state.loading = false
			state.initialAuthDone = false
			state.permissions = []
		},
	},
})

// REDUCER
export default authSlice.reducer

// ACTIONS
const { loggedIn, loggingIn, loggingInFailed, clearAuthState } = authSlice.actions

const establishAuth =
	(token: string): AppThunk =>
	async dispatch => {
		try {
			apiConfig.setAuthToken(token)
			socketConfig.initializeSocketConnection(token)
			authService.setAuthToken(token)
			const { data: profileResponse } = await authService.getProfile()
			dispatch(loggedIn({ token, ...profileResponse }))
		} catch {
			dispatch(loggingInFailed())
		}
	}

const login =
	(credentials: LoginInterface): AppThunk =>
	async dispatch => {
		try {
			dispatch(loggingIn())
			const { data: loginResponse } = await authService.login(credentials)
			dispatch(establishAuth(loginResponse.token))
		} catch (error) {
			dispatch(loggingInFailed())
		}
	}

export const reLogin = (): AppThunk => async dispatch => {
	try {
		const token = authService.getAuthToken()
		if (!token) return
		dispatch(establishAuth(token))
	} catch (error) {
		dispatch(loggingInFailed())
	}
}

const logout = (): AppThunk => async dispatch => {
	authService.logout()
	socket.disconnect()
	dispatch(clearAuthState())
	dispatch(ClearBranchState())
	dispatch(ClearEmployeeList())
	dispatch(ClearLocationDetailsState())
	dispatch(ClearSDSuppliersState())
	dispatch(clearCustomerState())
	dispatch(clearSchedule())
	dispatch(clearWorkMonitor())
	dispatch(clearUsersState())

	// window.location.reload()
}
const getRememberMe = () => authService.getUserRememberMe()
const setRememberMe = (value: string) => authService.setUserRememberMe(value)

export { login, getRememberMe, setRememberMe }

// SELECTORS
const selectAuthState = (state: RootState) => state.auth
const selectCurrentUser = () => (state: RootState) => selectAuthState(state).user
const selectCurrentUserRoles = () => (state: RootState) =>
	selectAuthState(state).user?.roles.map(item => caseConverters.constantCase(item?.name ?? "")) || []
const selectCurrentUserPermissions = () => (state: RootState) => selectAuthState(state).permissions
const selectLoadingAuth = () => (state: RootState) => selectAuthState(state).loading
const selectInitialAuthDone = () => (state: RootState) => selectAuthState(state).initialAuthDone
const selectAccessToken = () => (state: RootState) => selectAuthState(state).accessToken
const selectCurrentUserShouldChangePassword = () => (state: RootState) =>
	selectAuthState(state).user?.shouldChangePassword
const selectSignedIn = () => (state: RootState) =>
	selectAccessToken()(state) && !selectCurrentUserShouldChangePassword()(state)

export {
	selectCurrentUser,
	selectLoadingAuth,
	selectInitialAuthDone,
	selectSignedIn,
	logout,
	selectCurrentUserRoles,
	selectCurrentUserPermissions,
	selectCurrentUserShouldChangePassword,
}
