import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetPBRuleDetailsInterface, PBRuleDetailsInterface, UpdatePBRuleDetailsInterface } from "@type/payBill.types"
import { PBRuleDetailsService } from "services"
import { AppThunk, RootState } from "store"
import { sortByProperty, sortDeepCopyByProperty, updateState } from "config/utils"
export interface PBRuleDetailsStateInterface {
	list: null | Array<GetPBRuleDetailsInterface>
	loading: boolean
	saving: boolean
}

const initialState: PBRuleDetailsStateInterface = {
	list: null,
	loading: false,
	saving: false,
}

const PBRuleDetailsSlice = createSlice({
	name: "PBRuleDetails",
	initialState,
	reducers: {
		fetchingPBRuleDetails: PBRuleDetails => {
			PBRuleDetails.loading = true
		},
		PBRuleDetailsFetched: (PBRuleDetails, action: PayloadAction<GetPBRuleDetailsInterface[]>) => {
			PBRuleDetails.loading = false
			PBRuleDetails.list = action.payload
		},
		PBRuleDetailsFetchingFailed: PBRuleDetails => {
			PBRuleDetails.loading = false
		},
		savingPBRuleDetails: PBRuleDetailsData => {
			PBRuleDetailsData.saving = true
		},
		PBRuleDetailsSaved: (PBRuleDetails, action: PayloadAction<GetPBRuleDetailsInterface>) => {
			PBRuleDetails.saving = false
			const [UpdatedValue] = updateState(PBRuleDetails.list, action.payload, "_id")
			PBRuleDetails.list = UpdatedValue
		},
		PBRuleDetailsSavingFailed: PBRuleDetails => {
			PBRuleDetails.saving = false
		},
	},
})

//REDUCER
export default PBRuleDetailsSlice.reducer

//ACTIONS
const {
	fetchingPBRuleDetails,
	PBRuleDetailsFetched,
	PBRuleDetailsFetchingFailed,
	savingPBRuleDetails,
	PBRuleDetailsSaved,
	PBRuleDetailsSavingFailed,
} = PBRuleDetailsSlice.actions

const getPBRuleDetails =
	(cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingPBRuleDetails())
			const { data: PBRuleResponse } = await PBRuleDetailsService.getAllPBRuleDetails()
			PBRuleResponse.sort(sortByProperty("name"))
			cb && cb(PBRuleResponse[0]?._id)
			dispatch(PBRuleDetailsFetched(PBRuleResponse))
		} catch (error) {
			dispatch(PBRuleDetailsFetchingFailed())
		}
	}

const getPBRuleDetailsByRuleId =
	(id: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingPBRuleDetails())
			const { data: PBRuleResponse } = await PBRuleDetailsService.getPBRuleDetailsById(id)
			dispatch(PBRuleDetailsFetched(PBRuleResponse))
		} catch (error) {
			dispatch(PBRuleDetailsFetchingFailed())
		}
	}

const savePBRuleDetails =
	(PBRuleDetailsData: Partial<UpdatePBRuleDetailsInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingPBRuleDetails())
			if (PBRuleDetailsData?._id)
				data = await PBRuleDetailsService.updatePBRuleDetails(PBRuleDetailsData._id, PBRuleDetailsData)
			else data = await PBRuleDetailsService.createPBRuleDetails(PBRuleDetailsData as PBRuleDetailsInterface)
			const { data: PBRuleResponse } = data
			cb && cb(PBRuleResponse._id)
			dispatch(PBRuleDetailsSaved(PBRuleResponse))
		} catch (error) {
			dispatch(PBRuleDetailsSavingFailed())
		}
	}

export { getPBRuleDetails, getPBRuleDetailsByRuleId, savePBRuleDetails }

//SELECTORS
const selectPBRuleDetailsState = (state: RootState) => state.payBill.PBRuleDetails
const isPBRuleDetailsLoading = () => (state: RootState) => selectPBRuleDetailsState(state).loading
const isPBRuleDetailsSaving = () => (state: RootState) => selectPBRuleDetailsState(state).saving
const selectPBRuleDetailsList = createSelector(
	(state: RootState) => state.payBill.PBRuleDetails.list,
	list => {
		return sortDeepCopyByProperty(list, "name") as GetPBRuleDetailsInterface[]
	},
)

const selectFilteredPBRuleDetailsList = (PBRuleId: string) => (state: RootState) =>
	state.payBill.PBRuleDetails.list?.filter(ruleDetails => ruleDetails.PBRule === PBRuleId) || []

const selectPBRuleDetailsById = (PBRuleId: string | null) => (state: RootState) =>
	state.payBill.PBRuleDetails?.list?.find(PBRuleDetails => PBRuleDetails._id === PBRuleId)

export {
	selectPBRuleDetailsList,
	selectFilteredPBRuleDetailsList,
	selectPBRuleDetailsState,
	selectPBRuleDetailsById,
	isPBRuleDetailsLoading,
	isPBRuleDetailsSaving,
}
