import axios from "axios"
import { LocationDocumentInterface } from "@type/locations.types"

const ROOT_PATH = "locations/documents"

export default class LocationdocumentService {
	static createLocationDocument = (locationDocs: LocationDocumentInterface) =>
		axios.post<LocationDocumentInterface>(ROOT_PATH, locationDocs)

	static getLocationDocument = (locationId: string) =>
		axios.get<LocationDocumentInterface[]>(`${ROOT_PATH}?location=${locationId}`)

	static updateLocationDocument = (id: string, documentData: LocationDocumentInterface) =>
		axios.put<LocationDocumentInterface>(`${ROOT_PATH}/${id}`, documentData)
}
