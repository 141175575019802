import React, { useEffect, useRef, useState } from "react"
import { AntdButton, DateField, FormInputAntd, FormSelectAntd, Table2 } from "components"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { PBTermDetailsInitialValues, PBTermDetailsSchema } from "Schema"
import { PBTermDetailsInterface } from "@type/payBill.types"
import useAppSelector from "hooks/useAppSelector"
import useAppDispatch from "hooks/useAppDispatch"
import {
	isPBTermDetailsLoading,
	isPBTermDetailsSaving,
	savePBTermDetails,
	selectPBTermDetailsById,
	getPBTermDetailsByRuleId,
	selectFilteredPBTermDetailsList,
} from "store/PayBill/PBTermDetails.slice"
import { GLOBAL_DATE_FORMAT, PBCodeOptions } from "config/constants"
import {
	getPBCodes,
	isPBCodeLoading,
	selectPBCodeDropdownList,
	selectPBCodeIdToLabel,
} from "store/PayBill/PBCodes.slice"
import { convertIOSDateToHTMLFormat } from "config/utils"

interface PBTermDetailsFormHandler extends PBTermDetailsInterface {}

interface Props {
	termId: string
}

const PBTermDetails: React.FC<Props> = ({ termId }) => {
	const [selectedPBTermDetails, setSelectedPBTermDetails] = useState<string | null>(null)
	const dispatch = useAppDispatch()
	const PBTermDetailsLoading = useAppSelector(isPBTermDetailsLoading())
	const saving = useAppSelector(isPBTermDetailsSaving())
	const PBTermDetailsList = useAppSelector(selectFilteredPBTermDetailsList(termId))
	const PBCodesList = useAppSelector(selectPBCodeDropdownList)
	const PBCodesIdToLabel = useAppSelector(selectPBCodeIdToLabel)
	const PBCodeLoading = useAppSelector(isPBCodeLoading())
	const PBTermDetails = useAppSelector(selectPBTermDetailsById(selectedPBTermDetails))
	const isNewBeingCreated = useRef(false)
	const loading = PBCodeLoading && PBTermDetailsLoading
	const methods = useForm<PBTermDetailsFormHandler>({
		resolver: yupResolver(PBTermDetailsSchema),
		defaultValues: PBTermDetailsInitialValues,
	})
	const {
		reset,
		setValue,
		watch,
		handleSubmit,
		formState: { isSubmitting, errors, isDirty },
	} = methods

	const onSubmit: SubmitHandler<PBTermDetailsFormHandler> = data => {
		isNewBeingCreated.current = true
		dispatch(
			savePBTermDetails({ ...data }, id => {
				setSelectedPBTermDetails(id)
				isNewBeingCreated.current = false
			}),
		)
	}

	useEffect(() => {
		if (termId) dispatch(getPBTermDetailsByRuleId(termId))
	}, [termId])

	useEffect(() => {
		dispatch(getPBCodes(PBCodeOptions.TERMS))
	}, [])

	useEffect(() => {
		if (selectedPBTermDetails !== (null && undefined) && PBTermDetails) {
			reset({ ...PBTermDetails, PBTerm: termId || "" })
		} else if ((selectedPBTermDetails === null && !isNewBeingCreated.current) || !termId) {
			reset({ ...PBTermDetailsInitialValues, ...(termId ? { PBTerm: termId } : {}) })
		}
	}, [selectedPBTermDetails, loading, termId])

	return (
		<>
			<div className="space-y-5">
				<div>
					<div className="text-5">Details</div>
					<FormProvider {...methods}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="flex-col space-y-4">
								<div className="flex">
									<div className="grid w-full grid-cols-4 gap-x-3 gap-y-2">
										<DateField
											label="Start Date"
											name={"startDate"}
											setValue={setValue}
											watch={watch}
											errors={errors}
										/>
										<FormSelectAntd
											disabled={!PBCodesList.length}
											label="PB Code"
											name="PBCode"
											options={PBCodesList}
										/>
										<DateField
											label="Period Base Date"
											name={"periodBaseDate"}
											setValue={setValue}
											watch={watch}
											errors={errors}
										/>
										<FormInputAntd type="number" min={0} label="Period Days" name="periodDays" />
										<FormInputAntd
											type="number"
											label="Daily OT1 Hours"
											name="dailyOt1Hours"
											min={0}
										/>
										<FormInputAntd
											type="number"
											label="Daily OT2 Hours"
											name="dailyOt2Hours"
											min={0}
										/>
										<FormInputAntd
											type="number"
											label="Period OT1 Hours"
											name="periodOt1Hours"
											min={0}
										/>
										<FormInputAntd
											type="number"
											label="Period OT2 Hours"
											name="periodOt2Hours"
											min={0}
										/>
										<FormInputAntd
											type="number"
											label="Rest Period Hours"
											name="restPeriodHours"
											min={0}
										/>
									</div>
								</div>
								<div className="flex space-x-2">
									<AntdButton htmlType="button" onClick={() => setSelectedPBTermDetails(null)}>
										New
									</AntdButton>
									<AntdButton disabled={!isDirty || isSubmitting} loading={saving} htmlType="submit">
										{selectedPBTermDetails ? "Update" : "Save"}
									</AntdButton>
								</div>
							</div>
						</form>
					</FormProvider>
				</div>

				<Table2
					headerClassName="px-0"
					rows={PBTermDetailsList ? PBTermDetailsList : []}
					columns={[
						{
							label: "Sr. No",
							key: "serialNumber",
							render: data => <div>{PBTermDetailsList && PBTermDetailsList?.indexOf(data.row) + 1}</div>,
						},

						{
							label: "Start Date",
							key: "startDate",
							render: ({ row, key }) => convertIOSDateToHTMLFormat(row[key], GLOBAL_DATE_FORMAT),
						},

						{
							label: "Pb Code",
							key: "PBCode",
							render: ({ row, key }: any) => <div>{PBCodesIdToLabel[row[key]]}</div>,
						},
						{
							label: "Daily Ot 1 Hours",
							key: "dailyOt1Hours",
							render: ({ row, key }: any) => <div>{row[key]}</div>,
						},
						{
							label: "Daily Ot 2 Hours",
							key: "dailyOt2Hours",
							render: ({ row, key }: any) => <div>{row[key]}</div>,
						},
						{
							label: "Period Ot 1 Hours",
							key: "periodOt1Hours",
							render: ({ row, key }: any) => <div>{row[key]}</div>,
						},
						{
							label: "Period Ot 2 Hours",
							key: "periodOt2Hours",
							render: ({ row, key }: any) => <div>{row[key]}</div>,
						},
						{
							label: "Period Base Date",
							key: "periodBaseDate",
							render: ({ row, key }: any) => convertIOSDateToHTMLFormat(row[key], GLOBAL_DATE_FORMAT),
						},
						{
							label: "Period Days",
							key: "periodDays",
							render: ({ row, key }: any) => <div>{row[key]}</div>,
						},
						{
							label: "Rest Period Hours",
							key: "restPeriodHours",
							render: ({ row, key }: any) => <div>{row[key]}</div>,
						},
						{
							label: "Actions",
							key: "action",
							render: ({ row }) => {
								return (
									<AntdButton className="h-9 py-1" onClick={() => setSelectedPBTermDetails(row._id)}>
										Update
									</AntdButton>
								)
							},
						},
					]}
				/>
			</div>
		</>
	)
}

export default PBTermDetails
