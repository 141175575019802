import { GoogleMap, InfoWindow, Marker, MarkerClusterer } from "@react-google-maps/api"
import { GeocodeLatLngInterface } from "@type/geolocation.types"
import { defaultMapViewOptions } from "config/constants"
import React, { useCallback, useEffect, useState } from "react"

interface Props {
	marker: GeocodeLatLngInterface[]
}

const MapSection: React.FC<Props> = ({ marker }) => {
	const [map, setMap] = useState<null | google.maps.Map>(null)
	const [selectedMarker, setSelectedMarker] = useState<GeocodeLatLngInterface | null>(null)

	const onLoad = useCallback((map: google.maps.Map) => {
		setMap(map)
	}, [])

	const onUnmount = useCallback(() => {
		setMap(null)
	}, [])

	useEffect(() => {
		if (marker.length === 1) {
			map?.panTo(marker[0])
			map?.setZoom(13)
		} else {
			map?.panTo({ lat: 53.4722454, lng: -2.2234628 })
			map?.setZoom(5)
		}
	}, [marker, map])

	return (
		<GoogleMap
			onLoad={onLoad}
			onUnmount={onUnmount}
			zoom={10}
			mapContainerStyle={{ width: "100%", height: "100%" }}
			options={{
				mapTypeControl: false,
				disableDefaultUI: true,
				fullscreenControl: true,
				styles: defaultMapViewOptions,
			}}
		>
			{!!marker.length && (
				<MarkerClusterer key={marker.length} minimumClusterSize={4} maxZoom={14}>
					{clusterer => (
						<>
							{marker?.map((marker, index) => (
								<Marker
									position={marker}
									key={`${JSON.stringify(marker)}${index}`}
									clusterer={clusterer}
									onMouseOver={() => setSelectedMarker(marker)}
								/>
							))}

							{selectedMarker && selectedMarker.markerHoverInfo?.title && (
								<InfoWindow
									position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
									onCloseClick={() => setSelectedMarker(null)}
								>
									<div>{selectedMarker.markerHoverInfo?.title}</div>
								</InfoWindow>
							)}
						</>
					)}
				</MarkerClusterer>
			)}
		</GoogleMap>
	)
}

export default MapSection
