import { LocationIncidentInterface } from "@type/locations.types"
import { IncidentInterface } from "@type/workMonitor.types"

import axios from "axios"

const ROOT_PATH = "locations/incidents"

export class locationIncidentsService {
	static getLocationIncidents = (location: string) =>
		axios.get<LocationIncidentInterface[]>(`${ROOT_PATH}/${location}`)

	static getLocationShiftIncidents = (location: string) =>
		axios.get<IncidentInterface[]>(`locations/shift-incidents/${location}`)

	static saveLocationIncident = (payload: any) => axios.post<LocationIncidentInterface>(ROOT_PATH, payload)

	static updateLocationIncident = (id: string, payload: any) =>
		axios.put<LocationIncidentInterface>(`${ROOT_PATH}/${id}`, payload)
}
