import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetRoleInterface, RoleInterface, UpdateRoleInterface } from "@type/security.types"
import { rolesService } from "services"
import { AppThunk, RootState } from "store"
import { caseConverters, sortByProperty, sortDeepCopyByProperty, updateState } from "config/utils"
export interface RolesStateInterface {
	list: GetRoleInterface[]
	userAccess: any
	loading: boolean
	saving: boolean
	loadingAllowedResources: boolean
}

const initialState: RolesStateInterface = {
	list: [],
	userAccess: [],
	loading: false,
	loadingAllowedResources: false,
	saving: false,
}

const rolesSlice = createSlice({
	name: "roles",
	initialState,
	reducers: {
		fetchingRoles: role => {
			role.loading = true
		},
		fetchingAllowedResources: role => {
			role.loadingAllowedResources = true
		},
		rolesFetched: (role, action: PayloadAction<GetRoleInterface[]>) => {
			role.loading = false
			role.list = action.payload
		},
		rolesFetchingFailed: role => {
			role.loading = false
		},
		allowedResourcesFetchingFailed: role => {
			role.loadingAllowedResources = false
		},
		savingRole: roleData => {
			roleData.saving = true
		},
		roleSaved: (role, action: PayloadAction<GetRoleInterface>) => {
			role.saving = false
			const [UpdatedValue] = updateState(role.list, action.payload, "_id")
			role.list = UpdatedValue
		},
		roleSavingFailed: role => {
			role.saving = false
		},
		allowedResourcesFetched: (role, action: PayloadAction<any[]>) => {
			role.loadingAllowedResources = false
			role.userAccess = action.payload
		},
	},
})

//REDUCER
export default rolesSlice.reducer

//ACTIONS
const {
	fetchingRoles,
	rolesFetched,
	rolesFetchingFailed,
	savingRole,
	roleSaved,
	roleSavingFailed,
	allowedResourcesFetched,
	allowedResourcesFetchingFailed,
	fetchingAllowedResources,
} = rolesSlice.actions

const getRoles =
	(cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingRoles())
			const { data: roleResponse } = await rolesService.getAllRoles()
			roleResponse.sort(sortByProperty("name"))
			roleResponse[0]._id && cb && cb(roleResponse[0]?._id)
			dispatch(rolesFetched(roleResponse))
		} catch (error) {
			dispatch(rolesFetchingFailed())
		}
	}

const getUserAccess = (): AppThunk => async dispatch => {
	try {
		dispatch(fetchingAllowedResources())
		const { data: roleResponse } = await rolesService.getUserRoleByOrganization()
		dispatch(allowedResourcesFetched(roleResponse))
	} catch (error) {
		dispatch(allowedResourcesFetchingFailed())
	}
}

const saveRole =
	(roleData: Partial<UpdateRoleInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingRole())
			if (roleData?._id) data = await rolesService.updateRole(roleData._id, roleData)
			else data = await rolesService.createRole(roleData as RoleInterface)
			const { data: roleResponse } = data
			roleResponse?._id && cb(roleResponse?._id)
			dispatch(roleSaved(roleResponse))
		} catch (error) {
			dispatch(roleSavingFailed())
		}
	}

export { getRoles, saveRole, getUserAccess }

//SELECTORS
const selectRoleState = (state: RootState) => state.security.roles
const selectRolesAsAbacRules = (state: RootState) =>
	Object.fromEntries(
		state.security?.roles?.list?.map(item => [
			caseConverters.constantCase(item?.name ?? ""),
			Object.fromEntries(item?.functionsPermitted?.map(permission => [permission, true])),
		]) || [],
	)

const isLoadingRoles = () => (state: RootState) => selectRoleState(state).loading
const isLoadingAllowedResources = () => (state: RootState) => selectRoleState(state).loadingAllowedResources
const isRoleSaving = () => (state: RootState) => selectRoleState(state).saving
const selectRoleList = createSelector(
	(state: RootState) => state.security.roles.list,
	list => {
		return (sortDeepCopyByProperty(list, "name") as GetRoleInterface[]) ?? []
	},
)

const selectRoleById = (roleId: string | null) => (state: RootState) =>
	state.security.roles?.list?.find(role => role._id === roleId)

const selectUserAccessRoles = () => (state: RootState) => selectRoleState(state).userAccess

export {
	selectRoleList,
	selectRoleState,
	selectRoleById,
	isLoadingRoles,
	isRoleSaving,
	selectRolesAsAbacRules,
	selectUserAccessRoles,
	isLoadingAllowedResources,
}
