import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "hooks"

import Form from "./Form"
import DocumentTable from "./DocumentTable"

import { getEmployeesDocumentByEmployeeId } from "store/Employee/Documents/documents.slice"
import DocumentContext from "./documentContext"

import { EmployeeDocumentInterface } from "@type/employees.types"
import { selectSelectedEmployee } from "store/globalState.slice"

const Documents = () => {
	const dispatch = useAppDispatch()

	const selected = useAppSelector(selectSelectedEmployee)

	const [document, setDocument] = useState<EmployeeDocumentInterface | undefined>()

	const handleRow = (e: any) => setDocument(e)

	useEffect(() => {
		selected && dispatch(getEmployeesDocumentByEmployeeId(selected))
	}, [selected])

	return (
		<DocumentContext.Provider value={{ handleRow, document }}>
			<div className="flex space-x-16">
				<div className="w-1/6">
					<Form />
				</div>
				<div className="w-3/5 pt-5">
					<DocumentTable />
				</div>
			</div>
		</DocumentContext.Provider>
	)
}

export default Documents
