import React, { useEffect, useState, useLayoutEffect } from "react"
import { useAppDispatch, useAppSelector } from "hooks"

import { GlobalSidebar, TableSkeleton } from "components"
import BranchSideBar from "pages/branch/BranchSideBar"
import FilterOptions from "./FilterOptions"
import ScheduleFilterTable from "./ScheduleFilterTable"

import { filteredListBySearch, selectBranchList } from "store/Branches/branch.slice"
import { clearSchedule, selectScheduleLoading } from "store/Schedules/schedules.slice"

let searchString1 = ""
let timoutId: any = null

const SchedulesFilter: React.FC = () => {
	const dispatch = useAppDispatch()
	const [selected, setSelected] = useState<string | null>(null)
	const [branchSelection, setBranchSelection] = useState<string>("")
	const [searchString, setSearchString] = useState("")
	const [searchOnFields] = useState(["name"])
	const isScheduleLoading = useAppSelector(selectScheduleLoading())

	const branches = useAppSelector(selectBranchList())
	const filteredList = useAppSelector(filteredListBySearch(searchString, searchOnFields))

	useLayoutEffect(() => {
		if (selected) dispatch(clearSchedule())
	}, [selected])

	useLayoutEffect(() => {
		dispatch(clearSchedule())
	}, [])

	useEffect(() => {
		if (branches.length) {
			handleSelected(filteredList[0]._id)
		}
	}, [branches])

	const createTimemout = (time: any) => {
		return setTimeout(() => {
			setBranchSelection("")
			searchString1 = ""
		}, time)
	}

	const onKeyUpHandler = (e: any) => {
		if (timoutId) clearTimeout(timoutId)
		searchString1 += e.key
		setBranchSelection(prev => {
			let prev1 = prev
			prev1 += e.key

			return prev1
		})
		const filteredBranches = branches?.filter(({ name }) =>
			`${name}`.toLowerCase().includes(searchString1.toLowerCase()),
		)
		if (filteredBranches?.length) {
			setSelected(filteredBranches[0]._id)
		}
		timoutId = createTimemout(500)
	}

	const handleSelected = (branchId: string | null): void => setSelected(branchId)

	return (
		<>
			<GlobalSidebar>
				<div className="h-full outline-none">
					<BranchSideBar
						setSearchString={setSearchString}
						searchText={branchSelection}
						branches={filteredList}
						selected={selected}
						handleSelected={handleSelected}
						onKeyUpCapture={onKeyUpHandler}
					/>
				</div>
			</GlobalSidebar>
			<FilterOptions branch={selected} />
			{!isScheduleLoading ? <ScheduleFilterTable /> : <TableSkeleton />}
		</>
	)
}

export default SchedulesFilter
