import React, { useEffect, useState } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useAppSelector, useAppDispatch } from "hooks"
import { AntdButton, FormSelectAntd, Table2, TableSkeleton } from "components"
import { EmployeeUsualWorkFormInterface } from "@type/employees.types"
import { selectBranchList } from "store/Branches/branch.slice"
import {
	getLocationsByBranchId,
	isLocationLoading,
	selectLocationListByBranchId,
} from "store/Location/locationDetails.slice"
import {
	getEmployeesUsualWorkByEmployeeId,
	isEmployeeUsualWorkLoading,
	isEmployeeUsualWorkSaving,
	saveEmployeeUsualWorkData,
	selectEmployeeUsualWorkById,
	selectEmployeeUsualWorkData,
} from "store/Employee/Location/employeeUsualWork.slice"
import { yupResolver } from "@hookform/resolvers/yup"
import { employeeUsualWorkValidationSchema, usualWorkInitialValues } from "Schema"
import { AllowedTo } from "react-abac"
import { Permissions } from "config/constants"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"
import { SelectedPropInterface } from "services/generic/type"

interface LocationFormHandler extends EmployeeUsualWorkFormInterface {}

const EmployeeUsualWork: React.FC<SelectedPropInterface> = ({ selected }) => {
	const [selectedUsualWork, setSelectedUsualWork] = useState<string | null>(null)
	const employeeUsualWorks = useAppSelector(selectEmployeeUsualWorkData())
	const dispatch = useAppDispatch()
	const branches = useAppSelector(selectBranchList())
	const locationLoading = useAppSelector(isLocationLoading())
	const employeeUsualWorkLoading = useAppSelector(isEmployeeUsualWorkLoading())
	const selectedEmployeeUsualWork = useAppSelector(selectEmployeeUsualWorkById(selectedUsualWork))
	const employeeUsualWorkSaving = useAppSelector(isEmployeeUsualWorkSaving())

	const methods = useForm<LocationFormHandler>({
		resolver: yupResolver(employeeUsualWorkValidationSchema),
	})
	const { handleSubmit, watch, reset, setValue } = methods
	const locations = useAppSelector(selectLocationListByBranchId(watch("branch")))

	useEffect(() => {
		if (selected) {
			dispatch(getEmployeesUsualWorkByEmployeeId(selected as string))
			reset(usualWorkInitialValues)
			setSelectedUsualWork(null)
		}
	}, [selected])

	useEffect(() => {
		if (selectedEmployeeUsualWork) {
			const { branch, location } = selectedEmployeeUsualWork
			reset({ branch: branch._id, location: location._id })
		}
		if (!selectedUsualWork) reset(usualWorkInitialValues)
	}, [selectedUsualWork])

	useEffect(() => {
		if (!employeeUsualWorkSaving) {
			setSelectedUsualWork(null)
			reset(usualWorkInitialValues)
		}
	}, [employeeUsualWorkSaving])

	const onSubmit: SubmitHandler<LocationFormHandler> = data => {
		dispatch(
			saveEmployeeUsualWorkData(
				selectedUsualWork
					? { _id: selectedUsualWork, ...data, employee: selected as string }
					: { ...data, employee: selected as string },
			),
		)
	}
	useEffect(() => {
		if (watch("branch")) dispatch(getLocationsByBranchId(watch("branch")))
	}, [watch("branch"), employeeUsualWorks])

	return (
		<div className="space-y-4 pl-3">
			<div className="text-xl">Usual Work Location</div>
			<div>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="grid grid-cols-3 items-end gap-x-5">
							<FormSelectAntd
								label="Branch"
								name="branch"
								onChange={val => {
									setValue("location", "")
									setValue("branch", val)
								}}
								options={branches.map(({ _id, name }) => ({ label: name, value: _id }))}
							/>

							<FormSelectAntd
								loading={locationLoading}
								label="Location"
								name="location"
								value={locationLoading ? "" : watch("location")}
								options={locations.map(({ _id, name }) => ({ label: name, value: _id }))}
							/>
							<AllowedTo perform={Permissions.UPDATE_EMPLOYEE_LOCATIONS}>
								<AntdButton
									htmlType="submit"
									className="w-min"
									loading={employeeUsualWorkSaving}
									disabled={employeeUsualWorkSaving}
								>
									{selectedUsualWork ? "Update" : "Save"}
								</AntdButton>
							</AllowedTo>
						</div>
					</form>
				</FormProvider>
			</div>
			<div>
				{!employeeUsualWorkLoading && !employeeUsualWorkSaving ? (
					<Table2
						rows={employeeUsualWorks as any}
						columns={[
							{
								label: "Branch",
								key: "branch",
								render: ({ row, key }: any) => <div> {row[key]["name"]}</div>,
							},
							{
								label: "Location",
								key: "location",
								render: ({ row, key }: any) => <div>{row[key]["name"]}</div>,
							},
							{
								label: "SIN",
								key: "location",
								render: ({ row, key }: any) => <div> {row[key]["sin"]}</div>,
							},
							{
								label: "History",
								key: "_id",
								render: ({ row }: any) => (
									<AuditTrail id={row["_id"]} name={Entities.EMPLOYEE_USUAL_WORK} showLabel={false} />
								),
							},
							{
								label: "Actions",
								key: "action",
								render: ({ row }) => {
									return (
										<AntdButton
											className="w-min justify-center py-1"
											disabled={selectedUsualWork == row._id}
											onClick={() => setSelectedUsualWork(row._id)}
										>
											{selectedUsualWork !== row._id ? "Update" : "Editing"}
										</AntdButton>
									)
								},
							},
						]}
					/>
				) : (
					<TableSkeleton />
				)}
			</div>
		</div>
	)
}

export default EmployeeUsualWork
