import { DirectionsRenderer, GoogleMap, MarkerF } from "@react-google-maps/api"
import { VALIDATION_REGEX, defaultGeocodeData } from "config/constants"
import useAppDispatch from "hooks/useAppDispatch"
import useAppSelector from "hooks/useAppSelector"
import React, { useEffect, useMemo, useState } from "react"
import { FieldError, FieldErrors } from "react-hook-form"
import {
	getDrivingDistanceBetweenCoordinates,
	getPostalCodeCoordinates,
	isDrivingDistanceLoading,
	selectDrivingDistance,
	selectPostalCodeData,
	selectPostalCodeErrors,
} from "store/Geolocation/geolocation.slice"
import Input from "./Input"
import { GeocodeLatLngInterface } from "@type/geolocation.types"
import { debounce, meterToMiles } from "config/utils"

interface Props {
	zoom?: number
	postalCode: string
	errors: { [key: string]: FieldError | undefined } | FieldErrors
	containerClass?: string
	coordinates?: GeocodeLatLngInterface
	isDirty?: boolean | undefined
	showDistance?: boolean
}

const Map: React.FC<Props> = ({
	postalCode,
	errors,
	zoom = 15,
	containerClass = "",
	coordinates,
	isDirty,
	showDistance = true,
}) => {
	const dispatch = useAppDispatch()
	const [mapPostalCode, setMapPostalCode] = useState<string>("")
	const [directions, setDirections] = useState<any>("")

	const originCoordinates = useAppSelector(selectPostalCodeData(postalCode))
	const destinationCoordinates = useAppSelector(selectPostalCodeData(mapPostalCode))
	const travelDistance = useAppSelector(selectDrivingDistance())
	const loadingDistance = useAppSelector(isDrivingDistanceLoading())
	const postalCodeError = useAppSelector(selectPostalCodeErrors(mapPostalCode))

	const check = useMemo(() => debounce(600), [])

	useEffect(() => {
		if (postalCode && !errors.postalCode && isDirty) {
			check(() => dispatch(getPostalCodeCoordinates(postalCode)))
		}
	}, [!errors.postalCode, isDirty])

	const position = useMemo(
		() => (coordinates && !isDirty ? coordinates : originCoordinates),
		[coordinates, isDirty, originCoordinates],
	)

	const handleInput = (e: any) => {
		setMapPostalCode(e.target.value)
		if (mapPostalCode && VALIDATION_REGEX.postalCode.test(e.target.value)) {
			if (coordinates) dispatch(getDrivingDistanceBetweenCoordinates(e.target.value, position))
		}
	}
	const travelDistanceInMiles = useMemo(
		() => meterToMiles(travelDistance.distance.value),
		[travelDistance.distance.value],
	)

	const fetchDirections = () => {
		if (!destinationCoordinates) return

		const service = new google.maps.DirectionsService()
		service.route(
			{
				origin: position,
				destination: destinationCoordinates,
				travelMode: google.maps.TravelMode.DRIVING,
			},
			(result, status) => {
				if (status === "OK" && result) setDirections(result)
			},
		)
	}

	useMemo(() => {
		check(() => fetchDirections())
	}, [destinationCoordinates])

	return (
		<div className="h-full">
			<GoogleMap
				zoom={zoom}
				center={position || defaultGeocodeData.results[0].geometry.location}
				mapContainerClassName={`h-full w-full ${containerClass}`}
				options={{ mapTypeControl: false, disableDefaultUI: true, fullscreenControl: true }}
			>
				<MarkerF position={position || defaultGeocodeData.results[0].geometry.location} />
				{directions && (
					<DirectionsRenderer
						directions={directions}
						options={{
							polylineOptions: { zIndex: 50, strokeWeight: 6, strokeColor: "#00b0ff", strokeOpacity: 1 },
						}}
					/>
				)}
				{showDistance && position && (
					<div className="absolute flex flex-col justify-between  space-y-1 bg-[#dddcd588] py-2 px-2 pt-2">
						<div className="flex items-center">
							<Input
								className="relative h-8 w-full border border-secondary-light py-0 hover:border-primary1"
								type="text"
								onChange={handleInput}
								placeholder="Calculate Distance by Postal Code"
								loading={loadingDistance}
								loadingClass="top-3 right-6 "
								loaderSize={22}
							/>
						</div>
						{postalCodeError && (
							<div className={`pl-3 text-sm font-bold text-red-600`}>Invalid Postal Code</div>
						)}
						<div className="text-s flex justify-start space-x-6 rounded bg-white py-1 px-2 font-bold">
							<div className="">{`Distance : ${travelDistanceInMiles || "0.0"} mi`}</div>
							<div className="">{`Duration : ${travelDistance.duration.text || "0"}`}</div>
						</div>
					</div>
				)}
			</GoogleMap>
		</div>
	)
}

export default Map
