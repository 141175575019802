import React from "react"
import { DatePicker } from "antd"
import { RangeValue } from "rc-picker/lib/interface"
import { Moment } from "moment"
import "antd/dist/antd.min.css"

const { RangePicker } = DatePicker

interface Props {
	format?: string
	placeholder?: [string, string]
	allowClear?: boolean
	value: RangeValue<Moment>
	onChange: (values: RangeValue<Moment>, formatString: [string, string]) => void
}

const DateRangePicker: React.FC<Props> = ({ allowClear = true, placeholder, format, value, onChange }) => {
	return (
		<RangePicker
			style={{ border: "solid slate", height: "48px", minWidth: "280px" }}
			size={"large"}
			placeholder={placeholder}
			allowClear={allowClear}
			value={value}
			format={format}
			onChange={onChange}
		/>
	)
}

export default DateRangePicker
