import React, { useContext, useMemo } from "react"
import { useAppSelector } from "hooks"
import { AntdButton, Table2 } from "components"
import { selectLocationEscalationsData, isLocationEscalationsLoading } from "store/Location/locationEscalations.slice"
import LocationEscalationContext, { LocationEscalationContextInterface } from "./EscalationContext"
import { TableSkeleton } from "components"
import { convertIOSDateToHTMLFormat } from "config/utils"
import { GLOBAL_DATE_FORMAT } from "config/constants"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"

const AdditionalDataTable: React.FC = () => {
	const { handleLocationEscalationHandler } = useContext(
		LocationEscalationContext,
	) as LocationEscalationContextInterface
	const EscalationsData = useAppSelector(selectLocationEscalationsData())
	const loadingEscalations = useAppSelector(isLocationEscalationsLoading())

	const changedEscalationsData = useMemo(
		() =>
			EscalationsData?.map((escalation, i) => ({
				...escalation,
				serialNumber: i + 1,
			})),

		[EscalationsData],
	)

	if (loadingEscalations) return <TableSkeleton />

	return (
		<div>
			<Table2
				columns={[
					{
						label: "Sr. No",
						key: "serialNumber",
					},
					{ label: "Name", key: "name" },
					{ label: "Details", key: "details" },
					{
						label: "Reviewed",
						key: "reviewed",
						render: ({ row, key }) => convertIOSDateToHTMLFormat(row[key], GLOBAL_DATE_FORMAT),
					},
					{ label: "Reviewed By", key: "reviewedBy" },
					{
						label: "History",
						key: "_id",
						render: ({ row }: any) => (
							<AuditTrail id={row["_id"]} name={Entities.LOCATION_ESCALATIONS} showLabel={false} />
						),
					},
					{
						label: "Action",
						key: "action",
						render: data => (
							<AntdButton
								onClick={() => {
									handleLocationEscalationHandler(data.row)
								}}
							>
								Update
							</AntdButton>
						),
					},
				]}
				rows={changedEscalationsData?.length ? changedEscalationsData : []}
			/>
		</div>
	)
}

export default AdditionalDataTable
