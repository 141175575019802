import React, { useEffect, useMemo } from "react"
import { FormSelectAntd, FormCheckbox, AntdButton } from "components"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import FormSkeleton from "components/FormSkeleton"
import { yupResolver } from "@hookform/resolvers/yup"
import { locationQualificationInitialValues, locationQualificationValidationSchema } from "../../../Schema"
import { useAppDispatch, useAppSelector } from "hooks"
import isEqual from "lodash/isEqual"
import {
	isLocationQualificationSaving,
	isLocationQualificationLoading,
	saveLocationQualification,
	getLocationPostQualification,
	selectLocationQualification,
} from "../../../store/Location/locationQualification.slice"
import { GetLocationQualificationInterface } from "@type/locations.types"
import { Transfer } from "components/Transfer"
import { SelectEmployeeQualificationLoading } from "store/Employee/Qualification/qualification.slice"
import {
	getLocationContractsByLocationId,
	isLocationContractsLoading,
	selectLocationContractsDataDropDown,
} from "store/Location/locationContracts.slice"
import {
	clearPostList,
	getLocationPostsByContractId,
	isLocationPostsLoading,
	selectLocationPostsDataDropDown,
} from "store/Location/locationPosts.slice"
import { getSDQualifications, selectSDQualificationsDropdownList } from "store/StaticData/SDQualifications.slice"
import { AllowedTo } from "react-abac"
import { Permissions } from "config/constants"
import { selectSelectedLocation } from "store/globalState.slice"

interface QualificationFormHandler extends GetLocationQualificationInterface {}

const LocationQualification: React.FC = () => {
	const methods = useForm<QualificationFormHandler>({ resolver: yupResolver(locationQualificationValidationSchema) })
	const {
		handleSubmit,
		reset,
		setValue,
		watch,
		resetField,
		formState: { isSubmitting },
	} = methods

	const selected = useAppSelector(selectSelectedLocation)

	const dispatch = useAppDispatch()
	const saving = useAppSelector(isLocationQualificationSaving)
	//state
	const [requiredQualifications, setRequiredQualifications] = React.useState<string[]>([])
	// const [selectedContract, setSelectedContract] = React.useState<string>("")
	// const [selectedPost, setSelectedPost] = React.useState<string>("")

	//selectors
	// const locationQualification = useAppSelector(selectLocationQualificationByPostId(watch("post") || ""))
	const locationQualification = useAppSelector(selectLocationQualification())
	const contracts = useAppSelector(selectLocationContractsDataDropDown(selected as string))
	const posts = useAppSelector(selectLocationPostsDataDropDown())
	const qualificationsMapped = useAppSelector(selectSDQualificationsDropdownList)
	//loaders
	const isLoadingQualifications = useAppSelector(SelectEmployeeQualificationLoading())
	const isLoadingLocationQualifications = useAppSelector(isLocationQualificationLoading())
	const isLoadingContracts = useAppSelector(isLocationContractsLoading())
	const isLoadingPosts = useAppSelector(isLocationPostsLoading())

	//memo
	const isRequiredQualificationsChanged = useMemo(() => {
		// For New Qualification
		if (!locationQualification) {
			if (requiredQualifications?.length) return true
		} else {
			return !isEqual(
				[...requiredQualifications].sort(),
				[...locationQualification.requiredQualifications].sort(),
			)
		}
		return false
	}, [requiredQualifications?.length, locationQualification, isLoadingLocationQualifications])

	const isCreatingRequiredQualification = useMemo(() => {
		return !locationQualification && !isLoadingLocationQualifications ? true : false
	}, [locationQualification, isLoadingLocationQualifications])

	// effects
	useEffect(() => {
		if (locationQualification) {
			if (posts.length) {
				handleRequiredQualificationsChange([...locationQualification.requiredQualifications])
				reset({ ...locationQualification, contract: watch("contract") })
			} else setRequiredQualifications([])
		} else {
			resetField("requiredQualifications")
			setRequiredQualifications([])
		}
	}, [locationQualification])

	useEffect(() => {
		dispatch(getSDQualifications())
	}, [])

	useEffect(() => {
		if (contracts.length) handleContractSelect(contracts[0].value)
	}, [contracts.length])

	useEffect(() => {
		dispatch(getLocationContractsByLocationId(selected))
		reset({ ...locationQualificationInitialValues })
	}, [selected])

	useEffect(() => {
		if (requiredQualifications) setValue("requiredQualifications", requiredQualifications)
	}, [requiredQualifications?.length])

	useEffect(() => {
		handleContractSelect(watch("contract") || "")
		reset({ ...locationQualificationInitialValues, contract: watch("contract") })
	}, [watch("contract")])

	useEffect(() => {
		handlePostSelect(watch("post") || "")
	}, [watch("post")])

	//handlers
	const onSubmit: SubmitHandler<QualificationFormHandler> = ({ post, ...data }) => {
		dispatch(
			saveLocationQualification({
				...data,
				...(data.allPosts
					? { posts: posts.map(({ value }) => value).filter(item => !!item) }
					: { posts: [post] }),
			}),
		)
		setValue("allPosts", false)
	}

	const handleRequiredQualificationsChange = (list: string[]) => setRequiredQualifications(list)

	const handleContractSelect = (value: string) => {
		setValue("contract", value)
		value === "" ? dispatch(clearPostList()) : dispatch(getLocationPostsByContractId(value))
	}

	const handlePostSelect = (value: string) => {
		if (value) dispatch(getLocationPostQualification(value))
		else setRequiredQualifications([])
	}

	if (isLoadingContracts && isLoadingPosts) return <FormSkeleton />
	return (
		<div className="p-3">
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="grid grid-cols-5">
						<div className="col-span-3 grid grid-cols-2 gap-y-3  gap-x-10">
							<FormSelectAntd
								disabled={!contracts.length}
								label="Contract"
								name="contract"
								// value={watch("contract")}
								options={contracts}
								loading={isLoadingContracts}
							/>
							<FormSelectAntd
								disabled={!contracts.length || !posts.length}
								label="Post"
								name="post"
								options={[...posts]}
								// value={watch("post")}
								loading={isLoadingPosts}
							/>
							<FormCheckbox label="All Post" name="allPosts" />
						</div>
					</div>
					<Transfer
						columnClass="h-50 w-[400px]"
						disabled={!contracts.length || !posts.length || !watch("post")}
						isLoading={isLoadingQualifications}
						isLoadingTarget={isLoadingLocationQualifications}
						dataSource={qualificationsMapped}
						previousKeys={requiredQualifications}
						onChange={handleRequiredQualificationsChange}
						sourceListTitle="Available Qualifications"
						targetListTitle="Post Qualifications"
					/>
					<AllowedTo perform={Permissions.UPDATE_LOCATION_QUALIFICATIONS}>
						<AntdButton
							disabled={isSubmitting || !isRequiredQualificationsChanged || !watch("post")}
							loading={saving}
							className="mt-5"
							htmlType="submit"
						>
							{isCreatingRequiredQualification ? "Save" : "Update"}
						</AntdButton>
					</AllowedTo>
				</form>
			</FormProvider>
		</div>
	)
}

export default LocationQualification
