import React, { useEffect, useState } from "react"
import { AntdButton, TextArea } from "."
import Modal from "./Modal"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	confirmationText: string
	onConfirmation: (notes: string) => void
	confirmationAction: boolean
	loader?: boolean
}

const ConfirmationModal: React.FC<Props> = ({
	open,
	loader = false,
	modalHandler,
	confirmationText = "yes",
	onConfirmation,
	confirmationAction = false,
}) => {
	const [notes, setNotes] = useState<string>("")

	useEffect(() => {
		setNotes("")
	}, [open])

	const onClick = () => {
		onConfirmation(notes)
	}

	return (
		<Modal open={open} modalHandler={modalHandler} title={<p className="text-lg">Are you sure?</p>}>
			<div className="justify-center sm:flex sm:items-start">
				<div className="w-full">
					<div className="max-w-full pb-4">
						<TextArea
							labelClass="flex flex-col text-sm"
							className="h-20 min-w-full resize-none"
							label="Notes"
							value={notes}
							onChange={({ target: { value } }) => setNotes(value)}
						/>
					</div>
					<div className="flex justify-end space-x-2">
						<AntdButton onClick={() => modalHandler(false)}>Cancel</AntdButton>
						<AntdButton
							disabled={confirmationAction}
							onClick={onClick}
							loading={loader}
							className="capitalize text-black"
						>
							{confirmationText}
						</AntdButton>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default ConfirmationModal
