import { sortByProperty, updateState } from "config/utils"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetPayGroupsInterface, CreatePayGroupsInterface } from "@type/payGroup.types"
import { AppThunk, RootState } from "store"
import SDBillGroupService from "services/staticData/SDBillGroups.service"

export interface payGroupStateInterface {
	list: GetPayGroupsInterface[]
	loading: boolean
	saving: boolean
}

const initialState: payGroupStateInterface = {
	list: [],
	loading: false,
	saving: false,
}

const SDBillGroupSlice = createSlice({
	name: "SDBillGroup",
	initialState,
	reducers: {
		fetchingSDBillGroup: SDBillGroup => {
			SDBillGroup.loading = true
		},
		SDBillGroupFetched: (SDBillGroup, action: PayloadAction<GetPayGroupsInterface[]>) => {
			SDBillGroup.loading = false
			SDBillGroup.list = action.payload
		},
		SDBillGroupFetchingFailed: SDBillGroup => {
			SDBillGroup.loading = false
		},
		savingSDBillGroup: SDBillGroupData => {
			SDBillGroupData.saving = true
		},
		SDBillGroupSaved: (SDBillGroup, action: PayloadAction<GetPayGroupsInterface>) => {
			SDBillGroup.saving = false
			const [UpdatedValue, NewAdded] = updateState(SDBillGroup.list, action.payload, "_id")

			if (NewAdded) {
				SDBillGroup.list = UpdatedValue
			}
		},
		SDBillGroupSavingFailed: SDBillGroup => {
			SDBillGroup.saving = false
		},
	},
})

//REDUCER
export default SDBillGroupSlice.reducer

//ACTIONS
const {
	fetchingSDBillGroup,
	SDBillGroupFetched,
	SDBillGroupFetchingFailed,
	savingSDBillGroup,
	SDBillGroupSaved,
	SDBillGroupSavingFailed,
} = SDBillGroupSlice.actions

const getSDBillGroup =
	(cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingSDBillGroup())
			const { data: payGroupResponse } = await SDBillGroupService.getAllSDBillGroup()
			payGroupResponse.sort(sortByProperty("name"))
			if (cb) cb(payGroupResponse[0]?._id)
			dispatch(SDBillGroupFetched(payGroupResponse))
		} catch (error) {
			dispatch(SDBillGroupFetchingFailed())
		}
	}

const saveSDBillGroup =
	(payGroupData: Partial<GetPayGroupsInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingSDBillGroup())
			if (payGroupData?._id) data = await SDBillGroupService.updateSDBillGroup(payGroupData._id, payGroupData)
			else data = await SDBillGroupService.createSDBillGroup(payGroupData as CreatePayGroupsInterface)
			const { data: payGroupResponse } = data
			cb && cb(payGroupResponse._id)
			dispatch(SDBillGroupSaved(payGroupResponse))
		} catch (error) {
			dispatch(SDBillGroupSavingFailed())
		}
	}

export { getSDBillGroup, saveSDBillGroup }

//SELECTORS
const selectSDBillGroupState = (state: RootState) => state.billGroup
const isSDBillGroupLoading = () => (state: RootState) => selectSDBillGroupState(state).loading
const selectSDBillGroupData = () => (state: RootState) => selectSDBillGroupState(state).list || []
const isSDBillGroupSaving = () => (state: RootState) => selectSDBillGroupState(state).saving
const selectPayGroupById = (id: string) => (state: RootState) =>
	selectSDBillGroupState(state)?.list?.find(item => item._id === id)
export { selectSDBillGroupData, selectSDBillGroupState, isSDBillGroupSaving, isSDBillGroupLoading, selectPayGroupById }
