interface AuditTrailInterFace {
	_id: string
	changes: {
		index: any
		isArray: boolean
		to: any
		from: any
		path: string
		ops: string
		label: string
		employeeBranchOrSupplier?: string
	}[]
	action: string
	module: string
	documentNumber: string
	method: string
	createdAt: Date
}

export interface GetAuditTrailInterFace extends AuditTrailInterFace {
	user: { firstName: string; lastName: string }
}
export interface StateAuditTrailInterFace extends AuditTrailInterFace {
	user: string
}

export enum AUDIT_ACTIONS {
	"CREATED" = "CREATED",
	"UPDATED" = "UPDATED",
	"DELETED" = "DELETED",
}

export enum Entities {
	EMPLOYEE_DETAILS = "EMPLOYEE_DETAILS",
	EMPLOYEE_SERVICES = "EMPLOYEE_SERVICES",
	EMPLOYEE_QUALIFICATIONS = "EMPLOYEE_QUALIFICATIONS",
	EMPLOYEE_DOCUMENTS = "EMPLOYEE_DOCUMENTS",
	EMPLOYEE_EQUIPMENTS_SIZES = "EMPLOYEE_EQUIPMENTS_SIZES",
	EMPLOYEE_EQUIPMENTS_ISSUED = "EMPLOYEE_EQUIPMENTS_ISSUED",
	EMPLOYEE_HR = "EMPLOYEE_HR",
	EMPLOYEE_TRAINING = "EMPLOYEE_TRAINING",
	EMPLOYEE_USUAL_WORK = "EMPLOYEE_USUAL_WORK",
	EMPLOYEE_ADDITIONAL_DETAIL = "EMPLOYEE_ADDITIONAL_DETAIL",
	EMPLOYEE_BANK_DETAILS = "EMPLOYEE_BANK_DETAILS",
	EMPLOYEE_HOLIDAY = "EMPLOYEE_HOLIDAY",
	EMPLOYEE_SICKNESS = "EMPLOYEE_SICKNESS",
	EMPLOYEE_TERM = "EMPLOYEE_TERM",
	EMPLOYEE_BAN = "EMPLOYEE_BAN",
	BRANCHES = "BRANCHES",
	CUSTOMERS = "CUSTOMERS",
	LOCATION_ADDITIONAL_DETAILS = "LOCATION_ADDITIONAL_DETAILS",
	LOCATION_CALL_TIMES = "LOCATION_CALL_TIMES",
	LOCATION_CONTACTS = "LOCATION_CONTACTS",
	LOCATION_CONTRACTS = "LOCATION_CONTRACTS",
	LOCATION_DOCUMENTS = "LOCATION_DOCUMENTS",
	LOCATION_EQUIPMENTS = "LOCATION_EQUIPMENTS",
	LOCATION_ESCALATIONS = "LOCATION_ESCALATIONS",
	LOCATION_POSTS = "LOCATION_POSTS",
	LOCATION_QUALIFICATIONS = "LOCATION_QUALIFICATIONS",
	LOCATIONS = "LOCATIONS",
	LOCATION_TIME_CAPTURES = "LOCATION_TIME_CAPTURES",
	NOTIFIEES = "NOTIFIEES",
	PAY_BILL_CODES = "PAY_BILL_CODES",
	PAY_BILL_HOLIDAY_SCHEDULES = "PAY_BILL_HOLIDAY_SCHEDULES",
	PAY_BILL_RULES = "PAY_BILL_RULES",
	PAY_BILL_RULES_DETAILS = "PAY_BILL_RULES_DETAILS",
	PAY_BILLS = "PAY_BILLS",
	PAY_BILL_TERM_DETAILS = "PAY_BILL_TERM_DETAILS",
	PAY_BILL_TERMS = "PAY_BILL_TERMS",
	PAY_GROUP_HOLIDAY_SCHEDULES = "PAY_GROUP_HOLIDAY_SCHEDULES",
	PAY_GROUP_PUBLIC_HOLIDAYS = "PAY_GROUP_PUBLIC_HOLIDAYS",
	PAY_GROUPS = "PAY_GROUPS",
	PAY_GROUP_TERMS = "PAY_GROUP_TERMS",
	QUALIFICATIONS = "QUALIFICATIONS",
	ROLES = "ROLES",
	SCHEDULES = "SCHEDULES",
	SHIFTS = "SHIFTS",
	STATIC_DATA_BILL_GROUPS = "STATIC_DATA_BILL_GROUPS",
	STATIC_DATA_CONTACT_DETAILS = "STATIC_DATA_CONTACT_DETAILS",
	STATIC_DATA_COUNTIES = "STATIC_DATA_COUNTIES",
	STATIC_DATA_CT_MESSAGES = "STATIC_DATA_CT_MESSAGES",
	STATIC_DATA_CUSTOMERS = "STATIC_DATA_CUSTOMERS",
	STATIC_DATA_DOCUMENT_TYPES = "STATIC_DATA_DOCUMENT_TYPES",
	STATIC_DATA_EQUIPMENT_TYPES = "STATIC_DATA_EQUIPMENT_TYPES",
	STATIC_DATA_EVENT_TYPES = "STATIC_DATA_EVENT_TYPES",
	STATIC_DATA_INCIDENTS = "STATIC_DATA_INCIDENTS",
	STATIC_DATA_PAY_GROUPS = "STATIC_DATA_PAY_GROUPS",
	STATIC_DATA_QUALIFICATIONS = "STATIC_DATA_QUALIFICATIONS",
	STATIC_DATA_SUPPLIERS = "STATIC_DATA_SUPPLIERS",
	USERS = "USERS",
	WORK_MONITOR_VIEWS = "WORK_MONITOR_VIEWS",
}

export type EntitiesType = Partial<typeof Entities>
