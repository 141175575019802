import axios from "axios"
import { GetSDSuppliersInterface, SDSuppliersInterface } from "@type/staticData.types"

const ROOT_PATH = "static-data/suppliers"
export default class SDSuppliersService {
	static createSDSuppliers = (supplier: SDSuppliersInterface, organization: string) =>
		axios.post<GetSDSuppliersInterface>(ROOT_PATH, { supplier, organization })

	static getAllSDSuppliers = (fetchAll: boolean) =>
		axios.get<Array<GetSDSuppliersInterface>>(`${ROOT_PATH}`, { params: { fetchAll } })

	static getSDSuppliersById = (id: string) => axios.get<GetSDSuppliersInterface>(`${ROOT_PATH}/${id}`)

	static updateSDSuppliers = (id: string, supplier: Partial<GetSDSuppliersInterface>) =>
		axios.put<GetSDSuppliersInterface>(`${ROOT_PATH}/${id}`, supplier)
}
