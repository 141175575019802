import axios from "axios"
import { GetPBRulesInterface, PBRulesInterface } from "@type/payBill.types"

const ROOT_PATH = "pay-bill/rules"
export default class PBRulesService {
	static createPBRule = (notifiee: PBRulesInterface) => axios.post<GetPBRulesInterface>(ROOT_PATH, notifiee)

	static getAllPBRules = () => axios.get<Array<GetPBRulesInterface>>(`${ROOT_PATH}`)

	static getPBRuleById = (id: string) => axios.get<GetPBRulesInterface>(`${ROOT_PATH}/${id}`)

	static updatePBRule = (id: string, notifiee: Partial<GetPBRulesInterface>) =>
		axios.put<GetPBRulesInterface>(`${ROOT_PATH}/${id}`, notifiee)
}
