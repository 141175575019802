import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import useAppDispatch from "hooks/useAppDispatch"
import useAppSelector from "hooks/useAppSelector"
import { yupResolver } from "@hookform/resolvers/yup"
import { AiOutlineClose } from "react-icons/ai"

import { AntdButton, FormInput } from "components"
import CallTimeTable from "./CallTimeTable"

import TimeCapturesContext, { TimeCapturesContextProps } from "./TimeCapturesContext"
import { CreateLocationCallTimeInterface, NewCall } from "@type/locations.types"
import {
	getLocationCallTime,
	isDeletingLocationCallTime,
	isLoadingLocationCallTime,
	isSavingLocationCallTime,
	removeCallTimes,
	saveLocationCallTimes,
	selectLocationCallTimeColumnLabels,
	selectLocationCallTimeListWithLabel,
} from "store/Location/locationCallTime.slice"
import { LocationCallTimeSchema } from "Schema/Locations/locationSchema"
import { CallTimeDaysArray } from "./types"
import { AllowedTo } from "react-abac"
import { Permissions } from "config/constants"

const CallTime = () => {
	const dispatch = useAppDispatch()
	const [cellsToRemove, setCellsToRemove] = useState<string[]>([])
	const [cellsToAdd, setCellsToAdd] = useState<NewCall[]>([])
	const isLoading = useAppSelector(isLoadingLocationCallTime)
	const isSaving = useAppSelector(isSavingLocationCallTime)
	const isDeleting = useAppSelector(isDeletingLocationCallTime)
	const callTimes = useAppSelector(selectLocationCallTimeListWithLabel)
	const columnHeaderLabels = useAppSelector(selectLocationCallTimeColumnLabels)
	const isLoadingCallTimes = useAppSelector(isLoadingLocationCallTime)
	const { selectedPost } = useContext(TimeCapturesContext) as TimeCapturesContextProps

	useEffect(() => {
		if (selectedPost && !isSaving) dispatch(getLocationCallTime(selectedPost))
	}, [selectedPost, isSaving])

	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<CreateLocationCallTimeInterface>({ resolver: yupResolver(LocationCallTimeSchema) })

	const handleRemoveCheckCall = async (e: any) => {
		e.preventDefault()
		dispatch(removeCallTimes(cellsToRemove, () => setCellsToRemove([])))
	}

	const handleAddCheckCall = async (e: any) => {
		e.preventDefault()
		if (selectedPost) {
			dispatch(saveLocationCallTimes({ callTimes: cellsToAdd, post: selectedPost }, () => setCellsToAdd([])))
			handleClearTable()
		}
	}

	const handleClearTable = () => {
		setCellsToAdd([])
		setCellsToRemove([])
	}

	const onSubmit = (data: CreateLocationCallTimeInterface) => {
		dispatch(saveLocationCallTimes({ ...data, post: selectedPost as string }))
	}

	return (
		<>
			<CallTimeTable
				data={callTimes}
				rowHeaderKey="day"
				columnHeaderKey="time"
				isLoading={isLoadingCallTimes}
				cellsToAdd={cellsToAdd}
				cellsToRemove={cellsToRemove}
				setCellsToAdd={setCellsToAdd}
				setCellsToRemove={setCellsToRemove}
				rowHeaderLabels={CallTimeDaysArray}
				columnHeaderLabels={columnHeaderLabels}
			/>

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="flex flex-col">
					<div className="my-2 flex w-1/2 justify-between gap-2.5">
						<div className="flex space-x-2">
							<AllowedTo perform={Permissions.UPDATE_LOCATION_TIME_CAPTURE}>
								<AntdButton
									disabled={!cellsToAdd.length || isLoading}
									onClick={handleAddCheckCall}
									loading={isSaving}
								>
									Add Check Call
								</AntdButton>
								<AntdButton
									disabled={!cellsToRemove.length || isLoading}
									onClick={handleRemoveCheckCall}
									loading={isDeleting}
								>
									Remove Check Call
								</AntdButton>
							</AllowedTo>
						</div>
						<div>
							<AntdButton
								disabled={!cellsToRemove.length && !cellsToAdd.length}
								onClick={handleClearTable}
								icon={<AiOutlineClose />}
							>
								Clear
							</AntdButton>
						</div>
					</div>
					<AllowedTo perform={Permissions.UPDATE_LOCATION_TIME_CAPTURE}>
						<div className="flex flex-col gap-2.5">
							<div className="flex flex-row ">
								<FormInput
									className="mr-5.5 mt-1.25 w-1/2"
									label="Add New Call Time"
									name="time"
									register={register}
									errors={errors}
								/>
								<p className=" mt-5.5 self-center text-dark-alt">or</p>
							</div>
							<div className="mb-4 flex w-full  items-start gap-2.5">
								<FormInput
									className="mt-1 w-full"
									label="Add New Call Time"
									name="hours"
									register={register}
									errors={errors}
								/>
								<div className="mt-7 flex flex-row items-center gap-4">
									<span className="text-dark-alt">hours at</span>
									<FormInput className=" w-full" name="minutes" register={register} errors={errors} />
									<span className="text-dark-alt">minutes past </span>
								</div>
							</div>
							<div className="mb-2 flex gap-2.5">
								<AntdButton htmlType="submit">Create Call Time(s)</AntdButton>
							</div>
						</div>
					</AllowedTo>
				</div>
			</form>
		</>
	)
}

export default CallTime
