import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EmployeeHRInterface } from "@type/employees.types"
import { hrService } from "services"
import { AppThunk, RootState } from "store"

export interface HrStateInterface {
	data: null | EmployeeHRInterface
	loading: boolean
	saving: boolean
}

const initialState: HrStateInterface = {
	data: null,
	loading: false,
	saving: false,
}

const hrSlice = createSlice({
	name: "hr",
	initialState,
	reducers: {
		requestHrData: hr => {
			hr.loading = true
		},
		receiveHrData: (hr, action: PayloadAction<EmployeeHRInterface>) => {
			hr.loading = false
			hr.data = action.payload
		},
		receiveHrDataFailed: hr => {
			hr.loading = false
		},
		requestSavingHrData: hrData => {
			hrData.saving = true
		},
		SavingEmployeeHrSuccess: (hr, action: PayloadAction<EmployeeHRInterface>) => {
			hr.saving = false
			hr.data = action.payload
		},
		SavingEmployeeHrSuccessFail: hr => {
			hr.saving = false
		},
	},
})

//REDUCER
export default hrSlice.reducer

//ACTIONS
const {
	requestHrData,
	receiveHrData,
	receiveHrDataFailed,
	requestSavingHrData,
	SavingEmployeeHrSuccess,
	SavingEmployeeHrSuccessFail,
} = hrSlice.actions

const getEmployeesHrByEmployeeId =
	(employeeId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestHrData())
			const { data: hrResponse } = await hrService.getEmployeesHr(employeeId)
			dispatch(receiveHrData(hrResponse))
		} catch (error) {
			dispatch(receiveHrDataFailed())
		}
	}

const saveEmployeeHrData =
	(hrData: EmployeeHRInterface): AppThunk =>
	async dispatch => {
		try {
			let data = null
			dispatch(requestSavingHrData())
			if (hrData._id) {
				data = await hrService.updateEmployeeHr(hrData._id, hrData)
			} else {
				data = await hrService.createEmployeeHr(hrData)
			}
			const { data: hrResponse } = data
			dispatch(SavingEmployeeHrSuccess(hrResponse))
		} catch (error) {
			dispatch(SavingEmployeeHrSuccessFail())
		}
	}

export { saveEmployeeHrData, getEmployeesHrByEmployeeId }

//SELECTORS
const selectEmployeeHrState = (state: RootState) => state.employee.hr
const isEmployeeHrLoading = () => (state: RootState) => selectEmployeeHrState(state).loading
const selectEmployeeHrData = () => (state: RootState) => selectEmployeeHrState(state).data
const isEmployeeHrSaving = () => (state: RootState) => selectEmployeeHrState(state).saving

export { isEmployeeHrLoading, selectEmployeeHrData, isEmployeeHrSaving }
