import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { LocationDocumentsService } from "services/locations"
import { LocationDocumentInterface } from "@type/locations.types"
import { AppThunk, RootState } from "store"

export interface LocationDocumentStateInterface {
	data: null | LocationDocumentInterface[]
	loading: boolean
	saving: boolean
}

const initialState: LocationDocumentStateInterface = {
	data: null,
	loading: false,
	saving: false,
}

const locationDocumentsSlice = createSlice({
	name: "locationDocuments",
	initialState,
	reducers: {
		requestDocumentData: document => {
			document.loading = true
		},
		receiveDocumentData: (document, action: PayloadAction<LocationDocumentInterface[]>) => {
			document.loading = false
			document.data = action.payload
		},
		receiveDocumentDataFailed: document => {
			document.loading = false
		},
		requestSavingDocumentData: documentData => {
			documentData.saving = true
		},
		SavingLocationDocumentSuccess: (document, action: PayloadAction<LocationDocumentInterface>) => {
			document.saving = false
			const index = document.data?.findIndex(d => d._id === action.payload._id)
			if (index !== -1) {
				document.data?.splice(index as number, 1, action.payload)
			} else {
				document.data = [...(document.data as LocationDocumentInterface[]), action.payload]
			}
		},
		SavingLocationDocumentSuccessFail: document => {
			document.saving = false
		},
	},
})

//REDUCER
export default locationDocumentsSlice.reducer

//ACTIONS
const {
	requestDocumentData,
	receiveDocumentData,
	receiveDocumentDataFailed,
	requestSavingDocumentData,
	SavingLocationDocumentSuccess,
	SavingLocationDocumentSuccessFail,
} = locationDocumentsSlice.actions

const getLocationDocumentByLocationId =
	(locationId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestDocumentData())
			const { data: documentResponse } = await LocationDocumentsService.getLocationDocument(locationId)
			dispatch(receiveDocumentData(documentResponse))
		} catch (error) {
			dispatch(receiveDocumentDataFailed())
		}
	}

const saveLocationDocumentData =
	(documentData: LocationDocumentInterface): AppThunk =>
	async dispatch => {
		try {
			let data = null
			dispatch(requestSavingDocumentData())
			if (documentData._id) {
				data = await LocationDocumentsService.updateLocationDocument(documentData._id, documentData)
			} else {
				data = await LocationDocumentsService.createLocationDocument(documentData)
			}
			const { data: documentResponse } = data
			dispatch(SavingLocationDocumentSuccess(documentResponse))
		} catch (error) {
			dispatch(SavingLocationDocumentSuccessFail())
		}
	}

export { saveLocationDocumentData, getLocationDocumentByLocationId }

//SELECTORS
const selectLocationDocumentState = (state: RootState) => state.location.locationDocument
const selectLocationDocumentData = () => (state: RootState) => selectLocationDocumentState(state).data
const selectLoadingLocationDocument = () => (state: RootState) => selectLocationDocumentState(state).loading
const selectLocationDocumentSaving = () => (state: RootState) => selectLocationDocumentState(state).saving

export { selectLocationDocumentData, selectLoadingLocationDocument, selectLocationDocumentSaving }
