// REACT/HOOKS
import React, { useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector, useFeatureFlags, useHeightResize } from "hooks"
import { AllowedTo, useAbac } from "react-abac"

// COMPONENTS
import { Section, Icon, EmployeeSidebar, AntdButton } from "components"
import EmployeeTopBar from "./EmployeeTopBar"
import EmployeeDetail from "./EmployeeDetail"
import ServiceDetail from "./ServiceDetail"
import Sickness from "./Sickness/Sickness"
import Documents from "./Documents/Documents"
import Equipments from "./Equipments/Equipments"
import Holidays from "./Holidays/Holidays"
import Terms from "./Terms/Terms"
import HR from "./HR/HR"
import AdditionDetail from "./AdditionalDetail/main"
import Location from "./Location/EmployeeLocation"
// SLICE/CONTEXT
import { isEmployeeLoading, savingEmployeeSuccess } from "store/Employee/detail.slice"
import { selectBranchIsLoading, selectBranchList } from "store/Branches/branch.slice"
import { socket } from "config/socket"
import { ROOM_EVENTS, SOCKET_ROOMS, EMPLOYEE_SOCKET_EVENTS } from "types/webSocket.types"
import { selectSDSuppliersDropdownList } from "store/StaticData/SDSuppliers.slice"
import { Permissions } from "config/constants"
import { selectSelectedEmployee, setSelectedEmployee } from "store/globalState.slice"
import QualificationComponent from "./Qualifications/QualificationComponent"
import { Divider, Segmented } from "antd-v5"
import { FeatureFlagEnums } from "types/featureFlags.type"

const Index: React.FC = () => {
	const dispatch = useAppDispatch()
	useHeightResize({ element: "employee-segment-body" })
	const [value, setValue] = useState<string>("Detail")
	const { userHasPermissions } = useAbac()
	const { featureFlagEnabled } = useFeatureFlags()
	const selectedEmployee = useAppSelector(selectSelectedEmployee)

	const supplierList = useAppSelector(selectSDSuppliersDropdownList)

	const loading = useAppSelector(isEmployeeLoading())

	const branches = useAppSelector(selectBranchList())
	const isBranchLoading = useAppSelector(selectBranchIsLoading())

	useEffect((): any => {
		socket.emit(ROOM_EVENTS.JOIN_ROOM, SOCKET_ROOMS.EMPLOYEE)
		socket.on(EMPLOYEE_SOCKET_EVENTS.ADDED, (res: any) =>
			dispatch(savingEmployeeSuccess({ ...res, shouldSelect: true })),
		)
		socket.on(EMPLOYEE_SOCKET_EVENTS.UPDATED, res =>
			dispatch(savingEmployeeSuccess({ ...res, shouldSelect: true })),
		)
		return () => socket.emit(ROOM_EVENTS.LEAVE_ROOM, SOCKET_ROOMS.EMPLOYEE)
	}, [])

	const options = useMemo(() => {
		return [
			{
				label: "Detail",
				perform: Permissions.VIEW_EMPLOYEE_DETAILS,
				featureName: FeatureFlagEnums.EMPLOYEES_DETAILS,
				component: <EmployeeDetail />,
			},
			{
				label: "Service",
				perform: Permissions.VIEW_EMPLOYEE_DETAILS,
				featureName: FeatureFlagEnums.EMPLOYEES_SERVICE,
				component: <ServiceDetail />,
			},
			{
				label: "HR",
				perform: Permissions.VIEW_EMPLOYEE_HR,
				featureName: FeatureFlagEnums.EMPLOYEES_HR,
				component: <HR />,
			},
			{
				label: "Holidays",
				perform: Permissions.VIEW_EMPLOYEE_HOLIDAY,
				featureName: FeatureFlagEnums.EMPLOYEES_HOLIDAYS,
				component: <Holidays />,
			},
			{
				label: "Sickness",
				perform: Permissions.VIEW_EMPLOYEE_SICKNESS,
				featureName: FeatureFlagEnums.EMPLOYEES_SICKNESS,
				component: <Sickness />,
			},
			{
				label: "Terms",
				perform: Permissions.VIEW_EMPLOYEE_TERMS,
				featureName: FeatureFlagEnums.EMPLOYEES_TERMS,
				component: <Terms />,
			},
			{
				label: "Location",
				perform: Permissions.VIEW_EMPLOYEE_LOCATIONS,
				featureName: FeatureFlagEnums.EMPLOYEES_LOCATION,
				component: <Location />,
			},
			{
				label: "Equipments",
				perform: Permissions.VIEW_EMPLOYEE_EQUIPMENT,
				featureName: FeatureFlagEnums.EMPLOYEES_EQUIPMENTS,
				component: <Equipments />,
			},
			{
				label: "Documents",
				perform: Permissions.VIEW_EMPLOYEE_DETAILS,
				featureName: FeatureFlagEnums.EMPLOYEES_DOCUMENTS,
				component: <Documents />,
			},
			{
				label: "Additional Detail",
				perform: Permissions.VIEW_EMPLOYEE_ADDITIONAL_DETAILS,
				featureName: FeatureFlagEnums.EMPLOYEES_ADDITIONAL_DETAILS,
				component: <AdditionDetail />,
			},
			{
				label: "Qualifications",
				perform: Permissions.VIEW_EMPLOYEE_HR,
				featureName: FeatureFlagEnums.EMPLOYEES_QUALIFICATIONS,
				component: <QualificationComponent />,
			},
		]
			.map(item => ({ ...item, value: item.label }))
			.filter(item => userHasPermissions(item.perform) && featureFlagEnabled(item.featureName))
	}, [selectedEmployee])

	const handleSelected = (employee: string) => {
		dispatch(setSelectedEmployee(employee))
		setValue("Detail")
	}

	return (
		<>
			{branches.length || supplierList.length ? (
				<>
					<EmployeeSidebar />
					<div className="sticky top-0 z-10">
						<AllowedTo yes={() => <EmployeeTopBar />} perform={Permissions.VIEW_EMPLOYEE_DETAILS} />
						<Divider style={{ margin: "8px", marginLeft: "0px", marginRight: "0px" }} />
						<div className="flex items-center justify-between gap-x-2 pr-5">
							<Segmented
								disabled={!selectedEmployee}
								options={options as any}
								value={value}
								onChange={(e: any) => setValue(e)}
							/>
							<AntdButton onClick={() => handleSelected("")}>Add employee</AntdButton>
						</div>
						<Divider style={{ margin: "8px", marginLeft: "0px", marginRight: "0px" }} />
					</div>
					<div id="employee-segment-body" className="overflow-y-auto px-2">
						{options?.find(item => item.value === value)?.component}
					</div>
				</>
			) : isBranchLoading || loading ? (
				<div className="flex h-[94vh] w-full items-center justify-center">
					<Icon
						name="spinner"
						color="white"
						size={100}
						className="mr-3 mt-[-100] animate-spin !text-purple"
					/>
				</div>
			) : (
				<Section className="!m-20">
					<p className="p-5 text-center text-5 font-bold">Add Branch First</p>
				</Section>
			)}
		</>
	)
}

export default Index
