import React, { useEffect, useMemo, useRef, useState } from "react"
import { GlobalSidebar, SidebarList, FormInputAntd, AntdButton } from "components"
import { yupResolver } from "@hookform/resolvers/yup"
import { SDCountiesInterface } from "@type/staticData.types"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { SDCountiesInitialValues, SDCountiesSchema } from "Schema/StaticData/SDCounties"
import {
	getSDCounties,
	isSDCountiesLoading,
	isSDCountiesSaving,
	saveSDCounties,
	selectSDCountiesById,
	selectSDCountiesList,
} from "store/StaticData/SDCounties.slice"
import { useAppDispatch, useAppSelector } from "hooks"

interface EquipmentTypeFormHandler extends SDCountiesInterface {}

const SDCounties = () => {
	const [selectedSDCounties, setSelectedSDCounties] = useState<string | null>("")
	const dispatch = useAppDispatch()
	const saving = useAppSelector(isSDCountiesSaving())
	const loading = useAppSelector(isSDCountiesLoading())
	const SDCountiessList = useAppSelector(selectSDCountiesList)
	const SDCounties = useAppSelector(selectSDCountiesById(selectedSDCounties))
	const isNewBeingCreated = useRef(false)
	const methods = useForm<EquipmentTypeFormHandler>({ resolver: yupResolver(SDCountiesSchema) })
	const {
		handleSubmit,
		reset,
		formState: { isSubmitted, isDirty, isSubmitting },
	} = methods
	const sidebarSDCounties = useMemo(() => {
		return SDCountiessList?.map(SDCounties => ({
			_id: SDCounties._id,
			name: SDCounties.name,
		}))
	}, [saving, SDCountiessList?.length, isSubmitted])

	const onSubmit: SubmitHandler<EquipmentTypeFormHandler> = data => {
		isNewBeingCreated.current = true
		dispatch(
			saveSDCounties(data, id => {
				setSelectedSDCounties(id)
				isNewBeingCreated.current = false
			}),
		)
	}

	useEffect(() => {
		dispatch(getSDCounties(setSelectedSDCounties))
	}, [])

	useEffect(() => {
		if (selectedSDCounties !== (null && undefined)) {
			reset({ ...SDCountiesInitialValues, ...SDCounties })
		} else if (selectedSDCounties === null && !isNewBeingCreated.current) {
			reset({ ...SDCountiesInitialValues })
		}
	}, [selectedSDCounties])

	const handleSelected = (SDCountiesId: string | null): void => setSelectedSDCounties(SDCountiesId)

	return (
		<>
			<GlobalSidebar>
				<SidebarList
					title={"Counties"}
					loading={loading}
					selected={selectedSDCounties}
					setSelected={handleSelected}
					list={sidebarSDCounties}
				/>
			</GlobalSidebar>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="flex w-1/4 flex-col gap-y-2">
						<FormInputAntd type="text" label="County Name" name="name" />
						<div className="flex gap-x-2">
							<AntdButton onClick={() => setSelectedSDCounties(null)}>New</AntdButton>
							<AntdButton disabled={!isDirty || isSubmitting} loading={saving} htmlType="submit">
								{selectedSDCounties ? "Update" : "Save"}
							</AntdButton>
						</div>
					</div>
				</form>
			</FormProvider>
		</>
	)
}

export default SDCounties
