import { updateState } from "config/utils"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetLocationQualificationInterface, CreateLocationQualificationInterface } from "@type/locations.types"
import { locationQualificationService } from "services"
import { AppThunk, RootState } from "store"

export interface LocationQualificationStateInterface {
	list: GetLocationQualificationInterface[]
	loading: boolean
	saving: boolean
}

const initialState: LocationQualificationStateInterface = {
	list: [],
	loading: false,
	saving: false,
}

const locationQualificationSlice = createSlice({
	name: "locationQualification",
	initialState,
	reducers: {
		fetchingLocationQualification: locationQualification => {
			locationQualification.loading = true
		},
		locationQualificationFetched: (
			locationQualification,
			action: PayloadAction<GetLocationQualificationInterface[]>,
		) => {
			locationQualification.loading = false
			locationQualification.list = action.payload
			// if (!locationQualification.list) locationQualification.list = []
			// locationQualification.list?.push(action.payload)
		},
		locationQualificationFetchingFailed: locationQualification => {
			locationQualification.loading = false
		},
		savingLocationQualification: locationQualificationData => {
			locationQualificationData.saving = true
		},
		locationQualificationSaved: (
			locationQualification,
			action: PayloadAction<GetLocationQualificationInterface[]>,
		) => {
			locationQualification.saving = false
			if (action.payload.length === 1) {
				const [UpdatedValue] = updateState(locationQualification.list, action.payload[0], "_id")
				locationQualification.list = UpdatedValue
			} else locationQualification.list = action.payload
		},
		locationQualificationSavingFailed: locationQualification => {
			locationQualification.saving = false
		},
	},
})

//REDUCER
export default locationQualificationSlice.reducer

//ACTIONS
const {
	fetchingLocationQualification,
	locationQualificationFetched,
	locationQualificationFetchingFailed,
	savingLocationQualification,
	locationQualificationSaved,
	locationQualificationSavingFailed,
	// locationQualificationPresent,
} = locationQualificationSlice.actions

const getLocationPostQualification =
	(postId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingLocationQualification())
			const { data: locationQualificationResponse } =
				await locationQualificationService.getLocationQualificationByPostId(postId)
			dispatch(locationQualificationFetched(locationQualificationResponse))
		} catch (error) {
			dispatch(locationQualificationFetchingFailed())
		}
	}

const saveLocationQualification =
	(locationQualificationData: Partial<GetLocationQualificationInterface>): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingLocationQualification())
			if (locationQualificationData._id)
				data = await locationQualificationService.updateLocationQualification(locationQualificationData)
			else
				data = await locationQualificationService.createLocationQualification(
					locationQualificationData as CreateLocationQualificationInterface,
				)
			const { data: locationQualificationResponse } = data
			dispatch(locationQualificationSaved(locationQualificationResponse.data))
		} catch (error) {
			dispatch(locationQualificationSavingFailed())
		}
	}

export { getLocationPostQualification, saveLocationQualification }

//SELECTORS
const selectLocationQualificationState = (state: RootState) => state.location.qualifications
const selectLocationQualificationList = () => (state: RootState) => selectLocationQualificationState(state).list
const selectLocationQualification = () => (state: RootState) =>
	selectLocationQualificationState(state).list?.[0] || null
const isLocationQualificationLoading = () => (state: RootState) => selectLocationQualificationState(state).loading
const isLocationQualificationSaving = (state: RootState) => selectLocationQualificationState(state).saving

const selectLocationQualificationByPostId = (locationPostId: string | null) => (state: RootState) =>
	selectLocationQualificationState(state).list?.find((item: any) => item.post === locationPostId)

export {
	selectLocationQualificationList,
	selectLocationQualificationState,
	isLocationQualificationSaving,
	selectLocationQualificationByPostId,
	isLocationQualificationLoading,
	selectLocationQualification,
}
