import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import FeatureFlagsService from "services/featureFlags.service"
import { AppThunk, RootState } from "store"

interface FeatureFalgs {
	[key: string]: boolean
}
export interface FeatureFlagsInterface {
	object: FeatureFalgs
	loading: boolean
}

const initialState: FeatureFlagsInterface = {
	object: {},
	loading: false,
}

const featureFlagsSlice = createSlice({
	name: "featureFlags",
	initialState,
	reducers: {
		fetchingFeatureFlags: fetchingFeatureFlags => {
			fetchingFeatureFlags.loading = true
		},
		fetchingFeatureFlagsFailed: fetchingFeatureFlags => {
			fetchingFeatureFlags.loading = false
		},
		featureFalgsFetched: (featureFalgs, action: PayloadAction<{ [key: string]: boolean }>) => {
			featureFalgs.loading = false
			featureFalgs.object = action.payload
		},
	},
})

// REDUCER
export default featureFlagsSlice.reducer

// ACTIONS

export const { fetchingFeatureFlags, fetchingFeatureFlagsFailed, featureFalgsFetched } = featureFlagsSlice.actions

const getFeatureFlags = (): AppThunk => async dispatch => {
	try {
		dispatch(fetchingFeatureFlags())
		const { data: featureFlagResponse } = await FeatureFlagsService.getFeatureFlags()
		dispatch(featureFalgsFetched(featureFlagResponse))
	} catch (error) {
		dispatch(fetchingFeatureFlagsFailed())
	}
}

export { getFeatureFlags }

// SELECTORS
const selectFeatureFlags = (state: RootState) => state.featureFlags.object
const selectFeatureFlagsLoading = (state: RootState): boolean => state.featureFlags.loading

export { selectFeatureFlags, selectFeatureFlagsLoading }
