import React from "react"
import { AntdInput, SidebarList } from "components"
import { GetBranchesInterface } from "@type/branches.types"
import useAppSelector from "hooks/useAppSelector"
import { selectBranchList } from "store/Branches/branch.slice"
import { SearchOutlined } from "@ant-design/icons"
import { Divider } from "antd-v5"

interface Props {
	setSearchString: any
	branches: GetBranchesInterface[]
	selected: string | null
	searchText: string
	handleSelected: (branchId: string | null) => void
	loading?: boolean
	onKeyUpCapture: any
}

const BranchSideBar: React.FC<Props> = ({
	setSearchString,
	branches,
	selected,
	searchText,
	loading,
	handleSelected,
	...rest
}) => {
	const branchList = useAppSelector(selectBranchList())

	return (
		<>
			<div>
				{!!branchList.length && (
					<AntdInput
						onChange={e => setSearchString(e.target.value)}
						placeholder="Search"
						type="text"
						suffix={<SearchOutlined size={14} style={{ color: "rgb(156 163 175)" }} />}
					/>
				)}
			</div>
			<Divider className="bg-slate-700" style={{ margin: "15px 0 10px 0" }} />
			{searchText && (
				<span className="dark:bg-purple-200 dark:text-white-800 mr-2 rounded bg-purple px-5 py-0.5 text-md font-semibold text-white">
					{searchText}
				</span>
			)}
			<div className="h-fit outline-none" tabIndex={0} {...rest}>
				{!!branches.length && (
					<SidebarList
						loading={loading}
						selected={selected}
						setSelected={handleSelected}
						list={branches?.map(({ _id, name }) => ({
							_id,
							name,
						}))}
					/>
				)}
			</div>
		</>
	)
}

export default BranchSideBar
