import axios from "axios"
import { GetLocationAdditionalDetailInterface } from "@type/locations.types"

const ROOT_PATH = "locations/additional-details"
export default class LocationAdditionalDetails {
	static createLocationAdditionalDetail = (Data: GetLocationAdditionalDetailInterface) =>
		axios.post<GetLocationAdditionalDetailInterface>(`${ROOT_PATH}`, Data)

	static updateLocationAdditionalDetailByLocationId = (
		id: string,
		additionalData: GetLocationAdditionalDetailInterface,
	) => axios.put<GetLocationAdditionalDetailInterface>(`${ROOT_PATH}/${id}`, additionalData)

	static getLocationAdditionalDetailByLocationID = (locationId: string) => axios.get(`${ROOT_PATH}/${locationId}`)
}
