import React, { useContext, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "hooks"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { qualificationValidationSchema } from "Schema"
import { AntdButton, DateField, FormInputAntd, FormSelectAntd } from "components"
import { EmployeeQualificationInterface } from "@type/employees.types"
import {
	getEmployeesQualificationByEmployeeId,
	saveEmployeeQualificationData,
	SelectEmployeeQualificationSaving,
} from "store/Employee/Qualification/qualification.slice"
import QualificationContext, { QualificationContextProps } from "./qualificationContext"
import { isSDQualificationsLoading, selectSDQualificationsDropdownList } from "store/StaticData/SDQualifications.slice"
import { AllowedTo } from "react-abac"
import { Permissions } from "config/constants"
import { selectSelectedEmployee } from "store/globalState.slice"

interface QualificationFormHandler extends EmployeeQualificationInterface {}

const cleanObjectFromFalsyValues = (obj: any) => {
	for (const propName in obj) {
		if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
			delete obj[propName]
		}
	}
	return obj
}

const Qualification = () => {
	const dispatch = useAppDispatch()
	const isQualificationSaving = useAppSelector(SelectEmployeeQualificationSaving())
	const allQualifications = useAppSelector(selectSDQualificationsDropdownList)
	const isQualificationLoading = useAppSelector(isSDQualificationsLoading())
	const selectedEmployee = useAppSelector(selectSelectedEmployee)
	// context
	const { qualification, handleRow } = useContext(QualificationContext) as QualificationContextProps

	// useEffects

	useEffect(() => {
		selectedEmployee && dispatch(getEmployeesQualificationByEmployeeId(selectedEmployee))
	}, [selectedEmployee])

	useEffect(() => {
		if (qualification) {
			reset({
				...qualification,
			})
		}
	}, [qualification?._id])

	//react-hook-forms
	const methods = useForm<QualificationFormHandler>({
		resolver: yupResolver(qualificationValidationSchema),
		reValidateMode: "onChange",
	})
	const {
		reset,
		handleSubmit,
		setValue,
		watch,
		formState: { errors, isDirty, isSubmitting },
	} = methods
	const onSubmit: SubmitHandler<QualificationFormHandler> = data => {
		dispatch(saveEmployeeQualificationData({ ...cleanObjectFromFalsyValues(data), employee: selectedEmployee }))
	}

	return (
		<div>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="flex flex-col gap-y-3">
						<FormSelectAntd
							disabled={!allQualifications.length}
							label="Qualification"
							name="qualification"
							loading={isQualificationLoading}
							options={allQualifications}
						/>
						<DateField
							label="Start Date"
							setValue={setValue}
							watch={watch}
							errors={errors}
							name={"startDate"}
						/>
						<DateField
							label="Finish Date"
							name={"finishDate"}
							setValue={setValue}
							watch={watch}
							errors={errors}
						/>
						<FormInputAntd label="Reference" type="text" name="reference" />
						<AllowedTo perform={Permissions.UPDATE_EMPLOYEE_LOCATIONS}>
							<div className="flex gap-x-2">
								<AntdButton
									onClick={e => {
										e.preventDefault()
										handleRow(null)
									}}
								>
									Clear
								</AntdButton>
								<AntdButton
									loading={isQualificationSaving || isQualificationLoading}
									disabled={!isDirty || isSubmitting}
									htmlType="submit"
								>
									{qualification ? "Update" : "Save"}
								</AntdButton>
							</div>
						</AllowedTo>
					</div>
				</form>
			</FormProvider>
		</div>
	)
}

export default Qualification
