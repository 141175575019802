import { Divider } from "antd-v5"
import React, { useEffect, useState } from "react"
import GlobalSidebar from "./GlobalSidebar"
import AntdSwitch from "./AntdSwitch"
import { AntdInput, AntdSelect1, SidebarList } from "."
import { useAppDispatch, useAppSelector } from "hooks"
import {
	ClearNewDetail,
	filteredListBySearch,
	isEmployeeLoading,
	selectEmployeeById,
	selectNewEmployeeDetailAdded,
} from "store/Employee/detail.slice"
import { getPayGroup } from "store/PayGroups/payGroup.slice"
import { getBranches, selectBranchList } from "store/Branches/branch.slice"
import { getSDQualifications } from "store/StaticData/SDQualifications.slice"
import { getSDCounties } from "store/StaticData/SDCounties.slice"
import { getSDSuppliers, selectSDSuppliersDropdownList } from "store/StaticData/SDSuppliers.slice"
import {
	clearSelectedBranch,
	clearSelectedEmployee,
	clearSelectedSupplier,
	selectSelectedEmployee,
	selectSelectedEmployeeBranch,
	selectSelectedSupplier,
	setSelectedEmployee,
	setSelectedEmployeeBranch,
	setSelectedSupplier,
} from "store/globalState.slice"
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons"

const EmployeeSidebar: React.FC = () => {
	const dispatch = useAppDispatch()
	const loading = useAppSelector(isEmployeeLoading())
	const selectedEmployee = useAppSelector(selectSelectedEmployee)
	const selectedEmployeeObject = useAppSelector(selectEmployeeById(selectedEmployee))
	const newDetail = useAppSelector(selectNewEmployeeDetailAdded())
	const supplierList = useAppSelector(selectSDSuppliersDropdownList)
	const branches = useAppSelector(selectBranchList())
	const selectedBranch = useAppSelector(selectSelectedEmployeeBranch)
	const selectedSupplier = useAppSelector(selectSelectedSupplier)

	const [addEmployee, setAddEmployee] = useState(false)
	const [shouldSelectActiveEmployees, setShouldSelectActiveEmployees] = useState<boolean>(true)
	const [searchString, setSearchString] = useState("")
	// const [isFocused, setIsFocused] = useState<boolean>(false)
	const [searchOnFields] = useState(["shortName", "firstName", "lastName"])

	const filteredList = useAppSelector(
		filteredListBySearch(
			searchString,
			searchOnFields,
			shouldSelectActiveEmployees,
			selectedEmployeeObject?.branch ?? selectedBranch,
			selectedEmployeeObject?.supplier ?? selectedSupplier,
		),
	)

	useEffect(() => {
		dispatch(getPayGroup())
		dispatch(getBranches(false))
		dispatch(getSDQualifications())
		dispatch(getSDCounties())
		dispatch(getSDSuppliers(false))
	}, [dispatch])

	const handleBranchSelected = (value: string) => {
		setAddEmployee(false)
		if (value) {
			dispatch(clearSelectedSupplier())
			dispatch(setSelectedEmployeeBranch(value))
			dispatch(clearSelectedEmployee())
		}
	}
	const handleSupplierSelected = (value: string) => {
		setAddEmployee(false)
		if (value) {
			dispatch(setSelectedSupplier(value))
			dispatch(clearSelectedBranch())
			dispatch(clearSelectedEmployee())
		}
	}

	useEffect(() => {
		if (branches && branches?.length && !selectedBranch && !selectedSupplier) {
			dispatch(setSelectedEmployeeBranch(branches[0]._id))
		}
	}, [branches.length])

	useEffect(() => {
		if (filteredList?.length) {
			if (selectedEmployee === "" && !addEmployee) {
				handleSelected(filteredList[0]._id)
				setAddEmployee(true)
			}
		} else if (selectedEmployee !== "") {
			setAddEmployee(false)
		}
	}, [filteredList, shouldSelectActiveEmployees])

	useEffect(() => {
		if (newDetail?._id) {
			handleSelected(newDetail._id)
			newDetail && dispatch(ClearNewDetail())
		}
	}, [newDetail?._id])

	useEffect(() => {
		if (shouldSelectActiveEmployees && filteredList?.length) {
			!selectedEmployee && handleSelected(filteredList[0]._id)
		}
	}, [shouldSelectActiveEmployees])

	const handleSelected = (employeeId: string) => dispatch(setSelectedEmployee(employeeId))

	// useEffect(() => {
	// 	window.addEventListener("keydown", handleKeyDown)

	// 	return () => {
	// 		window.removeEventListener("keydown", handleKeyDown)
	// 	}
	// }, [filteredList])

	// const handleKeyDown = (event: any) => {
	// 	if (isInputInFocus()) return
	// 	if (isFocused) {
	// 		const buttonPressed = event.key
	// 		if (buttonPressed === "Backspace")
	// 			setSearchString(searchString => searchString.slice(0, searchString.length - 1))
	// 		else if (buttonPressed === "ArrowDown" || buttonPressed === "ArrowUp") {
	// 			event.preventDefault()
	// 			const selectedIndex = filteredList.findIndex((item: any) => item._id === selectedEmployee)
	// 			if (buttonPressed === "ArrowDown") {
	// 				const selectedId = filteredList[selectedIndex + 1]
	// 					? filteredList[selectedIndex + 1]._id
	// 					: filteredList[selectedIndex]._id
	// 				handleSelected(selectedId)
	// 				document.getElementById(selectedId)?.scrollIntoView()
	// 			} else {
	// 				const selectedId = filteredList[selectedIndex - 1]
	// 					? filteredList[selectedIndex - 1]._id
	// 					: filteredList[selectedIndex]._id

	// 				handleSelected(selectedId)
	// 				document.getElementById(selectedId)?.scrollIntoView()
	// 			}
	// 		} else if (event.key?.length === 1) setSearchString(searchString => searchString + event.key)
	// 	}
	// }

	return (
		<GlobalSidebar>
			<div className="h-full">
				<div className="flex flex-col space-y-2">
					<div className="flex flex-row items-center justify-between">
						<span className="text-md font-medium tracking-wider text-gray-400">EMPLOYEES</span>
						<AntdSwitch
							checked={shouldSelectActiveEmployees}
							checkedChildren="Active Employees"
							unCheckedChildren="All"
							onChange={() => setShouldSelectActiveEmployees(!shouldSelectActiveEmployees)}
						/>
					</div>
					<AntdSelect1
						onChange={handleBranchSelected}
						value={selectedBranch}
						placeholder="Select branch"
						options={[{ name: "Select Branch", _id: "" }, ...branches].map(({ _id, name }) => ({
							label: name,
							value: _id,
						}))}
						suffixIcon={
							<>
								<span style={{ color: "rgb(156 163 175)" }}>Branches</span>
								<CaretDownOutlined style={{ color: "rgb(156 163 175)" }} />
							</>
						}
					/>
					<AntdSelect1
						onChange={handleSupplierSelected}
						value={selectedSupplier}
						placeholder="Select Supplier"
						options={supplierList.map(({ label, value }) => ({ label, value }))}
						suffixIcon={
							<>
								<span style={{ color: "rgb(156 163 175)" }}>Suppliers</span>
								<CaretDownOutlined style={{ color: "rgb(156 163 175)" }} />
							</>
						}
					/>
					<AntdInput
						id="sidebar-search"
						onChange={e => setSearchString(e.target.value)}
						value={searchString}
						placeholder="Search"
						type="text"
						suffix={
							<>
								<span style={{ color: "rgb(156 163 175)" }}>Ctrl + G</span>
								<SearchOutlined size={14} style={{ color: "rgb(156 163 175)" }} />{" "}
							</>
						}
					/>
				</div>
				<Divider className="bg-slate-700" />
				<SidebarList
					loading={loading}
					selected={selectedEmployee}
					setSelected={handleSelected}
					list={filteredList?.map(({ _id, shortName, firstName, lastName, isActive = true }: any) => ({
						_id,
						name: shortName || `${firstName} ${lastName}`,
						isActive,
					}))}
				/>
			</div>
		</GlobalSidebar>
	)
}

export default EmployeeSidebar
