import { useMemo, useState } from "react"
import cx from "classnames"
import { FieldError, FieldErrors } from "react-hook-form"
import { AntdButton, FormInput } from "components"

interface RenderProps {
	row: any
	key: string
}
interface Column {
	label: string
	key: string
	render?: (data: RenderProps, index: number) => React.ReactNode
	className?: string
}

interface Props {
	columns: Column[]
	rows: any[]
	onRowClick?: (id: string) => void
	selectRow?: boolean
	selectedRowColor?: string
	selectedRows?: string[]
	rowClassName?: string
	headerClassName?: string
	append: any
	update: any
	remove: any
	register: any
	initialValues: any
	fieldArrayName?: string
	errors: { [key: string]: FieldError | undefined } | FieldErrors
}

const EditableTable: React.FC<Props> = ({
	columns,
	rows,
	onRowClick = () => undefined,
	selectRow = false,
	selectedRowColor,
	selectedRows,
	rowClassName = "",
	headerClassName = "",
	fieldArrayName = "",
	register,
	append,
	errors,
	update,
	remove,
	initialValues,
}) => {
	const [preEditRow, setPreEditRow] = useState<any>(null)
	const actionButtonClass = useMemo(() => "px-3 py-1.5 text-sm", [])
	return (
		<>
			<table className="w-full text-left">
				<thead className="whitespace-no-wrap w-full table-auto text-left">
					<tr className=" bg-gray-3 flex h-9 w-full text-left">
						{columns.map(({ label }, colIndex) => {
							const thClassName = cx({
								" bg-background flex w-full items-center justify-center px-2.5 py-1.25 text-sm font-semibold text-dark-alt pl-4 font-medium":
									true,
								"rounded-tl-md": colIndex === 0,
							})
							return (
								<th key={colIndex} className={`${thClassName} ${headerClassName}`}>
									<span> {label}</span>
								</th>
							)
						})}
						<th
							key={"actionHeader"}
							className={
								"flex w-full items-center justify-center rounded-tr-2.5 bg-background px-2.5 py-1.25 pr-4 text-sm font-semibold text-dark-alt"
							}
						>
							<span>Action</span>
						</th>
					</tr>
				</thead>
				<tbody className="flex w-full flex-col overflow-y-scroll " style={{ maxHeight: "30vh" }}>
					{rows.length ? (
						rows.map((row, rowIndex) => {
							const { isNew = false, isEditing = false, ...rest } = row

							return (
								<tr
									key={rowIndex}
									onClick={() => onRowClick(row._id)}
									className={`flex h-12 w-full items-center  border-t-0.125  border-solid text-sm ${rowClassName}`}
									style={{
										...(selectRow && selectedRows?.includes(row._id)
											? { backgroundColor: selectedRowColor }
											: {}),
									}}
								>
									{columns.map(({ key, render, className }, colIndex) => {
										if ((row.isNew || row.isEditing) && !["serialNumber", "action"].includes(key)) {
											return (
												<td
													key={colIndex}
													className={`flex items-center justify-center ${className} relative w-full`}
												>
													<FormInput
														className={`h-0.5 w-24  text-sm`}
														errorClass="absolute top-7 -left-2"
														name={`${fieldArrayName}.${rowIndex}.${key}`}
														errors={errors}
														register={register}
													/>
												</td>
											)
										} else {
											return (
												<td
													key={colIndex}
													className={`flex items-center justify-center ${className} w-full`}
												>
													{render ? render({ row, key }, rowIndex) : row[key]}
												</td>
											)
										}
									})}
									<td
										key={`actionColumn ${rowIndex}`}
										className={`flex w-full items-center justify-center py-1.5 text-left`}
									>
										{!isNew && !isEditing && (
											<AntdButton
												className={actionButtonClass}
												disabled={rows?.some((item: any) => item.isNew || item.isEditing)}
												htmlType="button"
												onClick={() => {
													setPreEditRow(rest)
													update(rowIndex, { isEditing: true, ...rest })
												}}
											>
												Edit
											</AntdButton>
										)}
										{isNew || isEditing ? (
											<div className="flex flex-row space-x-2 ">
												<AntdButton
													className={actionButtonClass}
													htmlType="button"
													onClick={() => {
														if (Object.values(rest).every(value => !value))
															return remove(rowIndex, { ...preEditRow })
														update(rowIndex, { ...preEditRow })
														setPreEditRow(null)
													}}
												>
													Cancel
												</AntdButton>
												<AntdButton
													className={actionButtonClass}
													htmlType="button"
													onClick={() => update(rowIndex, { ...rest })}
												>
													Save
												</AntdButton>
											</div>
										) : null}
									</td>
								</tr>
							)
						})
					) : (
						<tr>
							<th colSpan={12} className="p-3 text-center text-dark-alt">
								No Data
							</th>
						</tr>
					)}
				</tbody>
			</table>

			<AntdButton
				className="mt-2"
				disabled={rows?.some((item: any) => item.isNew || item.isEditing)}
				htmlType="button"
				onClick={() => append({ ...initialValues, isNew: true })}
			>
				Add New
			</AntdButton>
		</>
	)
}
export default EditableTable
