import ScheduleTable from "./ScheduleTable"
import { forwardRef, useEffect, useMemo, useRef, useState } from "react"
import { DaysInWeekLabel } from "./Location"
import { GetSchedulesInterface, SelectedSchedules } from "@type/schedules.types"
import { InActiveSchedule } from "@type/locations.types"
import { ScheduleStatus } from "../Constants/schedulesConstants"
import { round } from "lodash"
import { calculateTimeDifferenceWrtDst } from "config/utils"

interface ColumnHeaderProps {
	label: string
	key: string
	shifts: number
	hours: number
}

const createSelectedCellKey = (rowKeyValue: string, colKeyValue: string, rowIndex: number, colIndex: number) =>
	`${rowKeyValue}_${colKeyValue}_${rowIndex}_${colIndex}`

interface Props {
	data: (GetSchedulesInterface | InActiveSchedule)[]
	rowHeaderKey: string
	columnHeaderKey: string
	rowHeaderLabels: { [key: string]: string }[]
	columnHeaderLabels: DaysInWeekLabel[]
	noDataText?: string
	multipleSingleSelection?: boolean
	selected: (selectedCell: SelectedSchedules) => void
}
const ScheduleRevamp: React.FC<Props & { ref: React.Ref<() => void> }> = forwardRef(
	(
		{
			data,
			rowHeaderKey,
			columnHeaderKey,
			rowHeaderLabels,
			columnHeaderLabels,
			noDataText = "",
			multipleSingleSelection = false,
			selected,
		},
		ref: any,
	) => {
		const cellCoordinates = useRef<{ rIndex: number; cIndex: number } | null>(null)
		const dataHeaderKeyRowIndex = useRef<string[]>([])
		const selectedCellsRef = useRef<number>(0)
		const [selectedCells, setSelectedCells] = useState<SelectedSchedules>({})

		useEffect(() => {
			ref.current = resetState
		}, [ref])

		useEffect(() => {
			selected(selectedCells)
		}, [selectedCells])

		const columns: ColumnHeaderProps[] = useMemo(() => {
			if (columnHeaderLabels.length) {
				const dayWiseScheduleStats = data.reduce((acc: { [key: string]: any }, curr) => {
					const hours = calculateTimeDifferenceWrtDst(curr.startTime, curr.finishTime, "seconds") / 3600
					if (curr.status !== ScheduleStatus.INACTIVE && "isDeleted" in curr && !curr.isDeleted) {
						if (!acc[curr.date]) {
							acc[curr.date] = { shifts: 1, hours }
						} else {
							acc[curr.date].shifts++
							acc[curr.date].hours += hours
						}
					}
					return acc
				}, {})
				return columnHeaderLabels?.map(column => ({
					label: column.label,
					key: column.date,
					disable: column.disable,
					shifts: round(dayWiseScheduleStats[column.date]?.shifts, 2) || 0,
					hours: round(dayWiseScheduleStats[column.date]?.hours, 2) || 0,
				}))
			}
			return []
		}, [columnHeaderLabels, data])

		const resetState = () => {
			setSelectedCells({})
			selectedCellsRef.current = 0
		}

		const onMouseDrag = (drag: any, rowEndIndex: any, colEndIndex: any) => {
			if (drag) {
				let rowStartIndex: number
				let columnStartIndex: number

				// On mouse down will give first and last selected row and col index
				if (cellCoordinates.current) {
					rowStartIndex = cellCoordinates.current.rIndex
					columnStartIndex = cellCoordinates.current.cIndex

					if (rowStartIndex > rowEndIndex) [rowStartIndex, rowEndIndex] = [rowEndIndex, rowStartIndex]

					if (columnStartIndex > colEndIndex)
						[columnStartIndex, colEndIndex] = [colEndIndex, columnStartIndex]
				}

				// Extracting selected posts based selected drag coordinates
				const totalRows = [...dataHeaderKeyRowIndex.current].filter(id => {
					const rowNumber = +id.split("_")[1]
					return rowNumber >= rowStartIndex && rowNumber <= rowEndIndex
				})

				// Resetting state when new drag happens
				resetState()

				totalRows.forEach(row => {
					const splitSelectedHeader = row.split("_")
					const selectedPost = splitSelectedHeader[0]
					const selectedRowIndex = +splitSelectedHeader[1]
					for (let i = columnStartIndex; i <= colEndIndex; i++) {
						const data = rows[selectedPost][selectedRowIndex][i]

						setSelectedCells(prev => {
							const addCells = {
								...prev,
								[createSelectedCellKey(selectedPost, columns[i].key, selectedRowIndex, i)]: data["_id"]
									? { ...data }
									: null,
							}
							selectedCellsRef.current = Object.keys(addCells).length
							return addCells
						})
					}
				})
			}
		}

		const onMouseDown = ({ ctrlKey, metaKey }: MouseEvent, rowIndex: number, colIndex: number, _data: any) => {
			// Single Selection don't let select multiple cells
			/*if ((!ctrlKey || !metaKey) && selectedCellsRef.current > 1) {
				setSelectedCells({})
			}*/
			cellCoordinates.current = { rIndex: rowIndex, cIndex: colIndex }

			setSelectedCells(prev => {
				const key = createSelectedCellKey(_data[rowHeaderKey], _data[columnHeaderKey], rowIndex, colIndex)

				if (multipleSingleSelection) {
					selectedCellsRef.current = 1
					if (key in prev) {
						delete prev[key]
						const addCells = { ...prev }
						return addCells
					} else {
						const addCells = {
							...prev,
							[key]: _data["_id"] ? { ..._data } : null,
						}
						return addCells
					}
				} else {
					if (ctrlKey || metaKey) {
						selectedCellsRef.current = 1
						if (key in prev) {
							delete prev[key]
							const addCells = { ...prev }
							return addCells
						} else {
							const addCells = {
								...prev,
								[key]: _data["_id"] ? { ..._data } : null,
							}
							return addCells
						}
					} else {
						return {
							[key]: _data["_id"] ? { ..._data } : null,
						}
					}
				}
			})
		}

		const rows = useMemo(() => {
			let rowIndex: string[] = []
			let rowCount = 0
			const dataFormat: any = {}
			const noOfSchedulesInSinglePost: { [key: string]: number } = {}

			rowHeaderLabels.forEach(rowHeader => {
				const rowKey = rowHeader[rowHeaderKey]
				let columnValueLength = 0
				columnHeaderLabels.forEach(columnHeader => {
					const columnKey = columnHeader.date
					const matchedData = [] as any
					data.forEach((row: any) => {
						// Extracting data that matches row and column key
						if (row[rowHeaderKey]["_id"] === rowKey && row[columnHeaderKey] === columnKey) {
							matchedData.push({ onMouseDown, onMouseDrag, ...row, post: row.post._id })
						}
					})
					if (columnValueLength < matchedData.length) columnValueLength = matchedData.length
					dataFormat[rowKey] = { ...dataFormat[rowKey], [columnKey]: matchedData }
				})
				noOfSchedulesInSinglePost[rowKey] = columnValueLength
			})
			const newDataFormat = {} as any

			for (const [dataKey, maxDataLength] of Object.entries(noOfSchedulesInSinglePost)) {
				if (maxDataLength === 0) {
					rowIndex = [...new Set([...rowIndex, `${dataKey}_${rowCount}`])]

					newDataFormat[dataKey] = {
						...newDataFormat[dataKey],
						[rowCount]: [
							...columnHeaderLabels.map(columnHeader => ({
								onMouseDown,
								onMouseDrag,
								[rowHeaderKey]: dataKey,
								[columnHeaderKey]: columnHeader.date,
								disable: columnHeader.disable,
							})),
						],
					}
					rowCount++
				} else {
					for (let i = 0; i < maxDataLength; i++) {
						const rowColumnValue = [] as any
						columnHeaderLabels.forEach((columnHeader: any) => {
							const columnKey = columnHeader[columnHeaderKey]
							rowColumnValue.push(
								dataFormat[dataKey][columnKey][i]
									? dataFormat[dataKey][columnKey][i]
									: {
											onMouseDown,
											onMouseDrag,
											[rowHeaderKey]: dataKey,
											[columnHeaderKey]: columnHeader[columnHeaderKey],
											disable: columnHeader.disable,
									  },
							)
						})

						rowIndex = [...new Set([...rowIndex, `${dataKey}_${rowCount}`])]

						newDataFormat[dataKey] = {
							...newDataFormat[dataKey],
							[rowCount]: [...rowColumnValue],
						}
						rowCount++
					}
				}
				dataHeaderKeyRowIndex.current = rowIndex
			}
			return newDataFormat
		}, [data, rowHeaderLabels, columnHeaderLabels])

		return (
			<ScheduleTable
				selectedCells={selectedCells}
				noDataText={noDataText}
				columns={columns}
				rows={rows}
				showRowHeaders
				rowHeaders={rowHeaderLabels}
			/>
		)
	},
)
export default ScheduleRevamp
