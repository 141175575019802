import GlobalSidebar from "components/GlobalSidebar"
import JavForm from "./Jaf"
import SearchField from "components/SearchField"
import { useEffect, useMemo, useState } from "react"
import useAppDispatch from "hooks/useAppDispatch"
import { getJafDetails, isJafLoading, selectJafList } from "store/Jaf/jaf.slice"
import useAppSelector from "hooks/useAppSelector"
import SidebarList from "components/SidebarList"
import { Input } from "components"

const JafWrapper: React.FC = () => {
	const dispatch = useAppDispatch()
	const [searchString, setSearchString] = useState("")
	const [selectApproved, setSelectApproved] = useState<boolean>(false)
	const [selected, setSelected] = useState("")
	const jafList = useAppSelector(selectJafList())
	const loading = useAppSelector(isJafLoading())

	useEffect(() => {
		dispatch(getJafDetails(selectApproved))
	}, [selectApproved])

	const formData = useMemo(() => {
		const selectedEntry = jafList.find(jaf => jaf?._id === selected)
		return { id: selectedEntry?._id, ...selectedEntry?.formInfo }
	}, [selected])

	const filteredList = useMemo(() => {
		return jafList
			?.filter(
				element =>
					(selectApproved ? element.formInfo.approved : true) &&
					(searchString
						? element?.formInfo?.forenames?.toLowerCase().includes(searchString.toLowerCase()) ||
						  element?.formInfo?.maidenOrFormerNames?.toLowerCase().includes(searchString.toLowerCase())
						: true),
			)
			.map(({ _id, formInfo }: any) => ({
				_id,
				name: `${formInfo.surname} ${formInfo.maidenOrFormerNames} ${formInfo.forenames}`,
				isActive: formInfo.approved || false,
			}))
	}, [selectApproved, jafList, searchString])

	useEffect(() => {
		if (filteredList.length) setSelected(filteredList[0]._id)
	}, [filteredList])

	return (
		<>
			<GlobalSidebar>
				<div className="h-full outline-none">
					<div className="px-2 pb-2 font-semibold text-sky-blue">
						<div className="px-1 pb-1">Name</div>

						<SearchField
							setSearchString={setSearchString}
							searchString={searchString}
							placeholder="Search...."
							className="mt-2 h-10 !w-full !py-0 !pr-5 text-sm"
						/>
					</div>

					<div className="h-auto outline-none" tabIndex={0}>
						<div className="flex justify-start space-x-2 pl-3 pb-4 text-white">
							<Input
								checked={selectApproved}
								type="checkbox"
								className="mr-2.5 !w-auto "
								onChange={e => setSelectApproved(e.target.checked)}
							/>
							<div>Approved JAF</div>
						</div>
						<SidebarList
							loading={loading}
							selected={selected}
							setSelected={setSelected}
							list={filteredList}
						/>
					</div>
				</div>
			</GlobalSidebar>
			<JavForm data={formData} disableSubmit={true} />
		</>
	)
}
export default JafWrapper
