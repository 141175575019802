import { SidebarList, GlobalSidebar, FormInputAntd, FormSelectAntd, FormCheckbox, AntdButton } from "components"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAppDispatch, useAppSelector } from "hooks"
import {
	getSDIncidents,
	isSDIncidentsLoading,
	isSDIncidentsSaving,
	saveSDIncidents,
	selectSDIncidentsById,
	selectSDIncidentsList,
} from "store/StaticData/SDIncidents.slice"
import { SDIncidentsInterface } from "@type/staticData.types"
import { useEffect, useMemo, useState } from "react"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { SDIncidentInitialValues, SDIncidentSchema } from "Schema/StaticData/SDIncidents"
import { selectDefaultEscalationType } from "config/constants"

interface SDIncidentsFormHandler extends SDIncidentsInterface {}

const SDIncidents = () => {
	const [selectedSDIncidents, setSelectedSDIncidents] = useState<string>("")
	const dispatch = useAppDispatch()
	const saving = useAppSelector(isSDIncidentsSaving())
	const loading = useAppSelector(isSDIncidentsLoading())
	const SDIncidentsList = useAppSelector(selectSDIncidentsList)
	const SDIncidentsById = useAppSelector(selectSDIncidentsById(selectedSDIncidents))
	const methods = useForm<SDIncidentsFormHandler>({ resolver: yupResolver(SDIncidentSchema) })

	const {
		handleSubmit,
		reset,
		formState: { isDirty, isSubmitting, isSubmitted },
	} = methods

	const sidebarSDIncidents = useMemo(() => {
		return SDIncidentsList?.map(SDIncidents => ({
			_id: SDIncidents._id,
			name: SDIncidents.name,
		}))
	}, [saving, SDIncidentsList?.length, isSubmitted])

	const handleSelected = (SDIncidentsId: string): void => setSelectedSDIncidents(SDIncidentsId)

	useEffect(() => {
		dispatch(getSDIncidents(setSelectedSDIncidents))
	}, [])

	useEffect(() => {
		if (selectedSDIncidents === "") reset(SDIncidentInitialValues)
		else reset(SDIncidentsById)
	}, [selectedSDIncidents])

	const onSubmit: SubmitHandler<SDIncidentsFormHandler> = data => {
		dispatch(saveSDIncidents(data, id => setSelectedSDIncidents(id)))
	}

	return (
		<>
			<GlobalSidebar>
				<SidebarList
					title={"Incidents"}
					loading={loading}
					selected={selectedSDIncidents}
					setSelected={handleSelected}
					list={sidebarSDIncidents}
				/>
			</GlobalSidebar>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="grid grid-cols-4 gap-y-4">
						<div className="col-span-2 grid grid-cols-2 gap-x-4 gap-y-2">
							<FormInputAntd type="text" label="Short Desc" name="name" />
							<FormInputAntd type="text" label="Description" name="description" />

							<FormSelectAntd
								label="Default Escalation Type"
								name="defaultEscalationType"
								options={[
									{ label: "Select Escalation Type", value: "Select Escalation Type" },
									...selectDefaultEscalationType,
								]}
							/>
							<FormInputAntd type="text" label="Priority" name="priority" />
							<FormCheckbox label="Shown in WorkMonitor" name="shownInWorkMonitor" />
							<FormCheckbox label="Is Company Wide" name="isCompanyWide" />
							<FormCheckbox label="Incident Description Required" name="isDescriptionRequired" />
							<FormCheckbox label="Enabled" name="isEnabled" />
						</div>
					</div>
					<div className="flex space-x-3 py-5">
						<AntdButton onClick={() => setSelectedSDIncidents("")}>New</AntdButton>
						<AntdButton disabled={!isDirty || isSubmitting} loading={saving} htmlType="submit">
							{selectedSDIncidents ? "Update" : "Save"}
						</AntdButton>
					</div>
				</form>
			</FormProvider>
		</>
	)
}

export default SDIncidents
