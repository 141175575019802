import {
	selectSignedIn,
	selectCurrentUser,
	selectLoadingAuth,
	selectCurrentUserRoles,
	selectCurrentUserPermissions,
} from "store/auth.slice"
import env from "config/env"
import Icon from "./Icon"
import { Navigate } from "react-router-dom"
import SidebarLayout from "./SidebarLayout"
import { AppRoutes } from "config"
import { AbacProvider } from "react-abac"
import { useLoadScript } from "@react-google-maps/api"
import React, { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "hooks"
import { getRoles, selectRolesAsAbacRules } from "store/Security/roles.slice"
import { getFeatureFlags, selectFeatureFlagsLoading } from "store/featureFlags.slice"

const AbacLayout: React.FC = () => {
	const dispatch = useAppDispatch()
	const loading = useAppSelector(selectLoadingAuth())
	const featureFlagsLoading = useAppSelector(selectFeatureFlagsLoading)
	const signedIn = useAppSelector(selectSignedIn())

	const currentUser = useAppSelector(selectCurrentUser())
	const currentUserRoles = useAppSelector(selectCurrentUserRoles())
	const currentUserPermissions = useAppSelector(selectCurrentUserPermissions())
	const rules = useAppSelector(selectRolesAsAbacRules)
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: env.googleMapsApiKey || "",
		libraries: ["places", "visualization", "drawing", "geometry"],
	})

	useEffect(() => {
		dispatch(getRoles())
		dispatch(getFeatureFlags())
	}, [])
	if (!signedIn && !loading) return <Navigate to={AppRoutes.LOGIN_PAGE} />
	if (loading || !isLoaded || featureFlagsLoading)
		return (
			<div className="flex h-screen w-screen items-center justify-center">
				<Icon name="spinner" color="white" size={100} className="mr-3 mt-[-100] animate-spin !text-purple" />
			</div>
		)

	return (
		<AbacProvider user={currentUser} roles={currentUserRoles} rules={rules} permissions={currentUserPermissions}>
			<SidebarLayout />
		</AbacProvider>
	)
}

export default AbacLayout
