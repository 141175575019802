import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetEmployeeBankDetailInterface, CreateEmployeeBankDetailInterface } from "@type/employees.types"
import { employeeBankDetailsService } from "services"
import { AppThunk, RootState } from "store"

export interface BankDetailInterface {
	list: Array<GetEmployeeBankDetailInterface>
	loading: boolean
	saving: boolean
}

const initialState: BankDetailInterface = {
	list: [],
	loading: false,
	saving: false,
}

const detailSlice = createSlice({
	name: "bankDetail",
	initialState,
	reducers: {
		requestBankDetail: bankDetail => {
			bankDetail.loading = true
		},
		receiveBankDetailData: (bankDetail, action: PayloadAction<Array<GetEmployeeBankDetailInterface>>) => {
			bankDetail.loading = false
			bankDetail.list = action.payload
		},
		receiveBankDetailDataFailed: bankDetail => {
			bankDetail.loading = false
		},
		requestSavingBankDetail: bankDetailData => {
			bankDetailData.saving = true
		},
		SavingBankDetail: (bankDetail, action: PayloadAction<GetEmployeeBankDetailInterface>) => {
			bankDetail.loading = false
			bankDetail.list = [...bankDetail.list, action.payload]
		},
		updateBankDetail: (bankDetail, action: PayloadAction<GetEmployeeBankDetailInterface>) => {
			bankDetail.loading = false
			const bankDetailDataIndex = bankDetail.list.findIndex(bank => {
				return bank._id === action.payload._id
			})
			if (bankDetailDataIndex !== -1) {
				bankDetail.list.splice(bankDetailDataIndex, 1, action.payload)
			}
		},
		SavingBankDetailFailed: bankDetail => {
			bankDetail.loading = false
		},
	},
})

//REDUCER
export default detailSlice.reducer

//ACTIONS
const {
	requestBankDetail,
	receiveBankDetailData,
	receiveBankDetailDataFailed,
	requestSavingBankDetail,
	SavingBankDetail,
	SavingBankDetailFailed,
	updateBankDetail,
} = detailSlice.actions

const getBankDetailByEmployeeId =
	(employeeId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestBankDetail())
			const { data: bankDetailData } = await employeeBankDetailsService.getEmployeeBankDetailByEmployeeId(
				employeeId,
			)
			dispatch(receiveBankDetailData(bankDetailData))
		} catch (error) {
			dispatch(receiveBankDetailDataFailed())
		}
	}

const saveBankDetailData =
	(BankDetail: Partial<GetEmployeeBankDetailInterface>): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestSavingBankDetail())
			if (BankDetail._id) {
				const { data: bankDetailData } = await employeeBankDetailsService.updateEmployeeBankDetailBy(
					BankDetail._id,
					BankDetail as GetEmployeeBankDetailInterface,
				)
				dispatch(updateBankDetail(bankDetailData))
			} else {
				const { data: bankDetailData } = await employeeBankDetailsService.createEmployeeBankDetail(
					BankDetail as CreateEmployeeBankDetailInterface,
				)
				dispatch(SavingBankDetail(bankDetailData))
			}
		} catch (error) {
			dispatch(SavingBankDetailFailed())
		}
	}

export { getBankDetailByEmployeeId, saveBankDetailData }

//SELECTORS
const selectEmployeeBankDetail = (state: RootState) => state.employee.bankDetail
const isBankDetailLoading = () => (state: RootState) => selectEmployeeBankDetail(state).loading
const selectBankDetailData = () => (state: RootState) => selectEmployeeBankDetail(state).list
const isBankDetailSaving = () => (state: RootState) => selectEmployeeBankDetail(state).saving

export { selectBankDetailData, selectEmployeeBankDetail, isBankDetailSaving, isBankDetailLoading }
