import axios from "axios"
import { GetSDQualificationInterface, SDQualificationInterface } from "@type/staticData.types"

const ROOT_PATH = "static-data/qualifications"
export default class SDQualificationsService {
	static createSDQualification = (qualification: SDQualificationInterface) =>
		axios.post<GetSDQualificationInterface>(ROOT_PATH, qualification)

	static getAllSDQualifications = () => axios.get<Array<GetSDQualificationInterface>>(`${ROOT_PATH}`)

	static getSDQualificationById = (id: string) => axios.get<GetSDQualificationInterface>(`${ROOT_PATH}/${id}`)

	static updateSDQualification = (id: string, qualification: Partial<GetSDQualificationInterface>) =>
		axios.put<GetSDQualificationInterface>(`${ROOT_PATH}/${id}`, qualification)
}
