import {
	ShiftInterface,
	UpdateWorkMonitor,
	ResolveIncidentDto,
	WorkMonitorInterface,
	IncidentReportInterface,
	UpdateMultipleWorkMonitor,
} from "@type/workMonitor.types"
import axios from "axios"

const ROOT_PATH = "/shifts"

export default class WorkMonitorShifts {
	static getScheduleShiftEvents = (scheduleId: string) =>
		axios.get<ShiftInterface[]>(`${ROOT_PATH}/shift-events-by-schedule/${scheduleId}`)

	static getScheduleIncidents = (scheduleId: string) =>
		axios.get<IncidentReportInterface[]>(`${ROOT_PATH}/incidents-by-schedule/${scheduleId}`)

	static getShifts = ({
		startTime = "2022-05-02T00:00:00Z",
		finishTime = "2022-05-08T00:00:00Z",
	}: WorkMonitorInterface) => {
		return axios.get(`${ROOT_PATH}`, {
			params: { startTime, finishTime },
		})
	}

	static updateShifts = (shiftId: string, data: UpdateWorkMonitor) =>
		axios.put(`${ROOT_PATH}/${shiftId}/add-event`, data)

	static addIncidentInShift = (shiftId: string, body: IncidentReportInterface) =>
		axios.put(`${ROOT_PATH}/${shiftId}/incident`, body)

	static resolveIncidentInShift = (incidentId: string, body: ResolveIncidentDto) =>
		axios.put(`${ROOT_PATH}/resolve-incident/${incidentId}`, body)

	static updateMultipleShifts = (data: UpdateMultipleWorkMonitor[]) =>
		axios.put(`${ROOT_PATH}/add-multiple-event`, data)
}
