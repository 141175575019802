import React, { useEffect, useMemo, useRef, useState } from "react"
import { GlobalSidebar, SidebarList, AntdButton, FormSelectAntd } from "components"
import { yupResolver } from "@hookform/resolvers/yup"
import { UpdateCronNotificationsInterface } from "@type/cronNotifications.types"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { CronNotificationsInitialValues, CronNotificationsSchema } from "Schema/StaticData/CronNotifications"
import {
	getCronNotifications,
	isCronNotificationsLoading,
	isCronNotificationsSaving,
	saveCronNotifications,
	selectCronNotificationsById,
	selectCronNotificationsList,
} from "store/StaticData/CronNotifications.slice"
import { useAppDispatch, useAppSelector } from "hooks"
import { isUserLoading, selectUserList } from "store/Security/users.slice"

interface CronNotificationFormHandler extends UpdateCronNotificationsInterface {}

const CronNotifications = () => {
	const [selectedCronNotifications, setSelectedCronNotifications] = useState<string | null>("")
	const dispatch = useAppDispatch()
	const saving = useAppSelector(isCronNotificationsSaving())
	const loading = useAppSelector(isCronNotificationsLoading())
	const cronNotificationsList = useAppSelector(selectCronNotificationsList)
	const cronNotification = useAppSelector(selectCronNotificationsById(selectedCronNotifications))
	const isNewBeingCreated = useRef(false)
	const users = useAppSelector(selectUserList)
	const usersLoading = useAppSelector(isUserLoading())

	const methods = useForm<CronNotificationFormHandler>({ resolver: yupResolver(CronNotificationsSchema) })
	const {
		handleSubmit,
		reset,
		formState: { isDirty, isSubmitted, isSubmitting },
	} = methods
	const sidebarCronNotifications = useMemo(() => {
		return cronNotificationsList?.map(CronNotifications => ({
			_id: CronNotifications._id,
			name: CronNotifications.reportTitle,
		}))
	}, [saving, cronNotificationsList?.length, isSubmitted])

	const onSubmit: SubmitHandler<CronNotificationFormHandler> = data => {
		dispatch(
			saveCronNotifications(data, id => {
				setSelectedCronNotifications(id)
				isNewBeingCreated.current = false
			}),
		)
	}

	useEffect(() => {
		dispatch(getCronNotifications(setSelectedCronNotifications))
	}, [])

	useEffect(() => {
		if (selectedCronNotifications !== (null && undefined)) {
			reset({
				...CronNotificationsInitialValues,
				...cronNotification,
				users: cronNotification?.users.map(item => item._id),
			})
		} else if (selectedCronNotifications === null && !isNewBeingCreated.current) {
			reset({ ...CronNotificationsInitialValues })
		}
	}, [selectedCronNotifications])

	const handleSelected = (CronNotificationsId: string | null): void =>
		setSelectedCronNotifications(CronNotificationsId)

	return (
		<>
			<GlobalSidebar>
				<SidebarList
					title={"Notifications"}
					loading={loading}
					selected={selectedCronNotifications}
					setSelected={handleSelected}
					list={sidebarCronNotifications}
				/>
			</GlobalSidebar>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="flex w-1/2 flex-col gap-y-2">
						<FormSelectAntd
							allowClear
							name="users"
							mode="multiple"
							label="Assigned Users"
							loading={usersLoading}
							options={users.map(item => ({
								label: `${item.firstName} ${item.lastName}`,
								value: item._id,
							}))}
						/>
						<div className="flex gap-2">
							<AntdButton
								disabled={!selectedCronNotifications || !isDirty || isSubmitting}
								loading={saving}
								htmlType="submit"
							>
								Update
							</AntdButton>
						</div>
					</div>
				</form>
			</FormProvider>
		</>
	)
}

export default CronNotifications
