import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
	GetCronNotificationsInterface,
	CronNotificationsInterface,
	UpdateCronNotificationsInterface,
} from "@type/cronNotifications.types"
import { CronNotificationsService } from "services"
import { AppThunk, RootState } from "store"
import { sortByProperty, sortDeepCopyByProperty, updateState } from "config/utils"
export interface CronNotificationsStateInterface {
	list: null | Array<GetCronNotificationsInterface>
	loading: boolean
	saving: boolean
}

const initialState: CronNotificationsStateInterface = {
	list: null,
	loading: false,
	saving: false,
}

const cronNotificationsSlice = createSlice({
	name: "cronNotifications",
	initialState,
	reducers: {
		fetchingCronNotifications: cronNotifications => {
			cronNotifications.loading = true
		},
		cronNotificationsFetched: (cronNotifications, action: PayloadAction<GetCronNotificationsInterface[]>) => {
			cronNotifications.loading = false
			cronNotifications.list = action.payload
		},
		cronNotificationsFetchingFailed: CronNotifications => {
			CronNotifications.loading = false
		},
		savingCronNotifications: cronNotificationsData => {
			cronNotificationsData.saving = true
		},
		cronNotificationsSaved: (cronNotifications, action: PayloadAction<GetCronNotificationsInterface>) => {
			cronNotifications.saving = false
			const [UpdatedValue] = updateState(cronNotifications.list, action.payload, "_id")
			cronNotifications.list = UpdatedValue
		},
		cronNotificationsSavingFailed: cronNotifications => {
			cronNotifications.saving = false
		},
		ClearCronNotificationsState: cronNotifications => {
			cronNotifications.list = []
			cronNotifications.loading = false
			cronNotifications.saving = false
		},
	},
})

//REDUCER
export default cronNotificationsSlice.reducer

//ACTIONS
export const {
	ClearCronNotificationsState,
	fetchingCronNotifications,
	cronNotificationsFetched,
	cronNotificationsFetchingFailed,
	savingCronNotifications,
	cronNotificationsSaved,
	cronNotificationsSavingFailed,
} = cronNotificationsSlice.actions

const getCronNotifications =
	(cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingCronNotifications())
			const { data: CronNotificationsResponse } = await CronNotificationsService.getAllCronNotifications()
			CronNotificationsResponse.sort(sortByProperty("name"))
			cb && cb(CronNotificationsResponse[0]?._id)
			dispatch(cronNotificationsFetched(CronNotificationsResponse))
		} catch (error) {
			dispatch(cronNotificationsFetchingFailed())
		}
	}

const saveCronNotifications =
	(CronNotificationsData: Partial<UpdateCronNotificationsInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingCronNotifications())
			if (CronNotificationsData?._id) {
				data = await CronNotificationsService.updateCronNotifications(
					CronNotificationsData._id,
					CronNotificationsData,
				)
			} else
				data = await CronNotificationsService.createCronNotifications(
					CronNotificationsData as CronNotificationsInterface,
				)
			const { data: CronNotificationsResponse } = data
			cb && cb(CronNotificationsResponse._id)
			dispatch(cronNotificationsSaved(CronNotificationsResponse))
		} catch (error) {
			dispatch(cronNotificationsSavingFailed())
		}
	}

export { getCronNotifications, saveCronNotifications }

//SELECTORS
const selectCronNotificationsState = (state: RootState) => state.staticData.CronNotification
const isCronNotificationsLoading = () => (state: RootState) => selectCronNotificationsState(state).loading
const isCronNotificationsSaving = () => (state: RootState) => selectCronNotificationsState(state).saving
const selectCronNotificationsList = createSelector(
	(state: RootState) => state.staticData.CronNotification.list,
	list => {
		return sortDeepCopyByProperty(list, "description") as GetCronNotificationsInterface[]
	},
)

const selectCronNotificationsDropdownList = createSelector(
	(state: RootState) => state.staticData.CronNotification.list,
	list => {
		return [
			{ label: "Select Report", value: "" },
			...sortDeepCopyByProperty(list?.map(item => ({ label: item.reportName, value: item._id })) || [], "label"),
		]
	},
)

const suppliersDropdownList = createSelector(
	(state: RootState) => state.staticData.CronNotification.list,
	list => {
		return [
			...sortDeepCopyByProperty(list?.map(item => ({ label: item.reportName, value: item._id })) || [], "label"),
		]
	},
)

const selectCronNotificationsById = (CronNotificationsId: string | null) => (state: RootState) =>
	state.staticData.CronNotification?.list?.find(cronNotifications => cronNotifications._id === CronNotificationsId)

export {
	selectCronNotificationsList,
	selectCronNotificationsDropdownList,
	suppliersDropdownList,
	selectCronNotificationsState,
	selectCronNotificationsById,
	isCronNotificationsLoading,
	isCronNotificationsSaving,
}
