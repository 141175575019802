import axios from "axios"
import { ActiveEmployeeDetailsInterface, CreateEmployeeInterface, GetEmployeeInterface } from "@type/employees.types"

const ROOT_PATH = "employees/details"
const ROOT_PATH_PARENT = "employees"

export default class EmployeesService {
	static createEmployee = (employee: CreateEmployeeInterface) => axios.post<GetEmployeeInterface>(ROOT_PATH, employee)

	static getAllEmployees = () => axios.get<Array<GetEmployeeInterface>>(ROOT_PATH)
	static getEmployeeByBranchId = (id: string) => axios.get<Array<GetEmployeeInterface>>(`${ROOT_PATH}/?branch=${id}`)
	static getActiveEmployeeByBranchId = (ids: ActiveEmployeeDetailsInterface) =>
		axios.get<Array<GetEmployeeInterface>>(`${ROOT_PATH}-active`, { params: ids })
	static getEmployeeById = (id: string) => axios.get<GetEmployeeInterface>(`${ROOT_PATH}/${id}`)

	static getEmployeeBySearch = (employee: { shortName?: string; clientPin?: string; reference?: string }) =>
		axios.get<GetEmployeeInterface>(`${ROOT_PATH_PARENT}/enhanced-search`, { params: employee })

	static updateEmployee = (id: string, employee: Partial<GetEmployeeInterface>) =>
		axios.put<GetEmployeeInterface>(`${ROOT_PATH}/${id}`, employee)
}
