import axios from "axios"
import { EmployeeTrainingFormInterface, EmployeeTrainingInterface } from "@type/employees.types"

const ROOT_PATH = "employees/training"

export default class EmployeesTrainingService {
	static createEmployeeTraining = (trainingData: EmployeeTrainingFormInterface) =>
		axios.post<EmployeeTrainingFormInterface[]>(ROOT_PATH, trainingData)

	static updateEmployeeTraining = (id: string, trainingData: EmployeeTrainingFormInterface) =>
		axios.put<EmployeeTrainingFormInterface[]>(`${ROOT_PATH}/${id}`, trainingData)

	static deleteEmployeeTrainingById = (id: string) => axios.delete(`${ROOT_PATH}/${id}`)

	static findEmployeeTrainingByEmployeeID = (id: string) =>
		axios.get<EmployeeTrainingInterface[]>(`${ROOT_PATH}?employee=${id}`)
}
