import { updateState } from "config/utils"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EmployeeTrainingFormInterface, EmployeeTrainingInterface } from "@type/employees.types"
import { employeesTrainingService } from "services"
import { AppThunk, RootState } from "store"

export interface TrainingStateInterface {
	data: EmployeeTrainingInterface[]
	loading?: boolean
	saving: boolean
}

const initialState: TrainingStateInterface = {
	data: [],
	loading: false,
	saving: false,
}

const trainingSlice = createSlice({
	name: "training",
	initialState,
	reducers: {
		requestTrainingData: training => {
			training.loading = true
		},
		receiveTrainingData: (training, action: PayloadAction<EmployeeTrainingInterface[]>) => {
			training.loading = false
			training.data = action.payload
		},
		receiveTrainingDataFailed: training => {
			training.loading = false
		},
		requestSavingTrainingData: trainingData => {
			trainingData.saving = true
		},
		SavingEmployeeTrainingSuccess: (training, action: PayloadAction<EmployeeTrainingFormInterface[]>) => {
			training.saving = false
			const [UpdatedValue] = updateState(training.data, action.payload, "_id")

			training.data = UpdatedValue
		},
		SavingEmployeeTrainingSuccessFail: training => {
			training.saving = false
		},
	},
})

//REDUCER
export default trainingSlice.reducer

//ACTIONS
const {
	requestTrainingData,
	receiveTrainingData,
	receiveTrainingDataFailed,
	requestSavingTrainingData,
	SavingEmployeeTrainingSuccess,
	SavingEmployeeTrainingSuccessFail,
} = trainingSlice.actions

const getEmployeesTrainingByEmployeeId =
	(employeeId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestTrainingData())
			const { data: TrainingResponse } = await employeesTrainingService.findEmployeeTrainingByEmployeeID(
				employeeId,
			)
			dispatch(receiveTrainingData(TrainingResponse))
		} catch (error) {
			dispatch(receiveTrainingDataFailed())
		}
	}

const saveEmployeeTrainingData =
	(locationData: EmployeeTrainingFormInterface): AppThunk =>
	async dispatch => {
		try {
			let data = null
			dispatch(requestSavingTrainingData())
			if (locationData._id) {
				data = await employeesTrainingService.updateEmployeeTraining(locationData._id, locationData)
			} else {
				data = await employeesTrainingService.createEmployeeTraining(locationData)
			}
			const { data: locationResponse } = data
			dispatch(SavingEmployeeTrainingSuccess(locationResponse))
		} catch (error) {
			dispatch(SavingEmployeeTrainingSuccessFail())
		}
	}

export { saveEmployeeTrainingData, getEmployeesTrainingByEmployeeId }

//SELECTORS
const selectEmployeeTrainingState = (state: RootState) => state.employee.training
const isEmployeeTrainingLoading = () => (state: RootState) => selectEmployeeTrainingState(state).loading
const selectEmployeeTrainingData = () => (state: RootState) => selectEmployeeTrainingState(state).data || []
const isEmployeeTrainingSaving = () => (state: RootState) => selectEmployeeTrainingState(state).saving
const selectEmployeeTrainingById = (trainingId: string | null) => (state: RootState) =>
	selectEmployeeTrainingData()(state)?.find(training => training._id === trainingId)

export { isEmployeeTrainingLoading, selectEmployeeTrainingData, isEmployeeTrainingSaving, selectEmployeeTrainingById }
