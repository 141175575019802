import { updateState } from "config/utils"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EmployeeUsualWorkFormInterface, EmployeeUsualWorkInterface } from "@type/employees.types"
import { employeesUsualWorkService } from "services"
import { AppThunk, RootState } from "store"

export interface UsualWorkStateInterface {
	data: EmployeeUsualWorkInterface[]
	loading?: boolean
	saving: boolean
}

const initialState: UsualWorkStateInterface = {
	data: [],
	loading: false,
	saving: false,
}

const usualWorkSlice = createSlice({
	name: "usualWork",
	initialState,
	reducers: {
		requestUsualWorkData: usualWork => {
			usualWork.loading = true
		},
		receiveUsualWorkData: (usualWork, action: PayloadAction<EmployeeUsualWorkInterface[]>) => {
			usualWork.loading = false
			usualWork.data = action.payload
		},
		receiveUsualWorkDataFailed: usualWork => {
			usualWork.loading = false
		},
		requestSavingUsualWorkData: usualWorkData => {
			usualWorkData.saving = true
		},
		SavingEmployeeUsualWorkSuccess: (usualWork, action: PayloadAction<EmployeeUsualWorkFormInterface[]>) => {
			usualWork.saving = false
			const [UpdatedValue] = updateState(usualWork.data, action.payload, "_id")

			usualWork.data = UpdatedValue
		},
		SavingEmployeeUsualWorkSuccessFail: usualWork => {
			usualWork.saving = false
		},
	},
})

//REDUCER
export default usualWorkSlice.reducer

//ACTIONS
const {
	requestUsualWorkData,
	receiveUsualWorkData,
	receiveUsualWorkDataFailed,
	requestSavingUsualWorkData,
	SavingEmployeeUsualWorkSuccess,
	SavingEmployeeUsualWorkSuccessFail,
} = usualWorkSlice.actions

const getEmployeesUsualWorkByEmployeeId =
	(employeeId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestUsualWorkData())
			const { data: UsualWorkResponse } = await employeesUsualWorkService.findEmployeeUsualWorkByEmployeeID(
				employeeId,
			)
			dispatch(receiveUsualWorkData(UsualWorkResponse))
		} catch (error) {
			dispatch(receiveUsualWorkDataFailed())
		}
	}

const saveEmployeeUsualWorkData =
	(locationData: EmployeeUsualWorkFormInterface): AppThunk =>
	async dispatch => {
		try {
			let data = null
			dispatch(requestSavingUsualWorkData())
			if (locationData._id) {
				data = await employeesUsualWorkService.updateEmployeeUsualWork(locationData._id, locationData)
			} else {
				data = await employeesUsualWorkService.createEmployeeUsualWork(locationData)
			}
			const { data: locationResponse } = data
			dispatch(SavingEmployeeUsualWorkSuccess(locationResponse))
		} catch (error) {
			dispatch(SavingEmployeeUsualWorkSuccessFail())
		}
	}

export { saveEmployeeUsualWorkData, getEmployeesUsualWorkByEmployeeId }

//SELECTORS
const selectEmployeeUsualWorkState = (state: RootState) => state.employee.usualWork
const isEmployeeUsualWorkLoading = () => (state: RootState) => selectEmployeeUsualWorkState(state).loading
const selectEmployeeUsualWorkData = () => (state: RootState) => selectEmployeeUsualWorkState(state).data || []
const isEmployeeUsualWorkSaving = () => (state: RootState) => selectEmployeeUsualWorkState(state).saving
const selectEmployeeUsualWorkById = (usualWorkId: string | null) => (state: RootState) =>
	selectEmployeeUsualWorkData()(state)?.find(usualWork => usualWork._id === usualWorkId)

export {
	isEmployeeUsualWorkLoading,
	selectEmployeeUsualWorkData,
	isEmployeeUsualWorkSaving,
	selectEmployeeUsualWorkById,
}
