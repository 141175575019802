import axios from "axios"
import { GetSDEquipmentTypeInterface, SDEquipmentTypeInterface } from "@type/staticData.types"

const ROOT_PATH = "static-data/equipment-type"
export default class SDEquipmentTypeService {
	static createSDEquipmentType = (equipmentType: SDEquipmentTypeInterface) =>
		axios.post<GetSDEquipmentTypeInterface>(ROOT_PATH, equipmentType)

	static getAllSDEquipmentType = () => axios.get<Array<GetSDEquipmentTypeInterface>>(`${ROOT_PATH}`)

	static getSDEquipmentTypeById = (id: string) => axios.get<GetSDEquipmentTypeInterface>(`${ROOT_PATH}/${id}`)

	static updateSDEquipmentType = (id: string, equipmentType: Partial<GetSDEquipmentTypeInterface>) =>
		axios.put<GetSDEquipmentTypeInterface>(`${ROOT_PATH}/${id}`, equipmentType)
}
