import { useEffect, useState } from "react"
import momentZ from "moment-timezone"
import { DATE_FORMATS } from "config/constants"

const ShowCurrentTime = () => {
	const [date, setDate] = useState(momentZ().format(DATE_FORMATS.SHOW_TIME_TOP))

	useEffect(() => {
		const timeIntervalId = setInterval(() => {
			setDate(momentZ().format(DATE_FORMATS.SHOW_TIME_TOP))
		}, 60000)

		return () => {
			clearInterval(timeIntervalId)
		}
	}, [])

	return [date]
}

export default ShowCurrentTime
