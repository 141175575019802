import React, { useEffect, useRef } from "react"
import moment from "moment"

import { TimePicker } from "antd"
import styles from "../../src/antd-datePicker.module.css"
import { DATE_FORMATS, GLOBAL_TIME_FORMAT } from "config/constants"
import { BaseSelectRef } from "rc-select/lib/BaseSelect"
import { twMerge } from "tailwind-merge"

interface Props {
	label: string
	containerClass?: string
	className?: string
	formValueSetter: (name: any, value: any, args: any) => void
	name: string
	watch: (name: any, defaultValue?: any) => any
	disabled?: boolean
	defaultFocusOn?: boolean
	errors?: any
}

const TimeField: React.FC<Props> = ({
	name = "",
	formValueSetter,
	label,
	className,
	containerClass = "w-full",
	watch,
	disabled = false,
	defaultFocusOn = false,
	errors,
}) => {
	const inputRef = useRef<BaseSelectRef>(null)

	const formValue = watch(name) ? moment(watch(name), GLOBAL_TIME_FORMAT) : watch(name) || null

	useEffect(() => {
		if (defaultFocusOn) inputRef?.current?.focus()
	}, [])

	return (
		<div className={`flex flex-col  ${containerClass}`}>
			<label className=" text-dark-alt"> {label}</label>
			<div className={className}>
				<TimePicker
					style={{ width: "100%" }}
					ref={inputRef}
					disabled={disabled}
					value={formValue}
					format={GLOBAL_TIME_FORMAT}
					className={styles.datePickerStyle}
					placeholder={GLOBAL_TIME_FORMAT}
					onChange={(value: any) => {
						if (value)
							formValueSetter(name, moment(value).format(DATE_FORMATS.HH_mm), { shouldDirty: true })
					}}
				/>
			</div>
			{!!errors?.[name]?.message && (
				<span className={twMerge(`mt-1 ml-1 text-sm text-danger`)}>{errors?.[name]?.message}</span>
			)}
		</div>
	)
}

export default TimeField
