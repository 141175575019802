import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
	GeocodeActionInterface,
	GeocodeLatLngInterface,
	GeocodeResultInterface,
	GetDistanceMatrixResponseInterface,
	NearbyLocationEmployeesActionInterface,
} from "@type/geolocation.types"
import { AppThunk, RootState } from "store"
import { defaultDistanceMatrixResponseData } from "config/constants"
import { geolocationService } from "services"
import { NearbyLocationEmployeesInterface } from "@type/employees.types"

export interface GoogleMapStateInterface {
	geolocationDataObject: { [key: string]: GeocodeResultInterface }
	loaders: { [key: string]: boolean }
	errors: { [key: string]: boolean }
	loadingNearbyEmployees: boolean
	loadingDistance: boolean
	error: boolean
	distanceData: GetDistanceMatrixResponseInterface
	nearbyEmployeesObject: { [key: string]: NearbyLocationEmployeesInterface[] }
}

const initialState: GoogleMapStateInterface = {
	loaders: {},
	errors: {},
	loadingNearbyEmployees: false,
	loadingDistance: false,
	error: false,
	geolocationDataObject: {},
	nearbyEmployeesObject: {},
	distanceData: defaultDistanceMatrixResponseData,
}

const geolocationSlice = createSlice({
	name: "geolocation",
	initialState,
	reducers: {
		fetchingPostalCodeData: (state, action: PayloadAction<{ [key: string]: string }>) => {
			const { postalCode } = action.payload
			state.loaders[postalCode] = true
			state.errors[postalCode] = false
		},
		postalCodeDataFetched: (state, action: PayloadAction<GeocodeActionInterface>) => {
			const { postalCode, ...payload } = action.payload
			state.geolocationDataObject[postalCode] = payload.results[0]
			state.loaders[postalCode] = false
			state.errors[postalCode] = false
		},
		postalCodeDataFailed: (state, action: PayloadAction<{ [key: string]: string }>) => {
			const { postalCode } = action.payload
			state.loaders[postalCode] = false
			state.errors[postalCode] = true
		},

		fetchingDistanceData: state => {
			state.loadingDistance = true
			state.error = false
		},
		distanceDataFetched: (state, action: PayloadAction<GetDistanceMatrixResponseInterface>) => {
			state.loadingDistance = false
			state.error = false
			state.distanceData = action.payload
		},

		fetchingDistanceDataFailed: state => {
			state.loadingDistance = false
			state.error = true
		},

		employeeNearbyLocationFetching: state => {
			state.loadingNearbyEmployees = true
		},

		employeeNearbyLocationFetched: (state, action: PayloadAction<NearbyLocationEmployeesActionInterface>) => {
			const { locationId, ...payload } = action.payload
			state.nearbyEmployeesObject[locationId] = payload.nearbyEmployees
			state.loadingNearbyEmployees = false
		},

		employeeNearbyLocationFetchedFailed: state => {
			state.loadingNearbyEmployees = false
		},
	},
})

//REDUCER
export default geolocationSlice.reducer

//ACTIONS
export const {
	fetchingPostalCodeData,
	postalCodeDataFetched,
	postalCodeDataFailed,
	fetchingDistanceData,
	distanceDataFetched,
	fetchingDistanceDataFailed,
	employeeNearbyLocationFetching,
	employeeNearbyLocationFetched,
	employeeNearbyLocationFetchedFailed,
} = geolocationSlice.actions

const getPostalCodeCoordinates =
	(postalCode: string): AppThunk =>
	async (dispatch, getState) => {
		try {
			if (getState().geolocation.geolocationDataObject?.[postalCode]) return
			dispatch(fetchingPostalCodeData({ postalCode }))
			const { data: latLngResponse } = await geolocationService.getLatLng(postalCode)
			dispatch(postalCodeDataFetched({ ...latLngResponse, postalCode }))
		} catch {
			dispatch(postalCodeDataFailed({ postalCode }))
		}
	}

const getDrivingDistanceBetweenCoordinates =
	(destinationPostalCode: string, origin: GeocodeLatLngInterface): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingDistanceData())
			const { data: latLngResponse } = await geolocationService.getLatLng(destinationPostalCode)
			dispatch(postalCodeDataFetched({ ...latLngResponse, postalCode: destinationPostalCode }))
			const { data: distanceMatrixResponse } = await geolocationService.getDistance({
				origin,
				destination: latLngResponse.results[0].geometry.location,
			})
			dispatch(distanceDataFetched(distanceMatrixResponse))
		} catch {
			dispatch(fetchingDistanceDataFailed())
		}
	}

const getEmployeesNearbyLocation =
	(locationId: string, employeeType: string): AppThunk =>
	async dispatch => {
		try {
			// if (getState().geolocation.nearbyEmployeesObject?.[locationId]) return
			dispatch(employeeNearbyLocationFetching())
			const { data: nearbyLocationEmployeeResponse } = await geolocationService.getNearbyLocationEmployees(
				locationId,
				employeeType,
			)

			dispatch(employeeNearbyLocationFetched({ nearbyEmployees: nearbyLocationEmployeeResponse, locationId }))
		} catch (error) {
			dispatch(employeeNearbyLocationFetchedFailed())
		}
	}

export { getPostalCodeCoordinates, getDrivingDistanceBetweenCoordinates, getEmployeesNearbyLocation }

//SELECTORS
const selectPostalCodeMap = (state: RootState) => state.geolocation

const selectPostalCodeData = (postalCode: string) => (state: RootState) =>
	state.geolocation.geolocationDataObject?.[postalCode]?.geometry?.location

const isLoadingPostalCodeCoordinates = (postalCode: string) => (state: RootState) =>
	state.geolocation.loaders[postalCode]
const selectPostalCodeErrors = (postalCode: string) => (state: RootState) => state.geolocation.errors[postalCode]

const selectDrivingDistance = () => (state: RootState) => selectPostalCodeMap(state).distanceData.rows[0].elements[0]
const isDrivingDistanceLoading = () => (state: RootState) => selectPostalCodeMap(state).loadingDistance
const selectDistanceMatrixError = (state: RootState) => selectPostalCodeMap(state).error

const selectNearbyLocationEmployees = (locationId: string) => (state: RootState) =>
	state.geolocation.nearbyEmployeesObject?.[locationId] || []
const isNearbyEmployeesLoading = () => (state: RootState) => selectPostalCodeMap(state).loadingNearbyEmployees

export {
	selectPostalCodeData,
	isLoadingPostalCodeCoordinates,
	selectPostalCodeErrors,
	isDrivingDistanceLoading,
	selectDrivingDistance,
	selectDistanceMatrixError,
	selectNearbyLocationEmployees,
	isNearbyEmployeesLoading,
}
