import axios from "axios"
import { EmployeeQualificationInterface, EmployeeQualificationSiaInterface, LdnInterface } from "@type/employees.types"

const ROOT_PATH = "employees/qualifications"
const ROOT_PATH_QUALIFICATION = "static-data/qualifications"

export default class EmployeesQualificationService {
	static createEmployeeQualification = (employeeQualification: EmployeeQualificationInterface) =>
		axios.post<EmployeeQualificationInterface>(ROOT_PATH, employeeQualification)

	static createEmployeeQualificationSia = (employeeQualification: EmployeeQualificationInterface) =>
		axios.post<EmployeeQualificationInterface>(`${ROOT_PATH}-sia`, employeeQualification)

	static getEmployeesQualification = (employeeId: string) =>
		axios.get<EmployeeQualificationInterface[]>(`${ROOT_PATH}/${employeeId}`)

	static updateEmployeeQualification = (id: string, qualificationData: EmployeeQualificationInterface) =>
		axios.put<EmployeeQualificationInterface>(`${ROOT_PATH}/${id}`, qualificationData)

	static getQualification = () => axios.get(`${ROOT_PATH_QUALIFICATION}`)

	static updateEmployeeQualificationSia = (id: string, siaImage: EmployeeQualificationSiaInterface) =>
		axios.put<EmployeeQualificationInterface>(`${ROOT_PATH}-sia/${id}`, siaImage)

	static createLdnForEmployeeQualification = (employeeQualificationId: string, payload: LdnInterface) =>
		axios.post<EmployeeQualificationInterface>(`${ROOT_PATH}/ldn/${employeeQualificationId}`, payload)

	static updateLdnForEmployeeQualification = (employeeQualificationId: string, payload: LdnInterface) =>
		axios.put<EmployeeQualificationInterface>(`${ROOT_PATH}/ldn/${employeeQualificationId}`, payload)

	static deleteEmployeesQualification = (id: string) =>
		axios.delete<EmployeeQualificationInterface>(`${ROOT_PATH}-delete/${id}`)
}
