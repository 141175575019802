import useAppSelector from "hooks/useAppSelector"
import { selectEmployeeById } from "store/Employee/detail.slice"
import React, { useMemo } from "react"
import { selectBranchList } from "store/Branches/branch.slice"
import { selectSDSuppliersList } from "store/StaticData/SDSuppliers.slice"
import { Card, Descriptions } from "antd-v5"
interface Props {
	id: string
}

const EmployeeInfo: React.FC<Props> = ({ id = "" }) => {
	const employee = useAppSelector(selectEmployeeById(id))
	const branches = useAppSelector(selectBranchList())
	const suppliers = useAppSelector(selectSDSuppliersList)

	const mappedEmployee = useMemo(() => {
		return [
			{ label: "First Name", children: employee?.firstName },
			{ label: "Last Name", children: employee?.lastName },
			{ label: "Short Name", children: employee?.shortName },
			{ label: "PIN", children: employee?.pin },
			...(employee?.branch
				? [{ label: "Branch", children: branches?.find(({ _id }) => _id === employee?.branch)?.name }]
				: []),
			...(employee?.supplier
				? [{ label: "Supplier", children: suppliers.find(({ _id }) => _id === employee?.supplier)?.name }]
				: []),

			{ label: "Client PIN", children: employee?.clientPin },
			...(employee?.addressLine1 || employee?.addressLine2
				? [
						{
							label: "Address",
							children: `${employee?.addressLine1} ${
								employee?.addressLine1 && employee?.addressLine2 ? "," : ""
							} ${employee?.addressLine2}`,
						},
				  ]
				: []),
			{ label: "City", children: employee?.city },
			{ label: "County", children: employee?.county?.name },
			{ label: "Postal Code", children: employee?.postalCode },
			{ label: "Mobile", children: employee?.mobile },
			{ label: "Email", children: employee?.email },
		]
	}, [employee])
	return (
		<Card size="small">
			<Descriptions key={"Employee"} size="small" title={"Details"} column={1} items={mappedEmployee} />
		</Card>
	)
}

export default EmployeeInfo
