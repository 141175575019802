import React, { useEffect } from "react"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { useAppSelector, useAppDispatch } from "hooks"
import { yupResolver } from "@hookform/resolvers/yup"
import { AntdButton, AntdSelect1, DateField, FormInputAntd } from "components"
import {
	getEmployeesSicknessByServiceId,
	saveEmployeeSicknessData,
	selectEmployeeSicknessData,
	selectEmployeeSicknessSaving,
} from "store/Employee/Sickness/sickness.slice"
import { getServices, selectLoadingService, selectServiceList } from "store/Employee/service.slice"

import { EmployeeSicknessInterface } from "@type/employees.types"
import { sicknessInitialValues, sicknessValidationSchema } from "Schema"
import { changeTimeZone, convertIOSDateToHTMLFormat } from "config/utils"
import { GLOBAL_DATE_FORMAT, Permissions } from "config/constants"
import { AllowedTo } from "react-abac"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"
import { selectSelectedEmployee } from "store/globalState.slice"

interface SicknessFormHandle extends EmployeeSicknessInterface {}

const Sickness: React.FC = () => {
	const dispatch = useAppDispatch()
	const services = useAppSelector(selectServiceList())
	const isServiceLoading = useAppSelector(selectLoadingService())

	const sicknessDetail = useAppSelector(selectEmployeeSicknessData())
	const isSicknessSaving = useAppSelector(selectEmployeeSicknessSaving())

	const selected = useAppSelector(selectSelectedEmployee)

	const methods = useForm<SicknessFormHandle>({
		defaultValues: { ...sicknessInitialValues },
		resolver: yupResolver(sicknessValidationSchema),
	})
	const {
		reset,
		handleSubmit,
		setValue,
		watch,
		formState: { errors, isDirty, isSubmitting },
	} = methods
	useEffect(() => {
		if (selected) dispatch(getServices(selected))
	}, [selected])

	useEffect(() => {
		if (services?.length) {
			setValue("employeeService", services[0]._id as string)
			dispatch(getEmployeesSicknessByServiceId(services[0]._id as string))
		}
	}, [services])

	useEffect(() => {
		if (sicknessDetail) {
			reset({ ...sicknessDetail })
		} else reset({ entitledDays: "", sicknessYearStartDate: "" })
	}, [sicknessDetail?._id])

	const onSubmit: SubmitHandler<SicknessFormHandle> = data => {
		dispatch(
			saveEmployeeSicknessData({
				...data,
				sicknessYearStartDate: changeTimeZone(new Date(data.sicknessYearStartDate).toISOString()),
			}),
		)
	}
	return (
		<>
			<div className="flex flex-col">
				<AuditTrail id={sicknessDetail?._id} name={Entities.EMPLOYEE_SICKNESS} />
			</div>
			<FormProvider {...methods}>
				<form className="pb-2.75" onSubmit={handleSubmit(onSubmit)}>
					<div className="flex flex-col p-2">
						<div className="flex w-1/4 flex-col gap-y-2">
							<AntdSelect1
								disabled={!services.length}
								containerClass="w-full"
								className="w-full"
								label="Employee Service"
								loading={isServiceLoading}
								value={watch("employeeService")}
								onChange={val => {
									setValue("employeeService", val)
									dispatch(getEmployeesSicknessByServiceId(val))
								}}
								options={services?.map(({ _id, startDate }) => ({
									label: convertIOSDateToHTMLFormat(startDate, GLOBAL_DATE_FORMAT),
									value: _id as string,
								}))}
							/>
							<DateField
								containerClass="w-full"
								className="w-full"
								label="Sickness Year Start Date"
								setValue={setValue}
								watch={watch}
								errors={errors}
								name={"sicknessYearStartDate"}
							/>
							<FormInputAntd
								containerClass="w-full"
								className="w-full"
								label="Entitled Days"
								name="entitledDays"
								type="number"
								min={0}
							/>
						</div>

						<AllowedTo perform={Permissions.UPDATE_EMPLOYEE_SICKNESS}>
							<AntdButton
								loading={isSicknessSaving}
								disabled={!isDirty || isSubmitting}
								className="mt-5 w-min"
								htmlType="submit"
							>
								{sicknessDetail === null ? "Save" : "Update"}
							</AntdButton>
						</AllowedTo>
					</div>
				</form>
			</FormProvider>
		</>
	)
}
export default Sickness
