import { Drawer } from "antd"
import LdnForm from "./LdnForm"
import { LdnFormProps } from "../HR/QualificationTable"
import Icon from "components/Icon"

interface Props {
	open: boolean
	handleDrawerClose: () => void
	ldnFormProps: LdnFormProps
}

const LdnDrawer: React.FC<Props> = ({ open, handleDrawerClose, ldnFormProps }) => {
	return (
		<Drawer
			title="LDN Details"
			open={open}
			onClose={handleDrawerClose}
			width={"25%"}
			placement="right"
			rootStyle={{
				color: "rgb(229 229 229);",
			}}
			headerStyle={{
				background: "#1f2937",
			}}
			closeIcon={<Icon name="cross" color="white" size={18} />}
		>
			<LdnForm ldnFormProps={ldnFormProps} handleDrawerClose={handleDrawerClose} />
		</Drawer>
	)
}

export default LdnDrawer
