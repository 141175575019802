import { combineReducers } from "@reduxjs/toolkit"
import detailsReducer from "./detail.slice"
import serviceReducer from "./service.slice"
import sicknessReducer from "./Sickness/sickness.slice"
import documentReducer from "./Documents/documents.slice"
import equipmentReducer from "./Equipments/equipments.slice"
import holidayReducer from "./Holidays/holidays.slice"
import termReducer from "./Terms/terms.slice"
import qualificationReducer from "./Qualification/qualification.slice"
import hrReducer from "./HR/hr.slice"
import additionalDetailReducer from "./AdditionalDetail/additionalDetail.slice"
import bankDetailReducer from "./BankDetail/bankDetail.slice"
import sizesReducer from "./equipmentSizes.slice"
import issuedReducer from "./equipmentIssued.slice"
import trainingReducer from "./Location/employeeTraining.slice"
import usualWorkReducer from "./Location/employeeUsualWork.slice"
import workHistoryReducer from "./Location/employeeWorkHistory.slice"
import banReducer from "./Location/employeeBan.slice"
import informationReducer from "./Information.slice"

const EmployeeReducer = combineReducers({
	details: detailsReducer,
	sickness: sicknessReducer,
	service: serviceReducer,
	documents: documentReducer,
	equipment: equipmentReducer,
	holiday: holidayReducer,
	term: termReducer,
	qualification: qualificationReducer,
	hr: hrReducer,
	additionalDetail: additionalDetailReducer,
	bankDetail: bankDetailReducer,
	sizes: sizesReducer,
	issued: issuedReducer,
	training: trainingReducer,
	usualWork: usualWorkReducer,
	workHistory: workHistoryReducer,
	ban: banReducer,
	information: informationReducer,
})

export default EmployeeReducer
