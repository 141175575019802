export enum EMPLOYEE_SOCKET_EVENTS {
	ADDED = "employeesAdded",
	UPDATED = "employeesUpdated",
}

export enum LOCATION_SOCKET_EVENTS {
	ADDED = "locationsAdded",
	UPDATED = "locationsUpdated",
}

export enum EMPLOYEE_QUALIFICATION_SOCKET_EVENTS {
	ADDED = "employeeQualificationAdded",
	UPDATED = "employeeQualificationUpdated",
}

export enum STATIC_DATA_QUALIFICATION_SOCKET_EVENTS {
	ADDED = "staticDataQualificationAdded",
	UPDATED = "staticDataQualificationUpdated",
}

export enum SCHEDULE_SOCKET_EVENTS {
	ADDED = "schedulesAdded",
	UPDATED = "schedulesUpdated",
}

export enum SHIFT_SOCKET_EVENTS {
	ADDED = "shiftsAdded",
	UPDATED = "shiftsUpdated",
}

export enum ROOM_EVENTS {
	JOIN_ROOM = "joinRoom",
	JOINED_ROOM = "joinedRoom",
	LEAVE_ROOM = "leaveRoom",
	LEFT_ROOM = "leftRoom",
}

export enum SOCKET_ROOMS {
	SCHEDULES = "schedules",
	SHIFTS = "shifts",
	LOCATIONS = "locations",
	EMPLOYEE = "employee",
	EMPLOYEE_QUALIFICATION = "employeeQualification",
	STATIC_DATA_QUALIFICATION = "staticDataQualification",
}
