import React, { useMemo, useState } from "react"
import { TableSelectableCells, TableSkeleton } from "../../../components"

interface ColumnHeaderProps {
	label: string
	key: string
}

interface Props {
	data: any
	isLoading: boolean
	cellsToAdd: any
	cellsToRemove: string[]
	setCellsToAdd: (cells: (cells: any) => any) => void
	setCellsToRemove: (callIds: (cells: string[]) => string[]) => void
	rowHeaderKey: string
	columnHeaderKey: string
	rowHeaderLabels: string[]
	columnHeaderLabels: string[]
}

const CallTimeTable: React.FC<Props> = ({
	data,
	isLoading,
	cellsToAdd,
	cellsToRemove,
	setCellsToAdd,
	setCellsToRemove,
	rowHeaderKey,
	columnHeaderKey,
	rowHeaderLabels,
	columnHeaderLabels,
}) => {
	const [selectionStartPoint, setSelectionStartPoint] = useState<any>({})

	const columns: ColumnHeaderProps[] = useMemo(() => {
		if (columnHeaderLabels.length) return columnHeaderLabels?.map((label: string) => ({ label, key: label }))
		return []
	}, [columnHeaderLabels])

	const rows = useMemo(() => {
		const formattedRows = rowHeaderLabels.map(
			(label: string) => ({ _: { label: label.toString(), isRowHeader: true } } as any),
		)

		formattedRows.forEach((row, index: number) => {
			columnHeaderLabels.forEach((column: string) => {
				let id: string | undefined
				let isEmpty: boolean | undefined
				const { label: rowLabel } = row._
				const columnLabel = column
				const cell = data?.find(
					(item: any) => item[rowHeaderKey] === rowLabel && item[columnHeaderKey] === columnLabel,
				)

				if (cell) {
					id = cell?._id
					formattedRows[index][columnLabel] = { label: cell?.label || "No Label", id }
				} else {
					isEmpty = true
					formattedRows[index][columnLabel] = { label: "", isEmpty }
				}

				const { label } = formattedRows[index][columnLabel]

				formattedRows[index][columnLabel] = {
					id,
					label,
					isEmpty,
					isToBeRemoved: id && cellsToRemove?.includes(id),
					isToBeAdded: cellsToAdd?.some(
						(cell: any) => cell[rowHeaderKey] === rowLabel && cell[columnHeaderKey] === columnLabel,
					),

					onMouseDown: () => {
						setSelectionStartPoint({ [rowHeaderKey]: rowLabel, [columnHeaderKey]: columnLabel })
						handleCellClick(id, isEmpty, true, 0, {
							[rowHeaderKey]: rowLabel,
							[columnHeaderKey]: columnLabel,
						})
					},

					onMouseEnter: ({ buttons }: any) => {
						if (buttons === 1) {
							let rowStartIndex: number | undefined,
								rowEndIndex: number | undefined,
								columnStartIndex: number | undefined,
								columnEndIndex: number | undefined
							const items: any = []

							rowHeaderLabels.forEach((_rowLabel, rIndex) => {
								columnHeaderLabels.forEach((_columnLabel, cIndex) => {
									if (
										_rowLabel === selectionStartPoint[rowHeaderKey] &&
										_columnLabel === selectionStartPoint[columnHeaderKey]
									) {
										rowStartIndex = rIndex
										columnStartIndex = cIndex
									}
									if (_rowLabel === rowLabel && _columnLabel === columnLabel) {
										rowEndIndex = rIndex
										columnEndIndex = cIndex
									}
								})
							})

							if ((rowStartIndex as number) > (rowEndIndex as number))
								rowEndIndex = [rowStartIndex, (rowStartIndex = rowEndIndex)][0]
							if ((columnStartIndex as number) > (columnEndIndex as number))
								columnEndIndex = [columnStartIndex, (columnStartIndex = columnEndIndex)][0]

							rowHeaderLabels.slice(rowStartIndex, (rowEndIndex as number) + 1).forEach(_rowLabel => {
								columnHeaderLabels
									.slice(columnStartIndex, (columnEndIndex as number) + 1)
									.forEach(_columLabel => {
										items.push({ [rowHeaderKey]: _rowLabel, [columnHeaderKey]: _columLabel })
									})
							})

							setCellsToRemove(() => [])
							setCellsToAdd(() => [])

							items.forEach((item: any) => {
								const selectedItem = formattedRows.find(
									row =>
										(row[item[columnHeaderKey]].id || row[item[columnHeaderKey]].isEmpty) &&
										item[rowHeaderKey] === row._.label,
								)

								const { id, isEmpty } = selectedItem[item[columnHeaderKey]]

								handleCellClick(id, isEmpty, false, buttons, {
									[rowHeaderKey]: item[rowHeaderKey],
									[columnHeaderKey]: item[columnHeaderKey],
								})
							})
						}
					},
				}
			})
		})
		return formattedRows
	}, [
		isLoading,
		data?.length,
		cellsToAdd?.length,
		cellsToRemove?.length,
		rowHeaderLabels?.length,
		columnHeaderLabels.length,
		selectionStartPoint,
		setSelectionStartPoint,
	])

	const handleCellClick = (
		id: string | undefined,
		isNew: boolean | undefined,
		isSingleClick: boolean,
		isDragging: number,
		newCell: any,
	) => {
		if (isNew) {
			if (
				cellsToAdd?.some(
					(cell: any) =>
						cell[rowHeaderKey] === newCell[rowHeaderKey] &&
						cell[columnHeaderKey] === newCell[columnHeaderKey],
				) &&
				!isDragging &&
				isSingleClick
			) {
				setCellsToAdd((cells: any) =>
					cells.filter(
						(cell: any) =>
							cell[rowHeaderKey] !== newCell[rowHeaderKey] ||
							cell[columnHeaderKey] !== newCell[columnHeaderKey],
					),
				)
			} else {
				setCellsToAdd((cells: any) => [
					...cells,
					{ [rowHeaderKey]: newCell[rowHeaderKey], [columnHeaderKey]: newCell[columnHeaderKey] },
				])
			}
		} else if (id) {
			if (cellsToRemove.includes(id) && !isDragging && isSingleClick) {
				setCellsToRemove((cells: string[]) => cells.filter((item: string) => item !== id))
			} else {
				setCellsToRemove((cells: string[]) => [...cells, id])
			}
		}
	}

	if (isLoading) return <TableSkeleton />
	return <TableSelectableCells columns={columns} rows={rows} onCellClick={handleCellClick} showRowHeaders />
}

export default CallTimeTable
