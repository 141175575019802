import React, { useEffect, useMemo } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { AntdButton, AntdSelect, DateField, FormInputAntd, FormSelectAntd, FormSwitchInput } from "components"
import { yupResolver } from "@hookform/resolvers/yup"
import { BranchesInterface, GetBranchesInterface } from "@type/branches.types"
import { branchInitialValues, branchValidationSchema } from "Schema"
import { saveBranch, selectBranchIsSaving } from "store/Branches/branch.slice"
import { useAppDispatch, useAppSelector } from "hooks"
import { selectPBCodeDropdownList } from "store/PayBill/PBCodes.slice"
import { selectPublicHolidaysDropdownList } from "store/PayBill/publicHolidays.slice"
import { timeZones } from "config/constants"
import { selectSDCountiesDropdownList } from "store/StaticData/SDCounties.slice"
import { selectSDCompaniesDropdownList } from "store/StaticData/SDCompanies.slice"
import { selectCurrentUser } from "store/auth.slice"
import { selectSDQualificationsList } from "store/StaticData/SDQualifications.slice"

interface BranchFormHandle extends BranchesInterface {}

interface Props {
	branch: GetBranchesInterface | undefined
}

const BranchForm: React.FC<Props> = ({ branch }) => {
	const dispatch = useAppDispatch()

	const PBCodes = useAppSelector(selectPBCodeDropdownList)
	const publicHolidays = useAppSelector(selectPublicHolidaysDropdownList)
	const allCounties = useAppSelector(selectSDCountiesDropdownList) || []
	const isSavingBranch = useAppSelector(selectBranchIsSaving())
	const allCompanies = useAppSelector(selectSDCompaniesDropdownList) || []
	const SDQualificationsList = useAppSelector(selectSDQualificationsList)
	const user = useAppSelector(selectCurrentUser())

	const methods = useForm<BranchFormHandle>({
		resolver: yupResolver(branchValidationSchema),
	})
	const {
		reset,
		getValues,
		handleSubmit,
		setValue,
		watch,
		formState: { isSubmitting },
	} = methods

	const SDQualificationsOptions = useMemo(() => {
		return SDQualificationsList?.map(SDQualification => ({
			label: SDQualification.name,
			value: SDQualification._id,
		}))
	}, [SDQualificationsList?.length])

	const onSubmit: SubmitHandler<BranchFormHandle> = data => {
		let organization
		if (user?.roles.filter(role => role.organization).length === 1) {
			organization = user?.roles[0]?.organization ?? ""
		} else {
			organization = data.organization
		}

		if (!data.PBCode) delete data.PBCode
		if (!data.county) delete data.county
		if (!data.publicHolidaySchedule) delete data.publicHolidaySchedule
		dispatch(saveBranch(data, organization))
	}

	useEffect(() => {
		if (branch) {
			reset(branch)
		} else {
			reset({
				...branchInitialValues,
				...(user?.roles.length
					? { organization: user.roles[0]?.organization ?? allCompanies?.[1]?.value }
					: {}),
			})
		}
	}, [branch])

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="space-y-4">
					<div className="grid grid-cols-4 gap-y-2 gap-x-3">
						{user?.roles.some(role => role.isSuperAdmin) || user?.isSuperAdmin ? (
							<FormSelectAntd
								disabled={!!branch}
								value={branch ? `${branch?.organization}` : watch("organization")}
								label="Company"
								name="organization"
								options={allCompanies ?? []}
							/>
						) : (
							<></>
						)}
						<FormInputAntd label="Short name" name="shortName" type="text" />
						<FormInputAntd label="Name" name="name" type="text" />
						<FormInputAntd label="Address Line 1" name="addressLine1" type="text" />
						<FormInputAntd label="Address Line 2" name="addressLine2" type="text" />
						<FormInputAntd label="City" name="city" type="text" />
						<FormSelectAntd
							disabled={!allCounties?.length}
							label="County"
							name="county"
							options={allCounties}
						/>
						<FormInputAntd label="Postal Code" name="postalCode" type="text" />
						<FormInputAntd label="Telephone" name="telephone" type="text" />
						<FormInputAntd label="Fax" name="fax" type="text" />
						<FormSelectAntd disabled={!PBCodes?.length} label="PB Code" name="PBCode" options={PBCodes} />
						<FormSelectAntd
							disabled={!publicHolidays?.length}
							label="Default Public Holiday Schedule"
							name="publicHolidaySchedule"
							options={publicHolidays}
						/>
						<FormSelectAntd
							disabled={!timeZones?.length}
							label="Time Zone"
							name="timeZone"
							options={timeZones}
						/>
						<FormInputAntd label="Culture" name="culture" type="text" />
						<DateField label="Branch Lock Date" setValue={setValue} watch={watch} name="lockDate" />
						<AntdSelect
							size="middle"
							disabled={!SDQualificationsOptions?.length}
							label="Required Qualifications"
							name="requiredQualifications"
							mode={"multiple"}
							formValueSetter={setValue}
							selected={watch("requiredQualifications") || []}
							isControlled={true}
							options={SDQualificationsOptions}
						/>
						<div className="mt-5 flex items-center">
							<FormSwitchInput label="Enabled" name="enabled" watch={watch} setValue={setValue} />
						</div>
					</div>

					<div className="flex space-x-2">
						<AntdButton
							onClick={e => {
								e.preventDefault()
								reset({ ...branchInitialValues, _id: getValues("_id") })
							}}
						>
							Clear
						</AntdButton>
						<AntdButton disabled={isSubmitting} loading={isSavingBranch} htmlType="submit">
							{branch ? "Update" : "Save"}
						</AntdButton>
					</div>
				</div>
			</form>
		</FormProvider>
	)
}

export default BranchForm
