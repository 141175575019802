import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
	GetSDQualificationInterface,
	SDQualificationInterface,
	UpdateSDQualificationInterface,
} from "@type/staticData.types"
import { SDQualificationsService } from "services"
import { AppThunk, RootState } from "store"
import { sortByProperty, sortDeepCopyByProperty, updateState } from "config/utils"
import { TABLE_SIA_LICENSE_SECTORS } from "config/constants"
export interface SDQualificationsStateInterface {
	list: null | Array<GetSDQualificationInterface>
	loading: boolean
	saving: boolean
}

const initialState: SDQualificationsStateInterface = {
	list: null,
	loading: false,
	saving: false,
}

const SDQualificationsSlice = createSlice({
	name: "SDQualifications",
	initialState,
	reducers: {
		fetchingSDQualifications: SDQualifications => {
			SDQualifications.loading = true
		},
		SDQualificationsFetched: (SDQualifications, action: PayloadAction<GetSDQualificationInterface[]>) => {
			SDQualifications.loading = false
			SDQualifications.list = action.payload
		},
		SDQualificationsFetchingFailed: SDQualifications => {
			SDQualifications.loading = false
		},
		savingSDQualifications: SDQualificationsData => {
			SDQualificationsData.saving = true
		},
		SDQualificationsSaved: (SDQualifications, action: PayloadAction<GetSDQualificationInterface>) => {
			SDQualifications.saving = false
			const [UpdatedValue] = updateState(SDQualifications.list, action.payload, "_id")
			SDQualifications.list = UpdatedValue
		},
		SDQualificationsSavingFailed: SDQualifications => {
			SDQualifications.saving = false
		},
	},
})

//REDUCER
export default SDQualificationsSlice.reducer

//ACTIONS
export const {
	fetchingSDQualifications,
	SDQualificationsFetched,
	SDQualificationsFetchingFailed,
	savingSDQualifications,
	SDQualificationsSaved,
	SDQualificationsSavingFailed,
} = SDQualificationsSlice.actions

const getSDQualifications =
	(cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingSDQualifications())
			const { data: SDQualificationsResponse } = await SDQualificationsService.getAllSDQualifications()
			SDQualificationsResponse.sort(sortByProperty("name"))
			cb && cb(SDQualificationsResponse[0]?._id)
			dispatch(SDQualificationsFetched(SDQualificationsResponse))
		} catch (error) {
			dispatch(SDQualificationsFetchingFailed())
		}
	}

const saveSDQualification =
	(SDQualificationsData: Partial<UpdateSDQualificationInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingSDQualifications())
			if (SDQualificationsData?._id)
				data = await SDQualificationsService.updateSDQualification(
					SDQualificationsData._id,
					SDQualificationsData,
				)
			else
				data = await SDQualificationsService.createSDQualification(
					SDQualificationsData as SDQualificationInterface,
				)
			const { data: SDQualificationsResponse } = data
			cb && cb(SDQualificationsResponse._id)
			dispatch(SDQualificationsSaved(SDQualificationsResponse))
		} catch (error) {
			dispatch(SDQualificationsSavingFailed())
		}
	}

export { getSDQualifications, saveSDQualification }

//SELECTORS
const selectSDQualificationsState = (state: RootState) => state.staticData.SDQualifications
const isSDQualificationsLoading = () => (state: RootState) => selectSDQualificationsState(state).loading
const isSDQualificationsSaving = () => (state: RootState) => selectSDQualificationsState(state).saving
const selectSDQualificationsList = createSelector(
	(state: RootState) => state.staticData.SDQualifications.list,
	list => {
		return sortDeepCopyByProperty(list, "name") as GetSDQualificationInterface[]
	},
)

const selectFilteredSDQualificationsList = (state: RootState) =>
	state?.staticData?.SDQualifications?.list?.filter(
		item =>
			item.fetchedSiaDetails ||
			TABLE_SIA_LICENSE_SECTORS.some(licenseTitle =>
				licenseTitle.toLowerCase().localeCompare(item.name.toLowerCase()),
			),
	)
// state?.staticData?.SDQualifications?.list?.filter(item => TABLE_SIA_LICENSE_SECTORS.includes(item.name))

const selectSDQualificationsDropdownList = createSelector(
	(state: RootState) => state.staticData.SDQualifications.list,
	list => {
		return sortDeepCopyByProperty(list?.map(item => ({ label: item.name, value: item._id })) || [], "label")
	},
)
const selectSDQualificationsIdToLabel = createSelector(
	(state: RootState) => state.staticData.SDQualifications.list,
	list => {
		if (list) {
			const idToLabel: { [key: string]: string } = {}
			list?.forEach(item => (idToLabel[item._id] = item.name))
			return idToLabel
		}
		return {}
	},
)

const selectSDQualificationsById = (SDQualificationsId: string | null) => (state: RootState) =>
	state.staticData.SDQualifications?.list?.find(SDQualifications => SDQualifications._id === SDQualificationsId)

export {
	selectSDQualificationsList,
	selectSDQualificationsDropdownList,
	selectSDQualificationsState,
	selectSDQualificationsById,
	isSDQualificationsLoading,
	isSDQualificationsSaving,
	selectSDQualificationsIdToLabel,
	selectFilteredSDQualificationsList,
}
