import { updateState } from "config/utils"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EmployeeEquipmentIssuedInterface, GetEmployeeEquipmentIssuedInterface } from "@type/employees.types"
import { employeeEquipmentIssuedService } from "services"
import { AppThunk, RootState } from "store"

export interface IssuedStateInterface {
	data: GetEmployeeEquipmentIssuedInterface[] | null
	loading: boolean
	saving: boolean
	savingFailed: boolean
}

const initialState: IssuedStateInterface = {
	data: null,
	loading: false,
	saving: false,
	savingFailed: false,
}

const IssuedSlice = createSlice({
	name: "Issued",
	initialState,
	reducers: {
		requestIssuedData: state => {
			state.loading = true
		},
		receiveIssuedData: (state, action: PayloadAction<GetEmployeeEquipmentIssuedInterface[]>) => {
			state.loading = false
			state.data = action.payload
		},
		receiveIssuedDataFailed: state => {
			state.loading = false
		},
		requestSavingIssuedData: state => {
			state.saving = true
			state.savingFailed = false
		},
		SavingEmployeeIssuedSuccess: (state, action: PayloadAction<GetEmployeeEquipmentIssuedInterface>) => {
			state.saving = false
			state.savingFailed = false
			const [UpdatedValue] = updateState(state.data, action.payload, "_id")
			state.data = UpdatedValue
		},
		SavingEmployeeIssuedSuccessFail: state => {
			state.saving = false
			state.savingFailed = true
		},
	},
})

//REDUCER
export default IssuedSlice.reducer

//ACTIONS
const {
	requestIssuedData,
	receiveIssuedData,
	receiveIssuedDataFailed,
	requestSavingIssuedData,
	SavingEmployeeIssuedSuccess,
	SavingEmployeeIssuedSuccessFail,
} = IssuedSlice.actions

const getEmployeesIssuedByEmployeeId =
	(employeeId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestIssuedData())
			const { data: equipmentResponse } = await employeeEquipmentIssuedService.getEquipmentIssuedByEmployeeId(
				employeeId,
			)
			dispatch(receiveIssuedData(equipmentResponse))
		} catch (error) {
			dispatch(receiveIssuedDataFailed())
		}
	}

const saveEmployeeIssuedData =
	(IssuedData: EmployeeEquipmentIssuedInterface, cb?: () => void): AppThunk =>
	async dispatch => {
		try {
			let data = null
			dispatch(requestSavingIssuedData())
			if (IssuedData._id) {
				data = await employeeEquipmentIssuedService.updateEquipmentIssued(IssuedData._id, IssuedData)
			} else {
				data = await employeeEquipmentIssuedService.createEquipmentIssued(IssuedData)
			}
			const { data: equipmentResponse } = data
			dispatch(SavingEmployeeIssuedSuccess(equipmentResponse))
			cb?.()
		} catch (error) {
			dispatch(SavingEmployeeIssuedSuccessFail())
		}
	}

export { saveEmployeeIssuedData, getEmployeesIssuedByEmployeeId }

//SELECTORS
const selectEmployeeIssuedState = (state: RootState) => state.employee.issued
const selectEmployeeIssuedLoading = () => (state: RootState) => selectEmployeeIssuedState(state).loading
const selectEmployeeIssuedData = () => (state: RootState) => selectEmployeeIssuedState(state).data
const selectEmployeeIssuedSaving = () => (state: RootState) => selectEmployeeIssuedState(state).saving
const selectEmployeeIssuedSavingFailed = () => (state: RootState) => selectEmployeeIssuedState(state).savingFailed

export {
	selectEmployeeIssuedData,
	selectEmployeeIssuedSaving,
	selectEmployeeIssuedLoading,
	selectEmployeeIssuedSavingFailed,
}
