import React, { useEffect, useMemo, useRef, useState } from "react"
import { GlobalSidebar, SidebarList, AntdButton, FormInputAntd, FormCheckbox } from "components"
import { yupResolver } from "@hookform/resolvers/yup"
import { SDEquipmentTypeInterface } from "@type/staticData.types"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { SDEquipmentTypeInitialValues, SDEquipmentTypeSchema } from "Schema/StaticData/SDEquipmentType"
import {
	getSDEquipmentType,
	isSDEquipmentTypeLoading,
	isSDEquipmentTypeSaving,
	saveSDEquipmentType,
	selectSDEquipmentTypeById,
	selectSDEquipmentTypeList,
} from "store/StaticData/SDEquipmentTypes.slice"
import { useAppDispatch, useAppSelector } from "hooks"

interface EquipmentTypeFormHandler extends SDEquipmentTypeInterface {}

const EquipmentTypes = () => {
	const [selectedSDEquipmentType, setSelectedSDEquipmentType] = useState<string | null>("")
	const dispatch = useAppDispatch()
	const saving = useAppSelector(isSDEquipmentTypeSaving())
	const loading = useAppSelector(isSDEquipmentTypeLoading())
	const SDEquipmentTypesList = useAppSelector(selectSDEquipmentTypeList)
	const SDEquipmentType = useAppSelector(selectSDEquipmentTypeById(selectedSDEquipmentType))
	const isNewBeingCreated = useRef(false)
	const methods = useForm<EquipmentTypeFormHandler>({ resolver: yupResolver(SDEquipmentTypeSchema) })
	const {
		handleSubmit,
		reset,
		formState: { isSubmitted, isDirty, isSubmitting },
	} = methods

	const sidebarSDEquipmentType = useMemo(() => {
		return SDEquipmentTypesList?.map(SDEquipmentType => ({
			_id: SDEquipmentType._id,
			name: SDEquipmentType.description,
		}))
	}, [saving, SDEquipmentTypesList?.length, isSubmitted])

	const onSubmit: SubmitHandler<EquipmentTypeFormHandler> = data => {
		isNewBeingCreated.current = true
		dispatch(
			saveSDEquipmentType(data, id => {
				setSelectedSDEquipmentType(id)
				isNewBeingCreated.current = false
			}),
		)
	}

	useEffect(() => {
		dispatch(getSDEquipmentType(setSelectedSDEquipmentType))
	}, [])

	useEffect(() => {
		if (selectedSDEquipmentType !== (null && undefined)) {
			reset({ ...SDEquipmentTypeInitialValues, ...SDEquipmentType })
		} else if (selectedSDEquipmentType === null && !isNewBeingCreated.current) {
			reset({ ...SDEquipmentTypeInitialValues })
		}
	}, [selectedSDEquipmentType])

	const handleSelected = (SDEquipmentTypeId: string | null): void => setSelectedSDEquipmentType(SDEquipmentTypeId)

	return (
		<>
			<GlobalSidebar>
				<SidebarList
					title={"Equipment Type"}
					loading={loading}
					selected={selectedSDEquipmentType}
					setSelected={handleSelected}
					list={sidebarSDEquipmentType}
				/>
			</GlobalSidebar>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="flex w-1/4 flex-col gap-2">
						<FormInputAntd type="text" label="Description" name="description" />
						<FormCheckbox label="Allocate to Employee" name="allocatedToEmployee" />
						<FormCheckbox label="Allocate to Location" name="allocatedToLocation" />
						<div className="flex gap-2">
							<AntdButton onClick={() => setSelectedSDEquipmentType(null)}>New</AntdButton>
							<AntdButton disabled={!isDirty || isSubmitting} loading={saving} htmlType="submit">
								{selectedSDEquipmentType ? "Update" : "Save"}
							</AntdButton>
						</div>
					</div>
				</form>
			</FormProvider>
		</>
	)
}

export default EquipmentTypes
