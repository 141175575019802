import axios from "axios"
import { SDEventTypes } from "@type/staticData.types"

const ROOT_PATH = "static-data/event-type"
export default class SDEventTypesService {
	static createSDEventTypes = (eventType: SDEventTypes) => axios.post<SDEventTypes>(ROOT_PATH, eventType)

	static getAllSDEventsType = () => axios.get<SDEventTypes[]>(`${ROOT_PATH}`)

	static getSDEventTypesById = (id: string) => axios.get<SDEventTypes>(`${ROOT_PATH}/${id}`)

	static updateSDEventTypes = (id: string, eventType: Partial<SDEventTypes>) =>
		axios.put<SDEventTypes>(`${ROOT_PATH}/${id}`, eventType)
}
