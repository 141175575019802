import { getRememberMe, logout, selectCurrentUser, selectSignedIn } from "store/auth.slice"
import { useAppDispatch, useAppSelector } from "hooks"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { toast } from "react-toastify"
import env from "config/env"
import { authService } from "services/index"
import moment from "moment"
import { useLocation } from "react-router-dom"
import { isWorkMonitor } from "config/utils"

const createLogoutToast = () =>
	toast.info("Session expired due to inactivity.", {
		position: toast.POSITION.TOP_CENTER,
		autoClose: false,
	})

const InactivityTimer = () => {
	const currentUser = useAppSelector(selectCurrentUser())
	const dispatch = useAppDispatch()
	const signedIn = useAppSelector(selectSignedIn())
	const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

	const resetTimer = useCallback(() => {
		clearTimeout(timeoutRef.current)

		let inactivityTimer = env.inactivityTimeoutMinutes

		if (currentUser) {
			if (currentUser?.inactivityTimer)
				inactivityTimer =
					currentUser.inactivityTimer === 0 ? env.inactivityTimeoutMinutes : currentUser.inactivityTimer
		}

		if (signedIn) {
			timeoutRef.current = setTimeout(() => {
				dispatch(logout())
				createLogoutToast()
			}, inactivityTimer * 60 * 1000)
		}
	}, [signedIn])

	const handleUserActivity = useCallback(() => {
		resetTimer()
	}, [resetTimer])

	useEffect(() => {
		const previousTime = authService.getUserTimeout()
		if (previousTime.length)
			if (moment().diff(moment(previousTime), "milliseconds") > 60 * 1000) {
				dispatch(logout())
			} else {
				authService.setUserTimeout("")
			}
	}, [])

	const handleReloadTimeout = useCallback(() => {
		authService.setUserTimeout(moment().toString())
	}, [])

	useEffect(() => {
		resetTimer()
		document.addEventListener("mousemove", handleUserActivity)
		document.addEventListener("keydown", handleUserActivity)
		window.addEventListener("beforeunload", handleReloadTimeout)
		return () => {
			clearTimeout(timeoutRef.current)
			document.removeEventListener("mousemove", handleUserActivity)
			document.removeEventListener("keydown", handleUserActivity)
			window.removeEventListener("beforeunload", handleReloadTimeout)
		}
	}, [signedIn, resetTimer, handleUserActivity])

	return null
}
const InactivityTimerWrapper = () => {
	const { pathname } = useLocation()
	const isCommandCenterRoute = useMemo(() => isWorkMonitor(pathname), [pathname])

	const signedIn = useAppSelector(selectSignedIn())
	const isRememberMe = useMemo(() => getRememberMe(), [signedIn])
	return <>{!isRememberMe && !isCommandCenterRoute ? <InactivityTimer /> : null}</>
}

export default InactivityTimerWrapper
