import axios from "axios"
import { GetRoleInterface, RoleInterface } from "@type/security.types"

const ROOT_PATH = "roles"
export default class RoleService {
	static createRole = (notifiee: RoleInterface) => axios.post<GetRoleInterface>(ROOT_PATH, notifiee)

	static getAllRoles = () => axios.get<Array<GetRoleInterface>>(`${ROOT_PATH}`)

	static getRoleById = (id: string) => axios.get<GetRoleInterface>(`${ROOT_PATH}/${id}`)

	static updateRole = (id: string, notifiee: Partial<GetRoleInterface>) =>
		axios.put<GetRoleInterface>(`${ROOT_PATH}/${id}`, notifiee)

	static getUserRoleByOrganization = () => axios.get(`${ROOT_PATH}/allowed-resources`)

	static updateUserRoles = (payload: { id: string; roles: GetRoleInterface[] }) =>
		axios.put<GetRoleInterface>(`${ROOT_PATH}/user-roles`, payload)
}
