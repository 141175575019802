import { useEffect, useMemo, Fragment } from "react"
import { PreviousShiftEvent, ShiftInterface } from "types/workMonitor.types"
import { ShiftStatus, platformTypeENUM } from "config/constants"
// import IncidentView from "pages/schedules/Location/IncidentInfo"
import { useAppDispatch } from "hooks"
import { getUsers } from "store/Security/users.slice"
import ShiftEventCard from "./ShiftEventCard"
import QRCard from "./QRCard"
import cx from "classnames"
import moment from "moment"

interface Props {
	shift: ShiftInterface | undefined
	containerClass?: string
}

const getShiftStatusColor = (events: PreviousShiftEvent): string => {
	return cx({
		"bg-[#01A66F]": events["status"] === ShiftStatus.BOOKON,
		"bg-[red]":
			(moment(events["dueTime"]).isValid()
				? moment(events["dueTime"]) < moment(events["eventTime"])
				: events["dueTime"] < moment(events["eventTime"]).format("HH:mm")) &&
			events["status"] === ShiftStatus.BOOKON,

		"bg-[#312e81]": events["status"] === ShiftStatus.CHECK,
		"bg-[#8E4306]": events["status"] === ShiftStatus.INVENTORY,
		"bg-[#3D5A80]": events["status"] === ShiftStatus.QR,
		"bg-[#D62828]": events["status"] === ShiftStatus.BOOKOFF,
	})
}

const WorkMonitorView: React.FC<Props> = ({ shift, containerClass }) => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(getUsers(platformTypeENUM.MOBILE))
	}, [])

	const filteredShiftEvents = useMemo(() => {
		return (
			shift?.shiftEvents
				?.filter(events => events.status !== ShiftStatus.CHASED)
				.sort((a, b) => new Date(a.dueTime).valueOf() - new Date(b.dueTime).valueOf()) || []
		)
	}, [shift])

	return (
		<div className={containerClass}>
			{!!filteredShiftEvents?.length && (
				<>
					<span className="w-full py-6 text-center text-lg font-semibold tracking-wide">Shift Events</span>
					{filteredShiftEvents.map(events => {
						const shiftStatusColor = getShiftStatusColor(events)
						return (
							<Fragment key={events?._id}>
								{events.status !== ShiftStatus.QR && (
									<ShiftEventCard
										key={events._id}
										events={events}
										containerClass={containerClass}
										shiftStatusColor={shiftStatusColor}
									/>
								)}
								{events.status === ShiftStatus.QR && (
									<QRCard events={events} key={events._id} shiftStatusColor={shiftStatusColor} />
								)}
							</Fragment>
						)
					})}
				</>
			)}
		</div>
	)
}

export default WorkMonitorView
