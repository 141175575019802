import React, { useEffect, useState } from "react"
import cx from "classnames"
import { ScheduleStatus } from "../Constants/schedulesConstants"
import { convertIOSDateToHTMLFormatTime, getMomentZInstanceOfDate } from "config/utils"
import moment from "moment"
import { DATE_FORMATS } from "config/constants"
import { ColorName, IconName } from "components/types"
import Icon from "components/Icon"
import { twMerge } from "tailwind-merge"
import ScheduleTableCell from "./ScheduleTableCell"
import { useHeightResize } from "hooks"

interface RenderProps {
	row: any
	key: string
}

interface Column {
	label: string
	key: string
	hours: number
	shifts: number
	render?: (data: RenderProps) => React.ReactNode
	className?: string
}

interface Props {
	rowHeaders: any[]
	rows: { [key: string | number]: any }[]
	columns: Column[]
	showRowHeaders: boolean
	noDataText?: string
	selectedCells: { [key: string | number]: any }
}

const iconProps = {
	SECURITY: { name: "security" as IconName, color: "info" as ColorName },
	CLEANING: { name: "cleaning" as IconName, color: "tangerine" as ColorName },
}
type postType = "SECURITY" | "CLEANING"

const ScheduleTable: React.FC<Props> = ({
	rowHeaders = [],
	rows = [],
	columns = [],
	showRowHeaders = true,
	noDataText = "",
	selectedCells = {},
}) => {
	useHeightResize({ element: "schedule-table" })
	const [cellWidth, setCellWidth] = useState(0)
	useEffect(() => {
		const handleResize = () => {
			if (document) {
				const childElement = document.getElementById("schedule-table-container")
				const parentElement = document.getElementById("schedule-info-container")

				const difference =
					(parentElement?.getBoundingClientRect()?.left as number) -
					(childElement?.getBoundingClientRect()?.left as number)

				if (childElement) setCellWidth(Math.floor(difference / 8))
			}
		}
		handleResize()
		window.addEventListener("resize", handleResize)
	}, [])

	const createSelectedCellKey = (rowKeyValue: any, colKeyValue: any, rowIndex: any, colIndex: any) =>
		`${rowKeyValue}_${colKeyValue}_${rowIndex}_${colIndex}`

	return (
		<div id="schedule-table" className="table-fix-head schedule-scrollbar">
			<table className="schedule-table table-auto text-left">
				<thead>
					<tr>
						{[
							...(showRowHeaders ? [{ label: "", key: "_", hours: 0, shifts: 0 }, ...columns] : columns),
						].map(({ label, hours = 0, shifts = 0 }, colIndex) => {
							const formattedCurrentDate = getMomentZInstanceOfDate().format(DATE_FORMATS.DD_MM_YYYY)
							const thClassName = cx({
								[`select-none border-b-[1px] border-l-white border-l-[3px] border-b-primary1 bg-primary-bg h-20  text-sm font-normal !z-9999 `]:
									true,
								"bg-[#e3e6ff]": formattedCurrentDate === label,
							})

							return (
								<th
									key={colIndex}
									className={thClassName}
									style={{
										width: cellWidth + "px",
									}}
								>
									{label.length ? (
										<div className="text-center ">
											<div>{label}</div>
											<div>
												{moment(label, DATE_FORMATS.DD_MM_YYYY).format(DATE_FORMATS.dddd)}
											</div>
											<div className="flex justify-center space-x-3">
												<div className="space-x-2">
													<span>S</span>
													<span className="text-md font-medium text-primary1 ">{shifts}</span>
												</div>
												<div className="space-x-2">
													<span>H</span>
													<span className="text-md font-medium text-primary1">{hours}</span>
												</div>
											</div>
										</div>
									) : (
										<div />
									)}
								</th>
							)
						})}
					</tr>
				</thead>
				<tbody>
					{rows ? (
						rowHeaders.map(({ post: key }) => {
							const value = rows[key]
							let headerDisplayed = 0
							const totalRowsInOnePost = Object.keys(value).length

							return Object.entries(value).map(([rowKey, rowValue]: [any, any], rowIndex) => {
								let rowHeaderValue = {} as any
								if (headerDisplayed !== 1) {
									rowHeaderValue = rowHeaders.find(
										({ post: rowLabelKey }) => key == rowLabelKey,
									) as any
									headerDisplayed++
								}
								return (
									<tr
										key={`${key}-${rowIndex}`}
										className="last:schedule-table-last-row last:border-b-0.125 last:border-b-primary1"
									>
										{!!Object.entries(rowHeaderValue).length && (
											<th
												className={`select-none border border-r-[3px] border-x-white border-b-primary1 bg-primary-bg text-center text-md  font-medium text-primary1 `}
												rowSpan={totalRowsInOnePost}
											>
												<div
													style={{
														width: cellWidth + "px",
													}}
												>
													{rowHeaderValue?.type && (
														<div className="mb-1 flex w-full items-center justify-center">
															<Icon
																{...iconProps[rowHeaderValue?.type as postType]}
																size={16}
															/>
														</div>
													)}
													<div>{rowHeaderValue.label}</div>
												</div>
											</th>
										)}
										{rowValue.map((row: any, colIndex: any) => {
											let isToBeRemoved = false
											let isToBeAdded = false
											const {
												disable = false,
												isRowHeader = false,
												onMouseDown,
												onMouseDrag,
											} = row

											const selectedCellsCopy = { ...selectedCells }
											const selectedKey = createSelectedCellKey(
												row["post"],
												row["date"],
												rowKey,
												colIndex,
											)
											const selectedValue = selectedCellsCopy[selectedKey]

											if (selectedValue === null) isToBeAdded = true
											else if (selectedValue && Object.keys(selectedValue).length)
												isToBeRemoved = true

											const breakerRows = Object.values(rows)
												.map(item => {
													const keys = Object.keys(item)
													return keys[keys.length - 1]
												})
												.slice(0, -1)

											return (
												<td
													width={`${cellWidth}px`}
													key={`${rowKey}-${colIndex}`}
													className={twMerge(
														cx(
															"h-[70px] cursor-pointer border-r-[3px] border-b border-r-white border-b-primary1 bg-white p-[3px]",
															{
																"align-middle font-semibold": isRowHeader,
																"pointer-events-none select-none bg-[#F5F5F5]": disable,
																"border-b border-b-white":
																	!breakerRows.includes(rowKey),
																"bg-secondary": isToBeRemoved,
																"bg-secondary ": isToBeAdded,
															},
														),
													)}
													onMouseDown={e =>
														onMouseDown(e, Number(rowKey), Number(colIndex), row)
													}
													onMouseEnter={({ buttons }) =>
														onMouseDrag(buttons, Number(rowKey), Number(colIndex), row)
													}
												>
													{row.name && (
														<ScheduleTableCell
															name={row.name}
															employeeType={
																row?.populatedEmployee?.type || row.employeeType
															}
															isDeleted={row.isDeleted}
															status={row.status as ScheduleStatus}
															time={`${convertIOSDateToHTMLFormatTime(
																row.startTime,
															)} - ${convertIOSDateToHTMLFormatTime(row.finishTime)}`}
															scheduleType={row.type}
														/>
													)}
												</td>
											)
										})}
									</tr>
								)
							})
						})
					) : (
						<tr>
							<th colSpan={12} className="p-3 text-center text-dark-alt">
								{noDataText ? noDataText : "No Data"}
							</th>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}

export default ScheduleTable
