import { ColorName } from "components/types"

export const colors: { [key in ColorName]: string } = {
	white: "#FFFFFF",
	black: "#000000",
	background: "#F1F3F4",
	"secondary-light": "#C9C9C9",
	"secondary-dark": "#A7A7A7",
	purple: "#7D7FF0",
	danger: "#C61919",
	dark: "#4D4D4D",
	"dark-alt": "#737373",
	"sky-blue": "#00D1FF",
	green: "#6E9107",
	warning: "#F7B924",
	"danger-light": "#D92550",
	success: "#3AC47D",
	tangerine: "#EB6440",
	info: "#007bff",
	"t-blue": "#37A6C9",
	primary: "#008989bf",
}
