import {
	deleteShift,
	updateShifts,
	getWorkMonitor,
	workMonitorSaved,
	updateShiftColors,
	getNextIntervalData,
	selectWorkMonitorSaving,
	selectWorkMonitorLoading,
	selectWorkMonitorDataStructureChanged,
} from "store/workMonitor/workMonitor.slice"
import { theme } from "config/index"
import { Drawer } from "antd"
import { socket } from "config/socket"
import ReactHowler from "react-howler"
import { toUpper } from "lodash"
import IncidentView from "pages/schedules/Location/IncidentInfo"
import IncidentModal from "./IncidentModal"
import annoyingSound from "../../audio/alarm.mp3"
import WorkMonitorTabs from "./WorkMonitorTabs"
import WorkMonitorView from "./WorkMonitorView"
import ShiftStatusModal from "./ShiftStatusModal"
import FilterShiftDrawer from "./FilterShiftDrawer"
import AllocateShiftModal from "./AllocateShiftModal"
import WorkMonitorActions from "./WorkMonitorActions"
import { getSDIncidents } from "store/StaticData/SDIncidents.slice"
import { GeoFenceTimeline } from "./GeoFenceTimeline"
import { Divider, Segmented } from "antd-v5"
import { executeAtEvenInterval } from "config/utils"
import { useAppDispatch, useAppSelector } from "hooks"
import React, { useEffect, useMemo, useState } from "react"
import { deletingSchedule, selectScheduleDelete } from "store/Schedules/schedules.slice"
import { setSelectedBranch, setSelectedLocation } from "store/globalState.slice"
import { ConfirmationModal, GlobalSearchModal, Icon, WhatsAppButton } from "components"
import { ROOM_EVENTS, SOCKET_ROOMS, SHIFT_SOCKET_EVENTS } from "types/webSocket.types"
import { PopulatedShiftInterface, ShiftInterface, UpdateWorkMonitor } from "@type/workMonitor.types"
import { platformTypeENUM, WORK_MONITOR_UPDATE_TIME, ShiftStatus, WORK_MONITOR_REFRESH_TIME } from "config/constants"
import ResolveIncidentModal from "./ResolveIncidentModal"

const WorkMonitor: React.FC = () => {
	const dispatch = useAppDispatch()
	const [searchString, setSearchString] = useState<string>("")
	const [selectedRow, setSelectedRow] = useState<ShiftInterface[] | null>(null)
	const [openAllocateModal, setOpenAllocateModal] = useState<boolean>(false)
	const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
	const [openIncidentModal, setOpenIncidentModal] = useState<boolean>(false)
	const [openShiftDetailsDrawer, setOpenShiftDetailsDrawer] = useState<boolean>(false)
	const [openShiftFilterDrawer, setOpenShiftFilterDrawer] = useState<boolean>(false)
	const [openResolveIncidentModal, setOpenResolveIncidentModal] = useState<boolean>(false)
	const [searchModal, setSearchModal] = useState<boolean>(false)
	const [shiftStatusChangeModalOpen, setShiftStatusChangeModalOpen] = useState<boolean>(false)
	const [shiftData, setShiftData] = useState<string>("")
	const [multipleEventsData, setMultipleEventsData] = useState<any>([])
	const isScheduleDeleting = useAppSelector(selectScheduleDelete())
	const shifts = useAppSelector(selectWorkMonitorDataStructureChanged)
	const workMonitorLoading = useAppSelector(selectWorkMonitorLoading())
	const statusUpdating = useAppSelector(selectWorkMonitorSaving())
	const [value, setValue] = useState<string>("Shift Events")

	useEffect(() => {
		dispatch(getWorkMonitor())

		socket.emit(ROOM_EVENTS.JOIN_ROOM, SOCKET_ROOMS.SHIFTS)

		socket.on(SHIFT_SOCKET_EVENTS.ADDED, (res: any) => dispatch(workMonitorSaved(res)))
		socket.on(SHIFT_SOCKET_EVENTS.UPDATED, res => dispatch(workMonitorSaved(res)))

		const refreshIntervalID = setInterval(() => {
			executeAtEvenInterval(() => dispatch(updateShiftColors()), WORK_MONITOR_REFRESH_TIME)
		}, WORK_MONITOR_REFRESH_TIME)

		const updateIntervalID = setInterval(() => {
			executeAtEvenInterval(() => dispatch(getNextIntervalData()), WORK_MONITOR_UPDATE_TIME)
		}, WORK_MONITOR_UPDATE_TIME)

		return () => {
			clearInterval(refreshIntervalID)
			clearInterval(updateIntervalID)
			socket.emit(ROOM_EVENTS.LEAVE_ROOM, SOCKET_ROOMS.SHIFTS)
		}
	}, [])

	useEffect(() => {
		if (selectedRow?.[0]?.locationId) {
			dispatch(setSelectedBranch(selectedRow?.[0]?.locationBranch))
			dispatch(setSelectedLocation(selectedRow?.[0]?.locationId))
		}
	}, [selectedRow])

	useEffect(() => {
		dispatch(getSDIncidents())
	}, [])

	const play = useMemo(() => {
		return (
			shifts.allocatedShift.some(item => item.isLate) ||
			shifts.activeShift.some(item => item.isLate) ||
			shifts.cleaning.some(item => item.isLate) ||
			shifts.criticalShift.some(item => item.isLate)
		)
	}, [shifts])

	const onConfirmationHandler = (data: UpdateWorkMonitor) => {
		const [selectedEvent] = multipleEventsData
		if (shiftData)
			dispatch(
				updateShifts(
					shiftData,
					{ ...data, eventTime: selectedEvent.eventTime, eventType: selectedEvent.eventType },
					() => resetShiftState(),
				),
			)
	}

	const resetShiftState = () => {
		setShiftStatusChangeModalOpen(false)
		setSelectedRow(null)
		setShiftData("")
		setMultipleEventsData([])
	}

	const rowClickHandler = (data: PopulatedShiftInterface, event: any) => {
		if (data.nextEvents[data.action as ShiftStatus]) {
			if (event.ctrlKey) {
				setMultipleEventsData((prev: any[]) => {
					let dataToUpdate = []
					if (prev.some(item => item.id === data._id)) {
						dataToUpdate = prev.filter(item => item.id !== data._id)
					} else {
						dataToUpdate = [
							...(prev?.length ? prev : []),
							{
								eventTime: data.nextEvents[data.action as ShiftStatus]?.[0].eventTime,
								eventType: toUpper(data.action),
								id: data._id,
								source: platformTypeENUM.WEB,
							},
						]
					}
					return dataToUpdate
				})
			} else {
				setMultipleEventsData([
					{
						eventTime: data.nextEvents[data.action as ShiftStatus]?.[0].eventTime,
						eventType: toUpper(data.action),
						id: data._id,
						source: platformTypeENUM.WEB,
					},
				])
			}
		}

		setSelectedRow(prev => {
			if (prev?.length) {
				const foundShift = prev?.some(element => element._id === data._id)
				if (foundShift && !event.ctrlKey) {
					return [data]
				} else if (event.ctrlKey) {
					if (foundShift) return prev.filter(entity => entity._id !== data._id)
					else return [...(prev?.length ? prev : []), data]
				} else {
					return [data]
				}
			} else {
				return [data]
			}
		})
	}

	const options = useMemo(() => {
		return [
			{
				label: "Shift Events",
				component: (
					<>
						<IncidentView
							incidents={
								shifts.allocatedShift.find(shift => shift._id === selectedRow?.[0]?._id)?.incidents ||
								shifts.activeShift.find(shift => shift._id === selectedRow?.[0]?._id)?.incidents ||
								shifts.cleaning.find(shift => shift._id === selectedRow?.[0]?._id)?.incidents ||
								shifts.criticalShift.find(shift => shift._id === selectedRow?.[0]?._id)?.incidents ||
								[]
							}
						/>

						<WorkMonitorView
							shift={
								shifts.allocatedShift.find(shift => shift._id === selectedRow?.[0]?._id) ||
								shifts.activeShift.find(shift => shift._id === selectedRow?.[0]?._id) ||
								shifts.cleaning.find(shift => shift._id === selectedRow?.[0]?._id) ||
								shifts.criticalShift.find(shift => shift._id === selectedRow?.[0]?._id)
							}
						/>

						<WhatsAppButton
							phone={
								parseInt(
									shifts.allocatedShift.find(shift => shift._id === selectedRow?.[0]?._id)
										?.employeeMobile || "",
								) ||
								parseInt(
									shifts.activeShift.find(shift => shift._id === selectedRow?.[0]?._id)
										?.employeeMobile || "",
								) ||
								parseInt(
									shifts.cleaning.find(shift => shift._id === selectedRow?.[0]?._id)
										?.employeeMobile || "",
								) ||
								parseInt(
									shifts.criticalShift.find(shift => shift._id === selectedRow?.[0]?._id)
										?.employeeMobile || "",
								) ||
								0
							}
							className="bottom-15 right-4 h-20 w-20"
						/>
					</>
				),
			},
			{
				label: "Geo Fence",
				component: (
					<GeoFenceTimeline
						shift={shifts.criticalShift.find(shift => shift._id === selectedRow?.[0]?._id) || null}
					/>
				),
			},
		].map(item => ({ ...item, value: item.label }))
	}, [shifts])

	useEffect(() => {
		if (selectedRow && shifts.criticalShift.length) {
			const selectedRowId = selectedRow[0]._id
			const selectedShift = shifts.criticalShift.find(shift => shift._id === selectedRowId)
			if (selectedShift) {
				setSelectedRow([selectedShift])
			}
		} else if (openResolveIncidentModal) {
			setOpenResolveIncidentModal(false)
		}
	}, [shifts])

	if (workMonitorLoading) {
		return (
			<div className="flex h-[90vh] items-center justify-center">
				<Icon name="spinner" color="dark" size={88} className="animate-spin" />
			</div>
		)
	}

	return (
		<div>
			<ReactHowler src={annoyingSound} loop={true} playing={play} html5={true} />

			<WorkMonitorActions
				shifts={shifts}
				multipleEventsData={multipleEventsData}
				resetShiftState={resetShiftState}
				searchString={searchString}
				selectedRow={selectedRow}
				searchModal={searchModal}
				setSearchModal={setSearchModal}
				setSearchString={setSearchString}
				setShiftData={setShiftData}
				setShiftStatusChangeModalOpen={setShiftStatusChangeModalOpen}
				setOpenAllocateModal={setOpenAllocateModal}
				setOpenDeleteModal={setOpenDeleteModal}
				setOpenIncidentModal={setOpenIncidentModal}
				setOpenShiftDetailsDrawer={setOpenShiftDetailsDrawer}
				setOpenShiftFilterDrawer={setOpenShiftFilterDrawer}
				setOpenResolveIncidentModal={setOpenResolveIncidentModal}
			/>

			<WorkMonitorTabs
				shifts={shifts}
				rowClickHandler={rowClickHandler}
				searchString={searchString}
				selectedRow={selectedRow}
				setOpenShiftDetailsDrawer={setOpenShiftDetailsDrawer}
			/>

			{openShiftDetailsDrawer && (
				<Drawer
					title="Shift Activity"
					width={"30%"}
					placement="right"
					onClose={() => {
						setOpenShiftDetailsDrawer(false)
					}}
					open={openShiftDetailsDrawer}
					rootStyle={{
						color: "rgb(229 229 229);",
					}}
					headerStyle={{
						background: "#1f2937",
					}}
					bodyStyle={{ background: theme.colors.background }}
					closeIcon={<Icon name="cross" color="white" size={18} />}
				>
					<div className="sticky top-0 z-10">
						<div className="flex items-center justify-between gap-x-2 pr-5">
							<Segmented options={options as any} value={value} onChange={(e: any) => setValue(e)} />
						</div>

						<Divider style={{ margin: "8px", marginLeft: "0px", marginRight: "0px" }} />

						<div id="shift-segment-body" className="mt-2 overflow-y-auto px-2">
							{options?.find(item => item.value === value)?.component}
						</div>
					</div>
				</Drawer>
			)}

			{shiftStatusChangeModalOpen && (
				<ShiftStatusModal
					confirmationLoading={statusUpdating}
					open={shiftStatusChangeModalOpen}
					modalHandler={resetShiftState}
					handleSubmit={onConfirmationHandler}
				/>
			)}

			{searchModal && <GlobalSearchModal modalHandler={setSearchModal} open={searchModal} />}

			{openShiftFilterDrawer && (
				<FilterShiftDrawer
					openShiftFilterDrawer={openShiftFilterDrawer}
					setOpenShiftFilterDrawer={setOpenShiftFilterDrawer}
				/>
			)}

			{openAllocateModal && (
				<AllocateShiftModal
					open={openAllocateModal}
					modalHandler={close => setOpenAllocateModal(close)}
					showInfo={{ location: selectedRow?.[0]?.location || "", post: selectedRow?.[0]?.post || "" }}
					selectedRow={selectedRow?.[0] || null}
				/>
			)}

			{openIncidentModal && (
				<IncidentModal
					open={openIncidentModal}
					modalHandler={close => setOpenIncidentModal(close)}
					selectedRow={selectedRow?.[0] || null}
				/>
			)}

			{openResolveIncidentModal && (
				<ResolveIncidentModal
					open={openResolveIncidentModal}
					modalHandler={close => setOpenResolveIncidentModal(close)}
					selectedRow={selectedRow?.[0] || null}
				/>
			)}

			{openDeleteModal && (
				<ConfirmationModal
					confirmationAction={false}
					open={openDeleteModal}
					loader={isScheduleDeleting}
					confirmationText={"delete"}
					modalHandler={(close: boolean) => setOpenDeleteModal(close)}
					onConfirmation={(notes: string) => {
						if (selectedRow) {
							const payload = {
								ids: [selectedRow[0].scheduleId],
								notes,
							}
							dispatch(
								deletingSchedule(payload, () => {
									dispatch(deleteShift(selectedRow[0]._id))
									dispatch(updateShiftColors())
									setOpenDeleteModal(false)
								}),
							)
							setOpenDeleteModal(false)
						}
					}}
				/>
			)}
		</div>
	)
}

export default WorkMonitor
