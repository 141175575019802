import React, { useEffect, useMemo, useRef, useState } from "react"
import { GlobalSidebar, SidebarList, FormInputAntd, AntdButton } from "components"
import { yupResolver } from "@hookform/resolvers/yup"
import { CreatePayGroupsInterface } from "@type/payGroup.types"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { SDPayGroupInitialValues, SDPayGroupSchema } from "Schema/StaticData/SDPayGroup"
import {
	getPayGroup,
	isPayGroupLoading,
	isPayGroupSaving,
	savePayGroup,
	selectPayGroupById,
	selectPayGroupData,
} from "store/PayGroups/payGroup.slice"
import { useAppDispatch, useAppSelector } from "hooks"

interface PayGroupTypeFormHandler extends CreatePayGroupsInterface {}

const SDPayGroup = () => {
	const [selectedSDPayGroup, setSelectedSDPayGroup] = useState<string | null>("")
	const dispatch = useAppDispatch()
	const saving = useAppSelector(isPayGroupSaving())
	const loading = useAppSelector(isPayGroupLoading())
	const SDPayGroupList = useAppSelector(selectPayGroupData())
	const SDPayGroup = useAppSelector(selectPayGroupById(selectedSDPayGroup || ""))
	const isNewBeingCreated = useRef(false)
	const methods = useForm<PayGroupTypeFormHandler>({ resolver: yupResolver(SDPayGroupSchema) })
	const {
		handleSubmit,
		reset,
		formState: { isSubmitted, isDirty, isSubmitting },
	} = methods
	const sidebarSDPayGroup = useMemo(() => {
		return SDPayGroupList?.map(SDPayGroup => ({
			_id: SDPayGroup._id,
			name: SDPayGroup.name,
		}))
	}, [saving, SDPayGroupList?.length, isSubmitted])

	const onSubmit: SubmitHandler<PayGroupTypeFormHandler> = data => {
		isNewBeingCreated.current = true
		dispatch(
			savePayGroup(data, (id: string) => {
				setSelectedSDPayGroup(id)
				isNewBeingCreated.current = false
			}),
		)
	}

	useEffect(() => {
		dispatch(getPayGroup(setSelectedSDPayGroup))
	}, [])

	useEffect(() => {
		if (selectedSDPayGroup !== (null && undefined)) {
			reset({ ...SDPayGroupInitialValues, ...SDPayGroup })
		} else if (selectedSDPayGroup === null && !isNewBeingCreated.current) {
			reset({ ...SDPayGroupInitialValues })
		}
	}, [selectedSDPayGroup])

	const handleSelected = (SDPayGroupId: string | null): void => setSelectedSDPayGroup(SDPayGroupId)

	return (
		<>
			<GlobalSidebar>
				<SidebarList
					title={"Pay Groups"}
					loading={loading}
					selected={selectedSDPayGroup}
					setSelected={handleSelected}
					list={sidebarSDPayGroup}
				/>
			</GlobalSidebar>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="flex w-1/4 flex-col gap-y-2">
						<FormInputAntd type="text" label="Pay Group Name" name="name" />
						<div className=" flex gap-2">
							<AntdButton onClick={() => setSelectedSDPayGroup(null)}>New</AntdButton>
							<AntdButton disabled={!isDirty || isSubmitting} loading={saving} htmlType="submit">
								{selectedSDPayGroup ? "Update" : "Save"}
							</AntdButton>
						</div>
					</div>
				</form>
			</FormProvider>
		</>
	)
}

export default SDPayGroup
