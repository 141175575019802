import React from "react"
import { Checkbox } from "antd"
import { CheckboxProps } from "antd/lib/checkbox"
import { Controller, useFormContext } from "react-hook-form"

interface Props extends CheckboxProps {
	label?: string
	name: string
	containerClassName?: string
}

const FormCheckbox: React.FC<Props> = ({ label, name, containerClassName, ...rest }) => {
	const { control } = useFormContext()
	return (
		<div className={containerClassName}>
			<Controller
				control={control}
				name={name}
				render={state => (
					<Checkbox checked={state.field.value} {...state.field} {...rest}>
						{label}
					</Checkbox>
				)}
			/>
		</div>
	)
}

export default FormCheckbox
