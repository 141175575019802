import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetEmployeeInformationInterface } from "@type/employees.types"
import { employeesInformationService } from "services"
import { AppThunk, RootState } from "store"

export interface EmployeeInformationStateInterface {
	list: GetEmployeeInformationInterface[]
	loading: boolean
	saving: boolean
}

const initialState: EmployeeInformationStateInterface = {
	list: [],
	loading: false,
	saving: false,
}

const informationSlice = createSlice({
	name: "information",
	initialState,
	reducers: {
		requestEmployeesInformation: employee => {
			employee.loading = true
		},
		receiveEmployeesInformation: (employee, action: PayloadAction<GetEmployeeInformationInterface[]>) => {
			employee.loading = false
			employee.list = action.payload
		},

		receiveEmployeesInformationFailed: employee => {
			employee.loading = false
		},
	},
})

//REDUCER
export default informationSlice.reducer

//ACTIONS
export const { requestEmployeesInformation, receiveEmployeesInformation, receiveEmployeesInformationFailed } =
	informationSlice.actions

const getEmployeesInformationByLocation =
	(locationId: string, dates: { startTime: string; finishTime: string }[]): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestEmployeesInformation())
			const { data: employeeResponse } = await employeesInformationService.getEmployeesInformationByLocation(
				locationId,
				dates,
			)
			dispatch(receiveEmployeesInformation(employeeResponse))
		} catch (error) {
			dispatch(receiveEmployeesInformationFailed())
		}
	}

export { getEmployeesInformationByLocation }

//SELECTORS
const selectEmployeeInformationState = (state: RootState) => state.employee.information
const isEmployeeInformationLoading = () => (state: RootState) => selectEmployeeInformationState(state).loading
const isEmployeeInformationSaving = () => (state: RootState) => selectEmployeeInformationState(state).saving
const selectEmployeeInformationList = () => (state: RootState) => selectEmployeeInformationState(state).list

export {
	selectEmployeeInformationList,
	selectEmployeeInformationState,
	isEmployeeInformationLoading,
	isEmployeeInformationSaving,
}
