import React, { useMemo } from "react"
import Icon from "./Icon"
import { AUDIT_ACTIONS, Entities } from "types/auditTrail.types"
import { isAuditTrailLoading, selectAuditTrail } from "store/AuditTrail/auditTrail.slice"
import useAppSelector from "hooks/useAppSelector"
import { TableColumnExtendedProps } from "./Table2"
import { AuditTrailTable } from "."
import { selectSelectedLocationDetails } from "store/globalState.slice"
import { caseConverters } from "config/utils"
import { selectBranchList } from "store/Branches/branch.slice"
import { selectSDSuppliersList } from "store/StaticData/SDSuppliers.slice"

interface Props {
	id: string
	name: Entities
	tableHeaders: TableColumnExtendedProps[]
}

const changesToFilter = ["date", "blowout"]

const maskChange: Record<string, string> = {
	notes: "comment",
	bypassSiaVerification: "Bypass SIA",
}

const transformChangesForTable = (transformedChangesObject: any, method: string) => {
	if (method === AUDIT_ACTIONS.CREATED.toLowerCase()) {
		return caseConverters.titleCase(AUDIT_ACTIONS.CREATED)
	} else if (transformedChangesObject["isDeleted"]) {
		return caseConverters.titleCase(AUDIT_ACTIONS.DELETED)
	} else if (method === AUDIT_ACTIONS.UPDATED.toLowerCase()) {
		const keysArrayInTransformedObject = Object.keys(transformedChangesObject)
		return keysArrayInTransformedObject
			.filter(
				item =>
					!changesToFilter.includes(item) &&
					!!transformedChangesObject[item] &&
					keysArrayInTransformedObject.length >= 1,
			)
			.map(item => caseConverters.titleCase(maskChange[item] ? maskChange[item] : item))
			.join(", ")
	}
}

const AuditTrailTableWrapper: React.FC<Props> = ({ id, name, tableHeaders }) => {
	const loading = useAppSelector(isAuditTrailLoading(id || "", name))
	const logs = useAppSelector(selectAuditTrail(id || "", name))
	const selectedLocationDetails = useAppSelector(selectSelectedLocationDetails)
	const branchesList = useAppSelector(selectBranchList())
	const suppliersList = useAppSelector(selectSDSuppliersList)

	const rows = useMemo(() => {
		let lastUpdate = {}
		const result: any = []
		const reversedLogs = [...logs].reverse()

		reversedLogs.forEach(({ method, changes, createdAt, user }) => {
			const messages: string[] = []
			const updatedFields: string[] = []

			const transformedChangesObject: any = changes.reduce((acc, curr) => {
				if (selectedLocationDetails?.branch._id !== curr.employeeBranchOrSupplier && curr.path === "employee") {
					const foundEmployeeBranchOrSupplier =
						branchesList.find(item => item._id === curr.employeeBranchOrSupplier)?.name ||
						suppliersList.find(item => item._id === curr.employeeBranchOrSupplier)?.name ||
						""
					if (foundEmployeeBranchOrSupplier)
						messages.push(`${curr.to} is from the different branch (${foundEmployeeBranchOrSupplier})`)
				}

				if (curr.path === "bypassSiaVerification" && !!curr.to) {
					const employeeChange = changes.find(change => change.path === "employee")
					messages.push(
						`${employeeChange?.to || "Employee"}'s SIA is invalid. SIA verification was manually bypassed`,
					)
				}

				updatedFields.push(curr.path)
				return { ...acc, [curr.path]: curr.to }
			}, {})

			lastUpdate = {
				...lastUpdate,
				...transformedChangesObject,
				user,
				method,
				createdAt,
				changes: transformChangesForTable(transformedChangesObject, method),
				updatedKeysArray: updatedFields,
			}
			result.push(lastUpdate)

			if (messages.length) result.push(...messages.map(message => ({ message })))
		})

		return result
	}, [logs])

	return loading ? (
		<div className={`flex min-h-52 w-full max-w-3xl items-center justify-center  overflow-hidden`}>
			<Icon name="spinner" color="black" size={85} className=" animate-spin" />
		</div>
	) : (
		<div>
			<AuditTrailTable columns={tableHeaders} rows={rows} renderAllRowKey="message" />
		</div>
	)
}

export default AuditTrailTableWrapper
