import axios from "axios"
import { GetNotifieeInterface, NotifieeInterface } from "@type/organization.types"

const ROOT_PATH = "notifiees"
export default class NotifieesService {
	static createNotifiee = (notifiee: NotifieeInterface) => axios.post<GetNotifieeInterface>(ROOT_PATH, notifiee)

	static getAllNotifiees = () => axios.get<Array<GetNotifieeInterface>>(`${ROOT_PATH}`)

	static getNotifieeById = (id: string) => axios.get<GetNotifieeInterface>(`${ROOT_PATH}/${id}`)

	static updateNotifiee = (id: string, notifiee: Partial<GetNotifieeInterface>) =>
		axios.put<GetNotifieeInterface>(`${ROOT_PATH}/${id}`, notifiee)
}
