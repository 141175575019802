import * as Yup from "yup"

export const SDDocumentTypeInitialValues = {
	type: "",
	enabledForLocations: false,
	enabledForEmployees: false,
}

export const SDDocumentTypeSchema = Yup.object().shape({
	type: Yup.string().required().label("Type"),
	enabledForLocations: Yup.boolean().required().label("Enabled For Locations"),
	enabledForEmployees: Yup.boolean().required().label("Enabled For Employees"),
})
