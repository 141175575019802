import { createContext } from "react"
import { EmployeeTermInterface } from "@type/employees.types"

export interface TermContextProps {
	handleRow: (e: EmployeeTermInterface | null) => void
	term: EmployeeTermInterface | null
}

const TermContext = createContext<TermContextProps | null>(null)

export default TermContext
