import { useEffect } from "react"
import { useAppDispatch } from "hooks"
import { reLogin } from "store/auth.slice"
import { socket } from "config/socket"

const AutoReLogin = () => {
	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(reLogin())
		return () => {
			socket?.disconnect()
		}
	}, [])
	return null
}

export default AutoReLogin
