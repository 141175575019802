import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
	GetSDDocumentTypeInterface,
	SDDocumentTypeInterface,
	UpdateSDDocumentTypeInterface,
} from "@type/staticData.types"
import { SDDocumentTypesService } from "services"
import { AppThunk, RootState } from "store"
import { sortByProperty, sortDeepCopyByProperty, updateState } from "config/utils"
export interface SDDocumentTypesStateInterface {
	list: null | Array<GetSDDocumentTypeInterface>
	loading: boolean
	saving: boolean
}

const initialState: SDDocumentTypesStateInterface = {
	list: null,
	loading: false,
	saving: false,
}

const SDDocumentTypesSlice = createSlice({
	name: "SDDocumentTypes",
	initialState,
	reducers: {
		fetchingSDDocumentTypes: SDDocumentTypes => {
			SDDocumentTypes.loading = true
		},
		SDDocumentTypesFetched: (SDDocumentTypes, action: PayloadAction<GetSDDocumentTypeInterface[]>) => {
			SDDocumentTypes.loading = false
			SDDocumentTypes.list = action.payload
		},
		SDDocumentTypesFetchingFailed: SDDocumentTypes => {
			SDDocumentTypes.loading = false
		},
		savingSDDocumentTypes: SDDocumentTypesData => {
			SDDocumentTypesData.saving = true
		},
		SDDocumentTypesSaved: (SDDocumentTypes, action: PayloadAction<GetSDDocumentTypeInterface>) => {
			SDDocumentTypes.saving = false
			const [UpdatedValue] = updateState(SDDocumentTypes.list, action.payload, "_id")
			SDDocumentTypes.list = UpdatedValue
		},
		SDDocumentTypesSavingFailed: SDDocumentTypes => {
			SDDocumentTypes.saving = false
		},
	},
})

//REDUCER
export default SDDocumentTypesSlice.reducer

//ACTIONS
const {
	fetchingSDDocumentTypes,
	SDDocumentTypesFetched,
	SDDocumentTypesFetchingFailed,
	savingSDDocumentTypes,
	SDDocumentTypesSaved,
	SDDocumentTypesSavingFailed,
} = SDDocumentTypesSlice.actions

const getSDDocumentTypes =
	(cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingSDDocumentTypes())
			const { data: SDDocumentTypesResponse } = await SDDocumentTypesService.getAllSDDocumentTypes()
			SDDocumentTypesResponse.sort(sortByProperty("type"))
			cb && cb(SDDocumentTypesResponse[0]?._id)
			dispatch(SDDocumentTypesFetched(SDDocumentTypesResponse))
		} catch (error) {
			dispatch(SDDocumentTypesFetchingFailed())
		}
	}

const saveSDDocumentType =
	(SDDocumentTypesData: Partial<UpdateSDDocumentTypeInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingSDDocumentTypes())
			if (SDDocumentTypesData?._id)
				data = await SDDocumentTypesService.updateSDDocumentType(SDDocumentTypesData._id, SDDocumentTypesData)
			else
				data = await SDDocumentTypesService.createSDDocumentType(SDDocumentTypesData as SDDocumentTypeInterface)
			const { data: SDDocumentTypesResponse } = data
			cb && cb(SDDocumentTypesResponse._id)
			dispatch(SDDocumentTypesSaved(SDDocumentTypesResponse))
		} catch (error) {
			dispatch(SDDocumentTypesSavingFailed())
		}
	}

export { getSDDocumentTypes, saveSDDocumentType }

//SELECTORS
const selectSDDocumentTypesState = (state: RootState) => state.staticData.SDDocumentTypes
const isSDDocumentTypesLoading = () => (state: RootState) => selectSDDocumentTypesState(state).loading
const isSDDocumentTypesSaving = () => (state: RootState) => selectSDDocumentTypesState(state).saving
const selectSDDocumentTypesList = createSelector(
	(state: RootState) => state.staticData.SDDocumentTypes.list,
	list => {
		return sortDeepCopyByProperty(list, "type") as GetSDDocumentTypeInterface[]
	},
)
const selectSDDocumentTypesDropdownList = createSelector(
	(state: RootState) => state.staticData.SDDocumentTypes.list,
	list => {
		return sortDeepCopyByProperty(list?.map(item => ({ label: item.type, value: item._id })) || [], "label")
	},
)
const selectSDDocumentTypesIdToLabel = createSelector(
	(state: RootState) => state.staticData.SDDocumentTypes.list,
	list => {
		if (list) {
			const idToLabel: { [key: string]: string } = {}
			list?.forEach(item => (idToLabel[item._id] = item.type))
			return idToLabel
		}
		return {}
	},
)

const selectSDDocumentTypesById = (SDDocumentTypesId: string | null) => (state: RootState) =>
	state.staticData.SDDocumentTypes?.list?.find(SDDocumentTypes => SDDocumentTypes._id === SDDocumentTypesId)

export {
	selectSDDocumentTypesList,
	selectSDDocumentTypesDropdownList,
	selectSDDocumentTypesState,
	selectSDDocumentTypesById,
	isSDDocumentTypesLoading,
	isSDDocumentTypesSaving,
	selectSDDocumentTypesIdToLabel,
}
