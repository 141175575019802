import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit"
import {
	DuplicatePayrollInterface,
	LocationHoursInterface,
	PayrollDataInterface,
	PayrollListInterface,
} from "@type/account.types"
import { AppThunk, RootState } from ".."
import PayrollService from "services/account/payroll.service"
import LocationSchedules from "services/schedules/schedules.service"

export interface PayrollStateInterface {
	list: PayrollListInterface[]
	loading: boolean
	loadingHoursWorked: boolean
	saving: boolean
}
const initialState: PayrollStateInterface = {
	list: [],
	loading: false,
	loadingHoursWorked: false,
	saving: false,
}

const PayrollSlice = createSlice({
	name: "Payroll",
	initialState,
	reducers: {
		fetchingPayrolls: payrolls => {
			payrolls.loading = true
		},
		fetchingHoursWorked: payrolls => {
			payrolls.loadingHoursWorked = true
		},
		hoursWorkedFetched: payrolls => {
			payrolls.loadingHoursWorked = false
		},
		payrollsFetched: (payrolls, action: PayloadAction<PayrollListInterface[]>) => {
			payrolls.loading = false
			payrolls.list = action.payload
		},
		fetchingPayrollsFailed: payrolls => {
			payrolls.loading = false
		},
		fetchingHoursWorkedFailed: payrolls => {
			payrolls.loadingHoursWorked = false
		},
		savingPayrolls: payrolls => {
			payrolls.saving = true
		},
		payrollsSaved: (payrolls, action: PayloadAction<PayrollDataInterface[]>) => {
			payrolls.saving = false
			action.payload.forEach(payload => {
				const currentSelectedMonthDataIndex = payrolls.list?.findIndex(
					entity => entity.month === action.payload[0]?.month,
				)
				const currentSelectedMonthData = payrolls.list[currentSelectedMonthDataIndex]

				const employeeIndexToUpdate = currentSelectedMonthData.data.findIndex(
					item => item.employee === payload.employee,
				)

				const employeeDataToUpdate = currentSelectedMonthData.data[employeeIndexToUpdate]

				const updatedObject = Object.fromEntries(
					Object.keys(payload).map(key =>
						payload[key as keyof PayrollDataInterface]
							? [key, payload[key as keyof PayrollDataInterface]]
							: [],
					),
				)

				const newCurrentSelectedMonthData = [...currentSelectedMonthData.data]

				newCurrentSelectedMonthData.splice(employeeIndexToUpdate, 1, {
					...employeeDataToUpdate,
					...updatedObject,
				})

				const updatedMonthData = {
					month: currentSelectedMonthData.month,
					data: [...newCurrentSelectedMonthData],
				}

				const updatedState = [...payrolls.list]
				updatedState.splice(currentSelectedMonthDataIndex, 1, updatedMonthData)

				payrolls.list = updatedState
			})
		},
		payrollsHoursSaved: (
			payrolls,
			action: PayloadAction<{
				res: {
					_id: string
					hoursWorked: number
					locationHours?: LocationHoursInterface[]
					locationRates?: { rate: number; additionalHours: number; location: string }[]
				}[]
				month: string
			}>,
		) => {
			payrolls.saving = false
			const { month, res: employeeLocationHoursDetail } = action.payload

			const currentSelectedMonthDataIndex = payrolls.list?.findIndex(entity => entity.month === month)

			const foundPayrollData = payrolls?.list?.find(entity => entity.month === action.payload.month)?.data

			const mergeLocationHoursWithPayroll = employeeLocationHoursDetail.map(employeeHours => {
				const currentMonthPayrollExistsForEmployee = foundPayrollData?.find(
					payroll => payroll.employee === employeeHours._id,
				)
				if (currentMonthPayrollExistsForEmployee) {
					return {
						...currentMonthPayrollExistsForEmployee,
						hoursWorked: currentMonthPayrollExistsForEmployee.hoursWorked || employeeHours?.hoursWorked,
						locationHours:
							employeeHours?.locationHours || currentMonthPayrollExistsForEmployee.locationHours,
						locationRates:
							employeeHours?.locationRates || currentMonthPayrollExistsForEmployee.locationRates,
					}
				} else {
					return {
						employee: employeeHours._id,
						month: action.payload.month,
						hours: 0,

						hoursWorked: employeeHours.hoursWorked,
						locationHours: employeeHours.locationHours,
						locationRates: employeeHours.locationRates,
					}
				}
			})

			const modifiedMonthData = {
				month,
				data: mergeLocationHoursWithPayroll,
			}

			const updatedState = [...payrolls.list]
			updatedState.splice(currentSelectedMonthDataIndex, 1, modifiedMonthData)

			payrolls.list = updatedState
		},
		savingPayrollsFailed: payrolls => {
			payrolls.saving = false
		},
		updatePayrollHours: (payroll, action: PayloadAction<PayrollListInterface>) => {
			payroll.saving = false
			payroll.list = [...payroll.list, action.payload]
		},
	},
})

export default PayrollSlice.reducer

export const {
	fetchingPayrolls,
	payrollsFetched,
	fetchingPayrollsFailed,
	savingPayrolls,
	payrollsSaved,
	savingPayrollsFailed,
	payrollsHoursSaved,
	updatePayrollHours,
	fetchingHoursWorked,
	fetchingHoursWorkedFailed,
	hoursWorkedFetched,
} = PayrollSlice.actions

const getPayrolls = (): AppThunk => async dispatch => {
	try {
		dispatch(fetchingPayrolls())
		const { data: response } = await PayrollService.getPayrolls()

		dispatch(payrollsFetched(response))
	} catch (error) {
		dispatch(fetchingPayrollsFailed())
	}
}
const getScheduleHoursByEmployee =
	(month: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingHoursWorked())
			const { data: response } = await LocationSchedules.getScheduleHoursByEmployeeHours(month)

			dispatch(payrollsHoursSaved({ res: response, month }))
			dispatch(hoursWorkedFetched())
		} catch (error) {
			dispatch(fetchingHoursWorkedFailed())
		}
	}
const savePayrolls =
	(payload: PayrollDataInterface[], update: boolean, cb?: () => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingPayrolls())

			const { data: response } = update
				? await PayrollService.updatePayrolls(payload)
				: await PayrollService.createPayrolls(payload)

			dispatch(payrollsSaved(response))
			cb?.()
		} catch (error) {
			dispatch(savingPayrollsFailed())
		}
	}

const duplicatePayroll =
	(payload: DuplicatePayrollInterface, cb?: () => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingPayrolls())
			const { data: response } = await PayrollService.duplicatePayroll(payload)
			dispatch(updatePayrollHours(response))
			cb?.()
		} catch (error) {
			dispatch(savingPayrollsFailed())
		}
	}

export { getPayrolls, savePayrolls, getScheduleHoursByEmployee, duplicatePayroll }

const selectPayrollState = (state: RootState) => state.payroll
const selectPayrollLoading = (state: RootState) => selectPayrollState(state).loading
const selectHoursWorkedLoading = (state: RootState) => selectPayrollState(state).loadingHoursWorked
const selectPayrollSaving = (state: RootState) => selectPayrollState(state).saving

const selectPayrollList = (state: RootState) => selectPayrollState(state).list
const selectPayrollSideBarMonths = () => {
	return createSelector(
		(state: RootState) => state.payroll.list,
		payroll => {
			return payroll?.map(entity => entity?.month)
		},
	)
}
const selectPayrollDataByDate = (date: string) => {
	return createSelector(
		(state: RootState) => state.payroll.list,
		payroll => {
			return payroll?.find(entity => entity?.month === date)?.data || []
		},
	)
}

export {
	selectPayrollList,
	selectPayrollLoading,
	selectPayrollSideBarMonths,
	selectPayrollDataByDate,
	selectPayrollSaving,
	selectHoursWorkedLoading,
}
