import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { momentZ } from "config/moment"
import store, { AppThunk, RootState } from "store"

import WorkMonitorShiftsService from "services/workMonitor/workMonitor.service"

import { convertIOSDateTZToSpecificFormat, getMomentZInstanceOfDate, updateState } from "config/utils"
import { DATE_FORMATS, ShiftStatus, platformTypeENUM } from "config/constants"
import {
	ShiftInterface,
	UpdateWorkMonitor,
	ResolveIncidentDto,
	ShiftFiltersInterface,
	IncidentReportInterface,
	PopulatedShiftInterface,
	UpdateMultipleWorkMonitor,
} from "@type/workMonitor.types"
import WorkMonitorShiftsManager from "./workMonitorManager"

const COMMAND_CENTER_WINDOW_START_HOURS = 1
const COMMAND_CENTER_WINDOW_STEP_HOURS = 2
const COMMAND_CENTER_WINDOW_END_HOURS = 24

export interface WorkMonitorInterfaceProps {
	list: ShiftInterface[]
	processedList: PopulatedShiftInterface[]
	shiftFilters: ShiftFiltersInterface | null
	loading: boolean
	nextLoading: boolean
	saving: boolean
	playAudio: boolean
}

const initialState: WorkMonitorInterfaceProps = {
	list: [],
	processedList: [],
	shiftFilters: null,
	loading: false,
	nextLoading: false,
	saving: false,
	playAudio: false,
}

const processShiftsForWorkMonitor = (list: ShiftInterface[]): PopulatedShiftInterface[] => {
	const startTime = momentZ().subtract(COMMAND_CENTER_WINDOW_START_HOURS, "hours")
	const finishTime = momentZ().add(COMMAND_CENTER_WINDOW_END_HOURS, "hours")

	const result = list
		.filter(
			item =>
				item &&
				!item.shiftEvents.some(({ status }) => status == ShiftStatus.BOOKOFF) &&
				((momentZ(item.startTime).isSameOrAfter(startTime) &&
					momentZ(item.startTime).isSameOrBefore(finishTime)) ||
					(momentZ(item.finishTime).isSameOrAfter(startTime) &&
						momentZ(item.finishTime).isSameOrBefore(finishTime))),
		)
		.map(shift => {
			const { finishTime, startTime, shiftEvents = [] } = shift
			const { action, nextStatusTime } = WorkMonitorShiftsManager.getNextShiftEvent(shift)

			const status = WorkMonitorShiftsManager.getShiftsStatusFromNextEvent(shift)

			const doesCallExists = shiftEvents
				.filter(item => item.status === action)
				.map(item => momentZ(item.dueTime).format())
				.includes(momentZ(nextStatusTime?.eventTime).format())

			const isInBefore =
				getMomentZInstanceOfDate().isBetween(nextStatusTime.window.before, nextStatusTime.eventTime) &&
				(doesCallExists ? false : true)
			const isInAfter =
				getMomentZInstanceOfDate().isBetween(nextStatusTime.window.after, nextStatusTime.eventTime) &&
				(doesCallExists ? false : true)
			const isLate =
				getMomentZInstanceOfDate().isAfter(nextStatusTime.window.after) && (doesCallExists ? false : true)

			return {
				...shift,
				startTime: convertIOSDateTZToSpecificFormat(startTime, DATE_FORMATS.SHIFT_EXPECTED_TIME_FORMAT),
				finishTime: convertIOSDateTZToSpecificFormat(finishTime, DATE_FORMATS.SHIFT_EXPECTED_TIME_FORMAT),
				action,
				status,
				isInBefore,
				isInAfter,
				isLate,
				nextStatusTime,
			}
		})

	return result
}

const workMonitorSlice = createSlice({
	name: "workMonitor",
	initialState,
	reducers: {
		fetchingWorkMonitor: workMonitor => {
			workMonitor.loading = true
		},
		fetchingWorkMonitorNextSteps: workMonitor => {
			workMonitor.nextLoading = true
		},
		fetchedWorkMonitorNextSteps: workMonitor => {
			workMonitor.nextLoading = false
		},
		workMonitorFetched: (workMonitor, action: PayloadAction<any[]>) => {
			workMonitor.loading = false
			workMonitor.list = action.payload
			workMonitor.processedList = processShiftsForWorkMonitor(workMonitor.list)
		},
		workMonitorNextDurationFetched: (workMonitor, action: PayloadAction<any[]>) => {
			const data = action.payload
			const [UpdatedValue] = updateState(workMonitor.list, data, "_id")
			workMonitor.list = [...UpdatedValue]
			workMonitor.processedList = processShiftsForWorkMonitor([...UpdatedValue])
		},
		workMonitorFetchingFailed: workMonitor => {
			workMonitor.loading = false
		},
		savingWorkMonitor: workMonitorData => {
			workMonitorData.saving = true
		},
		workMonitorSaved: (workMonitor, action: PayloadAction<any>) => {
			const data = action.payload
			workMonitor.saving = false
			const [UpdatedValue] = updateState(workMonitor.list, data, "_id")
			workMonitor.list = [...UpdatedValue]
			workMonitor.processedList = processShiftsForWorkMonitor([...UpdatedValue])
		},
		updateShiftColors: workMonitor => {
			workMonitor.processedList = [...processShiftsForWorkMonitor(workMonitor.list)]
		},
		workMonitorColorChanged: (workMonitor, action: PayloadAction<any>) => {
			const [UpdatedValue, NewAdded] = updateState(workMonitor.list, action.payload, "_id")
			if (NewAdded) {
				workMonitor.list = [
					...UpdatedValue.filter(({ status }: { status: any }) => status != ShiftStatus.BOOKOFF),
				]
			}
		},
		deleteShift: (workMonitor, action: PayloadAction<string>) => {
			const filterData = workMonitor.list.filter(shift => shift._id !== action.payload)
			workMonitor.list = filterData
		},

		workMonitorSavingFailed: workMonitor => {
			workMonitor.saving = false
		},

		clearWorkMonitor: workMonitor => {
			workMonitor.list = []
			workMonitor.processedList = []
			workMonitor.loading = false
			workMonitor.saving = false
			workMonitor.playAudio = false
		},
		playAudio: workMonitor => {
			workMonitor.playAudio = true
		},
		stopAudio: workMonitor => {
			workMonitor.playAudio = false
		},
		saveShiftFilters: (state, action: PayloadAction<ShiftFiltersInterface | null>) => {
			state.shiftFilters = action.payload
		},
		clearShiftFilters: state => {
			state.shiftFilters = null
		},
	},
})

//REDUCER
export default workMonitorSlice.reducer

//ACTIONS
export const {
	updateShiftColors,
	fetchingWorkMonitor,
	workMonitorFetched,
	workMonitorFetchingFailed,
	savingWorkMonitor,
	workMonitorSaved,
	workMonitorSavingFailed,
	clearWorkMonitor,
	workMonitorColorChanged,
	playAudio,
	stopAudio,
	deleteShift,
	saveShiftFilters,
	clearShiftFilters,
	workMonitorNextDurationFetched,
	fetchingWorkMonitorNextSteps,
	fetchedWorkMonitorNextSteps,
} = workMonitorSlice.actions

const getWorkMonitor = (): AppThunk => async dispatch => {
	let data = {
		startTime: momentZ()
			.clone()
			.subtract(COMMAND_CENTER_WINDOW_START_HOURS, "hours")
			.utc()
			.format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1),
		finishTime: momentZ()
			.clone()
			.add(COMMAND_CENTER_WINDOW_STEP_HOURS, "hours")
			.utc()
			.format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1),
	}
	try {
		dispatch(clearWorkMonitor())
		dispatch(fetchingWorkMonitor())
		let date = Date.now()
		const { data: workMonitorResponse } = await WorkMonitorShiftsService.getShifts(data)
		dispatch(workMonitorFetched(workMonitorResponse))
		console.warn("Call 1 ended", (Date.now() - date) / 1000)

		dispatch(fetchingWorkMonitorNextSteps())
		for (let i = 0; i < COMMAND_CENTER_WINDOW_END_HOURS / COMMAND_CENTER_WINDOW_STEP_HOURS; i++) {
			date = Date.now()
			data = getWorkMonitorNextStepDates(data.finishTime, COMMAND_CENTER_WINDOW_STEP_HOURS)
			const { data: workMonitorResponse2 } = await WorkMonitorShiftsService.getShifts(data)
			dispatch(workMonitorNextDurationFetched(workMonitorResponse2))
			console.warn(`Call ${i + 2} ended`, (Date.now() - date) / 1000)
		}
		dispatch(fetchedWorkMonitorNextSteps())
	} catch (error) {
		dispatch(workMonitorFetchingFailed())
	}
}
const getWorkMonitorNextStepDates = (startTime: string, finishTimeDifference: number) => {
	return {
		startTime,
		finishTime: momentZ(startTime, DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1)
			.clone()
			.add(finishTimeDifference, "hours")
			.utc()
			.format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1),
	}
}
const getNextIntervalData = (): AppThunk => async dispatch => {
	const currentTime = momentZ()

	const startTime = currentTime.clone().utc().add(COMMAND_CENTER_WINDOW_END_HOURS, "hours")
	const finishTime = currentTime.clone().utc().add(COMMAND_CENTER_WINDOW_END_HOURS, "hours").add(1, "minutes")

	try {
		const { data: workMonitorResponse } = await WorkMonitorShiftsService.getShifts({
			startTime: startTime.format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1),
			finishTime: finishTime.format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1),
		})

		dispatch(workMonitorNextDurationFetched(workMonitorResponse))
	} catch (error) {
		dispatch(workMonitorFetchingFailed())
	}
}

const updateShifts =
	(shiftId: string, data: UpdateWorkMonitor, cb?: any): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingWorkMonitor())
			const { data: workMonitorResponse } = await WorkMonitorShiftsService.updateShifts(shiftId, {
				...data,
				source: platformTypeENUM.WEB,
			})
			dispatch(workMonitorSaved(workMonitorResponse))
			cb?.()
		} catch (error) {
			dispatch(workMonitorSavingFailed())
		}
	}

const updateMultipleShifts =
	(data: UpdateMultipleWorkMonitor[], cb?: any): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingWorkMonitor())
			const { data: workMonitorResponse } = await WorkMonitorShiftsService.updateMultipleShifts(data)
			dispatch(workMonitorSaved(workMonitorResponse))
			cb && cb()
		} catch (error) {
			dispatch(workMonitorSavingFailed())
		}
	}

const addIncidentInShift =
	(shiftId: string, data: IncidentReportInterface, cb?: any): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingWorkMonitor())
			await WorkMonitorShiftsService.addIncidentInShift(shiftId, data)
			cb && cb()
		} catch (error) {
			dispatch(workMonitorSavingFailed())
		}
	}

const resolveIncidentInShift =
	(incidentId: string, data: ResolveIncidentDto, cb?: any): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingWorkMonitor())
			await WorkMonitorShiftsService.resolveIncidentInShift(incidentId, data)
			cb && cb()
		} catch (error) {
			dispatch(workMonitorSavingFailed())
		}
	}

export { getWorkMonitor, updateShifts, addIncidentInShift, updateMultipleShifts, resolveIncidentInShift }

//SELECTORS
const selectWorkMonitorState = (state: RootState) => state.workMonitor
const selectWorkMonitorLoading = () => (state: RootState) => selectWorkMonitorState(state).loading
const selectWorkMonitorNextLoading = () => (state: RootState) => selectWorkMonitorState(state).nextLoading
const selectPlayAudio = () => (state: RootState) => selectWorkMonitorState(state).playAudio
const selectPlayAudioFromStore = () => store.getState().workMonitor.playAudio
const selectWorkMonitorList = () => (state: RootState) => selectWorkMonitorState(state).list || []
const selectWorkMonitorListFromStore = () => store.getState().workMonitor.list || []
const selectWorkMonitorSaving = () => (state: RootState) => selectWorkMonitorState(state).saving
const selectShiftFilter = () => (state: RootState) => state.workMonitor.shiftFilters

const selectWorkMonitorDataStructureChanged = createSelector(
	(state: RootState) => ({
		processedList: state.workMonitor.processedList,
		shiftFilters: state.workMonitor.shiftFilters,
	}),
	({ processedList, shiftFilters }) => {
		if (shiftFilters) {
			const filteredList = processedList.filter(list => {
				const {
					employeeBranch = null,
					supplier = null,
					locationBranch = null,
					customer = null,
					upcomingShift = false,
					onGoingShift = false,
					startingSoon = false,
					endingSoon = false,
				} = shiftFilters

				const isDropdownFilterActive = ["employeeBranch", "supplier", "locationBranch", "customer"].some(
					key => !!shiftFilters[key as keyof ShiftFiltersInterface],
				)
				let dropdownFilter = !isDropdownFilterActive

				if (isDropdownFilterActive) {
					dropdownFilter = !!(
						employeeBranch?.includes(list?.employeeBranch || "") ||
						supplier?.includes(list?.employeeSupplier || "") ||
						locationBranch?.includes(list?.locationBranch || "") ||
						customer?.includes(list?.customerId || "")
					)
				}

				const isBothLocationAndCustomerFilter =
					locationBranch && customer
						? locationBranch?.includes(list?.locationBranch || "") &&
						  customer?.includes(list?.customerId || "")
						: true

				const isToggleFilterActive = ["upcomingShift", "onGoingShift", "startingSoon", "endingSoon"].some(
					key => !!shiftFilters[key as keyof ShiftFiltersInterface],
				)

				let toggleFilter = !isToggleFilterActive

				if (isToggleFilterActive) {
					toggleFilter =
						(upcomingShift && list.action === ShiftStatus.CHASED) ||
						(onGoingShift && list.status === ShiftStatus.BOOKON) ||
						(startingSoon && list.action === ShiftStatus.BOOKON) ||
						(endingSoon && list.action === ShiftStatus.BOOKOFF)
				}

				return dropdownFilter && toggleFilter && isBothLocationAndCustomerFilter
			})

			return WorkMonitorShiftsManager.sortShiftsByDateAndStatus(filteredList)
		} else return WorkMonitorShiftsManager.sortShiftsByDateAndStatus(processedList)
	},
)

export {
	selectWorkMonitorList,
	selectShiftFilter,
	selectWorkMonitorState,
	selectWorkMonitorSaving,
	selectWorkMonitorLoading,
	selectPlayAudio,
	selectWorkMonitorDataStructureChanged,
	selectWorkMonitorListFromStore,
	selectPlayAudioFromStore,
	getNextIntervalData,
	selectWorkMonitorNextLoading,
}
