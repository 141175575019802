import { ApexOptions } from "apexcharts"
import ReactApexChart from "react-apexcharts"

interface ChartInterface {
	series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined
	options: ApexOptions
	height: number | string
	width: number | string
	type: string
}

const Chart: React.FC<ChartInterface> = ({ series, width, options, height, type = "donut" }) => (
	<ReactApexChart
		options={options}
		series={series}
		type={type as any}
		height={height || "auto"}
		width={width || "auto"}
	/>
)
export default Chart
