import { isSavingLocation } from "store/Location/locationDetails.slice"
import { Modal, AntdInput, AntdButton } from "components"
import { useAppSelector } from "hooks"
interface Props {
	showInputs: boolean
	inputSize: number[]
	openModal: boolean
	handleModal: (value: boolean) => void
	name: string[]
	handleInput: (value: string, index: number) => void
	submitHandler: () => void
}

const QRModal: React.FC<Props> = ({
	inputSize,
	handleModal,
	openModal,
	name,
	handleInput,
	submitHandler,
	showInputs,
}) => {
	const locationSaving = useAppSelector(isSavingLocation())

	return (
		<Modal open={openModal} modalHandler={handleModal}>
			{showInputs ? (
				<>
					<div className="grid grid-cols-2 gap-4">
						{inputSize.map((count, index) => (
							<div key={index}>
								<AntdInput
									key={index}
									label={`Name ${count + 1}`}
									type="text"
									value={name[index]}
									onChange={e => handleInput(e.target.value, index)}
								/>
							</div>
						))}
					</div>
					<AntdButton
						className="mt-4 h-full w-fit p-2"
						onClick={submitHandler}
						loading={locationSaving}
						disabled={locationSaving}
					>
						Save
					</AntdButton>
				</>
			) : (
				<></>
			)}
		</Modal>
	)
}

export default QRModal
