import useLocationStats from "hooks/useLocationStats"
import StatCard from "components/StatCard"

export interface StatsData {
	title: string
	color: string
	value: number
}

const StatsSection = () => {
	const { statsData } = useLocationStats()

	return (
		<div className="flex space-x-4 py-4">
			{statsData?.map(item => (
				<StatCard key={item.title} title={item.title} color={item.color} value={item.value} />
			))}
		</div>
	)
}

export default StatsSection
