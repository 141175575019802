import React, { useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "hooks"
import { AntdButton, Table2, TableSkeleton } from "components"
import { GetLocationEquipmentInterface } from "@type/locations.types"
import {
	getLocationEquipment,
	selectLocationEquipmentData,
	isLocationEquipmentLoading,
} from "store/Location/locationEquipment.slice"
import LocationEquipmentModal from "./LocationEquipmentModal"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"
import { selectSelectedLocation } from "store/globalState.slice"
import { Divider, Typography } from "antd"
import { PlusOutlined } from "@ant-design/icons"

const LocationEquipments = () => {
	const dispatch = useAppDispatch()
	const selected = useAppSelector(selectSelectedLocation)
	const [modalOpen, setModalOpen] = useState(false)
	const [row, setRow] = useState<GetLocationEquipmentInterface>()
	const equipmentData = useAppSelector(selectLocationEquipmentData())
	const isLoading = useAppSelector(isLocationEquipmentLoading())

	const locationEquipment = useMemo(() => {
		if (equipmentData) {
			return equipmentData?.map((d, i) => {
				return {
					...d,
					serialNumber: i + 1,
				}
			})
		}
		return []
	}, [equipmentData])

	useEffect(() => {
		selected && dispatch(getLocationEquipment(selected as string))
	}, [selected])

	return (
		<div className="py-5">
			<Divider orientation="left" plain>
				<Typography.Title level={5}>Equipments Section</Typography.Title>
			</Divider>
			<div className="flex justify-end pb-4">
				<AntdButton
					icon={<PlusOutlined />}
					onClick={() => {
						setRow(undefined)
						setModalOpen(true)
					}}
				>
					Add Equipment
				</AntdButton>
			</div>
			{isLoading ? (
				<TableSkeleton />
			) : (
				<Table2
					columns={[
						{ label: "Sr. No", key: "serialNumber" },
						{ label: "Equipment", key: "equipment" },
						{ label: "Quantity", key: "quantity" },
						{ label: "Notes", key: "notes" },
						{
							label: "History",
							key: "_id",
							render: ({ row }: any) => (
								<AuditTrail id={row["_id"]} name={Entities.LOCATION_EQUIPMENTS} showLabel={false} />
							),
						},
						{
							label: "Action",
							key: "action",
							render: ({ row }) => {
								return (
									<AntdButton
										className="my-1.25"
										onClick={e => {
											e.preventDefault()
											setRow(row)
											setModalOpen(true)
										}}
									>
										Update
									</AntdButton>
								)
							},
						},
					]}
					rows={locationEquipment}
				/>
			)}

			{modalOpen && (
				<LocationEquipmentModal
					row={row}
					open={modalOpen}
					modalHandler={(close: boolean) => {
						setModalOpen(close)
					}}
				/>
			)}
		</div>
	)
}

export default LocationEquipments
