//REACT/PACKAGE
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "hooks"
// COMPONENTS
import { Icon, AntdButton } from "components"
//CONTEXT/SLICE
import {
	saveEmployeeQualificationData,
	SavingEmployeeQualificationSuccess,
	deleteEmployeesQualificationById,
	selectEmployeeQualificationData,
	SelectEmployeeQualificationLoading,
	selectFilteredEmployeeQualifications,
	SelectEmployeeQualificationApprovalLoading,
} from "store/Employee/Qualification/qualification.slice"
import QualificationContext, { QualificationContextProps } from "./qualificationContext"
import { convertIOSDateToHTMLFormat, checkDateExpired, addPathToDocument } from "config/utils"
import {
	isSDQualificationsLoading,
	SDQualificationsSaved,
	selectFilteredSDQualificationsList,
	selectSDQualificationsList,
} from "store/StaticData/SDQualifications.slice"
import { socket } from "config/socket"
import {
	ROOM_EVENTS,
	SOCKET_ROOMS,
	EMPLOYEE_QUALIFICATION_SOCKET_EVENTS,
	STATIC_DATA_QUALIFICATION_SOCKET_EVENTS,
} from "types/webSocket.types"
import { DATE_FORMATS, GLOBAL_DATE_FORMAT, qualificationState } from "config/constants"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"
import { Table, Tooltip } from "antd-v5"
import LdnDrawer from "../Qualifications/LdnDrawer"
import { LdnInterface } from "@type/employees.types"
import { momentZ } from "config/moment"
import cx from "classnames"
import { RightOutlined, DownOutlined } from "@ant-design/icons"
import GenericService from "services/generic/generic.service"
interface Props {
	showActions?: boolean
	onRowClick?: (id: string) => void
	selectedRow?: string
	checkInvalidRow?: boolean
}

export interface LdnFormProps {
	qualificationId: string
	ldn: LdnInterface | null
}

const QualificationTable: React.FC<Props> = ({
	showActions = true,
	onRowClick = () => undefined,
	selectedRow = "",
}) => {
	const dispatch = useAppDispatch()
	const allQualifications = useAppSelector(selectSDQualificationsList)
	const filteredQualifications = useAppSelector(selectFilteredSDQualificationsList)
	const qualifications = useAppSelector(selectEmployeeQualificationData())
	const filteredEmployeeQualifications = useAppSelector(selectFilteredEmployeeQualifications(filteredQualifications))
	const approvalLoading = useAppSelector(SelectEmployeeQualificationApprovalLoading())
	const loadingQualifications = useAppSelector(isSDQualificationsLoading())
	const loadingEmployeeQualifications = useAppSelector(SelectEmployeeQualificationLoading())
	const [openLDNDrawer, setOpenLDNDrawer] = useState(false)
	const [ldnFormProps, setLdnFormProps] = useState<LdnFormProps>({ qualificationId: "", ldn: null })

	const context = useContext(QualificationContext) as QualificationContextProps

	const [qualificationSectors, employeeQualifications] = useMemo(() => {
		return showActions
			? [allQualifications, qualifications]
			: [filteredQualifications, filteredEmployeeQualifications]
	}, [allQualifications, qualifications, filteredQualifications, filteredEmployeeQualifications, showActions])

	const handleRow = useMemo(() => {
		return context?.handleRow
	}, [QualificationContext])

	const qualificationValue = useMemo(() => {
		if (employeeQualifications) {
			return employeeQualifications?.map((qualification, i) => {
				return { ...qualification, serialNumber: i + 1, key: qualification._id }
			})
		}

		return []
	}, [employeeQualifications])

	useEffect((): any => {
		socket.emit(ROOM_EVENTS.JOIN_ROOM, SOCKET_ROOMS.EMPLOYEE_QUALIFICATION)
		socket.emit(ROOM_EVENTS.JOIN_ROOM, SOCKET_ROOMS.STATIC_DATA_QUALIFICATION)

		socket.on(EMPLOYEE_QUALIFICATION_SOCKET_EVENTS.ADDED, (res: any) =>
			dispatch(SavingEmployeeQualificationSuccess(res)),
		)
		socket.on(EMPLOYEE_QUALIFICATION_SOCKET_EVENTS.UPDATED, res =>
			dispatch(SavingEmployeeQualificationSuccess(res)),
		)

		socket.on(STATIC_DATA_QUALIFICATION_SOCKET_EVENTS.ADDED, (res: any) => dispatch(SDQualificationsSaved(res)))
		socket.on(STATIC_DATA_QUALIFICATION_SOCKET_EVENTS.UPDATED, res => dispatch(SDQualificationsSaved(res)))

		return () => {
			socket.emit(ROOM_EVENTS.LEAVE_ROOM, SOCKET_ROOMS.EMPLOYEE_QUALIFICATION)
			socket.emit(ROOM_EVENTS.LEAVE_ROOM, SOCKET_ROOMS.STATIC_DATA_QUALIFICATION)
		}
	}, [])

	// const handleInvalidRow = (e: any) => {
	// 	return checkDateExpired(new Date(e.finishDate))
	// }
	const approveSiaQualification = (input: any) => {
		const { qualification, employee, reference, _id } = input
		dispatch(
			saveEmployeeQualificationData({
				employee,
				qualification,
				reference,
				_id,
				currentStatus: qualificationState.Approved,
			}),
		)
	}
	const deleteSiaQualification = (id: string) => {
		dispatch(deleteEmployeesQualificationById(id))
	}

	const isSiaExpired = (finishDate?: string) => !!finishDate && checkDateExpired(new Date(finishDate))

	return (
		<div>
			{ldnFormProps && openLDNDrawer && (
				<LdnDrawer
					ldnFormProps={ldnFormProps}
					open={openLDNDrawer}
					handleDrawerClose={() => setOpenLDNDrawer(prev => !prev)}
				/>
			)}

			<Table
				loading={loadingQualifications || loadingEmployeeQualifications}
				pagination={showActions && false}
				size="small"
				expandable={{
					columnTitle: "LDN(s)",
					expandIcon: ({ expanded, onExpand, record }) => (
						<AntdButton
							onClick={e => onExpand(record, e)}
							icon={expanded ? <DownOutlined /> : <RightOutlined />}
							className="gap-x-2"
						>
							{record?.["ldn"] ? record?.["ldn"].length : 0}
						</AntdButton>
					),
					expandedRowRender: record => {
						return (
							<div className="ml-28 border-l-0.25 border-secondary-dark pl-2">
								{record?.ldn && !!record?.ldn?.length ? (
									<div key={`${record._id}_${record.serialNumber}`}>
										<Table
											size="small"
											pagination={false}
											dataSource={record?.ldn || []}
											columns={[
												...(showActions
													? [
															{
																title: "Reference No",
																key: "reference",
																dataIndex: "reference",
															},
													  ]
													: []),
												{
													title: "Start Date",
													key: "startDate",
													dataIndex: "startDate",
													render: value => (
														<div>{momentZ(value).format(DATE_FORMATS.DD_MM_YYYY)}</div>
													),
												},
												{
													title: "Expiry Date",
													key: "endDate",
													dataIndex: "endDate",
													render: value => (
														<div>{momentZ(value).format(DATE_FORMATS.DD_MM_YYYY)}</div>
													),
												},
												{
													title: "Document",
													key: "documentPath",
													dataIndex: "documentPath",
													render: (value, ldnRecord) => {
														return (
															<AntdButton
																size="small"
																onClick={async () => {
																	if (value) {
																		const downloadURL = await addPathToDocument(
																			value,
																		)
																		try {
																			const response =
																				await GenericService.getBlobResponse(
																					downloadURL,
																				)
																			const contentType =
																				response.headers["content-type"]

																			// Assuming the response.data is a Blob
																			const blobData = new Blob([response.data], {
																				type: contentType,
																			})
																			const objectURL =
																				window.URL.createObjectURL(blobData)
																			const tempLink = document.createElement("a")
																			tempLink.href = objectURL
																			tempLink.setAttribute(
																				"download",
																				ldnRecord.reference,
																			)
																			tempLink.click()
																		} catch (error) {
																			console.error(
																				"Error occurred while downloading the file:",
																				error,
																			)
																		}
																	}
																}}
															>
																<span className="text-sm">Download</span>
															</AntdButton>
														)
													},
												},
												...(showActions
													? [
															{
																title: "Edit",
																render: (_: any, ldnRecord: any) => {
																	return (
																		<div
																			onClick={() => {
																				setOpenLDNDrawer(true)
																				setLdnFormProps(() => ({
																					qualificationId: record._id,
																					ldn: ldnRecord,
																				}))
																			}}
																			className="cursor-pointer text-sm"
																		>
																			Update
																		</div>
																	)
																},
															},
													  ]
													: []),
											]}
										/>
										{showActions && (
											<div className="mt-2 flex justify-end">
												<AntdButton
													type="dashed"
													onClick={() => {
														setLdnFormProps(() => ({
															qualificationId: record._id || "",
															ldn: null,
														}))
														setOpenLDNDrawer(prev => !prev)
													}}
												>
													Add New LDN
												</AntdButton>
											</div>
										)}
									</div>
								) : (
									<div className="flex justify-end">
										{showActions ? (
											<AntdButton
												type="dashed"
												onClick={() => {
													setLdnFormProps(() => ({
														qualificationId: record._id || "",
														ldn: null,
													}))
													setOpenLDNDrawer(prev => !prev)
												}}
											>
												Add New LDN
											</AntdButton>
										) : (
											<div className="text-right font-semibold">No LDN Issued</div>
										)}
									</div>
								)}
							</div>
						)
					},
				}}
				dataSource={qualificationValue.sort(
					(a, b) => momentZ(b.finishDate || "").valueOf() - momentZ(a.finishDate || "").valueOf(),
				)}
				rowClassName={"text-sm cursor-pointer"}
				rowSelection={{
					type: "radio",
					selectedRowKeys: [selectedRow],
					onSelect: record => {
						onRowClick(record._id)
					},
				}}
				columns={[
					Table.EXPAND_COLUMN,
					{
						title: "Name",
						key: "fetchedSiaDetails",
						dataIndex: "fetchedSiaDetails",
						render: value => <div>{value ? value?.["firstName"] + " " + value?.["surname"] : " "}</div>,
					},
					{
						title: "Qualification",
						key: "qualification",
						dataIndex: "qualification",
						render: value => qualificationSectors?.find(i => i._id === value)?.name,
					},
					...(showActions
						? [
								{
									title: "Reference",
									key: "reference",
									dataIndex: "reference",
								},
						  ]
						: []),
					{
						title: "Finish Date",
						key: "finishDate",
						dataIndex: "finishDate",
						render: value => (
							<div
								className={`flex w-full items-center justify-center ${
									isSiaExpired(value) ? "rounded-md bg-red-300" : ""
								}`}
							>
								<div className="w-fit p-1">
									{value ? convertIOSDateToHTMLFormat(value, GLOBAL_DATE_FORMAT) : ""}
								</div>
							</div>
						),
					},
					{
						title: "Status",
						key: "fetchedSiaDetails",
						dataIndex: "fetchedSiaDetails",
						render: (value, record) => (
							<div
								className={cx("font-bold", {
									"text-green-700": value?.["status"]?.includes("Active"),
									"text-red-700": isSiaExpired(record?.["finishDate"]),
								})}
							>
								{isSiaExpired(record?.["finishDate"]) ? "Expired" : value?.["status"] ?? ""}
							</div>
						),
					},
					{
						title: "State",
						key: "currentStatus",
						dataIndex: "currentStatus",
						render: (value, row) => (
							<div>
								{value?.includes(qualificationState.Pending) ? (
									!approvalLoading ? (
										<div className="flex justify-between space-x-2">
											<div
												className="bg-green-600"
												onClick={e => {
													e.stopPropagation()
													approveSiaQualification(row)
												}}
											>
												<Icon name="approve" color="white" size={18} />
											</div>
											<div
												className="bg-red-500"
												onClick={e => {
													e.stopPropagation()
													deleteSiaQualification(row._id)
												}}
											>
												<Icon name="deny" color="white" size={18} />
											</div>
										</div>
									) : (
										<div className="flex justify-center">
											<Icon name="spinner" color="black" size={18} className="animate-spin" />
										</div>
									)
								) : (
									value
								)}
							</div>
						),
					},
					{
						title: "History",
						key: "_id",
						dataIndex: "_id",
						render: value => (
							<AuditTrail id={value} name={Entities.EMPLOYEE_QUALIFICATIONS} showLabel={false} />
						),
					},
					...(showActions
						? [
								{
									title: "Action",
									key: "action",
									dataIndex: "action",
									render: (_: any, row: any) => (
										<Tooltip title={row.fetchedSiaDetails ? "Auto-generated: Not editable" : null}>
											<AntdButton
												onClick={e => {
													e.preventDefault()
													handleRow(row)
												}}
												disabled={row.fetchedSiaDetails}
											>
												Update
											</AntdButton>
										</Tooltip>
									),
								},
						  ]
						: []),
				]}
			/>
			{/* <Table2
				selectRow
				onRowClick={onRowClick}
				selectedRows={[selectedRow]}
				checkInvalidRow={row => handleInvalidRow(row)}
				columns={[
					{ label: "Sr. No", key: "serialNumber" },
					{
						label: "Name",
						key: "fetchedSiaDetails",
						render: ({ row, key }) => (
							<div>{row?.[key] ? row?.[key]?.["firstName"] + " " + row?.[key]?.["surname"] : " "}</div>
						),
					},
					{
						label: "Qualification",
						key: "qualification",
						render: ({ row, key }) => qualificationSectors?.find(i => i._id === row[key])?.name,
					},
					{
						label: "Reference",
						key: "reference",
					},
					{
						label: "Finish Date",
						key: "finishDate",
						render: ({ row, key }) =>
							row[key] ? convertIOSDateToHTMLFormat(row[key], GLOBAL_DATE_FORMAT) : "",
					},
					{
						label: "Status",
						key: "fetchedSiaDetails",
						render: ({ row, key }) => (
							<div
								className={`${
									row?.[key]?.["status"]?.includes("Active") ? "font-bold text-green-700" : ""
								}`}
							>
								{row?.[key]?.["status"] ?? ""}
							</div>
						),
					},
					{
						label: "State",
						key: "currentStatus",
						render: ({ row, key }) => (
							<div>
								{row[key]?.includes(qualificationState.Pending) && selectedRow ? (
									!approvalLoading ? (
										<div className="flex justify-between space-x-2">
											<>
												<div
													className="bg-green-600"
													onClick={e => {
														e.stopPropagation()
														approveSiaQualification(row)
													}}
												>
													<Icon name="approve" color="white" size={18} />
												</div>
												<div
													className="bg-red-500"
													onClick={e => {
														e.stopPropagation()
														deleteSiaQualification(row._id)
														if (row._id === selectedRow) setSelectedQualification("")
													}}
												>
													<Icon name="deny" color="white" size={18} />
												</div>
											</>
										</div>
									) : (
										<div className="flex justify-center">
											<Icon name="spinner" color="black" size={18} className="animate-spin" />
										</div>
									)
								) : (
									row[key]
								)}
							</div>
						),
					},
					{
						label: "History",
						key: "_id",
						render: ({ row }: any) => (
							<AuditTrail id={row["_id"]} name={Entities.EMPLOYEE_QUALIFICATIONS} showLabel={false} />
						),
					},
					...(showActions
						? [
								{
									label: "Action",
									key: "action",
									render: ({ row }: any) => {
										return (
											<Button
												className="my-1.25"
												onClick={e => {
													e.preventDefault()
													handleRow(row)
												}}
											>
												Update
											</Button>
										)
									},
								},
						  ]
						: []),
				]}
				rows={qualificationValue}
			/> */}
		</div>
	)
}

export default QualificationTable
