import React from "react"

interface Props {}

const Loading: React.FC<Props> = () => {
	return (
		<div className="mx-auto   w-full max-w-sm rounded-md border-none p-4">
			<div className="flex animate-pulse flex-col ">
				{Array.from(Array(5)).map((_, index) => (
					<div key={index} className="mb-5 h-5 space-y-3 rounded-md bg-secondary-light"></div>
				))}
			</div>
		</div>
	)
}

export default Loading
