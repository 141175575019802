const AboutUs = () => {
	return (
		<div className="m-auto w-4/5 pt-[50px] pb-[25px]">
			<p
				style={{
					marginTop: "0pt",
					marginBottom: "15pt",
					textAlign: "justify",
					lineHeight: "115%",
					fontSize: "30pt",
					backgroundColor: "#ffffff",
				}}
			>
				<strong>
					<span style={{ fontSize: "11pt" }}>About Us</span>
				</strong>
			</p>

			<p style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>Our Story</span>
				</strong>
			</p>
			<p style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					Welcome to Timetrail Guard, a revolutionary time-tracking solution designed to enhance productivity
					and streamline daily tasks. Our journey began with a simple yet profound mission: to empower
					individuals and teams with the tools they need to manage their time effectively and achieve their
					goals.
				</span>
			</p>

			<p style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>Who We Are</span>
				</strong>
			</p>

			<p style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					We are a passionate team of developers, designers, and productivity enthusiasts committed to making
					a positive impact through technology. We believe that efficient time management is the cornerstone
					of success, and our products are designed to help users take control of their schedules and maximize
					their potential.
				</span>
			</p>

			<p style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>Timetrail Guard</span>
				</strong>
			</p>

			<p style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					Timetrail Guard is our flagship product, epitomizing our commitment to excellence and user-centric
					design. It is a powerful time-tracking app developed to help users log their work hours, track their
					progress, and boost their productivity. Whether {`you're`} a freelancer, remote worker, or part of a
					team, Timetrail Guard offers a suite of features tailored to meet your unique needs:
				</span>
			</p>

			<ul>
				<li style={{ listStyleType: "disc", fontSize: "11pt" }}>
					<p style={{ textAlign: "justify" }}>
						<strong>
							<span style={{ fontSize: "11pt" }}>Comprehensive Time Logging:</span>
						</strong>
						<span style={{ fontSize: "11pt" }}>
							&nbsp;Easily log work hours, breaks, and projects to keep track of your day.
						</span>
					</p>
				</li>
				<li style={{ listStyleType: "disc", fontSize: "11pt" }}>
					<p style={{ textAlign: "justify" }}>
						<strong>
							<span style={{ fontSize: "11pt" }}>Insightful Reports:</span>
						</strong>
						<span style={{ fontSize: "11pt" }}>
							&nbsp;Generate detailed reports by day, week, or month to monitor your progress and identify
							areas for improvement.
						</span>
					</p>
				</li>
				<li style={{ listStyleType: "disc", fontSize: "11pt" }}>
					<p style={{ textAlign: "justify" }}>
						<strong>
							<span style={{ fontSize: "11pt" }}>Customizable Schedules:</span>
						</strong>
						<span style={{ fontSize: "11pt" }}>
							&nbsp;Create custom work schedules that fit your lifestyle and work habits.
						</span>
					</p>
				</li>
				<li style={{ listStyleType: "disc", fontSize: "11pt" }}>
					<p style={{ textAlign: "justify" }}>
						<strong>
							<span style={{ fontSize: "11pt" }}>Reminders and Notifications:</span>
						</strong>
						<span style={{ fontSize: "11pt" }}>
							&nbsp;Stay on top of your tasks with timely reminders and notifications.
						</span>
					</p>
				</li>
				<li style={{ listStyleType: "disc", fontSize: "11pt" }}>
					<p style={{ textAlign: "justify" }}>
						<strong>
							<span style={{ fontSize: "11pt" }}>Flexible Time-Tracking Rules:</span>
						</strong>
						<span style={{ fontSize: "11pt" }}>
							&nbsp;Set up specific rules to manage your time more effectively and get more done.
						</span>
					</p>
				</li>
			</ul>

			<h3 style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "13pt" }}>Mobile Apps</span>
				</strong>
			</h3>
			<p style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					Our mobile apps extend the power of Timetrail Guard to your fingertips, providing the flexibility to
					manage your time on the go. Available for both iOS and Android, our mobile apps offer all the
					features below for enhanced productivity.
				</span>
			</p>
			<ul>
				<li style={{ listStyleType: "disc", fontSize: "11pt" }}>
					<p style={{ textAlign: "justify" }}>
						<strong>
							<span style={{ fontSize: "11pt" }}>Geo-Fencing for Accurate Tracking</span>
						</strong>
						<span style={{ fontSize: "11pt" }}>
							: Our mobile apps use geo-fencing technology to provide accurate and honest employee
							tracking. This ensures that your logged hours are precise and reliable, whether you’re
							working from the site or your office, home, or on the move.
						</span>
					</p>
				</li>
				<li style={{ listStyleType: "disc", fontSize: "11pt" }}>
					<p style={{ textAlign: "justify" }}>
						<strong>
							<span style={{ fontSize: "11pt" }}>Roster Management</span>
						</strong>
						<span style={{ fontSize: "11pt" }}>
							: Guards can check their rosters directly from the app, ensuring they are always aware of
							their schedules.
						</span>
					</p>
				</li>
				<li style={{ listStyleType: "disc", fontSize: "11pt" }}>
					<p style={{ textAlign: "justify" }}>
						<strong>
							<span style={{ fontSize: "11pt" }}>Site Booking Completion</span>
						</strong>
						<span style={{ fontSize: "11pt" }}>
							: The app allows guards to complete site bookings, making them visible to employers and
							helping them work more efficiently.
						</span>
					</p>
				</li>
			</ul>
			<p style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>Our Vision</span>
				</strong>
			</p>

			<p style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					Our vision at Timetrail Guard is to revolutionize the way people manage their time. We are
					constantly exploring new ways to enhance our products and services, ensuring that we stay at the
					forefront of technological innovation. Our goal is to provide tools that not only meet but exceed
					our {`users'`} expectations, helping them to work smarter and achieve more.
				</span>
			</p>

			<p style={{ textAlign: "justify" }}>
				<strong>
					<span style={{ fontSize: "11pt" }}>Join Us on Our Journey</span>
				</strong>
			</p>

			<p style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					We are excited to have you join us on our journey towards greater productivity and efficiency.
					Whether you are a seasoned professional looking to optimize your workflow or a team seeking better
					coordination and time management, Timetrail Guard is here to support you every step of the way.
				</span>
			</p>

			<p style={{ textAlign: "justify" }}>
				<span style={{ fontSize: "11pt" }}>
					Thank you for choosing Timetrail Guard. Together, {`let's`} make every second count!
				</span>
			</p>
		</div>
	)
}

export default AboutUs
