import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EmployeeWorkHistoryInterface } from "@type/employees.types"
import { employeeWorkHistoryService } from "services"
import { AppThunk, RootState } from "store"

export interface EmployeeWorkHistoryStateInterface {
	data: EmployeeWorkHistoryInterface[]
	loading?: boolean
}

const initialState: EmployeeWorkHistoryStateInterface = {
	data: [],
	loading: false,
}

const employeeWorkHistorySlice = createSlice({
	name: "employeeWorkHistory",
	initialState,
	reducers: {
		requestWorkHistoryData: workHistory => {
			workHistory.loading = true
		},
		receiveWorkHistoryData: (workHistory, action: PayloadAction<EmployeeWorkHistoryInterface[]>) => {
			workHistory.loading = false
			const list = action.payload
			workHistory.data = list?.sort((a, b) =>
				a?.location?.toLowerCase().localeCompare(b?.location?.toLowerCase()),
			)
		},
		receiveWorkHistoryDataFailed: workHistory => {
			workHistory.loading = false
		},
	},
})

//REDUCER
export default employeeWorkHistorySlice.reducer

//ACTIONS
const { requestWorkHistoryData, receiveWorkHistoryData, receiveWorkHistoryDataFailed } =
	employeeWorkHistorySlice.actions

const getEmployeesWorkHistoryByEmployeeId =
	(employeeId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestWorkHistoryData())
			const { data: WorkHistoryResponse } = await employeeWorkHistoryService.getEmployeeWorkHistoryByEmployeeID(
				employeeId,
			)
			dispatch(receiveWorkHistoryData(WorkHistoryResponse))
		} catch (error) {
			dispatch(receiveWorkHistoryDataFailed())
		}
	}

export { getEmployeesWorkHistoryByEmployeeId }

//SELECTORS
const selectEmployeeWorkHistoryState = (state: RootState) => state.employee.workHistory
const isEmployeeWorkHistoryLoading = () => (state: RootState) => selectEmployeeWorkHistoryState(state).loading
const selectEmployeeWorkHistoryData = () => (state: RootState) => selectEmployeeWorkHistoryState(state).data || []

export { isEmployeeWorkHistoryLoading, selectEmployeeWorkHistoryData }
