import React from "react"

const ScheduleTableSkeletonEmployee = () => {
	return (
		<div>
			<div className="flex animate-pulse gap-1 px-2.5 py-2.5">
				<div className="w-1/6 flex-col justify-around space-y-1 rounded-md border-1 bg-secondary-dark px-1.25 py-1.25">
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
				</div>
				<div className="w-1/6 flex-col justify-around space-y-1  py-1.25">
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
				</div>
				<div className="w-1/6 flex-col justify-around space-y-1  py-1.25">
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
				</div>
				<div className="w-1/6 flex-col justify-around space-y-1  py-1.25">
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
				</div>
				<div className="w-1/6 flex-col justify-around space-y-1  py-1.25">
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
				</div>
				<div className="w-1/6 flex-col justify-around space-y-1  py-1.25">
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24  rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-24 rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
				</div>
			</div>
		</div>
	)
}

export default ScheduleTableSkeletonEmployee
