import axios from "axios"
import { CreateIncidentCommentDto, IncidentCommentInterface } from "@type/workMonitor.types"

const ROOT_PATH = "/incident-comments"

export default class IncidentComments {
	static getAllByIncidentId = (incidentId: string) =>
		axios.get<IncidentCommentInterface[]>(`${ROOT_PATH}/incident/${incidentId}`)

	static create = (body: CreateIncidentCommentDto) => axios.post(ROOT_PATH, body)
}
