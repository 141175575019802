import axios from "axios"
import { GetSDCountiesInterface, SDCountiesInterface } from "@type/staticData.types"

const ROOT_PATH = "static-data/county"
export default class SDCountiesService {
	static createSDCounties = (counties: SDCountiesInterface) => axios.post<GetSDCountiesInterface>(ROOT_PATH, counties)

	static getAllSDCounties = () => axios.get<Array<GetSDCountiesInterface>>(`${ROOT_PATH}`)

	static getSDCountiesById = (id: string) => axios.get<GetSDCountiesInterface>(`${ROOT_PATH}/${id}`)

	static updateSDCounties = (id: string, counties: Partial<GetSDCountiesInterface>) =>
		axios.put<GetSDCountiesInterface>(`${ROOT_PATH}/${id}`, counties)
}
