import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
	GetPublicHolidaysInterface,
	CreatePublicHolidaysInterface,
	UpdatePublicHolidaysInterface,
} from "@type/payBill.types"
import { publicHolidaysService } from "services"
import { AppThunk, RootState } from "store"
import { sortByProperty, sortDeepCopyByProperty, updateState } from "config/utils"
export interface publicHolidaysStateInterface {
	list: null | Array<GetPublicHolidaysInterface>
	loading: boolean
	saving: boolean
}

const initialState: publicHolidaysStateInterface = {
	list: null,
	loading: false,
	saving: false,
}

const publicHolidaysSlice = createSlice({
	name: "publicHolidays",
	initialState,
	reducers: {
		fetchingPublicHolidays: publicHolidays => {
			publicHolidays.loading = true
		},
		publicHolidaysFetched: (publicHolidays, action: PayloadAction<GetPublicHolidaysInterface[]>) => {
			publicHolidays.loading = false
			publicHolidays.list = action.payload
		},
		publicHolidaysFetchingFailed: publicHolidays => {
			publicHolidays.loading = false
		},
		savingPublicHolidays: publicHolidaysData => {
			publicHolidaysData.saving = true
		},
		publicHolidaysSaved: (publicHolidays, action: PayloadAction<GetPublicHolidaysInterface>) => {
			publicHolidays.saving = false
			const [UpdatedValue] = updateState(publicHolidays.list, action.payload, "_id")
			publicHolidays.list = UpdatedValue
		},
		publicHolidaysSavingFailed: publicHolidays => {
			publicHolidays.saving = false
		},
	},
})

//REDUCER
export default publicHolidaysSlice.reducer

//ACTIONS
const {
	fetchingPublicHolidays,
	publicHolidaysFetched,
	publicHolidaysFetchingFailed,
	savingPublicHolidays,
	publicHolidaysSaved,
	publicHolidaysSavingFailed,
} = publicHolidaysSlice.actions

const getPublicHolidays =
	(cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingPublicHolidays())
			const { data: publicHolidaysResponse } = await publicHolidaysService.getAllPublicHolidays()
			publicHolidaysResponse.sort(sortByProperty("name"))
			cb && cb(publicHolidaysResponse[0]?._id)
			dispatch(publicHolidaysFetched(publicHolidaysResponse))
		} catch (error) {
			dispatch(publicHolidaysFetchingFailed())
		}
	}

const savePublicHolidays =
	(publicHolidaysData: Partial<UpdatePublicHolidaysInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingPublicHolidays())
			if (publicHolidaysData?._id)
				data = await publicHolidaysService.updatePublicHolidays(publicHolidaysData._id, publicHolidaysData)
			else
				data = await publicHolidaysService.createPublicHolidays(
					publicHolidaysData as CreatePublicHolidaysInterface,
				)
			const { data: publicHolidaysResponse } = data
			cb && cb(publicHolidaysResponse._id)
			dispatch(publicHolidaysSaved(publicHolidaysResponse))
		} catch (error) {
			dispatch(publicHolidaysSavingFailed())
		}
	}

export { getPublicHolidays, savePublicHolidays }

//SELECTORS
const selectPublicHolidaysState = (state: RootState) => state.payBill.publicHolidays
const isPublicHolidaysLoading = () => (state: RootState) => selectPublicHolidaysState(state).loading
const isPublicHolidaysSaving = () => (state: RootState) => selectPublicHolidaysState(state).saving
const selectPublicHolidaysList = createSelector(
	(state: RootState) => state.payBill.publicHolidays.list,
	list => {
		return sortDeepCopyByProperty(list, "name") as GetPublicHolidaysInterface[]
	},
)
const selectPublicHolidaysDropdownList = createSelector(
	(state: RootState) => state.payBill.publicHolidays.list,
	list => {
		return sortDeepCopyByProperty(list?.map(item => ({ label: item.name, value: item._id })) || [], "label")
	},
)

const selectPublicHolidaysById = (PublicHolidaysId: string | null) => (state: RootState) =>
	state.payBill.publicHolidays?.list?.find(publicHolidays => publicHolidays._id === PublicHolidaysId)

export {
	selectPublicHolidaysList,
	selectPublicHolidaysDropdownList,
	selectPublicHolidaysState,
	selectPublicHolidaysById,
	isPublicHolidaysLoading,
	isPublicHolidaysSaving,
}
