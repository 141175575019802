import { createContext } from "react"
import { LocationDocumentInterface } from "@type/locations.types"

export interface DocumentContextProps {
	handleRow: (e: LocationDocumentInterface | null) => void
	document: LocationDocumentInterface | undefined
}

const DocumentContext = createContext<DocumentContextProps | null>(null)

export default DocumentContext
