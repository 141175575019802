import React, { useContext, useEffect } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAppDispatch, useAppSelector } from "hooks"
import { DateField, FormSelectAntd, AntdButton } from "components"
import { saveEmployeeTermData, selectEmployeeTermSaving } from "store/Employee/Terms/terms.slice"
import TermContext, { TermContextProps } from "./termsContext"
import { termsInitialValues, termsValidationSchema } from "Schema"
import { EmployeeTermInterface } from "@type/employees.types"
import { AllowedTo } from "react-abac"
import { Permissions } from "config/constants"
import { selectSelectedEmployee } from "store/globalState.slice"
import { Typography } from "antd"

interface TermsFormHandler extends EmployeeTermInterface {}

enum Terms {
	SelfEmployed = "SELF_EMPLOYED",
	PartTime = "PART_TIME",
	FullTime = "FULL_TIME",
}

const terms = [
	{ name: "Self Employed", _id: Terms.SelfEmployed },
	{ name: "Part Time", _id: Terms.PartTime },
	{ name: "Full Time", _id: Terms.FullTime },
]

const Form = () => {
	const dispatch = useAppDispatch()
	const isTermsSaving = useAppSelector(selectEmployeeTermSaving())

	const selected = useAppSelector(selectSelectedEmployee)
	const { term, handleRow } = useContext(TermContext) as TermContextProps

	const methods = useForm<TermsFormHandler>({
		resolver: yupResolver(termsValidationSchema),
	})
	const {
		reset,
		handleSubmit,
		setValue,
		watch,
		formState: { isDirty, isSubmitting },
	} = methods
	const handleClear = (e: React.MouseEvent) => {
		e.preventDefault()
		handleRow(null)
		reset(termsInitialValues)
	}

	useEffect(() => {
		term ? reset({ ...term }) : reset(termsInitialValues)
	}, [term?._id])

	const onSubmit: SubmitHandler<TermsFormHandler> = data => {
		dispatch(saveEmployeeTermData({ ...data, employee: selected as string }))
	}

	return (
		<>
			{/* <div className="ml-2 flex flex-col py-2 empty:hidden">
				<AuditTrail id={term?._id} name={Entities.EMPLOYEE_TERM} />
			</div> */}
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Typography.Title level={5}> Create/Update Term</Typography.Title>
					<div className="flex flex-col gap-y-3">
						<DateField label="Start Date" name={"startDate"} setValue={setValue} watch={watch} />
						<FormSelectAntd
							disabled={!terms.length}
							label="Terms"
							name="terms"
							options={terms.map(({ _id, name }) => ({ label: name, value: _id }))}
						/>

						<AllowedTo perform={Permissions.UPDATE_EMPLOYEE_TERMS}>
							<div className="flex gap-x-2">
								<AntdButton className="min-w" onClick={handleClear}>
									Clear
								</AntdButton>
								<AntdButton
									className="min-w"
									loading={isTermsSaving}
									disabled={!isDirty || isSubmitting}
									htmlType="submit"
								>
									{term ? "Update" : "Save"}
								</AntdButton>
							</div>
						</AllowedTo>
					</div>
				</form>
			</FormProvider>
		</>
	)
}

export default Form
