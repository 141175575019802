import * as Yup from "yup"

export const SDEquipmentTypeInitialValues = {
	description: "",
	allocatedToEmployee: false,
	allocatedToLocation: false,
}

export const SDEquipmentTypeSchema = Yup.object().shape({
	description: Yup.string().required().label("Description"),
	allocatedToEmployee: Yup.boolean().required().label("Allocate to Employee"),
	allocatedToLocation: Yup.boolean().required().label("Allocate to Location"),
})
