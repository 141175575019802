import React from "react"
import cx from "classnames"
import { twMerge } from "tailwind-merge"
interface RenderProps {
	row: any
	key: string
}

export interface TableColumnProps {
	label: string
	key: string
	keyForSorting?: string
}
export interface TableColumnExtendedProps extends TableColumnProps {
	render?: (data: RenderProps) => React.ReactNode
	headerRender?: (data: TableColumnProps) => React.ReactNode
	className?: string
}
export interface AuditTableProps {
	columns: TableColumnExtendedProps[]
	rows: any[] | any
	onRowClick?: (id: string) => void
	selectRow?: boolean
	selectedRowColor?: string
	selectedRows?: string[]
	rowClassName?: string
	headerClassName?: string
	headerRowClassName?: string
	tableClassName?: string
	checkInvalidRow?(row: any): boolean | undefined
	invalidRowColor?: string
	sortingEnabled?: boolean
	multiColumnSortingEnabled?: boolean
	renderAllRowKey?: string
}
const AuditTrailTable: React.FC<AuditTableProps> = ({
	columns,
	rows,
	onRowClick = () => undefined,
	selectRow = false,
	selectedRowColor = "#c4e5f8",
	selectedRows,
	rowClassName = "",
	headerClassName = "",
	headerRowClassName = "",
	tableClassName = "",
	checkInvalidRow = () => false,
	invalidRowColor = "#FF2400",
	renderAllRowKey = "",
}) => {
	return (
		<table className={twMerge("whitespace-no-wrap w-full table-auto text-left", tableClassName)}>
			<thead className="sticky top-0 z-10 text-sm">
				<tr className={twMerge("bg-gray-3 h-9 text-left", headerRowClassName)}>
					{columns.map(({ label, key, keyForSorting = "", headerRender }, colIndex) => {
						const thClassName = cx({
							" bg-background px-2.5 py-1.25 text-sm font-semibold text-dark-alt text-left font-medium":
								true,
							"rounded-tl-md": colIndex === 0,
							"rounded-tr-2.5": colIndex === columns.length - 1,
						})

						return (
							<th key={colIndex} className={`${twMerge(thClassName)} ${headerClassName}`}>
								{headerRender && key ? headerRender({ label, key, keyForSorting }) : label}
							</th>
						)
					})}
				</tr>
			</thead>
			<tbody>
				{rows?.length ? (
					rows.map((row: any, rowIndex: any) => {
						return !row[renderAllRowKey] ? (
							<tr
								key={rowIndex}
								onClick={() => onRowClick(row._id)}
								className={twMerge(
									`border-gray-4 h-11 border-t-0.125 border-b border-solid text-sm odd:bg-white even:bg-slate-50`,
									rowClassName,
								)}
								style={{
									...(selectRow && !checkInvalidRow(row) && selectedRows?.includes(row._id)
										? { backgroundColor: selectedRowColor }
										: checkInvalidRow(row)
										? { backgroundColor: invalidRowColor }
										: {}),
								}}
							>
								{columns.map(({ key, render, className }, colIndex) => {
									return (
										<td key={colIndex + rowIndex} className={twMerge("px-2 text-left", className)}>
											{render ? render({ row, key }) : row[key]}
										</td>
									)
								})}
							</tr>
						) : (
							<tr
								key={row[renderAllRowKey]}
								className={twMerge(
									`border-gray-4 h-8 border-t-0.125 border-b border-solid text-sm text-orange-400 odd:bg-white even:bg-slate-50`,
									rowClassName,
								)}
							>
								<td colSpan={columns.length}>{row[renderAllRowKey]}</td>
							</tr>
						)
					})
				) : (
					<tr>
						<th colSpan={12} className="  p-3 text-center text-dark-alt">
							No Data
						</th>
					</tr>
				)}
			</tbody>
		</table>
	)
}

export default AuditTrailTable
