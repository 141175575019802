import React, { useEffect, useMemo } from "react"
import { useAppSelector, useAppDispatch } from "hooks"
import { Table2, TableSkeleton } from "components"
import {
	getEmployeesWorkHistoryByEmployeeId,
	selectEmployeeWorkHistoryData,
	isEmployeeWorkHistoryLoading,
} from "store/Employee/Location/employeeWorkHistory.slice"
import { convertIOSDateToHTMLFormat } from "config/utils"
import { GLOBAL_DATE_FORMAT } from "config/constants"
import { EmployeeWorkHistoryInterface } from "@type/employees.types"
import { SelectedPropInterface } from "services/generic/type"
import { round } from "lodash"

const EmployeeWorkHistory: React.FC<SelectedPropInterface> = ({ selected }) => {
	const employeeWorkHistory = useAppSelector(selectEmployeeWorkHistoryData())
	const employeeWorkHistoryLoading = useAppSelector(isEmployeeWorkHistoryLoading())
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (selected) dispatch(getEmployeesWorkHistoryByEmployeeId(selected))
	}, [selected])

	const [totalHours, totalShifts] = useMemo(() => {
		if (!employeeWorkHistoryLoading && employeeWorkHistory.length) {
			return [
				employeeWorkHistory.reduce(
					(prev: number, { hoursWorked }: EmployeeWorkHistoryInterface) => prev + hoursWorked,
					0,
				),
				employeeWorkHistory.reduce(
					(prev: number, { shiftsWorked }: EmployeeWorkHistoryInterface) => prev + shiftsWorked,
					0,
				),
			]
		}
		return [0, 0]
	}, [employeeWorkHistory])

	return (
		<div>
			<div className="py-4 text-xl">Previous Work History</div>
			{!employeeWorkHistoryLoading ? (
				<div className="w-full space-y-4">
					<div className="h-[50vh] w-full overflow-y-auto">
						<Table2
							headerRowClassName="sticky top-0"
							rows={employeeWorkHistory}
							columns={[
								{
									label: "Premises",
									key: "location",
									render: ({ row, key }) => <div> {row[key]}</div>,
								},
								{
									label: "First Date Worked",
									key: "firstDateWorked",
									render: ({ row, key }) => convertIOSDateToHTMLFormat(row[key], GLOBAL_DATE_FORMAT),
								},
								{
									label: "Last Date Worked",
									key: "lastDateWorked",
									render: ({ row, key }) => convertIOSDateToHTMLFormat(row[key], GLOBAL_DATE_FORMAT),
								},
								{
									label: "Hours Worked",
									key: "hoursWorked",
									render: ({ row, key }) => {
										return <div> {row[key].toFixed(2)}</div>
									},
								},
								{
									label: "Shifts Worked",
									key: "shiftsWorked",
									render: ({ row, key }) => {
										return <div> {row[key].toFixed(2)}</div>
									},
								},
							]}
						/>
					</div>
					<div className="flex justify-end space-x-12">
						<span>Total Hours: {round(totalHours, 2)}</span>
						<span>Total Shifts: {totalShifts}</span>
					</div>
				</div>
			) : (
				<div className="p w-2/3">
					<TableSkeleton />
				</div>
			)}
		</div>
	)
}

export default EmployeeWorkHistory
