import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EmployeeEquipmentSizesInterface } from "@type/employees.types"
import { employeeEquipmentSizesService } from "services"
import { AppThunk, RootState } from "store"

export interface SizesStateInterface {
	data: null | EmployeeEquipmentSizesInterface
	loading: boolean
	saving: boolean
}

const initialState: SizesStateInterface = {
	data: null,
	loading: false,
	saving: false,
}

const sizesSlice = createSlice({
	name: "sizes",
	initialState,
	reducers: {
		requestSizesData: sizes => {
			sizes.loading = true
		},
		receiveSizesData: (sizes, action: PayloadAction<EmployeeEquipmentSizesInterface>) => {
			sizes.loading = false
			if (typeof action.payload == "string") {
				sizes.data = null
				return
			}
			sizes.data = action.payload
		},
		receiveSizesDataFailed: sizes => {
			sizes.loading = false
		},
		requestSavingSizesData: sizes => {
			sizes.saving = true
		},
		SavingEmployeeSizesSuccess: (sizes, action: PayloadAction<EmployeeEquipmentSizesInterface>) => {
			sizes.saving = false
			sizes.data = action.payload
		},
		SavingEmployeeSizesSuccessFail: sizes => {
			sizes.saving = false
		},
	},
})

//REDUCER
export default sizesSlice.reducer

//ACTIONS
const {
	requestSizesData,
	receiveSizesData,
	receiveSizesDataFailed,
	requestSavingSizesData,
	SavingEmployeeSizesSuccess,
	SavingEmployeeSizesSuccessFail,
} = sizesSlice.actions

const getEmployeesSizesByEmployeeId =
	(employeeId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestSizesData())
			const { data: equipmentResponse } =
				await employeeEquipmentSizesService.getEmployeeEquipmentSizesByEmployeeId(employeeId)
			dispatch(receiveSizesData(equipmentResponse))
		} catch (error) {
			dispatch(receiveSizesDataFailed())
		}
	}

const saveEmployeeSizesData =
	(sizesData: EmployeeEquipmentSizesInterface): AppThunk =>
	async dispatch => {
		try {
			let data = null
			dispatch(requestSavingSizesData())
			if (sizesData._id) {
				data = await employeeEquipmentSizesService.updateEmployeeEquipmentSizes(sizesData._id, sizesData)
			} else {
				data = await employeeEquipmentSizesService.createEmployeeEquipmentSizes(sizesData)
			}
			const { data: equipmentResponse } = data
			dispatch(SavingEmployeeSizesSuccess(equipmentResponse))
		} catch (error) {
			dispatch(SavingEmployeeSizesSuccessFail())
		}
	}

export { saveEmployeeSizesData, getEmployeesSizesByEmployeeId }

//SELECTORS
const selectEmployeeSizesState = (state: RootState) => state.employee.sizes
const isEmployeeSizesLoading = () => (state: RootState) => selectEmployeeSizesState(state).loading
const selectEmployeeSizesData = () => (state: RootState) => selectEmployeeSizesState(state).data
const selectEmployeeSizesSaving = () => (state: RootState) => selectEmployeeSizesState(state).saving

export { selectEmployeeSizesData, selectEmployeeSizesSaving, isEmployeeSizesLoading }
