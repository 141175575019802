import React, { useContext, useEffect, useState } from "react"
import { useAppSelector, useAppDispatch } from "hooks"
import { yupResolver } from "@hookform/resolvers/yup"
import { AntdButton, FormInputAntd } from "components"
import { employeeDocumentsInitialValues, employeeDocumentValidationSchema } from "Schema"
import { EmployeeDocumentInterface } from "@type/employees.types"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { saveEmployeeDocumentData } from "store/Employee/Documents/documents.slice"
import DocumentContext, { DocumentContextProps } from "./documentContext"
import { selectSelectedEmployee } from "store/globalState.slice"
import { Upload, UploadProps } from "antd-v5"
import { UploadOutlined } from "@ant-design/icons"
import { UploadFile } from "antd-v5/es/upload"
import { mediaService } from "services/index"

interface DocumentFormHandle extends EmployeeDocumentInterface {}

const Form: React.FC = () => {
	const dispatch = useAppDispatch()
	const selected = useAppSelector(selectSelectedEmployee)
	const { document, handleRow } = useContext(DocumentContext) as DocumentContextProps

	const [fileList, setFileList] = useState<UploadFile[]>([])
	const [uploading, setUploading] = useState(false)

	const methods = useForm<DocumentFormHandle>({
		resolver: yupResolver(employeeDocumentValidationSchema),
	})
	const {
		reset,
		setValue,
		handleSubmit,
		formState: { isDirty, isSubmitting },
	} = methods

	const onSubmit: SubmitHandler<DocumentFormHandle> = async data => {
		setUploading(true)
		let documentPathS3 = data.link
		if (fileList[0]) {
			documentPathS3 = (await mediaService.upload(fileList[0].originFileObj as File)).data.url[0]
			setValue("link", documentPathS3)
		}
		dispatch(saveEmployeeDocumentData({ ...data, link: documentPathS3, employee: selected as string }, handleClear))
		setUploading(false)
	}
	const handleClear = () => {
		setFileList([])
		handleRow(null)
		reset(employeeDocumentsInitialValues)
	}

	useEffect(() => {
		if (document) reset(document)
		else reset(employeeDocumentsInitialValues)
	}, [document?._id])

	const props: UploadProps = {
		beforeUpload: () => false,
		onRemove: () => {
			setFileList([])
			setValue("link", "")
			return false
		},
		onChange: ({ fileList: newFileList }) => {
			const file = newFileList[newFileList.length - 1]
			setFileList([file])
			setValue("link", file.name)
		},
	}
	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="flex flex-col gap-y-3">
					<div className="flex w-full flex-col gap-y-3">
						<FormInputAntd type="text" label="Title" name="title" />
						<FormInputAntd type="text" label="Description" name="description" />
						<div className="w-fit max-w-full">
							<Upload {...props} fileList={fileList} maxCount={1} accept="image/*,.pdf,.docx">
								<AntdButton
									className="flex items-center justify-center"
									icon={<UploadOutlined />}
									htmlType="button"
								>
									{document?.link ? "Update" : "Add"} Document
								</AntdButton>
							</Upload>
						</div>
					</div>
					<div className="flex gap-x-2">
						<AntdButton onClick={handleClear}>Clear</AntdButton>
						<AntdButton
							disabled={!isDirty || isSubmitting || uploading}
							loading={uploading || isSubmitting}
							htmlType="submit"
						>
							{document ? "Update" : "Save"}
						</AntdButton>
					</div>
				</div>
			</form>
		</FormProvider>
	)
}

export default Form
