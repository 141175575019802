import axios from "axios"
import { CreateLocationCallTimeInterface, GetLocationCallTimeInterface } from "@type/locations.types"

const ROOT_PATH = "locations/call-time"
export default class LocationCallTimeService {
	static createCallTime = (callTimes: CreateLocationCallTimeInterface) => {
		return axios.post<GetLocationCallTimeInterface[]>(ROOT_PATH, callTimes)
	}

	static getPostCallTimes = (postId: string) =>
		axios.get<Array<GetLocationCallTimeInterface>>(`${ROOT_PATH}/${postId}`)

	static deleteCallTimes = (ids: string[]) =>
		axios.delete<GetLocationCallTimeInterface>(`${ROOT_PATH}`, { data: { ids } })
}
