import React from "react"
import { Button, ButtonProps } from "antd-v5"
import { twMerge } from "tailwind-merge"

interface Props extends ButtonProps {}

const AntdButton: React.FC<Props> = ({ children, className, ...rest }) => {
	return (
		<Button className={twMerge(`flex items-center justify-center`, className)} {...rest}>
			{children}
		</Button>
	)
}

export default AntdButton
