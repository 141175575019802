import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PayRollTermsInterface } from "@type/payBill.types"
import { AppThunk, RootState } from "store"
import { sortDeepCopyByProperty, updateState } from "config/utils"
import { PayRollService } from "services/payBill"

export interface PayRollTermsStateInterface {
	list: null | Array<PayRollTermsInterface>
	loading: boolean
	saving: boolean
}

const initialState: PayRollTermsStateInterface = {
	list: null,
	loading: false,
	saving: false,
}

const payrollTermsSlice = createSlice({
	name: "payRoll",
	initialState,
	reducers: {
		fetchingPayrollTerms: payrollTerm => {
			payrollTerm.loading = true
		},
		payrollTermsFetched: (payrollTerm, action: PayloadAction<PayRollTermsInterface[]>) => {
			payrollTerm.loading = false
			payrollTerm.list = action.payload
		},
		payrollTermsFetchingFailed: payrollTerm => {
			payrollTerm.loading = false
		},
		savingPayrollTerm: payrollTermData => {
			payrollTermData.saving = true
		},
		payrollTermSaved: (payrollTerm, action: PayloadAction<PayRollTermsInterface>) => {
			payrollTerm.saving = false
			const [UpdatedValue] = updateState(payrollTerm.list, action.payload, "_id")
			payrollTerm.list = UpdatedValue
		},
		payrollTermSavingFailed: payrollTerm => {
			payrollTerm.saving = false
		},
	},
})

//REDUCER
export default payrollTermsSlice.reducer

//ACTIONS
const { fetchingPayrollTerms, payrollTermsFetched, payrollTermsFetchingFailed } = payrollTermsSlice.actions

const getPayrollTerms = (): AppThunk => async dispatch => {
	try {
		dispatch(fetchingPayrollTerms())
		const { data: payrollTermResponse } = await PayRollService.getAllPayroll()
		dispatch(payrollTermsFetched(payrollTermResponse))
	} catch (error) {
		dispatch(payrollTermsFetchingFailed())
	}
}

export { getPayrollTerms }

//SELECTORS
const selectPayrollTermState = (state: RootState) => state.payBill.payRoll
const isPayrollTermLoading = () => (state: RootState) => selectPayrollTermState(state).loading
const isPayrollTermSaving = () => (state: RootState) => selectPayrollTermState(state).saving
const selectPayrollTermList = createSelector(
	(state: RootState) => state.payBill.payRoll.list,
	list => sortDeepCopyByProperty(list, "payRoll") as PayRollTermsInterface[],
)

export { selectPayrollTermList, selectPayrollTermState, isPayrollTermLoading, isPayrollTermSaving }
