import axios from "axios"
import { CreateLocationsEscalationsInterface, GetLocationEscalationsInterface } from "@type/locations.types"

const ROOT_PATH = "locations/escalations"
export default class LocationsEscalationsService {
	static createLocationEscalations = (locationEscalations: CreateLocationsEscalationsInterface) =>
		axios.post<GetLocationEscalationsInterface>(ROOT_PATH, locationEscalations)

	static getAllLocationsEscalations = () => axios.get<Array<GetLocationEscalationsInterface>>(`${ROOT_PATH}`)

	static getLocationEscalationsByLocationId = (id: string) =>
		axios.get<GetLocationEscalationsInterface[]>(`${ROOT_PATH}/?location=${id}`)

	static updateLocationEscalations = (id: string, locationEscalations: Partial<GetLocationEscalationsInterface>) =>
		axios.put<GetLocationEscalationsInterface>(`${ROOT_PATH}/${id}`, locationEscalations)
}
