import { selectFeatureFlags, selectFeatureFlagsLoading } from "store/featureFlags.slice"
import { useSelector } from "react-redux"

const useFeatureFlags = () => {
	const features = useSelector(selectFeatureFlags)
	const featureFlagsLoading = useSelector(selectFeatureFlagsLoading)
	return {
		featureFlagEnabled: (name: string) => {
			const featureFlag = features[name]
			if (featureFlag === undefined) return false
			return featureFlag
		},
		featureFlagsLoading,
	}
}
export default useFeatureFlags
