export enum CallTimeDays {
	MONDAY = "Monday",
	TUESDAY = "Tuesday",
	WEDNESDAY = "Wednesday",
	THURSDAY = "Thursday",
	FRIDAY = "Friday",
	SATURDAY = "Saturday",
	SUNDAY = "Sunday",
	PH = "PH",
}

export const CallTimeDaysArray = Object.values(CallTimeDays)
