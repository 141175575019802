import { Loading } from "components"
import clsx from "clsx"
import { twMerge } from "tailwind-merge"
import { AutoSizer as _AutoSizer, List as _List, ListProps, AutoSizerProps } from "react-virtualized"
import { Tooltip } from "antd-v5"
import useHeightResize from "hooks/useHeightResize"

const List = _List as unknown as React.FC<ListProps>
const AutoSizer = _AutoSizer as unknown as React.FC<AutoSizerProps>
interface Props {
	list: Array<{ [key: string]: string }>
	setSelected: (_id: string) => void
	selected?: string | null
	loading?: boolean
	title?: string
	overRideClassName?: string
	disableClick?: boolean
}

const SidebarList: React.FC<Props> = ({ list = [], setSelected, selected, loading = false, disableClick }) => {
	useHeightResize({ element: "sidebar-list" })
	if (loading) return <Loading />

	const selectedIndex = list.findIndex(item => item._id === selected)
	return (
		<div className="mb-5 flex h-full flex-1" id={"sidebar-list"} style={{ width: "100%" }}>
			<AutoSizer>
				{({ width, height }) => (
					<List
						className="scrollbar-thin  scrollbar-thumb-gray-400 scrollbar-track-dark hover:scrollbar-thumb-gray-500"
						width={width}
						height={height}
						rowHeight={30}
						rowCount={list?.length || 0}
						scrollToIndex={selectedIndex}
						rowRenderer={({ key, index, style }) => {
							const listItem = list[index]
							const className = twMerge(
								clsx(
									"text-md text-zinc-300 font-extralight cursor-pointer hover:text-white w-full truncate",
									{
										"text-white font-semibold": selected === listItem._id,
										"text-amber-400": "isActive" in listItem && !listItem.isActive,
										"text-amber-400 font-semibold hover:text-text-amber-400":
											"isActive" in listItem && !listItem.isActive && selected === listItem._id,
										"pointer-events-none ": disableClick,
									},
								),
							)
							return (
								<div
									key={key}
									className={className}
									style={style}
									onClick={() => setSelected(listItem._id)}
								>
									<Tooltip placement="right" title={listItem.name || "Unnamed"}>
										{listItem.name || "Unnamed"}
									</Tooltip>
								</div>
							)
						}}
					/>
				)}
			</AutoSizer>
		</div>
	)
}

export default SidebarList
