import axios from "axios"
import {
	DistanceMatrixPayloadInterface,
	GetDistanceMatrixResponseInterface,
	GetGeocodeInterface,
	NearbyLocationEmployeesActionInterface,
} from "@type/geolocation.types"

const ROOT_PATH = "geolocation"

export default class GeolocationService {
	static getLatLng = (address: string) =>
		axios.get<GetGeocodeInterface>(`${ROOT_PATH}/postal-code-position/${address}`)

	static getDistance = (coordinates: DistanceMatrixPayloadInterface) =>
		axios.post<GetDistanceMatrixResponseInterface>(`${ROOT_PATH}/distance`, coordinates)

	static getNearbyLocationEmployees = (locationId: string, employeeType: string) =>
		axios.get<Array<NearbyLocationEmployeesActionInterface>>(
			`${ROOT_PATH}/nearby-employees/${locationId}/${employeeType}`,
		)
}
