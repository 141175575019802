import { MomentInput } from "moment-timezone"
import { momentZ } from "config/moment"
import moment, { Moment } from "moment"
import { AppRoutes } from "config"
import { FieldError, FieldErrors } from "react-hook-form"
import { DATE_FORMATS, DashboardDateFilter, GLOBAL_TIME_FORMAT, Time, getWeekDayIndex } from "./constants"
import { cloneDeep, round } from "lodash"
import GenericService from "services/generic/generic.service"
const { camelCase, startCase, lowerCase, upperCase, kebabCase, snakeCase, upperFirst } = require("lodash")
import { EventValue } from "rc-picker/lib/interface"

export const caseConverters = {
	pascalCase: (str: string) => startCase(camelCase(str)).replace(/ /g, ""),
	titleCase: (str: string) => startCase(camelCase(str)),
	pathCase: (str: string) => lowerCase(str).replace(/ /g, "/"),
	constantCase: (str: string) => upperCase(str).replace(/ /g, "_"),
	sentenceCase: (str: string) => upperFirst(lowerCase(str)),
	camelCase: camelCase,
	snakeCase: snakeCase,
	kebabCase: kebabCase,
}

export const convertDateTimeStringToIsoUtc = (dateTime: string, format?: string) => {
	return momentZ(moment(dateTime, format ? format : DATE_FORMATS.DD_MM_YYYY_HH_MM).format())
		.utc()
		.toISOString()
}

export const getMomentZInstanceOfDate = (date: string | Date = new Date(), format?: string) => {
	return format ? momentZ(date, format) : momentZ(date)
}

export const isDateSameOrBetween = (currentDate: string, checkStartDate: string, checkFinishDate: string) => {
	const currentDateMoment = moment(currentDate, DATE_FORMATS.DD_MM_YYYY)
	return (
		currentDateMoment.isSameOrAfter(moment(checkStartDate, DATE_FORMATS.DD_MM_YYYY)) &&
		currentDateMoment.isSameOrBefore(moment(checkFinishDate, DATE_FORMATS.DD_MM_YYYY))
	)
}

export const convertIOSDateToHTMLFormat = (date: string | moment.Moment, format?: string) =>
	momentZ(date as unknown as MomentInput)
		.clone()
		.format(format ? format : DATE_FORMATS.YYYY_MM_DD)
export const convertIOSDateToHTMLFormatTime = (date: string) => momentZ(date).clone().format(DATE_FORMATS.HH_mm)
export const convertIOSDateToStandardTime = (date: string) => momentZ(date).clone().toISOString()
export const convertIOSDateToSpecificFormat = (date: string | moment.Moment, format: string) =>
	moment(date).clone().format(format)

export const convertIOSTZDateToSpecificUTCFormat = (date: string | moment.Moment, format: string) =>
	momentZ(date as MomentInput)
		.clone()
		.utc()
		.format(format)

export const convertIOSDateTZToSpecificFormat = (date: string | moment.Moment, format: string) => {
	return momentZ(date as MomentInput)
		.clone()
		.format(format)
}
export const convertDateToStartTimeUTC = (date: string | moment.Moment) =>
	momentZ(`${date} 00:00.000Z`, "YYYY-MM-DDTHH:mm:ss.SSSZ").utc().format()

export const convertDateToFinishTimeUTC = (date: string | moment.Moment) =>
	momentZ(`${date} 23:59.000Z`, "YYYY-MM-DDTHH:mm:ss.SSSZ").utc().format()

export const concatenateDateTimeToUTC = (date: string | moment.Moment, time: string) =>
	moment(`${date} ${time}`).utc().format()
export const convertDateTimeToUTC = (date: moment.Moment) => momentZ(date as MomentInput).utc()

export const addPathToDocument = async (path: string) => {
	try {
		const { data } = await GenericService.getSignedUrl(path)
		return data.url
	} catch (error) {
		return ""
	}
}

export const getTimeInMilliSeconds = (time: string) => +moment(`${Time.EPOCHTIME}T${time}`).format("x")

export const getDateTimeInMilliSeconds = (DateTime: string): number => new Date(DateTime).getTime()

export const convertUTCTimeStringToHTMLFormat = (time: string) =>
	momentZ(new Date(`${Time.EPOCHTIME}T${time}Z`)).format(GLOBAL_TIME_FORMAT) as unknown as string

export const convertDateTimeToTime = (time: string) => momentZ(time).format(GLOBAL_TIME_FORMAT).toString()

export const getTimeInUtc = (time: string): moment.Moment =>
	momentZ(`${Time.EPOCHTIME} ${time}`).clone().utc().format(DATE_FORMATS.HH_mm) as unknown as moment.Moment

export const isSameDate = (date: string | moment.Moment, date2: string | moment.Moment) =>
	moment(convertIOSDateToHTMLFormat(date as string)).isSame(convertIOSDateToHTMLFormat(date2 as string))

export const splitDate = (value: string) => {
	return value.split("T")[0]
}

export const changeTimeZone = (value: string) => {
	return `${convertIOSDateTZToSpecificFormat(value, "YYYY-MM-DD")}T00:00:00.000Z`
}

export const weekDates = (startTime: any, endTime: any) => {
	const day = 24 * 60 * 60 * 1000

	const getStartTimeFirstWeekDate = moment(startTime).clone().startOf("isoWeek")

	const getEndTimeFirstWeekDate = moment(endTime).clone().startOf("isoWeek")

	const numberOFDays = getEndTimeFirstWeekDate.diff(getStartTimeFirstWeekDate) / day

	const weekDays = []
	for (let i = 0; i <= numberOFDays; i += 7) {
		weekDays.push(getStartTimeFirstWeekDate.clone().add(i, "days").format(DATE_FORMATS.DD_MM_YYYY))
	}
	return weekDays
}

export const startWeekDate = (currentDate: any) => {
	return moment(currentDate).clone().startOf("isoWeek")
}

export const getDaysInWeek = (date: any) => {
	const dates = []

	if (date) {
		for (let i = 0; i < 7; i++) {
			dates.push(moment(date, DATE_FORMATS.DD_MM_YYYY).clone().add(i, "days").format(DATE_FORMATS.DD_MM_YYYY))
		}
	}

	return dates
}

export const updateState = (list: any, data: any, checkOn: string): [any[], boolean] => {
	if (Array.isArray(data)) {
		let newStateAdded = false
		let pushNewList: any[] = [...list]
		data.forEach(d => {
			const [updated, newAdded] = update(pushNewList, d, checkOn)
			newStateAdded = newStateAdded || newAdded
			pushNewList = [...updated]
		})
		return [pushNewList, newStateAdded]
	} else {
		const [updated, newAdded] = update(list, { ...data }, checkOn)
		return [updated, newAdded]
	}
}

const update = (list: any, data: any, checkOn: string): [any[], boolean] => {
	const index = list.findIndex((ls: { [key: string]: string }) => ls[checkOn] === data[checkOn])
	if (index === -1) {
		return [[...list, { ...data }], true]
	} else {
		const newList = [...list]
		newList.splice(index, 1, { ...data } as never)
		return [newList as any[], false]
	}
}

export const deleteState = (list: any, data: any, checkOn: string) => {
	let newAdded = false
	const index = list.findIndex((ls: { [key: string]: string }) => ls[checkOn] === data[checkOn])

	if (index !== -1) {
		newAdded = true
		list.splice(index, 1)
	}

	return [list, newAdded]
}

export const debounce = (timeout = 10) => {
	let time = 0
	return function (cb: any) {
		if (time) clearTimeout(time)
		time = window.setTimeout(() => {
			cb()
		}, timeout)
	}
}

export const fieldsError = (name: string, errors: { [key: string]: FieldError | undefined } | FieldErrors): any => {
	const splitCheck = name.split(".")

	if (splitCheck.length === 3) {
		return errors?.[splitCheck?.[0]]?.[+splitCheck?.[1]]?.[splitCheck?.[2]]
	}
	if (splitCheck.length > 1) {
		return splitCheck.reduce((prev: any, curr: any, i) => {
			return i == 1 ? (errors[prev] ? errors[prev][curr] : false) : prev[curr] ? prev[curr] : false
		})
	} else return errors[name]
}

export const sortDeepCopyByProperty = (list: any, property: string) => {
	return cloneDeep(list)?.sort(sortByProperty(property))
}

export const sortByProperty = (property: string) => (a: any, b: any) =>
	a?.[property]?.toLowerCase().localeCompare(b?.[property]?.toLowerCase())

// for search

export const checkIfTextExists = (list: any, value: string, searchOnFields: string[]): any => {
	return value
		? list?.filter((item: any) =>
				searchOnFields?.some(key => item?.[key]?.toLowerCase().includes(value.toLowerCase())),
		  )
		: list
}

export const getTimeDifference = (startTime: string, finishTime: string): number => {
	return moment(finishTime).diff(startTime, "seconds")
}

export const timeInHoursMinutesfromSeconds = (time: number[]): { totalTime: string } => {
	if (time.length) {
		const totalSeconds = time.reduce((prev, curr) => prev + curr)
		const totalTime = round(totalSeconds / 3600, 2) + ""
		return { totalTime }
	} else return { totalTime: "0" }
}

export const checkDateExpired = (date: Date): boolean => {
	return date < new Date()
}

export const meterToMiles = (meters: number) => {
	return (meters * 0.000621371192).toFixed(1)
}

export const AddDateIfFinishTimeIsGreater = (startTime: string, finishTime: string) => {
	if (moment(startTime).date() === moment(finishTime).date()) {
		if (moment(startTime).hour() > moment(finishTime).hour()) {
			const newFinishTime = moment(finishTime).add(1, "days").toISOString()
			return newFinishTime
		} else return finishTime
	} else return finishTime
}

export const removeWhiteSpaces = (text: string) => text.replaceAll(" ", "")

export const getTimeDifferenceWithCurrentTime = (time: string, unitOfTime: moment.unitOfTime.Diff, date: string) => {
	return moment(moment(`${moment().format("YYYY-MM-DD")}T${moment().format("HH:mm")}:00Z`)).diff(
		moment(`${moment(date).format("YYYY-MM-DD")}T${time}:00Z`),
		unitOfTime,
	)
}
export const isInputInFocus = () => {
	const activeElement = document.activeElement
	if (
		activeElement?.tagName === "INPUT" ||
		activeElement?.tagName === "TEXTAREA" ||
		activeElement?.tagName === "SELECT"
	) {
		return true
	} else return false
}

export const getStartAndEndWeekDateFromCurrentDate = (currentDate: string) => {
	const weekStartDate = moment(currentDate, DATE_FORMATS.DD_MM_YYYY).startOf("week").format(DATE_FORMATS.DD_MM_YYYY)

	const weekEndDate = moment(currentDate, DATE_FORMATS.DD_MM_YYYY).endOf("week").format(DATE_FORMATS.DD_MM_YYYY)

	return [weekStartDate, weekEndDate]
}

export const getCurrentWeekDateFromPreviousDate = (selectedDate: string, inActiveScheduleDate: string) => {
	const inActiveScheduleDay = moment(inActiveScheduleDate, DATE_FORMATS.DD_MM_YYYY).format(DATE_FORMATS.dddd)
	const inActiveScheduleIndex = getWeekDayIndex[inActiveScheduleDay]

	const [startWeekDate] = getStartAndEndWeekDateFromCurrentDate(
		moment(selectedDate, DATE_FORMATS.DD_MM_YYYY).format(DATE_FORMATS.DD_MM_YYYY),
	)

	return moment(startWeekDate, DATE_FORMATS.DD_MM_YYYY)
		.add(inActiveScheduleIndex, "day")
		.format(DATE_FORMATS.DD_MM_YYYY)
}

export const isRouteDashboard = (route: string): boolean => route === `/${AppRoutes.SCHEDULES}/${AppRoutes.DASHBOARD}`

export const executeAtEvenInterval = (func: () => void | { payload: undefined; type: string }, interval: number) => {
	// Check current time and calculate the delay until next interval
	const now = Date.now()
	const delay = interval - (now % interval)
	// Delay execution until it's an even interval
	setTimeout(func, delay)
}
export const isWorkMonitor = (route: string): boolean => route === `/${AppRoutes.SCHEDULES}/${AppRoutes.WORK_MONITOR}`

export const reverseMapEnum = (enumObj: Record<string, string>): Record<string, string> => {
	const keyValueObject: Record<string, string> = {}

	for (const key in enumObj) {
		keyValueObject[enumObj[key]] = key
	}

	return keyValueObject
}

export const calculateTimeDifferenceWrtDst = (
	startTime: string | Date,
	finishTime: string | Date,
	unit: moment.unitOfTime.Diff = "seconds",
) => {
	const startTimeWOTimeZone = getMomentZInstanceOfDate(startTime)
	const finishTimeWOTimeZone = getMomentZInstanceOfDate(finishTime)
	let timeDifferenceInSeconds = finishTimeWOTimeZone.diff(startTimeWOTimeZone, unit)

	if (startTimeWOTimeZone.isDST() && !finishTimeWOTimeZone.isDST()) {
		timeDifferenceInSeconds -= 3600
	}
	return timeDifferenceInSeconds
}

export const getDatesOfWeekFromSingleDate = (value: Date | string) => {
	// Your input date
	const inputDate = moment(value) // Change this to your desired date

	// Get the start and end dates of the week
	const startOfWeek = inputDate.clone().startOf("week")
	const endOfWeek = inputDate.clone().endOf("week")

	// Create an array to store all the dates of the week
	const datesOfWeek = []

	// Start from the beginning of the week and add each day to the array
	let currentDay = startOfWeek.clone()
	while (currentDay.isSameOrBefore(endOfWeek)) {
		datesOfWeek.push(currentDay.clone())
		currentDay = currentDay.clone().add(1, "days")
	}
	return datesOfWeek
}

export const getAllDatesOfMonth = (currentMonth: moment.Moment) => {
	const startDate = moment(currentMonth).startOf("month")
	const endDate = moment(currentMonth).endOf("month")

	const dates = []
	const currentDate = startDate

	while (currentDate.isSameOrBefore(endDate, "day")) {
		dates.push(currentDate.toISOString())
		currentDate.add(1, "day")
	}

	return dates
}

export const setElementHeightWrtToParent = (
	document: Document,
	{
		parentId = "root",
		elementId,
		bottomPadding = 20,
	}: { parentId?: string; elementId: string; bottomPadding?: number },
) => {
	if (document) {
		const parentElement = document.getElementById(parentId)
		const childElement = document.getElementById(elementId)

		const difference =
			(parentElement?.getBoundingClientRect()?.bottom as number) -
			(childElement?.getBoundingClientRect()?.top as number)

		if (childElement) childElement.style.cssText = `height: ${difference - bottomPadding}px`
	}
}

export const generateDateRangesFromFilter = (filterType: DashboardDateFilter, finishTime: EventValue<Moment>) => {
	const currentDate = momentZ(finishTime)
	const ranges = []

	switch (filterType) {
		case DashboardDateFilter.DAILY:
			for (let i = 0; i < 30; i++) {
				const startTime = momentZ(currentDate).subtract(i, "days").set({ hour: 0, minute: 0, second: 0 })
				const finishTime = momentZ(startTime).set({ hour: 23, minute: 59, second: 59 })
				ranges.push({ startTime, finishTime })
			}
			break

		case DashboardDateFilter.WEEKLY:
			for (let i = 0; i < 12; i++) {
				const startTime = momentZ(currentDate)
					.subtract(i, "weeks")
					.startOf("week")
					.set({ hour: 0, minute: 0, second: 0 })
				const finishTime = momentZ(startTime).endOf("week").set({ hour: 23, minute: 59, second: 59 })
				ranges.push({ startTime, finishTime })
			}
			break

		case DashboardDateFilter.MONTHLY:
			for (let i = 0; i < 12; i++) {
				const startTime = momentZ(currentDate)
					.subtract(i, "months")
					.startOf("month")
					.set({ hour: 0, minute: 0, second: 0 })
				const finishTime = momentZ(startTime).endOf("month").set({ hour: 23, minute: 59, second: 59 })
				ranges.push({ startTime, finishTime })
			}
			break

		default:
			throw new Error("Invalid filter type")
	}

	return ranges
}
