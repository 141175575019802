import { isNaN, round } from "lodash"
import React from "react"

interface Props {
	color?: string
	title?: string
	value?: string | number
}

const StatCard: React.FC<Props> = ({ color = "primary", title = "Population to branch ratio", value = "187" }) => {
	return (
		<div className={`flex flex-1 flex-col rounded-b-sm border-b-1 pt-1 pb-2 border-${color} bg-white`}>
			<span className={`text-base font-semibold text-${color}`}>{isNaN(+value) ? 0 : round(+value, 2)}</span>
			<span className="text-tiny font-semibold text-light-text">{title}</span>
		</div>
	)
}

export default StatCard
