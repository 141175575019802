import * as Yup from "yup"

const locationQualificationInitialValues = {
	post: "",
	requiredQualifications: [],
	allPosts: false,
	contract: "",
}
const locationQualificationValidationSchema = Yup.object().shape({
	post: Yup.string().required().label("Post ID"),
	requiredQualifications: Yup.array().required().label("Post Qualifications"),
	allPosts: Yup.boolean().label("All Posts").default(false),
	contract: Yup.string().label("Contract"),
})

export { locationQualificationInitialValues, locationQualificationValidationSchema }
