import React from "react"
import Issued from "./Issued"
import Sizes from "./Sizes"
import useAppSelector from "hooks/useAppSelector"
import { selectSelectedEmployee } from "store/globalState.slice"
import { Divider } from "antd"

const Equipments: React.FC = () => {
	const selected = useAppSelector(selectSelectedEmployee)
	return (
		<>
			<Issued selected={selected} />
			<Divider />
			<Sizes selected={selected} />
		</>
	)
}

export default Equipments
