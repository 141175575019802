import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { updateState } from "config/utils"
import { GetLocationAdditionalDetailInterface } from "@type/locations.types"
import { LocationAdditionalDetail } from "services"
import { AppThunk, RootState } from "store"

export interface AdditionalDetailInterface {
	data: null | GetLocationAdditionalDetailInterface[]
	loading: boolean
	saving: boolean
}

const initialState: AdditionalDetailInterface = {
	data: null,
	loading: false,
	saving: false,
}

const locationDetailSlice = createSlice({
	name: "locationAdditionalDetail",
	initialState,
	reducers: {
		requestAdditionalDetail: additionalDetail => {
			additionalDetail.loading = true
		},
		receiveAdditionalDetailData: (
			additionalDetail,
			action: PayloadAction<GetLocationAdditionalDetailInterface[]>,
		) => {
			additionalDetail.loading = false
			additionalDetail.data = action.payload
		},
		receiveAdditionalDetailDataFailed: additionalDetail => {
			additionalDetail.loading = false
		},
		requestSavingAdditionalData: additionalDetailData => {
			additionalDetailData.saving = true
		},
		SavingAdditionalData: (additionalDetail, action: PayloadAction<GetLocationAdditionalDetailInterface>) => {
			additionalDetail.saving = false
			const [updatedValue] = updateState(additionalDetail.data, action.payload, "_id")
			additionalDetail.data = updatedValue
		},
		SavingAdditionalDataFailed: additionalDetail => {
			additionalDetail.saving = false
		},
	},
})

//REDUCER
export default locationDetailSlice.reducer

//ACTIONS
const {
	requestAdditionalDetail,
	receiveAdditionalDetailData,
	receiveAdditionalDetailDataFailed,
	requestSavingAdditionalData,
	SavingAdditionalData,
	SavingAdditionalDataFailed,
} = locationDetailSlice.actions

const getAdditionalDataByLocationId =
	(locationId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestAdditionalDetail())
			const { data: additionalDetailData } =
				await LocationAdditionalDetail.getLocationAdditionalDetailByLocationID(locationId)
			dispatch(receiveAdditionalDetailData(additionalDetailData))
		} catch (error) {
			dispatch(receiveAdditionalDetailDataFailed())
		}
	}

const saveAdditionalDetailData =
	(additionalData: GetLocationAdditionalDetailInterface, cb?: () => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(requestSavingAdditionalData())
			if (additionalData._id) {
				data = await LocationAdditionalDetail.updateLocationAdditionalDetailByLocationId(
					additionalData._id,
					additionalData,
				)
			} else {
				data = await LocationAdditionalDetail.createLocationAdditionalDetail(additionalData)
			}
			const { data: additionalDetailData } = data
			dispatch(SavingAdditionalData(additionalDetailData))
			cb?.()
		} catch (error) {
			dispatch(SavingAdditionalDataFailed())
		}
	}

export { getAdditionalDataByLocationId, saveAdditionalDetailData }

//SELECTORS
const selectLocationAdditionalDetail = (state: RootState) => state.location.additionalDetail
const selectAdditionalDetailLoading = () => (state: RootState) => selectLocationAdditionalDetail(state).loading
const selectAdditionalDetailData = () => (state: RootState) => selectLocationAdditionalDetail(state).data
const selectAdditionalDetailSaving = () => (state: RootState) => selectLocationAdditionalDetail(state).saving

export {
	selectAdditionalDetailData,
	selectLocationAdditionalDetail,
	selectAdditionalDetailSaving,
	selectAdditionalDetailLoading,
}
