import React, { useMemo } from "react"
import { useAppDispatch, useAppSelector } from "hooks"
import { logout, selectCurrentUser } from "store/auth.slice"
import CurrentDateAndTime from "./CurrentDateAndTime"
import { Button, Dropdown } from "antd-v5"
import { FaAngleDown } from "react-icons/fa"

const NavbarMenu = () => {
	const dispatch = useAppDispatch()
	const user = useAppSelector(selectCurrentUser())

	const menuItems = useMemo(() => {
		return [
			{
				key: "logout",
				label: "Logout",
				onClick: () => dispatch(logout()),
			},
		]
	}, [dispatch])

	const handleMenuClick = (e: any) => {
		menuItems.find(item => item.key === e.key)?.onClick()
	}
	return (
		<div className="flex h-full items-center justify-center px-5">
			<div className="flex h-full items-center space-x-2">
				<CurrentDateAndTime />
				<div className="inline-block h-auto min-h-[1em] w-0.5 self-stretch bg-neutral-200 opacity-100 dark:opacity-50"></div>
			</div>
			<Dropdown menu={{ items: menuItems, onClick: handleMenuClick }} placement="topRight">
				<Button className="flex items-center justify-center" icon={<FaAngleDown />} type="text">
					{user?.firstName || ""}
				</Button>
			</Dropdown>
		</div>
	)
}

export default NavbarMenu
