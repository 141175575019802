import axios from "axios"
import { EmployeeBansFormInterface, EmployeeBansInterface } from "@type/employees.types"

const ROOT_PATH = "employees/ban"

export default class EmployeesBanService {
	static createEmployeeBan = (trainingData: EmployeeBansFormInterface) =>
		axios.post<EmployeeBansFormInterface[]>(ROOT_PATH, trainingData)

	static updateEmployeeBan = (id: string, trainingData: EmployeeBansFormInterface) =>
		axios.put<EmployeeBansFormInterface[]>(`${ROOT_PATH}/${id}`, trainingData)

	static deleteEmployeeBanById = (id: string) => axios.delete(`${ROOT_PATH}/${id}`)

	static findEmployeeBanByEmployeeID = (id: string) => axios.get<EmployeeBansInterface[]>(`${ROOT_PATH}/${id}`)
}
