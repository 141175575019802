import axios from "axios"
import { CreatePostsInterface, GetPostsInterface } from "@type/locations.types"

const ROOT_PATH = "locations/posts"
export default class LocationPosts {
	static createLocationPosts = (locationPosts: CreatePostsInterface) =>
		axios.post<GetPostsInterface>(ROOT_PATH, locationPosts)

	static getAllLocationsPosts = () => axios.get<GetPostsInterface>(`${ROOT_PATH}`)

	static getLocationPostsByLocationId = (locationId: string) =>
		axios.get<GetPostsInterface[]>(`${ROOT_PATH}/?post=${locationId}`)

	static getLocationPostsByContractId = (contractId: string) =>
		axios.get<GetPostsInterface[]>(`${ROOT_PATH}/?contract=${contractId}`)

	static updateLocationPosts = (id: string, locationPosts: Partial<GetPostsInterface>) =>
		axios.put<GetPostsInterface>(`${ROOT_PATH}/${id}`, locationPosts)
}
