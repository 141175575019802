import { ThemeConfig } from "antd-v5"

export const PRIMARY = "rgb(99, 102, 241);"

const AntdThemeConfig: ThemeConfig = {
	token: {
		colorPrimary: PRIMARY,
	},
	components: {
		Segmented: {
			itemSelectedColor: PRIMARY,
		},
		Tabs: {
			margin: 0,
		},
		Descriptions: {
			fontSize: 14,
			fontSizeLG: 14,
			titleMarginBottom: 10,
		},
		Statistic: {
			titleFontSize: 12,
			contentFontSize: 12,
			marginXXS: 0,
		},
	},
}

export default AntdThemeConfig
