// import { caseConverters } from "config/utils"
import { Modal, SortableTable } from "components/index" //Icon,
import React from "react" // useState
// import WorkMonitorTable from "./WorkMonitorTable"

interface Props {
	response: { source: string; data: any } | null
	setResponse: (data: any) => void
	searchQuery: any
}

const locationKeys = [
	{ label: "SIN", key: "sin" },
	{ label: "Client Sin", key: "clientSin" },
	{ label: "Name", key: "name" },
	{ label: "ShortName", key: "shortName" },
	{
		label: "Branch",
		key: "branch",
		render: ({ row, key }: any) => row[key]?.name || "N/A",
	},
	{
		label: "Customer",
		key: "customer",
		render: ({ row, key }: any) => row[key]?.name || "N/A",
	},
	{ label: "City", key: "city" },
	{
		label: "County",
		key: "county",
		render: ({ row, key }: any) => row[key]?.name || "N/A",
	},
	{ label: "Postal Code", key: "postalCode" },
	{ label: "Contact No", key: "telephone" },
	{ label: "Contact No 1", key: "telephone1" },
	{ label: "Address Line 1", key: "addressLine1" },
	{ label: "Address Line 2", key: "addressLine2" },
]
const employeeKeys = [
	{ label: "Pin", key: "pin" },
	{ label: "Client Pin", key: "clientPin" },
	{ label: "ShortName", key: "shortName" },

	{
		label: "Branch",
		key: "branch",
		render: ({ row, key }: any) => row[key]?.name || "N/A",
	},
	{
		label: "Supplier",
		key: "supplier",
		render: ({ row, key }: any) => row[key]?.name || "N/A",
	},
	{ label: "Email", key: "email" },
	{ label: "City", key: "city" },
	{
		label: "County",
		key: "county",
		render: ({ row, key }: any) => row[key]?.name || "N/A",
	},
	{ label: "Postal Code", key: "postalCode" },
	{ label: "Contact No", key: "telephone" },
	{ label: "Contact No 1", key: "telephone1" },
	{ label: "Address Line 1", key: "addressLine1" },
	{ label: "Address Line 2", key: "addressLine2" },
]

// const propToRemove = ["createdAt", "updatedAt", "_id", "user", "pin", "pinBy", "oldPin", "position", "qrCode"]

const GlobalSearchDataModal: React.FC<Props> = ({ response, setResponse, searchQuery }) => {
	// const [showDropDown, setShowDropDown] = useState<number>(-1)

	return (
		<Modal
			title={
				<div className="text-lg font-medium leading-6 text-gray-900">
					Search Criteria
					<div>{`${Object.keys(searchQuery)[0]}: ${Object.values(searchQuery)[0]}`}</div>
				</div>
			}
			open={!!response}
			modalHandler={() => setResponse(null)}
			goBack={true}
			containerClass={`${response?.data?.length ? `!max-w-[1600px]` : ""}`}
		>
			<div>
				{response?.data?.length ? (
					<div className="table-fix-head schedule-scrollbar mt-4 h-[62vh] overflow-y-scroll">
						<SortableTable
							columns={response.source === "employee" ? employeeKeys : locationKeys}
							rows={response.data}
							rowClassName={"odd:bg-white even:bg-slate-50"}
						/>
					</div>
				) : (
					<span className="flex h-[60vh] w-[60vh] items-center justify-center">
						<span className="text-xl text-gray-600"> No Data Found</span>
					</span>
				)}
			</div>
		</Modal>
	)
}

export default GlobalSearchDataModal
