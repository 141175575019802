import { combineReducers } from "@reduxjs/toolkit"
import PBCodes from "./PBCodes.slice"
import PBRules from "./PBRules.slice"
import PublicHolidays from "./publicHolidays.slice"
import PBRuleDetails from "./PBRuleDetails.slice"
import PBTerms from "./PBTerms.slice"
import PBTermDetails from "./PBTermDetails.slice"
import payRoll from "./payRollTerms.slice"
const OrganizationReducer = combineReducers({
	PBCodes,
	PBRules,
	PBRuleDetails,
	PBTerms,
	PBTermDetails,
	publicHolidays: PublicHolidays,
	payRoll,
})

export default OrganizationReducer
