import { FeatureFlagEnums } from "types/featureFlags.type"
import { Permissions } from "config/constants"

enum AppRoutes {
	JAF = "jaf",
	REPORTS = "/reports",
	ABOUT_US = "/about-us",
	ACCOUNTS = "accounts",
	LOCATION = "location",
	PAY_BILL = "pay-bill",
	SECURITY = "security",
	DASHBOARD = "analytics",
	EMPLOYEES = "employees",
	SCHEDULES = "schedules",
	ADMIN_PAGE = "/admin",
	LOGIN_PAGE = "/auth/login",
	STATIC_DATA = "static-data",
	ORGANIZATION = "organization",
	WORK_MONITOR = "command-center",
	EMPLOYEES_PAGE = "/employees",
	PRIVACY_POLICY = "/privacy-policy",
	SCHEDULES_PAGE = "/schedules",
}

export const RouteToPermissions = {
	["/admin" as string]: Permissions.VIEW_ADMINISTRATION,
	["/reports" as string]: Permissions.VIEW_REPORTS,
	["/schedules/accounts" as string]: Permissions.VIEW_PAYROLL,
}

export const RouteToFeatureFlag: { [key: string]: FeatureFlagEnums } = {
	"/schedules": FeatureFlagEnums.SCHEDULES,
	"/schedules/employees": FeatureFlagEnums.EMPLOYEES,
	"/schedules/location": FeatureFlagEnums.LOCATIONS,
	"/schedules/command-center": FeatureFlagEnums.COMMAND_CENTER,
	"/schedules/accounts": FeatureFlagEnums.ACCOUNTS,
	"/schedules/jaf": FeatureFlagEnums.JOB_APPLICATION_FORM,
	"/schedules/analytics": FeatureFlagEnums.ANALYTICS,
	"/admin": FeatureFlagEnums.ADMIN,
	"/admin/security": FeatureFlagEnums.ADMIN_SECURITY,
	"/admin/pay-bill": FeatureFlagEnums.ADMIN_PAYBILL,
	"/admin/static-data": FeatureFlagEnums.ADMIN_STATIC_DATA,
	"/reports": FeatureFlagEnums.REPORTS,
}

export default AppRoutes
