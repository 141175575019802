import axios from "axios"
import {
	GetCronNotificationsInterface,
	CronNotificationsInterface,
	UpdateCronNotificationsInterface,
} from "@type/cronNotifications.types"

const ROOT_PATH = "cron-notifications"
export default class CronNotificationsService {
	static createCronNotifications = (cronNotification: CronNotificationsInterface) =>
		axios.post<GetCronNotificationsInterface>(ROOT_PATH, { cronNotification })

	static getAllCronNotifications = () => axios.get<Array<GetCronNotificationsInterface>>(`${ROOT_PATH}`)

	static getCronNotificationsById = (id: string) => axios.get<GetCronNotificationsInterface>(`${ROOT_PATH}/${id}`)

	static updateCronNotifications = (id: string, cronNotification: Partial<UpdateCronNotificationsInterface>) =>
		axios.put<GetCronNotificationsInterface>(`${ROOT_PATH}/${id}`, cronNotification)
}
