import axios from "axios"
import { GetPBTermDetailsInterface, PBTermDetailsInterface } from "@type/payBill.types"

const ROOT_PATH = "pay-bill/term-details"
export default class PBTermDetailsService {
	static createPBTermDetails = (PBTermDetails: PBTermDetailsInterface) =>
		axios.post<GetPBTermDetailsInterface>(ROOT_PATH, PBTermDetails)

	static getAllPBTermDetails = () => axios.get<Array<GetPBTermDetailsInterface>>(`${ROOT_PATH}`)

	static getPBTermDetailsById = (id: string) => axios.get<Array<GetPBTermDetailsInterface>>(`${ROOT_PATH}/${id}`)

	static updatePBTermDetails = (id: string, PBTermDetails: Partial<GetPBTermDetailsInterface>) =>
		axios.put<GetPBTermDetailsInterface>(`${ROOT_PATH}/${id}`, PBTermDetails)
}
