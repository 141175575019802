import React, { useContext, useMemo } from "react"
import useAppSelector from "hooks/useAppSelector"

import { AntdButton, Table2, TableSkeleton } from "components"

import { selectEmployeeTermData, selectEmployeeTermLoading } from "store/Employee/Terms/terms.slice"
import TermContext, { TermContextProps } from "./termsContext"

import { caseConverters, convertIOSDateToHTMLFormat } from "config/utils"
import { GLOBAL_DATE_FORMAT } from "config/constants"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"

const TermsTable = () => {
	const terms = useAppSelector(selectEmployeeTermData())
	const isTermsLoading = useAppSelector(selectEmployeeTermLoading())

	const { handleRow } = useContext(TermContext) as TermContextProps

	const termValue = useMemo(() => {
		if (terms) {
			return terms?.map((t, i) => {
				return {
					...t,
					serialNumber: i + 1,
				}
			})
		}

		return []
	}, [terms])

	if (isTermsLoading) return <TableSkeleton />

	return (
		<Table2
			columns={[
				{ label: "Sr. No", key: "serialNumber" },
				{
					label: "Start Date",
					key: "startDate",
					render: ({ row, key }) => convertIOSDateToHTMLFormat(row[key], GLOBAL_DATE_FORMAT),
				},
				{ label: "Terms", key: "terms", render: ({ row, key }) => caseConverters.titleCase(row[key]) },
				{
					label: "History",
					key: "_id",
					render: ({ row }: any) => (
						<AuditTrail id={row["_id"]} name={Entities.EMPLOYEE_TERM} showLabel={false} />
					),
				},
				{
					label: "Action",
					key: "action",
					render: ({ row }) => {
						return (
							<AntdButton
								className="my-1.25"
								onClick={e => {
									e.preventDefault()
									handleRow(row)
								}}
							>
								Update
							</AntdButton>
						)
					},
				},
			]}
			rows={termValue}
		/>
	)
}

export default TermsTable
