import React, { useEffect, useState } from "react"
import { Upload, Image } from "antd-v5"
import type { RcFile, UploadProps } from "antd-v5/es/upload"
import Icon from "components/Icon"
import { SignedUrlForImage } from "components/SignedUrl"

const getBase64 = (file: RcFile): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result as string)
		reader.onerror = error => reject(error)
	})

interface Props {
	image?: string
	name?: string
	handleFileToUpload: (file: File) => void
}
const ImageField: React.FC<Props> = ({ image, handleFileToUpload }) => {
	const [previewImage, setPreviewImage] = useState("")
	const [uploadedImage, setUploadedImage] = useState("")
	const handleChange: UploadProps["onChange"] = async ({ fileList: newFileList }) => {
		const file = newFileList[newFileList.length - 1]
		handleFileToUpload(newFileList[0].originFileObj as File)
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile)
		}
		setUploadedImage("")
		setPreviewImage(file.url || (file.preview as string) || "")
	}

	useEffect(() => {
		if (image && image !== uploadedImage) {
			setUploadedImage(image)
		} else {
			setUploadedImage("")
			setPreviewImage("")
		}
	}, [image])
	return (
		<>
			<div className="flex h-full w-full">
				{uploadedImage || previewImage ? (
					<div className="relative flex h-24 w-24 flex-col items-center justify-center overflow-hidden ">
						{uploadedImage ? (
							<SignedUrlForImage path={uploadedImage}>
								{(image: string) => <Image loading="lazy" height={132} width={132} src={image} />}
							</SignedUrlForImage>
						) : (
							<Image loading="lazy" height={132} width={132} src={previewImage} />
						)}

						<div className="absolute bottom-0 z-50 h-8 w-full place-content-center bg-[#00000080] ">
							<div className="hover: left-0 right-0 mx-auto w-fit">
								<Upload onChange={handleChange} showUploadList={false} className="avatar-uploader">
									<Icon className="mt-1" color="white" name="camera" size={20} />
								</Upload>
							</div>
						</div>
					</div>
				) : (
					<div>
						<Upload
							listType="picture-card"
							onChange={handleChange}
							showUploadList={false}
							className="avatar-uploader"
						>
							<div className="flex flex-col content-center items-center justify-center ">
								<div className="h-full w-full capitalize text-slate-700">Add Badge Image</div>
							</div>
						</Upload>
					</div>
				)}
			</div>
		</>
	)
}

export default ImageField
