export const pageStyle = `
@media print {
    #parent {
        -webkit-print-color-adjust: exact;
        display:flex;
        margin-top:6px;
        }
 }
@media print {
    #row{
        width:95%;
        height:95%;
        padding-left:10px;

    }

}
@media print {
    #row:first-child{
        background-color:#808080 !important;
        color:white !important;
        font-weight:bold;
    }
    
}
@media print {
    #title{
        display:flex;
        margin-top:60px;
        font-weight:bold;
    }

}
@media print {
    #location{
        display:flex;
        margin-top:10px;
        font-weight:bold;
    }

}
@media print {
    #reportName{
        display:flex;
        margin-top:10px;
        font-weight:bold;
        font-size: 150%;
    }

}
@media print {
    #logo{
        display:flex;
        margin-left:40px;
    }

}
@media print {
    body {
       -webkit-print-color-adjust: exact;
    }
 }
@page {
    size: auto;
    margin-top: 8%;
    margin-bottom: 8%;
    margin-left:4%;
}
`
