import axios from "axios"
import { GetSDBillGroupInterface, SDBillGroupInterface } from "@type/staticData.types"

const ROOT_PATH = "static-data/bill-group"
export default class SDBillGroupService {
	static createSDBillGroup = (billGroup: SDBillGroupInterface) =>
		axios.post<GetSDBillGroupInterface>(ROOT_PATH, billGroup)

	static getAllSDBillGroup = () => axios.get<Array<GetSDBillGroupInterface>>(`${ROOT_PATH}`)

	static getSDBillGroupById = (id: string) => axios.get<GetSDBillGroupInterface>(`${ROOT_PATH}/${id}`)

	static updateSDBillGroup = (id: string, billGroup: Partial<GetSDBillGroupInterface>) =>
		axios.put<GetSDBillGroupInterface>(`${ROOT_PATH}/${id}`, billGroup)
}
