import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetPBTermsInterface, PBTermsInterface, UpdatePBTermsInterface } from "@type/payBill.types"
import { PBTermsService } from "services"
import { AppThunk, RootState } from "store"
import { sortByProperty, sortDeepCopyByProperty, updateState } from "config/utils"

export interface PBTermsStateInterface {
	list: null | Array<GetPBTermsInterface>
	loading: boolean
	saving: boolean
}

const initialState: PBTermsStateInterface = {
	list: null,
	loading: false,
	saving: false,
}

const PBTermsSlice = createSlice({
	name: "PBTerms",
	initialState,
	reducers: {
		fetchingPBTerms: PBTerm => {
			PBTerm.loading = true
		},
		PBTermsFetched: (PBTerm, action: PayloadAction<GetPBTermsInterface[]>) => {
			PBTerm.loading = false
			PBTerm.list = action.payload
		},
		PBTermsFetchingFailed: PBTerm => {
			PBTerm.loading = false
		},
		savingPBTerm: PBTermData => {
			PBTermData.saving = true
		},
		PBTermSaved: (PBTerm, action: PayloadAction<GetPBTermsInterface>) => {
			PBTerm.saving = false
			const [UpdatedValue] = updateState(PBTerm.list, action.payload, "_id")
			PBTerm.list = UpdatedValue
		},
		PBTermSavingFailed: PBTerm => {
			PBTerm.saving = false
		},
	},
})

//REDUCER
export default PBTermsSlice.reducer

//ACTIONS
const { fetchingPBTerms, PBTermsFetched, PBTermsFetchingFailed, savingPBTerm, PBTermSaved, PBTermSavingFailed } =
	PBTermsSlice.actions

const getPBTerms =
	(cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingPBTerms())
			const { data: PBTermResponse } = await PBTermsService.getAllPBTerms()
			PBTermResponse.sort(sortByProperty("name"))
			cb && cb(PBTermResponse[0]?._id)
			dispatch(PBTermsFetched(PBTermResponse))
		} catch (error) {
			dispatch(PBTermsFetchingFailed())
		}
	}

const savePBTerm =
	(PBTermData: Partial<UpdatePBTermsInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingPBTerm())
			if (PBTermData?._id) data = await PBTermsService.updatePBTerm(PBTermData._id, PBTermData)
			else data = await PBTermsService.createPBTerm(PBTermData as PBTermsInterface)
			const { data: PBTermResponse } = data
			cb && cb(PBTermResponse._id)
			dispatch(PBTermSaved(PBTermResponse))
		} catch (error) {
			dispatch(PBTermSavingFailed())
		}
	}

export { getPBTerms, savePBTerm }

//SELECTORS
const selectPBTermState = (state: RootState) => state.payBill.PBTerms
const isPBTermLoading = () => (state: RootState) => selectPBTermState(state).loading
const isPBTermSaving = () => (state: RootState) => selectPBTermState(state).saving
const selectPBTermList = createSelector(
	(state: RootState) => state.payBill.PBTerms.list,
	list => {
		return sortDeepCopyByProperty(list, "name") as GetPBTermsInterface[]
	},
)

const selectPBTermById = (PBTermId: string | null) => (state: RootState) =>
	state.payBill.PBTerms?.list?.find(PBTerm => PBTerm._id === PBTermId)

export { selectPBTermList, selectPBTermState, selectPBTermById, isPBTermLoading, isPBTermSaving }
