import React from "react"
import { Navigate, Outlet } from "react-router-dom"
import { selectSignedIn } from "store/auth.slice"
import { AppRoutes } from "config"
import { useAppSelector } from "hooks"

const AuthLayout: React.FC = () => {
	const signedIn = useAppSelector(selectSignedIn())
	if (signedIn) return <Navigate to={AppRoutes.SCHEDULES_PAGE} />

	return <Outlet />
}

export default AuthLayout
