import React, { useMemo, useState } from "react"
import Icon from "./Icon/Main"
import { orderBy } from "lodash"
import { Table2 } from "."
import { TableColumnProps, TableProps } from "./Table2"
import { Account } from "@type/employees.types"

enum SORT_ORDERS {
	ASCENDING = "asc",
	DESCENDING = "desc",
}
export enum TableSortType {
	SINGLE_COLUMN,
	MULTI_COLUMN,
}

interface Props extends TableProps {
	sortType?: TableSortType
}
interface SortOption {
	key: string
	order: SORT_ORDERS.ASCENDING | SORT_ORDERS.DESCENDING
}
interface HeaderDataProps {
	label: string
	key: string
}

const handleHeaderRender = (
	{ label, key }: HeaderDataProps,
	sortOptions: SortOption[],
	handleSortButtonClick: (data: SortOption) => any,
) => {
	const currentSelectedOption = sortOptions.find((item: SortOption) => item.key === key)
	const downArrowColor =
		currentSelectedOption && currentSelectedOption.order === SORT_ORDERS.ASCENDING ? "sky-blue" : "dark-alt"
	const upArrowColor =
		currentSelectedOption && currentSelectedOption.order === SORT_ORDERS.DESCENDING ? "sky-blue" : "dark-alt"
	return (
		<div className="flex flex-col items-center justify-center space-y-2">
			<span>{label}</span>
			<div className="flex w-fit flex-row space-x-2">
				<button
					className="m-0 h-fit w-fit p-0"
					onClick={() => handleSortButtonClick({ key, order: SORT_ORDERS.ASCENDING })}
				>
					<Icon name="downArrowTable" size={9} color={downArrowColor} />
				</button>
				<button
					className="m-0 h-fit w-fit p-0"
					onClick={() => handleSortButtonClick({ key, order: SORT_ORDERS.DESCENDING })}
				>
					<Icon name="upArrowTable" size={9} color={upArrowColor} />
				</button>
			</div>
		</div>
	)
}

const SortableTable: React.FC<Props> = ({ rows, columns, sortType = TableSortType.MULTI_COLUMN, ...rest }) => {
	const [sortOptions, setSortOptions] = useState<SortOption[]>([])

	const handleSortButtonClick = ({ key, order }: SortOption) => {
		if (sortType === TableSortType.SINGLE_COLUMN) setSortOptions([{ key, order }])
		else {
			const sortIndexToRemove = sortOptions.findIndex(item => item.key === key && item.order === order)
			if (sortIndexToRemove >= 0)
				return setSortOptions(prev => prev.filter((_, ind) => ind !== sortIndexToRemove))

			const existingKeyIndex = sortOptions.findIndex(item => item.key === key)
			if (existingKeyIndex >= 0) {
				return setSortOptions(prev => [
					...prev.slice(0, existingKeyIndex),
					{ key, order },
					...prev.slice(existingKeyIndex + 1),
				])
			}

			setSortOptions(prev => [...prev, { key, order }])
		}
	}
	const sortedRows = useMemo(() => {
		if (sortOptions.length) {
			rows.map((ele: Account) => {
				return sortOptions?.map(opt => {
					if (!Object.prototype.hasOwnProperty.call(ele, opt.key) || !ele[opt.key as keyof Account]) {
						if (
							[
								"payrollNumber",
								"payDay",
								"hoursWorked",
								"hours",
								"uniformHours",
								"hourlyRate",
								"bankHolidayHours",
							].includes(opt.key)
						)
							(ele[opt.key as keyof Account] as number) = 0
						else (ele[opt.key as keyof Account] as string) = ""
					}
				})
			})
			return orderBy(
				rows,
				sortOptions.map(item => item.key),
				sortOptions.map(item => item.order),
			)
		}
		return rows
	}, [sortOptions, sortOptions.length, rows])

	// previous implementation for sorting

	// const sortedRows = useMemo(() => {
	// 	if (sortOptions.length) {
	// 		return orderBy(
	// 			rows,
	// 			sortOptions.map(item => item.key),
	// 			sortOptions.map(item => item.order),
	// 		)
	// 	}
	// 	return rows
	// }, [sortOptions, sortOptions.length, rows])

	return (
		<Table2
			rows={sortedRows}
			columns={columns.map(item => ({
				...item,
				headerRender: ({ label, key, keyForSorting }: TableColumnProps) =>
					handleHeaderRender({ label, key: keyForSorting || key }, sortOptions, handleSortButtonClick),
			}))}
			{...rest}
		/>
	)
}

export default SortableTable
