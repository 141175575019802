import axios from "axios"
import { GetPBRuleDetailsInterface, PBRuleDetailsInterface } from "@type/payBill.types"

const ROOT_PATH = "pay-bill/rule-details"
export default class PBRuleDetailsService {
	static createPBRuleDetails = (PBRuleDetails: PBRuleDetailsInterface) =>
		axios.post<GetPBRuleDetailsInterface>(ROOT_PATH, PBRuleDetails)

	static getAllPBRuleDetails = () => axios.get<Array<GetPBRuleDetailsInterface>>(`${ROOT_PATH}`)

	static getPBRuleDetailsById = (id: string) => axios.get<Array<GetPBRuleDetailsInterface>>(`${ROOT_PATH}/${id}`)

	static updatePBRuleDetails = (id: string, PBRuleDetails: Partial<GetPBRuleDetailsInterface>) =>
		axios.put<GetPBRuleDetailsInterface>(`${ROOT_PATH}/${id}`, PBRuleDetails)
}
