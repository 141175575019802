import { yupResolver } from "@hookform/resolvers/yup"
import { useAppSelector, useAppDispatch } from "hooks"
import { EmployeeQualificationInterface } from "@type/employees.types"
import { FormInputAntd, AntdButton } from "components"
import React, { useEffect, useState } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { siaQualificationsInitialValues, siaQualificationValidationSchema } from "Schema"
import {
	saveEmployeeQualificationSia,
	selectEmployeeQualificationById,
	SelectEmployeeQualificationSaving,
	updateEmployeeQualificationSiaImage,
} from "store/Employee/Qualification/qualification.slice"
import { selectSelectedEmployee } from "store/globalState.slice"
import { mediaService } from "services/index"
import ImageField from "./ImageField"

interface SIAFormHandler extends EmployeeQualificationInterface {}

interface Props {
	selectedQualification: string
	setSelectedQualification: (qualificationId: string) => void
}

const SIAForm: React.FC<Props> = ({ setSelectedQualification, selectedQualification = "" }) => {
	const dispatch = useAppDispatch()
	const [imageFile, setImageFile] = useState<File | null>(null)
	const [siaImageLoading, setSiaImageLoading] = useState<boolean>(false)
	const selected = useAppSelector(selectSelectedEmployee)
	const qualification = useAppSelector(selectEmployeeQualificationById(selectedQualification))
	const saving = useAppSelector(SelectEmployeeQualificationSaving())
	const methods = useForm<SIAFormHandler>({
		resolver: yupResolver(siaQualificationValidationSchema),
	})

	const {
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { isDirty, isSubmitting },
	} = methods

	useEffect(() => {
		if (selectedQualification === (null || "")) {
			reset(siaQualificationsInitialValues)
		}
	}, [selectedQualification])

	useEffect(() => {
		if (!saving) {
			reset(siaQualificationsInitialValues)
		}
	}, [saving, selected])

	useEffect(() => {
		if (qualification !== (null || undefined)) {
			reset({ ...qualification })
		} else {
			reset(siaQualificationsInitialValues)
		}
	}, [qualification])

	const handleAddNewQualificationClick = () => setSelectedQualification("")

	const addSiaImageToQualification = async (qualificationId: string, file?: File) => {
		setSiaImageLoading(true)
		const fileToUpload = file || imageFile
		if (qualificationId && fileToUpload) {
			const siaImagePath = (await mediaService.upload(fileToUpload)).data.url[0]
			setValue("siaImage", siaImagePath)
			dispatch(updateEmployeeQualificationSiaImage({ _id: qualificationId, siaImage: siaImagePath }))
		}
		setTimeout(() => setSiaImageLoading(false), 1000)
	}
	const onSubmit: SubmitHandler<SIAFormHandler> = data => {
		if (selected) {
			selectedQualification
				? dispatch(updateEmployeeQualificationSiaImage({ _id: selectedQualification, siaImage: "" }))
				: dispatch(
						saveEmployeeQualificationSia({ ...data, employee: selected }, qualificationId => {
							if (!selectedQualification) setSelectedQualification(qualificationId)
							addSiaImageToQualification(qualificationId)
						}),
				  )
		}
	}

	const onNewClick = () => {
		handleAddNewQualificationClick()
		reset({ ...siaQualificationsInitialValues })
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="flex w-full flex-row space-x-2">
					<div className="w-5/12">
						<ImageField
							image={watch("siaImage")}
							handleFileToUpload={file => {
								if (selectedQualification && !siaImageLoading)
									addSiaImageToQualification(selectedQualification, file)
								else setImageFile(file)
							}}
						/>
					</div>

					<div className="flex w-7/12 flex-col justify-center space-y-3">
						<FormInputAntd type="text" name="reference" disabled={selectedQualification !== ""} />
						<div className="flex items-center justify-start space-x-2">
							<AntdButton
								loading={saving}
								disabled={!isDirty || isSubmitting || siaImageLoading || saving}
								htmlType="submit"
								className="w-min"
							>
								Save
							</AntdButton>
							<AntdButton onClick={onNewClick}>New</AntdButton>
						</div>
					</div>
				</div>
			</form>
		</FormProvider>
	)
}

export default SIAForm
