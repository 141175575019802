import { ScheduleTypesEnum } from "config/constants"

export enum SchedulesFilter {
	NORMAL = "normal",
	UNALLOCATED = "Un Allocated",
	DELETED = "deleted",
}

export enum ScheduleStatus {
	ACCEPTED = "ACCEPTED",
	REJECTED = "REJECTED",
	PENDING = "PENDING",
	INACTIVE = "INACTIVE",
}

export type ScheduleStatusAndType = ScheduleStatus | ScheduleTypesEnum

export const ScheduleStatusToColor = {
	[ScheduleStatus.ACCEPTED]: "#53917A",
	[ScheduleStatus.REJECTED]: "#B83720",
	[ScheduleStatus.PENDING]: "#F27410",
	[ScheduleStatus.INACTIVE]: "#EACF60",
	[ScheduleTypesEnum.DUTY]: "#6E9107",
	[ScheduleTypesEnum.HOLIDAY]: "#F24141",
	[ScheduleTypesEnum.TRAINING]: "#3D70DD",
	[ScheduleTypesEnum.SICKNESS]: "#EACF60",
}
