import React, { useState } from "react"
import { useAppSelector, useHeightResize } from "hooks"
import ScheduleTableByFilter from "./ScheduleTableByFilter"

import { selectScheduleListByFilter } from "store/Schedules/schedules.slice"
import { convertIOSDateToHTMLFormat } from "config/utils"
import { SchedulesFilter } from "../Constants/schedulesConstants"
import { GLOBAL_DATE_FORMAT } from "config/constants"

interface Selected {
	[key: string]: string | number
}

enum AddMore {
	count = 5,
}

const ScheduleFilterTable: React.FC = () => {
	const [selected, setSelected] = useState<Selected[]>()
	useHeightResize({ element: "schedule-filter-table-container" })
	const scheduleList = useAppSelector(selectScheduleListByFilter())

	return (
		<div id="schedule-filter-table-container" className="table-fix-head schedule-scrollbar overflow-y-scroll">
			<ScheduleTableByFilter
				showRow={AddMore.count}
				scheduleList={scheduleList}
				columns={[
					{
						label: "Sr. No",
						key: "serialNumber",
						render: data => data.index + 1,
					},
					{ label: "State", key: "state" },
					{ label: "Location", key: "location_name" },
					{ label: "Post", key: "post_name" },
					{
						label: "Employee",
						key: "employee_name",
						disable: scheduleList?.length ? scheduleList[0]["state"] == SchedulesFilter.UNALLOCATED : false,
					},
					{
						label: "Start",
						key: "startTime",
						render: ({ key, row }) => convertIOSDateToHTMLFormat(row[key], GLOBAL_DATE_FORMAT),
					},
					{
						label: "Finish",
						key: "finishTime",
						render: ({ key, row }) => convertIOSDateToHTMLFormat(row[key], GLOBAL_DATE_FORMAT),
					},
				]}
				SelectedHandler={(e: Selected) => {
					if (e) {
						setSelected(prev =>
							prev && [...prev].some(element => element._id === e._id)
								? [...prev].filter(element => element._id !== e._id)
								: prev
								? [...prev, e]
								: [e],
						)
					} else {
						setSelected(() => [])
					}
				}}
				selected={selected as unknown as Selected[]}
			/>
		</div>
	)
}

export default ScheduleFilterTable
