import { useEffect, useMemo, useRef, useState } from "react"
import { GlobalSidebar, SidebarList, FormInputAntd, FormSelectAntd, AntdButton } from "components"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { PBCodeInitialValues, PBCodeSchema } from "Schema"
import { PBCodeInterface } from "@type/payBill.types"
import { PBCodeOptionsDropdown } from "config/constants"
import useAppSelector from "hooks/useAppSelector"
import useAppDispatch from "hooks/useAppDispatch"
import {
	getPBCodes,
	isPBCodeLoading,
	isPBCodeSaving,
	savePBCode,
	selectPBCodeById,
	selectPBCodeList,
} from "store/PayBill/PBCodes.slice"

interface PBCodeFormHandler extends PBCodeInterface {}

const PBCodes = () => {
	const [selectedPBCode, setSelectedPBCode] = useState<string | null>("")
	const dispatch = useAppDispatch()
	const loading = useAppSelector(isPBCodeLoading())
	const saving = useAppSelector(isPBCodeSaving())
	const PBCodesList = useAppSelector(selectPBCodeList)
	const PBCode = useAppSelector(selectPBCodeById(selectedPBCode))
	const isNewBeingCreated = useRef(false)

	const methods = useForm<PBCodeFormHandler>({ resolver: yupResolver(PBCodeSchema) })
	const {
		reset,
		handleSubmit,
		formState: { isSubmitted },
	} = methods

	const sidebarPBCodes = useMemo(() => {
		return PBCodesList?.map(PBCode => ({ _id: PBCode._id, name: PBCode.name }))
	}, [saving, PBCodesList?.length, isSubmitted])

	const onSubmit: SubmitHandler<PBCodeFormHandler> = data => {
		isNewBeingCreated.current = true
		dispatch(
			savePBCode(data, id => {
				setSelectedPBCode(id)
				isNewBeingCreated.current = false
			}),
		)
	}

	useEffect(() => {
		dispatch(getPBCodes("", setSelectedPBCode))
	}, [])

	useEffect(() => {
		if (selectedPBCode !== (null && undefined)) {
			reset({ ...PBCodeInitialValues, ...PBCode })
		} else if (selectedPBCode === null && !isNewBeingCreated.current) {
			reset({ ...PBCodeInitialValues })
		}
	}, [selectedPBCode])

	const handleSelected = (PBCodeId: string | null): void => setSelectedPBCode(PBCodeId)

	return (
		<>
			<GlobalSidebar>
				<SidebarList
					title={"PBCodes"}
					loading={loading}
					selected={selectedPBCode}
					setSelected={handleSelected}
					list={sidebarPBCodes}
				/>
			</GlobalSidebar>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="w-1/4 flex-col space-y-3">
						<div className="space-y-3">
							<FormInputAntd label="PB Code" name="name" type="text" />
							<FormInputAntd label="Description" name="description" type="text" />
							<FormSelectAntd
								disabled={!PBCodeOptionsDropdown.length}
								label="Select option it is to be used for"
								name="usedFor"
								options={PBCodeOptionsDropdown}
							/>
						</div>
						<div className="flex space-x-3">
							<AntdButton htmlType="button" onClick={() => setSelectedPBCode(null)}>
								New
							</AntdButton>
							<AntdButton htmlType="submit" loading={saving}>
								{selectedPBCode ? "Update" : "Save"}
							</AntdButton>
						</div>
					</div>
				</form>
			</FormProvider>
		</>
	)
}
0
export default PBCodes
