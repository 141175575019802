import React, { useEffect, useState } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useAppSelector, useAppDispatch } from "hooks"

import { AntdButton, FormSelectAntd, Table2, TableSkeleton } from "components"

import { EmployeeTrainingFormInterface } from "@type/employees.types"
import { selectBranchList } from "store/Branches/branch.slice"
import {
	getLocationsByBranchId,
	isLocationLoading,
	selectLocationListByBranchId,
} from "store/Location/locationDetails.slice"
import {
	getEmployeesTrainingByEmployeeId,
	isEmployeeTrainingLoading,
	isEmployeeTrainingSaving,
	saveEmployeeTrainingData,
	selectEmployeeTrainingById,
	selectEmployeeTrainingData,
} from "store/Employee/Location/employeeTraining.slice"
import { yupResolver } from "@hookform/resolvers/yup"
import { employeeTrainingValidationSchema, trainingInitialValues } from "Schema"
import { AllowedTo } from "react-abac"
import { Permissions } from "config/constants"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"
import { SelectedPropInterface } from "services/generic/type"

interface LocationFormHandler extends EmployeeTrainingFormInterface {}

const EmployeeTraining: React.FC<SelectedPropInterface> = ({ selected }) => {
	const [selectedTraining, setSelectedTraining] = useState<string | null>(null)
	const employeeTrainings = useAppSelector(selectEmployeeTrainingData())
	const dispatch = useAppDispatch()
	const branches = useAppSelector(selectBranchList())

	const locationLoading = useAppSelector(isLocationLoading())
	const employeeTrainingLoading = useAppSelector(isEmployeeTrainingLoading())
	const selectedEmployeeTraining = useAppSelector(selectEmployeeTrainingById(selectedTraining))
	const employeeTrainingSaving = useAppSelector(isEmployeeTrainingSaving())

	const methods = useForm<LocationFormHandler>({
		resolver: yupResolver(employeeTrainingValidationSchema),
	})
	const { handleSubmit, reset, watch, setValue } = methods
	const locations = useAppSelector(selectLocationListByBranchId(watch("branch")))
	useEffect(() => {
		if (selected) {
			dispatch(getEmployeesTrainingByEmployeeId(selected as string))
			reset(trainingInitialValues)
			setSelectedTraining(null)
		}
	}, [selected])

	useEffect(() => {
		if (watch("branch")) dispatch(getLocationsByBranchId(watch("branch")))
	}, [watch("branch"), employeeTrainings])

	useEffect(() => {
		if (selectedEmployeeTraining) {
			const { branch, location } = selectedEmployeeTraining
			reset({ branch: branch._id, location: location._id })
		}
		if (!selectedTraining) {
			reset(trainingInitialValues)
		}
	}, [selectedTraining])

	useEffect(() => {
		if (locations.length) {
			setValue("location", locations[0]._id)
		}
	}, [locations.length])

	useEffect(() => {
		if (!employeeTrainingSaving) {
			setSelectedTraining(null)
			reset(trainingInitialValues)
		}
	}, [employeeTrainingSaving])

	const onSubmit: SubmitHandler<LocationFormHandler> = data => {
		dispatch(
			saveEmployeeTrainingData(
				selectedTraining
					? { _id: selectedTraining, ...data, employee: selected as string }
					: { ...data, employee: selected as string },
			),
		)
	}

	return (
		<div className="space-y-4 pr-3">
			<div className="text-xl">Employee Training</div>
			<div>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						{/* <div className="flex flex-col gap-y-3"> */}
						<div className="grid grid-cols-3 items-end gap-x-5">
							<FormSelectAntd
								label="Branch"
								name="branch"
								onChange={val => {
									setValue("location", "")
									setValue("branch", val)
								}}
								options={branches.map(({ _id, name }) => ({ label: name, value: _id }))}
							/>

							<FormSelectAntd
								loading={locationLoading}
								label="Location"
								name="location"
								value={locationLoading ? null : watch("location")}
								options={locations.map(({ _id, name }) => ({ label: name, value: _id }))}
							/>

							<AllowedTo perform={Permissions.UPDATE_EMPLOYEE_LOCATIONS}>
								<AntdButton
									className="w-min"
									htmlType="submit"
									loading={employeeTrainingSaving}
									disabled={employeeTrainingSaving}
								>
									{selectedTraining ? "Update" : "Save"}
								</AntdButton>
							</AllowedTo>
						</div>
					</form>
				</FormProvider>
			</div>
			<div>
				{!employeeTrainingLoading && !employeeTrainingSaving ? (
					<Table2
						rows={employeeTrainings as any}
						columns={[
							{
								label: "Branch",
								key: "branch",
								render: ({ row, key }: any) => {
									return <div> {row[key]["name"]}</div>
								},
							},
							{
								label: "Location",
								key: "location",
								render: ({ row, key }: any) => <div>{row[key]["name"]}</div>,
							},
							{
								label: "SIN",
								key: "location",
								render: ({ row, key }: any) => {
									return <div> {row[key]["sin"]}</div>
								},
							},
							{
								label: "History",
								key: "_id",
								render: ({ row }: any) => (
									<AuditTrail id={row["_id"]} name={Entities.EMPLOYEE_TRAINING} showLabel={false} />
								),
							},
							{
								label: "Actions",
								key: "action",
								render: ({ row }) => {
									return (
										<AntdButton
											className="w-min justify-center py-1"
											disabled={selectedTraining == row._id}
											onClick={() => setSelectedTraining(row._id)}
										>
											{selectedTraining !== row._id ? "Update" : "Editing"}
										</AntdButton>
									)
								},
							},
						]}
					/>
				) : (
					<TableSkeleton />
				)}
			</div>
		</div>
	)
}

export default EmployeeTraining
