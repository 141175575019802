import { createContext } from "react"
import { CreateEmployeeBankDetailInterface, GetEmployeeBankDetailInterface } from "@type/employees.types"

export interface BankDetailContext {
	bankDetail: CreateEmployeeBankDetailInterface | null
	handleBankDetailHandler: (bankData: GetEmployeeBankDetailInterface | null) => void
}

const EmployeeBankContext = createContext<BankDetailContext | null>(null)

export default EmployeeBankContext
