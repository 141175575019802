import axios from "axios"
import { GetSDIncidentsInterface, SDIncidentsInterface } from "@type/staticData.types"

const ROOT_PATH = "static-data/incident"
export default class SDIncidentService {
	static createSDIncident = (incident: SDIncidentsInterface) =>
		axios.post<GetSDIncidentsInterface>(ROOT_PATH, incident)

	static getAllSDIncident = () => axios.get<Array<GetSDIncidentsInterface>>(`${ROOT_PATH}`)

	static getSDIncidentById = (id: string) => axios.get<GetSDIncidentsInterface>(`${ROOT_PATH}/${id}`)

	static updateSDIncident = (id: string, incident: Partial<GetSDIncidentsInterface>) =>
		axios.put<GetSDIncidentsInterface>(`${ROOT_PATH}/${id}`, incident)
}
