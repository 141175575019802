import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CreateTimeCaptureInterface, GetTimeCaptureInterface } from "@type/locations.types"
import { locationTimeCapture } from "services"
import { AppThunk, RootState } from "store"
import { locationCallTimeFetched } from "./locationCallTime.slice"
import { locationInventoryCallFetched } from "./locationInventoryCall.slice"
import { locationQRCallFetched } from "./locationQRCall.slice"

export interface LocationTimeCaptureStateInterface {
	list: null | GetTimeCaptureInterface
	loading: boolean
	saving: boolean
}

const initialState: LocationTimeCaptureStateInterface = {
	list: null,
	loading: false,
	saving: false,
}

const locationTimeCaptureSlice = createSlice({
	name: "locationTimeCapture",
	initialState,
	reducers: {
		fetchingLocationTimeCapture: locationTimeCapture => {
			locationTimeCapture.loading = true
		},
		fetchedNoTimeCapture: locationTimeCapture => {
			locationTimeCapture.list = null
		},
		locationTimeCaptureFetched: (locationTimeCapture, action: PayloadAction<GetTimeCaptureInterface>) => {
			locationTimeCapture.loading = false
			locationTimeCapture.list = action.payload
		},
		locationTimeCaptureFetchingFailed: locationTimeCapture => {
			locationTimeCapture.loading = false
		},
		savingLocationTimeCapture: locationTimeCaptureData => {
			locationTimeCaptureData.saving = true
		},
		locationTimeCaptureSaved: (locationTimeCapture, action: PayloadAction<GetTimeCaptureInterface>) => {
			locationTimeCapture.saving = false
			locationTimeCapture.list = action.payload
		},
		locationTimeCaptureSavingFailed: locationTimeCapture => {
			locationTimeCapture.saving = false
		},
	},
})

//REDUCER
export default locationTimeCaptureSlice.reducer

//ACTIONS
const {
	fetchedNoTimeCapture,
	fetchingLocationTimeCapture,
	locationTimeCaptureFetched,
	locationTimeCaptureFetchingFailed,
	savingLocationTimeCapture,
	locationTimeCaptureSaved,
	locationTimeCaptureSavingFailed,
} = locationTimeCaptureSlice.actions

const getLocationTimeCapture =
	(postId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingLocationTimeCapture())
			const { data: locationTimeCaptureResponse } = await locationTimeCapture.getLocationTimeCapture(postId)
			dispatch(locationTimeCaptureFetched(locationTimeCaptureResponse))
		} catch (error) {
			dispatch(locationTimeCaptureFetchingFailed())
		}
	}

const getLocationTimeCaptureNoPost = (): AppThunk => async dispatch => {
	try {
		dispatch(fetchedNoTimeCapture())
	} catch (error) {
		dispatch(locationTimeCaptureFetchingFailed())
	}
}

const saveLocationTimeCapture =
	(locationTimeCaptureData: GetTimeCaptureInterface): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingLocationTimeCapture())
			if (locationTimeCaptureData._id)
				data = await locationTimeCapture.updateLocationTimeCapture(
					locationTimeCaptureData._id,
					locationTimeCaptureData,
				)
			else
				data = await locationTimeCapture.createLocationTimeCapture(
					locationTimeCaptureData as CreateTimeCaptureInterface,
				)
			const { data: locationTimeCaptureResponse } = data
			dispatch(locationTimeCaptureSaved(locationTimeCaptureResponse as GetTimeCaptureInterface))
		} catch (error) {
			dispatch(locationTimeCaptureSavingFailed())
		}
	}

const saveLocationCall =
	(locationCallDetails: any, cb?: () => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingLocationTimeCapture())
			const { data: callDetails } = await locationTimeCapture.createLocationCalls(locationCallDetails)

			if (Object.values(callDetails.calls.checkCall).length) {
				delete callDetails.calls.checkCall.successMessage

				!!Object.values(callDetails.calls.checkCall).length &&
					dispatch(locationCallTimeFetched(Object.values(callDetails.calls.checkCall)))
			}
			if (Object.values(callDetails.calls.inventoryCall).length) {
				delete callDetails.calls.inventoryCall.successMessage

				!!Object.values(callDetails.calls.inventoryCall).length &&
					dispatch(locationInventoryCallFetched(Object.values(callDetails.calls.inventoryCall)))
			}
			if (Object.values(callDetails.calls.qrCall).length) {
				delete callDetails.calls.qrCall.successMessage

				!!Object.values(callDetails.calls.qrCall).length &&
					dispatch(locationQRCallFetched(Object.values(callDetails.calls.qrCall)))
			}
			dispatch(locationTimeCaptureSavingFailed())
			cb && cb()
		} catch (error) {
			dispatch(locationTimeCaptureSavingFailed())
		}
	}

export { getLocationTimeCapture, saveLocationTimeCapture, getLocationTimeCaptureNoPost, saveLocationCall }

//SELECTORS
const selectLocationTimeCaptureState = (state: RootState) => state.location.timeCapture
const isLocationTimeCaptureLoading = () => (state: RootState) => selectLocationTimeCaptureState(state).loading
const selectLocationTimeCaptureList = () => (state: RootState) => selectLocationTimeCaptureState(state).list
const isSavingLocationTimeCapture = () => (state: RootState) => selectLocationTimeCaptureState(state).saving

export { isLocationTimeCaptureLoading, selectLocationTimeCaptureList, isSavingLocationTimeCapture }
