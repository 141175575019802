import React, { useMemo, useContext } from "react"
import { selectBankDetailData } from "store/Employee/BankDetail/bankDetail.slice"
import { useAppSelector } from "hooks"
import { AntdButton, Table2 } from "components"
import EmployeeBankContext, { BankDetailContext } from "./AdditionalDetailContext"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"

const AdditionalDataTable: React.FC = () => {
	const { handleBankDetailHandler } = useContext(EmployeeBankContext) as BankDetailContext
	const bankData = useAppSelector(selectBankDetailData())

	const changedBankData = useMemo(
		() =>
			bankData.map((bank, i) => ({
				...bank,
				serialNumber: i + 1,
			})),

		[bankData],
	)

	return (
		<div>
			<Table2
				columns={[
					{ label: "Sr. No", key: "serialNumber" },
					{ label: "Account Number", key: "accountNumber" },
					{ label: "Bank Name", key: "bankName" },
					{ label: "Account Title", key: "accountTitle" },
					{ label: "Sort Code", key: "sortCode" },
					{
						label: "History",
						key: "_id",
						render: ({ row }) => (
							<AuditTrail id={row["_id"]} name={Entities.EMPLOYEE_BANK_DETAILS} showLabel={false} />
						),
					},
					{
						label: "Action",
						key: "action",
						render: data => (
							<AntdButton
								onClick={() => {
									handleBankDetailHandler(data.row)
								}}
							>
								Update
							</AntdButton>
						),
					},
				]}
				rows={changedBankData}
			/>
		</div>
	)
}

export default AdditionalDataTable
