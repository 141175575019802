import React, { useEffect, useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useAppDispatch, useAppSelector } from "hooks"
import { Accordion, AntdButton, DateField, FormCheckbox, FormInputAntd, FormSelectAntd, FormSkeleton } from "components"
import { hrInitialValues, hrValidationSchema } from "Schema"
import { EmployeeHRInterface, EmployeeQualificationInterface } from "@type/employees.types"
import {
	saveEmployeeHrData,
	selectEmployeeHrData,
	getEmployeesHrByEmployeeId,
	isEmployeeHrSaving,
	isEmployeeHrLoading,
} from "store/Employee/HR/hr.slice"
import { nationalityOptions } from "../SelectOptions"
import QualificationContext from "./qualificationContext"
import { selectSDCountiesDropdownList } from "store/StaticData/SDCounties.slice"
import { AllowedTo } from "react-abac"
import { Permissions } from "config/constants"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"
import { selectSelectedEmployee } from "store/globalState.slice"

interface HRFormHandler extends EmployeeHRInterface {}

enum MaritalStatus {
	MARRIED = "MARRIED",
	SINGLE = "SINGLE",
	WIDOWED = "WIDOWED",
}
enum Gender {
	MALE = "MALE",
	FEMALE = "FEMALE",
	OTHER = "OTHER",
}
enum DisclosureLevel {
	DisclosureLevel1 = "DISCLOSURE LEVEL 1",
	DisclosureLevel2 = "DISCLOSURE LEVEL 2",
}
enum VisaType {
	EU = "EU",
	STUDENT = "STUDENT",
	GENERAL = "GENERAL",
	UNRESTRICTED = "UNRESTRICTED",
	DEPENDANT = "DEPENDANT",
	SPOUSE = "SPOUSE",
}
const visaType = [
	{ name: "Eu", _id: VisaType.EU },
	{ name: "Student", _id: VisaType.STUDENT },
	{ name: "General", _id: VisaType.GENERAL },
	{ name: "Unrestricted", _id: VisaType.UNRESTRICTED },
	{ name: "Dependant", _id: VisaType.DEPENDANT },
	{ name: "Spouse", _id: VisaType.SPOUSE },
]
const maritalStatus = [
	{ name: "Single", _id: MaritalStatus.SINGLE },
	{ name: "Married", _id: MaritalStatus.MARRIED },
	{ name: "Widowed", _id: MaritalStatus.WIDOWED },
]
const gender = [
	{ name: "Male", _id: Gender.MALE },
	{ name: "Female", _id: Gender.FEMALE },
	{ name: "Other", _id: Gender.OTHER },
]
const disclosureLevel = [
	{ name: "Disclosure Level 1", _id: DisclosureLevel.DisclosureLevel1 },
	{ name: "Disclosure Level 2", _id: DisclosureLevel.DisclosureLevel2 },
]

const HR = () => {
	const dispatch = useAppDispatch()
	const hr = useAppSelector(selectEmployeeHrData())
	const isHRSaving = useAppSelector(isEmployeeHrSaving())
	const isHRLoading = useAppSelector(isEmployeeHrLoading())
	const allCounties = useAppSelector(selectSDCountiesDropdownList) || []

	const selected = useAppSelector(selectSelectedEmployee)

	const [qualification, setQualification] = useState<EmployeeQualificationInterface | undefined>()

	const methods = useForm<HRFormHandler>({
		resolver: yupResolver(hrValidationSchema),
	})
	const {
		reset,
		setValue,
		handleSubmit,
		watch,
		formState: { errors, isDirty, isSubmitting },
	} = methods
	useEffect(() => {
		selected && dispatch(getEmployeesHrByEmployeeId(selected))
	}, [selected])

	useEffect(() => {
		if (hr) {
			reset(hr)
		} else {
			reset(hrInitialValues)
		}
	}, [hr])

	const onSubmit: SubmitHandler<HRFormHandler> = data => {
		dispatch(saveEmployeeHrData({ ...data, employee: selected as string }))
	}

	const handleRow = (e: any) => {
		setQualification(e)
	}
	if (isHRLoading) return <FormSkeleton />
	return (
		<QualificationContext.Provider value={{ handleRow, qualification }}>
			<div>
				<div className="flex flex-col">
					<AuditTrail id={hr?._id} name={Entities.EMPLOYEE_HR} />
				</div>
				<div className="flex justify-between">
					<div>
						<FormProvider {...methods}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="flex flex-col items-center">
									<div className="flex w-full justify-between gap-2.5 px-1.25 py-1.25 ">
										<div className="flex flex-col gap-1.5">
											<span className="text-lg font-bold ">Basic Information</span>
											<FormInputAntd
												className=" mr-5.5 w-40 lg:w-44 xl:w-84"
												containerClass="max-w-84 w-full"
												type="text"
												label="NI Number"
												name="nin"
											/>
											<DateField
												containerClass=" mr-5.5 w-40 lg:w-44 xl:w-84"
												className="w-full "
												label="Date of Birth"
												setValue={setValue}
												watch={watch}
												name={"dob"}
												errors={errors}
											/>

											<FormSelectAntd
												disabled={!nationalityOptions.length}
												containerClass="max-w-84 w-full"
												className=" mr-5.5 w-40 lg:w-44 xl:w-84 "
												label="Nationality"
												name="nationality"
												options={nationalityOptions.map(({ num_code, nationality }) => ({
													label: nationality,
													value: num_code,
												}))}
											/>
											<DateField
												containerClass=" mr-5.5 w-40 lg:w-44 xl:w-84"
												className="w-full"
												label="Visa Expiry Date"
												setValue={setValue}
												watch={watch}
												errors={errors}
												name={"visaExpiryDate"}
												disabled={watch("nationality") === "826" ? true : false}
											/>
											<FormSelectAntd
												disabled={!maritalStatus.length}
												containerClass="max-w-84 w-full"
												className="mr-5.5 w-40 lg:w-44 xl:w-84 "
												label="Marital Status"
												name="maritalStatus"
												options={maritalStatus.map(({ _id, name }) => ({
													label: name,
													value: _id,
												}))}
											/>
											<FormSelectAntd
												disabled={!gender.length}
												containerClass="max-w-84 w-full"
												className="mr-5.5 w-40 lg:w-44 xl:w-84 "
												label="Gender"
												name="gender"
												options={gender.map(({ _id, name }) => ({ label: name, value: _id }))}
											/>
											<FormInputAntd
												className=" mr-5.5 w-40 lg:w-44 xl:w-84"
												containerClass="max-w-84 w-full"
												label="Dependant"
												name="dependants"
												type="number"
											/>
											<FormInputAntd
												className=" mr-5.5 w-40 lg:w-44 xl:w-84"
												containerClass="max-w-84 w-full"
												label="Hours Allowed"
												type="number"
												min={0}
												name="hoursAllowed"
											/>
											<FormSelectAntd
												disabled={!visaType.length}
												containerClass="max-w-84 w-full"
												className="mr-5.5 w-40 lg:w-44 xl:w-84 "
												label="Visa Type"
												name="visaType"
												options={visaType.map(({ _id, name }) => ({
													label: name,
													value: _id,
												}))}
											/>
										</div>

										<div className="flex flex-col gap-1.5">
											<span className="text-lg font-bold ">Next of Kin Details</span>
											<FormInputAntd
												className=" mr-5.5 w-40 lg:w-44 xl:w-84"
												label="Name"
												name="name"
												type="text"
											/>
											<FormInputAntd
												className=" mr-5.5 w-40 lg:w-44 xl:w-84"
												label="Relationship"
												name="relationship"
												type="text"
											/>
											<FormInputAntd
												className=" mr-5.5 w-40 lg:w-44 xl:w-84"
												label="Address Line1"
												name="addressLine1"
												type="text"
											/>
											<FormInputAntd
												className=" mr-5.5 w-40 lg:w-44 xl:w-84"
												label="Address Line2"
												name="addressLine2"
												type="text"
											/>
											<FormInputAntd
												className=" mr-5.5 w-40 lg:w-44 xl:w-84"
												label="City"
												name="city"
												type="text"
											/>
											<FormSelectAntd
												disabled={!allCounties?.length}
												className=" mr-5.5 w-40 lg:w-44 xl:w-84"
												label="County"
												name="county"
												options={allCounties}
											/>
											<FormInputAntd
												className=" mr-5.5 w-40 lg:w-44 xl:w-84"
												label="Postal Code"
												name="postalCode"
												type="text"
											/>
											<FormInputAntd
												className=" mr-5.5 w-40 lg:w-44 xl:w-84"
												label="Mobile"
												name="mobile"
												type="text"
											/>
											<FormInputAntd
												className=" mr-5.5 w-40 lg:w-44 xl:w-84"
												label="Telephone"
												name="telephone"
												type="text"
											/>
										</div>
										<Accordion
											containerClassName="w-fit"
											headerTextClassName="p-0 text-lg font-bold"
											bodyClassName="mt-1"
											label="Disclosure"
										>
											<div className="flex flex-col gap-1.5">
												<FormInputAntd
													className=" mr-5.5 w-40 lg:w-44 xl:w-84"
													label="Disclosure Ref"
													name="disclosureRef"
													type="text"
												/>
												<FormSelectAntd
													disabled={!disclosureLevel.length}
													containerClass="max-w-84 w-40 lg:w-44 xl:w-84"
													className=" w-auto"
													label="Disclosure Level"
													name="disclosureLevel"
													options={disclosureLevel.map(({ _id, name }) => ({
														label: name,
														value: _id,
													}))}
												/>
												<DateField
													containerClass=" mr-5.5 w-40 lg:w-44 xl:w-84"
													className="w-full"
													label="Disclosure Sent Date"
													setValue={setValue}
													watch={watch}
													errors={errors}
													name={"disclosureSentDate"}
												/>
												<FormCheckbox
													containerClassName="mr-5.5 mt-3.5 h-10 flex items-center "
													label="POVA Checked"
													name="povaChecked"
												/>

												<DateField
													containerClass=" mr-5.5 w-40 lg:w-44 xl:w-84"
													className="w-full"
													label="POVA Return Date"
													setValue={setValue}
													watch={watch}
													name={"povaReturnDate"}
												/>
												<DateField
													containerClass=" mr-5.5 w-40 lg:w-44 xl:w-84"
													className="w-full"
													label="Disclosure Return Date"
													setValue={setValue}
													watch={watch}
													errors={errors}
													name={"disclosureReturnDate"}
												/>
											</div>
										</Accordion>
									</div>
								</div>
								<AllowedTo perform={Permissions.UPDATE_EMPLOYEE_HR}>
									<AntdButton
										loading={isHRSaving}
										disabled={!isDirty || isSubmitting}
										className="my-2"
										htmlType="submit"
									>
										{hr ? "Update" : "Save"}
									</AntdButton>
								</AllowedTo>
							</form>
						</FormProvider>
					</div>
				</div>
			</div>
		</QualificationContext.Provider>
	)
}

export default HR
