import { DatePicker } from "antd"
import { Button } from "components"
import Modal from "components/Modal"
import styles from "../../../src/antd-datePicker.module.css"
import { useState } from "react"
import moment from "moment"
import { duplicatePayroll, selectPayrollSaving } from "store/Account/payroll.slice"
import useAppDispatch from "hooks/useAppDispatch"
import useAppSelector from "hooks/useAppSelector"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	selectedMonth: string
}

const DuplicatePayrollModal: React.FC<Props> = ({ open, modalHandler, selectedMonth }) => {
	const dispatch = useAppDispatch()
	const [selectedDate, setSelectedDate] = useState(moment().startOf("day").startOf("month"))
	const duplicatePayrollLoading = useAppSelector(selectPayrollSaving)

	const submitDuplicatePayroll = () => {
		dispatch(
			duplicatePayroll({ duplicateFromMonth: selectedMonth, duplicateToMonth: selectedDate?.toISOString() }, () =>
				modalHandler(false),
			),
		)
	}

	return (
		<Modal
			open={open}
			modalHandler={modalHandler}
			title={<div className="font-semi pt-2.5 pb-4 text-center text-sm font-semibold">Duplicate Payroll</div>}
			containerClass="w-1/4"
		>
			<div>
				<div>For Month</div>
				<div className="!h-12 !w-84">
					<DatePicker.MonthPicker
						className={styles.datePickerStyle}
						format={"MM-YYYY"}
						value={selectedDate}
						placeholder={"MM-YYYY"}
						onChange={time => {
							if (time) {
								setSelectedDate(time.startOf("day").startOf("month"))
							}
						}}
					/>
				</div>
				<div className="mt-20 flex justify-end">
					<Button loading={duplicatePayrollLoading} onClick={submitDuplicatePayroll}>
						Duplicate
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default DuplicatePayrollModal
