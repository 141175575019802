import React, { useEffect, useMemo, useState } from "react"
import Customers from "./Customers"
import SDQualifications from "./SDQualifications"
import EquipmentTypes from "./EquipmentTypes"
// import SDDocumentTypes from "./SDDocumentTypes"
import SDCounties from "./SDCounties"
import SDSuppliers from "./SDSupplier"
import SDPayGroups from "./SDPayGroups"
// import SDBillGroup from "./SDBillGroups"
import SDIncidents from "./SDIncidents"
import { Branch } from "pages"
import SDCompanies from "./SDCompanies"
import { getSDCompanies } from "store/StaticData/SDCompanies.slice"
import useAppDispatch from "hooks/useAppDispatch"
import { IS_SUPER_ADMIN, platformTypeENUM } from "config/constants"
import { useAbac } from "react-abac"
import { Divider, Segmented } from "antd-v5"
import { FeatureFlagEnums } from "types/featureFlags.type"
import useFeatureFlags from "hooks/useFeatureFlags"
import CronNotifications from "./CronNotifications"
import { getUsers } from "store/Security/users.slice"
// import SDEventTypes from "./SDEventTypes"

const Index = () => {
	const dispatch = useAppDispatch()
	const { userHasPermissions } = useAbac()
	const { featureFlagEnabled } = useFeatureFlags()
	const [value, setValue] = useState<string>("Branches")
	const options = useMemo(() => {
		return [
			{
				label: "Branches",
				component: <Branch />,
				perform: [],
				featureName: FeatureFlagEnums.ADMIN_STATIC_DATA_BRANCHES,
			},
			{
				label: "Customers",
				component: <Customers />,
				perform: [],
				featureName: FeatureFlagEnums.ADMIN_STATIC_DATA_CUSTOMERS,
			},
			{
				label: "Suppliers",
				component: <SDSuppliers />,
				perform: [],
				featureName: FeatureFlagEnums.ADMIN_STATIC_DATA_SUPPLIERS,
			},
			{
				label: "Notifications",
				component: <CronNotifications />,
				perform: IS_SUPER_ADMIN,
				featureName: FeatureFlagEnums.ADMIN_STATIC_DATA_NOTIFICATIONS,
			},
			{
				label: "Companies",
				component: <SDCompanies />,
				perform: IS_SUPER_ADMIN,
				featureName: FeatureFlagEnums.ADMIN_STATIC_DATA_COMPANIES,
			},
			{
				label: "Qualifications",
				component: <SDQualifications />,
				perform: [],
				featureName: FeatureFlagEnums.ADMIN_STATIC_DATA_QUALIFICATIONS,
			},
			// {label:"Event Types",  component: <SDEventTypes />, perform:[], featureName: FeatureFlagEnums.ADMIN_STATIC_DATA_EVENT_TYPES},
			{
				label: "Equipment Types",
				component: <EquipmentTypes />,
				perform: [],
				featureName: FeatureFlagEnums.ADMIN_STATIC_DATA_EQUIPMENT_TYPES,
			},
			// { label: "Reasons", component: <></>, perform: [] },
			{
				label: "Counties",
				component: <SDCounties />,
				perform: [],
				featureName: FeatureFlagEnums.ADMIN_STATIC_DATA_COUNTIES,
			},
			// { label: "CT Messages", component: <></>, perform: [] , featureName: FeatureFlagEnums.ADMIN_STATIC_DATA_CT_MESSAGES},
			{
				label: "Incidents",
				component: <SDIncidents />,
				perform: [],
				featureName: FeatureFlagEnums.ADMIN_STATIC_DATA_INCIDENTS,
			},
			{
				label: "Pay Groups",
				component: <SDPayGroups />,
				perform: [],
				featureName: FeatureFlagEnums.ADMIN_STATIC_DATA_PAY_GROUPS,
			},
			// { label:"Bill Groups",  component: <SDBillGroup />, perform:[], featureName: FeatureFlagEnums.ADMIN_STATIC_DATA_BILL_GROUPS},
			// { label: "HR Lists", component: <></>, perform: [] , featureName: FeatureFlagEnums.ADMIN_STATIC_DATA_HR_LISTS},
			// { label: "Document Types", component: <SDDocumentTypes /> , perform:[], featureName: FeatureFlagEnums.ADMIN_STATIC_DATA_DOCUMENT_TYPES},
		]
			.map(item => ({ ...item, value: item.label }))
			.filter(item => userHasPermissions(item.perform) && featureFlagEnabled(item.featureName))
	}, [])
	useEffect(() => {
		dispatch(getSDCompanies())
		dispatch(getUsers(platformTypeENUM.WEB))
	}, [])
	return (
		<div>
			<Divider style={{ margin: "8px", marginLeft: "0px", marginRight: "0px" }} />
			<div className="flex items-center justify-start">
				<Segmented options={options as any} value={value} onChange={(e: any) => setValue(e)} />
			</div>
			<Divider style={{ margin: "8px", marginLeft: "0px", marginRight: "0px" }} />
			<div className="px-2">{options?.find(item => item.value === value)?.component}</div>
		</div>
	)
}

export default Index
