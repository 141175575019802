import moment from "moment"
import styles from "../../../src/antd-datePicker.module.css"
import MasterSheet from "./MasterSheet"
import LocationHoursModal from "./LocationHoursModal"
import DuplicatePayrollModal from "./DuplicatePayrollModal"
import { round } from "lodash"
import { momentZ } from "config/moment"
import { DatePicker } from "antd"
import { suppliersDropdownList } from "store/StaticData/SDSuppliers.slice"
import { useEffect, useMemo, useState } from "react"
import { selectBranchesMappedAsOptions } from "store/Branches/branch.slice"
import { useAppDispatch, useAppSelector } from "hooks"
import { employeeListByMultipleBranchOrSupplierName } from "store/Employee/detail.slice"
import { FilterEmployeeFromMultipleSupplierOrBranchInterface } from "types/schedules.types"
import { getPayrollTerms, isPayrollTermLoading, selectPayrollTermList } from "store/PayBill/payRollTerms.slice"
import {
	Tab,
	Icon,
	Tabs,
	Input,
	Button,
	Select,
	Section,
	AntdSelect,
	SearchField,
	SidebarList,
	GlobalSidebar,
	SortableTable,
	FeatureFlagWrapper,
} from "components"
import {
	getPayrolls,
	savePayrolls,
	selectPayrollSaving,
	selectPayrollLoading,
	selectPayrollDataByDate,
	selectHoursWorkedLoading,
	getScheduleHoursByEmployee,
	selectPayrollSideBarMonths,
} from "store/Account/payroll.slice"
import { FeatureFlagEnums } from "types/featureFlags.type"

const Accounts = () => {
	const [searchString, setSearchString] = useState<string>("")
	const [selectedMonth, setSelectedMonth] = useState<string>("")
	const [creatingNew, setCreatingNew] = useState<boolean>(false)
	const [open, setOpen] = useState(false)
	const [modalData, setModalData] = useState<{
		_id: string
		locationHours: { hour: number; location: string; locationName: string }[]
		locationRates?: { rate: number; additionalHours: number; location: string }[]
		payslipExpenses?: number
		otherExpenses?: number
		name: string
		pin: string
		comments: string
	}>()
	const [openDuplicateModal, setOpenDuplicateModal] = useState(false)
	const branchesOptions = useAppSelector(selectBranchesMappedAsOptions())
	const supplierOptions = useAppSelector(suppliersDropdownList)

	const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null)
	const [formData, setFormData] = useState<{ [key: string]: any }>({})

	const dispatch = useAppDispatch()
	const monthsList = useAppSelector(selectPayrollSideBarMonths())
	const employeePayrollData = useAppSelector(
		selectPayrollDataByDate(selectedMonth || selectedDate?.toISOString() || ""),
	)
	const loading = useAppSelector(selectPayrollLoading)
	const saving = useAppSelector(selectPayrollSaving)
	const payRollTerms = useAppSelector(selectPayrollTermList)
	const payrollTermsLoading = useAppSelector(isPayrollTermLoading())
	const loadingHoursWorked = useAppSelector(selectHoursWorkedLoading)

	const [filterOptions, setFilterOptions] = useState<FilterEmployeeFromMultipleSupplierOrBranchInterface>({
		filterId: [],
		filterFrom: "",
	})
	const employeeList = useAppSelector(
		employeeListByMultipleBranchOrSupplierName(filterOptions.filterId, filterOptions.filterFrom),
	)

	const monthExtractedFromList = Array.from(
		new Set(
			monthsList
				?.map(entity => ({
					name: momentZ(entity).format("MMMM YYYY"),
					_id: entity,
				}))
				.sort((a, b) => momentZ(b._id).diff(momentZ(a._id))),
		),
	)

	const filteredTableData = useMemo(() => {
		return employeeList
			.filter(employee => !employee.supplier)
			.filter(employee => {
				const payRoll = employeePayrollData?.find(entity => entity.employee === employee._id)

				if (selectedMonth && payRoll?.month) {
					if (payRoll?.month === selectedMonth) return true
					else return false
				} else return true
			})
			.map(employee => {
				const payRoll = employeePayrollData?.find(entity => entity.employee === employee._id)

				return {
					name: `${employee.firstName} ${employee.lastName}`,
					pin: employee.pin,
					_id: employee._id,
					documentId: payRoll?._id || null,
					branch: employee.branch
						? branchesOptions.find(branch => branch.value === employee.branch)?.label
						: null,
					supplier: employee.supplier
						? supplierOptions.find(supplier => supplier.value === employee.supplier)?.label
						: null,
					...(!creatingNew && payRoll
						? {
								uniformHours: round((payRoll.hoursWorked ?? 0) - payRoll.hours, 2),
								hoursWorked: round(payRoll.hoursWorked || 0, 2),
								hours: payRoll.hours,
								payrollTerm: payRoll.payrollTerm,
								payrollTermLabel: payRollTerms?.find(item => item._id === payRoll.payrollTerm)?.name,
								payDay: payRoll.payDay,
								payrollNumber: payRoll.payrollNumber,
						  }
						: {}),
					locationHours: payRoll?.locationHours,
					locationRates: payRoll?.locationRates,
					payslipExpenses: payRoll?.payslipExpenses,
					otherExpenses: payRoll?.otherExpenses,
					hourlyRate: payRoll?.hourlyRate,
					comments: payRoll?.comments,
					// bankHolidayHours: payRoll?.bankHolidayHours,
				}
			})
	}, [employeeList, monthExtractedFromList, creatingNew, employeePayrollData, selectedDate, selectedMonth])

	filteredTableData.sort((a, b) => Number(a.pin) - Number(b.pin))

	const handleTimeChange = (value: moment.Moment | null) => {
		if (value) {
			value.startOf("day").startOf("month")
			setSelectedDate(value)
			setSelectedMonth(value?.toISOString())
		}
	}

	useEffect(() => {
		dispatch(getPayrolls())
		dispatch(getPayrollTerms())
	}, [])

	useEffect(() => {
		if (selectedMonth) {
			setCreatingNew(false)
			setSelectedDate(null)
			dispatch(getScheduleHoursByEmployee(selectedMonth))
		}
	}, [selectedMonth])

	useEffect(() => {
		if (monthExtractedFromList.length > 0 && !selectedMonth && !creatingNew) {
			const currentMonth = momentZ().format("MMMM YYYY")
			const monthFound = monthExtractedFromList.find(ele => ele.name === currentMonth)
			if (monthFound) setSelectedMonth(monthFound._id)
			else setSelectedMonth(monthExtractedFromList[0]?._id)
		}
	}, [monthExtractedFromList])

	const submitHandler = () => {
		const payload: {
			employee: string
			month: string

			locationRates: any
			hours: number
			_id?: string
			payrollTerm?: string
			payDay?: number
			payrollNumber?: number
			payslipExpenses?: number
			otherExpenses?: number
			hourlyRate?: number
			bankHolidayHours?: number
			hoursWorked?: number
			comments?: string
		}[] = []
		Object.entries(formData).forEach(([key, value]) => {
			const existingData = filteredTableData?.find(val => val?._id === key)

			payload.push({
				employee: key,
				month: selectedDate?.toISOString() || selectedMonth,
				comments: value.comments ? value.comments : existingData?.comments || "",

				hours: !isNaN(Number(value.hours)) ? Number(value.hours) : Number(existingData?.hours),
				payrollTerm: value?.payrollTerm || existingData?.payrollTerm,
				payDay: !isNaN(Number(value?.payDay)) ? Number(value?.payDay) : Number(existingData?.payDay),
				payrollNumber: !isNaN(Number(value?.payrollNumber))
					? Number(value?.payrollNumber)
					: Number(existingData?.payrollNumber),
				locationRates: value.locationRates || existingData?.locationRates,
				...(existingData?.documentId ? { _id: existingData?.documentId } : {}),
				payslipExpenses: !isNaN(Number(value?.payslipExpenses))
					? Number(value?.payslipExpenses)
					: Number(existingData?.payslipExpenses),
				otherExpenses: !isNaN(Number(value?.otherExpenses))
					? Number(value?.otherExpenses)
					: Number(existingData?.otherExpenses),
				hourlyRate: !isNaN(Number(value?.hourlyRate))
					? Number(value?.hourlyRate)
					: Number(existingData?.hourlyRate),
				// bankHolidayHours: !isNaN(Number(value?.bankHolidayHours))
				// 	? Number(value?.bankHolidayHours)
				// 	: Number(existingData?.bankHolidayHours),
				...(value?.hoursWorked && !isNaN(Number(value?.hoursWorked)) ? { hoursWorked: value.hoursWorked } : {}),
			})
		})

		const update = payload?.some(entry => entry?._id)

		if (payload) {
			dispatch(
				savePayrolls(payload, update, () => {
					setFormData({})
					setCreatingNew(false)
					setSelectedDate(null)
				}),
			)
		}
	}
	const dataHandler = (
		locationHours: [],
		additionalHour: [],
		expenses: { payslipExpenses: number; otherExpenses: number },
		comments: string,
	) => {
		const payload: { location: string; rate: number; additionalHours: number }[] = []
		if (modalData) {
			const keys = Object.keys(locationHours)
			if (keys && Object.keys(additionalHour).length > 0) {
				keys.forEach(key => {
					payload.push({
						location: key,
						rate: Number(locationHours[key as any]),
						additionalHours: Number(additionalHour[key as any]),
					})
				})
			}

			if (modalData.locationRates) {
				modalData.locationRates
					.filter(entity => !payload.some(entry => entity.location === entry.location))
					.forEach(element => {
						payload.push(element)
					})
			}

			setFormData((prev: any) => ({
				...prev,
				[modalData?._id]: {
					...prev[modalData._id],
					...(payload.length && { locationRates: payload }),
					...(expenses.payslipExpenses && { payslipExpenses: expenses.payslipExpenses }),
					...(expenses.otherExpenses && { otherExpenses: expenses.otherExpenses }),
					comments,
				},
			}))
		}
	}

	useEffect(() => {
		if (modalData && Object.entries(modalData).length && Object.entries(formData).length) {
			submitHandler()
			setModalData(undefined)
		}
	}, [formData])
	const minimumPayrate = useMemo(() => {
		return moment(selectedMonth).valueOf() >= moment("01-05-2024", "DD-MM-YYYY").startOf("month").valueOf()
			? 11.44
			: 10.42
	}, [selectedMonth])
	return (
		<div>
			<GlobalSidebar>
				<div className="h-full  outline-none">
					<div className="px-2 py-2 ">
						<SearchField
							placeholder="Search...."
							setSearchString={setSearchString}
							searchString={searchString}
							className="mb-2 h-10 !w-full !py-0 !pr-5 text-sm"
						/>
					</div>
					<div className="h-[58vh] 3xl:h-[64vh]">
						<SidebarList
							loading={false}
							selected={selectedMonth}
							setSelected={setSelectedMonth}
							list={monthExtractedFromList}
						/>
					</div>
				</div>
			</GlobalSidebar>
			<Section>
				<Tabs initialTab={0}>
					<FeatureFlagWrapper name={FeatureFlagEnums.ACCOUNTS_PAYROLL}>
						<Tab title="Payroll">
							<div className="flex flex-col space-y-3">
								<div className="mt-3 flex h-[10vh] items-center justify-start space-x-10  px-6">
									<div className="h-24">
										<div>Branch</div>
										<AntdSelect
											isControlled
											allowClear={true}
											selected={filterOptions.filterId || []}
											onChange={e => {
												if (Array.isArray(e))
													setFilterOptions({ filterId: e, filterFrom: "branch" })
											}}
											mode={"multiple"}
											name="branch"
											containerClass="w-72 h-10! space-y-0"
											options={branchesOptions}
										/>
									</div>
									<div className="h-24  flex-col">
										<div>From Month</div>
										<div className="!h-12 !w-72">
											<DatePicker.MonthPicker
												disabled={!!selectedMonth}
												className={styles.datePickerStyle}
												format={"MM-YYYY"}
												value={selectedDate}
												placeholder={"MM-YYYY"}
												onChange={handleTimeChange}
											/>
										</div>
									</div>
									<div className="flex  h-24 items-center justify-center space-x-4">
										<Button
											onClick={submitHandler}
											disabled={
												!(selectedDate || selectedMonth) ||
												!Object.keys(formData).length ||
												saving
											}
											loading={saving}
										>
											{!filteredTableData.some(entity => entity.documentId) ? "Save" : "Update"}
										</Button>
										<Button
											onClick={() => {
												setCreatingNew(true)
												setFormData({})
												setSelectedMonth("")
											}}
											disabled={saving}
										>
											New
										</Button>
									</div>
									{!creatingNew && (
										<div>
											<Button onClick={() => setOpenDuplicateModal(true)}>
												Duplicate Payroll
											</Button>
										</div>
									)}
								</div>
								<div className="h-[73vh] w-full overflow-y-scroll ">
									{loading || payrollTermsLoading || loadingHoursWorked ? (
										<div className="flex items-center justify-center">
											<Icon
												name="spinner"
												size={20}
												color="black"
												className="relative animate-spin"
											/>
										</div>
									) : (
										<SortableTable
											rowClassName="odd:bg-white even:bg-slate-50"
											columns={[
												{ label: "Pin", key: "pin", className: "text-center" },
												{ label: "Name", key: "name", className: "text-left pl-6" },
												{
													label: "Pay Day",
													key: "payDay",
													className: "text-center",
													render: ({ row, key }) => (
														<Input
															className={`h-0.5 w-16  text-sm`}
															value={formData?.[row["_id"]]?.[key] ?? (row[key] || "")}
															inputMode="numeric"
															onChange={e => {
																if (e) {
																	setFormData((prev: any) => ({
																		...prev,
																		[row["_id"]]: {
																			...prev[row["_id"]],
																			[key]: e.target.value,
																		},
																	}))
																}
															}}
														/>
													),
												},
												{
													label: "Payroll No.",
													key: "payrollNumber",
													className: "text-center",
													render: ({ row, key }) => (
														<Input
															className={`h-0.5 w-16  text-sm`}
															value={formData?.[row["_id"]]?.[key] ?? (row[key] || "")}
															inputMode="numeric"
															onChange={e => {
																if (e) {
																	setFormData((prev: any) => ({
																		...prev,
																		[row["_id"]]: {
																			...prev[row["_id"]],
																			[key]: e.target.value,
																		},
																	}))
																}
															}}
														/>
													),
												},
												{
													label: "Branch",
													key: "branch",
													className: "text-center pl-3",
													render: ({ row }) => (
														<div>{row?.["branch"] || row?.["supplier"]}</div>
													),
												},
												{
													label: "Status",
													key: "payrollTerm",
													keyForSorting: "payrollTermLabel",
													className: "text-center pl-5",
													render: ({ row, key }) => (
														<div>
															<Select
																className={"w-36 text-sm"}
																placeholder={"Select Payroll"}
																value={formData?.[row["_id"]]?.[key] || row[key] || ""}
																onChange={e => {
																	if (e.target.value)
																		setFormData((prev: any) => ({
																			...prev,
																			[row["_id"]]: {
																				...prev[row["_id"]],
																				[key]: e.target.value,
																			},
																		}))
																}}
															>
																<option
																	disabled={
																		formData?.[row["_id"]]?.[key] ? true : false
																	}
																>
																	Select Payroll
																</option>
																{payRollTerms?.map((payroll: any) => (
																	<option key={payroll._id} value={payroll._id}>
																		{payroll.name}
																	</option>
																))}
															</Select>
														</div>
													),
												},
												{
													label: "Total Hours",
													key: "hoursWorked",
													className: "text-center",
													render: ({ row, key }) => (
														<Input
															className={`h-0.5 w-16  text-sm`}
															value={formData?.[row["_id"]]?.[key] ?? (row[key] || "")}
															inputMode="numeric"
															disabled={true}
															onChange={e => {
																if (e) {
																	setFormData((prev: any) => ({
																		...prev,
																		[row["_id"]]: {
																			...prev[row["_id"]],
																			[key]: e.target.value,
																			uniformHours:
																				(Number(e.target.value) || 0) -
																				(prev[row["_id"]]?.hours ||
																					row["hours"]),
																		},
																	}))
																}
															}}
														/>
													),
												},
												{
													label: "Payroll hours",
													key: "hours",
													className: "text-center",
													render: ({ row, key }) => (
														<Input
															className={`h-0.5 w-16  text-sm`}
															value={formData?.[row["_id"]]?.[key] ?? (row[key] || "")}
															onChange={e => {
																if (e) {
																	setFormData((prev: any) => ({
																		...prev,
																		[row["_id"]]: {
																			...prev[row["_id"]],
																			[key]: e.target.value,
																			uniformHours:
																				(prev[row["_id"]]?.hoursWorked ||
																					row["hoursWorked"]) -
																					Number(e.target.value) ?? undefined,
																		},
																	}))
																}
															}}
															inputMode="numeric"
														/>
													),
												},
												{
													label: "Remaining Hours",
													key: "uniformHours",
													className: "text-center",
													render: ({ row, key }) => (
														<span className={`h-0.5 w-24  text-sm`}>
															{formData?.[row["_id"]]?.[key] ?? row[key] ?? 0}
														</span>
													),
												},
												{
													label: "Hourly Rate",
													key: "hourlyRate",
													className: "text-center",
													render: ({ row, key }) => (
														<Input
															className={`h-0.5 w-16  text-sm`}
															value={
																formData?.[row["_id"]]?.[key] ??
																(row[key] || minimumPayrate)
															}
															inputMode="numeric"
															onChange={e => {
																if (e) {
																	setFormData((prev: any) => ({
																		...prev,
																		[row["_id"]]: {
																			...prev[row["_id"]],
																			[key]: e.target.value,
																		},
																	}))
																}
															}}
														/>
													),
												},
												// {
												// 	label: "Bank Holiday Hours",
												// 	key: "bankHolidayHours",
												// 	className: "text-center",
												// 	render: ({ row, key }) => (
												// 		<Input
												// 			className={`h-0.5 w-16  text-sm`}
												// 			value={formData?.[row["_id"]]?.[key] ?? (row[key] || "")}
												// 			inputMode="numeric"
												// 			onChange={e => {
												// 				if (e) {
												// 					setFormData((prev: any) => ({
												// 						...prev,
												// 						[row["_id"]]: {
												// 							...prev[row["_id"]],
												// 							[key]: e.target.value,
												// 						},
												// 					}))
												// 				}
												// 			}}
												// 		/>
												// 	),
												// },
												{
													label: "Rates & Expenses",
													key: "",
													className: "text-center",
													render: ({ row }) => {
														const showWarning =
															row?.locationHours?.length &&
															!row.locationHours?.every((item: { location: string }) => {
																return row?.locationRates?.some(
																	(entity: { location: string }) =>
																		entity.location === item.location,
																)
															})
														return (
															<div className="flex items-center justify-start">
																<div>
																	<Button
																		onClick={() => {
																			setModalData(row)
																			setOpen((open: boolean) => !open)
																		}}
																	>
																		Add
																	</Button>
																</div>
																<div>
																	{showWarning && (
																		<Icon
																			name="warning"
																			size={17}
																			color="danger"
																			className="relative"
																		/>
																	)}
																</div>
															</div>
														)
													},
												},
											]}
											rows={filteredTableData}
										/>
									)}
									{openDuplicateModal && (
										<DuplicatePayrollModal
											open={openDuplicateModal}
											modalHandler={() => setOpenDuplicateModal(false)}
											selectedMonth={selectedMonth}
										/>
									)}
								</div>
							</div>
						</Tab>
					</FeatureFlagWrapper>
					<FeatureFlagWrapper name={FeatureFlagEnums.ACCOUNTS_MASTER_SHEET}>
						<Tab title="Master Sheet">
							<MasterSheet data={filteredTableData} selectedMonth={selectedMonth} />
						</Tab>
					</FeatureFlagWrapper>
				</Tabs>
			</Section>
			<LocationHoursModal
				open={open}
				modalHandler={setOpen}
				data={modalData}
				parentFormData={formData?.[modalData?._id || ""] ?? {}}
				dataHandler={dataHandler}
			/>
		</div>
	)
}

export default Accounts
