import { useEffect, useState } from "react"
import useAppDispatch from "hooks/useAppDispatch"
import { getSDIncidents } from "store/StaticData/SDIncidents.slice"
import IncidentModal from "pages/workMonitor/IncidentModal"
import useAppSelector from "hooks/useAppSelector"
import { selectSelectedLocation } from "store/globalState.slice"
import {
	getLocationIncidents,
	getLocationShiftIncidents,
	locationIncidentsList,
	locationShiftIncidentsList,
	saveIncident,
} from "store/Location/locationIncidents.slice"
import { AntdButton, Tab, Tabs } from "components"
import { PlusOutlined } from "@ant-design/icons"

import moment from "moment"
import { DATE_FORMATS } from "config/constants"
import { LocationIncidentInterface } from "@type/locations.types"
import { Table } from "antd-v5"
import { generatePDF } from "pages/schedules/Location/IncidentInfo"
import { CloudDownloadOutlined } from "@ant-design/icons"
import { selectSelectedLocationData } from "store/Location/locationDetails.slice"

const Incidents: React.FC = () => {
	const [openModal, setOpenModal] = useState(false)
	const dispatch = useAppDispatch()
	const selected = useAppSelector(selectSelectedLocation)
	const incidentList = useAppSelector(locationIncidentsList())
	const shiftsIncidentList = useAppSelector(locationShiftIncidentsList())

	const [selectedIncident, setSelectedIncident] = useState<LocationIncidentInterface | null>(null)
	const selectedLocation = useAppSelector(selectSelectedLocationData)

	useEffect(() => {
		dispatch(getSDIncidents())
	}, [])

	useEffect(() => {
		dispatch(getLocationIncidents(selected))
		dispatch(getLocationShiftIncidents(selected))
	}, [selected])

	return (
		<>
			<Tabs initialTab={0}>
				<Tab title="Site Incidents" hasHeader shouldOverflow={false}>
					<Table
						size="small"
						pagination={{
							position: ["bottomRight"],
							defaultPageSize: 12,
							pageSize: 12,
						}}
						dataSource={incidentList}
						columns={[
							{
								title: "Time Of Event",
								key: "date",
								dataIndex: "date",
								render: value => <div> {moment(value).format(DATE_FORMATS.DD_MM_YYYY_HH_MM)}</div>,
							},
							{
								title: "Incident",
								key: "incident",
								dataIndex: "incident",
							},
							{
								title: "Action Taken",
								key: "actionTaken",
								dataIndex: "actionTaken",
								render: value => <div> {value ? "Y" : "N"}</div>,
							},
							{
								title: "Control Informed",
								key: "controlInformed",
								dataIndex: "controlInformed",
								render: value => <div> {value ? "Y" : "N"}</div>,
							},
							{
								title: "Police Requested",
								key: "policeRequested",
								dataIndex: "policeRequested",
								render: value => <div> {value ? "Y" : "N"}</div>,
							},
							{
								title: "Action",
								key: "",
								dataIndex: "",
								render: (_, row) => (
									<AntdButton
										onClick={() => {
											setSelectedIncident(selectedRow => (selectedRow === row ? null : row))
											setOpenModal(true)
										}}
									>
										{selectedIncident === row ? "Editing." : "Update"}
									</AntdButton>
								),
							},
							{
								title: "Report",
								key: "",
								render: (_, row) => (
									<AntdButton
										className="flex items-center justify-center"
										icon={<CloudDownloadOutlined />}
										onClick={() => generatePDF({ ...row, siteName: selectedLocation?.name } as any)}
									>
										Download
									</AntdButton>
								),
							},
						]}
					/>
					<div className="flex justify-end pt-4">
						<AntdButton icon={<PlusOutlined />} onClick={() => setOpenModal(val => !val)}>
							Add New
						</AntdButton>
					</div>
				</Tab>
				<Tab title="Shift Incidents" hasHeader shouldOverflow={false}>
					<Table
						size="small"
						pagination={{
							position: ["bottomRight"],
							defaultPageSize: 12,
							pageSize: 12,
						}}
						dataSource={shiftsIncidentList}
						columns={[
							{
								title: "Time Of Event",
								key: "date",
								dataIndex: "date",
								render: value => <div> {moment(value).format(DATE_FORMATS.DD_MM_YYYY_HH_MM)}</div>,
							},
							{
								title: "Incident",
								key: "incident",
								dataIndex: "incident",
							},
							{
								title: "Action Taken",
								key: "actionTaken",
								dataIndex: "actionTaken",
								render: value => <div> {value ? "Y" : "N"}</div>,
							},
							{
								title: "Control Informed",
								key: "controlInformed",
								dataIndex: "controlInformed",
								render: value => <div> {value ? "Y" : "N"}</div>,
							},
							{
								title: "Police Requested",
								key: "policeRequested",
								dataIndex: "policeRequested",
								render: value => <div> {value ? "Y" : "N"}</div>,
							},
							{
								title: "Report",
								key: "",
								render: (_, row) => (
									<AntdButton
										className="flex items-center justify-center"
										icon={<CloudDownloadOutlined />}
										onClick={() => generatePDF({ ...row })}
									>
										Download
									</AntdButton>
								),
							},
						]}
					/>
				</Tab>
			</Tabs>

			<IncidentModal
				isForLocation
				open={openModal}
				modalHandler={(open: boolean) => {
					setOpenModal(open)
					setSelectedIncident(null)
				}}
				selectedRow={null}
				defaultFields={selectedIncident ?? undefined}
				submitHandler={data => {
					if (selectedIncident && Object.values(selectedIncident).length) {
						dispatch(saveIncident({ ...data, id: selectedIncident?._id, location: selected }))
					} else dispatch(saveIncident({ ...data, location: selected }))
				}}
			/>
		</>
	)
}
export default Incidents
