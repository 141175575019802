import React from "react"
import { ColorName, IconName } from "components/types"
import iconMap from "./map"
import { theme } from "config"

export interface IconProps {
	name?: IconName
	color?: ColorName
	size?: number
	className?: string
}

const Icon: React.FC<IconProps> = ({ name = "spinner", size = 24, color = "white", className = "" }) => {
	const Component = iconMap[name]
	return <Component name={name} size={size} color={theme.colors[color]} className={className} />
}

export default Icon
