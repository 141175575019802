import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm } from "react-hook-form"
import { Modal, TimeField, AntdButton, FormInputAntd } from "components"
import { CreateSchedulesInterface } from "@type/schedules.types"
import { SchedulesByLocationValidationSchema } from "Schema"
import useAppSelector from "hooks/useAppSelector"
import { saveScheduleByEmployee, selectScheduleSaving } from "store/Schedules/schedules.slice"
import useAppDispatch from "hooks/useAppDispatch"
import { TableCell } from "./EmployeeTable"
import { AddDateIfFinishTimeIsGreater, convertDateTimeStringToIsoUtc } from "config/utils"

export interface Payload {
	postId: string
	dates: { startTime: string; finishTime: string }[]
}

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	selectedSchedules: TableCell[]
	setSelectedCell: (data: []) => void
}

const UpdateEmployeeScheduleModal: React.FC<Props> = ({ open, modalHandler, selectedSchedules, setSelectedCell }) => {
	const methods = useForm<CreateSchedulesInterface>({
		resolver: yupResolver(SchedulesByLocationValidationSchema),
		shouldUnregister: true,
	})

	const {
		handleSubmit,
		setValue,
		watch,
		formState: { isDirty },
	} = methods

	const dispatch = useAppDispatch()
	const isScheduleSaving = useAppSelector(selectScheduleSaving())

	const onSubmit = (data: CreateSchedulesInterface) => {
		const { startTime, finishTime, notes } = data

		const schedulePayload = selectedSchedules.map(cell => {
			const starTimeMoment = convertDateTimeStringToIsoUtc(`${cell.date} ${startTime}`)
			let finishTimeMoment = convertDateTimeStringToIsoUtc(`${cell.date} ${finishTime}`)

			finishTimeMoment = AddDateIfFinishTimeIsGreater(starTimeMoment, finishTimeMoment)

			return {
				_id: cell._id,
				startTime: starTimeMoment,
				finishTime: finishTimeMoment,
			}
		})

		const payload = { notes, ids: schedulePayload }
		dispatch(
			saveScheduleByEmployee(payload, () => {
				setSelectedCell([])
				modalHandler(false)
			}),
		)
	}

	return (
		<Modal
			containerClass="min-w-[600px]"
			open={open}
			title={
				<div className="py-3 text-center text-md font-bold uppercase tracking-wider text-dark">
					{"Update"} Schedules
				</div>
			}
			modalHandler={open => {
				setValue("employee", "")
				modalHandler(open)
			}}
		>
			<div className="flex items-center justify-between py-2">
				{!!selectedSchedules.length && (
					<span className="text-md font-semibold text-dark">
						Location: {selectedSchedules[0].location?.name || ""}
					</span>
				)}
			</div>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)} className="w-full">
					<div className="grid grid-cols-2 gap-2">
						<TimeField
							label="Start Time"
							watch={watch}
							formValueSetter={setValue}
							name="startTime"
							defaultFocusOn={true}
						/>
						<TimeField label="Finish Time" watch={watch} formValueSetter={setValue} name="finishTime" />

						<div className="col-span-2">
							<FormInputAntd type="textarea" label="Notes" name="notes" />
						</div>
					</div>
					<AntdButton className="mt-5" loading={isScheduleSaving} disabled={!isDirty} htmlType="submit">
						Save
					</AntdButton>
				</form>
			</FormProvider>
		</Modal>
	)
}

export default UpdateEmployeeScheduleModal
