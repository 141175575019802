import React from "react"
import cx from "classnames"

interface PropsType {
	children?: JSX.Element | string
	className?: string
}

const Section: React.FC<PropsType> = ({ children, className = "" }) => {
	return <div className={cx(" mx-2.5 my-3 rounded-2.5 bg-white shadow-shadowCustom", className)}>{children}</div>
}

export default Section
