import React from "react"
import { Modal } from "components"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	message: string
}

const AlertModal: React.FC<Props> = ({ open, modalHandler, message }) => {
	return (
		<Modal
			open={open}
			title={<div className="font-semi select-none pt-2.5 text-center text-xl font-semibold">Alert !!</div>}
			modalHandler={modalHandler}
		>
			<p className="select-none text-center">{message}</p>
		</Modal>
	)
}

export default AlertModal
