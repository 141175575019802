import { updateState } from "config/utils"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EmployeeQualificationInterface, EmployeeQualificationSiaInterface, LdnInterface } from "@type/employees.types"
import { qualificationService } from "services"
import { AppThunk, RootState } from "store"

export interface QualificationStateInterface {
	data: EmployeeQualificationInterface[]
	qualificationLoading: boolean
	loading: boolean
	saving: boolean
	approvalLoading: boolean
}

const initialState: QualificationStateInterface = {
	data: [],
	qualificationLoading: false,
	loading: false,
	saving: false,
	approvalLoading: false,
}

const qualificationSlice = createSlice({
	name: "qualifications",
	initialState,
	reducers: {
		requestQualificationData: qualification => {
			qualification.loading = true
		},
		receiveQualificationData: (qualification, action: PayloadAction<EmployeeQualificationInterface[]>) => {
			qualification.loading = false
			qualification.data = action.payload
		},
		receiveQualificationDataFailed: qualification => {
			qualification.loading = false
		},
		requestSavingQualificationData: qualificationData => {
			qualificationData.saving = true
		},
		requestSavingStateQualificationData: qualificationData => {
			qualificationData.approvalLoading = true
		},
		SavingEmployeeQualificationSuccess: (qualification, action: PayloadAction<EmployeeQualificationInterface>) => {
			qualification.saving = false
			qualification.approvalLoading = false
			const index = qualification.data?.findIndex(d => d._id === action.payload._id)
			if (index !== -1) {
				qualification.data?.splice(index as number, 1, action.payload)
			} else {
				qualification.data = [...(qualification.data as EmployeeQualificationInterface[]), action.payload]
			}
		},
		SavingEmployeeQualificationSuccessFail: qualification => {
			qualification.saving = false
			qualification.approvalLoading = false
		},
		updateLdnForEmployeeQualification: (state, action: PayloadAction<EmployeeQualificationInterface>) => {
			state.saving = false
			const [updatedState] = updateState(state.data, action.payload, "_id")
			state.data = updatedState
		},
		deleteQualificationData: (qualification, action: PayloadAction<EmployeeQualificationInterface>) => {
			if (qualification.data)
				qualification.data = qualification.data?.filter(element => element._id !== action.payload._id)
			qualification.loading = false
		},
		clearQualificationData: state => {
			state.data = []
		},
	},
})

//REDUCER
export default qualificationSlice.reducer

//ACTIONS
export const {
	requestQualificationData,
	receiveQualificationData,
	receiveQualificationDataFailed,
	requestSavingQualificationData,
	SavingEmployeeQualificationSuccess,
	SavingEmployeeQualificationSuccessFail,
	updateLdnForEmployeeQualification,
	deleteQualificationData,
	requestSavingStateQualificationData,
	clearQualificationData,
} = qualificationSlice.actions

const getEmployeesQualificationByEmployeeId =
	(employeeId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestQualificationData())
			const { data: qualificationResponse } = await qualificationService.getEmployeesQualification(employeeId)
			dispatch(receiveQualificationData(qualificationResponse))
		} catch (error) {
			dispatch(receiveQualificationDataFailed())
		}
	}
const deleteEmployeesQualificationById =
	(qualificationId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestQualificationData())
			const { data: qualificationResponse } = await qualificationService.deleteEmployeesQualification(
				qualificationId,
			)
			dispatch(deleteQualificationData(qualificationResponse))
		} catch (error) {
			console.warn(error)
		}
	}

const saveEmployeeQualificationData =
	(qualificationData: EmployeeQualificationInterface): AppThunk =>
	async dispatch => {
		try {
			let data = null
			dispatch(requestSavingStateQualificationData())
			if (qualificationData._id) {
				data = await qualificationService.updateEmployeeQualification(qualificationData._id, qualificationData)
			} else {
				data = await qualificationService.createEmployeeQualification(qualificationData)
			}
			const { data: qualificationResponse } = data
			dispatch(SavingEmployeeQualificationSuccess(qualificationResponse))
		} catch (error) {
			dispatch(SavingEmployeeQualificationSuccessFail())
		}
	}

const saveEmployeeQualificationSia =
	(qualificationData: EmployeeQualificationInterface, cb?: (qualificationId: string) => void): AppThunk =>
	async dispatch => {
		try {
			let data = null
			dispatch(requestSavingQualificationData())
			if (qualificationData._id) {
				data = await qualificationService.updateEmployeeQualification(qualificationData._id, qualificationData)
			} else {
				data = await qualificationService.createEmployeeQualificationSia(qualificationData)
			}
			const { data: qualificationResponse } = data
			cb?.(qualificationResponse._id)
			dispatch(SavingEmployeeQualificationSuccess(qualificationResponse))
		} catch (error) {
			dispatch(SavingEmployeeQualificationSuccessFail())
		}
	}

const updateEmployeeQualificationSiaImage =
	(employeeQualification: EmployeeQualificationSiaInterface, cb?: (qualificationId: string) => void): AppThunk =>
	async dispatch => {
		try {
			let data = null
			dispatch(requestSavingQualificationData())
			data = await qualificationService.updateEmployeeQualificationSia(
				employeeQualification._id,
				employeeQualification,
			)
			const { data: qualificationResponse } = data
			dispatch(SavingEmployeeQualificationSuccess(qualificationResponse))
			cb?.(qualificationResponse._id)
		} catch (error) {
			dispatch(SavingEmployeeQualificationSuccessFail())
		}
	}

const saveLdnForEmployeeQualification =
	(employeeQualificationId: string, payload: LdnInterface, create: boolean, cb?: () => void): AppThunk =>
	async dispatch => {
		try {
			let data = null
			dispatch(requestSavingQualificationData())
			if (create) {
				data = await qualificationService.createLdnForEmployeeQualification(employeeQualificationId, payload)
			} else {
				data = await qualificationService.updateLdnForEmployeeQualification(employeeQualificationId, payload)
			}
			const { data: ldnQualificationResponse } = data
			dispatch(updateLdnForEmployeeQualification(ldnQualificationResponse))
			cb?.()
		} catch (error) {
			dispatch(SavingEmployeeQualificationSuccessFail())
		}
	}

export {
	saveEmployeeQualificationData,
	saveEmployeeQualificationSia,
	getEmployeesQualificationByEmployeeId,
	updateEmployeeQualificationSiaImage,
	saveLdnForEmployeeQualification,
	deleteEmployeesQualificationById,
}

//SELECTORS
const selectEmployeeQualificationState = (state: RootState) => state.employee.qualification
const SelectEmployeeQualificationLoading = () => (state: RootState) => selectEmployeeQualificationState(state).loading
// make different slice of qualification

const selectEmployeeQualificationData = () => (state: RootState) => selectEmployeeQualificationState(state).data
const selectEmployeeQualificationById = (id: string) => (state: RootState) =>
	selectEmployeeQualificationState(state).data?.find(item => item._id === id)

const selectFilteredEmployeeQualifications = (list: any) => (state: RootState) =>
	selectEmployeeQualificationState(state).data?.filter(item =>
		list?.some((category: any) => category._id === item.qualification),
	)
const SelectEmployeeQualificationSaving = () => (state: RootState) => selectEmployeeQualificationState(state).saving
const SelectEmployeeQualificationApprovalLoading = () => (state: RootState) =>
	selectEmployeeQualificationState(state).approvalLoading

export {
	SelectEmployeeQualificationLoading,
	selectEmployeeQualificationData,
	SelectEmployeeQualificationSaving,
	selectFilteredEmployeeQualifications,
	selectEmployeeQualificationById,
	SelectEmployeeQualificationApprovalLoading,
}
