import React, { useEffect, useMemo, useRef, useState } from "react"
import { capitalize } from "lodash"
import {
	AntdButton,
	AntdInput,
	AntdSelect1,
	// Button,
	DateField,
	FormCheckbox,
	// FormInput,
	FormInputAntd,
	// FormInputPassword,
	FormSkeleton,
	FormSwitchInput,
	// FormTextArea,
	GlobalSidebar,
	PasswordChangeModal,
	// SearchField,
	// Select,
	SidebarList,
	UserAccessModal,
} from "components"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { userInitialValues, usersSchema } from "Schema/Security/security"
import { GetRoleInterface, RoleInterface, UserInterface } from "@type/security.types"
import useAppDispatch from "hooks/useAppDispatch"
import useAppSelector from "hooks/useAppSelector"
import {
	getUsers,
	isUserLoading,
	isUserSaving,
	isUserPasswordSaving,
	saveUser,
	selectUserById,
	selectUserList,
} from "store/Security/users.slice"
import { platformTypeENUM } from "config/constants"
import { getUserAccess } from "store/Security/roles.slice"
import { selectCurrentUser } from "store/auth.slice"
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons"
import { Divider } from "antd-v5"

interface UsersFormHandler extends UserInterface {}

const Users = () => {
	const [passwordModalHandler, setPasswordModalHandler] = useState(false)
	const [selectedUser, setSelectedUser] = useState<string | null>("")
	const [selectedPlatform, setSelectedPlatform] = useState<string>(platformTypeENUM.WEB)
	const [userRoles, setUserRoles] = useState<GetRoleInterface[]>([])
	const dispatch = useAppDispatch()
	const loading = useAppSelector(isUserLoading())
	const saving = useAppSelector(isUserSaving())
	const passwordSaving = useAppSelector(isUserPasswordSaving())
	const userList = useAppSelector(selectUserList)
	const user = useAppSelector(selectUserById(selectedUser))
	const isNewBeingCreated = useRef(false)
	const currentUser = useAppSelector(selectCurrentUser())
	const [searchString, setSearchString] = useState<string>("")

	const [userAccessModal, setUserAccessModal] = useState(false)
	const methods = useForm<UsersFormHandler>({ resolver: yupResolver(usersSchema), mode: "all" })

	const {
		handleSubmit,
		reset,
		setValue,
		getValues,
		watch,
		formState: { errors, isSubmitting },
	} = methods
	const platformType = Object.keys(platformTypeENUM).map(item => ({ label: capitalize(item), key: item }))

	const sidebarUsers = useMemo(() => {
		const filteredPlatformsUsers =
			userList
				?.filter(
					user =>
						user.firstName.toLowerCase().includes(searchString.toLowerCase()) ||
						user.lastName.toLowerCase().includes(searchString.toLowerCase()),
				)
				.map(user => ({
					_id: user._id,
					name: `${user.firstName} ${user.lastName}`,
				})) || []

		if (userList?.length && !isNewBeingCreated.current) {
			if (!selectedUser || !userList.some(user => user._id === selectedUser)) setSelectedUser(userList[0]._id)
		}
		if (searchString.length && filteredPlatformsUsers.length) setSelectedUser(filteredPlatformsUsers[0]._id)

		return filteredPlatformsUsers
	}, [userList, saving, selectedPlatform, searchString])

	const onSubmit: SubmitHandler<UsersFormHandler> = data => {
		let roles: GetRoleInterface[] | RoleInterface[] = userRoles.length ? userRoles : user?.roles ?? []
		if (roles.length) {
			if (roles?.some(role => !role?.name?.includes(getValues("userName")))) {
				roles = userRoles?.map(role => {
					const { name, ...rest } = role
					return { ...rest, name: `${getValues("userName")}${name}` }
				})
			}
		}

		if (data.password === "#" && data.confirmPassword === "#") {
			delete data.confirmPassword
			delete data.password
		}
		if (data.lastLogin) {
			delete data.lastLogin
		}
		isNewBeingCreated.current = true

		dispatch(
			saveUser({ ...data, roles }, id => {
				setUserRoles([])
				if (!selectedUser) setSelectedUser(id)
				setTimeout(() => (isNewBeingCreated.current = false), 200)
			}),
		)
	}

	useEffect(() => {
		dispatch(getUsers(selectedPlatform))
	}, [])

	useEffect(() => {
		if (selectedUser !== (null && undefined)) {
			reset({
				...userInitialValues,
				...user,
				password: "#",
				confirmPassword: "#",
			})
		} else if (!selectedUser && !isNewBeingCreated.current) {
			reset({ ...userInitialValues })
		}
	}, [selectedUser])

	const handleSelected = (userId: string | null): void => setSelectedUser(userId)

	return (
		<>
			<GlobalSidebar>
				<div className="flex flex-col space-y-2">
					<AntdSelect1
						onChange={value => {
							setSelectedPlatform(value)
							dispatch(getUsers(value))
							setUserRoles([])
						}}
						value={selectedPlatform}
						placeholder="Select Platform"
						options={platformType.map(item => ({
							label: item.label,
							value: item.key,
						}))}
						suffixIcon={
							<>
								<span style={{ color: "rgb(156 163 175)" }}>Platform</span>
								<CaretDownOutlined style={{ color: "rgb(156 163 175)" }} />
							</>
						}
					/>

					<AntdInput
						onChange={e => setSearchString(e.target.value)}
						value={searchString}
						placeholder="Search"
						type="text"
						suffix={<SearchOutlined size={14} style={{ color: "rgb(156 163 175)" }} />}
					/>
				</div>
				<Divider className="bg-slate-700" style={{ margin: "15px 0 10px 0" }} />
				<SidebarList
					title={"Users"}
					loading={loading}
					selected={selectedUser}
					setSelected={handleSelected}
					list={sidebarUsers}
				/>
			</GlobalSidebar>
			{loading ? (
				<FormSkeleton />
			) : (
				<>
					<FormProvider {...methods}>
						<form className="flex-col py-2" onSubmit={handleSubmit(onSubmit)}>
							<div className="grid grid-cols-2 gap-5">
								<div className="space-y-5">
									<div className="grid grid-cols-2 gap-x-5 gap-y-3">
										<FormInputAntd type="text" label="First Name" name="firstName" />
										<FormInputAntd type="text" label="Last Name" name="lastName" />
										<FormInputAntd type="text" label="User Name" name="userName" />
										<FormInputAntd type="text" label="Email" name="email" />

										{!selectedUser ? (
											<div className="col-span-2">
												<div className="flex flex-col space-y-2">
													<div className="grid grid-cols-2 gap-x-5">
														<FormInputAntd
															autoComplete="new-password"
															type="password"
															label="Password"
															name="password"
															hidden={!!selectedUser}
														/>

														<FormInputAntd
															type="password"
															autoComplete="new-password"
															label="Confirm Password"
															name="confirmPassword"
															hidden={!!selectedUser}
														/>
													</div>
												</div>

												<FormCheckbox
													containerClassName="h-10 flex items-center "
													label="User must change password at next logon"
													name="shouldChangePassword"
												/>
											</div>
										) : null}
										<FormInputAntd type="text" label="Mobile" name="mobile" />
										<FormInputAntd type="text" label="Telephone 1" name="telephone1" />
										<div className="col-span-2 grid grid-cols-2 gap-x-5">
											<FormInputAntd
												className="col-span-1"
												type="text"
												label="Telephone 2"
												name="telephone2"
											/>
										</div>

										<DateField
											label="Expiry Date"
											name={"expiryDate"}
											setValue={setValue}
											watch={watch}
											errors={errors}
										/>
										<FormInputAntd
											type="number"
											min={0}
											label="Inactivity Timer (minutes)(Default: 10)"
											name="inactivityTimer"
										/>
										<FormSwitchInput
											label="Enabled"
											name="enabled"
											watch={watch}
											errors={errors}
											setValue={setValue}
										/>
										{selectedPlatform === platformTypeENUM.WEB &&
											currentUser?.roles.some(
												role => role.hasCompanyWideAccess || role.isOrganizationalAdmin,
											) && (
												<FormSwitchInput
													containerClass="flex items-center w-full"
													label="Receive Notifications"
													name="receiveNotifications"
													watch={watch}
													errors={errors}
													setValue={setValue}
												/>
											)}

										{/* <p className="mt-5">
										<span className="font-bold">NOTICE :</span> Users/ branches and employees
										created beyond the limits defined in your license may incur additional charges
									</p> */}
									</div>
									<div className="flex gap-x-2">
										<AntdButton
											onClick={() => {
												setSelectedUser(null)
												setUserRoles([])
												reset({ ...userInitialValues })
											}}
										>
											New
										</AntdButton>
										<AntdButton disabled={isSubmitting} loading={saving} htmlType="submit">
											{selectedUser ? "Update" : "Save"}
										</AntdButton>
									</div>
								</div>

								<div className="flex w-full flex-col space-y-2">
									<FormInputAntd
										containerClass="max-w-2xl w-full"
										className=" mr-5.5 mt-1.25 w-auto"
										label="Notes"
										name="notes"
										type="textarea"
									/>
									<div className="flex space-x-4">
										{selectedPlatform === platformTypeENUM.WEB &&
											currentUser?.roles.some(
												role => role.hasCompanyWideAccess || role.isOrganizationalAdmin,
											) && (
												<AntdButton
													onClick={() => {
														dispatch(getUserAccess())
														setUserAccessModal(!userAccessModal)
													}}
													className="mt-5 w-fit"
												>
													User Access
												</AntdButton>
											)}
										<AntdButton
											onClick={() => setPasswordModalHandler(!passwordModalHandler)}
											loading={passwordSaving}
											disabled={passwordSaving}
											className="mt-5 w-fit"
										>
											Change Password
										</AntdButton>
									</div>
								</div>
							</div>
						</form>
					</FormProvider>
					<PasswordChangeModal
						open={passwordModalHandler}
						modalHandler={setPasswordModalHandler}
						userId={selectedUser}
					/>
					{selectedPlatform === platformTypeENUM.WEB && (
						<UserAccessModal
							open={userAccessModal}
							modalHandler={setUserAccessModal}
							user={user ?? null}
							callbackfn={(data: GetRoleInterface[]) => {
								setUserRoles(data)
							}}
							setSelectedUser={setSelectedUser}
							userName={watch("userName")}
						/>
					)}
				</>
			)}
		</>
	)
}

export default Users
