import { addPathToDocument } from "config/utils"
import { useEffect, useState } from "react"

export const SignedUrlForImage: React.FC<any> = ({ children, path }) => {
	const [srcUrl, setSrcUrl] = useState("")

	const getSignedPath = async (path: string) => {
		const signedUrl = await addPathToDocument(path)
		setSrcUrl(signedUrl)
	}

	useEffect(() => {
		if (path) {
			getSignedPath(path)
		}
	}, [path])

	return children(srcUrl)
}
