import { GLOBAL_DATE_FORMAT } from "config/constants"
import { convertIOSDateToHTMLFormat, convertIOSDateToSpecificFormat } from "config/utils"
import _ from "lodash"
import moment from "moment-timezone"

const useEmployeeTable = () => {
	const getMonthDatesByWeek = (year: number, month: number) => {
		const startDate = moment([year, month - 1])

		let monday: any = []
		let tuesday: any = []
		let wednesday: any = []
		let thursday: any = []
		let friday: any = []
		let saturday: any = []
		let sunday: any = []

		for (let i = 0; i < 42; i++) {
			const tomorrow = moment(moment(startDate).startOf("isoWeek")).add(i, "days")

			switch (moment(tomorrow).format("dddd")) {
				case "Monday":
					monday = [
						..._.uniqBy(
							[
								...monday,
								{
									col_label: convertIOSDateToSpecificFormat(tomorrow, "D"),
									date: convertIOSDateToHTMLFormat(tomorrow, GLOBAL_DATE_FORMAT),
								},
							],

							"date",
						),
					]

					break
				case "Tuesday":
					tuesday = [
						..._.uniqBy(
							[
								...tuesday,
								{
									col_label: convertIOSDateToSpecificFormat(tomorrow, "D"),
									date: convertIOSDateToHTMLFormat(tomorrow, GLOBAL_DATE_FORMAT),
								},
							],
							"date",
						),
					]

					break
				case "Wednesday":
					wednesday = [
						..._.uniqBy(
							[
								...wednesday,
								{
									col_label: convertIOSDateToSpecificFormat(tomorrow, "D"),
									date: convertIOSDateToHTMLFormat(tomorrow, GLOBAL_DATE_FORMAT),
								},
							],
							"date",
						),
					]

					break
				case "Thursday":
					thursday = [
						..._.uniqBy(
							[
								...thursday,
								{
									col_label: convertIOSDateToSpecificFormat(tomorrow, "D"),
									date: convertIOSDateToHTMLFormat(tomorrow, GLOBAL_DATE_FORMAT),
								},
							],
							"date",
						),
					]
					break
				case "Friday":
					friday = [
						..._.uniqBy(
							[
								...friday,
								{
									col_label: convertIOSDateToSpecificFormat(tomorrow, "D"),
									date: convertIOSDateToHTMLFormat(tomorrow, GLOBAL_DATE_FORMAT),
								},
							],
							"date",
						),
					]
					break
				case "Saturday":
					saturday = [
						..._.uniqBy(
							[
								...saturday,
								{
									col_label: convertIOSDateToSpecificFormat(tomorrow, "D"),
									date: convertIOSDateToHTMLFormat(tomorrow, GLOBAL_DATE_FORMAT),
								},
							],
							"date",
						),
					]
					break
				case "Sunday":
					sunday = [
						..._.uniqBy(
							[
								...sunday,
								{
									col_label: convertIOSDateToSpecificFormat(tomorrow, "D"),
									date: convertIOSDateToHTMLFormat(tomorrow, GLOBAL_DATE_FORMAT),
								},
							],
							"date",
						),
					]
					break
			}
		}

		return [monday, tuesday, wednesday, thursday, friday, saturday, sunday]
	}

	return { getMonthDatesByWeek }
}

export default useEmployeeTable
