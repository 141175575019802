import { updateState } from "config/utils"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetLocationEscalationsInterface, CreateLocationsEscalationsInterface } from "@type/locations.types"
import { locationEscalations } from "services"
import { AppThunk, RootState } from "store"

export interface LocationEscalationsStateInterface {
	list: GetLocationEscalationsInterface[] | null
	loading: boolean
	saving: boolean
}

const initialState: LocationEscalationsStateInterface = {
	list: null,
	loading: false,
	saving: false,
}

const locationEscalationsSlice = createSlice({
	name: "locationEscalations",
	initialState,
	reducers: {
		fetchingLocationEscalations: location => {
			location.loading = true
		},
		locationEscalationsFetched: (location, action: PayloadAction<GetLocationEscalationsInterface[]>) => {
			location.loading = false
			location.list = action.payload
		},
		locationEscalationsFetchingFailed: location => {
			location.loading = false
		},
		savingLocationEscalations: locationEscalationsData => {
			locationEscalationsData.saving = true
		},
		locationEscalationsSaved: (location, action: PayloadAction<GetLocationEscalationsInterface>) => {
			location.saving = false
			const [UpdatedValue] = updateState(location.list, action.payload, "_id")
			location.list = UpdatedValue
		},
		locationEscalationsSavingFailed: location => {
			location.saving = false
		},
	},
})

//REDUCER
export default locationEscalationsSlice.reducer

//ACTIONS
const {
	fetchingLocationEscalations,
	locationEscalationsFetched,
	locationEscalationsFetchingFailed,
	savingLocationEscalations,
	locationEscalationsSaved,
	locationEscalationsSavingFailed,
} = locationEscalationsSlice.actions

const getLocationEscalationsByLocationId =
	(locationId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingLocationEscalations())
			const { data: locationEscalationsResponse } = await locationEscalations.getLocationEscalationsByLocationId(
				locationId,
			)
			dispatch(locationEscalationsFetched(locationEscalationsResponse))
		} catch (error) {
			dispatch(locationEscalationsFetchingFailed())
		}
	}

const saveLocationEscalations =
	(locationEscalationsData: Partial<GetLocationEscalationsInterface>): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingLocationEscalations())
			if (locationEscalationsData._id)
				data = await locationEscalations.updateLocationEscalations(
					locationEscalationsData._id,
					locationEscalationsData,
				)
			else
				data = await locationEscalations.createLocationEscalations(
					locationEscalationsData as CreateLocationsEscalationsInterface,
				)
			const { data: locationEscalationsResponse } = data
			dispatch(locationEscalationsSaved(locationEscalationsResponse))
		} catch (error) {
			dispatch(locationEscalationsSavingFailed())
		}
	}

export { getLocationEscalationsByLocationId, saveLocationEscalations }

//SELECTORS
const selectLocationEscalationsState = (state: RootState) => state.location.escalations
const isLocationEscalationsLoading = () => (state: RootState) => selectLocationEscalationsState(state).loading
const selectLocationEscalationsData = () => (state: RootState) => selectLocationEscalationsState(state).list
const isLocationEscalationsSaving = () => (state: RootState) => selectLocationEscalationsState(state).saving

export {
	selectLocationEscalationsData,
	selectLocationEscalationsState,
	isLocationEscalationsSaving,
	isLocationEscalationsLoading,
}
