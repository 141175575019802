import React from "react"
import cx from "classnames"
import { NewCall } from "@type/locations.types"

interface RenderProps {
	row: any
	key: string
}
interface Column {
	label: string
	key: string
	render?: (data: RenderProps) => React.ReactNode
	className?: string
}

interface Props {
	rows: any[]
	columns: Column[]
	showRowHeaders: boolean
	onCellClick: (
		id: string,
		isNew: boolean,
		isSingleClick: boolean,
		isDragging: number,
		newCell: NewCall,
		fullData?: any,
	) => void
	noDataText?: string
}

const TableSelectableCells: React.FC<Props> = ({ rows, columns, showRowHeaders, noDataText = "" }) => {
	return (
		<div className="overflow-x-auto">
			<table className="whitespace-no-wrap table-auto text-left">
				<thead className="text-sm">
					<tr className="bg-gray-3 h-9 text-left">
						{[...(showRowHeaders ? [{ label: "", key: "_" }, ...columns] : columns)].map(
							({ label }, colIndex) => {
								const thClassName = cx({
									" bg-background px-2.5 py-1.25 text-sm font-semibold text-dark-alt pl-4 font-medium":
										true,
									"rounded-tl-md": colIndex === 0,
									" rounded-tr-2.5": colIndex === columns.length,
								})
								return (
									<th key={colIndex} className={thClassName}>
										{label}
									</th>
								)
							},
						)}
					</tr>
				</thead>
				<tbody>
					{rows.length ? (
						rows.map((row, rowIndex) => (
							<tr
								key={rowIndex}
								className="border-gray-4 h-11 border-t-0.125 border-b border-solid text-sm"
							>
								{Object.keys(row).map((key, colIndex) => {
									const {
										isEmpty = false,
										label = "",
										isRowHeader = false,
										isToBeRemoved = false,
										isToBeAdded = false,
										onMouseDown,
										onMouseEnter,
									} = row[key]
									return (
										<td
											key={colIndex}
											className={cx("cursor-pointer select-none p-0 text-sm", {
												"bg-background font-semibold": isRowHeader,
												"border border-background bg-white text-dark-alt ": isEmpty,
												"bg-red-200": isToBeRemoved,
												"bg-green-200": isToBeAdded,
											})}
											onMouseDown={onMouseDown}
											onMouseEnter={onMouseEnter}
										>
											{<div className={`p-2 text-center`}>{label}</div>}
										</td>
									)
								})}
							</tr>
						))
					) : (
						<tr>
							<th colSpan={12} className="  p-3 text-center text-dark-alt">
								{noDataText ? noDataText : "No Data"}
							</th>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}

export default TableSelectableCells
