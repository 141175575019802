import React from "react"
import cx from "classnames"

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	label?: string
	labelClass?: string
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
	({ className = "", labelClass = "", label = null, ...rest }, ref) => {
		const inputClassName = cx(
			"px-3 py-3.5 w-84 outline-none rounded-md border border-secondary-dark hover:border-black text-black placeholder:text-secondary-dark text-md duration-100 ease-in-out",
			className,
		)

		return (
			<>
				{label && <label className={cx("text-dark-alt", labelClass)}>{label}</label>}
				<textarea className={inputClassName} ref={ref} {...rest} />
			</>
		)
	},
)

export default TextArea
