import { io, Socket } from "socket.io-client"
import { envConfig } from "."

export let socket: Socket

export function initializeSocketConnection(token: string) {
	socket = io(`${envConfig.socketRoot}`, {
		auth: { headers: { authorization: token } },
		transports: ["websocket", "polling"],
	})
	socket.on("connect_error", () => {
		// revert to classic upgrade
		socket.io.opts.transports = ["polling", "websocket"]
	})
}
