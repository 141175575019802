import React, { useContext, useEffect } from "react"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAppDispatch, useAppSelector } from "hooks"

import { CheckBoxGroup, DateField, FormInputAntd, FormCheckbox, FormSelectAntd, AntdButton } from "components"

import { isLocationEscalationsSaving, saveLocationEscalations } from "store/Location/locationEscalations.slice"
import LocationEscalationContext, { LocationEscalationContextInterface } from "./EscalationContext"

import { LocationSchema } from "Schema"
import { CreateLocationsEscalationsInterface } from "@type/locations.types"
import { Permissions } from "config/constants"
import { AllowedTo } from "react-abac"
import { selectSelectedLocation } from "store/globalState.slice"

const { escalationValidationSchema, escalationInitialValues } = LocationSchema

enum ApplicableDay {
	Monday = "Monday",
	Tuesday = "Tuesday",
	Wednesday = "Wednesday",
	Thursday = "Thursday",
	Friday = "Friday",
	Saturday = "Saturday",
	Sunday = "Sunday",
	All = "All",
	PH = "PH",
}

const ApplicableDays = [
	{ label: "Mon", value: ApplicableDay.Monday },
	{ label: "Tue", value: ApplicableDay.Tuesday },
	{ label: "Wed", value: ApplicableDay.Wednesday },
	{ label: "Thu", value: ApplicableDay.Thursday },
	{ label: "Fri", value: ApplicableDay.Friday },
	{ label: "Sat", value: ApplicableDay.Saturday },
	{ label: "Sun", value: ApplicableDay.Sunday },
	{ label: "All", value: ApplicableDay.All },
	{ label: "Ph", value: ApplicableDay.PH },
]

const Escalations: React.FC = () => {
	const dispatch = useAppDispatch()
	const saving = useAppSelector(isLocationEscalationsSaving())

	const selected = useAppSelector(selectSelectedLocation)

	const { locationEscalation, handleLocationEscalationHandler } = useContext(
		LocationEscalationContext,
	) as LocationEscalationContextInterface

	const methods = useForm<CreateLocationsEscalationsInterface>({ resolver: yupResolver(escalationValidationSchema) })
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		watch,
		formState: { errors, isDirty, isSubmitting },
	} = methods
	useEffect(() => {
		if (locationEscalation) reset({ ...locationEscalation })
	}, [locationEscalation])

	useEffect(() => {
		if (!saving) {
			if (locationEscalation) {
				handleLocationEscalationHandler(null)
			}
			reset(escalationInitialValues)
		}
	}, [saving])

	useEffect(() => {
		if (locationEscalation) {
			handleLocationEscalationHandler(null)
		}
		reset(escalationInitialValues)
	}, [selected])

	const onSubmit: SubmitHandler<CreateLocationsEscalationsInterface> = data => {
		dispatch(saveLocationEscalations({ ...data, location: selected as string }))
	}

	return (
		<div>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="grid w-1/2 grid-cols-2 gap-x-5 gap-y-4">
						<FormInputAntd type="text" label="Name" name="name" />
						<FormInputAntd type="textarea" label="Details" name="details" />
						<DateField label="Reviewed" name={"reviewed"} setValue={setValue} watch={watch} />
						<FormInputAntd type="text" label="Reviewed By" name="reviewedBy" />
						<FormInputAntd type="textarea" label="Control Comments" name="controlComments" />
						<div className="col-span-1"></div>
						<FormSelectAntd
							label="Escalation"
							name="escalation"
							options={[{ name: "props1", value: "Check1" }].map(({ name, value }) => ({
								label: name,
								value,
							}))}
						/>
						<FormInputAntd type="textarea" label="Description" name="description" />
						<DateField
							label="Window Start Time"
							name={"windowStartTime"}
							setValue={setValue}
							watch={watch}
							errors={errors}
						/>
						<DateField
							label="Window Finish Time"
							name={"windowFinishTime"}
							setValue={setValue}
							watch={watch}
							errors={errors}
						/>
						<FormCheckbox
							type="checkbox"
							label="Suppress 3rd Party Incidents"
							name="suppress3rdPartyIncidents"
						/>
						<FormCheckbox type="checkbox" label="Checked By Control" name="checkedByControl" />
						<CheckBoxGroup
							label="Applicable Days"
							name="applicableDays"
							labelClass="mb-2.75"
							register={register}
							errors={errors}
							checkBoxes={ApplicableDays}
						/>
					</div>
					<AllowedTo perform={Permissions.UPDATE_LOCATION_ESCALATION}>
						<div className="space-x-4 py-4">
							<AntdButton loading={saving} disabled={!isDirty || isSubmitting} htmlType="submit">
								{locationEscalation ? "Update" : "Save"}
							</AntdButton>
							<AntdButton
								onClick={e => {
									e.preventDefault()
									reset({ ...escalationInitialValues })
									handleLocationEscalationHandler(null)
								}}
								htmlType="button"
							>
								Clear
							</AntdButton>
						</div>
					</AllowedTo>
				</form>
			</FormProvider>
		</div>
	)
}

export default Escalations
