import { createContext } from "react"

export interface TimeCapturesContextProps {
	selectedPost?: string | null
	handleSelectedPost?: (postId: string | null) => void
}

const TimeCapturesContext = createContext<TimeCapturesContextProps | null>(null)

export default TimeCapturesContext
