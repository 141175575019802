import { Icon } from "components"
import { useState } from "react"
import { twMerge } from "tailwind-merge"

interface Props extends React.HTMLAttributes<HTMLElement> {
	label: string
	headerTextClassName?: string
	headerClassName?: string
	bodyClassName?: string
	containerClassName?: string
}

const Accordian: React.FC<Props> = ({
	label,
	children,
	bodyClassName = "",
	headerTextClassName = "",
	headerClassName = "",
	containerClassName = "",
}) => {
	const [open, setOpen] = useState(false)
	return (
		<div className={containerClassName}>
			<div
				className={twMerge(
					`flex w-full cursor-pointer space-x-2 rounded-md border-0.125 border-dark px-2 lg:w-44 xl:w-84 ${headerClassName}`,
				)}
				onClick={() => setOpen(!open)}
			>
				<Icon color="dark" name={`${open ? "dropdownArrow" : "dropRightArrow"}`} size={22} />
				<label className={`${headerTextClassName}`}>{label}</label>
			</div>
			<div className={twMerge(`mt-6 ${open ? "block" : "hidden"} ${bodyClassName}`)}>{children}</div>
		</div>
	)
}

export default Accordian
