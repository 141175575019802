import axios from "axios"
import { EmployeeEquipmentIssuedInterface, GetEmployeeEquipmentIssuedInterface } from "@type/employees.types"

const ROOT_PATH = "employees/equipments-issued"

export default class employeeEquipmentIssuedService {
	static createEquipmentIssued = (data: EmployeeEquipmentIssuedInterface) =>
		axios.post<GetEmployeeEquipmentIssuedInterface>(`${ROOT_PATH}`, data)

	static updateEquipmentIssued = (equipmentIssuedId: string, equipmentData: EmployeeEquipmentIssuedInterface) =>
		axios.put<GetEmployeeEquipmentIssuedInterface>(`${ROOT_PATH}/${equipmentIssuedId}`, equipmentData)

	static getEquipmentIssuedByEmployeeId = (employeeId: string) =>
		axios.get<GetEmployeeEquipmentIssuedInterface[]>(`${ROOT_PATH}/?employee=${employeeId}`)
}
