import { ConfigProvider } from "antd-v5"
import React, { useEffect, useState } from "react"
import { createPortal } from "react-dom"

interface Props {
	children: React.ReactNode
}

const GlobalSidebar = ({ children }: Props) => {
	const [sidebar, setSidebar] = useState<HTMLElement | null>(document.getElementById("global-sidebar"))
	const [div] = useState<any>(document.createElement("div"))

	div.style.cssText = `height:100%;`

	useEffect(() => {
		setSidebar(document.getElementById("global-sidebar"))
	}, [])

	const handleSearch = (e: KeyboardEvent) => {
		const element = document.getElementById("sidebar-search")
		if (e.ctrlKey && e.key === "g") {
			e.preventDefault()
			element?.focus({ preventScroll: true })
		}
	}

	useEffect(() => {
		sidebar?.appendChild(div)
		return () => sidebar?.removeChild(div)
	}, [div, sidebar])

	useEffect(() => {
		window.addEventListener("keydown", handleSearch)
		return () => window.removeEventListener("keydown", handleSearch)
	}, [])

	return (
		<ConfigProvider
			prefixCls="ant5"
			theme={{
				token: {
					colorPrimary: "rgb(99, 102, 241)",
				},
				components: {
					Select: {
						colorBgContainer: "rgb(51 65 85);",
						lineWidth: 0,
						colorTextBase: "rgb(229 229 229);",
						borderRadius: 4,
						fontSize: 14,
						colorTextPlaceholder: "rgb(156 163 175);",
						colorText: "rgb(229 229 229);",
						colorBgElevated: "rgb(51 65 85);",
						optionActiveBg: "rgb(31 41 55);",
						optionSelectedBg: "rgb(31 41 55);",
						optionSelectedFontWeight: 700,
					},
					Input: {
						activeBorderColor: "#ffffff",
						borderRadius: 4,
						colorBgContainer: "rgb(51 65 85);",
						lineWidth: 0,
						colorTextPlaceholder: "rgb(156 163 175);",
						colorText: "rgb(229 229 229);",
						fontSize: 14,
					},
				},
			}}
		>
			{createPortal(children, div as Element)}
		</ConfigProvider>
	)
}

export default GlobalSidebar
