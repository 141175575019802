import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EmployeeEquipmentInterface } from "@type/employees.types"
import { equipmentsService } from "services"
import { AppThunk, RootState } from "store"

export interface EquipmentStateInterface {
	data: null | EmployeeEquipmentInterface[]
	loading: boolean
	saving: boolean
}

const initialState: EquipmentStateInterface = {
	data: null,
	loading: false,
	saving: false,
}

const equipmentSlice = createSlice({
	name: "equipment",
	initialState,
	reducers: {
		requestEquipmentData: equipment => {
			equipment.loading = true
		},
		receiveEquipmentData: (equipment, action: PayloadAction<EmployeeEquipmentInterface[]>) => {
			equipment.loading = false
			equipment.data = action.payload
		},
		receiveEquipmentDataFailed: equipment => {
			equipment.loading = false
		},
		requestSavingEquipmentData: equipmentData => {
			equipmentData.saving = true
		},
		SavingEmployeeEquipmentSuccess: (equipment, action: PayloadAction<EmployeeEquipmentInterface[]>) => {
			equipment.loading = false
			equipment.data = action.payload
		},
		SavingEmployeeEquipmentSuccessFailes: equipment => {
			equipment.loading = false
		},
	},
})

//REDUCER
export default equipmentSlice.reducer

//ACTIONS
const {
	requestEquipmentData,
	receiveEquipmentData,
	receiveEquipmentDataFailed,
	requestSavingEquipmentData,
	SavingEmployeeEquipmentSuccess,
	SavingEmployeeEquipmentSuccessFailes,
} = equipmentSlice.actions

const getEmployeesEquipmentByEmployeeId =
	(employeeId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestEquipmentData())
			const { data: equipmentResponse } = await equipmentsService.getEmployeesEquipment(employeeId)
			dispatch(receiveEquipmentData(equipmentResponse))
		} catch (error) {
			dispatch(receiveEquipmentDataFailed())
		}
	}

const saveEmployeeEquipmentData =
	(equipmentData: EmployeeEquipmentInterface): AppThunk =>
	async dispatch => {
		try {
			let data = null
			dispatch(requestSavingEquipmentData())
			if (equipmentData._id) {
				data = await equipmentsService.updateEmployeeEquipment(equipmentData._id, equipmentData)
			} else {
				data = await equipmentsService.createEmployeeEquipment(equipmentData)
			}
			const { data: equipmentResponse } = data
			dispatch(SavingEmployeeEquipmentSuccess(equipmentResponse))
		} catch (error) {
			dispatch(SavingEmployeeEquipmentSuccessFailes())
		}
	}

export { saveEmployeeEquipmentData, getEmployeesEquipmentByEmployeeId }

//SELECTORS
const selectEmployeeEquipmentState = (state: RootState) => state.employee.equipment
const isEmployeeEquipmentLoading = () => (state: RootState) => selectEmployeeEquipmentState(state).loading
const selectEmployeeEquipmentData = () => (state: RootState) => selectEmployeeEquipmentState(state).data
const selectLoadingEmployeeEquipment = () => (state: RootState) => selectEmployeeEquipmentState(state).loading

export { isEmployeeEquipmentLoading, selectEmployeeEquipmentData, selectLoadingEmployeeEquipment }
