import React, { useRef } from "react"
import { AntdButton } from "components"
import { FieldError } from "react-hook-form"
import { UploadOutlined } from "@ant-design/icons"

interface Props {
	onDocumentChange(file: File): void
	errors?: { [key: string]: FieldError | undefined }
	name: string
	loading?: boolean
	documentName?: string
	containerClass?: string
}

const DocumentField: React.FC<Props> = ({
	documentName = "",
	onDocumentChange,
	errors = {},
	name = "",
	loading = false,
	containerClass = "",
}: Props) => {
	const inputRef = useRef<HTMLInputElement>(null)

	return (
		<div className={`flex flex-row ${containerClass}`}>
			<input
				ref={inputRef}
				type="file"
				className="hidden"
				name="link"
				onChange={e => {
					if (e.target.files) {
						const reader = new FileReader()
						reader.onload = (): void => {
							if (e.target.files?.length) {
								onDocumentChange(e.target.files[0])
								e.target.value = ""
							}
						}
						reader.readAsDataURL(e.target.files[0])
					}
				}}
			/>

			<AntdButton
				disabled={loading}
				name="link"
				icon={<UploadOutlined />}
				onClick={e => {
					e.preventDefault()
					if (inputRef && inputRef.current) {
						inputRef?.current?.click()
					}
				}}
			>
				{documentName ? documentName.slice(0, 15) + "..." : "Add Document"}
			</AntdButton>
			{errors[name] && <p className="mt-1 ml-1 text-sm text-danger">{errors[name]?.message}</p>}

			{/* <p className="ml-2 w-40.75 self-center overflow-hidden text-ellipsis whitespace-nowrap">{documentName}</p> */}
		</div>
	)
}

export default DocumentField
