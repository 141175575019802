import { yupResolver } from "@hookform/resolvers/yup"
import {
	ShiftInterface,
	IncidentInterface,
	ResolveIncidentDto,
	CreateIncidentCommentDto,
} from "@type/workMonitor.types"
import Icon from "../../components/Icon"
import clsx from "clsx"
import Modal from "../../components/Modal"
import Loading from "../../components/Loading"
import AntdButton from "../../components/AntdButton"
import { twMerge } from "tailwind-merge"
import FormInputAntd from "../../components/FormInputAntd"
import useAppDispatch from "../../hooks/useAppDispatch"
import { DATE_FORMATS, IncidentState } from "../../config/constants"
import useIncidentComments from "../../hooks/useIncidentComments"
import { Statistic, Timeline } from "antd-v5"
import { FormProvider, useForm } from "react-hook-form"
import { resolveIncidentInShift, selectWorkMonitorSaving } from "../../store/workMonitor/workMonitor.slice"
import { caseConverters, getMomentZInstanceOfDate } from "../../config/utils"
import { FC, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { incidentCommentsInitialValues, incidentCommentsSchema } from "Schema/workMonitor/workMonitorSchema"
import useAppSelector from "../../hooks/useAppSelector"
import { orderBy } from "lodash"

interface ResolveIncidentModalProps {
	open: boolean
	modalHandler: (open: boolean) => void
	selectedRow: ShiftInterface | null
	onResolveCb?: () => void
}

const ResolveIncidentModal: FC<ResolveIncidentModalProps> = ({ open, selectedRow, modalHandler, onResolveCb }) => {
	const { createIncidentComment, fetchIncidentCommentsByIncident, incidentComments, isLoading, isSaving } =
		useIncidentComments()
	const [incidents, setIncidents] = useState<Array<IncidentInterface>>([])
	const [selectedIncident, setSelectedIncident] = useState<IncidentInterface | null>(null)
	const dispatch = useAppDispatch()
	const workMonitorSaving = useAppSelector(selectWorkMonitorSaving())

	const methods = useForm<CreateIncidentCommentDto>({
		resolver: yupResolver(incidentCommentsSchema),
		defaultValues: incidentCommentsInitialValues,
	})
	const { handleSubmit, reset } = methods

	const rowHandler = (selectedRow: IncidentInterface) => {
		setSelectedIncident(selectedRow)
	}

	useLayoutEffect(() => {
		const incidents = selectedRow?.incidents

		if (incidents) {
			setIncidents(orderBy(incidents, ["incidentState"], ["desc"]))
			const indexOfFirstUnresolvedIncident = incidents.findIndex(
				incident => incident.incidentState === IncidentState.UN_RESOLVED || !incident.incidentState,
			)
			setSelectedIncident(incidents[indexOfFirstUnresolvedIncident === -1 ? 0 : indexOfFirstUnresolvedIncident])
		}
	}, [selectedRow])

	useEffect(() => {
		if (selectedIncident) {
			fetchIncidentCommentsByIncident(selectedIncident._id)
		}
	}, [selectedIncident])

	const onSubmit = (data: CreateIncidentCommentDto) => {
		if (selectedIncident) {
			createIncidentComment({ comment: data.comment, incident: selectedIncident._id }, () => {
				reset()
			})
		}
	}

	const incidentResolutionHandler = (data: ResolveIncidentDto) => {
		if (selectedIncident)
			dispatch(
				resolveIncidentInShift(selectedIncident._id, data, () => {
					onResolveCb?.()
				}),
			)
	}

	const incidentDetailsData: { title: string; value?: string; className?: string }[] = useMemo(() => {
		return [
			{ title: "Site", value: selectedIncident?.siteName },
			{ title: "Officer", value: selectedIncident?.officerName },
			{
				title: "Date",
				value: getMomentZInstanceOfDate(selectedIncident?.date).format("DD-MM-YYYY").split("T").toString(),
			},
			{
				title: "Reporting Officer",
				value: `${selectedIncident?.reportingOfficer.firstName} ${selectedIncident?.reportingOfficer.lastName}`,
			},
			{
				title: "Police Requested",
				value: selectedIncident?.policeRequested ? "Yes" : "No",
			},
			{
				title: "Action Taken",
				value: selectedIncident?.actionTaken ? "Yes" : "No",
			},
			{
				title: "Control Informed",
				value: selectedIncident?.controlInformed ? "Yes" : "No",
			},
			{
				title: "Reference",
				value: selectedIncident?.reference,
			},
			{
				title: "State",
				value: selectedIncident?.incidentState
					? caseConverters.titleCase(selectedIncident?.incidentState)
					: "N/A",
			},
		]
	}, [selectedIncident])

	const commentsTimeline = incidentComments.map(incidentComment => {
		return {
			children: (
				<div>
					<span className="text-[12px] font-normal">{incidentComment.comment}</span>

					<div className="flex ">
						<div className="flex items-center space-x-1">
							<Icon color="dark" name="user" size={10} />
							<div className="text-[10px] font-thin">
								{incidentComment.author.firstName} {incidentComment.author.lastName}
							</div>
						</div>

						<div className="ml-5 flex items-center space-x-1">
							<Icon color="dark" name="history" size={10} />
							<div className="text-[10px] font-thin">
								{getMomentZInstanceOfDate(incidentComment.createdAt).format(
									DATE_FORMATS.DD_MM_YYYY_HH_MM,
								)}
							</div>
						</div>
					</div>
				</div>
			),
		}
	})

	return (
		<Modal
			containerClass="w-[65%] h-[730px]"
			open={open}
			title={<div className="text-center text-md font-bold  tracking-wider text-dark">Handle Incident</div>}
			modalHandler={modalHandler}
		>
			<div className="h-full">
				<div className="flex w-[100%] flex-row">
					<div className="h-full w-[25%] ">
						<div className="mt-2 h-[580px] overflow-scroll rounded-2  bg-side-bar-bg p-2 scrollbar-thin">
							{incidents?.map(incident => {
								return (
									<div
										key={incident._id}
										className={twMerge(
											clsx(
												"w-full cursor-pointer truncate text-md font-extralight text-zinc-300 hover:text-white",
												{
													"font-semibold text-white": incident._id === selectedIncident?._id,
												},
											),
										)}
										onClick={() => rowHandler(incident)}
									>
										<div className="flex items-center">
											<div>{incident.incident}</div>
											{(incident.incidentState === IncidentState.RESOLVED ||
												incident.incidentState === IncidentState.HANDED_OVER) && (
												<div className="ml-auto">
													<Icon color="green" name="checkedCircle" size={17} />
												</div>
											)}
										</div>
									</div>
								)
							})}
						</div>
					</div>

					<>
						<div className="border-green flex-1 justify-center pl-5">
							{selectedIncident && (
								<div className="mt-5 ml-4 ">
									<div className="mb-[0] text-[20px] font-bold">
										<div className="grid grid-cols-4 space-y-2">
											{incidentDetailsData.map(({ title, value, className = "col-span-1" }) => (
												<Statistic
													key={title}
													title={title}
													value={value || "N/A"}
													className={className}
													formatter={val => val}
												/>
											))}
										</div>

										<div>
											<span className="text-[12px] font-semibold text-stats-title">
												{" "}
												Description:{" "}
											</span>
											<div className="h-[100px] overflow-scroll text-3 font-normal scrollbar-thin">
												{selectedIncident.details}
											</div>
										</div>

										<div className="mt-1">
											<div className="text-[12px] text-stats-title">Comments:</div>

											{isLoading ? (
												<Loading />
											) : (
												<div className="h-[200px] overflow-scroll pt-4 scrollbar-thin">
													<Timeline items={commentsTimeline} />
												</div>
											)}
										</div>

										<FormProvider {...methods}>
											<form onSubmit={handleSubmit(onSubmit)}>
												<div className="mt-4 flex flex-row items-center justify-between">
													<FormInputAntd
														label="Leave a comment"
														type="text"
														name="comment"
														className="w-[97%]"
													/>
													<AntdButton
														htmlType="submit"
														className="mt-[18px]"
														disabled={isSaving}
														loading={isSaving}
													>
														Submit
													</AntdButton>
												</div>
											</form>
										</FormProvider>
									</div>
								</div>
							)}
						</div>
					</>
				</div>

				{selectedIncident && (
					<div className="mt-8 flex">
						<div className="flex h-fit w-full justify-end space-x-4">
							<AntdButton
								onClick={() => incidentResolutionHandler({ incidentState: IncidentState.HANDED_OVER })}
								loading={workMonitorSaving}
								disabled={
									workMonitorSaving ||
									selectedIncident.incidentState === IncidentState.RESOLVED ||
									selectedIncident.incidentState === IncidentState.HANDED_OVER
								}
							>
								Handover
							</AntdButton>

							<AntdButton
								onClick={() => incidentResolutionHandler({ incidentState: IncidentState.RESOLVED })}
								loading={workMonitorSaving}
								disabled={
									workMonitorSaving ||
									selectedIncident.incidentState === IncidentState.RESOLVED ||
									selectedIncident.incidentState === IncidentState.HANDED_OVER
								}
							>
								Resolve
							</AntdButton>
						</div>
					</div>
				)}
			</div>
		</Modal>
	)
}

export default ResolveIncidentModal
