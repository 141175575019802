import axios from "axios"
import { CreateLocationInventoryCallInterface, GetLocationCallTimeInterface } from "@type/locations.types"

const ROOT_PATH = "locations/inventory-call"
export default class LocationInventoryCallService {
	static createInventoryCall = (inventoryCalls: CreateLocationInventoryCallInterface) => {
		return axios.post<GetLocationCallTimeInterface[]>(ROOT_PATH, inventoryCalls)
	}

	static getPostInventoryCalls = (postId: string) =>
		axios.get<Array<GetLocationCallTimeInterface>>(`${ROOT_PATH}/${postId}`)

	static deleteInventoryCalls = (ids: string[]) =>
		axios.delete<GetLocationCallTimeInterface>(`${ROOT_PATH}`, { data: { ids } })
}
