import React from "react"
import cx from "classnames"
import Icon, { IconProps } from "./Icon"
import { twMerge } from "tailwind-merge"

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	loading?: boolean
	iconPosition?: "left" | "right"
	iconProps?: IconProps
}

const Button: React.FC<Props> = ({
	className = "",
	children,
	loading = false,
	iconProps,
	iconPosition = "right",

	...rest
}) => {
	const { disabled } = rest
	const buttonClassNames = twMerge(
		cx("bg-black rounded-xl text-white py-2.5 px-7.5 text-md flex items-center", {
			"flex-row-reverse": iconProps && iconPosition === "left",
			"disabled:opacity-75": disabled,
		}),
		className,
	)

	const iconClassNames = cx({
		"ml-2.5": iconPosition === "right",
		"mr-2.5": iconPosition === "left",
	})
	return (
		<button
			className={buttonClassNames}
			{...rest}
			onMouseDown={e => {
				e.preventDefault()
				rest?.onMouseDown?.(e)
			}}
		>
			{loading ? (
				<Icon name="spinner" color="white" size={22} className="animate-spin" />
			) : (
				<>
					{children}
					{iconProps && <Icon className={iconClassNames} {...iconProps} />}
				</>
			)}
		</button>
	)
}

export default Button
