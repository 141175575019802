import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { mediaService } from "services"
import { AppThunk, RootState } from "store"

export interface MediaStateInterface {
	mediaURL: string[]
	loading: boolean
}

const initialState: MediaStateInterface = {
	mediaURL: [],
	loading: false,
}

const mediaSlice = createSlice({
	name: "media",
	initialState,
	reducers: {
		uploading: state => {
			state.loading = true
		},
		uploaded: (state, action: PayloadAction<string[]>) => {
			state.loading = false
			state.mediaURL = action.payload
		},
		uploadingFailed: state => {
			state.loading = false
		},
		clearMedia: state => {
			state.mediaURL = []
		},
	},
})

export default mediaSlice.reducer

export const { uploading, uploaded, uploadingFailed, clearMedia } = mediaSlice.actions

const uploadMedia =
	(file: File[] | File): AppThunk =>
	async dispatch => {
		try {
			dispatch(uploading())
			const { data: mediaResponse } = await mediaService.upload(file)
			dispatch(uploaded(mediaResponse.url))
		} catch (error) {
			dispatch(uploadingFailed())
		}
	}

export { uploadMedia }

const selectMediaState = (state: RootState) => state.media
const selectMediaURL = () => (state: RootState) => selectMediaState(state).mediaURL
const selectLoadingMedia = () => (state: RootState) => selectMediaState(state).loading

export { selectMediaURL, selectLoadingMedia }
