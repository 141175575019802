import React, { useEffect, useMemo, useState } from "react"
import { useAppSelector, useAppDispatch } from "hooks"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AntdButton, AntdSelect1, FormInputAntd, FormSelectAntd, Modal, TimeField } from "components"
import { CreateSchedulesInterface } from "@type/schedules.types"
import { AllocationSchedulesByEmployeeValidationSchema } from "Schema"
import {
	getLocationsByBranchId,
	isLocationLoading,
	selectLocationListByBranchId,
} from "store/Location/locationDetails.slice"
import {
	clearContractList,
	getLocationContractsByLocationId,
	isLocationContractsLoading,
	selectLocationContractsDataDropDown,
} from "store/Location/locationContracts.slice"
import { clearPostList, isLocationPostsLoading, selectLocationPostsData } from "store/Location/locationPosts.slice"
import { getLocationPostsByContractId } from "store/Location/locationPosts.slice"
import { selectScheduleSaving } from "store/Schedules/schedules.slice"
import { EmployeeTypesEnum, Permissions, byEmployeeScheduleType } from "config/constants"
import { selectSelectedLocation, selectSelectedLocationBranch } from "store/globalState.slice"
import { selectBranchesMappedAsOptions } from "store/Branches/branch.slice"
import { Switch } from "antd"
import { AllowedTo } from "react-abac"
import { selectSelectedEmployeeData } from "store/Employee/detail.slice"

export interface CreateScheduleByEmployeeFormInterface extends CreateSchedulesInterface {
	contract: string
}

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	onSubmit: (data: CreateScheduleByEmployeeFormInterface) => void
	isRecurringSchedule: boolean
}

const CreateEmployeeScheduleModal: React.FC<Props> = ({ open, modalHandler, onSubmit, isRecurringSchedule }) => {
	const dispatch = useAppDispatch()

	// const [locationId, setLocationId] = useState<string>("")
	const [branchId, setBranchId] = useState<string>("")
	const saving = useAppSelector(selectScheduleSaving())
	const contractsLoading = useAppSelector(isLocationContractsLoading())
	const locations = useAppSelector(selectLocationListByBranchId(branchId)).filter((item: any) => !!item?.isActive)
	const locationLoading = useAppSelector(isLocationLoading())
	const branches = useAppSelector(selectBranchesMappedAsOptions())
	const posts = useAppSelector(selectLocationPostsData())
	const globalLocation = useAppSelector(selectSelectedLocation)
	const globalBranch = useAppSelector(selectSelectedLocationBranch)
	const postsLoading = useAppSelector(isLocationPostsLoading())
	const selectedEmployee = useAppSelector(selectSelectedEmployeeData)

	const methods = useForm<CreateScheduleByEmployeeFormInterface>({
		resolver: yupResolver(AllocationSchedulesByEmployeeValidationSchema),
	})

	const {
		handleSubmit,
		watch,
		setValue,
		reset,
		formState: { errors, isDirty },
	} = methods

	const contractsData = useAppSelector(selectLocationContractsDataDropDown(watch("location")))

	useEffect(() => {
		if (open) {
			dispatch(clearContractList())
			dispatch(clearPostList())
			if (globalBranch) setBranchId(globalBranch)
			if (globalBranch && locations.some(item => item._id === globalLocation)) {
				setValue("location", globalLocation)
				dispatch(getLocationContractsByLocationId(watch("location")))
				setValue("location", globalLocation)
			}
			setValue("type", byEmployeeScheduleType[0].value)
		}
	}, [open])

	useEffect(() => {
		if (globalBranch && !branchId) {
			setBranchId(globalBranch)
		} else if (branches.length && !branchId) {
			setBranchId(branches[0].value)
		}
	}, [branches])

	useEffect(() => {
		if (!locationLoading && locations.length && locations[0]._id !== watch("location"))
			setValue("location", locations[0]._id)
	}, [locationLoading, branchId])

	useEffect(() => {
		if (watch("location")) dispatch(getLocationContractsByLocationId(watch("location")))
	}, [watch("location")])

	useEffect(() => {
		if (contractsData.length > 1) {
			setValue("contract", contractsData[1].value)
			dispatch(getLocationPostsByContractId(contractsData[1].value))
		}
	}, [contractsData.length])

	const filterPostDropdown = useMemo(() => {
		return posts
			.filter(post => {
				if (!selectedEmployee?.type || selectedEmployee.type === EmployeeTypesEnum.SECURITY)
					return !post?.type || post.type === EmployeeTypesEnum.SECURITY
				else return post.type === EmployeeTypesEnum.CLEANING
			})
			.map(({ _id, shortName }) => ({ label: shortName, value: _id }))
	}, [selectedEmployee, contractsData])

	return (
		<Modal
			containerClass="min-w-[600px]"
			open={open}
			title={
				<div className="py-3 text-center text-md font-bold uppercase tracking-wider text-dark">
					{`${isRecurringSchedule ? "Recurring" : "Create"}`} Schedule
				</div>
			}
			modalHandler={() => {
				modalHandler(false)
				reset({})
			}}
		>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)} className="w-full">
					<div className="grid grid-cols-2 gap-2">
						<TimeField
							name="startTime"
							label="Start Time"
							formValueSetter={setValue}
							watch={watch}
							errors={errors}
							defaultFocusOn={true}
						/>
						<TimeField
							name="finishTime"
							label="Finish Time"
							formValueSetter={setValue}
							watch={watch}
							errors={errors}
						/>

						<AntdSelect1
							disabled={!branches.length}
							label="Branch"
							placeholder="Select Branch"
							value={branchId}
							onChange={branch => {
								if (typeof branch === "string") {
									setBranchId(branch)
									dispatch(getLocationsByBranchId(branch))
								}
							}}
							options={branches}
						/>
						<AntdSelect1
							disabled={watch("checkMultiple")}
							label="Location"
							loading={locationLoading}
							placeholder="Select Supplier"
							value={watch("location")}
							onChange={value => {
								if (!Array.isArray(value)) setValue("location", value)
							}}
							options={locations?.map(({ _id, name }) => ({
								label: name,
								value: _id,
							}))}
						/>
						<AntdSelect1
							disabled={!contractsData.length || !locations.length}
							label="Contracts"
							loading={contractsLoading}
							value={watch("contract")}
							onChange={value => {
								setValue("contract", value)
								dispatch(clearPostList())
								dispatch(getLocationPostsByContractId(value))
							}}
							options={contractsData}
						/>
						<FormSelectAntd
							loading={postsLoading}
							containerClass="w-full"
							label="Post"
							name="post"
							disabled={!posts.length}
							options={[{ label: "Select Post", value: "" }, ...filterPostDropdown]}
						/>
						<FormSelectAntd
							label="Schedule Type"
							name="type"
							options={byEmployeeScheduleType?.map(({ label, value }) => ({ label, value }))}
						/>
						<AllowedTo perform={Permissions.BYPASS_SIA_VALIDATION}>
							<div
								className="mt-4 mb-2 w-fit cursor-pointer"
								onClick={() => setValue("bypassSiaVerification", !watch("bypassSiaVerification"))}
							>
								<label className="mr-2 cursor-pointer text-md font-normal">
									Bypass SIA verification
								</label>
								<Switch checked={watch("bypassSiaVerification")} />
							</div>
						</AllowedTo>
						<div className="col-span-2">
							<FormInputAntd type="textarea" label="Notes" name="notes" />
						</div>
					</div>
					{isRecurringSchedule ? (
						<AntdButton htmlType="submit" loading={saving} disabled={!isDirty} className="mt-5">
							Next
						</AntdButton>
					) : (
						<AntdButton loading={saving} disabled={!isDirty} className="mt-5" htmlType="submit">
							Save
						</AntdButton>
					)}
				</form>
			</FormProvider>
		</Modal>
	)
}

export default CreateEmployeeScheduleModal
