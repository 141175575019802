import React, { useCallback, useRef, useEffect, useState } from "react"
import cx from "classnames"

interface Selected {
	[key: string]: string | number
}

interface RenderProps {
	row: any
	key: string
	index: number
}
interface Column {
	label: string
	key: string
	render?: (data: RenderProps) => React.ReactNode
	className?: string
	disable?: boolean
}

interface Props {
	scheduleList: any[]
	showRow: number
	columns: Column[]
	SelectedHandler?: (e: Selected) => void
	selected?: Selected[]
}

const ScheduleTableByFilter: React.FC<Props> = ({ columns, selected, showRow, scheduleList }) => {
	const [filteredScheduleList, setFilteredScheduleList] = useState<any[]>([])
	const [count, setCount] = useState(showRow)

	const addMoreHandler = () => setCount(prev => prev + showRow)

	const observer = useRef<IntersectionObserver>()
	const lastTableCell = useCallback((node: any) => {
		if (observer.current) observer.current?.disconnect()
		observer.current = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
			if (entries[0].isIntersecting) {
				addMoreHandler && addMoreHandler()
			}
		})
		if (node) observer.current.observe(node)
	}, [])

	useEffect(() => {
		if (count >= showRow) addMoreData()
	}, [count])

	const addMoreData = () => {
		if (count < scheduleList.length) {
			setFilteredScheduleList(prev => [...prev, ...scheduleList.slice(count - showRow, count)])
		} else if (Math.abs(count - scheduleList.length) < 5) {
			setCount(prev => prev + showRow)
			setFilteredScheduleList(prev => {
				return [...prev, ...scheduleList.slice(scheduleList.length - 1, count)]
			})
		}
	}

	useEffect(() => {
		if (scheduleList?.length && filteredScheduleList.length == 0) {
			setFilteredScheduleList([...scheduleList.slice(0, showRow)])
		} else if (scheduleList.length == 0 && filteredScheduleList.length > 0) {
			setFilteredScheduleList([])
			setCount(showRow)
		}
	}, [scheduleList])

	useEffect(() => {
		return () => {
			if (observer.current) observer.current?.disconnect()
		}
	}, [])

	return (
		<table className="whitespace-no-wrap table-fix-head w-full table-auto text-left">
			<thead className="text-sm">
				<tr className="bg-gray-3 h-9 text-left">
					{columns.map(({ label, disable = false }, colIndex) => {
						if (disable) return
						const thClassName = cx({
							" bg-background px-2.5 py-1.25 text-sm font-semibold text-dark-alt pl-4 font-medium": true,
							"rounded-tl-md": colIndex === 0,
							" rounded-tr-2.5": colIndex === columns.length - 1,
						})
						return (
							<th key={colIndex} className={thClassName}>
								{label}
							</th>
						)
					})}
				</tr>
			</thead>
			<tbody>
				{filteredScheduleList.length ? (
					filteredScheduleList.map((row, rowIndex) => (
						<tr
							ref={filteredScheduleList.length === rowIndex + 1 ? lastTableCell : undefined}
							key={rowIndex}
							className={cx("border-gray-4 h-11  border-t-0.125 border-b border-solid text-sm", {
								"bg-background": selected?.some(sel => sel._id == row._id),
							})}
						>
							{columns.map(({ key, render, className, disable = false }, colIndex) => {
								if (disable) return
								return (
									<td key={colIndex} className={`pl-4 ${className}`}>
										{render ? render({ row, key, index: rowIndex }) : row[key]}
									</td>
								)
							})}
						</tr>
					))
				) : (
					<tr>
						<th colSpan={12} className="p-3 text-center text-dark-alt">
							No Data
						</th>
					</tr>
				)}
			</tbody>
		</table>
	)
}

export default ScheduleTableByFilter
