import React, { useMemo, useState } from "react"
import Users from "./Users"
// import UserRole from "./UserRole"
// import Roles from "./Roles"
import { Permissions } from "config/constants"
import { useAbac } from "react-abac"
import { Divider, Segmented } from "antd-v5"
import useFeatureFlags from "hooks/useFeatureFlags"
import { FeatureFlagEnums } from "types/featureFlags.type"
// import { IS_SUPER_ADMIN, Permissions } from "config/constants"
// import ResourceAccess from "./ResourceAccess"

const Index = () => {
	const { userHasPermissions } = useAbac()
	const { featureFlagEnabled } = useFeatureFlags()
	const [value, setValue] = useState<string>("Users")

	const options = useMemo(() => {
		return [
			{
				label: "Users",
				perform: Permissions.VIEW_ADMIN_SECURITY,
				featureName: FeatureFlagEnums.ADMIN_SECURITY_USERS,
				component: <Users />,
			},
			// {
			// 	label: "User Roles",
			// 	perform: Permissions.VIEW_ADMIN_SECURITY,
			// 	component: <Roles/>,
			// },
			// {
			// 	label: "Resource Access",
			// 	perform: IS_SUPER_ADMIN,
			// 	component: <ResourceAccess/>,
			// },
		]
			.map(item => ({ ...item, value: item.label }))
			.filter(item => userHasPermissions(item.perform) && featureFlagEnabled(item.featureName))
	}, [])

	return (
		<div>
			<Divider style={{ margin: "8px", marginLeft: "0px", marginRight: "0px" }} />
			<div className="flex items-center justify-start">
				<Segmented options={options as any} value={value} onChange={(e: any) => setValue(e)} />
			</div>
			<Divider style={{ margin: "8px", marginLeft: "0px", marginRight: "0px" }} />
			<div className="px-2">{options?.find(item => item.value === value)?.component}</div>
		</div>
	)
}

export default Index
