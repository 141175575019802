import React, { useEffect, useMemo, useRef, useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import useAppDispatch from "hooks/useAppDispatch"
import useAppSelector from "hooks/useAppSelector"
import { SDCompaniesInterface } from "@type/staticData.types"
import { SDCompaniesInitialValues, SDCompaniesSchema } from "Schema/StaticData/SDCompanies"
import { AntdButton, FormInputAntd, GlobalSidebar, SidebarList } from "components"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import {
	getSDCompanies,
	saveSDCompanies,
	selectSDCompaniesById,
	selectSDCompaniesList,
	isSDCompaniesLoading,
	isSDCompaniesSaving,
} from "store/StaticData/SDCompanies.slice"

interface EquipmentTypeFormHandler extends SDCompaniesInterface {}

const SDCompanies = () => {
	const [selectedSDCompanies, setSelectedSDCompanies] = useState<string | null>("")
	const dispatch = useAppDispatch()
	const saving = useAppSelector(isSDCompaniesSaving())
	const loading = useAppSelector(isSDCompaniesLoading())
	const SDCompaniesList = useAppSelector(selectSDCompaniesList)
	const SDCompanies = useAppSelector(selectSDCompaniesById(selectedSDCompanies))
	const isNewBeingCreated = useRef(false)
	const methods = useForm<EquipmentTypeFormHandler>({ resolver: yupResolver(SDCompaniesSchema) })
	const {
		handleSubmit,
		reset,
		formState: { isSubmitted, isSubmitting },
	} = methods
	const sidebarSDCompanies = useMemo(() => {
		return SDCompaniesList?.map(SDCompanies => ({
			_id: SDCompanies._id,
			name: SDCompanies.name,
		}))
	}, [saving, SDCompaniesList?.length, isSubmitted])

	const onSubmit: SubmitHandler<EquipmentTypeFormHandler> = data => {
		isNewBeingCreated.current = true
		dispatch(
			saveSDCompanies(data, id => {
				setSelectedSDCompanies(id)
				isNewBeingCreated.current = false
			}),
		)
	}

	useEffect(() => {
		dispatch(getSDCompanies(true, setSelectedSDCompanies))
	}, [])

	useEffect(() => {
		if (selectedSDCompanies !== (null && undefined)) {
			reset({ ...SDCompaniesInitialValues, ...SDCompanies })
		} else if (selectedSDCompanies === null && !isNewBeingCreated.current) {
			reset({ ...SDCompaniesInitialValues })
		}
	}, [selectedSDCompanies])

	const handleSelected = (SDCompaniesId: string | null): void => setSelectedSDCompanies(SDCompaniesId)

	return (
		<>
			<GlobalSidebar>
				<SidebarList
					title={"Companies"}
					loading={loading}
					selected={selectedSDCompanies}
					setSelected={handleSelected}
					list={sidebarSDCompanies}
				/>
			</GlobalSidebar>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="flex w-1/4 flex-col gap-y-2">
						<FormInputAntd type="text" label="Company Name" name="name" />
						<div className="flex gap-x-2">
							<AntdButton onClick={() => setSelectedSDCompanies(null)}>New</AntdButton>
							<AntdButton disabled={isSubmitting} loading={saving} htmlType="submit">
								{selectedSDCompanies ? "Update" : "Save"}
							</AntdButton>
						</div>
					</div>
				</form>
			</FormProvider>
		</>
	)
}

export default SDCompanies
