import React from "react"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "config"
import NavbarMenu from "./NavbarMenu"
import { Permissions } from "config/constants"
import { useAbac } from "react-abac"
import { Tabs, ConfigProvider } from "antd-v5"
import useFeatureFlags from "hooks/useFeatureFlags"
import { FeatureFlagEnums } from "types/featureFlags.type"

export interface NAVBAR_OPTION {
	label: string
	path: AppRoutes
	perform: Permissions | Permissions[]
	featureName: FeatureFlagEnums
}
export const NAVBAR_OPTIONS: {
	[key: string]: NAVBAR_OPTION[]
} = {
	schedules: [
		{ label: "Schedules", path: AppRoutes.SCHEDULES_PAGE, perform: [], featureName: FeatureFlagEnums.SCHEDULES },
		{ label: "Employees", path: AppRoutes.EMPLOYEES, perform: [], featureName: FeatureFlagEnums.EMPLOYEES },
		{ label: "Sites", path: AppRoutes.LOCATION, perform: [], featureName: FeatureFlagEnums.LOCATIONS },
		{
			label: "Command Center",
			path: AppRoutes.WORK_MONITOR,
			perform: Permissions.VIEW_WORKMONITOR,
			featureName: FeatureFlagEnums.COMMAND_CENTER,
		},
		// { label: "Job application", path: AppRoutes.JAF, perform: [], featureName: FeatureFlagEnums.JOB_APPLICATION_FORM },
		{
			label: "Accounts",
			path: AppRoutes.ACCOUNTS,
			perform: Permissions.VIEW_PAYROLL,
			featureName: FeatureFlagEnums.ACCOUNTS,
		},
		{
			label: "Analytics",
			path: AppRoutes.DASHBOARD,
			perform: Permissions.VIEW_ADMINISTRATION,
			featureName: FeatureFlagEnums.ANALYTICS,
		},
	],
	admin: [
		{ label: "Security", path: AppRoutes.ADMIN_PAGE, perform: [], featureName: FeatureFlagEnums.ADMIN_SECURITY },
		// { label: "Organization", path: AppRoutes.ORGANIZATION, perform: [] },
		{ label: "PayBill", path: AppRoutes.PAY_BILL, perform: [], featureName: FeatureFlagEnums.ADMIN_PAYBILL },
		{
			label: "Static Data",
			path: AppRoutes.STATIC_DATA,
			perform: [],
			featureName: FeatureFlagEnums.ADMIN_STATIC_DATA,
		},
	],
	reports: [],
}
interface Props {
	panel: string
	active: number
	loaderRef: any
}

const NavBar: React.FC<Props> = ({ panel, active, loaderRef }) => {
	const navigate = useNavigate()
	const { userHasPermissions } = useAbac()
	const { featureFlagEnabled } = useFeatureFlags()
	const onChange = (key: string) => {
		loaderRef?.current?.continuousStart()
		const indexOfKey = NAVBAR_OPTIONS[panel].findIndex(item => item.label === key)
		setTimeout(() => navigate(NAVBAR_OPTIONS[panel][indexOfKey].path), 500)
	}
	if (active === -1) return <></>
	return (
		<ConfigProvider prefixCls="ant5" theme={{ components: { Tabs: { horizontalMargin: "0" } } }}>
			<Tabs
				size="middle"
				activeKey={NAVBAR_OPTIONS[panel].find((_, i) => i === active)?.label}
				defaultActiveKey={NAVBAR_OPTIONS[panel].find((_, i) => i === active)?.label}
				onChange={onChange}
				tabBarExtraContent={{ right: <NavbarMenu /> }}
				items={NAVBAR_OPTIONS[panel]
					?.filter(
						({ perform, featureName }: NAVBAR_OPTION) =>
							userHasPermissions(perform) && featureFlagEnabled(featureName),
					)
					.map(({ label }) => ({
						key: label,
						label: label,
					}))}
			/>
		</ConfigProvider>
	)
}

export default NavBar
