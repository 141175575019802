import axios from "axios"
import { GetEmployeeInformationInterface } from "@type/employees.types"
const ROOT_PATH = "employees/information"

export default class EmployeesInformationService {
	static getEmployeesInformationByLocation = (
		locationId: string,
		dates: { startTime: string; finishTime: string }[],
	) => axios.post<Array<GetEmployeeInformationInterface>>(`${ROOT_PATH}/${locationId}`, dates)
}
