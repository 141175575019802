import * as React from "react"

const useHeightResize = ({
	parent = "root",
	element,
	height = 10,
}: {
	parent?: string
	element: string
	height?: number
}) => {
	React.useEffect(() => {
		const handleResize = () => {
			if (document) {
				const parentElement = document.getElementById(parent)
				const childElement = document.getElementById(element)

				const difference =
					(parentElement?.getBoundingClientRect()?.bottom as number) -
					(childElement?.getBoundingClientRect()?.top as number)

				if (childElement) childElement.style.cssText = `height: ${difference - height}px`
			}
		}
		handleResize()
		window.addEventListener("resize", handleResize)
	})
}

export default useHeightResize
