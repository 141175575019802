import React, { useState, useMemo, useEffect } from "react"
import { debounce } from "config/utils"

import Input, { InputProps } from "./Input"

interface Props extends InputProps {
	setSearchString?: any
	searchString?: string
	label?: string
	containerClass?: string
	labelClass?: string
}

const SearchField: React.FC<Props> = ({ setSearchString, searchString = "", ...rest }) => {
	const [searchText, setSearchText] = useState<string>("")

	const check = useMemo(() => debounce(600), [])

	const textHandler = (e: any) => {
		const {
			target: { value },
		} = e
		setSearchText(value)
		check(() => setSearchString(value))
	}

	useEffect(() => {
		setSearchText(searchString)
	}, [searchString.length])

	return (
		<div>
			<Input {...rest} value={searchText} onChange={textHandler} type="search" />
		</div>
	)
}

export default SearchField
