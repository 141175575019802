import axios from "axios"
import { GetSDCompaniesInterface, SDCompaniesInterface } from "@type/staticData.types"

const ROOT_PATH = "/organizations"
export default class SDCompaniesService {
	static createSDCompanies = (companies: SDCompaniesInterface) =>
		axios.post<GetSDCompaniesInterface>(ROOT_PATH, companies)

	static getAllSDCompanies = () => axios.get<Array<GetSDCompaniesInterface>>(ROOT_PATH)

	static getSDCompaniesById = (id: string) => axios.get<GetSDCompaniesInterface>(`${ROOT_PATH}/${id}`)

	static updateSDCompanies = (id: string, companies: Partial<GetSDCompaniesInterface>) =>
		axios.put<GetSDCompaniesInterface>(`${ROOT_PATH}/${id}`, companies)
}
