import { DatePicker } from "antd"
import moment, { Moment } from "moment"
import { fieldsError } from "config/utils"

import styles from "../../src/antd-datePicker.module.css"
import { DATE_FORMATS } from "config/constants"
import { FieldError, FieldErrors, FieldPath, FieldValues, UseFormWatch } from "react-hook-form"
import { useEffect, useRef } from "react"
import { twMerge } from "tailwind-merge"
import InputLabel from "./InputLabel"

interface Props<T extends FieldValues> {
	label?: string
	containerClass?: string
	className?: string
	clearErrors?: (name?: any) => void
	setValue: any
	name: FieldPath<T>
	watch: UseFormWatch<T>
	disabled?: boolean
	showTime?: boolean
	errorClass?: string
	errors?: { [key: string]: FieldError | undefined } | FieldErrors
}

function DateField<T extends FieldValues>({
	setValue,
	name,
	label,
	errors = {},
	className = "w-full",
	clearErrors,
	containerClass = "w-full",
	watch,
	errorClass,
	showTime = false,
	disabled = false,
}: Props<T>) {
	const formValue = watch(name) ? moment(watch(name)) : watch(name)
	const format = showTime ? DATE_FORMATS.DD_MM_YYYY_HH_MM : DATE_FORMATS.DD_MM_YYYY
	const errorRef = useRef<HTMLDivElement | null>(null)

	const executeScroll = () => errorRef?.current?.scrollIntoView()

	const onChange = (date: Moment | null) => {
		if (date) {
			setValue(name, date.toISOString(), {
				shouldDirty: true,
			})
			clearErrors?.(name)
		} else {
			setValue(name, "")
		}
	}

	useEffect(() => {
		if (fieldsError(name, errors)) executeScroll()
	}, [fieldsError(name, errors)])

	return (
		<div ref={errorRef} className={`flex flex-col  ${containerClass}`}>
			<InputLabel label={label || ""} />
			<div className={className}>
				<DatePicker
					style={{ width: "100%" }}
					showTime={showTime}
					disabled={disabled}
					value={formValue}
					className={styles.datePickerStyle}
					format={format}
					placeholder={format}
					onChange={onChange}
				/>
			</div>
			{fieldsError(name, errors) && (
				<p className={twMerge(`mt-1 ml-1 text-sm text-danger`, errorClass)}>
					{fieldsError(name, errors)?.message}
				</p>
			)}
		</div>
	)
}

export default DateField
