import { useEffect, useMemo, useRef, useState } from "react"
import { GlobalSidebar, SidebarList, FormInputAntd, AntdButton } from "components"
import PBTermDetails from "./PBTermDetails"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { PBTermInitialValues, PBTermSchema } from "Schema"
import { PBTermsInterface } from "@type/payBill.types"
import useAppSelector from "hooks/useAppSelector"
import useAppDispatch from "hooks/useAppDispatch"
import {
	getPBTerms,
	isPBTermLoading,
	isPBTermSaving,
	savePBTerm,
	selectPBTermList,
	selectPBTermById,
} from "store/PayBill/PBTerms.slice"

interface PBTermFormHandler extends PBTermsInterface {}

const PBTerms = () => {
	const [selectedPBTerm, setSelectedPBTerm] = useState<string | null>("")
	const dispatch = useAppDispatch()
	const loading = useAppSelector(isPBTermLoading())
	const saving = useAppSelector(isPBTermSaving())
	const PBTermsList = useAppSelector(selectPBTermList)
	const PBTerm = useAppSelector(selectPBTermById(selectedPBTerm))
	const isNewBeingCreated = useRef(false)

	const methods = useForm<PBTermFormHandler>({
		resolver: yupResolver(PBTermSchema),
		defaultValues: PBTermInitialValues,
	})
	const {
		reset,
		handleSubmit,
		formState: { isSubmitting, isSubmitted, isDirty },
	} = methods

	const sidebarPBTerms = useMemo(() => {
		return PBTermsList?.map(PBTerm => ({ _id: PBTerm._id, name: PBTerm.name }))
	}, [PBTermsList?.length, isSubmitted, saving])

	const onSubmit: SubmitHandler<PBTermFormHandler> = data => {
		isNewBeingCreated.current = true
		dispatch(
			savePBTerm(data, id => {
				setSelectedPBTerm(id)
				isNewBeingCreated.current = false
			}),
		)
	}

	useEffect(() => {
		dispatch(getPBTerms(setSelectedPBTerm))
	}, [])

	useEffect(() => {
		if (selectedPBTerm !== (null && undefined) && PBTerm) {
			reset({ ...PBTerm })
		} else if (selectedPBTerm === null && !isNewBeingCreated.current) {
			reset({ ...PBTermInitialValues })
		}
	}, [selectedPBTerm, loading])

	const handleSelected = (PBTermId: string | null): void => setSelectedPBTerm(PBTermId)

	return (
		<>
			<GlobalSidebar>
				<SidebarList
					title={"PBTerms"}
					loading={loading}
					selected={selectedPBTerm}
					setSelected={handleSelected}
					list={sidebarPBTerms}
				/>
			</GlobalSidebar>
			<div className="space-y-5">
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="flex flex-col space-y-2">
							<div className="flex w-3/5 space-x-5">
								<FormInputAntd type="text" label="Term Name" name="name" />
								<FormInputAntd type="textarea" label="Description" name="description" />
							</div>
							<div className="flex space-x-2">
								<AntdButton htmlType="button" onClick={() => setSelectedPBTerm(null)}>
									New
								</AntdButton>
								<AntdButton htmlType="submit" disabled={!isDirty || isSubmitting} loading={saving}>
									{selectedPBTerm ? "Update" : "Save"}
								</AntdButton>
							</div>
						</div>
					</form>
				</FormProvider>
				<PBTermDetails termId={selectedPBTerm || ""} />
			</div>
		</>
	)
}

export default PBTerms
