import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetPBRulesInterface, PBRulesInterface, UpdatePBRulesInterface } from "@type/payBill.types"
import { PBRulesService } from "services"
import { AppThunk, RootState } from "store"
import { sortByProperty, sortDeepCopyByProperty, updateState } from "config/utils"
import { RuleType } from "config/constants"
export interface PBRulesStateInterface {
	list: null | Array<GetPBRulesInterface>
	loading: boolean
	saving: boolean
}

const initialState: PBRulesStateInterface = {
	list: null,
	loading: false,
	saving: false,
}

const PBRulesSlice = createSlice({
	name: "PBRules",
	initialState,
	reducers: {
		fetchingPBRules: PBRule => {
			PBRule.loading = true
		},
		PBRulesFetched: (PBRule, action: PayloadAction<GetPBRulesInterface[]>) => {
			PBRule.loading = false
			PBRule.list = action.payload
		},
		PBRulesFetchingFailed: PBRule => {
			PBRule.loading = false
		},
		savingPBRule: PBRuleData => {
			PBRuleData.saving = true
		},
		PBRuleSaved: (PBRule, action: PayloadAction<GetPBRulesInterface>) => {
			PBRule.saving = false
			const [UpdatedValue] = updateState(PBRule.list, action.payload, "_id")
			PBRule.list = UpdatedValue
		},
		PBRuleSavingFailed: PBRule => {
			PBRule.saving = false
		},
	},
})

//REDUCER
export default PBRulesSlice.reducer

//ACTIONS
const { fetchingPBRules, PBRulesFetched, PBRulesFetchingFailed, savingPBRule, PBRuleSaved, PBRuleSavingFailed } =
	PBRulesSlice.actions

const getPBRules =
	(cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingPBRules())
			const { data: PBRuleResponse } = await PBRulesService.getAllPBRules()
			PBRuleResponse.sort(sortByProperty("name"))
			cb && cb(PBRuleResponse[0]?._id)
			dispatch(PBRulesFetched(PBRuleResponse))
		} catch (error) {
			dispatch(PBRulesFetchingFailed())
		}
	}

const savePBRule =
	(PBRuleData: Partial<UpdatePBRulesInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingPBRule())
			if (PBRuleData?._id) data = await PBRulesService.updatePBRule(PBRuleData._id, PBRuleData)
			else data = await PBRulesService.createPBRule(PBRuleData as PBRulesInterface)
			const { data: PBRuleResponse } = data
			cb && cb(PBRuleResponse._id)
			dispatch(PBRuleSaved(PBRuleResponse))
		} catch (error) {
			dispatch(PBRuleSavingFailed())
		}
	}

export { getPBRules, savePBRule }

//SELECTORS
const selectPBRuleState = (state: RootState) => state.payBill.PBRules
const isPBRuleLoading = () => (state: RootState) => selectPBRuleState(state).loading
const isPBRuleSaving = () => (state: RootState) => selectPBRuleState(state).saving
const selectPBRuleList = createSelector(
	(state: RootState) => state.payBill.PBRules.list,
	list => {
		return sortDeepCopyByProperty(list, "name") as GetPBRulesInterface[]
	},
)

const selectFilteredPBRuleList = (type: string) => (state: RootState) =>
	sortDeepCopyByProperty(
		type === RuleType.BOTH
			? state.payBill.PBRules.list
			: state.payBill.PBRules.list?.filter(rule => rule.type === type) || [],
		"name",
	) as GetPBRulesInterface[]

const selectPBRuleById = (PBRuleId: string | null) => (state: RootState) =>
	state.payBill.PBRules?.list?.find(PBRule => PBRule._id === PBRuleId)

export {
	selectPBRuleList,
	selectFilteredPBRuleList,
	selectPBRuleState,
	selectPBRuleById,
	isPBRuleLoading,
	isPBRuleSaving,
}
