import axios from "axios"
import { GetSDDocumentTypeInterface, SDDocumentTypeInterface } from "@type/staticData.types"

const ROOT_PATH = "static-data/document-type"
export default class SDDocumentTypesService {
	static createSDDocumentType = (qualification: SDDocumentTypeInterface) =>
		axios.post<GetSDDocumentTypeInterface>(ROOT_PATH, qualification)

	static getAllSDDocumentTypes = () => axios.get<Array<GetSDDocumentTypeInterface>>(`${ROOT_PATH}`)

	static getSDDocumentTypeById = (id: string) => axios.get<GetSDDocumentTypeInterface>(`${ROOT_PATH}/${id}`)

	static updateSDDocumentType = (id: string, qualification: Partial<GetSDDocumentTypeInterface>) =>
		axios.put<GetSDDocumentTypeInterface>(`${ROOT_PATH}/${id}`, qualification)
}
