// PACKAGES/ HOOKS
import React, { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "hooks"
// COMPONENTS
import EscalationForm from "./EscalationForm"
import EscalationsTable from "./EscalationsTable"
// SLICE/CONTEXT
import EscalationContext from "./EscalationContext"
import { getLocationEscalationsByLocationId } from "store/Location/locationEscalations.slice"
// UTILS/CONSTANTS
import { GetLocationEscalationsInterface } from "@type/locations.types"
import { selectSelectedLocation } from "store/globalState.slice"

const LocationEscalations: React.FC = () => {
	const dispatch = useAppDispatch()

	const [locationEscalation, setLocationEscalation] = useState<GetLocationEscalationsInterface | null>(null)

	const selected = useAppSelector(selectSelectedLocation)

	const handleLocationEscalationHandler = (escalations: GetLocationEscalationsInterface | null) => {
		setLocationEscalation(escalations)
	}

	useEffect(() => {
		selected && dispatch(getLocationEscalationsByLocationId(selected))
	}, [selected])

	return (
		<>
			<EscalationContext.Provider value={{ locationEscalation, handleLocationEscalationHandler }}>
				<EscalationForm />
				<EscalationsTable />
			</EscalationContext.Provider>
		</>
	)
}

export default LocationEscalations
