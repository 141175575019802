import React, { useEffect, useState } from "react"
import { momentZ } from "config/moment"
import { Icon, Table2, Button } from "components"
import { Timeline } from "antd"
import { getAuditTrail, isAuditTrailLoading, selectAuditTrail } from "store/AuditTrail/auditTrail.slice"
import useAppDispatch from "hooks/useAppDispatch"
import { Entities } from "types/auditTrail.types"
import useAppSelector from "hooks/useAppSelector"
import { Drawer } from "antd"
import moment from "moment"
import { DATE_FORMATS } from "config/constants"
import { isArray, isObject } from "lodash"

interface Props {
	id: string | null | undefined
	name: Entities
	showLabel?: boolean
	showInSchedule?: boolean
	disabled?: boolean
}

const AuditTrail: React.FC<Props> = ({ id, name, showLabel = true, showInSchedule = false, disabled = false }) => {
	const dispatch = useAppDispatch()
	const logs = useAppSelector(selectAuditTrail(id || "", name))
	const loading = useAppSelector(isAuditTrailLoading(id || "", name))
	const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false)
	const toggleHistoryModal = () => setShowHistoryModal(!showHistoryModal)

	useEffect(() => {
		if (id) dispatch(getAuditTrail(id, name))
	}, [id])
	if (!showInSchedule && loading) return <Icon name="spinner" size={20} color="black" className="animate-spin" />
	if (!showInSchedule && !logs?.length) return <></>
	return (
		<>
			{showInSchedule ? (
				<Button disabled={disabled || !logs?.length} loading={loading} onClick={toggleHistoryModal}>
					Audit
				</Button>
			) : (
				<div className="flex cursor-pointer items-center" onClick={toggleHistoryModal}>
					{showLabel ? (
						<span>
							{"Last Updated on "}
							{momentZ(logs?.[0]?.createdAt).format("MMMM DD, YYYY")}
							{" at "}
							{momentZ(logs?.[0]?.createdAt).format("HH:mm")}
							{" by "}
							{logs?.[0]?.user}
						</span>
					) : undefined}
					<Icon name="history" color="black" size={18} className="ml-2" />
				</div>
			)}
			{logs?.length ? (
				<Drawer
					contentWrapperStyle={{ backgroundColor: "transparent" }}
					title="Change Logs"
					placement="right"
					onClose={() => setShowHistoryModal(false)}
					open={showHistoryModal}
					width={"45%"}
					rootStyle={{
						color: "rgb(229 229 229);",
					}}
					headerStyle={{
						background: "#1f2937",
					}}
					closeIcon={<Icon name="cross" color="white" size={18} />}
				>
					<div className="px-2">
						<Timeline>
							{logs
								.map(({ changes, ...rest }) => ({
									changes: changes.filter(
										change =>
											!(
												isObject(change.to) ||
												isObject(change.from) ||
												isArray(change.to) ||
												isArray(change.from)
											),
									),
									...rest,
								}))
								.map((log: any, index: number) => {
									return (
										<Timeline.Item color="#ababab" key={`history_log_${index}`}>
											<div className={"flex flex-col gap-2"}>
												<div className="flex items-center gap-2 font-semibold">
													Changes
													{" on "}
													{momentZ(log?.createdAt).format("MMMM DD, YYYY")}
													{" at "}
													{momentZ(log?.createdAt).format("HH:mm")}
													{" by "}
													{log?.user}
												</div>
												<Table2
													tableClassName="table-fixed"
													columns={[
														{ label: "Property Name", key: "label" },
														{
															label: "Old Value",
															key: "from",

															render: ({ row }) => (
																<span className="rounded-2 bg-red-100 p-2">
																	{moment(row.from).toString() !== "Invalid date"
																		? moment(row.from).format(
																				DATE_FORMATS.SHIFT_EXPECTED_TIME_FORMAT,
																		  )
																		: row.from}
																</span>
															),
														},
														{
															label: "New Value",
															key: "to",

															render: ({ row }) => (
																<span className="rounded-2 bg-green-100 p-2">
																	{moment(row.to).toString() !== "Invalid date"
																		? moment(row.to).format(
																				DATE_FORMATS.SHIFT_EXPECTED_TIME_FORMAT,
																		  )
																		: row.to}
																</span>
															),
														},
													]}
													rows={log.changes}
												/>
											</div>
										</Timeline.Item>
									)
								})}
						</Timeline>
					</div>
				</Drawer>
			) : undefined}
		</>
	)
}

export default AuditTrail
