import React, { useEffect, useMemo, useRef, useState } from "react"
import cx from "classnames"
import { twMerge } from "tailwind-merge"
import { ShiftInterface } from "@type/workMonitor.types"
import { lowerCase } from "lodash"

interface RenderProps {
	row: any
	key: string
	rowIndex: number
}
export interface ColumnInterfaceCommandCenter {
	label: string
	key: string
	render?: (data: RenderProps) => React.ReactNode
	className?: string
	classNameFunc?: (data: RenderProps) => string
}

interface Props {
	columns: ColumnInterfaceCommandCenter[]
	rows: any[]
	alternateColor?: boolean
	rowClicked?: (data: any, event: any) => void
	selectedRow?: ShiftInterface[] | null
	openShiftDetailsDrawer?: () => void
	searchString?: string
}

const WorkMonitorTable: React.FC<Props> = ({
	columns,
	rows,
	alternateColor = false,
	rowClicked = () => undefined,
	selectedRow,
	searchString = "",
	openShiftDetailsDrawer = () => undefined,
}) => {
	// const timeoutRef = useRef<NodeJS.Timeout | null>(null)
	const tableRef = useRef<HTMLTableElement>(null)
	const [animationClass, setAnimationClass] = useState("animate-blink-red")

	const [focusedRow, setFocusedRow] = useState<number | undefined>(undefined)
	// const handleMouseDown = (row: ShiftInterface, event: any) => {
	// if (timeoutRef.current) {
	// 	clearTimeout(timeoutRef.current)
	// 	timeoutRef.current = null
	// 	if (!selectedRow || selectedRow?.some(selected => selected._id === row._id)) rowClicked(row, event)
	// 	openShiftDetailsDrawer()
	// } else {
	// 	const newTimeoutId = setTimeout(() => {
	// 		clearTimeout(newTimeoutId)
	// 		timeoutRef.current = null
	// 		rowClicked(row, event)
	// 	}, 200)
	// 	timeoutRef.current = newTimeoutId
	// }
	// }
	useEffect(() => {
		const matchedRow = rows.findIndex(
			row =>
				lowerCase(row?.employee).includes(lowerCase(searchString)) ||
				lowerCase(row?.location).includes(lowerCase(searchString)),
		)
		setFocusedRow(matchedRow !== -1 ? matchedRow : undefined)
	}, [rows, searchString])

	useEffect(() => {
		if (focusedRow && tableRef.current) {
			const rowElement = tableRef.current.querySelector(`tr[data-index="${focusedRow}"]`) as HTMLTableRowElement

			if (rowElement) {
				rowElement.focus()
				rowElement.scrollIntoView({ behavior: "smooth", block: "center" })
			}
		}
	}, [focusedRow])

	const rowIds = useMemo(() => {
		return selectedRow?.map(item => item._id).join("_")
	}, [selectedRow])

	useEffect(() => {
		// if (!selectedRow?.length) {
		setAnimationClass("")
		setTimeout(() => {
			setAnimationClass("animate-blink-red")
		}, 10)
		// }
	}, [rowIds])
	// useEffect(() => {
	// 	return () => {
	// 		if (timeoutRef.current) {
	// 			clearTimeout(timeoutRef.current)
	// 		}
	// 	}
	// }, [timeoutRef.current])

	const workMonitorTableColor = (row: any) => {
		return twMerge(
			...Object.entries({
				"odd:bg-white even:bg-slate-50 border-b-gray-300":
					!selectedRow?.some(selected => selected?._id === row?._id) && !alternateColor,
				[`!bg-purple text-white`]: selectedRow?.some(selected => selected._id === row?._id),
			}).map(([key, value]) => (value ? key : "")),
		)
	}

	return (
		<div className="table-fix-head schedule-scrollbar h-[82vh] w-full">
			<table className="whitespace-no-wrap mt-2 w-full table-auto text-left" ref={tableRef}>
				<thead className="text-sm">
					<tr className="bg-gray-3 h-10 text-left">
						{columns.map(({ label }, colIndex) => {
							const thClassName = cx({
								" bg-background px-2.5 py-1.25 text-md h-auto font-Semi text-black pl-4": true,
								"rounded-tl-md": colIndex === 0,
								" rounded-tr-2.5": colIndex === columns.length - 1,
							})
							return (
								<th key={`${label}_${colIndex}`} className={thClassName}>
									{label}
								</th>
							)
						})}
					</tr>
				</thead>
				<tbody>
					{rows.length ? (
						rows.map((row, rowIndex) => {
							return (
								<tr
									key={rowIndex}
									className={`h-7 border-b text-sm font-normal  ${
										rowIndex === focusedRow ? "focused" : ""
									} ${workMonitorTableColor(row)}`}
									onMouseDown={(event: any) => {
										if (event.button === 0) rowClicked(row, event)
									}}
									onDoubleClick={e => {
										if (!e.ctrlKey) {
											openShiftDetailsDrawer()
										}
									}}
									data-index={rowIndex}
									tabIndex={0}
								>
									{columns.map(
										({ key, render, className = "", classNameFunc = () => "" }, colIndex) => {
											return (
												<td
													key={`${rowIndex}_${colIndex}`}
													// className={`pl-4 ${className} ${classNameFunc({
													className={`pl-4 ${className} ${classNameFunc({
														row,
														key,
														rowIndex,
													})} cursor-pointer ${
														(row.isLate || !row.employee) &&
														!selectedRow?.some(selected => selected._id === row?._id)
															? animationClass
															: ""
													}`}
												>
													{render ? render({ row, key, rowIndex }) : row[key]}
												</td>
											)
										},
									)}
								</tr>
							)
						})
					) : (
						<tr>
							<th colSpan={12} className="  p-3 text-center text-dark-alt">
								No Data
							</th>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}

export default WorkMonitorTable
