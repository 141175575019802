import React from "react"
import { FieldError, FieldErrors, UseFormWatch, SetFieldValue } from "react-hook-form"
import { InputProps } from "./Input"
import { fieldsError } from "config/utils"
import { Switch } from "@headlessui/react"

interface Props extends InputProps {
	watch: UseFormWatch<any>
	setValue: SetFieldValue<any>
	errors?: { [key: string]: FieldError | undefined } | FieldErrors
	containerClass?: string
	errorClass?: string
	loading?: boolean
}

const FormSwitchInput: React.FC<Props> = ({
	watch,
	errors = {},
	containerClass,
	name = "",
	errorClass,
	label,
	setValue,
	...rest
}) => {
	return (
		<>
			<div className="flex flex-col">
				<div className={`flex  ${containerClass}`}>
					<label htmlFor={label} className={`${rest.className} mr-4 cursor-pointer`}>
						{label}
					</label>

					<Switch
						checked={watch(name)}
						onChange={e => setValue(name, e)}
						className={`${
							watch(name) ? "bg-blue-600" : "bg-gray-200"
						} relative inline-flex h-6 w-11 items-center rounded-full`}
					>
						<span className="sr-only">Enable</span>
						<span
							className={`${
								watch(name) ? "translate-x-6" : "translate-x-1"
							} inline-block h-4 w-4 transform rounded-full bg-white transition`}
						/>
					</Switch>
				</div>

				{fieldsError(name, errors) && (
					<p className={`mt-1 ml-1 text-sm text-danger ${errorClass}`}>
						{fieldsError(name, errors)?.message}
					</p>
				)}
			</div>
		</>
	)
}

export default FormSwitchInput
