import axios from "axios"
import { EmployeeHRInterface } from "@type/employees.types"

const ROOT_PATH = "employees/hr"

export default class EmployeesHrService {
	static createEmployeeHr = (employeeHr: EmployeeHRInterface) =>
		axios.post<EmployeeHRInterface>(ROOT_PATH, employeeHr)

	static getEmployeesHr = (employeeId: string) => axios.get<EmployeeHRInterface>(`${ROOT_PATH}/${employeeId}`)

	static updateEmployeeHr = (HrId: string, hrData: EmployeeHRInterface) =>
		axios.put<EmployeeHRInterface>(`${ROOT_PATH}/${HrId}`, hrData)

	static deleteEmployeesHr = (id: string) => axios.delete(`${ROOT_PATH}/${id}`)
}
