import axios from "axios"
import { EmployeeDocumentInterface } from "@type/employees.types"

const ROOT_PATH = "employees/documents"

export default class EmployeesdocumentService {
	static createEmployeeDocument = (employeeDocs: EmployeeDocumentInterface) =>
		axios.post<EmployeeDocumentInterface>(ROOT_PATH, employeeDocs)

	static getEmployeesDocument = (employeeId: string) =>
		axios.get<EmployeeDocumentInterface[]>(`${ROOT_PATH}/${employeeId}`)

	static updateEmployeeDocument = (id: string, documentData: EmployeeDocumentInterface) =>
		axios.put<EmployeeDocumentInterface>(`${ROOT_PATH}/${id}`, documentData)
}
