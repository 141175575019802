import React from "react"

const TableSkeleton = () => {
	return (
		<div>
			<div className="flex animate-pulse flex-col gap-1.25 px-2.5 py-2.5">
				<div className="flex justify-around gap-1.25 border-1 bg-secondary-dark px-0.25 py-1.25">
					<div className="h-7.5 w-full rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-7.5 w-full rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-7.5 w-full rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-7.5 w-full rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
				</div>
				<div className="flex justify-around gap-1.25 ">
					<div className="h-7.5 w-full rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-7.5 w-full rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-7.5 w-full rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-7.5 w-full rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
				</div>
				<div className="flex justify-around gap-1.25 ">
					<div className="h-7.5 w-full rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-7.5 w-full rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-7.5 w-full rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-7.5 w-full rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
				</div>
				<div className="flex justify-around gap-1.25 ">
					<div className="h-7.5 w-full rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-7.5 w-full rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-7.5 w-full rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
					<div className="h-7.5 w-full rounded-md border-b-1 border-secondary-dark bg-secondary-light"></div>
				</div>
			</div>
		</div>
	)
}

export default TableSkeleton
