import { useEffect, useMemo, useState } from "react"
import { AntdButton, AntdSwitch, Icon, LocationSidebar, Select } from "components"
import { selectSelectedLocation, setSelectedLocation } from "store/globalState.slice"
import useAppSelector from "hooks/useAppSelector"
import useAppDispatch from "hooks/useAppDispatch"
import { getBranches } from "store/Branches/branch.slice"
import {
	selectLocationById,
	getLocationStats,
	selectLocationStatsLoading,
	selectLocationList,
	selectLocationTrendsLoading,
	getLocationTrendsInChunks,
} from "store/Location/locationDetails.slice"
import MapSection from "./MapSection"
import StatsSection from "./StatsSection"
import ChartSection from "./ChartSection"
import moment, { Moment } from "moment"
import { EventValue } from "rc-picker/lib/interface"
import ApexChart from "./ApexChart"
import { DatePicker } from "antd"
import { momentZ } from "config/moment"
import { GeocodeLatLngInterface } from "@type/geolocation.types"
import { convertDateTimeToUTC } from "config/utils"
import { DashboardDateFilter, DATE_FORMATS } from "config/constants"

const Dashboard = () => {
	const dispatch = useAppDispatch()
	const [date, setDate] = useState<{ startTime: EventValue<Moment>; finishTime: EventValue<Moment> }>({
		startTime: momentZ().utc().startOf("week"),
		finishTime: momentZ().utc().endOf("week"),
	})
	const [picker, setPicker] = useState<DashboardDateFilter>(DashboardDateFilter.WEEKLY)
	const selectedLocation = useAppSelector(selectSelectedLocation)
	const [forAllLocations, setForAllLocations] = useState(false)
	const [dashboardSelectedLocations, setDashboardSelectedLocations] = useState<string[]>([])
	const locationDetails = useAppSelector(selectLocationById(selectedLocation))
	const allLocations = useAppSelector(selectLocationList())
	const locationStatsLoading = useAppSelector(selectLocationStatsLoading())
	const locationTrendsLoading = useAppSelector(selectLocationTrendsLoading())
	const [isAllBranchesSelected, setIsAllBranchesSelected] = useState(false)
	const [isActiveOnlySelected, setIsActiveOnlySelected] = useState(true)

	const pickerTypes = [DashboardDateFilter.DAILY, DashboardDateFilter.WEEKLY, DashboardDateFilter.MONTHLY]

	const handleIsAllBranchesSelected = () => {
		setIsAllBranchesSelected(prevState => !prevState)
	}

	const handleIsActiveOnlySelected = (isActiveOnlySelected: boolean) => {
		setIsActiveOnlySelected(isActiveOnlySelected)
	}

	useEffect(() => {
		setPicker(DashboardDateFilter.WEEKLY)
	}, [])

	useEffect(() => {
		switch (picker) {
			case DashboardDateFilter.DAILY:
				setDate(() => ({
					startTime: momentZ()?.utc().startOf("day"),
					finishTime: momentZ()?.utc().endOf("day"),
				}))
				break

			case DashboardDateFilter.WEEKLY:
				setDate(prev => ({
					startTime: momentZ(prev?.startTime)?.utc().startOf("week") || momentZ().utc().startOf("week"),
					finishTime: momentZ(prev?.startTime)?.utc().endOf("week") || momentZ().utc().endOf("week"),
				}))
				break

			case DashboardDateFilter.MONTHLY:
				setDate(prev => ({
					startTime: momentZ(prev?.startTime)?.utc().startOf("month") || momentZ().utc().startOf("month"),
					finishTime: momentZ(prev?.startTime)?.utc().endOf("month") || momentZ().utc().endOf("month"),
				}))
				break
		}
	}, [picker])

	useEffect(() => {
		dispatch(getBranches(false))
	}, [])

	useEffect(() => {
		if (locationDetails) {
			dispatch(
				getLocationStats({
					...(!isAllBranchesSelected && { locationIds: [selectedLocation] }),
					date: {
						startTime: moment(date.startTime)
							.set({ hour: 0, minute: 0, second: 0 })
							.format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1),
						finishTime: convertDateTimeToUTC(moment(date.finishTime))
							.set({ hour: 23, minute: 59, second: 59 })
							.format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1),
					},
					activeOnly: isActiveOnlySelected,
				}),
			)

			dispatch(
				getLocationTrendsInChunks(
					picker as DashboardDateFilter,
					date.finishTime,
					[selectedLocation],
					isAllBranchesSelected,
					isActiveOnlySelected,
				),
			)
		}
	}, [locationDetails, isAllBranchesSelected])

	const handleSelected = (location: string) => {
		dispatch(setSelectedLocation(location))
	}

	const showMarkers: GeocodeLatLngInterface[] = useMemo(() => {
		if (dashboardSelectedLocations.length === allLocations.length) {
			return allLocations
				.filter(item => !!item.position && !!item.position.type)
				.map(({ position, name }) => ({
					lat: position.lat,
					lng: position.lng,
					markerHoverInfo: {
						title: name,
					},
				}))
		} else if (dashboardSelectedLocations.length > 0) {
			return allLocations
				.filter(
					item => !!item.position && !!item.position.type && dashboardSelectedLocations.includes(item._id),
				)
				.map(({ position, name }) => ({
					lat: position.lat,
					lng: position.lng,
					markerHoverInfo: {
						title: name,
					},
				}))
		} else {
			return [
				{
					lat: locationDetails?.position.lat ?? 53.4722454,
					lng: locationDetails?.position.lng ?? -2.2234628,
				},
			]
		}
	}, [dashboardSelectedLocations.length, allLocations, locationDetails])

	return (
		<div className="h-[94vh] w-full bg-background">
			<LocationSidebar
				selected={selectedLocation}
				handleSelected={handleSelected}
				forAllLocations={forAllLocations}
				handleSelectedLocations={locationIds => {
					setDashboardSelectedLocations(locationIds)
				}}
				allowSelectAllBranches
				handleIsAllBranchesSelected={handleIsAllBranchesSelected}
				isAllBranchesSelected={isAllBranchesSelected}
				handleIsActiveOnlySelected={handleIsActiveOnlySelected}
			/>

			<div className="flex h-full w-full space-x-5 p-5">
				{locationStatsLoading ? (
					<div className="flex h-full w-[50vw] items-center justify-center bg-white">
						<Icon name="spinner" size={30} color="black" className="animate-spin" />
					</div>
				) : (
					<div className="flex h-full w-[45vw] flex-col bg-white p-3 drop-shadow-md ">
						<div className="flex items-center justify-start space-x-5">
							<Select
								className="mb-2 h-12 w-28 text-sm"
								onChange={e => {
									setPicker(e.target.value as DashboardDateFilter)
								}}
								value={picker}
							>
								{pickerTypes.map((type, index) => (
									<option key={index} value={type}>
										{type}
									</option>
								))}
							</Select>
							{picker === "Weekly" && (
								<DatePicker
									style={{ border: "solid slate", height: "48px", minWidth: "280px" }}
									size="large"
									picker="week"
									placeholder="Select Week"
									value={date.startTime}
									onChange={value => {
										if (value) {
											setDate(() => ({
												startTime: momentZ(value).utc().startOf("week"),
												finishTime: momentZ(value).utc().endOf("week"),
											}))
										} else {
											setDate({ startTime: null, finishTime: null })
										}
									}}
								/>
							)}
							{picker === "Daily" && (
								<DatePicker
									style={{ border: "solid slate", height: "48px", minWidth: "280px" }}
									size={"large"}
									placeholder="Select Day"
									value={date.startTime}
									onChange={value => {
										if (value) {
											setDate(() => ({
												startTime: momentZ(value).utc().startOf("day"),
												finishTime: momentZ(value).utc().endOf("day"),
											}))
										} else {
											setDate({ startTime: null, finishTime: null })
										}
									}}
								/>
							)}
							{picker === "Monthly" && (
								<DatePicker
									style={{ border: "solid slate", height: "48px", minWidth: "280px" }}
									size={"large"}
									picker="month"
									placeholder="Select Month"
									value={date.startTime}
									onChange={value => {
										if (value) {
											setDate({
												...date,
												startTime: moment(value).startOf("month"),
												finishTime: moment(value).endOf("month"),
											})
										} else {
											setDate({ startTime: null, finishTime: null })
										}
									}}
								/>
							)}
							<AntdSwitch
								className="bg-[#00000055]"
								checkedChildren="All Locations"
								unCheckedChildren="Selected Locations"
								checked={forAllLocations}
								onChange={() => setForAllLocations(prev => !prev)}
								disabled={isAllBranchesSelected}
							/>
							<div>
								<AntdButton
									disabled={!dashboardSelectedLocations.length && !selectedLocation}
									onClick={() => {
										dispatch(
											getLocationStats({
												...(!isAllBranchesSelected && {
													locationIds: dashboardSelectedLocations.length
														? dashboardSelectedLocations
														: [selectedLocation],
												}),
												date: {
													startTime: moment(date.startTime)
														.set({ hour: 0, minute: 0, second: 0 })
														.format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1),
													finishTime: convertDateTimeToUTC(moment(date.finishTime))
														.set({ hour: 23, minute: 59, second: 59 })
														.format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1),
												},
												activeOnly: isActiveOnlySelected,
											}),
										)
										dispatch(
											getLocationTrendsInChunks(
												picker as DashboardDateFilter,
												date.finishTime,
												dashboardSelectedLocations.length
													? dashboardSelectedLocations
													: [selectedLocation],
												isAllBranchesSelected,
												isActiveOnlySelected,
											),
										)
									}}
								>
									Show Data
								</AntdButton>
							</div>
						</div>

						<StatsSection />
						<ChartSection />
					</div>
				)}
				<div className="flex h-full w-[35vw] flex-col space-y-5">
					<div className="h-[45%] ">
						{locationTrendsLoading ? (
							<div className="flex h-full w-full  items-center justify-center bg-white">
								<Icon name="spinner" size={30} color="black" className="animate-spin" />
							</div>
						) : (
							<div className="h-full bg-white p-2 shadow-lg">
								<ApexChart selectedPicker={picker} />
							</div>
						)}
					</div>
					<div className="h-[55%]">
						<div className="h-full bg-white p-2 shadow-lg">
							<MapSection marker={showMarkers} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Dashboard
