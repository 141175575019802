import React from "react"
import EmployeeTraining from "./EmployeeTraining"
import EmployeeWorkHistory from "./EmployeeWorkHistory"
import EmployeeUsualWork from "./EmployeeUsualWork"
import EmployeeBans from "./EmployeeBans"
import { useAppSelector } from "hooks"
import { selectSelectedEmployee } from "store/globalState.slice"
import { Divider } from "antd"

const Location: React.FC = () => {
	const selected = useAppSelector(selectSelectedEmployee)

	return (
		<div>
			<EmployeeWorkHistory selected={selected} />
			<Divider />
			<EmployeeBans selected={selected} />
			<Divider />
			<div className="grid grid-cols-2 divide-x-0.5 py-5">
				<EmployeeTraining selected={selected} />
				<EmployeeUsualWork selected={selected} />
			</div>
		</div>
	)
}

export default Location
