import { Permissions } from "config/constants"
import { Icon, Button } from "components"
import React, { Dispatch, SetStateAction, useMemo, useState } from "react"
import { useAbac } from "react-abac"
import { Page, Document, pdfjs } from "react-pdf"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import { useHeightResize } from "hooks"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
const options = {
	cMapUrl: "cmaps/",
	standardFontDataUrl: "standard_fonts/",
}

interface Props {
	pdf: Blob
	setPdf: Dispatch<SetStateAction<Blob | null>>
	handleDownloadPdf: () => void
}

const PdfViewer: React.FC<Props> = ({ pdf, setPdf, handleDownloadPdf }) => {
	const [numPages, setNumPages] = useState<number>(1)
	const [loading, setLoading] = useState<boolean>(true)
	const { userHasPermissions } = useAbac()
	const arrayForPages = useMemo(() => {
		return Array.from({ length: numPages }, (_, index) => index + 1)
	}, [numPages])

	useHeightResize({ element: "pdf-container1" })

	return (
		<>
			<div className="w-full">
				<div className="sticky top-0 z-50 w-full bg-white  py-2">
					<div className="mx-auto flex max-w-[892px] justify-between">
						<div
							className="flex cursor-pointer items-center justify-center space-x-2 rounded-xl border border-dark p-2"
							onClick={() => setPdf(null)}
						>
							<Icon color="black" name={"leftArrow"} size={22} />
							<span className="text-sm">Go Back</span>
						</div>
						{userHasPermissions(Permissions.DOWNLOAD_REPORTS) && (
							<Button className=" bg-black px-4 py-2" onClick={handleDownloadPdf}>
								Download
								<Icon name="download" size={22} color="white" className="ml-2" />
							</Button>
						)}
					</div>
				</div>
				<div className="pdf-container" id="pdf-container1">
					<Document
						file={pdf}
						onContextMenu={e => e.preventDefault()}
						onLoadSuccess={({ numPages }) => {
							setNumPages(numPages)
							setLoading(false)
						}}
						options={options}
					>
						{loading ? (
							<Icon size={100} name="spinner" color="dark" className="animate-spin" />
						) : (
							arrayForPages.map((pageNumber: number, i: number) => (
								<Page
									key={i}
									scale={1.5}
									renderMode="canvas"
									pageNumber={pageNumber}
									renderTextLayer={false}
								/>
							))
						)}
					</Document>
				</div>
			</div>
		</>
	)
}

export default PdfViewer
