import { selectSelectedLocationStats } from "store/Location/locationDetails.slice"
import useAppSelector from "./useAppSelector"
import { StatsData } from "pages/dashboard/StatsSection"
import { useMemo } from "react"
import { orderBy, round } from "lodash"

const useLocationStats = () => {
	const locationStats = useAppSelector(selectSelectedLocationStats())

	const statsData: StatsData[] = [
		{ title: "Total Shifts", value: locationStats?.totalShifts || 0, color: "info" },
		{ title: "Total Hours", value: locationStats?.totalHours || 0, color: "success" },
		// { title: "Self Covered", value: locationStats?.selfCoveredShifts || 0, color: "success" },
		// { title: "Covered by Suppliers", value: locationStats?.otherCoveredShifts || 0, color: "warning" },
		{ title: "Critical Shifts", value: locationStats?.criticalShifts || 0, color: "danger-light" },
		{ title: "Late Shifts", value: locationStats?.lateShifts || 0, color: "warning" },
	]

	// const totalShiftsBreakdownByCount = useMemo(() => {
	// 	if (locationStats) {
	// 		const { selfCoveredShifts, otherCoveredShifts } = locationStats

	// 		const seriesData = [selfCoveredShifts, otherCoveredShifts]
	// 		const labels = [`Self Covered - ${selfCoveredShifts}`, `Others Covered - ${otherCoveredShifts}`]

	// 		return {
	// 			seriesData,
	// 			labels,
	// 		}
	// 	} else
	// 		return {
	// 			seriesData: [],
	// 			labels: [],
	// 		}
	// }, [])

	const totalShiftsBreakdownByHours = useMemo(() => {
		if (locationStats && !!Object.keys(locationStats)?.length) {
			const { selfCoveredHours, otherCoveredHours } = locationStats

			const seriesData = [selfCoveredHours, otherCoveredHours]
			const labels = [`Self Covered - ${selfCoveredHours}`, `Supp. Covered - ${otherCoveredHours}`]

			return {
				seriesData,
				labels,
			}
		} else
			return {
				seriesData: [],
				labels: [],
			}
	}, [])

	const totalShiftsBreakdownByCountInPercent = useMemo(() => {
		if (locationStats && !!Object.keys(locationStats)?.length) {
			const { selfCoveredShifts, otherCoveredShifts, totalShifts } = locationStats
			const seriesData = [selfCoveredShifts, otherCoveredShifts].map(item => round((item / totalShifts) * 100, 2))
			const labels = [`Self Covered - ${seriesData[0]}%`, `Supp. Covered - ${seriesData[1]}%`]

			if (selfCoveredShifts || otherCoveredShifts) {
				return {
					seriesData,
					labels,
				}
			}
		}
		return {
			seriesData: [],
			labels: [],
		}
	}, [])

	const totalSuppliersBreakdown = useMemo(() => {
		if (locationStats && !!Object.keys(locationStats)?.length) {
			const { otherCoveredShiftsBreakdown } = locationStats

			const seriesData = otherCoveredShiftsBreakdown?.map(item => item.count)
			const labels = otherCoveredShiftsBreakdown?.map(item => `${item.name} - ${item.count}`)

			return {
				seriesData,
				labels,
			}
		} else
			return {
				seriesData: [],
				labels: [],
			}
	}, [])

	const totalCustomersBreakdown = useMemo(() => {
		if (locationStats && !!Object.keys(locationStats)?.length) {
			const { shiftsCustomersBreakdown }: any = locationStats
			const shiftsCustomersBreakdownSorted = orderBy(shiftsCustomersBreakdown, ["hours"], ["desc"])
			const seriesData = shiftsCustomersBreakdownSorted?.map((item: any) => item.count, 2)
			const labels = shiftsCustomersBreakdownSorted?.map((item: any) => `${item.name} - ${round(item.count, 2)}`)
			return {
				seriesData,
				labels,
			}
		} else
			return {
				seriesData: [],
				labels: [],
			}
	}, [])

	const totalCustomersBreakdownByHours = useMemo(() => {
		if (locationStats && !!Object.keys(locationStats)?.length) {
			const { otherCustomerCoveredHours, totalHours }: any = locationStats
			const otherCustomerCoveredHoursSorted = orderBy(otherCustomerCoveredHours, ["hours"], ["desc"])
			const seriesData = otherCustomerCoveredHoursSorted?.map((item: any) => item.hours)
			const labels = otherCustomerCoveredHoursSorted?.map((item: any) => {
				const calculateHoursPercentage = (item.hours * 100) / totalHours
				return `${item.name} - ${round(calculateHoursPercentage, 2)} % -  ${round(item.hours, 2)}`
			})

			return {
				seriesData,
				labels,
			}
		} else
			return {
				seriesData: [],
				labels: [],
			}
	}, [])

	const totalSupplierBreakdownByHours = useMemo(() => {
		if (locationStats && !!Object.keys(locationStats)?.length) {
			const { supplierCoveredHoursBreakdown, totalHours }: any = locationStats
			const supplierCoveredHoursBreakdownSorted = orderBy(supplierCoveredHoursBreakdown, ["hours"], ["desc"])

			const seriesData = supplierCoveredHoursBreakdownSorted?.map((item: any) => item.hours)
			const labels = supplierCoveredHoursBreakdownSorted?.map((item: any) => {
				const calculateHoursPercentage = (item.hours * 100) / totalHours
				return `${item.name} - ${round(calculateHoursPercentage, 2)} % -  ${round(item.hours, 2)}`
			})

			return {
				seriesData,
				labels,
			}
		} else
			return {
				seriesData: [],
				labels: [],
			}
	}, [])

	const generalShiftsBreakdownByCount = useMemo(() => {
		if (locationStats && !!Object.keys(locationStats)?.length) {
			const { allocatedShifts, unAllocatedShifts, unCoveredShifts, totalShifts } = locationStats

			const seriesData = [allocatedShifts, unAllocatedShifts, unCoveredShifts]

			const labels = [
				`Allocated- ${((allocatedShifts * 100) / totalShifts).toFixed(1)} % - ${allocatedShifts}`,
				`UnAllocated- ${((unAllocatedShifts * 100) / totalShifts).toFixed(1)} % - ${unAllocatedShifts}`,
				`Uncovered- ${((unCoveredShifts * 100) / totalShifts).toFixed(1)} % - ${unCoveredShifts}`,
			]

			if (seriesData.length) {
				return {
					seriesData,
					labels,
				}
			}
		}
		return {
			seriesData: [],
			labels: [],
		}
	}, [])

	const shiftPlanDistribution = useMemo(() => {
		if (locationStats && !!Object.keys(locationStats)?.length) {
			const { plannedShifts, adhocShifts } = locationStats

			const seriesData = [plannedShifts, adhocShifts]

			const labels = [`Planned Shifts - ${plannedShifts}`, `Adhoc Shifts - ${adhocShifts}`]
			if (plannedShifts || adhocShifts) {
				return {
					seriesData,
					labels,
				}
			}
		}
		return {
			seriesData: [],
			labels: [],
		}
	}, [])

	const generalBookOnBreakdownByCount = useMemo(() => {
		if (locationStats && !!Object.keys(locationStats)?.length) {
			const { mobileBookOns, webBookOns, unattendedShifts } = locationStats

			const seriesData = [mobileBookOns, webBookOns, unattendedShifts]

			const labels = [
				`Mobile BookOns - ${mobileBookOns}`,
				`Web BookOns - ${webBookOns}`,
				`Unattended Shifts - ${unattendedShifts}`,
			]
			if (mobileBookOns || webBookOns || unattendedShifts) {
				return {
					seriesData,
					labels,
				}
			}
		}
		return {
			seriesData: [],
			labels: [],
		}
	}, [])
	return {
		statsData,
		shiftPlanDistribution,
		totalShiftsBreakdownByHours,
		totalShiftsBreakdownByCountInPercent,
		totalSuppliersBreakdown,
		totalCustomersBreakdown,
		totalCustomersBreakdownByHours,
		totalSupplierBreakdownByHours,
		locationStats,
		generalShiftsBreakdownByCount,
		generalBookOnBreakdownByCount,
	}
}

export default useLocationStats
