import { FC } from "react"

interface props {
	phone: number
	className?: string
}

const WhatsAppButton: FC<props> = ({ phone, className }) => {
	const link = `https://wa.me/${phone}`

	return (
		<a
			href={link}
			className={`fixed bottom-5 right-10 h-20 w-20 text-blue-500 ${className}`}
			target="_blank"
			rel="noopener noreferrer"
		>
			<img src="https://cdn.icon-icons.com/icons2/1826/PNG/512/4202050chatlogosocialsocialmediawhatsapp-115638_115663.png" />
		</a>
	)
}

export default WhatsAppButton
