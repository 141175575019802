import { sortDeepCopyByProperty, updateState } from "config/utils"
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import SDIncidentService from "services/staticData/SDIncidents.service"
import { UpdateSDIncidentsInterface, SDIncidentsInterface, GetSDIncidentsInterface } from "@type/staticData.types"
import { AppThunk, RootState } from "store"
export interface SDIncidentsTypeStateInterface {
	list: GetSDIncidentsInterface[]
	loading: boolean
	saving: boolean
}

const initialState: SDIncidentsTypeStateInterface = {
	list: [],
	loading: false,
	saving: false,
}

const SDIncidents = createSlice({
	name: "SDQualifications",
	initialState,
	reducers: {
		fetchingSDIncidents: SDIncidents => {
			SDIncidents.loading = true
		},
		SDIncidentsFetched: (SDIncidents, action: PayloadAction<GetSDIncidentsInterface[]>) => {
			SDIncidents.loading = false
			SDIncidents.list = action.payload
		},
		SDIncidentsFetchingFailed: SDIncidents => {
			SDIncidents.loading = false
		},
		savingSDIncidents: SDIncidents => {
			SDIncidents.saving = true
		},
		SDIncidentsSaved: (SDIncidents, action: PayloadAction<UpdateSDIncidentsInterface>) => {
			SDIncidents.saving = false
			const [UpdatedValue] = updateState(SDIncidents.list, action.payload, "_id")
			SDIncidents.list = UpdatedValue
		},
		SDIncidentsSavingFailed: SDIncidents => {
			SDIncidents.saving = false
		},
	},
})

//REDUCER
export default SDIncidents.reducer

//ACTIONS
const {
	fetchingSDIncidents,
	SDIncidentsFetched,
	SDIncidentsFetchingFailed,
	savingSDIncidents,
	SDIncidentsSaved,
	SDIncidentsSavingFailed,
} = SDIncidents.actions

const getSDIncidents =
	(cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingSDIncidents())
			const { data: SDIncidentsResponse } = await SDIncidentService.getAllSDIncident()
			cb && cb(SDIncidentsResponse[0]?._id)
			dispatch(SDIncidentsFetched(SDIncidentsResponse))
		} catch (error) {
			dispatch(SDIncidentsFetchingFailed())
		}
	}

const saveSDIncidents =
	(SDIncidentsData: Partial<UpdateSDIncidentsInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingSDIncidents())
			if (SDIncidentsData?._id)
				data = await SDIncidentService.updateSDIncident(SDIncidentsData._id, SDIncidentsData)
			else data = await SDIncidentService.createSDIncident(SDIncidentsData as SDIncidentsInterface)
			const { data: SDIncidentsResponse } = data
			cb && cb(SDIncidentsResponse._id)
			dispatch(SDIncidentsSaved(SDIncidentsResponse))
		} catch (error) {
			dispatch(SDIncidentsSavingFailed())
		}
	}

export { getSDIncidents, saveSDIncidents }

//SELECTORS
const selectSDIncidentsState = (state: RootState) => state.staticData.SDIncidents
const isSDIncidentsLoading = () => (state: RootState) => selectSDIncidentsState(state).loading
const isSDIncidentsSaving = () => (state: RootState) => selectSDIncidentsState(state).saving
const selectSDIncidentsList = createSelector(
	(state: RootState) => state.staticData.SDIncidents.list,
	list => {
		return sortDeepCopyByProperty(list, "name") as GetSDIncidentsInterface[]
	},
)

const selectSDIncidentsById = (SDIncidentsId: string) => (state: RootState) =>
	state.staticData.SDIncidents?.list?.find(SDIncidentsIds => SDIncidentsIds._id === SDIncidentsId)

const SDIncidentsOptions = createSelector(
	(state: RootState) => state.staticData.SDIncidents.list,
	list =>
		list.map(item => ({
			label: item.name,
			value: item._id,
		})),
)

export {
	selectSDIncidentsState,
	selectSDIncidentsList,
	selectSDIncidentsById,
	isSDIncidentsLoading,
	isSDIncidentsSaving,
	SDIncidentsOptions,
}
