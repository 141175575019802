import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit"
import { CreateLocationInventoryCallInterface, GetLocationCallTimeInterface } from "@type/locations.types"
import { locationInventoryCall } from "services"
import { AppThunk, RootState } from "store"

export interface LocationInventoryCallStateInterface {
	list: null | Array<GetLocationCallTimeInterface>
	loading: boolean
	saving: boolean
	deleting: boolean
}

const initialState: LocationInventoryCallStateInterface = {
	list: null,
	loading: false,
	saving: false,
	deleting: false,
}

const locationInventoryCallSlice = createSlice({
	name: "locationInventoryCall",
	initialState,
	reducers: {
		clearInventoryCallList: locationInventoryCall => {
			locationInventoryCall.list = null
		},
		fetchingLocationInventoryCall: locationInventoryCall => {
			locationInventoryCall.loading = true
		},
		deletingLocationInventoryCall: locationInventoryCall => {
			locationInventoryCall.deleting = true
		},
		locationInventoryCallFetched: (
			locationInventoryCall,
			action: PayloadAction<GetLocationCallTimeInterface[]>,
		) => {
			locationInventoryCall.loading = false
			locationInventoryCall.list = action.payload
		},
		locationInventoryCallFetchingFailed: locationInventoryCall => {
			locationInventoryCall.loading = false
		},
		savingLocationInventoryCall: locationInventoryCallData => {
			locationInventoryCallData.saving = true
		},
		locationInventoryCallDeleted: (locationInventoryCall, action: PayloadAction<string[]>) => {
			locationInventoryCall.deleting = false
			locationInventoryCall.list =
				locationInventoryCall.list?.filter(item => !action.payload.includes(item._id)) || []
		},
		locationInventoryCallSaved: (locationInventoryCall, action: PayloadAction<GetLocationCallTimeInterface[]>) => {
			locationInventoryCall.saving = false
			locationInventoryCall.list?.push(...action.payload)
		},
		locationInventoryCallSavingFailed: locationInventoryCall => {
			locationInventoryCall.saving = false
		},
		locationInventoryCallDeletingFailed: locationInventoryCall => {
			locationInventoryCall.deleting = false
		},
	},
})

//REDUCER
export default locationInventoryCallSlice.reducer

//ACTIONS
export const {
	clearInventoryCallList,
	fetchingLocationInventoryCall,
	locationInventoryCallFetched,
	locationInventoryCallFetchingFailed,
	savingLocationInventoryCall,
	locationInventoryCallSaved,
	locationInventoryCallSavingFailed,
	locationInventoryCallDeleted,
	deletingLocationInventoryCall,
	locationInventoryCallDeletingFailed,
} = locationInventoryCallSlice.actions

const getLocationInventoryCall =
	(postId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingLocationInventoryCall())
			const { data: locationInventoryCallResponse } = await locationInventoryCall.getPostInventoryCalls(postId)
			dispatch(locationInventoryCallFetched(locationInventoryCallResponse))
		} catch (error) {
			dispatch(locationInventoryCallFetchingFailed())
		}
	}

const removeInventoryCalls =
	(inventoryCallIds: string[], cb: () => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(deletingLocationInventoryCall())
			await locationInventoryCall.deleteInventoryCalls(inventoryCallIds)
			dispatch(locationInventoryCallDeleted(inventoryCallIds))
			cb()
		} catch (error) {
			dispatch(locationInventoryCallDeletingFailed())
		}
	}

const saveLocationInventoryCalls =
	(locationInventoryCallData: CreateLocationInventoryCallInterface, cb: () => void = () => null): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingLocationInventoryCall())
			const { data: locationInventoryCallResponse } = await locationInventoryCall.createInventoryCall(
				locationInventoryCallData,
			)
			dispatch(locationInventoryCallSaved(locationInventoryCallResponse))
			cb()
		} catch (error) {
			dispatch(locationInventoryCallSavingFailed())
		}
	}

export { getLocationInventoryCall, saveLocationInventoryCalls, removeInventoryCalls }

//SELECTORS
const selectLocationInventoryCallState = (state: RootState) => state.location.inventoryCall
const selectLocationInventoryCallList = (state: RootState) => selectLocationInventoryCallState(state).list
const isLoadingLocationInventoryCall = (state: RootState) => selectLocationInventoryCallState(state).loading
const isSavingLocationInventoryCall = (state: RootState) => selectLocationInventoryCallState(state).saving
const isDeletingLocationInventoryCall = (state: RootState) => selectLocationInventoryCallState(state).deleting
const selectLocationInventoryCallListWithLabel = (state: RootState) =>
	selectLocationInventoryCallState(state).list?.map(item => ({ ...item, label: "inv" }))

const selectLocationInventoryCallColumnLabels = createSelector(
	(state: RootState) => state.location.inventoryCall.list,
	list =>
		[...new Set(list?.map(item => item.time))].sort(
			(a, b) => new Date("1970/01/01 " + a).valueOf() - new Date("1970/01/01 " + b).valueOf(),
		),
)

export {
	selectLocationInventoryCallList,
	selectLocationInventoryCallListWithLabel,
	selectLocationInventoryCallState,
	isSavingLocationInventoryCall,
	isLoadingLocationInventoryCall,
	isDeletingLocationInventoryCall,
	selectLocationInventoryCallColumnLabels,
}
