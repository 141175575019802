import { DuplicatePayrollInterface, PayrollDataInterface, PayrollListInterface } from "@type/account.types"
import axios from "axios"
const ROOT_PATH = "uniform-payroll"

export default class PayrollService {
	static getPayrolls = () => axios.get<PayrollListInterface[]>(ROOT_PATH)

	static createPayrolls = (payload: PayrollDataInterface[]) => axios.post<PayrollDataInterface[]>(ROOT_PATH, payload)
	static updatePayrolls = (payload: PayrollDataInterface[]) => axios.put<PayrollDataInterface[]>(ROOT_PATH, payload)
	static duplicatePayroll = (payload: DuplicatePayrollInterface) =>
		axios.post<PayrollListInterface>(`${ROOT_PATH}/duplicate`, payload)
}
