import useAppSelector from "hooks/useAppSelector"
import { selectEmployeeByEmployeeId } from "store/Employee/detail.slice"
import Modal from "components/Modal"
import { useContext } from "react"
import EmployeeRetainContext, { EmployeeRetainContextProps } from "./CreateScheduleRetainContext"
import { caseConverters } from "config/utils"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
}

const keysToOmit = [
	"_id",
	"position",
	"Culture",
	"createdAt",
	"updatedAt",
	"user",
	"county",
	"branch",
	"supplier",
	"__v",
]

const ShowEmployeeDetailModal: React.FC<Props> = ({ open = false, modalHandler = () => undefined }) => {
	const { createScheduleModalData } = useContext(EmployeeRetainContext) as EmployeeRetainContextProps
	const selectedEmployeeDetails =
		useAppSelector(selectEmployeeByEmployeeId(createScheduleModalData?.employee || "")) || null

	return (
		<Modal
			open={open}
			title={<div className="font-semi p-2 text-center text-lg font-semibold">Employee Details</div>}
			modalHandler={modalHandler}
			goBack={true}
		>
			<div className="grid grid-cols-2">
				{selectedEmployeeDetails !== null ? (
					Object.entries(selectedEmployeeDetails).map(([item, value], index) => {
						if (!keysToOmit.includes(item)) {
							return (
								<div className="flex space-x-2 p-2 text-md" key={`${index}-${item}`}>
									<span className="font-medium">{caseConverters.titleCase(item)}:</span>
									<span>{value as string}</span>
								</div>
							)
						}
					})
				) : (
					<div></div>
				)}
			</div>
		</Modal>
	)
}

export default ShowEmployeeDetailModal
