import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetUserInterface, UserInterface, UpdateUserInterface } from "@type/security.types"
import { usersService } from "services"
import { AppThunk, RootState } from "store"
import { sortByProperty, sortDeepCopyByProperty, updateState } from "config/utils"
import { reLogin } from "store/auth.slice"

export interface UsersStateInterface {
	list: null | Array<GetUserInterface>
	loading: boolean
	saving: boolean
	savingPassword: boolean
}

const initialState: UsersStateInterface = {
	list: null,
	loading: false,
	saving: false,
	savingPassword: false,
}

const usersSlice = createSlice({
	name: "users",
	initialState,
	reducers: {
		fetchingUsers: user => {
			user.loading = true
		},
		usersFetched: (user, action: PayloadAction<GetUserInterface[]>) => {
			user.loading = false
			user.list = action.payload
		},
		usersFetchingFailed: user => {
			user.loading = false
		},
		savingUser: userData => {
			userData.saving = true
		},
		userSaved: (user, action: PayloadAction<GetUserInterface>) => {
			user.saving = false
			user.savingPassword = false
			const [UpdatedValue] = updateState(user.list, action.payload, "_id")
			user.list = UpdatedValue
		},
		userSavingFailed: user => {
			user.saving = false
		},
		savingUserPassword: user => {
			user.savingPassword = true
		},
		clearUsersState: user => {
			user.list = null
			user.loading = false
			user.saving = false
			user.savingPassword = false
		},
		userPasswordSavingFailed: user => {
			user.savingPassword = false
		},
	},
})

//REDUCER
export default usersSlice.reducer

//ACTIONS
export const {
	clearUsersState,
	fetchingUsers,
	usersFetched,
	usersFetchingFailed,
	savingUser,
	userSaved,
	userSavingFailed,
	savingUserPassword,
	userPasswordSavingFailed,
} = usersSlice.actions

const getUsers =
	(platform: string, cb?: (user: GetUserInterface) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingUsers())
			const { data: userResponse } = await usersService.getAllUsersByOrganization(platform)
			userResponse.sort(sortByProperty("firstName"))
			cb && cb(userResponse[0])
			dispatch(usersFetched(userResponse))
		} catch (error) {
			dispatch(usersFetchingFailed())
		}
	}

const saveUser =
	(userData: Partial<UpdateUserInterface>, cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingUser())
			if (userData?._id) data = await usersService.updateUser(userData._id, userData)
			else data = await usersService.createUser(userData as UserInterface)
			const { data: userResponse } = data
			cb && cb(userResponse._id)
			dispatch(userSaved(userResponse))
		} catch (error) {
			dispatch(userSavingFailed())
		}
	}

const updatePassword =
	(id: string, userData: { currentPassword?: string; newPassword: string }, getProfile?: boolean): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingUserPassword())
			data = await usersService.updatePassword(id, userData)
			const { data: userResponse } = data
			if (userResponse && getProfile) dispatch(reLogin())
			dispatch(userSaved(userResponse))
		} catch (error) {
			dispatch(userPasswordSavingFailed())
		}
	}

export { getUsers, saveUser, updatePassword }

//SELECTORS
const selectUserState = (state: RootState) => state.security.users
const isUserLoading = () => (state: RootState) => selectUserState(state).loading
const isUserSaving = () => (state: RootState) => selectUserState(state).saving
const isUserPasswordSaving = () => (state: RootState) => selectUserState(state).savingPassword
const selectUserList = createSelector(
	(state: RootState) => state.security.users.list,
	list => {
		return sortDeepCopyByProperty(list, "firstName") as GetUserInterface[]
	},
)

const selectRoleFilteredUserList = (role: string) =>
	!role
		? selectUserList
		: createSelector(
				(state: RootState) => state.security.users.list,
				list => {
					return sortDeepCopyByProperty(
						list?.filter(user => user.oldRoles.includes(role)) || [],
						"firstName",
					) as GetUserInterface[]
				},
		  )

const selectUserById = (userId: string | null) => (state: RootState) => {
	return state.security.users?.list?.find(user => user._id === userId)
}

const filterUsersByPlatformOrRole = (platformType: string, role?: string) =>
	createSelector(
		(state: RootState) => state?.security?.users?.list,
		list => {
			return list?.filter(user => {
				if (!role) return user?.platform === platformType
				return user?.platform === platformType && user.oldRoles.includes(role)
			})
		},
	)

export {
	selectUserList,
	selectRoleFilteredUserList,
	selectUserState,
	selectUserById,
	isUserLoading,
	isUserSaving,
	filterUsersByPlatformOrRole,
	isUserPasswordSaving,
}
