import { VALIDATION_REGEX } from "config/constants"
import * as Yup from "yup"

const globalSearchInitialValues = {
	shortName: "",
	clientPin: "",
	reference: "",
	sin: "",
	name: "",
}
const globalSearchValidationSchema = Yup.object().shape(
	{
		shortName: Yup.lazy(value => {
			if (!value) return Yup.string().notRequired()
			return Yup.string().min(3, "Short Name must be at least 3 characters").label("Short Name")
		}),
		clientPin: Yup.lazy(value => {
			if (!value) return Yup.string().notRequired().typeError("Client PIN must be a number")
			return Yup.number().positive().integer().label("Client PIN").typeError("Client PIN must be a number")
		}),
		pin: Yup.lazy(value => {
			if (!value) return Yup.string().notRequired().typeError("PIN must be a number")
			return Yup.number().positive().integer().label("PIN").typeError("PIN must be a number")
		}),
		reference: Yup.lazy(value => {
			if (!value) return Yup.string().notRequired()
			return Yup.string().matches(VALIDATION_REGEX.sia, "Invalid SIA Number")
		}),
		sin: Yup.lazy(value => {
			if (!value) return Yup.string().notRequired().typeError("SIN must be a number")
			return Yup.number().positive().integer().label("SIN").typeError("SIN must be a number")
		}),
		name: Yup.lazy(value => {
			if (!value) return Yup.string().notRequired()
			return Yup.string().min(3, "Name must be at least 3 characters").label("Name")
		}),
	},
	[
		["shortName", "shortName"],
		["clientPin", "clientPin"],
		["pin", "pin"],
		["reference", "reference"],
		["sin", "sin"],
		["name", "name"],
	],
)

export { globalSearchInitialValues, globalSearchValidationSchema }
