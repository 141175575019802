import axios from "axios"
import { CreateEmployeeBankDetailInterface, GetEmployeeBankDetailInterface } from "@type/employees.types"

const ROOT_PATH = "employees/bank-details"
export default class EmployeeBankDetails {
	static createEmployeeBankDetail = (Data: CreateEmployeeBankDetailInterface) =>
		axios.post<GetEmployeeBankDetailInterface>(`${ROOT_PATH}`, Data)

	static updateEmployeeBankDetailBy = (id: string, bankData: GetEmployeeBankDetailInterface) =>
		axios.put<GetEmployeeBankDetailInterface>(`${ROOT_PATH}/${id}`, bankData)

	static getEmployeeBankDetailByEmployeeId = (employeeId: string) =>
		axios.get<GetEmployeeBankDetailInterface[]>(`${ROOT_PATH}/${employeeId}`)
}
