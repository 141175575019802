import { updateState } from "config/utils"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EmployeeBansFormInterface, EmployeeBansInterface } from "@type/employees.types"
import { employeeBanService } from "services"
import { AppThunk, RootState } from "store"

export interface EmployeeBanStateInterface {
	data: EmployeeBansInterface[]
	loading?: boolean
	saving: boolean
}

const initialState: EmployeeBanStateInterface = {
	data: [],
	loading: false,
	saving: false,
}

const employeeBanSlice = createSlice({
	name: "employeeBan",
	initialState,
	reducers: {
		requestEmployeeBanData: employeeBan => {
			employeeBan.loading = true
		},
		receiveEmployeeBanData: (employeeBan, action: PayloadAction<EmployeeBansInterface[]>) => {
			employeeBan.loading = false
			employeeBan.data = action.payload
		},
		receiveEmployeeBanDataFailed: employeeBan => {
			employeeBan.loading = false
		},
		requestSavingEmployeeBanData: employeeBanData => {
			employeeBanData.saving = true
		},
		savingEmployeeBanSuccess: (employeeBan, action: PayloadAction<EmployeeBansFormInterface[]>) => {
			employeeBan.saving = false
			const [UpdatedValue] = updateState(employeeBan.data, action.payload, "_id")

			employeeBan.data = UpdatedValue
		},
		savingEmployeeBanSuccessFail: employeeBan => {
			employeeBan.saving = false
		},
	},
})

//REDUCER
export default employeeBanSlice.reducer

//ACTIONS
const {
	requestEmployeeBanData,
	receiveEmployeeBanData,
	receiveEmployeeBanDataFailed,
	requestSavingEmployeeBanData,
	savingEmployeeBanSuccess,
	savingEmployeeBanSuccessFail,
} = employeeBanSlice.actions

const getEmployeesBanByEmployeeId =
	(employeeId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestEmployeeBanData())
			const { data: BanResponse } = await employeeBanService.findEmployeeBanByEmployeeID(employeeId)

			dispatch(receiveEmployeeBanData(BanResponse))
		} catch (error) {
			dispatch(receiveEmployeeBanDataFailed())
		}
	}

const saveEmployeeBanData =
	(employeeBanData: EmployeeBansFormInterface, cb?: () => void): AppThunk =>
	async dispatch => {
		try {
			let data = null
			dispatch(requestSavingEmployeeBanData())
			if (employeeBanData._id) {
				data = await employeeBanService.updateEmployeeBan(employeeBanData._id, employeeBanData)
			} else {
				data = await employeeBanService.createEmployeeBan(employeeBanData)
			}
			const { data: banResponse } = data
			dispatch(savingEmployeeBanSuccess(banResponse))
			cb && cb()
		} catch (error) {
			dispatch(savingEmployeeBanSuccessFail())
		}
	}

export { saveEmployeeBanData, getEmployeesBanByEmployeeId }

//SELECTORS
const selectEmployeeBanState = (state: RootState) => state.employee.ban
const isEmployeeBanLoading = () => (state: RootState) => selectEmployeeBanState(state).loading
const selectEmployeeBanData = () => (state: RootState) => selectEmployeeBanState(state).data || []
const isEmployeeBanSaving = () => (state: RootState) => selectEmployeeBanState(state).saving
const selectEmployeeBanById = (trainingId: string | null) => (state: RootState) =>
	selectEmployeeBanData()(state)?.find(training => training._id === trainingId)

export { isEmployeeBanLoading, selectEmployeeBanData, isEmployeeBanSaving, selectEmployeeBanById }
