import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk, RootState } from "store"

import {
	GetSchedulesInterface,
	CreateSchedulesInterface,
	AllocateEmployeeToScheduleInterface,
	UpdateSchedulesInterface,
	ScheduleFilterInterface,
	DeleteEmployeeToScheduleInterface,
} from "@type/schedules.types"
import LocationSchedules, { getSchedulesParameters, getScheduleByEmployee } from "services/schedules/schedules.service"

import {
	convertIOSDateToHTMLFormat,
	deleteState,
	getAllDatesOfMonth,
	getCurrentWeekDateFromPreviousDate,
	getDatesOfWeekFromSingleDate,
	getMomentZInstanceOfDate,
	isDateSameOrBetween,
	updateState,
} from "config/utils"
import { DATE_FORMATS } from "config/constants"
import { GetLocationContractsInterface, GetPostsInterface, InActiveSchedule } from "@type/locations.types"
import moment from "moment"
import { ScheduleStatus } from "pages/schedules/Constants/schedulesConstants"
import { isObject } from "lodash"
import { momentZ } from "config/moment"

export interface scheduleInterfaceProps {
	list: GetSchedulesInterface[]
	loading: boolean
	saving: boolean
	allocating: boolean
	delete: boolean
	filterState: string
	scheduleInfo: getSchedulesParameters | null
	inActiveSchedulesListByEmployee: GetPostsInterface[]
}

const initialState: scheduleInterfaceProps = {
	list: [],
	loading: false,
	saving: false,
	allocating: false,
	delete: false,
	filterState: "",
	scheduleInfo: null,
	inActiveSchedulesListByEmployee: [],
}

const scheduleSlice = createSlice({
	name: "schedule",
	initialState,
	reducers: {
		fetchingSchedule: schedule => {
			schedule.loading = true
		},
		scheduleFetched: (schedule, action: PayloadAction<GetSchedulesInterface[]>) => {
			schedule.loading = false
			schedule.list = action.payload
		},
		scheduleFetchingFailed: schedule => {
			schedule.loading = false
		},
		savingSchedule: scheduleData => {
			scheduleData.saving = true
		},
		scheduleSaved: (schedule, action: PayloadAction<GetSchedulesInterface | GetSchedulesInterface[]>) => {
			if (schedule.scheduleInfo) {
				schedule.saving = false
				const {
					employee: selectedEmployee,
					location: selectedLocation,
					startTime: fetchedScheduleStartWeekDate,
					finishTime: fetchedScheduleEndWeekDate,
				} = schedule.scheduleInfo

				let doesStateNeedUpdate = false
				if (Array.isArray(action.payload)) {
					const schedules = action.payload
					doesStateNeedUpdate = schedules.every(item => {
						const scheduleLocation = isObject(item.location) ? item.location._id : item.location
						const shouldPass = selectedEmployee
							? item.employee === selectedEmployee
							: scheduleLocation === selectedLocation

						return (
							momentZ(item.startTime).isSameOrAfter(fetchedScheduleStartWeekDate) &&
							momentZ(item.startTime).isSameOrBefore(fetchedScheduleEndWeekDate) &&
							shouldPass
						)
					})
				} else {
					const { startTime, location, employee } = action.payload
					const shouldPass = employee ? employee === selectedEmployee : location === selectedLocation

					doesStateNeedUpdate =
						momentZ(startTime).isSameOrAfter(fetchedScheduleStartWeekDate) &&
						momentZ(startTime).isSameOrBefore(fetchedScheduleEndWeekDate) &&
						shouldPass
				}

				if (doesStateNeedUpdate) {
					const [UpdatedValue] = updateState(schedule.list, action.payload, "_id")
					schedule.list = [...UpdatedValue]
				}
			}
		},
		scheduleSavingFailed: schedule => {
			schedule.saving = false
		},
		scheduleDoneSaving: schedule => {
			schedule.saving = false
		},
		allocatingEmployee: schedule => {
			schedule.allocating = true
		},
		allocatingEmployeeComplete: (schedule, action: PayloadAction<GetSchedulesInterface>) => {
			schedule.allocating = false
			schedule.saving = false

			if (schedule.list?.length) {
				const [UpdatedValue, NewAdded] = updateState(schedule.list, action.payload, "_id")
				if (NewAdded) {
					schedule.list = [...UpdatedValue]
				}
			}
		},
		allocatingEmployeeFailed: schedule => {
			schedule.allocating = false
		},
		scheduleUpdated: (schedule, action: PayloadAction<GetSchedulesInterface[]>) => {
			schedule.saving = false
			const [UpdatedValue] = updateState(schedule.list, action.payload, "_id")
			schedule.list = [...UpdatedValue]
		},
		deleteSchedule: schedule => {
			schedule.delete = true
		},
		deleteScheduleComplete: (schedule, action: PayloadAction<GetSchedulesInterface[]>) => {
			schedule.delete = false
			if (schedule.list) {
				let deletedState: GetSchedulesInterface[] = []
				action.payload.forEach((deletedSchedule: GetSchedulesInterface) => {
					const [UpdatedValue] = updateState(schedule.list, deletedSchedule, "_id")
					deletedState = [...UpdatedValue]
				})
				schedule.list = deletedState
			} else return
		},
		deleteEmployeeScheduleComplete: (schedule, action: PayloadAction<GetSchedulesInterface[]>) => {
			schedule.delete = false

			let deletedState: GetSchedulesInterface[] = []
			action.payload.forEach((deletedSchedule: GetSchedulesInterface) => {
				const [UpdatedValue] = deleteState(schedule.list, deletedSchedule, "_id")
				deletedState = [...UpdatedValue]
			})
			schedule.list = deletedState
		},
		deleteScheduleFailed: schedule => {
			schedule.delete = false
		},
		clearSchedule: schedule => {
			schedule.list = []
			schedule.loading = false
			schedule.saving = false
			schedule.allocating = false
			schedule.delete = false
			schedule.filterState = ""
		},
		setFilterState: (schedule, action: PayloadAction<string>) => {
			schedule.filterState = action.payload
		},
		setScheduleDetail: (schedule, action: PayloadAction<getSchedulesParameters>) => {
			const { startTime, finishTime, location = "", employee = "" } = action.payload
			schedule.scheduleInfo = {
				startTime,
				finishTime,
				location,
				employee,
			}
		},
		fetchInactiveSchedulesByEmployee: (state, action: PayloadAction<GetPostsInterface[]>) => {
			const [updatedState] = updateState(state.inActiveSchedulesListByEmployee, action.payload, "_id")
			state.inActiveSchedulesListByEmployee = [...updatedState]
		},
		inactiveScheduleByEmployeeSaved: (state, action: PayloadAction<GetPostsInterface[]>) => {
			state.saving = false
			const [updatedState] = updateState(state.inActiveSchedulesListByEmployee, action.payload, "_id")
			state.list = [...updatedState]
		},
		clearInactiveScheduleByEmployee: state => {
			state.inActiveSchedulesListByEmployee = []
		},
	},
})

//REDUCER
export default scheduleSlice.reducer

//ACTIONS
export const {
	fetchingSchedule,
	scheduleFetched,
	scheduleFetchingFailed,
	savingSchedule,
	scheduleSaved,
	scheduleDoneSaving,
	scheduleSavingFailed,
	scheduleUpdated,
	allocatingEmployee,
	allocatingEmployeeComplete,
	allocatingEmployeeFailed,
	deleteSchedule,
	deleteScheduleComplete,
	deleteEmployeeScheduleComplete,
	deleteScheduleFailed,
	clearSchedule,
	setFilterState,
	setScheduleDetail,
	fetchInactiveSchedulesByEmployee,
	inactiveScheduleByEmployeeSaved,
	clearInactiveScheduleByEmployee,
} = scheduleSlice.actions

const getSchedule =
	(data: getSchedulesParameters): AppThunk =>
	async dispatch => {
		try {
			dispatch(clearSchedule())
			dispatch(fetchingSchedule())
			dispatch(setScheduleDetail({ ...data, startTime: moment(data.startTime).subtract(1, "hour").format() }))
			const { data: scheduleResponse } = await LocationSchedules.getScheduleByPostAndDateRange(data)

			dispatch(scheduleFetched(scheduleResponse))
		} catch (error) {
			dispatch(scheduleFetchingFailed())
		}
	}

const getScheduleByEmployeeAndDateRange =
	(data: getScheduleByEmployee): AppThunk =>
	async dispatch => {
		try {
			dispatch(clearSchedule())
			dispatch(fetchingSchedule())
			dispatch(setScheduleDetail({ ...data, startTime: moment(data.startTime).subtract(1, "hour").format() }))
			const { data: scheduleResponse } = await LocationSchedules.getScheduleByEmployeeAndDateRange(data)
			const { data: inActiveScheduleByEmployee } = await LocationSchedules.getInactiveSchedules({
				employee: data.employee,
				startDate: moment(data.startTime).startOf("month").toISOString(),
			})
			dispatch(scheduleFetched(scheduleResponse))
			dispatch(fetchInactiveSchedulesByEmployee(inActiveScheduleByEmployee))
		} catch (error) {
			dispatch(scheduleFetchingFailed())
		}
	}

const getScheduleByFilter =
	(data: ScheduleFilterInterface): AppThunk =>
	async dispatch => {
		const { state } = data
		try {
			dispatch(clearSchedule())
			dispatch(fetchingSchedule())
			dispatch(setFilterState(state))
			const { data: scheduleResponse } = await LocationSchedules.scheduleByFilter(data)
			dispatch(scheduleFetched(scheduleResponse))
		} catch (error) {
			dispatch(scheduleFetchingFailed())
		}
	}
const saveInactiveSchedule =
	(scheduleData: Partial<CreateSchedulesInterface>[], cb: any): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingSchedule())
			await LocationSchedules.createInactiveSchedulesByLocation(scheduleData as CreateSchedulesInterface[])
			dispatch(scheduleDoneSaving())
			cb()
		} catch (error) {
			dispatch(scheduleSavingFailed())
		}
	}
const saveSchedule =
	(scheduleData: Partial<CreateSchedulesInterface> | UpdateSchedulesInterface, cb: any): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingSchedule())
			if ((scheduleData as UpdateSchedulesInterface)?.ids?.length) {
				await LocationSchedules.updateSchedulesByLocation(scheduleData as UpdateSchedulesInterface)
			} else {
				await LocationSchedules.createSchedulesByLocation(scheduleData as CreateSchedulesInterface)
			}
			dispatch(scheduleDoneSaving())
			cb()
		} catch (error) {
			dispatch(scheduleSavingFailed())
		}
	}

const acceptSchedule =
	(scheduleIds: string[], cb?: () => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingSchedule())
			if (scheduleIds.length) {
				const { data: updatedSchedule } = await LocationSchedules.updateScheduleStatus(scheduleIds)
				dispatch(scheduleUpdated(updatedSchedule as GetSchedulesInterface[]))
			}
			cb && cb()
		} catch (error) {
			dispatch(scheduleSavingFailed())
		}
	}

const saveScheduleByEmployee =
	(scheduleData: Partial<CreateSchedulesInterface>, cb: () => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingSchedule())
			if ((scheduleData as UpdateSchedulesInterface).ids) {
				await LocationSchedules.updateSchedulesByLocation(scheduleData as UpdateSchedulesInterface)
			} else {
				await LocationSchedules.createSchedulesByEmployee(scheduleData as CreateSchedulesInterface)
			}
			dispatch(scheduleDoneSaving())

			cb()
		} catch (error) {
			dispatch(scheduleSavingFailed())
		}
	}

const allocatingSchedule =
	(scheduleData: Partial<AllocateEmployeeToScheduleInterface>, cb: () => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(allocatingEmployee())
			data = await LocationSchedules.AllocateEmployeeToScheduleInterface(
				scheduleData as AllocateEmployeeToScheduleInterface,
			)
			const { data: scheduleResponse } = data
			dispatch(allocatingEmployeeComplete(scheduleResponse.data))
			cb()
		} catch (error) {
			dispatch(allocatingEmployeeFailed())
		}
	}

const deAllocateEmployee =
	(scheduleId: { ids: string[] }): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(deleteSchedule())
			data = await LocationSchedules.deAllocateEmployees(scheduleId)
			const { data: scheduleResponse } = data
			dispatch(deleteEmployeeScheduleComplete(scheduleResponse.data))
		} catch (error) {
			dispatch(deleteScheduleFailed())
		}
	}

const deletingSchedule =
	(scheduleDetails: DeleteEmployeeToScheduleInterface, cb?: () => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(deleteSchedule())
			data = await LocationSchedules.deleteSchedule(scheduleDetails)
			const { data: scheduleResponse } = data
			dispatch(deleteScheduleComplete(scheduleResponse.data))
			cb && cb()
		} catch (error) {
			dispatch(deleteScheduleFailed())
		}
	}

export {
	getSchedule,
	deAllocateEmployee,
	getScheduleByEmployeeAndDateRange,
	saveSchedule,
	acceptSchedule,
	saveScheduleByEmployee,
	allocatingSchedule,
	deletingSchedule,
	getScheduleByFilter,
	saveInactiveSchedule,
}

//SELECTORS
const selectScheduleState = (state: RootState) => state.schedules
const selectScheduleLoading = () => (state: RootState) => selectScheduleState(state).loading
const selectScheduleList = () => (state: RootState) => selectScheduleState(state).list || []
const selectScheduleSaving = () => (state: RootState) => selectScheduleState(state).saving
const selectScheduleDelete = () => (state: RootState) => selectScheduleState(state).delete
const selectScheduleFilterState = () => (state: RootState) => selectScheduleState(state).filterState

const selectScheduleEmployeeAllocation = () => (state: RootState) => selectScheduleState(state).allocating

const selectScheduleListByIsDeleted = (showDeleted: boolean) =>
	createSelector(selectScheduleList(), scheduleList =>
		scheduleList.filter(schedule => schedule.isDeleted == showDeleted),
	)

const selectScheduleListByEmployee = (selectedDate: string, showDeleted = false) =>
	createSelector(
		(state: RootState) => ({
			scheduleList: state.schedules.list,
			inActiveSchedulesPosts: state.schedules.inActiveSchedulesListByEmployee,
			selectedEmployee: state.globalState.selectedEmployee,
			locationContractList: state.location.contracts.list || [],
		}),
		({ scheduleList, inActiveSchedulesPosts, selectedEmployee, locationContractList }) => {
			const allDatesInCurrentMonth = getAllDatesOfMonth(moment(selectedDate, DATE_FORMATS.DD_MM_YYYY))
			const inActiveSchedules: any[] = []
			const uniqueInActiveSchedules: any[] = []
			let contract: GetLocationContractsInterface
			inActiveSchedulesPosts.forEach(post => {
				const { contract: contractFromPost } = post

				if (typeof contractFromPost !== "string") {
					contract = contractFromPost
				} else {
					const contractFoundGlobalState = locationContractList.find(
						contract => contractFromPost === contract._id,
					)

					if (contractFoundGlobalState) contract = contractFoundGlobalState
				}

				post.inActiveSchedules
					?.filter(({ employee }) => selectedEmployee === employee?._id)
					?.forEach(inActiveSchedule => {
						const {
							from,
							until,
							startTime,
							finishTime,
							weeksInterval = 1,
							isFourOnFourOff = false,
						} = inActiveSchedule

						allDatesInCurrentMonth.forEach(currentDate => {
							if (
								moment(from).isSameOrBefore(currentDate) &&
								(moment(until).isSameOrAfter(currentDate) || until === null)
							) {
								const startTimeMoment = moment(startTime)
								const startTime_HH_MM_SS = startTimeMoment.format(DATE_FORMATS.HH_mm_ss)

								const finishTimeMoment = moment(finishTime)
								const finishTime_HH_MM_SS = finishTimeMoment.format(DATE_FORMATS.HH_mm_ss)

								const currentDate_YY_MM_DD = moment(currentDate).format(DATE_FORMATS.YYYY_MM_DD)

								const scheduleStartTime = `${currentDate_YY_MM_DD}T${startTime_HH_MM_SS}`

								const formattedScheduleStartTime = moment(
									scheduleStartTime,
									DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS,
								).toISOString()

								let formattedScheduleFinishTime = ""

								if (startTimeMoment.hour() >= finishTimeMoment.hour()) {
									const scheduleFinishTime = `${currentDate_YY_MM_DD}T${finishTime_HH_MM_SS}`
									formattedScheduleFinishTime = moment(
										scheduleFinishTime,
										DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS,
									)
										.add(1, "day")
										.toISOString()
								} else {
									formattedScheduleFinishTime = moment(
										`${currentDate_YY_MM_DD}T${finishTime_HH_MM_SS}`,
										DATE_FORMATS.DD_MM_YYYY_HH_MM_SS,
									).toISOString()
								}

								if (isFourOnFourOff) {
									const differenceInDays = moment(currentDate).diff(from, "days")

									if (differenceInDays >= 0) {
										const isRecurringScheduleDay = (differenceInDays % (2 * 4)) + 1 <= 4

										if (
											isRecurringScheduleDay &&
											(!contract?.finishDate ||
												moment(contract?.finishDate)
													.startOf("D")
													.isSameOrAfter(
														moment(currentDate, DATE_FORMATS.DD_MM_YYYY).startOf("D"),
													))
										) {
											inActiveSchedules.push({
												_id: inActiveSchedule._id,
												post: {
													_id: post._id,
													name: post.name,
												},
												location: inActiveSchedule.location,
												employee: inActiveSchedule?.employee?._id,
												date: formattedScheduleStartTime,
												startTime: formattedScheduleStartTime,
												finishTime: formattedScheduleFinishTime,
												type: inActiveSchedule.type,
												status: ScheduleStatus.INACTIVE,
												from,
												until,
												weeksInterval,
												isFourOnFourOff: false,
											})
										}
									}
								} else {
									const differenceInWeeks = moment(currentDate).diff(from, "weeks")

									if (
										moment(currentDate).isSame(from, "date") ||
										(differenceInWeeks >= weeksInterval &&
											differenceInWeeks % weeksInterval === 0 &&
											moment(currentDate).format(DATE_FORMATS.dddd) ===
												moment(from).format(DATE_FORMATS.dddd))
									) {
										inActiveSchedules.push({
											_id: inActiveSchedule._id,
											post: post,
											location: inActiveSchedule.location,
											employee: inActiveSchedule?.employee?._id,
											date: formattedScheduleStartTime,
											startTime: formattedScheduleStartTime,
											finishTime: formattedScheduleFinishTime,
											type: inActiveSchedule.type,
											status: ScheduleStatus.INACTIVE,
											from,
											until,
											weeksInterval,
											isFourOnFourOff: false,
										})
									}
								}
							}
						})
					})
			})

			const activeSchedules = scheduleList
				.filter(sch => showDeleted || !sch.isDeleted)
				.filter(sch => !!sch.employee && sch.employee === selectedEmployee)

			inActiveSchedules.forEach(inActiveSchedule => {
				if (
					!inActiveSchedule.isFourOnFourOff &&
					!activeSchedules.find(schedule => schedule.createdFromRecur === inActiveSchedule._id)
				) {
					uniqueInActiveSchedules.push(inActiveSchedule)
				} else {
					const isScheduleActivated = activeSchedules.some(schedule => {
						const activeScheduleStartTime = moment(schedule.startTime).format(DATE_FORMATS.HH_mm_ss)
						const activeScheduleFinishTime = moment(schedule.finishTime).format(DATE_FORMATS.HH_mm_ss)
						const activeScheduleDate = moment(schedule.startTime).format(DATE_FORMATS.DD_MM_YYYY)

						const inactiveScheduleStartTime = moment(inActiveSchedule.startTime).format(
							DATE_FORMATS.HH_mm_ss,
						)
						const inactiveScheduleFinishTime = moment(inActiveSchedule.finishTime).format(
							DATE_FORMATS.HH_mm_ss,
						)
						const inactiveScheduleDate = moment(inActiveSchedule.startTime).format(DATE_FORMATS.DD_MM_YYYY)
						return (
							schedule.createdFromRecur === inActiveSchedule._id &&
							activeScheduleStartTime === inactiveScheduleStartTime &&
							activeScheduleFinishTime === inactiveScheduleFinishTime &&
							activeScheduleDate === inactiveScheduleDate
						)
					})
					if (!isScheduleActivated) uniqueInActiveSchedules.push(inActiveSchedule)
				}
			})

			return [...activeSchedules, ...uniqueInActiveSchedules]
		},
	)

const selectScheduleListByLocationForTable = (showDeleted: boolean, selectedDate: string, contract?: any) =>
	createSelector(
		(state: RootState) => ({
			scheduleList: state.schedules.list || [],
			employeeList: state.employee.details.list || [],
			selectedContractPosts: state.location.posts.list || [],
		}),
		({ scheduleList, employeeList, selectedContractPosts }) => {
			const uniqueInActiveSchedules: InActiveSchedule[] = []
			const inActiveSchedules: InActiveSchedule[] = []
			selectedContractPosts?.forEach(post => {
				post?.inActiveSchedules?.forEach(inActiveSchedule => {
					const {
						from,
						until,
						startTime,
						finishTime,
						weeksInterval = 1,
						isFourOnFourOff = false,
					} = inActiveSchedule

					const recurWeekStart = moment(from).clone().startOf("isoWeek").format(DATE_FORMATS.DD_MM_YYYY)
					const differenceInWeeks = moment(selectedDate, DATE_FORMATS.DD_MM_YYYY).diff(
						moment(from).clone().startOf("isoWeek"),
						"weeks",
					)
					if (isFourOnFourOff) {
						const startTimeMoment = moment(startTime)
						const startTime_HH_MM_SS = startTimeMoment.format(DATE_FORMATS.HH_mm_ss)

						const finishTimeMoment = moment(finishTime)
						const finishTime_HH_MM_SS = finishTimeMoment.format(DATE_FORMATS.HH_mm_ss)

						const selectedDateMoment = moment(selectedDate, DATE_FORMATS.DD_MM_YYYY)
						const datesInWeek = getDatesOfWeekFromSingleDate(selectedDateMoment.clone().toDate())
						datesInWeek.forEach(dateInWeek => {
							const fromMoment = moment(from).clone()
							if (dateInWeek.startOf("day").isSameOrAfter(fromMoment.startOf("day"))) {
								if (!until || dateInWeek.startOf("day").isSameOrBefore(moment(until).endOf("day"))) {
									const differenceInDays = +dateInWeek.diff(fromMoment, "days")

									if (Math.floor(differenceInDays / 4) % 2 === 0) {
										const currentDate = dateInWeek.format(DATE_FORMATS.DD_MM_YYYY)

										const formattedScheduleStartTime = moment(
											`${currentDate}T${startTime_HH_MM_SS}`,
											DATE_FORMATS.DD_MM_YYYY_HH_MM_SS,
										).format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1)

										let formattedScheduleFinishTime = ""
										if (startTimeMoment.hour() >= finishTimeMoment.hour()) {
											formattedScheduleFinishTime = moment(
												`${currentDate}T${finishTime_HH_MM_SS}`,
												DATE_FORMATS.DD_MM_YYYY_HH_MM_SS,
											)
												.add(1, "day")
												.format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1)
										} else {
											formattedScheduleFinishTime = moment(
												`${currentDate}T${finishTime_HH_MM_SS}`,
												DATE_FORMATS.DD_MM_YYYY_HH_MM_SS,
											).format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1)
										}
										if (
											!contract?.finishDate ||
											moment(contract?.finishDate).startOf("D") >=
												moment(currentDate, DATE_FORMATS.DD_MM_YYYY).startOf("D")
										) {
											inActiveSchedules.push({
												_id: inActiveSchedule._id,
												post: post,
												location: inActiveSchedule?.location?._id,
												employee: inActiveSchedule?.employee?._id,
												date: currentDate,
												startTime: formattedScheduleStartTime,
												finishTime: formattedScheduleFinishTime,
												type: inActiveSchedule.type,
												status: ScheduleStatus.INACTIVE,
												from,
												until,
												weeksInterval,
												isFourOnFourOff: true,
											})
										}
									}
								}
							}
						})
					} else if (
						!differenceInWeeks ||
						(differenceInWeeks >= weeksInterval && differenceInWeeks % weeksInterval === 0)
					) {
						const startTimeMoment = moment(startTime)
						const startTime_HH_MM_SS = startTimeMoment.format(DATE_FORMATS.HH_mm_ss)

						const finishTimeMoment = moment(finishTime)
						const finishTime_HH_MM_SS = finishTimeMoment.format(DATE_FORMATS.HH_mm_ss)

						const currentWeekEnd = moment(selectedDate, DATE_FORMATS.DD_MM_YYYY)
							.clone()
							.endOf("isoWeek")
							.toISOString()

						const untilDate = getMomentZInstanceOfDate(until || currentWeekEnd).format(
							DATE_FORMATS.DD_MM_YYYY,
						)

						const shouldShowRecurSchedule = isDateSameOrBetween(selectedDate, recurWeekStart, untilDate)

						if (shouldShowRecurSchedule) {
							const currentWeekInactiveScheduleDate = getCurrentWeekDateFromPreviousDate(
								selectedDate,
								inActiveSchedule.date,
							)
							const scheduleStartTime = `${currentWeekInactiveScheduleDate}T${startTime_HH_MM_SS}`
							const formattedScheduleStartTime = moment(
								scheduleStartTime,
								DATE_FORMATS.DD_MM_YYYY_HH_MM_SS,
							).format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1)

							let formattedScheduleFinishTime = ""
							if (startTimeMoment.hour() >= finishTimeMoment.hour()) {
								const scheduleFinishTime = `${currentWeekInactiveScheduleDate}T${finishTime_HH_MM_SS}`
								formattedScheduleFinishTime = moment(
									scheduleFinishTime,
									DATE_FORMATS.DD_MM_YYYY_HH_MM_SS,
								)
									.add(1, "day")
									.format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1)
							} else {
								formattedScheduleFinishTime = moment(
									`${currentWeekInactiveScheduleDate}T${finishTime_HH_MM_SS}`,
									DATE_FORMATS.DD_MM_YYYY_HH_MM_SS,
								).format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS1)
							}
							if (
								!contract?.finishDate ||
								moment(contract?.finishDate).startOf("D") >=
									moment(currentWeekInactiveScheduleDate, DATE_FORMATS.DD_MM_YYYY).startOf("D")
							)
								inActiveSchedules.push({
									_id: inActiveSchedule._id,
									post: post,
									location: inActiveSchedule?.location?._id,
									employee: inActiveSchedule?.employee?._id,
									date: currentWeekInactiveScheduleDate,
									startTime: formattedScheduleStartTime,
									finishTime: formattedScheduleFinishTime,
									type: inActiveSchedule.type,
									status: ScheduleStatus.INACTIVE,
									from,
									until,
									weeksInterval,
									isFourOnFourOff: false,
								})
						}
					}
				})
			})

			const activeSchedules = scheduleList
				?.filter(schedule => showDeleted || !schedule.isDeleted)
				?.filter(
					schedule =>
						moment(schedule.startTime).isSameOrAfter(
							moment(selectedDate, DATE_FORMATS.DD_MM_YYYY).startOf("week"),
						) &&
						moment(schedule.startTime).isSameOrBefore(
							moment(selectedDate, DATE_FORMATS.DD_MM_YYYY).endOf("week"),
						),
				)
				?.map(schedule => ({
					...schedule,
					date: convertIOSDateToHTMLFormat(schedule.date, DATE_FORMATS.DD_MM_YYYY),
				}))

			inActiveSchedules?.forEach(inActiveSchedule => {
				if (
					!inActiveSchedule.isFourOnFourOff &&
					!activeSchedules.find(schedule => schedule.createdFromRecur === inActiveSchedule._id)
				) {
					uniqueInActiveSchedules.push(inActiveSchedule)
				} else {
					const isScheduleActivated = activeSchedules.some(schedule => {
						const activeScheduleStartTime = moment(schedule.startTime).format(DATE_FORMATS.HH_mm_ss)
						const activeScheduleFinishTime = moment(schedule.finishTime).format(DATE_FORMATS.HH_mm_ss)
						const activeScheduleDate = moment(schedule.startTime).format(DATE_FORMATS.DD_MM_YYYY)

						const inactiveScheduleStartTime = moment(inActiveSchedule.startTime).format(
							DATE_FORMATS.HH_mm_ss,
						)
						const inactiveScheduleFinishTime = moment(inActiveSchedule.finishTime).format(
							DATE_FORMATS.HH_mm_ss,
						)
						const inactiveScheduleDate = moment(inActiveSchedule.startTime).format(DATE_FORMATS.DD_MM_YYYY)
						return (
							schedule.createdFromRecur === inActiveSchedule._id &&
							activeScheduleStartTime === inactiveScheduleStartTime &&
							activeScheduleFinishTime === inactiveScheduleFinishTime &&
							activeScheduleDate === inactiveScheduleDate
						)
					})
					if (!isScheduleActivated) uniqueInActiveSchedules.push(inActiveSchedule)
				}
			})

			return [...activeSchedules, ...uniqueInActiveSchedules].map(schedule => {
				const employeeFound = employeeList?.find(employee => employee._id === schedule.employee)
				const name = employeeFound?.firstName
					? `${employeeFound?.firstName || ""} ${employeeFound?.lastName || ""}`
					: "Un Allocated"

				const employeeType = employeeFound ? employeeFound.type : ""
				return { ...schedule, employee: employeeFound?._id || "", name, employeeType }
			})
		},
	)

const selectScheduleListByFilter = () =>
	createSelector(
		(state: RootState) => ({
			scheduleList: selectScheduleList()(state),
			filterState: selectScheduleFilterState()(state),
		}),
		({ scheduleList, filterState }) => scheduleList.map(schedule => ({ ...schedule, state: filterState })),
	)

export {
	selectScheduleList,
	selectScheduleState,
	selectScheduleSaving,
	selectScheduleLoading,
	selectScheduleEmployeeAllocation,
	selectScheduleDelete,
	selectScheduleListByLocationForTable,
	selectScheduleListByEmployee,
	selectScheduleListByIsDeleted,
	selectScheduleListByFilter,
	selectScheduleFilterState,
}
