import axios from "axios"
const ROOT_PATH = "jaf"

export default class JAFService {
	static createJAF = (data: any) => axios.post(ROOT_PATH, data)

	static getByStatus = (approved: boolean) => axios.get(ROOT_PATH, { params: { approved } })

	static handleApprove = (id: string, data: any) => axios.post(`${ROOT_PATH}/approve/${id}`, data)
}
