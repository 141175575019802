import React, { useMemo } from "react"
import useAppSelector from "hooks/useAppSelector"
import { selectLocationById } from "store/Location/locationDetails.slice"
import { selectContractByLocationId } from "store/Location/locationContracts.slice"
import { selectPostsByContractId } from "store/Location/locationPosts.slice"
import { Card, Descriptions, Divider } from "antd-v5"
interface Props {
	id: string
}

const LocationInfo: React.FC<Props> = ({ id = "" }) => {
	const location = useAppSelector(selectLocationById(id))
	const contract = useAppSelector(selectContractByLocationId(id))
	const post = useAppSelector(selectPostsByContractId(contract?._id || ""))

	const mappedPost = useMemo(() => {
		return [
			{ label: "Short Name", children: post?.shortName },
			{ label: "Name", children: post?.name },
			{ label: "Telephone 1", children: post?.telephone1 },
			{ label: "Telephone 2", children: post?.telephone2 },
			{ label: "Telephone 3", children: post?.telephone3 },
		]
	}, [post])
	const mappedLocation = useMemo(() => {
		return [
			{ label: "Short Name", children: location?.shortName },
			{ label: "Name", children: location?.name },
			{ label: "SIN", children: location?.sin },
			{ label: "Client SIN", children: location?.clientSin },
			{ label: "Telephone", children: location?.telephone },
			...(location?.postalCode ? [{ label: "Postal Code", children: location?.postalCode }] : []),
			...(location?.addressLine1 || location?.addressLine2
				? [
						{
							label: "Address",
							children: `${location?.addressLine1} ${
								location?.addressLine1 && location?.addressLine2 ? "," : ""
							} ${location?.addressLine2}`,
						},
				  ]
				: []),
		]
	}, [location])
	return (
		<Card size="small">
			<Descriptions key="location" size="small" title={"Details"} column={1} items={mappedLocation} />
			<Divider />
			<Descriptions
				key="locationContract"
				size="small"
				title={"Contract"}
				column={1}
				items={[{ label: "Reference", children: contract?.reference }]}
			/>
			<Divider />
			<Descriptions key="locationPosts" size="small" title={"Posts"} column={1} items={mappedPost} />
		</Card>
	)
}

export default LocationInfo
