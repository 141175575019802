import React, { useMemo } from "react"
import AppRoutes from "config/routes"
import { useLocation, useNavigate } from "react-router-dom"
import { Permissions } from "config/constants"
import { isWorkMonitor } from "config/utils"
import { newTimeTrailShieldLogo } from "images"
import Icon from "./Icon"
import { useAbac } from "react-abac"
import { ConfigProvider, Divider, Dropdown } from "antd-v5"
import type { MenuProps } from "antd-v5"

interface Props {
	setPanel: (panel: string) => void
	showSidebar: boolean
	handleShowSidebar: () => void
}

const Sidebar: React.FC<Props> = ({ setPanel, showSidebar, handleShowSidebar }) => {
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const handleOnClick = (route: string, panel: string) => {
		navigate(route)
		setPanel(panel)
	}
	const { userHasPermissions } = useAbac()

	const handleMenuClick: MenuProps["onClick"] = e => {
		handleOnClick(e.key, e.key.replace("/", ""))
	}

	const isCommandCenterRoute = useMemo(() => isWorkMonitor(pathname), [pathname])

	const menuItems = [
		{
			label: "Admin",
			key: AppRoutes.ADMIN_PAGE,
			icon: <Icon name="admin" color="secondary-dark" size={22} />,
			perform: Permissions.VIEW_ADMINISTRATION,
		},
		{
			label: "Schedules",
			key: AppRoutes.SCHEDULES_PAGE,
			icon: <Icon name="schedules" color="secondary-dark" size={22} />,
			perform: [],
		},
		{
			label: "Reports",
			key: AppRoutes.REPORTS,
			icon: <Icon name="reporting" color="secondary-dark" size={22} />,
			perform: Permissions.VIEW_REPORTS,
		},
	]

	const dropdownLabelName = pathname.split("/")?.[1] || ""

	const filterMenuItems: MenuProps["items"] = useMemo(() => {
		return menuItems.filter(item => !!userHasPermissions(item.perform))
	}, [menuItems])

	const menuProps = {
		items: filterMenuItems,
		onClick: handleMenuClick,
	}

	return (
		<div className="relative h-full w-full">
			{isCommandCenterRoute && (
				<div
					className="absolute -right-2 top-1 z-50 h-6 w-6 cursor-pointer rounded-full bg-primary1 "
					onClick={() => handleShowSidebar()}
				>
					<div className="flex h-full w-full items-center justify-center">
						<Icon name={showSidebar ? "dropLeftArrow" : "dropRightArrow"} color="white" size={25} />
					</div>
				</div>
			)}

			<div className={`h-full px-2 ${showSidebar ? "visible" : "hidden"}`}>
				<div className="space-y-5  pt-4">
					<div className=" flex flex-row space-x-5 px-4">
						<div className="h-10 w-10 overflow-hidden rounded-md bg-white ring-1  ring-gray-600">
							<img
								src={newTimeTrailShieldLogo}
								alt="TimeTrail-Login"
								className="h-10 w-10 object-contain p-0.125"
							/>
						</div>
						<div className="flex flex-col justify-center">
							<div className="text-lg font-semibold text-neutral-200">Time Trail</div>
							{/* <div className="text-md text-gray-400">Time Trail Security</div> */}
						</div>
					</div>
					<ConfigProvider
						prefixCls="ant5"
						theme={{
							components: {
								Dropdown: {
									colorBgContainer: "rgb(51 65 85);",
									lineWidth: 0,
									colorTextBase: "rgb(229 229 229);",
									borderRadius: 4,
									fontSize: 14,
									colorTextPlaceholder: "rgb(156 163 175);",
									colorText: "rgb(229 229 229);",
									colorBgElevated: "rgb(51 65 85);",
									lineWidthBold: 0,
									lineWidthFocus: 0,
								},
							},
						}}
					>
						<Dropdown menu={menuProps}>
							<div className="flex cursor-pointer justify-between rounded-md bg-gray-900 py-2 px-4 ">
								<div className="flex space-x-2 text-md font-semibold text-neutral-200 ">
									<div>
										{menuItems.find(({ label }) => label.toLowerCase() === dropdownLabelName)?.icon}
									</div>
									<div>
										{
											menuItems.find(({ label }) => label.toLowerCase() === dropdownLabelName)
												?.label
										}
									</div>
								</div>
								<div>
									<Icon color="secondary-dark" size={22} name="dropdownArrow" />
								</div>
							</div>
						</Dropdown>
					</ConfigProvider>
				</div>
				<div className="flex items-center py-4 3xl:py-5">
					<Divider className="bg-slate-700" style={{ margin: 0 }} />
				</div>
				<div id="global-sidebar" />
			</div>
		</div>
	)
}

export default Sidebar
