import axios from "axios"
import {
	CreateLocationQualificationInterface,
	GetLocationQualificationInterface,
	GetLocationQualificationObjectInterface,
} from "@type/locations.types"
const ROOT_PATH = "locations/qualifications"
export default class LocationQualificationService {
	static createLocationQualification = (locationQualification: CreateLocationQualificationInterface) =>
		axios.post<GetLocationQualificationObjectInterface>(ROOT_PATH, locationQualification)
	static getLocationQualificationByPostId = (id: string) =>
		axios.get<GetLocationQualificationInterface[]>(`${ROOT_PATH}/${id}`)

	static updateLocationQualification = (locationQualification: Partial<GetLocationQualificationInterface>) =>
		axios.put<GetLocationQualificationObjectInterface>(`${ROOT_PATH}`, locationQualification)
}
