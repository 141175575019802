import { createContext } from "react"

export interface LocationContextProps {
	selected: string
	handleSelected: (LocationId: string) => void
}

const LocationContext = createContext<LocationContextProps>({ selected: "", handleSelected: () => undefined })

export default LocationContext
