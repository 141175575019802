import axios from "axios"

import { EmployeeEquipmentSizesInterface } from "@type/employees.types"

const ROOT_PATH = "employees/equipments-sizes"

export default class EmployeeEquipmentSizesService {
	static createEmployeeEquipmentSizes = (equipmentSizes: EmployeeEquipmentSizesInterface) =>
		axios.post<EmployeeEquipmentSizesInterface>(`${ROOT_PATH}`, equipmentSizes)

	static updateEmployeeEquipmentSizes = (equipmentId: string, EquipmentData: EmployeeEquipmentSizesInterface) =>
		axios.put<EmployeeEquipmentSizesInterface>(`${ROOT_PATH}/${equipmentId}`, EquipmentData)

	static getEmployeeEquipmentSizesByEmployeeId = (employeeId: string) =>
		axios.get<EmployeeEquipmentSizesInterface>(`${ROOT_PATH}/${employeeId}`)
}
