import React, { useEffect } from "react"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAppDispatch, useAppSelector } from "hooks"
import startCase from "lodash/startCase"
import { AntdButton, DateField, FormInputAntd, FormSelectAntd } from "components"
import { getScheduleByFilter, selectScheduleLoading } from "store/Schedules/schedules.slice"
import { ScheduleFilterSchema } from "Schema"
import { ScheduleFilterInterface } from "@type/schedules.types"
import { SchedulesFilter } from "../Constants/schedulesConstants"
import { convertIOSTZDateToSpecificUTCFormat } from "config/utils"
import { DATE_FORMATS } from "config/constants"

interface Props {
	branch: string | null
}

const schedulesOptionsFilter = Object.values(SchedulesFilter).map(filterKey => ({
	label: startCase(filterKey),
	value: filterKey,
}))

const FilterOptions: React.FC<Props> = ({ branch = null }) => {
	const dispatch = useAppDispatch()
	const loading = useAppSelector(selectScheduleLoading())
	const methods = useForm<ScheduleFilterInterface>({
		resolver: yupResolver(ScheduleFilterSchema),
		defaultValues: { maxRows: 200 },
	})

	const {
		setValue,
		handleSubmit,
		watch,
		formState: { isDirty },
	} = methods

	useEffect(() => {
		setValue("state", schedulesOptionsFilter[0].value, { shouldValidate: true, shouldDirty: true })
	}, [])

	useEffect(() => {
		if (branch) {
			setValue("branch", branch, { shouldDirty: true, shouldValidate: true })
		}
	}, [branch])

	const onSubmit: SubmitHandler<ScheduleFilterInterface> = data => {
		dispatch(
			getScheduleByFilter({
				...data,
				startTime: convertIOSTZDateToSpecificUTCFormat(data.startTime, DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS),
				finishTime: convertIOSTZDateToSpecificUTCFormat(data.finishTime, DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS),
			}),
		)
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid w-1/2 grid-cols-2 gap-2">
					<FormSelectAntd
						disabled={!branch || !schedulesOptionsFilter.length}
						name="state"
						label="Filter"
						options={schedulesOptionsFilter}
					/>
					<FormInputAntd disabled={!branch} label="Max Rows" name="maxRows" type="text" />
					<DateField
						disabled={!branch}
						label="From date and time"
						setValue={setValue}
						watch={watch}
						name="startTime"
						showTime={true}
					/>
					<DateField
						disabled={!branch}
						label="To date and time"
						setValue={setValue}
						watch={watch}
						name="finishTime"
						showTime={true}
					/>
				</div>

				<div className="py-2">
					<AntdButton disabled={!isDirty || !branch} loading={loading} htmlType="submit">
						Fetch
					</AntdButton>
				</div>
			</form>
		</FormProvider>
	)
}

export default FilterOptions
