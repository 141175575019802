import React from "react"
import { FieldError, UseFormRegister, FieldErrors } from "react-hook-form"
import Input, { InputProps } from "./Input"
import { fieldsError } from "config/utils"

interface Props extends InputProps {
	register: UseFormRegister<any>
	errors?: { [key: string]: FieldError | undefined } | FieldErrors
	containerClass?: string
	errorClass?: string
	loading?: boolean
}

const FormInput: React.FC<Props> = ({
	register,
	errors = {},
	containerClass,
	name = "",
	errorClass,
	loading,
	label,
	type,
	autoComplete = "new-password",
	...rest
}) => {
	return (
		<div className={`flex flex-col ${containerClass}`}>
			{type && ["radio", "checkbox"].includes(type) && label ? (
				<label htmlFor={label} className={`${rest.className} cursor-pointer`}>
					{label} {fieldsError(name, errors)?.message}
				</label>
			) : null}
			<Input
				autoComplete={autoComplete}
				label={type && ["radio", "checkbox"].includes(type) ? "" : label}
				id={label}
				type={type}
				{...register(name)}
				{...rest}
				disabled={loading || rest.disabled}
				loading={loading}
			/>

			{fieldsError(name, errors) && (
				<p className={`mt-1 ml-1 text-sm text-danger ${errorClass}`}>{fieldsError(name, errors)?.message}</p>
			)}
		</div>
	)
}

export default FormInput
