import { getMomentZInstanceOfDate } from "config/utils"
import {
	EmployeeTypesEnum,
	IncidentState,
	SHIFT_NEXT_EVENTS_PRIORITY,
	ShiftStatus,
	nextStatusToPriorityMap,
} from "config/constants"
import {
	EventPropInterface,
	ModifiedEventProps,
	NextEventsInterface,
	PopulatedShiftInterface,
	PreviousShiftEvent,
	ShiftInterface,
} from "@type/workMonitor.types"
import { orderBy } from "lodash"
import { momentZ } from "config/moment"
export interface WorkMonitorShiftsClassified {
	allocatedShift: PopulatedShiftInterface[]
	unAllocatedShift: PopulatedShiftInterface[]
	criticalShift: PopulatedShiftInterface[]
	activeShift: PopulatedShiftInterface[]
	cleaning: PopulatedShiftInterface[]
}

export default class WorkMonitorShiftsManager {
	static sortShiftsByDateAndStatus = (shifts: PopulatedShiftInterface[]) => {
		const result: WorkMonitorShiftsClassified = {
			allocatedShift: [],
			unAllocatedShift: [],
			criticalShift: [],
			activeShift: [],
			cleaning: [],
		}

		const sortedShifts = orderBy(
			shifts.map(item => ({
				...item,
				eventTime: getMomentZInstanceOfDate(item.nextStatusTime.eventTime).toDate(),
				actionPriority:
					item.geoFenceData && item.geoFenceData.length > 0 ? 0 : nextStatusToPriorityMap[item.action],
			})),
			["eventTime", "actionPriority", "customer", "location", "employee"],
			["asc", "asc", "asc", "asc", "asc"],
		)

		sortedShifts?.forEach(shift => {
			if (shift?.postType === EmployeeTypesEnum.CLEANING) {
				result.cleaning.push(shift)
			} else if (!shift.employee) {
				result.unAllocatedShift.push(shift)
				result.allocatedShift.push(shift)
			} else if (
				shift?.incidents?.some(
					incident => incident.incidentState === IncidentState.UN_RESOLVED || !incident?.incidentState,
				)
			) {
				result.criticalShift.push(shift)
			} else if (
				shift?.geoFenceData?.length &&
				momentZ(shift.nextEvents.BOOKOFF[0].eventTime).diff(momentZ(), "minutes") >= 15
			) {
				result.criticalShift.push(shift)
			} else if (
				shift.nextEvents.CHECK?.length &&
				shift.shiftEvents.some(item => item.status === ShiftStatus.BOOKON) &&
				shift.action !== ShiftStatus.BOOKOFF
			)
				result.activeShift.push(shift)
			else result.allocatedShift.push(shift)
		})

		return result
	}

	static getShiftsStatusFromNextEvent = (row: ShiftInterface): ShiftStatus => {
		if (row.nextEvents.CHASED) return ShiftStatus.PENDING
		else if (row.nextEvents.BOOKON) return ShiftStatus.CHASED
		else if (row.nextEvents.BOOKOFF) return ShiftStatus.BOOKON
		return ShiftStatus.BOOKOFF
	}

	static getNextShiftEvent = ({
		shiftEvents = [],
		nextEvents,
	}: ShiftInterface): { action: ShiftStatus; nextStatusTime: EventPropInterface } => {
		if (
			getMomentZInstanceOfDate().isSameOrAfter(getMomentZInstanceOfDate(nextEvents.BOOKOFF[0].window.before)) &&
			shiftEvents.some(({ status }) => status === ShiftStatus.BOOKON)
		)
			return { action: ShiftStatus.BOOKOFF, nextStatusTime: nextEvents[ShiftStatus.BOOKOFF][0] }
		else {
			const defaultNextEvent = {
				eventTime: new Date(),
				window: { after: new Date(), before: new Date() },
			}

			if (nextEvents.CHASED)
				return {
					action: ShiftStatus.CHASED,
					nextStatusTime: nextEvents[ShiftStatus.CHASED]?.[0] || defaultNextEvent,
				}
			else if (nextEvents.BOOKON)
				return {
					action: ShiftStatus.BOOKON,
					nextStatusTime: nextEvents[ShiftStatus.BOOKON]?.[0] || defaultNextEvent,
				}
			else if (
				nextEvents?.QR?.length >= 1 ||
				nextEvents?.INVENTORY?.length >= 1 ||
				nextEvents?.CHECK?.length >= 1
			)
				return this.getHighestPriorityEvent(nextEvents, shiftEvents)
			else
				return {
					action: ShiftStatus.BOOKOFF,
					nextStatusTime: nextEvents[ShiftStatus.BOOKOFF]?.[0] || defaultNextEvent,
				}
		}
	}

	static getHighestPriorityEvent = (nextEvents: NextEventsInterface, shiftEvents: PreviousShiftEvent[]) => {
		const modifiedNextEvents = this.getOneNextEventFromNextEvents(nextEvents, shiftEvents)

		if (modifiedNextEvents) {
			const modifiedNextEventsKeys = Object.keys(modifiedNextEvents)
			const firstModifiedNextEventKey = modifiedNextEvents[modifiedNextEventsKeys[0]]

			const [{ eventTime, status, window, qrList = [] }] = Object.entries(modifiedNextEvents).reduce(
				(acc, [, value]) => {
					if (value.length > 0 && acc.length > 0 && acc[0].status !== value[0].status) {
						return momentZ(value[0].eventTime).isBefore(acc[0].eventTime) ? value : acc
					} else return acc
				},
				firstModifiedNextEventKey,
			)
			return { action: status, nextStatusTime: { eventTime, window, ...(qrList.length ? qrList : {}) } }
		} else return { action: ShiftStatus.BOOKOFF, nextStatusTime: nextEvents[ShiftStatus.BOOKOFF][0] }
	}

	static getOneNextEventFromNextEvents = (nextEvents: NextEventsInterface, shiftEvents: PreviousShiftEvent[]) => {
		let modifiedNextEvents: Record<string, ModifiedEventProps[]> = {}

		SHIFT_NEXT_EVENTS_PRIORITY.forEach(nextEvent => {
			const nextEventData = nextEvents[nextEvent] || []
			if (nextEventData?.length) {
				const currentTime = getMomentZInstanceOfDate()

				const nextGreaterEventWRTIndexFound = nextEventData.findIndex(({ eventTime }) =>
					getMomentZInstanceOfDate(eventTime).isAfter(currentTime),
				)

				if (nextGreaterEventWRTIndexFound >= 0) {
					const isPreviousEventFromCurrentTimeTaken = shiftEvents
						.filter(({ status }) => status === nextEvent)
						.some(
							({ dueTime, missingQREvents = [], isEventMissed = false }) =>
								getMomentZInstanceOfDate(dueTime).isSame(
									getMomentZInstanceOfDate(
										nextEventData[
											nextGreaterEventWRTIndexFound === 0
												? nextGreaterEventWRTIndexFound
												: nextGreaterEventWRTIndexFound - 1
										].eventTime,
									),
								) && (nextEvent === ShiftStatus.QR ? !missingQREvents.length : !isEventMissed),
						)

					const selectIndexBased = isPreviousEventFromCurrentTimeTaken
						? nextGreaterEventWRTIndexFound
						: nextGreaterEventWRTIndexFound === 0
						? nextGreaterEventWRTIndexFound
						: nextGreaterEventWRTIndexFound - 1

					modifiedNextEvents = {
						...modifiedNextEvents,
						[nextEvent]: [
							{
								eventTime: nextEventData[selectIndexBased].eventTime,
								window: nextEventData[selectIndexBased].window,
								status: nextEvent,
							},
						],
					}
				} else
					modifiedNextEvents = {
						...modifiedNextEvents,
						[nextEvent]: [
							{
								eventTime: nextEventData.slice(-1)?.[0]?.eventTime,
								window: nextEventData.slice(-1)?.[0]?.window,
								status: nextEvent,
							},
						],
					}
			}
		})

		return Object.values(modifiedNextEvents).length ? modifiedNextEvents : null
	}
}
