import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EmployeeTermInterface } from "@type/employees.types"
import { termsService } from "services"
import { AppThunk, RootState } from "store"

export interface TermStateInterface {
	data: null | EmployeeTermInterface[]
	loading: boolean
	saving: boolean
}

const initialState: TermStateInterface = {
	data: null,
	loading: false,
	saving: false,
}

const termSlice = createSlice({
	name: "term",
	initialState,
	reducers: {
		requestTermData: term => {
			term.loading = true
		},
		receiveTermData: (term, action: PayloadAction<EmployeeTermInterface[]>) => {
			term.loading = false
			term.data = action.payload
		},
		receiveTermDataFailed: term => {
			term.loading = false
		},
		requestSavingTermData: termData => {
			termData.saving = true
		},
		SavingEmployeeTermSuccess: (term, action: PayloadAction<EmployeeTermInterface>) => {
			term.saving = false
			const index = term.data?.findIndex(d => d._id === action.payload._id)
			if (index !== -1) {
				term.data?.splice(index as number, 1, action.payload)
			} else {
				term.data = [...(term.data as EmployeeTermInterface[]), action.payload]
			}
		},
		SavingEmployeeTermSuccessFail: term => {
			term.saving = false
		},
	},
})

//REDUCER
export default termSlice.reducer

//ACTIONS
const {
	requestTermData,
	receiveTermData,
	receiveTermDataFailed,
	requestSavingTermData,
	SavingEmployeeTermSuccess,
	SavingEmployeeTermSuccessFail,
} = termSlice.actions

const getEmployeesTermByEmployeeId =
	(employeeId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestTermData())
			const { data: termResponse } = await termsService.getEmployeesTerm(employeeId)
			dispatch(receiveTermData(termResponse))
		} catch (error) {
			dispatch(receiveTermDataFailed())
		}
	}

const saveEmployeeTermData =
	(termData: EmployeeTermInterface): AppThunk =>
	async dispatch => {
		try {
			let data = null
			dispatch(requestSavingTermData())
			if (termData._id) {
				data = await termsService.updateEmployeeTerm(termData._id, termData)
			} else {
				data = await termsService.createEmployeeTerm(termData)
			}
			const { data: termResponse } = data
			dispatch(SavingEmployeeTermSuccess(termResponse))
		} catch (error) {
			dispatch(SavingEmployeeTermSuccessFail())
		}
	}

export { saveEmployeeTermData, getEmployeesTermByEmployeeId }

//SELECTORS
const selectEmployeeTermState = (state: RootState) => state.employee.term
const selectEmployeeTermLoading = () => (state: RootState) => selectEmployeeTermState(state).loading
const selectEmployeeTermData = () => (state: RootState) => selectEmployeeTermState(state).data
const selectEmployeeTermSaving = () => (state: RootState) => selectEmployeeTermState(state).saving

export { selectEmployeeTermLoading, selectEmployeeTermData, selectEmployeeTermSaving }
