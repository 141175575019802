import axios from "axios"
import { EmployeeUsualWorkFormInterface, EmployeeUsualWorkInterface } from "@type/employees.types"

const ROOT_PATH = "employees/usual-work"

export default class EmployeesUsualWorkService {
	static createEmployeeUsualWork = (usualWorkData: EmployeeUsualWorkFormInterface) =>
		axios.post<EmployeeUsualWorkFormInterface[]>(ROOT_PATH, usualWorkData)

	static updateEmployeeUsualWork = (id: string, usualWorkData: EmployeeUsualWorkFormInterface) =>
		axios.put<EmployeeUsualWorkFormInterface[]>(`${ROOT_PATH}/${id}`, usualWorkData)

	static deleteEmployeeUsualWorkById = (id: string) => axios.delete(`${ROOT_PATH}/${id}`)

	static findEmployeeUsualWorkByEmployeeID = (id: string) =>
		axios.get<EmployeeUsualWorkInterface[]>(`${ROOT_PATH}?employee=${id}`)
}
