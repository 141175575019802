import axios from "axios"
import {
	CreateLocationQRCodeInterface,
	GetLocationDetailsInterface,
	GetLocationQRCodeInterface,
} from "@type/locations.types"
const ROOT_PATH = "locations/qr-code"
export default class LocationQRCodeService {
	static createLocationQRCode = (id: string, locationQRCode: CreateLocationQRCodeInterface) =>
		axios.post<GetLocationDetailsInterface>(`${ROOT_PATH}/${id}`, locationQRCode)

	static updateLocationQRCode = (_id: string, locationQRCode: Partial<GetLocationQRCodeInterface>) =>
		axios.put<GetLocationDetailsInterface>(`${ROOT_PATH}/${_id}`, locationQRCode)

	static deleteLocationQRCode = (_id: string, qrId: string, isLocationActive = true) =>
		axios.put<GetLocationDetailsInterface>(`${ROOT_PATH}/qr/delete`, { id: _id, qrId, isLocationActive })
}
