import React from "react"

import { AntdButton, AntdSwitch } from "components"
import { AllowedTo } from "react-abac"
import { Permissions } from "config/constants"
import { useAppSelector } from "hooks"
import { selectScheduleSaving } from "store/Schedules/schedules.slice"

interface Props {
	disableCreate: boolean
	disableAccept: boolean
	disableUpdate: boolean
	disableAllocate: boolean
	disableDelete: boolean
	disableAuto: boolean
	disableAudit: string
	disableIncident: boolean
	onCreateSchedules: () => void
	onRecurringSchedules: () => void
	onCreateInActiveSchedule: () => void
	onUpdateSchedules: () => void
	onAllocateSchedule: () => void
	onAcceptSchedule: () => void
	onIncidentReport: () => void
	onAuditSchedule: () => void
	onDeleteSchedule: () => void
	showDeletedSchedules: (show: boolean) => void
}

const ScheduleCrudBar: React.FC<Props> = ({
	onCreateSchedules,
	onRecurringSchedules,
	onCreateInActiveSchedule,
	onUpdateSchedules,
	onAcceptSchedule,
	onAllocateSchedule,
	onIncidentReport,
	onAuditSchedule,
	onDeleteSchedule,
	showDeletedSchedules,
	disableAudit,
	disableAuto = false,
	disableCreate = false,
	disableAccept = false,
	disableUpdate = false,
	disableAllocate = false,
	disableDelete = false,
	disableIncident = false,
}) => {
	const isScheduleSaving = useAppSelector(selectScheduleSaving())

	return (
		<div className="sticky top-0 flex gap-1 bg-white py-2">
			<div className="flex w-3/5 flex-wrap gap-1 2.5xl:w-full">
				<AllowedTo perform={Permissions.CREATE_AND_UPDATE_SCHEDULE}>
					<AntdButton size="middle" disabled={disableCreate} onClick={onCreateSchedules}>
						Create
					</AntdButton>
					<AntdButton size="middle" disabled={disableCreate} onClick={onRecurringSchedules}>
						Recur
					</AntdButton>
					<AntdButton size="middle" disabled={disableAccept} onClick={onAcceptSchedule}>
						Accept
					</AntdButton>
					<AntdButton size="middle" disabled={disableUpdate} onClick={onUpdateSchedules}>
						Update
					</AntdButton>
					<AntdButton size="middle" disabled={disableAllocate} onClick={onAllocateSchedule}>
						Allocate
					</AntdButton>
					<AntdButton size="middle" disabled={disableIncident} onClick={onIncidentReport}>
						Incident
					</AntdButton>
					<AntdButton
						size="middle"
						disabled={disableAuto}
						onClick={onCreateInActiveSchedule}
						loading={isScheduleSaving}
					>
						Auto
					</AntdButton>
				</AllowedTo>

				<AntdButton size="middle" disabled={!disableAudit} onClick={onAuditSchedule}>
					Audit
				</AntdButton>
				<AllowedTo perform={Permissions.CREATE_AND_UPDATE_SCHEDULE}>
					<AntdButton size="middle" disabled={disableDelete} onClick={onDeleteSchedule}>
						Delete
					</AntdButton>
				</AllowedTo>
				<div className="flex items-center justify-center">
					<AntdSwitch
						className="bg-[#00000035]"
						checkedChildren="Hide Deleted"
						unCheckedChildren="Show Deleted"
						onChange={e => showDeletedSchedules(e)}
					/>
				</div>
			</div>
		</div>
	)
}

export default ScheduleCrudBar
