import axios from "axios"
import {
	GetPublicHolidaysInterface,
	CreatePublicHolidaysInterface,
	UpdatePublicHolidaysInterface,
} from "@type/payBill.types"

const ROOT_PATH = "pay-bill/public-holiday"
export default class PBCodesService {
	static createPublicHolidays = (notifiee: CreatePublicHolidaysInterface) =>
		axios.post<GetPublicHolidaysInterface>(ROOT_PATH, notifiee)

	static getAllPublicHolidays = () => axios.get<Array<GetPublicHolidaysInterface>>(`${ROOT_PATH}`)

	static getPublicHolidaysById = (id: string) => axios.get<GetPublicHolidaysInterface>(`${ROOT_PATH}/${id}`)

	static updatePublicHolidays = (id: string, notifiee: Partial<UpdatePublicHolidaysInterface>) =>
		axios.put<GetPublicHolidaysInterface>(`${ROOT_PATH}/${id}`, notifiee)
}
