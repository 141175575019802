import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EmployeeHolidayInterface } from "@type/employees.types"
import { holidaysService } from "services"
import { AppThunk, RootState } from "store"

export interface HolidayStateInterface {
	data: EmployeeHolidayInterface | null
	loading: boolean
	saving: boolean
}

const initialState: HolidayStateInterface = {
	data: null,
	loading: false,
	saving: false,
}

const holidaySlice = createSlice({
	name: "holiday",
	initialState,
	reducers: {
		requestHolidayByService: holiday => {
			holiday.loading = true
		},
		receiveHolidayByService: (holiday, action: PayloadAction<EmployeeHolidayInterface>) => {
			holiday.loading = false
			holiday.data = action.payload
		},
		receiveHolidayByServiceFailed: holiday => {
			holiday.loading = false
		},
		requestSavingHolidayData: holidayData => {
			holidayData.saving = true
		},
		SavingEmployeeHolidaySuccess: (holiday, action: PayloadAction<EmployeeHolidayInterface>) => {
			holiday.saving = false
			holiday.data = action.payload
		},
		SavingEmployeeHolidaySuccessFail: holiday => {
			holiday.saving = false
		},
	},
})

//REDUCER
export default holidaySlice.reducer

//ACTIONS
const {
	requestHolidayByService,
	receiveHolidayByService,
	receiveHolidayByServiceFailed,
	requestSavingHolidayData,
	SavingEmployeeHolidaySuccess,
	SavingEmployeeHolidaySuccessFail,
} = holidaySlice.actions

const getEmployeesHolidayByServiceId =
	(serviceId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestHolidayByService())
			const { data: holidayResponse } = await holidaysService.getHolidayByService(serviceId)
			dispatch(receiveHolidayByService(holidayResponse))
		} catch (error) {
			dispatch(receiveHolidayByServiceFailed())
		}
	}

const saveEmployeeHolidayData =
	(holidayData: EmployeeHolidayInterface): AppThunk =>
	async dispatch => {
		try {
			let data = null
			dispatch(requestSavingHolidayData())
			if (holidayData._id) {
				data = await holidaysService.updateEmployeeHoliday(holidayData._id, holidayData)
			} else {
				data = await holidaysService.createEmployeeHoliday(holidayData)
			}
			const { data: holidayResponse } = data
			dispatch(SavingEmployeeHolidaySuccess(holidayResponse))
		} catch (error) {
			dispatch(SavingEmployeeHolidaySuccessFail())
		}
	}

export { saveEmployeeHolidayData, getEmployeesHolidayByServiceId }

//SELECTORS
const selectEmployeeHolidayState = (state: RootState) => state.employee.holiday
const SelectEmployeeHolidayLoading = () => (state: RootState) => selectEmployeeHolidayState(state).loading
const selectEmployeeHolidayData = () => (state: RootState) => selectEmployeeHolidayState(state).data
const selectEmployeeHolidaySaving = () => (state: RootState) => selectEmployeeHolidayState(state).saving

export { SelectEmployeeHolidayLoading, selectEmployeeHolidayData, selectEmployeeHolidaySaving }
