import Chart from "components/Chart"
import { ApexOptions } from "apexcharts"
import { theme } from "config"
import useLocationStats from "hooks/useLocationStats"

// const keysToOmit = [
// 	"_id",
// 	"otherCoveredShiftsBreakdown",
// 	"totalShifts",
// 	"totalHours",
// 	"allocatedShifts",
// 	"allocatedHours",
// 	"unAllocatedHours",
// 	"criticalShifts",
// 	"selfCoveredShifts",
// 	"selfCoveredHours",
// 	"otherCoveredShifts",
// 	"otherCoveredHours",
// 	"lateShifts",
// 	"trends",
// ]

const calculateChartHeight = (labelsCount: number) => {
	let legendHeight = 100
	let chartHeight = 320

	if (labelsCount === 1) {
		legendHeight = 25
		chartHeight = 220
	} else if (labelsCount === 2) {
		legendHeight = 50
		chartHeight = 245
	} else if (labelsCount === 3) {
		legendHeight = 75
		chartHeight = 270
	} else if (labelsCount === 4) {
		legendHeight = 100
		chartHeight = 295
	} else if (labelsCount === 5) {
		legendHeight = 125
		chartHeight = 320
	} else if (labelsCount === 6) {
		legendHeight = 150
		chartHeight = 345
	} else if (labelsCount === 7) {
		legendHeight = 175
		chartHeight = 370
	} else {
		legendHeight = 8 * labelsCount
		chartHeight = 220 + (labelsCount - 1) * 7
	}

	return {
		legendHeight,
		chartHeight,
	}
}

const ChartSection = () => {
	const {
		totalShiftsBreakdownByHours,
		totalShiftsBreakdownByCountInPercent,
		totalSuppliersBreakdown,
		totalCustomersBreakdownByHours,
		totalSupplierBreakdownByHours,
		generalShiftsBreakdownByCount,
		generalBookOnBreakdownByCount,
		shiftPlanDistribution,
	} = useLocationStats()

	const defaultChartOptions: ApexOptions = {
		title: {
			align: "center",
			style: {
				fontFamily: "Poppins",
				fontSize: "12px",
				fontWeight: 600,
			},
		},

		dataLabels: { enabled: false },
		legend: {
			height: 100,
			position: "bottom",
			horizontalAlign: "left",
			fontFamily: "Poppins",
			fontSize: "12px",
			fontWeight: 400,
		},

		tooltip: {
			shared: false,
			style: {
				fontFamily: "Poppins",
				fontSize: "12px",
			},
			custom: function ({ seriesIndex, w }) {
				const data = w.config.labels[seriesIndex].split(" - ")
				return `<div style="background:${w.config.colors[seriesIndex]} " class="flex text-white p-2">
			        <span>${data[0].trim()}: </span>
			        <span class="font-semibold pl-1"> ${data[1]}</span>
			    </div>`
			},
		},
	}

	const { title, legend, ...rest } = defaultChartOptions

	const totalShiftsBreakdownByCountInPercentChartOptions: ApexOptions = {
		...rest,
		title: {
			text: "Shift Distribution(%)",
			...title,
		},
		colors: [theme.colors.warning, theme.colors.info],
		labels: totalShiftsBreakdownByCountInPercent.labels,
		legend: {
			...legend,
			height: calculateChartHeight(totalShiftsBreakdownByCountInPercent.labels?.length).legendHeight,
			markers: {
				fillColors: [theme.colors.warning, theme.colors.info],
			},
		},
	}

	const totalShiftsBreakdownByHoursChartOptions: ApexOptions = {
		...rest,
		title: {
			text: "Hours Distribution",
			...title,
		},
		colors: [theme.colors.success, theme.colors["danger-light"]],
		labels: totalShiftsBreakdownByHours.labels,
		legend: {
			...legend,
			height: calculateChartHeight(totalShiftsBreakdownByHours.labels?.length).legendHeight,
			markers: {
				fillColors: [theme.colors.success, theme.colors["danger-light"]],
			},
		},
	}

	const totalSuppliersBreakdownChartOptions: ApexOptions = {
		...rest,
		title: {
			text: "Shifts Covered by Suppliers",
			...title,
		},
		colors: [
			theme.colors.tangerine,
			theme.colors.warning,
			theme.colors["sky-blue"],
			theme.colors["t-blue"],
			theme.colors.success,
			theme.colors.info,
		],
		labels: totalSuppliersBreakdown.labels,
		legend: {
			...legend,
			height: calculateChartHeight(totalSuppliersBreakdown.labels?.length).legendHeight,
			markers: {
				fillColors: [
					theme.colors.tangerine,
					theme.colors.warning,
					theme.colors["sky-blue"],
					theme.colors["t-blue"],
					theme.colors.success,
					theme.colors.info,
				],
			},
		},
	}

	// const totalCustomerBreakdownChartOptions: ApexOptions = {
	// 	...rest,
	// 	title: {
	// 		text: "Shifts Covered by Customers",
	// 		...title,
	// 	},
	// 	colors: [theme.colors.tangerine, theme.colors["t-blue"], theme.colors.success, theme.colors.info],
	// 	labels: totalCustomersBreakdown.labels,
	// 	legend: {
	// 		...legend,
	// 		height: calculateChartHeight(totalCustomersBreakdown.labels.length).legendHeight,
	// 		markers: {
	// 			fillColors: [theme.colors.tangerine, theme.colors["t-blue"], theme.colors.success, theme.colors.info],
	// 		},
	// 	},
	// }
	const totalShiftsCustomersBreakdownByHoursChartOptions: ApexOptions = {
		...rest,
		title: {
			text: "Customer Hours Distribution",
			...title,
		},
		colors: [
			theme.colors.tangerine,
			theme.colors.warning,
			theme.colors["sky-blue"],
			theme.colors["t-blue"],
			theme.colors.success,
			theme.colors.info,
		],
		labels: totalCustomersBreakdownByHours.labels,
		legend: {
			...legend,
			height: calculateChartHeight(totalCustomersBreakdownByHours.labels?.length).legendHeight,
			markers: {
				fillColors: [
					theme.colors.tangerine,
					theme.colors.warning,
					theme.colors["sky-blue"],
					theme.colors["t-blue"],
					theme.colors.success,
					theme.colors.info,
				],
			},
		},
	}

	const totalShiftsSupplierBreakdownByHoursChartOptions: ApexOptions = {
		...rest,
		title: {
			text: "Supplier Hours Distribution",
			...title,
		},
		colors: [
			theme.colors.tangerine,
			theme.colors.warning,
			theme.colors["sky-blue"],
			theme.colors["t-blue"],
			theme.colors.success,
			theme.colors.info,
		],
		labels: totalSupplierBreakdownByHours.labels,
		legend: {
			...legend,
			height: calculateChartHeight(totalSupplierBreakdownByHours.labels.length).legendHeight,
			markers: {
				fillColors: [
					theme.colors.tangerine,
					theme.colors.warning,
					theme.colors["sky-blue"],
					theme.colors["t-blue"],
					theme.colors.success,
					theme.colors.info,
				],
			},
		},
	}

	const generalShiftsBreakdownByHoursChartOptions: ApexOptions = {
		title: {
			text: "Shift Coverage",
			...title,
		},
		colors: [theme.colors.success, theme.colors["danger-light"], theme.colors.tangerine, theme.colors.info],
		labels: generalShiftsBreakdownByCount.labels,
		legend: {
			...legend,
			height: calculateChartHeight(generalShiftsBreakdownByCount.labels?.length).legendHeight,
			markers: {
				fillColors: [
					theme.colors.success,
					theme.colors["danger-light"],
					theme.colors.tangerine,
					theme.colors.info,
				],
			},
		},
		tooltip: {
			style: {
				fontFamily: "Poppins",
				fontSize: "14px",
			},
		},
		dataLabels: { enabled: false },
	}

	const generalBookOnsBreakdownByHoursChartOptions: ApexOptions = {
		title: {
			text: "Activity Stats",
			...title,
		},
		colors: [theme.colors["success"], theme.colors.info, theme.colors["danger-light"]],
		labels: generalBookOnBreakdownByCount.labels,
		legend: {
			...legend,
			height: calculateChartHeight(generalBookOnBreakdownByCount.labels?.length).legendHeight,
			markers: {
				fillColors: [theme.colors["success"], theme.colors.info, theme.colors["danger-light"]],
			},
		},
		tooltip: {
			style: {
				fontFamily: "Poppins",
				fontSize: "14px",
			},
		},
		dataLabels: { enabled: false },
	}
	const shiftPlanDistributionChartOptions: ApexOptions = {
		title: {
			text: "Request Distribution",
			...title,
		},
		colors: [theme.colors["success"], theme.colors["danger-light"]],
		labels: shiftPlanDistribution.labels,
		legend: {
			...legend,
			height: calculateChartHeight(shiftPlanDistribution.labels?.length).legendHeight,
			markers: {
				fillColors: [theme.colors["success"], theme.colors["danger-light"]],
			},
		},
		tooltip: {
			style: {
				fontFamily: "Poppins",
				fontSize: "14px",
			},
		},
		dataLabels: { enabled: false },
	}

	return (
		<div id="charts-section" className="h-[70vh]">
			<div className={`grid h-full grid-cols-3 justify-items-center gap-y-5 overflow-y-scroll py-5`}>
				{!!totalShiftsBreakdownByCountInPercent?.seriesData?.length && (
					<Chart
						options={totalShiftsBreakdownByCountInPercentChartOptions}
						series={totalShiftsBreakdownByCountInPercent.seriesData}
						type="donut"
						height={calculateChartHeight(totalShiftsBreakdownByCountInPercent.labels.length).chartHeight}
						width={200}
					/>
				)}
				{!!totalShiftsBreakdownByHours?.seriesData?.length && (
					<Chart
						options={totalShiftsBreakdownByHoursChartOptions}
						series={totalShiftsBreakdownByHours.seriesData}
						type="donut"
						height={calculateChartHeight(totalShiftsBreakdownByHours.labels.length).chartHeight}
						width={200}
					/>
				)}
				{!!generalShiftsBreakdownByCount?.seriesData?.length && (
					<Chart
						options={generalShiftsBreakdownByHoursChartOptions}
						series={generalShiftsBreakdownByCount.seriesData}
						type="donut"
						height={calculateChartHeight(generalShiftsBreakdownByCount.labels.length).chartHeight}
						width={200}
					/>
				)}

				{!!generalBookOnBreakdownByCount?.seriesData?.length && (
					<Chart
						options={generalBookOnsBreakdownByHoursChartOptions}
						series={generalBookOnBreakdownByCount.seriesData}
						type="donut"
						height={calculateChartHeight(generalBookOnBreakdownByCount.labels.length).chartHeight}
						width={200}
					/>
				)}

				{!!shiftPlanDistribution?.seriesData?.length && (
					<Chart
						options={shiftPlanDistributionChartOptions}
						series={shiftPlanDistribution.seriesData}
						type="donut"
						height={calculateChartHeight(shiftPlanDistribution.labels.length).chartHeight}
						width={200}
					/>
				)}
				{/* {!!totalCustomersBreakdown?.seriesData?.length && (
					<Chart
						options={totalCustomerBreakdownChartOptions}
						series={totalCustomersBreakdown.seriesData}
						type="donut"
						height={calculateChartHeight(totalCustomersBreakdown.labels.length).chartHeight}
						width={200}
					/>
				)} */}
				{!!totalCustomersBreakdownByHours?.seriesData?.length && (
					<Chart
						options={totalShiftsCustomersBreakdownByHoursChartOptions}
						series={totalCustomersBreakdownByHours.seriesData}
						type="donut"
						height={calculateChartHeight(totalCustomersBreakdownByHours.labels.length).chartHeight}
						width={220}
					/>
				)}
				{!!totalSupplierBreakdownByHours?.seriesData?.length && (
					<Chart
						options={totalShiftsSupplierBreakdownByHoursChartOptions}
						series={totalSupplierBreakdownByHours.seriesData}
						type="donut"
						height={calculateChartHeight(totalSupplierBreakdownByHours.labels.length).chartHeight}
						width={220}
					/>
				)}

				{!!totalSuppliersBreakdown?.seriesData?.length && (
					<Chart
						options={totalSuppliersBreakdownChartOptions}
						series={totalSuppliersBreakdown.seriesData}
						type="donut"
						height={calculateChartHeight(totalSuppliersBreakdown.labels.length).chartHeight}
						width={215}
					/>
				)}
			</div>
			{/* <div className="grid grid-cols-3 justify-items-center">
				
			</div> */}
			{/* <div className="grid grid-cols-3 justify-items-center py-5">
				
			</div> */}
			{/* <div className="my-16 flex justify-between space-x-6">
				{locationStats &&
					Object.keys(locationStats).map((item, index) => {
						if (keysToOmit.includes(item)) return <div key={`locationStats/${index}`}></div>
						else
							return (
								<div className="col-span-1" key={`locationStats/${index}`}>
									<Card
										title={caseConverters.titleCase(item).toLocaleUpperCase()}
										value={Number((locationStats as any)[item])}
									/>
								</div>
							)
					})}
			</div> */}
		</div>
	)
}

export default ChartSection
