import axios from "axios"
import { CreateEmployeeAdditionalDetailInterface, GetEmployeeAdditionalDetailInterface } from "@type/employees.types"

const ROOT_PATH = "employees/additional-details"
export default class EmployeeAdditionalDetails {
	static createEmployeeAdditionalDetail = (Data: CreateEmployeeAdditionalDetailInterface) =>
		axios.post<GetEmployeeAdditionalDetailInterface>(`${ROOT_PATH}`, Data)

	static updateEmployeeAdditionalDetailByEmployeeId = (
		id: string,
		additionalData: GetEmployeeAdditionalDetailInterface,
	) => axios.put<GetEmployeeAdditionalDetailInterface>(`${ROOT_PATH}/${id}`, additionalData)

	static getEmployeeAdditionalDetailByEmployeeID = (employeeId: string) => axios.get(`${ROOT_PATH}/${employeeId}`)
}
