import React from "react"
import ShowCurrentTime from "./ShowCurrentTime"

const CurrentDateAndTime = () => {
	const [date] = ShowCurrentTime()

	return <span>{date}</span>
}

export default CurrentDateAndTime
