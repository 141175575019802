import { createContext } from "react"

import { CreateLocationsEscalationsInterface, GetLocationEscalationsInterface } from "@type/locations.types"

export interface LocationEscalationContextInterface {
	locationEscalation: CreateLocationsEscalationsInterface | null
	handleLocationEscalationHandler: (locationEscalation: GetLocationEscalationsInterface | null) => void
}

const LocationEscalationContext = createContext<LocationEscalationContextInterface | null>(null)

export default LocationEscalationContext
