import { sortDeepCopyByProperty, updateState } from "config/utils"
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import SDEventTypesService from "services/staticData/SDEventTypes.service"
import { SDEventTypes as SDEventTypesInterface } from "@type/staticData.types"
import { AppThunk, RootState } from "store"
export interface SDEventTypesStateInterface {
	list: SDEventTypesInterface[]
	loading: boolean
	saving: boolean
}

const initialState: SDEventTypesStateInterface = {
	list: [],
	loading: false,
	saving: false,
}

const SDEventTypes = createSlice({
	name: "SDEventTypes",
	initialState,
	reducers: {
		fetchingSDEventTypes: SDEventTypes => {
			SDEventTypes.loading = true
		},
		SDEventTypesFetched: (SDEventTypes, action: PayloadAction<SDEventTypesInterface[]>) => {
			SDEventTypes.loading = false
			SDEventTypes.list = action.payload
		},
		SDEventTypesFetchingFailed: SDEventTypes => {
			SDEventTypes.loading = false
		},
		savingSDEventTypes: SDEventTypes => {
			SDEventTypes.saving = true
		},
		SDEventTypesSaved: (SDEventTypes, action: PayloadAction<SDEventTypesInterface>) => {
			SDEventTypes.saving = false
			const [UpdatedValue] = updateState(SDEventTypes.list, action.payload, "_id")
			SDEventTypes.list = UpdatedValue
		},
		SDEventTypesSavingFailed: SDEventTypes => {
			SDEventTypes.saving = false
		},
	},
})

//REDUCER
export default SDEventTypes.reducer

//ACTIONS
const {
	fetchingSDEventTypes,
	SDEventTypesFetched,
	SDEventTypesFetchingFailed,
	savingSDEventTypes,
	SDEventTypesSaved,
	SDEventTypesSavingFailed,
} = SDEventTypes.actions

const getSDEventTypes =
	(cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingSDEventTypes())
			const { data: SDEventTypesResponse } = await SDEventTypesService.getAllSDEventsType()
			cb && cb(SDEventTypesResponse[0]?._id)
			dispatch(SDEventTypesFetched(SDEventTypesResponse))
		} catch (error) {
			dispatch(SDEventTypesFetchingFailed())
		}
	}

const saveSDEventTypes =
	(SDEventTypesData: Partial<SDEventTypesInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingSDEventTypes())
			if (SDEventTypesData?._id)
				data = await SDEventTypesService.updateSDEventTypes(SDEventTypesData._id, SDEventTypesData)
			else data = await SDEventTypesService.createSDEventTypes(SDEventTypesData as SDEventTypesInterface)
			const { data: SDEventTypesResponse } = data
			cb && cb(SDEventTypesResponse._id)
			dispatch(SDEventTypesSaved(SDEventTypesResponse))
		} catch (error) {
			dispatch(SDEventTypesSavingFailed())
		}
	}

export { getSDEventTypes, saveSDEventTypes }

//SELECTORS
const selectSDEventTypesState = (state: RootState) => state.staticData.SDEventTypes
const isSDEventTypesLoading = () => (state: RootState) => selectSDEventTypesState(state).loading
const isSDEventTypesSaving = () => (state: RootState) => selectSDEventTypesState(state).saving
const selectSDEventTypesList = createSelector(
	(state: RootState) => state.staticData.SDEventTypes.list,
	list => {
		return sortDeepCopyByProperty(list, "name") as SDEventTypesInterface[]
	},
)

const selectSDEventTypesById = (SDEventTypesId: string) => (state: RootState) =>
	state.staticData.SDEventTypes?.list?.find(SDEventTypesIds => SDEventTypesIds._id === SDEventTypesId)

const SDEventTypesOptions = createSelector(
	(state: RootState) => state.staticData.SDEventTypes.list,
	list =>
		list.map(item => ({
			label: item.name,
			value: item._id,
		})),
)

export {
	selectSDEventTypesState,
	selectSDEventTypesList,
	selectSDEventTypesById,
	isSDEventTypesLoading,
	isSDEventTypesSaving,
	SDEventTypesOptions,
}
