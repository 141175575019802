import React, { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "hooks"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AntdButton, FormInputAntd } from "components"
import {
	getEmployeesSizesByEmployeeId,
	saveEmployeeSizesData,
	selectEmployeeSizesData,
	selectEmployeeSizesSaving,
} from "store/Employee/equipmentSizes.slice"
import { EmployeeEquipmentSizesInterface } from "@type/employees.types"
import { EmployeeEquipmentSizesInitialValues, EmployeeEquipmentSizesValidationSchema } from "Schema"
import { AllowedTo } from "react-abac"
import { Permissions } from "config/constants"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"
import { SelectedPropInterface } from "services/generic/type"
import { Divider, Typography } from "antd"

export enum EquipmentType {
	CAP = "Cap",
	COAT = "Coat",
	WAIST = "Waist",
	CHEST = "Chest",
	INSIDE_LEG = "Inside Leg",
	DRESS = "Dress",
	COLLAR = "Collar",
	SHOES = "Shoes",
	GLOVES = "Gloves",
}

const Sizes: React.FC<SelectedPropInterface> = ({ selected }) => {
	const dispatch = useAppDispatch()
	const EquipmentSizes = useAppSelector(selectEmployeeSizesData())
	const isSizeSaving = useAppSelector(selectEmployeeSizesSaving())

	const methods = useForm<EmployeeEquipmentSizesInterface>({
		resolver: yupResolver(EmployeeEquipmentSizesValidationSchema),
	})
	const {
		handleSubmit,
		reset,
		formState: { isDirty, isSubmitting },
	} = methods

	useEffect(() => {
		dispatch(getEmployeesSizesByEmployeeId(selected as string))
	}, [selected])

	useEffect(() => {
		if (EquipmentSizes) reset(EquipmentSizes)
		else reset(EmployeeEquipmentSizesInitialValues)
	}, [EquipmentSizes?._id])

	const onSubmit: SubmitHandler<EmployeeEquipmentSizesInterface> = data =>
		dispatch(saveEmployeeSizesData({ ...data, employee: selected as string }))

	return (
		<>
			<Divider orientation="left" plain>
				<Typography.Title level={5}>Equipment Sizes</Typography.Title>
			</Divider>
			<div className="flex flex-col empty:hidden">
				<AuditTrail id={EquipmentSizes?._id} name={Entities.EMPLOYEE_EQUIPMENTS_SIZES} />
			</div>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="flex flex-col gap-y-2">
						<div className="grid grid-cols-5 gap-2.5">
							<FormInputAntd label="Hard Hat" type="text" name="cap" />
							<FormInputAntd label="High Visibility Jacket" type="text" name="coat" />
							<FormInputAntd label="Waist (inches)" type="text" name="waist" />
							<FormInputAntd label="Chest (inches)" type="text" name="chest" />
							<FormInputAntd label="Inside Leg (inches)" type="text" name="insideLeg" />
							<FormInputAntd label="Dress" type="text" name="dress" />
							<FormInputAntd label="Collar (inches)" type="text" name="collar" />
							<FormInputAntd label="Shoes (UK Size)" type="text" name="shoes" />
							<FormInputAntd label="Gloves" type="text" name="gloves" />
						</div>
						<AllowedTo perform={Permissions.UPDATE_EMPLOYEE_EQUIPMENT}>
							<AntdButton
								loading={isSizeSaving}
								disabled={!isDirty || isSubmitting}
								htmlType="submit"
								className="my-2.5 w-min"
							>
								{EquipmentSizes ? "Update" : "Save"}
							</AntdButton>
						</AllowedTo>
					</div>
				</form>
			</FormProvider>
		</>
	)
}

export default Sizes
