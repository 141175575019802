import axios from "axios"
import { EmployeeTermInterface } from "@type/employees.types"

const ROOT_PATH = "employees/term"

export default class EmployeesTermsService {
	static createEmployeeTerm = (employeeTerm: EmployeeTermInterface) => axios.post(ROOT_PATH, employeeTerm)

	static getEmployeesTerm = (employeeId: string) => axios.get(`${ROOT_PATH}/${employeeId}`)

	static updateEmployeeTerm = (id: string, termsData: EmployeeTermInterface) =>
		axios.put(`${ROOT_PATH}/${id}`, termsData)
}
