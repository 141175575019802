import React, { useEffect } from "react"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useAppDispatch, useAppSelector } from "hooks"
import { AntdButton, FormInputAntd } from "components"

import {
	getAdditionalDataByEmployeeId,
	saveAdditionalDetailData,
	selectAdditionalDetailData,
	selectAdditionalDetailSaving,
} from "store/Employee/AdditionalDetail/additionalDetail.slice"

import { CreateEmployeeAdditionalDetailInterface } from "@type/employees.types"
import { additionalDetailsValidationSchema, additionalDetailsInitialValues } from "Schema"
import { AllowedTo } from "react-abac"
import { Permissions } from "config/constants"
import { SelectedPropInterface } from "services/generic/type"
import { Card } from "antd"

const AdditionalDetail: React.FC<SelectedPropInterface> = ({ selected }) => {
	const dispatch = useAppDispatch()
	const additionalData = useAppSelector(selectAdditionalDetailData())
	const isSavingAdditionalData = useAppSelector(selectAdditionalDetailSaving())

	const methods = useForm<CreateEmployeeAdditionalDetailInterface>({
		resolver: yupResolver(additionalDetailsValidationSchema),
	})
	const {
		handleSubmit,
		reset,
		formState: { isDirty, isSubmitting },
	} = methods
	useEffect(() => {
		selected && dispatch(getAdditionalDataByEmployeeId(selected as string))
	}, [selected])

	useEffect(() => {
		if (additionalData) reset(additionalData as CreateEmployeeAdditionalDetailInterface)
		else reset(additionalDetailsInitialValues)
	}, [additionalData?._id])

	const onSubmit: SubmitHandler<CreateEmployeeAdditionalDetailInterface> = data =>
		dispatch(saveAdditionalDetailData({ ...data, employee: selected }))

	return (
		<div>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Card size="small" title="Additional Details">
						<div className="space-y-2">
							<div>
								<FormInputAntd type="number" name="utrNumber" label="UTR Number" />
								<FormInputAntd type="text" name="shortCode" label="Short Code" />
							</div>
							<AllowedTo perform={Permissions.UPDATE_EMPLOYEE_ADDITIONAL_DETAILS}>
								<AntdButton
									loading={isSavingAdditionalData}
									disabled={!isDirty || isSubmitting}
									htmlType="submit"
								>
									{typeof additionalData != "object" ? "Save" : "Update"}
								</AntdButton>
							</AllowedTo>
						</div>
					</Card>
				</form>
			</FormProvider>
		</div>
	)
}

export default AdditionalDetail
