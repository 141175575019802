import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetPBTermDetailsInterface, PBTermDetailsInterface, UpdatePBTermDetailsInterface } from "@type/payBill.types"
import { PBTermDetailsService } from "services"
import { AppThunk, RootState } from "store"
import { sortByProperty, sortDeepCopyByProperty, updateState } from "config/utils"

export interface PBTermDetailsStateInterface {
	list: null | Array<GetPBTermDetailsInterface>
	loading: boolean
	saving: boolean
}

const initialState: PBTermDetailsStateInterface = {
	list: null,
	loading: false,
	saving: false,
}

const PBTermDetailsSlice = createSlice({
	name: "PBTermDetails",
	initialState,
	reducers: {
		fetchingPBTermDetails: PBTermDetails => {
			PBTermDetails.loading = true
		},
		PBTermDetailsFetched: (PBTermDetails, action: PayloadAction<GetPBTermDetailsInterface[]>) => {
			PBTermDetails.loading = false
			PBTermDetails.list = action.payload
		},
		PBTermDetailsFetchingFailed: PBTermDetails => {
			PBTermDetails.loading = false
		},
		savingPBTermDetails: PBTermDetailsData => {
			PBTermDetailsData.saving = true
		},
		PBTermDetailsSaved: (PBTermDetails, action: PayloadAction<GetPBTermDetailsInterface>) => {
			PBTermDetails.saving = false
			const [UpdatedValue] = updateState(PBTermDetails.list, action.payload, "_id")
			PBTermDetails.list = UpdatedValue
		},
		PBTermDetailsSavingFailed: PBTermDetails => {
			PBTermDetails.saving = false
		},
	},
})

//REDUCER
export default PBTermDetailsSlice.reducer

//ACTIONS
const {
	fetchingPBTermDetails,
	PBTermDetailsFetched,
	PBTermDetailsFetchingFailed,
	savingPBTermDetails,
	PBTermDetailsSaved,
	PBTermDetailsSavingFailed,
} = PBTermDetailsSlice.actions

const getPBTermDetails =
	(cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingPBTermDetails())
			const { data: PBRuleResponse } = await PBTermDetailsService.getAllPBTermDetails()
			PBRuleResponse.sort(sortByProperty("name"))
			cb && cb(PBRuleResponse[0]?._id)
			dispatch(PBTermDetailsFetched(PBRuleResponse))
		} catch (error) {
			dispatch(PBTermDetailsFetchingFailed())
		}
	}

const getPBTermDetailsByRuleId =
	(id: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingPBTermDetails())
			const { data: PBRuleResponse } = await PBTermDetailsService.getPBTermDetailsById(id)
			dispatch(PBTermDetailsFetched(PBRuleResponse))
		} catch (error) {
			dispatch(PBTermDetailsFetchingFailed())
		}
	}

const savePBTermDetails =
	(PBTermDetailsData: Partial<UpdatePBTermDetailsInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingPBTermDetails())
			if (PBTermDetailsData?._id)
				data = await PBTermDetailsService.updatePBTermDetails(PBTermDetailsData._id, PBTermDetailsData)
			else data = await PBTermDetailsService.createPBTermDetails(PBTermDetailsData as PBTermDetailsInterface)
			const { data: PBRuleResponse } = data
			cb && cb(PBRuleResponse._id)
			dispatch(PBTermDetailsSaved(PBRuleResponse))
		} catch (error) {
			dispatch(PBTermDetailsSavingFailed())
		}
	}

export { getPBTermDetails, getPBTermDetailsByRuleId, savePBTermDetails }

//SELECTORS
const selectPBTermDetailsState = (state: RootState) => state.payBill.PBTermDetails
const isPBTermDetailsLoading = () => (state: RootState) => selectPBTermDetailsState(state).loading
const isPBTermDetailsSaving = () => (state: RootState) => selectPBTermDetailsState(state).saving
const selectPBTermDetailsList = createSelector(
	(state: RootState) => state.payBill.PBTermDetails.list,
	list => {
		return sortDeepCopyByProperty(list, "name") as GetPBTermDetailsInterface[]
	},
)

const selectFilteredPBTermDetailsList = (PBTermId: string) => (state: RootState) =>
	state.payBill.PBTermDetails.list?.filter(termDetails => termDetails.PBTerm === PBTermId) || []

const selectPBTermDetailsById = (PBRuleId: string | null) => (state: RootState) =>
	state.payBill.PBTermDetails?.list?.find(PBTermDetails => PBTermDetails._id === PBRuleId)

export {
	selectPBTermDetailsList,
	selectFilteredPBTermDetailsList,
	selectPBTermDetailsState,
	selectPBTermDetailsById,
	isPBTermDetailsLoading,
	isPBTermDetailsSaving,
}
