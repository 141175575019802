import React, { useMemo } from "react"
import { useAppSelector } from "hooks"
import { selectEmployeeById } from "store/Employee/detail.slice"
import { selectSelectedEmployee } from "store/globalState.slice"
import { ConfigProvider, Statistic } from "antd-v5"

const EmployeeTopBar: React.FC = () => {
	const selected = useAppSelector(selectSelectedEmployee)

	const user = useAppSelector(selectEmployeeById(selected))

	const data: { title: string; value: string | undefined; className?: string }[] = useMemo(() => {
		return [
			{ title: "PIN", value: user?.pin },
			{ title: "App Username", value: user?.userName },
			{ title: "First Name", value: user?.firstName },
			{ title: "Last Name", value: user?.lastName },
			{ title: "Short Name", value: user?.shortName },
			{ title: "City", value: user?.city },
			{ title: "Postal Code", value: user?.postalCode },
			{ title: "Mobile", value: user?.mobile },
			// { title: "Address Line 1", value: user?.addressLine1 },
			// { title: "Address Line 2", value: user?.addressLine2 },
			// { title: "Telephone", value: user?.telephone },
			// { title: "Email", value: user?.email },
			// { title: "County", value: user?.county?.name },
		]
	}, [user])

	return (
		<ConfigProvider
			prefixCls="ant5"
			theme={{
				components: {
					Statistic: {
						titleFontSize: 12,
						contentFontSize: 12,
						marginXXS: 0,
					},
				},
			}}
		>
			<div className="grid grid-cols-8">
				{data.map(({ title, value, className = "col-span-1" }) => (
					<Statistic
						key={title}
						title={title}
						value={value || "N/A"}
						className={className}
						formatter={val => val}
					/>
				))}
			</div>
		</ConfigProvider>
	)
}

export default EmployeeTopBar
