import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CreateServiceInterface } from "@type/employees.types"
import { AppThunk, RootState } from "store"
import { serviceService } from "services"
import { updateState } from "config/utils"
import { updateEmployeeByEmployeeIdAndIsActive } from "./detail.slice"
export interface ServiceStateInterface {
	list: Array<CreateServiceInterface>
	loading: boolean
	saving: boolean
}

const initialState: ServiceStateInterface = {
	list: [],
	loading: false,
	saving: false,
}

const serviceSlice = createSlice({
	name: "service",
	initialState,
	reducers: {
		requestServices: service => {
			service.loading = true
		},
		receiveServices: (service, action: PayloadAction<CreateServiceInterface[]>) => {
			service.loading = false
			service.list = action.payload
		},
		receiveServicesFailed: service => {
			service.loading = false
		},
		requestSavingService: serviceData => {
			serviceData.saving = true
		},
		SavingServiceSuccess: (service, action: PayloadAction<CreateServiceInterface>) => {
			service.saving = false
			const [UpdatedValue, NewAdded] = updateState(service.list, action.payload, "_id")

			if (NewAdded) {
				service.list = UpdatedValue
			}
		},
		SavingServiceSuccessFail: service => {
			service.saving = false
		},
	},
})

//REDUCER
export default serviceSlice.reducer

//ACTIONS
const {
	requestServices,
	receiveServices,
	receiveServicesFailed,
	requestSavingService,
	SavingServiceSuccess,
	SavingServiceSuccessFail,
} = serviceSlice.actions

const getServices =
	(employeeId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(requestServices())
			const { data: serviceResponse } = await serviceService.getServiceById(employeeId)
			dispatch(receiveServices(serviceResponse))
		} catch (error) {
			dispatch(receiveServicesFailed())
		}
	}

const saveService =
	(serviceData: CreateServiceInterface): AppThunk =>
	async dispatch => {
		try {
			let data = null
			dispatch(requestSavingService())
			if (serviceData._id) {
				data = await serviceService.updateService(serviceData._id, serviceData)
			} else {
				data = await serviceService.createService(serviceData)
			}
			const { data: serviceResponse } = data
			dispatch(SavingServiceSuccess(serviceResponse))

			dispatch(
				updateEmployeeByEmployeeIdAndIsActive({
					employeeId: serviceResponse.employee,
					isActive: new Date(serviceResponse.finishDate) > new Date(),
				}),
			)
		} catch (error) {
			dispatch(SavingServiceSuccessFail())
		}
	}

export { getServices, saveService }

//SELECTORS
const selectServiceState = (state: RootState) => state.employee.service
const selectServiceList = () => (state: RootState) => selectServiceState(state).list || []
const selectLoadingService = () => (state: RootState) => selectServiceState(state).loading
const selectSavingService = () => (state: RootState) => selectServiceState(state).saving

const selectServiceById = (serviceId: string | null) => (state: RootState) =>
	selectServiceList()(state)?.find(service => service._id === serviceId)

export { selectServiceList, selectServiceState, selectLoadingService, selectSavingService, selectServiceById }
