import { updateState } from "config/utils"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { AppThunk, RootState } from ".."
import { locationIncidentsService } from "services/locations/locationIncidents.service"
import { LocationIncidentInterface } from "@type/locations.types"
import { IncidentInterface } from "@type/workMonitor.types"

export interface locationIncidentsInterface {
	list: LocationIncidentInterface[]
	shiftIncidentsList: IncidentInterface[]
	loading: boolean
	saving: boolean
}

const initialState: locationIncidentsInterface = {
	list: [],
	shiftIncidentsList: [],
	loading: false,
	saving: false,
}

const locationIncidentsSlice = createSlice({
	name: "locationIncidents",
	initialState,
	reducers: {
		fetchingIncidents: incidents => {
			incidents.loading = true
		},
		savingIncidents: incidents => {
			incidents.saving = true
		},
		fetchedIncidents: (incidents, action: PayloadAction<LocationIncidentInterface[]>) => {
			incidents.loading = false
			incidents.list = action.payload
		},
		savedIncidents: (incidents, action: PayloadAction<LocationIncidentInterface>) => {
			incidents.saving = false
			const [updatedState] = updateState(incidents.list, action.payload, "_id")
			incidents.list = updatedState
		},
		savedShiftIncidents: (incidents, action: PayloadAction<IncidentInterface[]>) => {
			incidents.saving = false
			incidents.shiftIncidentsList = action.payload
		},
		fetchingIncidentsFailed: incidents => {
			incidents.loading = false
		},
		savingIncidentsFailed: incidents => {
			incidents.saving = false
		},
		clearLocationIncidents: incidents => {
			incidents.list = []
		},
	},
})

export default locationIncidentsSlice.reducer

export const {
	fetchingIncidents,
	savingIncidents,
	fetchedIncidents,
	savedIncidents,
	fetchingIncidentsFailed,
	savingIncidentsFailed,
	clearLocationIncidents,
	savedShiftIncidents,
} = locationIncidentsSlice.actions

const saveIncident =
	(payload: any, cb?: () => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingIncidents())
			const { _id, ...rest } = payload
			const { data: response } = _id
				? await locationIncidentsService.updateLocationIncident(_id, rest)
				: await locationIncidentsService.saveLocationIncident(rest)
			dispatch(savedIncidents(response))
			cb && cb()
		} catch (error) {
			dispatch(savingIncidentsFailed())
		}
	}

const getLocationIncidents =
	(locationId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingIncidents())
			const { data: response } = await locationIncidentsService.getLocationIncidents(locationId)
			dispatch(fetchedIncidents(response))
		} catch (error) {
			dispatch(fetchingIncidentsFailed())
		}
	}

const getLocationShiftIncidents =
	(locationId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingIncidents())
			const { data: response } = await locationIncidentsService.getLocationShiftIncidents(locationId)
			dispatch(savedShiftIncidents(response))
		} catch (error) {
			dispatch(fetchingIncidentsFailed())
		}
	}

export { saveIncident, getLocationIncidents, getLocationShiftIncidents }

const selectLocationIncidentsState = (state: RootState) => state.location.incidents
const isLocationIncidentsLoading = () => (state: RootState) => selectLocationIncidentsState(state).loading
const isLocationIncidentsSaving = () => (state: RootState) => selectLocationIncidentsState(state).saving
const locationIncidentsList = () => (state: RootState) => selectLocationIncidentsState(state).list
const locationShiftIncidentsList = () => (state: RootState) => selectLocationIncidentsState(state).shiftIncidentsList

export {
	selectLocationIncidentsState,
	isLocationIncidentsLoading,
	isLocationIncidentsSaving,
	locationIncidentsList,
	locationShiftIncidentsList,
}
