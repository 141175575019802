import { createContext } from "react"
import { EmployeeDocumentInterface } from "@type/employees.types"

export interface DocumentContextProps {
	handleRow: (e: EmployeeDocumentInterface | null) => void
	document: EmployeeDocumentInterface | undefined
}

const DocumentContext = createContext<DocumentContextProps | null>(null)

export default DocumentContext
