import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { fieldsError } from "config/utils"
import { InputProps } from "antd-v5"
import InputLabel from "./InputLabel"
import AntdInput from "./AntdInput"

export type CustomFormInputType = "text" | "textarea" | "number" | "password"

interface Props extends InputProps {
	containerClass?: string
	errorClass?: string
	disabled?: boolean
	label?: string
	loading?: boolean
	type: CustomFormInputType
	hidden?: boolean
}

const FormInputAntd: React.FC<Props> = ({
	containerClass = "w-full",
	name = "",
	errorClass = "",
	label,
	type,
	hidden,
	...rest
}) => {
	const {
		control,
		formState: { errors },
	} = useFormContext()
	if (hidden) return <></>
	return (
		<div className={`flex w-full flex-col ${containerClass}`}>
			<InputLabel label={label || ""} />
			<Controller
				control={control}
				name={name}
				render={state => <AntdInput {...rest} {...state.field} type={type} />}
			/>
			{fieldsError(name, errors) && (
				<p className={`mt-1 ml-1 text-sm text-danger ${errorClass}`}>{fieldsError(name, errors)?.message}</p>
			)}
		</div>
	)
}

export default FormInputAntd
