import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetPBCodeInterface, PBCodeInterface, UpdatePBCodeInterface } from "@type/payBill.types"
import { PBCodesService } from "services"
import { AppThunk, RootState } from "store"
import { sortByProperty, sortDeepCopyByProperty, updateState } from "config/utils"
export interface PBCodesStateInterface {
	list: null | Array<GetPBCodeInterface>
	loading: boolean
	saving: boolean
}

const initialState: PBCodesStateInterface = {
	list: null,
	loading: false,
	saving: false,
}

const PBCodesSlice = createSlice({
	name: "PBCodes",
	initialState,
	reducers: {
		fetchingPBCodes: PBCode => {
			PBCode.loading = true
		},
		PBCodesFetched: (PBCode, action: PayloadAction<GetPBCodeInterface[]>) => {
			PBCode.loading = false
			PBCode.list = action.payload
		},
		PBCodesFetchingFailed: PBCode => {
			PBCode.loading = false
		},
		savingPBCode: PBCodeData => {
			PBCodeData.saving = true
		},
		PBCodeSaved: (PBCode, action: PayloadAction<GetPBCodeInterface>) => {
			PBCode.saving = false
			const [UpdatedValue] = updateState(PBCode.list, action.payload, "_id")
			PBCode.list = UpdatedValue
		},
		PBCodeSavingFailed: PBCode => {
			PBCode.saving = false
		},
	},
})

//REDUCER
export default PBCodesSlice.reducer

//ACTIONS
const { fetchingPBCodes, PBCodesFetched, PBCodesFetchingFailed, savingPBCode, PBCodeSaved, PBCodeSavingFailed } =
	PBCodesSlice.actions

const getPBCodes =
	(PBCodeType: string, cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingPBCodes())
			const { data: PBCodeResponse } = await PBCodesService.getAllPBCodes(PBCodeType)
			PBCodeResponse.sort(sortByProperty("name"))
			cb && cb(PBCodeResponse[0]?._id)
			dispatch(PBCodesFetched(PBCodeResponse))
		} catch (error) {
			dispatch(PBCodesFetchingFailed())
		}
	}

const savePBCode =
	(PBCodeData: Partial<UpdatePBCodeInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingPBCode())
			if (PBCodeData?._id) data = await PBCodesService.updatePBCode(PBCodeData._id, PBCodeData)
			else data = await PBCodesService.createPBCode(PBCodeData as PBCodeInterface)
			const { data: PBCodeResponse } = data
			cb && cb(PBCodeResponse._id)
			dispatch(PBCodeSaved(PBCodeResponse))
		} catch (error) {
			dispatch(PBCodeSavingFailed())
		}
	}

export { getPBCodes, savePBCode }

//SELECTORS
const selectPBCodeState = (state: RootState) => state.payBill.PBCodes
const isPBCodeLoading = () => (state: RootState) => selectPBCodeState(state).loading
const isPBCodeSaving = () => (state: RootState) => selectPBCodeState(state).saving
const selectPBCodeList = createSelector(
	(state: RootState) => state.payBill.PBCodes.list,
	list => {
		return sortDeepCopyByProperty(list, "name") as GetPBCodeInterface[]
	},
)
const selectPBCodeDropdownList = createSelector(
	(state: RootState) => state.payBill.PBCodes.list,
	list => {
		return sortDeepCopyByProperty(list?.map(item => ({ label: item.name, value: item._id })) || [], "label")
	},
)
const selectPBCodeIdToLabel = createSelector(
	(state: RootState) => state.payBill.PBCodes.list,
	list => {
		if (list) {
			const idToLabel: { [key: string]: string } = {}
			list?.forEach(item => (idToLabel[item._id] = item.name))
			return idToLabel
		}
		return {}
	},
)

const selectPBCodeById = (PBCodeId: string | null) => (state: RootState) =>
	state.payBill.PBCodes?.list?.find(PBCode => PBCode._id === PBCodeId)

export {
	selectPBCodeList,
	selectPBCodeDropdownList,
	selectPBCodeState,
	selectPBCodeById,
	isPBCodeLoading,
	isPBCodeSaving,
	selectPBCodeIdToLabel,
}
