import React from "react"
import { Switch } from "antd-v5"
import { SwitchProps } from "antd/lib/switch"
import cx from "classnames"
import { twMerge } from "tailwind-merge"

interface Props extends Omit<SwitchProps, "onClick"> {
	label?: string
	order?: "Switch First" | "Label First"
	onClick?: () => void
}
const AntdSwitch: React.FC<Props> = ({ label, ...rest }) => {
	return (
		<div
			className={twMerge(
				cx(`flex select-none space-x-2 `, {
					"flex-row-reverse space-x-reverse": rest?.order === "Switch First",
				}),
			)}
			onClick={() => rest?.onClick?.()}
		>
			<label className="cursor-pointer">{label}</label>
			<Switch {...rest} />
		</div>
	)
}

export default AntdSwitch
