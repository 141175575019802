import { AntdInput, AntdButton } from "components"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useAppSelector, useAppDispatch } from "hooks"
import { GetLocationQRCodeInterface } from "@type/locations.types"
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons"
import {
	isSavingLocation,
	saveLocationQRCode,
	selectLocationById,
	selectQRCodeByLocationId,
} from "store/Location/locationDetails.slice"
import QRCodes from "./QRCodes"
import { useReactToPrint } from "react-to-print"
import { pageStyleForQR } from "config/constants"
import { selectSelectedLocation } from "store/globalState.slice"
import { Divider, Typography } from "antd"
import QRModal from "./QRModal"

const QRCodeTab: React.FC = () => {
	const dispatch = useAppDispatch()
	const [name, setName] = useState<string[]>([])
	const [openModal, setOpenModal] = useState(false)
	const [showInputs, setShowInputs] = useState(false)
	const [count, setCount] = useState(1)
	const [selectedQR, setSelectedQR] = useState<GetLocationQRCodeInterface>()
	const selected = useAppSelector(selectSelectedLocation)
	const selectedLocation = useAppSelector(selectLocationById(selected))
	const locationQRCodes = useAppSelector(selectQRCodeByLocationId(selected))
	const locationSaving = useAppSelector(isSavingLocation())
	const componentRef = useRef(null)

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: pageStyleForQR,
		documentTitle: selectedLocation?.name,
	})

	const submitHandler = () => {
		if (selected && name.length) {
			dispatch(
				saveLocationQRCode(selected, {
					...(selectedQR ? { _id: selectedQR._id } : {}),
					name,
				}),
			)
			setName([])
			setSelectedQR(undefined)
		}
	}

	useEffect(() => {
		if (selectedQR?.name) setName([selectedQR.name.toString()])
		else setName([])
	}, [selectedQR])

	const inputSize = useMemo(() => {
		if (name.length > count)
			setName(prev => {
				prev.splice(count)
				return prev
			})
		setShowInputs(false)
		const inputs = []
		for (let index = 0; index < count; index++) {
			inputs.push(index)
		}
		return inputs
	}, [count])

	useEffect(() => {
		setName([])
		setOpenModal(false)
	}, [selected, locationQRCodes])

	return (
		<>
			<div className="w-1/4 space-y-6 py-2">
				{selectedQR ? (
					<>
						<AntdInput
							label="Name"
							type="text"
							value={name[0]}
							onChange={({ target: { value } }) => setName([value])}
						/>
						<AntdButton onClick={submitHandler} loading={locationSaving} disabled={locationSaving}>
							Update
						</AntdButton>
					</>
				) : (
					<>
						<AntdInput
							label="Quantity"
							type="number"
							min="1"
							max="20"
							value={count}
							onChange={value => setCount(Number(value))}
						/>
						<AntdButton
							onClick={() => {
								setShowInputs(true)
								setOpenModal(true)
							}}
							loading={locationSaving}
							disabled={locationSaving}
							icon={<PlusOutlined />}
						>
							Create New
						</AntdButton>
					</>
				)}
			</div>
			<QRModal
				showInputs={showInputs}
				inputSize={inputSize}
				handleModal={setOpenModal}
				openModal={openModal}
				name={name}
				handleInput={(value, index) =>
					setName(prev => {
						prev.splice(index, 1, value)
						return prev
					})
				}
				submitHandler={submitHandler}
			/>
			{!!locationQRCodes?.length && (
				<div className="py-6">
					<Divider orientation="left" plain>
						<Typography.Title level={5}>QR CODES</Typography.Title>
					</Divider>
					<div ref={componentRef}>
						<div id="titleHolder" className="hidden">
							<div id="title">Time Trail</div>
							<div id="locationName">{selectedLocation?.name}</div>
						</div>
						<div
							className=" my-5 grid grid-cols-3 gap-y-5 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6"
							id="qrList"
						>
							{locationQRCodes?.map((currentQRCode, key) => {
								return (
									<QRCodes
										key={key}
										selectedQR={selectedQR}
										currentQRCode={currentQRCode}
										index={key}
										setSelectedQR={(qr: GetLocationQRCodeInterface | undefined) =>
											setSelectedQR(qr)
										}
										selected={selected}
									/>
								)
							})}
						</div>
					</div>
					<AntdButton onClick={handlePrint} icon={<DownloadOutlined />}>
						Download All
					</AntdButton>
				</div>
			)}
		</>
	)
}
export default QRCodeTab
