import axios from "axios"
import { CreatePayGroupsInterface, GetPayGroupsInterface } from "@type/payGroup.types"

const ROOT_PATH = "/paygroups"
export default class PayGroups {
	static createPayGroups = (payGroups: CreatePayGroupsInterface) =>
		axios.post<GetPayGroupsInterface>(ROOT_PATH, payGroups)

	static updatePayGroup = (id: string, payGroup: Partial<GetPayGroupsInterface>) =>
		axios.put<GetPayGroupsInterface>(`${ROOT_PATH}/${id}`, payGroup)

	static getAllPayGroups = () => axios.get<GetPayGroupsInterface[]>(`${ROOT_PATH}`)
}
