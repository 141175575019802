import React, { useEffect } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAppSelector, useAppDispatch } from "hooks"
import { DateField, FormSelectAntd, FormInputAntd, AntdButton } from "components"
import {
	getEmployeesHolidayByServiceId,
	saveEmployeeHolidayData,
	selectEmployeeHolidayData,
	selectEmployeeHolidaySaving,
} from "store/Employee/Holidays/holidays.slice"
import { getServices, selectServiceList } from "store/Employee/service.slice"
import { holidayInitialValues, holidayValidationSchema } from "Schema"
import { EmployeeHolidayInterface } from "@type/employees.types"
import { convertIOSDateToHTMLFormat } from "config/utils"
import { GLOBAL_DATE_FORMAT, Permissions } from "config/constants"
import { AllowedTo } from "react-abac"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"
import { selectSelectedEmployee } from "store/globalState.slice"

interface HolidayFormHandler extends EmployeeHolidayInterface {}

const Holidays = () => {
	const dispatch = useAppDispatch()

	const selected = useAppSelector(selectSelectedEmployee)

	const holiday = useAppSelector(selectEmployeeHolidayData())
	const isHolidaySaving = useAppSelector(selectEmployeeHolidaySaving())

	const services = useAppSelector(selectServiceList()) || []
	const methods = useForm<HolidayFormHandler>({
		resolver: yupResolver(holidayValidationSchema),
	})
	const {
		reset,
		setValue,
		handleSubmit,
		watch,
		formState: { isDirty },
	} = methods

	useEffect(() => {
		selected && dispatch(getServices(selected))
	}, [selected])

	useEffect(() => {
		if (services?.length) {
			setValue("employeeService", services[0]._id as string)
			dispatch(getEmployeesHolidayByServiceId(services[0]._id as string))
		}
	}, [services])

	useEffect(() => {
		if (holiday?._id) {
			reset({ ...holiday })
		} else {
			reset(holidayInitialValues)
		}
	}, [holiday?._id])

	const onSubmit: SubmitHandler<HolidayFormHandler> = data =>
		dispatch(saveEmployeeHolidayData({ ...data, employee: selected as string }))

	return (
		<>
			<div className="flex flex-col">
				<AuditTrail id={holiday?._id} name={Entities.EMPLOYEE_HOLIDAY} />
			</div>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="flex flex-col p-2">
						<div className="flex w-1/4 flex-col gap-y-2">
							<FormSelectAntd
								disabled={!services.length}
								label="Employee Service"
								onChange={e => {
									dispatch(getEmployeesHolidayByServiceId(e.target.value))
								}}
								name="employeeService"
								options={services?.map(({ _id, startDate }) => ({
									label: convertIOSDateToHTMLFormat(startDate, GLOBAL_DATE_FORMAT),
									value: _id as string,
								}))}
							/>
							<DateField
								label="Holiday Year Start Date"
								setValue={setValue}
								watch={watch}
								name={"holidayYearStartDate"}
							/>
							<FormInputAntd label="Yearly Entitlement" type="number" name="yearlyEntitlement" />
							<FormInputAntd label="Carried Forward Days" type="number" name="carriedForwardDays" />
						</div>
						<AllowedTo perform={Permissions.UPDATE_EMPLOYEE_HOLIDAY}>
							<AntdButton
								loading={isHolidaySaving}
								disabled={!isDirty}
								htmlType="submit"
								className="my-2 w-min"
							>
								{holiday ? "Update" : "Save"}
							</AntdButton>
						</AllowedTo>
					</div>
				</form>
			</FormProvider>
		</>
	)
}

export default Holidays
