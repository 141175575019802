import { GetSchedulesInterface } from "@type/schedules.types"
import { calculateTimeDifferenceWrtDst, timeInHoursMinutesfromSeconds } from "config/utils"

const useGetScheduleCurrentWeekHours = (schedules: GetSchedulesInterface[]) => {
	const totalAllocatedTime: number[] = []
	const totalUnAllocatedTime: number[] = []
	if (schedules.length) {
		schedules
			.filter(schedule => !schedule.isDeleted)
			.forEach(({ startTime = new Date().toISOString(), finishTime = new Date().toISOString(), employee }) => {
				employee
					? totalAllocatedTime.push(calculateTimeDifferenceWrtDst(startTime, finishTime, "seconds"))
					: totalUnAllocatedTime.push(calculateTimeDifferenceWrtDst(startTime, finishTime, "seconds"))
			})

		const showTotalAllocatedTime = timeInHoursMinutesfromSeconds(totalAllocatedTime)

		const showTotalUnAllocatedTime = timeInHoursMinutesfromSeconds(totalUnAllocatedTime)

		const showTotalTime = {
			totalTime: +Number(showTotalAllocatedTime.totalTime) + Number(showTotalUnAllocatedTime.totalTime),
		}

		return {
			showTotalTime,
			showTotalAllocatedTime,
			showTotalUnAllocatedTime,
		}
	} else {
		return {
			showTotalUnAllocatedTime: { totalTime: 0 },
			showTotalTime: { totalTime: 0 },
			showTotalAllocatedTime: { totalTime: 0 },
		}
	}
}

export default useGetScheduleCurrentWeekHours
