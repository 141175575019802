import axios from "axios"
import { CreateLocationQRCallInterface, GetLocationCallTimeInterface } from "@type/locations.types"

const ROOT_PATH = "locations/qr-call"
export default class LocationQRCallService {
	static createQRCall = (qrCalls: CreateLocationQRCallInterface) => {
		return axios.post<GetLocationCallTimeInterface[]>(ROOT_PATH, qrCalls)
	}

	static getPostQRCalls = (postId: string) => axios.get<Array<GetLocationCallTimeInterface>>(`${ROOT_PATH}/${postId}`)

	static deleteQRCalls = (ids: string[]) =>
		axios.delete<GetLocationCallTimeInterface>(`${ROOT_PATH}`, { data: { ids } })
}
