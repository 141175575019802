import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetCustomersInterface, CreateCustomersInterface } from "@type/customers.types"
import { customersService } from "services"
import { AppThunk, RootState } from "store"
import { sortByProperty, updateState } from "config/utils"

export interface CustomersStateInterface {
	list: GetCustomersInterface[]
	loading: boolean
	saving: boolean
}

const initialState: CustomersStateInterface = {
	list: [],
	loading: false,
	saving: false,
}

const customersSlice = createSlice({
	name: "customers",
	initialState,
	reducers: {
		fetchingCustomers: customer => {
			customer.loading = true
		},
		customersFetched: (customer, action: PayloadAction<GetCustomersInterface[]>) => {
			customer.loading = false
			customer.list = action.payload
		},
		customersFetchingFailed: customer => {
			customer.loading = false
		},
		savingCustomer: customerData => {
			customerData.saving = true
		},
		customerSaved: (customer, action: PayloadAction<GetCustomersInterface>) => {
			customer.saving = false
			const [UpdatedValue] = updateState(customer.list, action.payload, "_id")
			customer.list = UpdatedValue
		},
		customerSavingFailed: customer => {
			customer.saving = false
		},
		clearCustomerState: customer => {
			customer.list = []
			customer.loading = false
			customer.saving = false
		},
	},
})

//REDUCER
export default customersSlice.reducer

//ACTIONS
export const {
	fetchingCustomers,
	customersFetched,
	customersFetchingFailed,
	savingCustomer,
	customerSaved,
	customerSavingFailed,
	clearCustomerState,
} = customersSlice.actions

const getCustomers =
	(cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingCustomers())
			const { data: customerResponse } = await customersService.getAllCustomers()
			cb && cb(customerResponse[0]?._id)
			dispatch(customersFetched(customerResponse))
		} catch (error) {
			dispatch(customersFetchingFailed())
		}
	}

const saveCustomer =
	(customerData: CreateCustomersInterface, organization: string, cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingCustomer())
			if (customerData._id) {
				data = await customersService.updateCustomer(customerData._id, customerData)
				data["data"]["organization"] = organization
			} else {
				data = await customersService.createCustomer(customerData, organization)
			}
			const { data: customerResponse } = data
			cb && cb(customerResponse._id)
			dispatch(customerSaved(customerResponse))
		} catch (error) {
			dispatch(customerSavingFailed())
		}
	}

export { getCustomers, saveCustomer }

//SELECTORS
const selectCustomerState = (state: RootState) => state.customers
const isCustomerLoading = () => (state: RootState) => selectCustomerState(state).loading
const isCustomerSaving = () => (state: RootState) => selectCustomerState(state).saving
const selectCustomerList = () => (state: RootState) => selectCustomerState(state).list
const selectCustomerOptions = (state: RootState) =>
	selectCustomerState(state)
		.list?.map(({ _id, name }) => ({ label: name, value: _id }))
		.sort(sortByProperty("label")) || []
const selectCustomerById = (customerId: string | null) => (state: RootState) =>
	selectCustomerList()(state)?.find(customer => customer._id === customerId)

export {
	selectCustomerList,
	selectCustomerOptions,
	selectCustomerState,
	selectCustomerById,
	isCustomerLoading,
	isCustomerSaving,
}
