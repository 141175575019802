import React, { useState } from "react"
import { GetEmployeeBankDetailInterface } from "@type/employees.types"
import AdditionalDetail from "./AdditionalDetail"
import BankDetail from "./BankDetail"
import AdditionalDataTable from "./AdditionalDataTable"
import EmployeeBankContext from "./AdditionalDetailContext"
import { selectSelectedEmployee } from "store/globalState.slice"
import useAppSelector from "hooks/useAppSelector"

const AdditionDetail: React.FC = () => {
	const [bankDetail, setBankDetail] = useState<GetEmployeeBankDetailInterface | null>(null)

	const selected = useAppSelector(selectSelectedEmployee)

	const handleBankDetailHandler = (bankData: GetEmployeeBankDetailInterface | null) => {
		setBankDetail(bankData)
	}

	return (
		<>
			<EmployeeBankContext.Provider value={{ bankDetail, handleBankDetailHandler }}>
				<div className="flex flex-col gap-y-5">
					<div className="grid grid-cols-12 gap-x-5">
						<div className="col-span-5">
							<BankDetail selected={selected} />
						</div>
						<div className="col-span-3">
							<AdditionalDetail selected={selected} />
						</div>
					</div>
					<AdditionalDataTable />
				</div>
			</EmployeeBankContext.Provider>
		</>
	)
}

export default AdditionDetail
