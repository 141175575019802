import React from "react"

const FormSkeleton = () => {
	return (
		<div>
			<div className="flex animate-pulse flex-col gap-5 px-2.5 py-2.5">
				<div className="flex justify-around gap-5">
					<div className="h-7.5 w-full rounded-md bg-secondary-light"></div>
					<div className="h-7.5 w-full rounded-md bg-secondary-light"></div>
					<div className="h-7.5 w-full rounded-md bg-secondary-light"></div>
				</div>
				<div className="flex justify-around gap-5">
					<div className="h-7.5 w-full rounded-md bg-secondary-light"></div>
					<div className="h-7.5 w-full rounded-md bg-secondary-light"></div>
					<div className="h-7.5 w-full rounded-md bg-secondary-light"></div>
				</div>
				<div className="flex justify-around gap-5">
					<div className="h-7.5 w-full rounded-md bg-secondary-light"></div>
					<div className="h-7.5 w-full rounded-md bg-secondary-light"></div>
					<div className="h-7.5 w-full rounded-md bg-secondary-light"></div>
				</div>
			</div>
		</div>
	)
}

export default FormSkeleton
