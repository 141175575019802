import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from "@reach/combobox"
import usePlacesAutocomplete, { LatLng, getGeocode, getLatLng } from "use-places-autocomplete"
import { GoogleMapSelectedLocation } from "../types/geolocation.types"
import { forwardRef, useEffect, useImperativeHandle } from "react"

const getPositionFromAddress = async (address: string): Promise<LatLng> => {
	const results = await getGeocode({ address })
	const { lat, lng } = getLatLng(results[0])

	return { lat, lng }
}

interface props {
	setSelected: (coordinates: GoogleMapSelectedLocation | null) => void
	address: string
}

const PlacesAutocomplete = forwardRef(({ setSelected, address }: props, ref) => {
	const {
		ready,
		value,
		setValue,
		suggestions: { status, data },
		clearSuggestions,
	} = usePlacesAutocomplete({
		callbackName: "initMap",
		requestOptions: {
			componentRestrictions: {
				country: "uk",
			},
		},
	})

	const handleSelect = async (address: string) => {
		setValue(address, false)
		clearSuggestions()

		const { lat, lng } = await getPositionFromAddress(address)
		setSelected({ lat, lng, address })
	}

	const handleKeyPress = async (address: string) => {
		const { lat, lng } = await getPositionFromAddress(address)
		setSelected({ lat, lng, address })
		clearSuggestions()
	}

	useImperativeHandle(ref, () => ({
		clearInputField: () => {
			setValue("")
			clearSuggestions()
		},
	}))

	useEffect(() => {
		setValue(address)
	}, [address])

	return (
		<Combobox onSelect={handleSelect} style={{ width: "100%" }}>
			<ComboboxInput
				value={value}
				onChange={e => {
					if (!e.target.value) setSelected(null)
					setValue(e.target.value)
				}}
				onKeyDown={(e: any) => {
					if (e.key === "Enter") handleKeyPress(e.target.value)
				}}
				disabled={!ready}
				className="h-full w-full border border-dark py-1 px-2 placeholder:text-dark focus-within:outline-none"
				placeholder="Search an address"
			/>

			<ComboboxPopover style={{ zIndex: 10 }}>
				<ComboboxList className="divide-light-border cursor-pointer divide-y-[0.5px]  bg-slate-50">
					{status === "OK" &&
						data.map(({ place_id, description }) => {
							return (
								<ComboboxOption
									className=" px-2 py-1 hover:bg-slate-300"
									key={place_id}
									value={description}
								/>
							)
						})}
				</ComboboxList>
			</ComboboxPopover>
		</Combobox>
	)
})

export default PlacesAutocomplete
