import React from "react"
import { FieldError, UseFormRegister, FieldErrors } from "react-hook-form"

import TextArea, { TextAreaProps } from "./TextArea"

interface Props extends TextAreaProps {
	register: UseFormRegister<any>
	errors?: { [key: string]: FieldError | undefined } | FieldErrors
	containerClass?: string
}

const FormInput: React.FC<Props> = ({
	register,
	errors = {},
	containerClass,

	name = "",
	...rest
}) => {
	return (
		<div className={`flex flex-col ${containerClass}`}>
			<TextArea {...rest} {...register(name)} />
			{errors[name] && <p className="mt-1 ml-1 text-sm text-danger">{errors[name]?.message}</p>}
		</div>
	)
}

export default FormInput
