import { useState } from "react"
import IncidentComments from "../services/incidentComments/incidentComments.service"
import { CreateIncidentCommentDto, IncidentCommentInterface } from "@type/workMonitor.types"

const useIncidentComments = () => {
	const [incidentComments, setIncidentComments] = useState<IncidentCommentInterface[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [isSaving, setIsSaving] = useState(false)

	const fetchIncidentCommentsByIncident = async (incident: string) => {
		try {
			setIsLoading(true)
			const { data: incidentCommentsResponse } = await IncidentComments.getAllByIncidentId(incident)
			setIncidentComments(incidentCommentsResponse)
			setIsLoading(false)
		} catch {
			setIsLoading(false)
		}
	}

	const createIncidentComment = async (data: CreateIncidentCommentDto, cb?: any) => {
		try {
			setIsSaving(true)
			const { data: incidentCommentsResponse } = await IncidentComments.create(data)
			setIncidentComments([...incidentComments, incidentCommentsResponse])
			cb?.()
			setIsSaving(false)
		} catch {
			setIsSaving(false)
		}
	}

	return {
		isSaving,
		isLoading,
		incidentComments,
		createIncidentComment,
		fetchIncidentCommentsByIncident,
	}
}

export default useIncidentComments
