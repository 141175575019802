import axios from "axios"
import { CreateServiceInterface } from "@type/employees.types"

const SERVICE_PATH = "employees/services"

export default class ServiceService {
	static createService = (service: CreateServiceInterface) =>
		axios.post<CreateServiceInterface>(SERVICE_PATH, service)

	static updateService = (serviceId: string, service: CreateServiceInterface) =>
		axios.put<CreateServiceInterface>(`${SERVICE_PATH}/${serviceId}`, service)

	static getAllServices = () => axios.get(SERVICE_PATH)

	static getServiceById = (id: string) => axios.get(`${SERVICE_PATH}?employee=${id}`)
}
