import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAppDispatch, useAppSelector } from "hooks"

import { AntdButton, FormInput, FormSelect, Tab, Tabs } from "components"

import {
	getLocationTimeCapture,
	getLocationTimeCaptureNoPost,
	isSavingLocationTimeCapture,
	saveLocationCall,
	saveLocationTimeCapture,
	selectLocationTimeCaptureList,
} from "store/Location/locationTimeCapture.slice"
import { getLocationContractsByLocationId, selectLocationContractsData } from "store/Location/locationContracts.slice"
import {
	getLocationPostsByContractId,
	clearPostList,
	selectLocationPostsData,
} from "store/Location/locationPosts.slice"

import { CreateTimeCaptureInterface, GetTimeCaptureInterface } from "@type/locations.types"
import {
	LocationSimpleTimeSchema,
	timeCapturesInitialValues,
	timeCaptureValidationSchema,
} from "Schema/Locations/locationSchema"
import { convertIOSDateToHTMLFormat } from "config/utils"
import CallTime from "./CallTime"
import TimeCapturesContext from "./TimeCapturesContext"
import { clearCallTimeList } from "store/Location/locationCallTime.slice"
import { AllowedTo } from "react-abac"
import { Ongoing, Permissions } from "config/constants"
import InventoryCall from "./InventoryCall"
import QRCall from "./QRCall"
import { selectSelectedLocation } from "store/globalState.slice"
import { Switch } from "antd"

interface TimeCaptureFormHandle extends CreateTimeCaptureInterface {}

export enum OverdueActions {
	ALARM = "Alarm",
	TAKE = "Take",
	OUTBOUND = "Outbound",
}

export enum OutboundTelephones {
	EMPLOYEE_MOBILE = "Employee Mobile",
}
// const ApplicableOutboundTelephones = [{ label: "Employee Mobile", value: OutboundTelephones.EMPLOYEE_MOBILE }]

const simpleHoursOptions = [
	{ label: "Select Interval", value: "" },
	{ label: "Half an Hour", value: "00:30" },
	{ label: "Hour", value: "01:00" },
]
type callType = "checkCall" | "inventoryCall" | "qrCall"
const simpleSettingsData: { title: string; prop: callType }[] = [
	{ title: "Check Call", prop: "checkCall" },
	{ title: "Inventory Call", prop: "inventoryCall" },
	{ title: "QR Scan", prop: "qrCall" },
]

interface SimpleFormDetails {
	checkCall: string
	inventoryCall: string
	qrCall: string
	parent?: string
}
const TimeCaptures = () => {
	const dispatch = useAppDispatch()
	const contractsList = useAppSelector(selectLocationContractsData()) || []
	const postsList = useAppSelector(selectLocationPostsData()) || []
	const timeCapture = useAppSelector(selectLocationTimeCaptureList())
	const isSaving = useAppSelector(isSavingLocationTimeCapture())
	const [customSettings, setCustomSettings] = useState(false)
	const selected = useAppSelector(selectSelectedLocation)

	const {
		reset,
		setValue,
		getValues,
		handleSubmit,
		register,
		formState: { errors, isSubmitting, isDirty },
	} = useForm<TimeCaptureFormHandle>({
		resolver: yupResolver(timeCaptureValidationSchema),
		defaultValues: timeCapturesInitialValues,
	})

	const {
		watch: watchSimple,
		handleSubmit: handleSubmitSimple,
		reset: resetSimple,
		register: registerSimple,
		formState: { errors: errorsSimple },
	} = useForm<SimpleFormDetails>({
		resolver: yupResolver(LocationSimpleTimeSchema),
	})
	const onSubmit: SubmitHandler<TimeCaptureFormHandle> = data => {
		dispatch(saveLocationTimeCapture(data as GetTimeCaptureInterface))
	}

	const onSubmitSimple: SubmitHandler<SimpleFormDetails> = data => {
		const post = getValues("post")
		if (post) {
			const payload = {
				checkCall: {},
				inventoryCall: {},
				qrCall: {},
				post,
			}
			if (data.checkCall) {
				const checkCallTime = data.checkCall.split(":")
				payload.checkCall = { hours: Number(checkCallTime[0]), minutes: Number(checkCallTime[1]) }
			}
			if (data.inventoryCall) {
				const inventoryCallTime = data.inventoryCall.split(":")
				payload.inventoryCall = { hours: Number(inventoryCallTime[0]), minutes: Number(inventoryCallTime[1]) }
			}
			if (data.qrCall) {
				const qrCallTime = data.qrCall.split(":")
				payload.qrCall = { hours: Number(qrCallTime[0]), minutes: Number(qrCallTime[1]) }
			}
			dispatch(
				saveLocationCall(payload, () => {
					resetSimple({ checkCall: "", inventoryCall: "", qrCall: "" })
				}),
			)
		}
	}

	useEffect(() => {
		selected && dispatch(getLocationContractsByLocationId(selected))
	}, [selected])

	useEffect(() => {
		if (contractsList?.length) {
			setValue("contract", contractsList[0]._id)
			dispatch(getLocationPostsByContractId(contractsList[0]._id))
		} else if (contractsList.length == 0) {
			dispatch(clearPostList())
		}
	}, [contractsList])

	useEffect(() => {
		if (postsList?.length) {
			setValue("post", postsList[0]._id)
			dispatch(getLocationTimeCapture(postsList[0]._id))
		} else if (postsList.length == 0) {
			setValue("post", "")
			dispatch(clearCallTimeList())
			dispatch(getLocationTimeCaptureNoPost())
			reset(timeCapturesInitialValues)
		}
	}, [postsList?.length])

	useEffect(() => {
		if (timeCapture) {
			reset(timeCapture)
		} else {
			reset(timeCapturesInitialValues)
		}
	}, [timeCapture])

	return (
		<TimeCapturesContext.Provider value={{ selectedPost: getValues("post") }}>
			<div className="mt-4 flex flex-col gap-2.75 px-2.5 py-1.25">
				<div>
					<label className="mr-2 text-md font-normal">Custom settings</label>
					<Switch
						className="mb-12"
						checked={customSettings}
						onChange={e => {
							setCustomSettings(e)
						}}
					></Switch>
				</div>
				{customSettings ? (
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="flex flex-col gap-2.5">
							<FormSelect
								className="mr-5.5 mt-1.25"
								label="Contract"
								register={register}
								name="contract"
								errors={errors}
								disabled={!contractsList.length}
								onChange={e => {
									dispatch(getLocationPostsByContractId(e.target.value))
								}}
								options={contractsList?.map(({ _id, finishDate, startDate }) => ({
									label: finishDate
										? `${convertIOSDateToHTMLFormat(startDate)}-${convertIOSDateToHTMLFormat(
												finishDate,
										  )}`
										: `${convertIOSDateToHTMLFormat(startDate)}- ${Ongoing}`,
									value: _id,
								}))}
							/>
							<FormSelect
								className="mr-5.5 mt-1.25"
								label="Post"
								register={register}
								name="post"
								errors={errors}
								disabled={!postsList.length}
								onChange={e => {
									dispatch(getLocationTimeCapture(e.target.value))
								}}
								options={postsList?.map(({ _id, name }) => ({
									label: name,
									value: _id,
								}))}
							/>
						</div>
						<div className="flex justify-between gap-5 pt-7.5">
							{/* <div className="flex flex-col items-start gap-7.5 ">
								<FormInput
									type="checkbox"
									containerClass="!flex !flex-row-reverse gap-2.5"
									className="mr-5.5 mt-1.25 !w-auto"
									label="Employee Create Enabled"
									register={register}
									name="employeeCreateEnabled"
									errors={errors}
								/>
								<FormInput
									type="checkbox"
									containerClass="!flex !flex-row-reverse gap-2.5"
									className="mr-5.5 mt-1.25 !w-auto"
									label="Auto Allocate Enabled"
									register={register}
									name="autoAllocateEnabled"
									errors={errors}
								/>
								<FormInput
									type="checkbox"
									containerClass="!flex !flex-row-reverse gap-2.5"
									className="mr-5.5 mt-1.25 !w-auto"
									label="Overdue Off Call Visible"
									register={register}
									name="overdueOffCallVisible"
									errors={errors}
								/>
							</div> */}
							<div>
								<div className="flex items-center ">
									<p className="w-1/4 break-all p-2.5 text-dark-alt">On Call</p>
									<div className="flex w-full">
										<FormInput
											className="mr-5.5 mt-1.25 w-3/4"
											label="Before"
											register={register}
											name="onCall.before"
											errors={errors}
										/>
										<FormInput
											className="mr-5.5 mt-1.25 w-3/4"
											label="After"
											register={register}
											name="onCall.after"
											errors={errors}
										/>
									</div>
								</div>
								<div className="flex items-center ">
									<p className=" w-1/4 break-all p-2.5 text-dark-alt">Off Call</p>
									<div className="flex w-full">
										<FormInput
											containerClass="invisible"
											className="mr-5.5 mt-1.25 w-3/4 "
											label="Before"
											register={register}
											name="offCall.before"
											errors={errors}
										/>
										<FormInput
											className="mr-5.5 mt-1.25 w-3/4"
											label="After"
											register={register}
											name="offCall.after"
											errors={errors}
										/>
									</div>
								</div>
								{/* <div className="flex items-center ">
									<p className="w-1/4 break-all p-2.5 text-dark-alt">Check Call</p>
									<div className="flex w-full">
										<FormInput
											className="mr-5.5 mt-1.25 w-3/4"
											label="Before"
											register={register}
											name="checkCall.before"
											errors={errors}
										/>
										<FormInput
											className="mr-5.5 mt-1.25 w-3/4"
											label="After"
											register={register}
											name="checkCall.after"
											errors={errors}
										/>
									</div>
								</div>
								<div className="flex items-center ">
									<p className=" w-1/4 break-all p-2.5 text-dark-alt">Inventory Call</p>
									<div className="flex w-full">
										<FormInput
											className="mr-5.5 mt-1.25 w-3/4 "
											label="Before"
											register={register}
											name="inventoryCall.before"
											errors={errors}
										/>
										<FormInput
											className="mr-5.5 mt-1.25 w-3/4"
											label="After"
											register={register}
											name="inventoryCall.after"
											errors={errors}
										/>
									</div>
								</div>
								<div className="flex items-center ">
									<p className=" w-1/4 break-all p-2.5 text-dark-alt">QR Call</p>
									<div className="flex w-full">
										<FormInput
											className="mr-5.5 mt-1.25 w-3/4 "
											label="Before"
											register={register}
											name="qrCall.before"
											errors={errors}
										/>
										<FormInput
											className="mr-5.5 mt-1.25 w-3/4"
											label="After"
											register={register}
											name="qrCall.after"
											errors={errors}
										/>
									</div>
								</div> */}
							</div>
							{/* <div>
								<p className="text-dark-alt">MultiTake</p>
								<FormInput
									className="mr-5.5 mt-45 w-3/4"
									type="checkbox"
									register={register}
									name="checkCall.multiTake"
									errors={errors}
								/>
							</div>
							<div className="flex flex-col gap-7.5">
								<p className="text-dark-alt">Alarm</p>
								<FormInput
									className="mr-5.5 mt-1.25 w-3/4"
									type="radio"
									register={register}
									name="onCall.overdueAction"
									value="Alarm"
									errors={errors}
								/>
								<FormInput
									className="mr-5.5 mt-7.5 w-3/4"
									type="radio"
									register={register}
									value="Alarm"
									name="offCall.overdueAction"
									errors={errors}
								/>
								<FormInput
									className="mr-5.5 mt-7.5 w-3/4"
									type="radio"
									register={register}
									value="Alarm"
									name="checkCall.overdueAction"
									errors={errors}
								/>
								<FormInput
									className="mr-5.5 mt-7.5 w-3/4"
									type="radio"
									register={register}
									value="Alarm"
									name="inventoryCall.overdueAction"
									errors={errors}
								/>
							</div>
							<div className="flex flex-col gap-7.5">
								<p className="text-dark-alt">Take</p>
								<FormInput
									className="mr-5.5 mt-1.25 w-3/4"
									type="radio"
									register={register}
									value="Take"
									name="onCall.overdueAction"
									errors={errors}
								/>
								<FormInput
									className="mr-5.5 mt-7.5 w-3/4"
									type="radio"
									register={register}
									value="Take"
									name="offCall.overdueAction"
									errors={errors}
								/>
								<FormInput
									className="mr-5.5 mt-7.5 w-3/4"
									type="radio"
									register={register}
									value="Take"
									name="checkCall.overdueAction"
									errors={errors}
								/>
								<FormInput
									className="mr-5.5 mt-7.5 w-3/4"
									type="radio"
									register={register}
									value="Take"
									name="inventoryCall.overdueAction"
									errors={errors}
								/>
							</div>
							<div className="flex flex-col gap-7.5">
								<p className="text-dark-alt">Outbound</p>
								<FormInput
									className="mr-5.5 mt-1.25 w-3/4"
									type="radio"
									register={register}
									value="Outbound"
									name="onCall.overdueAction"
									errors={errors}
								/>
								<FormInput
									className="mr-5.5 mt-7.5 w-3/4"
									type="radio"
									register={register}
									value="Outbound"
									name="offCall.overdueAction"
									errors={errors}
								/>
								<FormInput
									className="mr-5.5 mt-7.5 w-3/4"
									type="radio"
									register={register}
									value="Outbound"
									name="checkCall.overdueAction"
									errors={errors}
								/>
								<FormInput
									className="mr-5.5 mt-7.5 w-3/4"
									type="radio"
									register={register}
									value="Outbound"
									name="inventoryCall.overdueAction"
									errors={errors}
								/>
							</div>
							<div className="flex-col space-y-40">
								<FormSelect
									disabled={!ApplicableOutboundTelephones.length}
									className="mr-5.5 mt-1.25"
									label="Outbound Telephone"
									register={register}
									name="outboundTelephone"
									errors={errors}
									options={ApplicableOutboundTelephones.map(({ label, value }) => ({
										label: label,
										value: value,
									}))}
								/>
								<FormInput
									label="Minimum Images"
									className="mr-5.5 mt-1.25 w-1/4"
									register={register}
									name="minimumImages"
									errors={errors}
								/>
							</div> */}
						</div>
						<AllowedTo perform={Permissions.UPDATE_LOCATION_TIME_CAPTURE}>
							<div className="pt-4">
								<AntdButton
									loading={isSaving}
									disabled={!isDirty || isSubmitting || !postsList.length}
									htmlType="submit"
								>
									{timeCapture ? "Update" : "Save"}
								</AntdButton>
							</div>
						</AllowedTo>
					</form>
				) : (
					<>
						<div className="mb-16  flex flex-col ">
							<form onSubmit={handleSubmitSimple(onSubmitSimple)} className="h-auto">
								<div className="flex justify-start space-x-4">
									<FormSelect
										className="mr-5.5 mt-1.25"
										label="Contract"
										register={register}
										name="contract"
										errors={errors}
										disabled={!contractsList.length}
										onChange={e => {
											dispatch(getLocationPostsByContractId(e.target.value))
										}}
										options={contractsList?.map(({ _id, finishDate, startDate }) => ({
											label: finishDate
												? `${convertIOSDateToHTMLFormat(
														startDate,
												  )}-${convertIOSDateToHTMLFormat(finishDate)}`
												: `${convertIOSDateToHTMLFormat(startDate)}- ${Ongoing}`,
											value: _id,
										}))}
									/>
									<FormSelect
										className="mr-5.5 mt-1.25"
										label="Post"
										register={register}
										name="post"
										errors={errors}
										disabled={!postsList.length}
										onChange={e => {
											dispatch(getLocationTimeCapture(e.target.value))
										}}
										options={postsList?.map(({ _id, name }) => ({
											label: name,
											value: _id,
										}))}
									/>
								</div>
								<div className="h-6">
									{errorsSimple.parent?.message && (
										<div className="flex items-center  justify-start text-red-500">
											{errorsSimple.parent?.message + " *"}
										</div>
									)}
								</div>
								<div className=" flex h-auto justify-start space-x-4">
									{simpleSettingsData.map(({ prop, title }, index) => {
										return (
											<div
												key={`${prop}-${index}`}
												className=" flex flex-col items-center justify-center  rounded-xl bg-slate-300/40  px-5  "
											>
												<div className="w-full">
													<div className="flex items-center justify-between">
														<label className="w-auto text-md font-normal">
															{title} every
														</label>
														<div className="flex h-full items-center justify-between space-x-5 py-2">
															<FormSelect
																register={registerSimple}
																name={prop}
																options={simpleHoursOptions}
																value={watchSimple(prop)}
																className="ml-2 h-10 w-full py-0"
															/>
														</div>
													</div>
												</div>
											</div>
										)
									})}
								</div>

								<div className=" mt-4 mr-4 flex items-center justify-start">
									<AntdButton htmlType="submit" loading={isSaving}>
										Submit
									</AntdButton>
								</div>
							</form>
						</div>
					</>
				)}

				<hr />
				<div className=" py-2">
					<Tabs initialTab={0}>
						<Tab title="Check Call" hasHeader shouldOverflow={false}>
							<CallTime />
						</Tab>
						<Tab title="Inventory Call" hasHeader shouldOverflow={false}>
							<InventoryCall />
						</Tab>
						<Tab title="QR Scan" hasHeader shouldOverflow={false}>
							<QRCall />
						</Tab>
					</Tabs>
				</div>
			</div>
		</TimeCapturesContext.Provider>
	)
}

export default TimeCaptures
