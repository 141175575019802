import React, { useContext, useMemo } from "react"
import { useAppSelector } from "hooks"
import { AntdButton, Table2 } from "components"
import { selectEmployeeDocumentData } from "store/Employee/Documents/documents.slice"
import DocumentContext, { DocumentContextProps } from "./documentContext"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"
import { addPathToDocument } from "config/utils"
import { CloudDownloadOutlined } from "@ant-design/icons"
import GenericService from "services/generic/generic.service"

const DocumentTable: React.FC = () => {
	const employeeDocument = useAppSelector(selectEmployeeDocumentData())

	const { handleRow } = useContext(DocumentContext) as DocumentContextProps

	const documentValue = useMemo(() => {
		if (employeeDocument) {
			return employeeDocument?.map((d, i) => {
				return {
					...d,
					serialNumber: i + 1,
				}
			})
		}
	}, [employeeDocument])

	const handleDownload = async ({ row, key }: any) => {
		if (row[key]) {
			const downloadURL = await addPathToDocument(row[key])
			try {
				const response = await GenericService.getBlobResponse(downloadURL)
				const contentType = response.headers["content-type"]

				// Assuming the response.data is a Blob
				const blobData = new Blob([response.data], {
					type: contentType,
				})
				const objectURL = window.URL.createObjectURL(blobData)
				const tempLink = document.createElement("a")
				tempLink.href = objectURL
				tempLink.setAttribute("download", row["title"])
				tempLink.click()
			} catch (error) {
				console.error("Error occurred while downloading the file:", error)
			}
		}
	}

	const handleUpdate = (e: any, { row }: any) => {
		e.preventDefault()
		handleRow(row)
	}
	const columns = [
		{ label: "Sr. No", key: "serialNumber" },
		{ label: "Title", key: "title" },
		{ label: "Description", key: "description" },
		{
			label: "Document",
			key: "link",
			render: ({ row, key }: any) => (
				<AntdButton
					className="flex items-center justify-center"
					icon={<CloudDownloadOutlined />}
					onClick={() => handleDownload({ row, key })}
				>
					Download
				</AntdButton>
			),
		},
		{
			label: "History",
			key: "_id",
			render: ({ row }: any) => (
				<AuditTrail id={row["_id"]} name={Entities.EMPLOYEE_DOCUMENTS} showLabel={false} />
			),
		},
		{
			label: "Action",
			key: "action",
			render: ({ row }: any) => {
				return <AntdButton onClick={e => handleUpdate(e, { row })}>Update</AntdButton>
			},
		},
	]
	return (
		<div>
			<Table2 columns={columns} rows={documentValue && documentValue.length > 0 ? documentValue : []} />
		</div>
	)
}

export default DocumentTable
