import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "hooks"
import { LocationDocumentInterface } from "@type/locations.types"
import Form from "./Form"
import Table from "./Table"
import { getLocationDocumentByLocationId } from "store/Location/Documents/documents.slice"
import DocumentContext from "./documentContext"
import { selectSelectedLocation } from "store/globalState.slice"

const Documents = () => {
	const dispatch = useAppDispatch()
	const [document, setDocument] = useState<LocationDocumentInterface | undefined>()
	const selected = useAppSelector(selectSelectedLocation)

	const handleRow = (e: any) => {
		setDocument(e)
	}

	useEffect(() => {
		selected && dispatch(getLocationDocumentByLocationId(selected))
		setDocument(undefined)
	}, [selected])

	return (
		<DocumentContext.Provider value={{ handleRow, document }}>
			<Form />
			<Table />
		</DocumentContext.Provider>
	)
}

export default Documents
