import { Input, InputNumber, InputNumberProps, InputProps } from "antd-v5"
import { TextAreaProps } from "antd-v5/lib/input"
import { forwardRef } from "react"
import InputLabel from "./InputLabel"
import { twMerge } from "tailwind-merge"
import { CustomFormInputType } from "./FormInputAntd"

type CustomFormProps = InputNumberProps | InputProps | TextAreaProps

enum CUSTOM_INPUT {
	"text" = "text",
	"textarea" = "textarea",
	"number" = "number",
	"password" = "password",
}

interface Props extends InputProps {
	label?: string
	containerClass?: string
}

const AntdInput = forwardRef<InputProps, Props>(
	({ containerClass = "w-full", type = "", label = "", ...rest }, ref) => {
		return (
			<div className={twMerge(`flex flex-col`, containerClass)}>
				<InputLabel label={label || ""} />
				<CustomInput type={type} ref={ref} rest={rest} />
			</div>
		)
	},
)

export default AntdInput

interface CustomInputProps {
	type: CustomFormInputType | string
	rest: CustomFormProps
}

const CustomInput = forwardRef<any, CustomInputProps>(({ type, rest }, ref) => {
	switch (type) {
		case CUSTOM_INPUT.number:
			return <InputNumber {...(rest as InputNumberProps)} ref={ref} style={{ width: "100%" }} />
		case CUSTOM_INPUT.text:
			return <Input {...(rest as InputProps)} ref={ref} />
		case CUSTOM_INPUT.textarea:
			return <Input.TextArea {...(rest as TextAreaProps)} autoSize={{ minRows: 2 }} ref={ref} />
		case CUSTOM_INPUT.password:
			return <Input.Password {...(rest as InputProps)} ref={ref} />
		default:
			return <></>
	}
})
