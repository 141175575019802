import { Controller, useFormContext } from "react-hook-form"
import InputLabel from "./InputLabel"
import AntdSelect, { AntSelectProps } from "./AntdSelect"

interface SelectOptions {
	label: string
	value: string
}

interface Props extends AntSelectProps {
	options: SelectOptions[]
	containerClass?: string
	className?: string
	name: string
}

const FormSelectAntd: React.FC<Props> = ({
	label,
	containerClass = "w-full",
	className = "w-full",
	name,
	options = [],
	...rest
}) => {
	const {
		control,
		formState: { errors },
	} = useFormContext()

	return (
		<div className={`flex w-full flex-col ${containerClass}`}>
			<InputLabel label={label || ""} />
			<Controller
				control={control}
				name={name}
				render={({ field: { value, onChange, onBlur, ref } }) => {
					return (
						<AntdSelect
							showSearch
							className={className}
							optionFilterProp="children"
							onChange={onChange}
							onBlur={onBlur}
							ref={ref}
							options={options}
							value={value}
							{...rest}
						/>
					)
				}}
			/>
			{errors[name] && <p className="mt-1 ml-1 text-sm text-danger">{errors[name]?.message}</p>}
		</div>
	)
}

export default FormSelectAntd
