import Icon from "components/Icon"
import useAppDispatch from "hooks/useAppDispatch"
import { deleteLocationQRCodeById } from "store/Location/locationDetails.slice"
import { GetLocationQRCodeInterface } from "@type/locations.types"
import React, { useRef } from "react"
import QRCode from "react-qr-code"
import { useReactToPrint } from "react-to-print"

interface Props {
	selectedQR: GetLocationQRCodeInterface | undefined
	currentQRCode: GetLocationQRCodeInterface
	index: number
	setSelectedQR: (e: GetLocationQRCodeInterface | undefined) => void
	selected: string | null
}

const QRCodes: React.FC<Props> = ({ selectedQR, currentQRCode, index, setSelectedQR, selected }) => {
	const pageStyle = `
	@media print {
		#para {
			margin-top:20px;
			font-weight:bold;
			font-size:40px;
			}
	 }
	 @media print {
		#qr{
			width:90%;
			height:90%;
			display:block;
			margin-left:auto;
			margin-right:auto;

		}
	}
	@page {
		size: auto;
		margin: 25%;
	}
  `
	const size = 120
	const dispatch = useAppDispatch()
	const componentRef = useRef(null)
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: pageStyle,
	})
	return (
		<div
			className={` group relative w-fit border-1 border-solid  
${selectedQR === currentQRCode ? "border-indigo-600" : "border-transparent"} hover:border-indigo-600`}
			key={index}
		>
			<div ref={componentRef}>
				<div id="qrSpan">
					<div className="hidden" id="tt">
						Time Trail
					</div>
					<QRCode
						id="qr"
						className=" group-hover:opacity-10"
						size={size}
						value={JSON.stringify({ qrId: currentQRCode._id, location: selected })}
					/>
					<div className="text-center" id="para">
						{currentQRCode.name}
					</div>
				</div>
				<div className="hidden" id="para2">
					{currentQRCode.name}
				</div>
			</div>
			<div className="group invisible absolute right-[19%] top-[20%] flex-col justify-center  space-y-4 group-hover:visible">
				<div className=" flex space-x-4">
					<div
						className="w-fit cursor-pointer"
						onClick={() =>
							selectedQR === currentQRCode ? setSelectedQR(undefined) : setSelectedQR(currentQRCode)
						}
					>
						{selectedQR === currentQRCode ? (
							<Icon name="cross" color="green" size={32}></Icon>
						) : (
							<Icon name="edit" color="green" size={32}></Icon>
						)}
					</div>
					<></>
					<div
						className="w-fit cursor-pointer"
						onClick={() => {
							if (selected) {
								dispatch(deleteLocationQRCodeById(selected, currentQRCode._id))
								setSelectedQR(undefined)
							}
						}}
					>
						<Icon name="delete" color="danger" size={32}></Icon>
					</div>
				</div>
				<div className="flex justify-center">
					<div className="w-fit cursor-pointer " onClick={handlePrint}>
						<Icon name="print" color="purple" size={32}></Icon>
					</div>
				</div>
			</div>
		</div>
	)
}

export default QRCodes
