import React, { useEffect, useMemo, useState } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useAppSelector, useAppDispatch } from "hooks"
import { AntdButton, AntdSelect, AntdSelect1, DateField, FormInputAntd, Table2, TableSkeleton } from "components"
import { EmployeeBansFormInterface } from "@type/employees.types"
import { selectBranchList } from "store/Branches/branch.slice"
import {
	getLocationsByBranchId,
	isLocationLoading,
	selectLocationListByBranchId,
} from "store/Location/locationDetails.slice"
import { yupResolver } from "@hookform/resolvers/yup"
import { employeeBanValidationSchema, employeeBanInitialValues } from "Schema"
import {
	getEmployeesBanByEmployeeId,
	isEmployeeBanLoading,
	isEmployeeBanSaving,
	saveEmployeeBanData,
	selectEmployeeBanById,
	selectEmployeeBanData,
} from "store/Employee/Location/employeeBan.slice"
import { convertIOSDateToHTMLFormat } from "config/utils"
import { GLOBAL_DATE_FORMAT, Permissions } from "config/constants"
import { getCustomers, selectCustomerList } from "store/Customers/customers.slice"
import { AllowedTo } from "react-abac"
import { Entities } from "types/auditTrail.types"
import AuditTrail from "components/AuditTrail"
import { SelectedPropInterface } from "services/generic/type"

const employeeBanCommonInitialValues = { customer: "", location: "", branch: "" }
const BanOptions = [
	{ label: "Select Option", value: "", initialValues: {} },
	{
		label: "Location",
		value: "location",
		initialValues: { ...employeeBanCommonInitialValues, selectOption: "location" },
	},
	{ label: "Branch", value: "branch", initialValues: { ...employeeBanCommonInitialValues, selectOption: "branch" } },
	{
		label: "Customer",
		value: "customer",
		initialValues: { ...employeeBanCommonInitialValues, selectOption: "customer" },
	},
]

interface EmployeeBansFormHandler extends EmployeeBansFormInterface {
	selectOption: string
}
interface FormDropdowns {
	name: string
	label: string
	options: any
	loading?: boolean
}
interface FormFields {
	branch: FormDropdowns[]
	customer: FormDropdowns[]
	location: FormDropdowns[]
}
const EmployeeBans: React.FC<SelectedPropInterface> = ({ selected }) => {
	const [selectedBan, setSelectedBan] = useState<string | null>(null)
	const selectedEmployeeBan = useAppSelector(selectEmployeeBanById(selectedBan))
	const employeeBan = useAppSelector(selectEmployeeBanData())
	const branches = useAppSelector(selectBranchList())
	const customerList = useAppSelector(selectCustomerList())
	const dispatch = useAppDispatch()
	const employeeBanSaving = useAppSelector(isEmployeeBanSaving())
	const employeeBanLoading = useAppSelector(isEmployeeBanLoading())
	const methods = useForm<EmployeeBansFormHandler>({
		resolver: yupResolver(employeeBanValidationSchema),
	})
	const {
		handleSubmit,
		reset,
		setValue,
		watch,
		clearErrors,
		// getValues,
		formState: { errors },
	} = methods
	const locationByBranch = useAppSelector(selectLocationListByBranchId(watch("branch") as string))
	const isLoadingLocations = useAppSelector(isLocationLoading())

	useEffect(() => {
		if (selected) {
			dispatch(getEmployeesBanByEmployeeId(selected))
			dispatch(getCustomers())
			setValue("selectOption", "")
			reset(employeeBanInitialValues)
			setSelectedBan(null)
		}
	}, [selected])
	useEffect(() => {
		if (!selectedEmployeeBan) {
			const initialValuesForSelectedOption = BanOptions.find(option => {
				return watch("selectOption") === option.value
			})?.initialValues
			reset(initialValuesForSelectedOption)
		}
	}, [watch("selectOption")])

	useEffect(() => {
		if (selectedEmployeeBan) {
			const { branch, location, customer } = selectedEmployeeBan
			reset({
				...selectedEmployeeBan,
				selectOption: location ? "location" : branch ? "branch" : customer ? "customer" : "",
				location: location ? location._id : "",
				branch: branch ? branch._id : "",
				customer: customer ? customer._id : "",
			})
		}
	}, [selectedEmployeeBan])

	useEffect(() => {
		if (!employeeBanSaving) {
			setSelectedBan(null)
			reset(employeeBanInitialValues)
		}
	}, [employeeBan])

	// const formData = useMemo(() => {
	// 	return getValues()
	// }, [getValues()])

	useEffect(() => {
		if (watch("selectOption") === "location") {
			if (watch("branch") && watch("branch") !== "") {
				dispatch(getLocationsByBranchId(watch("branch") || ""))
			}
		}
	}, [watch("selectOption"), watch("branch")])

	const fields: FormFields = useMemo(() => {
		return {
			location: [
				{ name: "branch", label: "Branch", options: branches || [] },
				{ name: "location", label: "Location", options: locationByBranch || [], loading: isLoadingLocations },
			],
			branch: [{ name: "branch", label: "Branch", options: branches || [] }],
			customer: [{ name: "customer", label: "Customer", options: customerList || [] }],
		}
	}, [watch("selectOption"), branches, customerList, locationByBranch, isLoadingLocations])

	const onSubmit: SubmitHandler<EmployeeBansFormHandler> = data => {
		Object.keys(data).forEach(key => {
			if (data[key as keyof EmployeeBansFormInterface] === "") {
				delete data[key as keyof EmployeeBansFormInterface]
			}
		})

		dispatch(
			saveEmployeeBanData(
				selectedBan
					? { _id: selectedBan, ...data, employee: selected as string }
					: { ...data, employee: selected as string },
				() => {
					setSelectedBan("")
					reset(employeeBanInitialValues)
				},
			),
		)
	}
	return (
		<div>
			<FormProvider {...methods}>
				<div className="text-xl">Employee Bans</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="mb-2 grid grid-cols-6">
						<AntdSelect1
							label="Ban From"
							value={watch("selectOption")}
							onChange={val => setValue("selectOption", val)}
							options={BanOptions}
							disabled={!!selectedBan}
						/>
					</div>
					<div className={`${watch("selectOption") === "" ? "hidden" : "flex flex-col gap-3"}`}>
						{!!watch("selectOption") && (
							<>
								<div className={`grid grid-cols-6 gap-x-5`}>
									{fields[watch("selectOption") as keyof FormFields].map(field => (
										<AntdSelect
											key={field.name}
											size="middle"
											label={`${field.label} Name`}
											name={field.name}
											loading={field?.loading}
											formValueSetter={(name: any, value, options) => {
												if (watch("selectOption") === "location" && name === "branch") {
													setValue("location", "", options)
												}
												setValue(name, value, options)
											}}
											isControlled={true}
											selected={field?.loading ? "" : watch(field.name as any)}
											options={field.options.map(
												({ _id, name }: { _id: string; name: string }) => ({
													label: name,
													value: _id,
												}),
											)}
											errors={errors}
										/>
									))}
									<FormInputAntd type="text" label="Reason" name="reason" />
									<FormInputAntd type="text" label="From" name="from" />
								</div>
								<div className="grid grid-cols-6 gap-x-5">
									<DateField
										label="Start Date"
										setValue={setValue}
										watch={watch}
										name={"startDate"}
										errors={errors}
										clearErrors={name => clearErrors(name)}
									/>
									<DateField
										label="Finish Date"
										setValue={setValue}
										watch={watch}
										name={"finishDate"}
										errors={errors}
									/>
								</div>
								<AllowedTo perform={Permissions.UPDATE_EMPLOYEE_LOCATIONS}>
									<div className="flex justify-start">
										<AntdButton
											htmlType="submit"
											loading={employeeBanSaving}
											disabled={employeeBanSaving}
										>
											{selectedBan ? "Update" : "Ban"}
										</AntdButton>
									</div>
								</AllowedTo>
							</>
						)}
					</div>
				</form>
			</FormProvider>
			<div className="table-fix-head schedule-scrollbar my-2 max-h-64 w-5/6">
				{!employeeBanLoading ? (
					<Table2
						rows={employeeBan}
						columns={[
							{
								label: "Banned From",
								key: "",
								render: ({ row }: any) => {
									const bannedFrom = BanOptions.find(type => row[type.value]) || BanOptions[0]
									return <div className="ml-2 text-left">{bannedFrom.label}</div>
								},
							},
							{
								label: "Name",
								key: "name",
								render: ({ row, key }: any) => {
									const bannedFrom = BanOptions.find(type => row[type.value]) || BanOptions[0]
									return <div className="ml-2 text-left">{row[bannedFrom.value][key]}</div>
								},
							},
							{
								label: "Reason",
								key: "reason",
								render: ({ row, key }: any) => (
									<div className="ml-2 text-left"> {row[key] ? row[key] : "N/A"}</div>
								),
							},
							{
								label: "From",
								key: "from",
								render: ({ row, key }: any) => (
									<div className="ml-2 text-left"> {row[key] ? row[key] : "N/A"}</div>
								),
							},
							{
								label: "Start Date",
								key: "startDate",
								render: ({ row, key }: any) => {
									return (
										<div className="ml-2 text-left">
											{row[key]
												? convertIOSDateToHTMLFormat(row[key], GLOBAL_DATE_FORMAT)
												: "N/A"}
										</div>
									)
								},
							},
							{
								label: "Finish Date",
								key: "finishDate",
								render: ({ row, key }: any) => {
									return (
										<div className="ml-2 text-left">
											{row[key]
												? convertIOSDateToHTMLFormat(row[key], GLOBAL_DATE_FORMAT)
												: "N/A"}
										</div>
									)
								},
							},
							{
								label: "History",
								key: "_id",
								render: ({ row }: any) => (
									<AuditTrail id={row["_id"]} name={Entities.EMPLOYEE_BAN} showLabel={false} />
								),
							},
							{
								label: "Actions",
								key: "action",
								render: ({ row }) => {
									return (
										<AntdButton
											className="w-min justify-center py-1"
											disabled={selectedBan == row._id || employeeBanSaving}
											onClick={() => setSelectedBan(row._id)}
										>
											{selectedBan !== row._id ? "Update" : "Editing"}
										</AntdButton>
									)
								},
							},
						]}
					/>
				) : (
					<TableSkeleton />
				)}
			</div>
		</div>
	)
}

export default EmployeeBans
