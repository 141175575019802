import React, { useMemo } from "react"
import moment from "moment-timezone"
import cx from "classnames"
import { convertIOSDateToHTMLFormat, convertIOSDateToSpecificFormat, getMomentZInstanceOfDate } from "config/utils"
import { useAppSelector, useEmployeeTable } from "hooks"
import { selectScheduleListByEmployee, selectScheduleLoading } from "store/Schedules/schedules.slice"
import { DATE_FORMATS, GLOBAL_DATE_FORMAT } from "config/constants"
import ScheduleTableSkeletonEmployee from "components/ScheduleTableSkeletonEmployee"
import { CreateScheduleByEmployeeFormInterface } from "./CreateEmployeeScheduleModal"
import { ScheduleStatus } from "../Constants/schedulesConstants"
import { GetLocationDetailsInterface } from "@type/locations.types"
import { twMerge } from "tailwind-merge"
import ScheduleTableCell from "../Location/ScheduleTableCell"

const DaysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
export interface TableCell extends Omit<CreateScheduleByEmployeeFormInterface, "location"> {
	col_label: string
	column: number
	subColumn: number
	row: number
	_id?: string
	location?: GetLocationDetailsInterface
	status?: ScheduleStatus
}

interface DayData {
	[key: string]: TableCell[]
}
interface Props {
	showDeleted: boolean
	date: string
	cellSelectionHandler: (e: any) => void
	selectedCell: TableCell[]
}

const EmployeeTable: React.FC<Props> = ({ showDeleted, date, cellSelectionHandler, selectedCell }) => {
	const schedules = useAppSelector(
		selectScheduleListByEmployee(
			moment(date, DATE_FORMATS.YYYY_MM_DD).startOf("month").format(DATE_FORMATS.DD_MM_YYYY),
			showDeleted,
		),
	)
	const isScheduleLoading = useAppSelector(selectScheduleLoading())
	const { getMonthDatesByWeek } = useEmployeeTable()

	const daysArr = (dayArr: any, row: number) => {
		let maxSchedulesInOneDay = 1
		const data = dayArr.map((day: any, i: number) => {
			const val = schedules.filter(
				(element: any) => convertIOSDateToHTMLFormat(element.date, GLOBAL_DATE_FORMAT) === day.date,
			)

			if (val.length > maxSchedulesInOneDay) maxSchedulesInOneDay = val.length
			return (val.length ? val : [undefined]).map((item, ind) => ({
				...(item || {}),
				...day,
				row,
				column: i,
				subColumn: ind,
			}))
		})

		return data.map((item: any) => {
			const { column, row, col_label, date } = item[0]
			return [
				...item,
				...Array.from({ length: maxSchedulesInOneDay - item.length }, (_, i) => ({
					row,
					date,
					col_label,
					column,
					subColumn: item.length + i,
				})),
			]
		})
	}

	const employeeSchedulesValue = useMemo(() => {
		/* 
		getMonthStartDate Returns Dates for Every Day of the Week for Selected Month
		[ { "col_label": "31", "date": "31-07-2023" }, { "col_label": "7", "date": "07-08-2023" }, { "col_label": "14", "date": "14-08-2023" }, { "col_label": "21", "date": "21-08-2023" }, { "col_label": "28", "date": "28-08-2023" }, { "col_label": "4", "date": "04-09-2023" } ]
		*/
		const weekDays = getMonthDatesByWeek(
			parseInt(convertIOSDateToSpecificFormat(date as string, "YYYY")),
			parseInt(convertIOSDateToSpecificFormat(date as string, "M")),
		)

		// Add arrays for dates with multiple schedules
		// Find max schedules for a date for monday, tuesday ets
		// Create that many rows for the day and add empty schedules as well
		return weekDays.map((item, ind) => ({ [DaysOfWeek[ind]]: [...daysArr(item, ind)] }))
	}, [date, schedules])

	if (isScheduleLoading)
		return (
			<div className="w-90p">
				<ScheduleTableSkeletonEmployee />
			</div>
		)

	return (
		<React.Fragment>
			<div className="px-1">
				{employeeSchedulesValue.map((data: any, i: number) => {
					const tableRowHeaderClassName = twMerge(
						cx(
							"border-primary1 flex select-none items-center justify-center border-b-0.25 bg-primary-bg px-10 text-center text-md font-medium text-primary1",
							{
								"border-t-0.25": i === 0,
							},
						),
					)
					return (
						/* Table row */
						<div key={i} className="grid h-fit grid-cols-7 gap-2">
							<div className={tableRowHeaderClassName}>{Object.keys(data)[0]}</div>
							<React.Fragment>
								{Object.keys(employeeSchedulesValue[i]).map(day => {
									const dayData: DayData = employeeSchedulesValue[i]
									return dayData[day].map((cell: any, ind: number) => {
										return (
											<div key={`${day}_${ind}`}>
												{cell.map((item: any) => {
													const tableCellClass = cx({
														"flex flex-col items-end space-y-1 h-[125px] w-full border-b-0.25  border-gray-200 cursor-pointer py-1":
															true,
														"border-t-0.25": i === 0,
														"bg-[#F5F5F5]":
															moment(item.date as string, GLOBAL_DATE_FORMAT).format(
																"M",
															) !== convertIOSDateToSpecificFormat(date as string, "M"),
														"bg-secondary": selectedCell?.some(
															element =>
																element?.subColumn === item.subColumn &&
																element?.column === item.column &&
																element.row === item.row,
														),
													})
													const isCurrentMonthDate =
														getMomentZInstanceOfDate(
															item.date,
															DATE_FORMATS.DD_MM_YYYY,
														).format("MM") ===
														getMomentZInstanceOfDate(date, DATE_FORMATS.DD_MM_YYYY).format(
															"MM",
														)
													return (
														<div
															key={`${item.date}_${item.row}_${item.column_}_${item.subColumn}`}
															className={twMerge(tableCellClass)}
															onClick={() => {
																if (isCurrentMonthDate) cellSelectionHandler(item)
															}}
														>
															<div className="text-sm text-gray-400">
																{item.col_label}
															</div>
															<div className="w-full">
																{item._id && (
																	<ScheduleTableCell
																		name={
																			item.location?.shortName ||
																			item.location.name
																		}
																		employeeType={item?.populatedEmployee?.type}
																		isDeleted={item.isDeleted}
																		status={item.status as ScheduleStatus}
																		postName={item.post.name}
																		time={`${moment(item.startTime).format(
																			"HH:mm",
																		)} - ${moment(item.finishTime).format(
																			"HH:mm",
																		)}`}
																		scheduleType={item.type}
																	/>
																)}
															</div>
														</div>
													)
												})}
											</div>
										)
									})
								})}
							</React.Fragment>
						</div>
					)
				})}
			</div>
		</React.Fragment>
	)
}

export default EmployeeTable
