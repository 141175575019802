import axios from "axios"
import { WorkMonitorViewsInterface, GetWorkMonitorViewsInterface } from "@type/organization.types"
const ROOT_PATH = "work-monitor-views"
export default class WorkMonitorViewsService {
	static createWorkMonitorViews = (workMonitorViews: WorkMonitorViewsInterface) =>
		axios.post<GetWorkMonitorViewsInterface>(ROOT_PATH, workMonitorViews)
	static getWorkMonitorViewById = (id: string) => axios.get<GetWorkMonitorViewsInterface>(`${ROOT_PATH}/${id}`)
	static getAllWorkMonitorViews = () => axios.get<Array<GetWorkMonitorViewsInterface>>(ROOT_PATH)

	static updateWorkMonitorViews = (id: string, workMonitorViews: Partial<GetWorkMonitorViewsInterface>) =>
		axios.put<GetWorkMonitorViewsInterface>(`${ROOT_PATH}/${id}`, workMonitorViews)
}
