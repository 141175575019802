import React, { useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "hooks"
import {
	filteredListBySearch,
	getLocationsByBranchId,
	selectAddLocationClicked,
	isLocationLoading,
	locationAddingEventClicked,
} from "store/Location/locationDetails.slice"
import GlobalSidebar from "./GlobalSidebar"
import SidebarList from "./SidebarList"
import { selectBranchList } from "store/Branches/branch.slice"
import { getEmployeesByBranchId } from "store/Employee/detail.slice"
import {
	selectSelectedLocationBranch,
	selectSelectedCustomer,
	setSelectedLocationBranch,
	setSelectedCustomer,
	setSelectedLocation,
} from "store/globalState.slice"
import { isRouteDashboard } from "config/utils"
import { getCustomers, isCustomerLoading, selectCustomerOptions } from "store/Customers/customers.slice"
import { useLocation } from "react-router-dom"
import { GetLocationDetailsInterface } from "@type/locations.types"
import AntdSwitch from "./AntdSwitch"
import AntdSelect1 from "./AntdSelect"
import { Divider } from "antd-v5"
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons"
import AntdInput from "./AntdInput"

interface Props {
	selected: string
	handleSelected(locationId: string): void
	needEmployeeByBranch?: boolean
	handleSelectedLocations?: (location: string[]) => void
	forAllLocations?: boolean
	allowSelectAllBranches?: boolean
	isAllBranchesSelected?: boolean
	handleIsAllBranchesSelected?: () => void
	handleIsActiveOnlySelected?: (isActiveOnlySelected: boolean) => void
}

const LocationSidebar: React.FC<Props> = ({
	selected,
	handleSelected,
	needEmployeeByBranch = false,
	forAllLocations = false,
	allowSelectAllBranches = false,
	handleSelectedLocations = () => undefined,
	isAllBranchesSelected = false,
	handleIsAllBranchesSelected = () => undefined,
	handleIsActiveOnlySelected = () => undefined,
}) => {
	const dispatch = useAppDispatch()

	const { pathname } = useLocation()

	const [searchString, setSearchString] = useState<string>("")
	// const [isFocused, setIsFocused] = useState<boolean>(false)
	const [searchOnFields] = useState(["shortName", "name"])
	const [shouldShowActiveOnly, setShouldShowActiveOnly] = useState<boolean>(true)

	const selectedBranch = useAppSelector(selectSelectedLocationBranch)
	const selectedCustomer = useAppSelector(selectSelectedCustomer)
	const branches = useAppSelector(selectBranchList())
	const customers = useAppSelector(selectCustomerOptions)
	const filteredLocationList = useAppSelector(
		filteredListBySearch(searchString, selectedBranch, searchOnFields, selectedCustomer, shouldShowActiveOnly),
	)
	const isAddLocationClicked = useAppSelector(selectAddLocationClicked())
	const loading = useAppSelector(isLocationLoading())
	const loadingCustomers = useAppSelector(isCustomerLoading())

	const isDashboardRoute = useMemo(() => isRouteDashboard(pathname), [pathname])

	useEffect(() => {
		dispatch(getCustomers())
	}, [])

	useEffect(() => {
		if (!loading && selected) {
			document.getElementById(selected)?.scrollIntoView()
		}
	}, [loading])

	useEffect(() => {
		if (filteredLocationList.length && !isAddLocationClicked) {
			if (!selected && !isDashboardRoute) {
				handleSelected(filteredLocationList[0]._id)
			} else if (!filteredLocationList.find((item: GetLocationDetailsInterface) => item._id === selected)) {
				handleSelected(filteredLocationList[0]._id)
			} else if (selected) {
				document.getElementById(selected)?.scrollIntoView()
			}
		} else if (!selected && !isAddLocationClicked) {
			handleSelected("")
		}
	}, [filteredLocationList.length, isDashboardRoute, isAddLocationClicked])

	useEffect(() => {
		if (selected && isAddLocationClicked) {
			dispatch(locationAddingEventClicked(false))
		}
	}, [selected, isAddLocationClicked])

	useEffect(() => {
		if (!!branches?.length && !selectedBranch) {
			dispatch(setSelectedLocationBranch(branches[0]._id))
			dispatch(getLocationsByBranchId(branches[0]._id))
		} else if (selectedBranch) {
			dispatch(getLocationsByBranchId(selectedBranch))
		}
	}, [branches.length, selectedBranch])

	// useEffect(() => {
	// 	window.addEventListener("keydown", handleKeyDown)
	// 	return () => {
	// 		window.removeEventListener("keydown", handleKeyDown)
	// 	}
	// }, [filteredLocationList])

	useEffect(() => {
		if (forAllLocations) handleSelectedLocations?.(filteredLocationList.map((item: any) => item._id))
	}, [filteredLocationList.length])

	useEffect(() => {
		if (forAllLocations || isAllBranchesSelected) {
			dispatch(setSelectedLocation(""))
			handleSelectedLocations?.(filteredLocationList.map((item: any) => item._id))
		} else if (isDashboardRoute) {
			if (!selected)
				dispatch(setSelectedLocation(filteredLocationList.find((item: any) => item.isActive)?._id ?? ""))

			handleSelectedLocations([])
		}
	}, [forAllLocations, isAllBranchesSelected])

	// const handleKeyDown = (event: any) => {
	// 	if (isInputInFocus()) return
	// 	if (isFocused) {
	// 		const buttonPressed = event.key
	// 		if (event.key === "Backspace")
	// 			setSearchString(searchString => searchString.slice(0, searchString.length - 1))
	// 		else if (buttonPressed === "ArrowDown" || buttonPressed === "ArrowUp") {
	// 			event.preventDefault()
	// 			const selectedIndex = filteredLocationList.findIndex((item: any) => item._id === selected)
	// 			if (buttonPressed === "ArrowDown") {
	// 				const selectedId = filteredLocationList[selectedIndex + 1]
	// 					? filteredLocationList[selectedIndex + 1]._id
	// 					: filteredLocationList[selectedIndex]._id

	// 				handleSelected(selectedId)
	// 				document.getElementById(selectedId)?.scrollIntoView()
	// 			} else {
	// 				const selectedId = filteredLocationList[selectedIndex - 1]
	// 					? filteredLocationList[selectedIndex - 1]._id
	// 					: filteredLocationList[selectedIndex]._id

	// 				handleSelected(selectedId)
	// 				document.getElementById(selectedId)?.scrollIntoView()
	// 			}
	// 		} else if (event.key.length === 1) setSearchString(searchString => searchString + event.key)
	// 	}
	// }

	return (
		<GlobalSidebar>
			<div className="max-h-full">
				<div className="flex flex-col space-y-2">
					{allowSelectAllBranches && (
						<div className="flex flex-row items-center justify-between">
							<span className="text-md font-medium tracking-wider text-gray-400">Branches</span>
							<AntdSwitch
								checked={isAllBranchesSelected}
								checkedChildren="All"
								unCheckedChildren="Selected"
								onChange={handleIsAllBranchesSelected}
							/>
						</div>
					)}

					<div className="flex flex-row items-center justify-between">
						<span className="text-md font-medium tracking-wider text-gray-400">SITES</span>
						<AntdSwitch
							checked={shouldShowActiveOnly}
							checkedChildren="Active only"
							unCheckedChildren="All"
							onChange={() => {
								setShouldShowActiveOnly(!shouldShowActiveOnly)
								handleIsActiveOnlySelected(!shouldShowActiveOnly)
							}}
						/>
					</div>

					<AntdSelect1
						onChange={value => {
							if (typeof value === "string") {
								dispatch(getLocationsByBranchId(value))
								needEmployeeByBranch && dispatch(getEmployeesByBranchId({ branchId: value }))
								dispatch(setSelectedLocationBranch(value))
							}
						}}
						value={selectedBranch}
						placeholder="Select branch"
						options={branches.map(({ _id, name }) => ({ label: name, value: _id }))}
						suffixIcon={
							<>
								<span style={{ color: "rgb(156 163 175)" }}>Branches</span>
								<CaretDownOutlined style={{ color: "rgb(156 163 175)" }} />
							</>
						}
						disabled={isAllBranchesSelected}
					/>
					<AntdSelect1
						loading={loadingCustomers}
						onChange={value => dispatch(setSelectedCustomer(value))}
						value={selectedCustomer}
						options={[{ label: "All Customers", value: "" }, ...customers].map(({ value, label }) => ({
							label,
							value,
						}))}
						suffixIcon={
							<>
								<span style={{ color: "rgb(156 163 175)" }}>Customers</span>
								<CaretDownOutlined style={{ color: "rgb(156 163 175)" }} />
							</>
						}
						disabled={isAllBranchesSelected}
					/>
					<AntdInput
						id="sidebar-search"
						onChange={e => setSearchString(e.target.value)}
						value={searchString}
						placeholder="Search"
						type="text"
						suffix={
							<>
								<span style={{ color: "rgb(156 163 175)" }}>Ctrl + G</span>
								<SearchOutlined size={14} style={{ color: "rgb(156 163 175)" }} />{" "}
							</>
						}
						disabled={isAllBranchesSelected}
					/>
				</div>
				<Divider className="bg-slate-700" style={{ margin: "15px 0 10px 0" }} />
				<SidebarList
					loading={loading}
					selected={selected}
					setSelected={handleSelected}
					disableClick={forAllLocations || isAllBranchesSelected}
					list={filteredLocationList?.map(({ _id, name, shortName, isActive = true }: any) => ({
						_id,
						name: shortName || name,
						isActive,
					}))}
				/>
			</div>
		</GlobalSidebar>
	)
}

export default LocationSidebar
