import { useRef } from "react"
import { FieldError, FieldErrors, FieldPath, FieldValues, UseFormSetError } from "react-hook-form"
import { AntdButton, Icon, Image, SignedUrlForImage } from "components"
import { OneMbInBytes, TwoPointThreeMbInBytes } from "config/constants"
import { UploadOutlined } from "@ant-design/icons"

interface Props<T extends FieldValues> {
	loading: boolean
	image: string | null | undefined | string[]
	onImageChange(file: File[]): void
	setError: UseFormSetError<T>
	clearErrors: any
	errors?: { [key: string]: FieldError | undefined } | FieldErrors
	name: FieldPath<T>
	label?: string
	containerClass?: string
	uploadMultiple?: boolean
	handleDeleteImage?: (imageUrl: string) => void
}

const ImageField = <T extends FieldValues>({
	loading = false,
	setError,
	onImageChange,
	clearErrors,
	image = "",
	errors = {},
	name,
	label = "",
	containerClass,
	uploadMultiple = false,
	handleDeleteImage = () => undefined,
}: Props<T>) => {
	const inputRef = useRef<HTMLInputElement>(null)
	const files: File[] = []

	const handleSubmit = (event: any) => {
		clearErrors(name)
		if (event.target.files?.length) {
			for (let i = 0; i < event.target.files.length; i++) {
				const { size } = event.target.files[i]
				if (event.target.files?.length) {
					if (size > TwoPointThreeMbInBytes) {
						setError(name, {
							type: "custom",
							message: `Size should be less than ${(TwoPointThreeMbInBytes / OneMbInBytes).toFixed()} mb`,
						})
					} else {
						files.push(event.target.files[i])
					}
				}
			}
			onImageChange(files)
			if (inputRef.current) inputRef.current.value = ""
		}
	}

	return (
		<>
			<label className={"text-dark-alt"}>{label}</label>
			<input
				multiple={uploadMultiple}
				ref={inputRef}
				type="file"
				className="hidden"
				accept="image/png, image/jpeg"
				onChange={e => handleSubmit(e)}
			/>

			{uploadMultiple ? (
				<div className="mb-2">
					<AntdButton
						className="flex items-center justify-center text-md font-normal"
						htmlType="button"
						icon={<UploadOutlined />}
						onClick={() => {
							if (!loading && inputRef && inputRef.current) {
								inputRef?.current?.click()
							}
						}}
					>
						{image?.length ? "Upload More" : "Upload Image"}
					</AntdButton>

					<div className=" grid grid-cols-4 items-center gap-x-3 gap-y-2 p-2">
						{Array.isArray(image) &&
							image.map((imgSrc, index) => (
								<Image
									index={index}
									imageSource={imgSrc}
									key={`loadedImage_${index}`}
									handleDeleteImage={handleDeleteImage}
								/>
							))}
					</div>
				</div>
			) : (
				<div
					className={`flex h-50 w-50 items-center justify-center border-0.75 border-dashed border-purple bg-background ${containerClass} `}
				>
					{image && (
						<SignedUrlForImage path={image}>
							{(imageURL: string) => (
								<img
									width={184}
									height={190}
									decoding="async"
									loading="lazy"
									className={`cursor-pointer object-cover py-[4px] text-xs ${containerClass}`}
									src={imageURL}
									onClick={e => {
										e.preventDefault()
										e.stopPropagation()
										if (!loading && inputRef && inputRef.current) {
											inputRef?.current?.click()
										}
									}}
								/>
							)}
						</SignedUrlForImage>
					)}

					<button
						className={image == (undefined || null || "") ? "w-full text-md font-normal" : "hidden"}
						type="button"
						onClick={e => {
							e.preventDefault()
							e.stopPropagation()
							if (!loading && inputRef && inputRef.current) {
								inputRef?.current?.click()
							}
						}}
					>
						Upload Image
					</button>
					{loading && (
						<Icon name="spinner" color="white" size={50} className="mr-3 animate-spin !text-purple" />
					)}
				</div>
			)}
			{errors[name] && <p className="mt-1 ml-1 text-sm text-danger">{errors[name]?.message}</p>}
		</>
	)
}

export default ImageField
